<template>
  <ActionLayout :actions="actions" :size="size" :action="action" @fetch="$emit('fetch')" :mainIcon="mainIcon" />
</template>

<script>
import {
  MUTATION_affaireDelete,
  MUTATION_affaireUpdate,
} from "./graphql/mutations.js";

import ActionLayout from "@/components/COMPONENTS-skeleton/Layouts/ActionLayout.vue";

export default {
  name: "AffaireActions",

  components: {
    ActionLayout,
  },

  props: {
    action: {
      type: String,
      default: "router",
    },

    mainIcon: {
      type: String,
      default: "mdi-briefcase-clock",
    },

    multiple: { type: Boolean, default: false },

    object: {
      type: [Array, Object],
    },

    size: {
      type: String,
      default: "md",
    },
  },

  computed: {
    actions() {
      return [
        {
          text: "Editer",
          icon: "mdi-pencil",
          path: "/satelease/affaires/edit/" + this.object.id,
          acl: "/satelease/affaires/edit",
          color: "",
          conditions: {
            multiple: false,
            isActif: true,
            objet: { contratLocation: null },
          },
        },

        {
          text: "Dupliquer",
          icon: "mdi-content-duplicate",
          path:
            "/satelease/affaires/add?fromAffaire=" + this.object.id,
          acl: "/satelease/affaires/add",
          color: "blue",
          conditions: {
            multiple: false,
            isActif: true,

          },
        },

        {
          text: "Ajouter une demande d'accord",
          icon: "mdi-cash-check",
          path: !Array.isArray(this.object)
            ? "/satelease/refinancement/demandeAccords/add/?affaire=" +
            this.object.id
            : "",
          acl: "/satelease/refinancement/demandeAccords/add",
          popup: true,
          color: "success",
          conditions: {
            multiple: false,
            isActif: true,
          },
        },

        {
          text: "Transformer en contrat",
          icon: "mdi-file-sign",
          path:
            "/satelease/contratsLocations/add?fromAffaire=" + this.object.id,
          acl: "/satelease/contratsLocations/add",
          color: "blue",
          conditions: {
            multiple: false,
            isActif: true,
            objet: { contratLocation: null },
          },
        },
        {
          text: "Supprimer",
          icon: "mdi-delete",
          method: "editIsActif",
          acl: "/satelease/affaires/deleted",
          color: "error",
          conditions: {
            multiple: true,
            isActif: true,
            objet: { contratLocation: null },
          },
        },
        {
          text: "Restauration",
          icon: "mdi-restore",
          method: "editIsActif",
          acl: "/satelease/affaires/edit",
          color: "",
          conditions: { multiple: true, isActif: false },
        },
        {
          text: "Supprimer définitivement",
          icon: "mdi-delete",
          method: "delete",
          acl: "/satelease/affaires/definitive-delete",
          color: "error",
          conditions: { multiple: false, isActif: false },
        },
        {
          text: "Visualiser",
          icon: "mdi-eye",
          path: "/satelease/affaires/view/" + this.object.id,
          acl: "/satelease/affaires/view",
          color: "",
          conditions: {},
        },
      ];
    },
  },

  created() {
    this.$root.$on("deleteAffaire", this.deleteAffaire);
    this.$root.$on("editAffaire", this.editAffaire);
  },

  methods: {
    /**
     * Supprimer une affaire grâce à son id
     */
    deleteAffaire(id) {
      this.$apollo
        .mutate({
          mutation: MUTATION_affaireDelete,
          variables: {
            id: id,
          },
        })
        .then(() => {
          this.$emit("fetch");
        });
    },

    /**
     * Fonction à avoir dans tous les composants Actions (infos à changer selon le composant)
     * Permet de lancer la popup de validation
     *
     * obj : l'objet a supprimé
     */
    delete(obj) {
      // infos qui s'afficheront dans le popup
      let toValid = {
        titre: "Suppression définitive d'une affaire",
        texte: `Êtes-vous sûr de vouloir supprimer définitivement l'affaire ${obj.numeroAffaire} ?`,
        type: "warning",
        toReturn: {
          function: "deleteAffaire", // cette fonction change en fonction du composant (ex: PersonneActions.vue ça serait deletePersonne,
          //qu'il faut créer à la place de deleteAffaire)
          arg: obj.id,
        },
      };

      // Envoi l'info pour ouvrir la popup
      this.$root.$emit("popupValidation", toValid);
    },

    /**
     * Fonction qui édite une affaire
     *
     * obj : l'objet à éditer
     */
    async editAffaire(obj) {
      // Si obj n'est pas un tableau alors on le transforme en tableau
      if (!Array.isArray(obj)) {
        obj = [obj];
      }
      // Selon le schéma, il est obligé d'avoir le siren et la raison sociale dans EntepriseInput
      // Bien vérifier dans le schéma si jamais il y a une erreur du style "$input etc.."
      this.$emit("loading");
      for (let item of obj) {
        let affaire = {
          id: item.id,
          numeroAffaire: item.numeroAffaire,
          isActif: !item.isActif,
        };
        await this.$apollo.mutate({
          mutation: MUTATION_affaireUpdate,
          variables: {
            input: affaire,
          },
        });
      }
      this.$emit("fetch");
    },

    /**
     * Fonction à avoir dans tous les composants (infos à changer)
     */
    editIsActif(obj) {
      // Si obj n'est pas un tableau alors on le transforme en tableau
      if (!Array.isArray(obj)) {
        obj = [obj];
      }
      let toValid = {
        titre: obj[0].isActif
          ? "Suppression d'affaire(s)"
          : "Restauration d'affaire(s)",
        texte: obj[0].isActif
          ? `Êtes-vous sûr de vouloir supprimer ${obj.length} affaire(s) ? ` +
          'Elles seront toujours présentes dans l\'onglet "Corbeille"'
          : `Êtes-vous sûr de vouloir restaurer ${obj.length} affaire(s) ?`,
        type: "warning",
        toReturn: {
          function: "editAffaire", // à changer selon le composant, comme deleteAffaire
          arg: obj,
        },
      };

      this.$root.$emit("popupValidation", toValid);
    },
  },
};
</script>
