<template>
  <ListLayout
    ref="list"
    :headers="headers"
    @fetch="init"
    @showMultipleActions="showMultipleActions = $event"
    title="Liste des enseignes"
  >
    <template v-slot:multipleActions>
      <EnseigneActions
        :multiple="true"
        :object="$refs.list ? $refs.list.itemsSelected : []"
        size="xl"
        v-show="showMultipleActions"
        @fetch="init"
        @loading="$refs.list.loading = true"
      />
    </template>
    <template v-slot:[`item.object`]="{ item }">
      <EnseigneObject :enseigneProp="item" size="xs" />
    </template>
    <template v-slot:[`item.etablissementCounter`]="{ item }">
      <EtablissementCounter :customSearch="[{ enseigne: item.id }]" />
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <EnseigneActions
        :object="item"
        size="xs"
        @fetch="init"
        @loading="$refs.list.loading = true"
      />
    </template>
  </ListLayout>
</template>

<script>
import EnseigneActions from "./EnseigneActions.vue";
import EnseigneObject from "./EnseigneObject.vue";
import EtablissementCounter from "./EtablissementsCounter.vue";
import ListLayout from "@/components/COMPONENTS-skeleton/Layouts/ListLayout.vue";

import { QUERY_enseignes } from "./graphql/queries";

export default {
  name: "EnseignesList",

  components: {
    EnseigneActions,
    EnseigneObject,
    EtablissementCounter,
    ListLayout,
  },

  data: () => ({
    // Recherche tapée par l'utilisateur dans le champ "Recherche"
    search: null,
    showMultipleActions: false,
    // Les colonnes ainsi que leur nom, id et options
    headers: [
      { text: "", value: "object", sortable: false },
      {
        text: "Etablissements",
        value: "etablissementCounter",
        sortable: false,
      },
      { text: "Fichiers", value: "fichiers" },
      { text: "Commentaires", value: "commentaires" },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),

  methods: {
    fetchEnseignes() {
      this.$refs.list.loading = true;
      this.$apollo
        .query({
          query: QUERY_enseignes,
          variables: {
            pagination: this.$refs.list.pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          this.$refs.list.items = data.enseignes;
          this.$refs.list.itemsCount = data.enseignesCount;
          this.$refs.list.loading = false;
        });
    },
    init() {
      this.fetchEnseignes();
    },
  },
};
</script>