<template>
  <ViewLayout :tabs="tabs" :item="user">
    <template v-slot:user>
      <UserActions :object="user" :withView="false" size="xl" />
      <v-row class="align-center mx-auto">
        <v-col cols="12">
          <UserObject :userProp="user" size="xl" />
        </v-col>
      </v-row>
    </template>
  </ViewLayout>
</template>

<script>
import { QUERY_user } from "./graphql/queries";
import UserObject from "@/components/COMPONENTS-authentification-habilitation/UserObject.vue";

import UserActions from "./UserActions.vue";
import ViewLayout from "@/components/COMPONENTS-skeleton/Layouts/ViewLayout.vue";
export default {
  name: "UserView",

  components: {
    UserObject,
    UserActions,
    ViewLayout,
  },
  data: () => ({
    user: {},
    tabs: [
      {
        icon: "mdi-account",
        displayName: "Utilisateur",
        name: "user",
      },
    ],
  }),
  apollo: {
    user: {
      query: QUERY_user,
      variables() {
        let idQuery = this.showPopup
          ? this.id
          : this.$route.params.id
          ? this.$route.params.id
          : this.id;
        return {
          id: idQuery,
        };
      },
    },
  },
  props: {
    showPopup: {
      type: Boolean,
    },
    id: {
      type: String,
    },
  },
};
</script>