<template>
  <SelectorLayout
    ref="selector"
    v-model="item"
    @fetch="fetch"
    :prepend-icon="prependIcon"
    :label="label"
    :addAction="addAction"
    :disabled="disabled"
  >
    <template v-slot:selection="data">
      <FournisseurObject
        :fournisseurProp="data.item"
        v-bind="data.attrs"
        :input-value="data.selected"
        @click="data.select"
        size="xs"
        action="none"
      />
    </template>

    <template v-slot:item="data">
      <FournisseurObject :fournisseurProp="data.item" size="xs" action="none" />
    </template>
  </SelectorLayout>
</template>

<script>
import { QUERY_fournisseurs } from "./graphql/queries";
import { QUERY_entreprisesID } from "@/components/COMPONENTS-annuaire/graphql/queries";

import FournisseurObject from "./FournisseurObject";
import SelectorLayout from "@/components/COMPONENTS-skeleton/Layouts/SelectorLayout.vue";

export default {
  name: "FournisseurSelector",

  components: {
    FournisseurObject,
    SelectorLayout,
  },

  data: () => ({
    item: null,
  }),

  props: {
    addAction: { type: String, default: "/devis-facture/fournisseurs/add" },
    disabled: { type: Boolean, default: false },
    errorMessage: { type: String },
    label: { type: String, default: "Fournisseur" },
    multiple: { type: Boolean, default: false },
    prependIcon: { type: String, default: "mdi-truck" },
    value: null,
  },

  mounted() {
    this.item = this.value && this.value.id ? this.value.id : this.value;
    this.fetch();
  },

  methods: {
    // Fetch les fournisseurs en fonction du filtre
    fetch() {
      this.$refs.selector.loading = true;
      let pagination = JSON.parse(
        JSON.stringify(this.$refs.selector.pagination)
      );
      pagination.limit = 0;

      this.$apollo
        .query({
          query: QUERY_entreprisesID,
          variables: {
            pagination: pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          let entreprisesID = [];
          __.data.entreprises.forEach((elem) => {
            entreprisesID.push(elem.id);
          });

          let paginationFournisseurs = JSON.parse(JSON.stringify(pagination));
          // pagination limit à 0 = renvoie de l'ensemble des résultats (5 par défaut)
          paginationFournisseurs.limit = 0;
          if (entreprisesID[0]) {
            paginationFournisseurs.filter.toFind = "";
            paginationFournisseurs.filter.customSearch.push({
              entreprise: entreprisesID,
            });
          }

          if(this.item) paginationFournisseurs.filter.customSearch.push({ _id: this.item });

          this.$apollo
            .query({
              query: QUERY_fournisseurs,
              variables: {
                pagination: paginationFournisseurs,
              },
              fetchPolicy: "no-cache",
            })
            .then((__) => {
              const data = __.data;
              this.$refs.selector.items = data.fournisseurs;
              this.$refs.selector.loading = false;
            });
        });
    },
  },

  watch: {
    value: {
      deep: true,
      handler(val) {
        this.item = val && val.id ? val.id : val;
        this.fetch();
      },
    },

    item: {
      deep: true,
      handler(val) {
        if (val === null) {
          this.$emit("input", null);
        } else {
          this.$emit("input", val);
        }
      },
    },
  },
};
</script>