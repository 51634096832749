<template>
  <ListLayout
    ref="list"
    title="Liste des modèles"
    :headers="headers"
    :noFilter="noFilter"
    @fetch="init"
    @showMultipleActions="showMultipleActions = $event"
  >
    <template v-slot:multipleActions>
      <ModeleActions
        :multiple="true"
        :withView="false"
        :object="$refs.list ? $refs.list.itemsSelected : []"
        size="xl"
        v-show="showMultipleActions"
        @fetch="init"
        @loading="$refs.list.loading = true"
      />
    </template>
    <template v-slot:customFilters>
      <MarqueSelector v-model="customFilters.marque" :addAction="null" />
    </template>
    <template v-slot:[`item.modele`]="{ item }">
      <ModeleObject :modeleProp="item" size="xs" />
    </template>

    <template v-slot:[`item.marque`]="{ item }">
      <MarqueObject :marqueProp="item.marque" size="xs" />
    </template>
    <template v-slot:[`item.assetsCount`]="{ item }">
      <AssetsCounter :customSearch="[{ modele: item.id }]" />
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <ModeleActions
        :isActif="$refs.list.itemsAreActive"
        :object="item"
        size="xs"
        @fetch="init"
        @loading="$refs.list.loading = true"
      />
    </template>
  </ListLayout>
</template>

<script>
import ListLayout from "@/components/COMPONENTS-skeleton/Layouts/ListLayout.vue";
import ModeleActions from "./ModeleActions.vue";
import { QUERY_modeles } from "./graphql/queries";
import MarqueObject from "./MarqueObject.vue";
import MarqueSelector from "./MarqueSelector.vue";
import AssetsCounter from "./AssetsCounter.vue";
import ModeleObject from "./ModeleObject.vue";
export default {
  name: "ModelesList",

  components: {
    MarqueObject,
    ModeleObject,
    ListLayout,
    ModeleActions,
    AssetsCounter,
    MarqueSelector,
  },

  props: {
    marqueID: null,
    noFilter: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data: () => ({
    customFilters: {
      marque: null,
    },

    headers: [
      { text: "Modèle", value: "modele", sortable: false },
      { text: "Marque", value: "marque" },
      { text: "Assets", value: "assetsCount" },
      { text: "Fichiers", value: "fichiers" },
      { text: "Commentaires", value: "commentaires" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    search: null,
    showMultipleActions: false,
  }),
  methods: {
    fetchModeles() {
      this.$refs.list.items = [];
      this.$refs.list.loading = true;
      let pagination = JSON.parse(JSON.stringify(this.$refs.list.pagination));

      if (this.marqueID) {
        pagination.filter.customSearch.push({ marque: this.marqueID });
      } else if (this.customFilters.marque) {
        pagination.filter.customSearch.push({
          marque: this.customFilters.marque,
        });
      }

      this.$apollo
        .query({
          query: QUERY_modeles,
          variables: {
            pagination: pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          this.$refs.list.items = data.modeles;
          this.$refs.list.itemsCount = data.modelesCount;
          this.$refs.list.loading = false;
        });
    },

    init() {
      this.fetchModeles();
    },
  },
  watch: {
    customFilters: {
      deep: true,
      handler() {
        this.$refs.list.currentPage = 1;
        this.fetchModeles();
      },
    },
    marqueID() {
      this.fetchModeles();
    },
  },
};
</script>