<template>
  <FormLayout
    icon="mdi-domain"
    @ajouter="ajouter"
    typeItem="usurpation"
  >
    <v-row justify="center">
      <v-col cols="6">
        <v-textarea
          v-model="input.motif"
          :error-messages="formsErrors.usurpationMotifManquant"
          label="Motif d'utilisation du mode miroir"
          prepend-icon="mdi-pen"
        />
      </v-col>
    </v-row>
  </FormLayout>
</template>

<script>
import { mapState } from "vuex";
import { onUsurpationLogin } from "@/components/COMPONENTS-skeleton/vue-apollo";
import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";

import { QUERY_userLoginUsurpationByHabilitation } from "./graphql/queries";

export default {
  name: "UsurpationForm",

  components: {
    FormLayout,
  },

  data: () => ({
    input: {
      motif: ""
    }
  }),

  computed: {
    ...mapState(["formsErrors"]),
    idHabilitation() {
      return this.$route.params.idHabilitation;
    }
  },

  methods: {
    ajouter() {
      this.$apollo
        .mutate({
          mutation: QUERY_userLoginUsurpationByHabilitation,
          variables: {
            id: this.idHabilitation,
            motif: this.input.motif
          },
          fetchPolicy: "no-cache",
        })
        .then(async (data) => {
          let token = data.data.userLoginUsurpationByHabilitation.token;
          await onUsurpationLogin(this.$apollo.provider.defaultClient, token);
          this.$router.push({ path: "/" }).catch(() => {});
        });
    },
  },
};
</script>