<template>
  <v-bottom-navigation
    v-model="value"
    grow
    fixed
    color="primary"
    app
    v-if="item.path"
  >
    <v-btn :to="item.path">
      <span>{{ item.title }}</span>
      <v-icon>{{ item.icon }}</v-icon>
    </v-btn>
    <template v-for="(item, index) in item.subMenuItems">
      <v-btn
        :key="`subMenuItem-${index}`"
        :value="item.path"
        :to="item.path"
        v-if="acl(item.acl)"
      >
        <span>{{ item.title }}</span>
        <v-icon>{{ item.icon }}</v-icon>
      </v-btn>
    </template>
    <v-btn @click="goBack">
      <span>Retour</span>
      <v-icon>mdi-arrow-left-circle</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
export default {
  name: "MainSubMenu",
  data: function () {
    return {
      value: this.$route.path,
      item: {},
    };
  },
  watch: {},
  methods: {
    goBack: function () {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.v-btn {
  height: inherit !important;
}
</style>