import gql from 'graphql-tag';
import {
  AFFAIRE_FIELDS,
  BAILLEUR_FIELDS,
  CONTRAT_LOCATION_FIELDS,
  DEMANDE_ACCORD_FIELDS,
  DEMANDE_PARTENAIRE_FIELDS,
  ENVELOPPE_THEORIQUE_FIELDS,
  PARTENAIRE_FIELDS
} from './fragments';

export const MUTATION_affaireCreate = gql`
mutation($input: AffaireInput!){
  affaireCreate(input:$input){
    ...AffaireFields
  }
}${AFFAIRE_FIELDS}
`;

export const MUTATION_affaireUpdate = gql`
mutation($input:AffaireInput!){
  affaireUpdate(input:$input){
    ...AffaireFields
  }
}${AFFAIRE_FIELDS}`

export const MUTATION_affaireDelete = gql`
mutation($id:ID!){
  affaireDelete(id:$id) {
    ...AffaireFields
  }
}${AFFAIRE_FIELDS}
`;

export const MUTATION_bailleurCreate = gql`
mutation($input: BailleurInput!){
  bailleurCreate(input:$input){
    ...BailleurFields
  }
}${BAILLEUR_FIELDS}
`;

export const MUTATION_bailleurUpdate = gql`
mutation($input: BailleurInput!){
  bailleurUpdate(input:$input){
    ...BailleurFields
  }
}${BAILLEUR_FIELDS}
`;

export const MUTATION_bailleurDelete = gql`
mutation($id:ID!){
  bailleurDelete(id:$id) {
    ...BailleurFields
  }
}${BAILLEUR_FIELDS}
`;


export const MUTATION_contratLocationCreate = gql`
mutation($input: ContratLocationInput!){
  contratLocationCreate(input:$input){
    ...ContratLocationFields
  }
}${CONTRAT_LOCATION_FIELDS}
`;

export const MUTATION_contratLocationUpdate = gql`
mutation($input:ContratLocationInput!){
  contratLocationUpdate(input:$input){
    ...ContratLocationFields
  }
}${CONTRAT_LOCATION_FIELDS}`

export const MUTATION_contratLocationDelete = gql`
mutation($id:ID!){
  contratLocationDelete(id:$id){
    ...ContratLocationFields
  }
}${CONTRAT_LOCATION_FIELDS}
`;

export const MUTATION_contratLocationUnlock = gql`
  mutation($id: ID!) {
    contratLocationUnlock(id: $id) {
      ...ContratLocationFields
    }
  }${CONTRAT_LOCATION_FIELDS}
`;

export const MUTATION_contratLocationValidate = gql`
mutation($input:ContratLocationValidateInput!){
  contratLocationValidate(input:$input){
    ...ContratLocationFields
  }
}${CONTRAT_LOCATION_FIELDS}
`;

export const MUTATION_demandeAccordCreate = gql`
mutation($input:DemandeAccordInput!){
  demandeAccordCreate(input:$input){
    ...DemandeAccordFields
  }
}${DEMANDE_ACCORD_FIELDS}
`;

export const MUTATION_demandeAccordUpdate = gql`
mutation($input:DemandeAccordInput!){
  demandeAccordUpdate(input:$input){
    ...DemandeAccordFields
  }
}${DEMANDE_ACCORD_FIELDS}
`;

export const MUTATION_demandeAccordDelete = gql`
  mutation($id: ID!) {
    demandeAccordDelete(id: $id) {
      ...DemandeAccordFields
    }
  }${DEMANDE_ACCORD_FIELDS}
`;

export const MUTATION_demandePartenaireCreate = gql`
mutation($input:DemandePartenaireInput!){
  demandePartenaireCreate(input:$input){
    ...DemandePartenaireFields
  }
}${DEMANDE_PARTENAIRE_FIELDS}
`;

export const MUTATION_demandePartenaireUpdate = gql`
mutation($input:DemandePartenaireInput!){
  demandePartenaireUpdate(input:$input){
    ...DemandePartenaireFields
  }
}${DEMANDE_PARTENAIRE_FIELDS}
`;

export const MUTATION_demandePartenaireDelete = gql`
  mutation($id: ID!){
    demandePartenaireDelete(id: $id){
      ...DemandePartenaireFields
    }
  }${DEMANDE_PARTENAIRE_FIELDS}
`;

export const MUTATION_enveloppeTheoriqueCreate = gql`
mutation($input:EnveloppeTheoriqueInput!){
  enveloppeTheoriqueCreate(input:$input){
    ...EnveloppeTheoriqueFields
  }
}${ENVELOPPE_THEORIQUE_FIELDS}
`;

export const MUTATION_enveloppeTheoriqueUpdate = gql`
mutation($input:EnveloppeTheoriqueInput!){
  enveloppeTheoriqueUpdate(input:$input){
    ...EnveloppeTheoriqueFields
  }
}${ENVELOPPE_THEORIQUE_FIELDS}
`;

export const MUTATION_enveloppeTheoriqueDelete = gql`
  mutation($id: ID!) {
    enveloppeTheoriqueDelete(id: $id) {
      ...EnveloppeTheoriqueFields
    }
  }${ENVELOPPE_THEORIQUE_FIELDS}
`;


export const MUTATION_partenaireCreate = gql`
mutation($input: PartenaireInput!){
  partenaireCreate(input:$input){
    ...PartenaireFields
  }
}${PARTENAIRE_FIELDS}
`;

export const MUTATION_partenaireUpdate = gql`
mutation($input: PartenaireInput!){
  partenaireUpdate(input:$input){
    ...PartenaireFields
  }
}${PARTENAIRE_FIELDS}
`;

export const MUTATION_partenaireDelete = gql`
mutation($id:ID!){
  partenaireDelete(id:$id) {
    ...PartenaireFields
  }
}${PARTENAIRE_FIELDS}
`;


export const MUTATION_projetSateLeaseOrganisationParametresUpdate = gql`
  mutation($input:ProjetSateLeaseOrganisationParametresInput){
    projetSateLeaseOrganisationParametresUpdate(input:$input){
      id
      fraisDossierPourcentage
      fraisDossierMinimum
    }
  }
`;