<template>
  <ViewLayout :tabs="tabs" :item="lienService">
    <template v-slot:lienService>
      <LienServiceActions :object="lienService" :withView="false" size="xl" />
      <v-row class="align-center mx-auto">
        <v-col cols="12">
          <LienServiceObject
            size="xl"
            :lienServiceProp="lienService"
            v-if="lienService.id"
          />
        </v-col>
        <!-- <v-col>
          <LienServiceHistorique />
        </v-col> -->
      </v-row>
    </template>
  </ViewLayout>
</template>

<script>
import { QUERY_lienService } from "./graphql/queries";
import LienServiceObject from "./LienServiceObject.vue";
import LienServiceActions from "./LienServiceActions.vue";
// import LienServiceHistorique from "./LienServiceHistorique.vue";
import ViewLayout from "@/components/COMPONENTS-skeleton/Layouts/ViewLayout.vue";
export default {
  name: "LienServiceView",
  components: {
    LienServiceObject,
    LienServiceActions,
    // LienServiceHistorique,
    ViewLayout,
  },

  data: () => ({
    lienService: {},
    tabs: [
      {
        icon: "mdi-link-box",
        displayName: "Lien de services",
        name: "lienService",
      },
      { name: "fichiers" },
    ],
  }),

  apollo: {
    lienService: {
      query: QUERY_lienService,
      variables() {
        let idQuery = this.showPopup
          ? this.id
          : this.$route.params.id
          ? this.$route.params.id
          : this.id;
        return {
          id: idQuery,
        };
      },
    },
  },
  props: {
    showPopup: {
      type: Boolean,
    },
    id: {
      type: String,
    },
  },
  computed: {},
};
</script>