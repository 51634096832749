<template>
  <ViewLayout :tabs="tabs" :item="devisClient" v-if="devisClient.id">
    <template v-slot:devisClient>
      <DevisClientActions :object="devisClient" :withView="false" size="xl" />
      <v-row class="align-center mx-auto">
        <v-col>
          <DevisClientObject size="xl" :devisClientProp="devisClient" />
        </v-col>
      </v-row>
    </template>
  </ViewLayout>
</template>

<script>
import { QUERY_devisClient } from "./graphql/queries";

import DevisClientObject from "./DevisClientObject.vue";
import DevisClientActions from "./DevisClientActions.vue";
import ViewLayout from "@/components/COMPONENTS-skeleton/Layouts/ViewLayout.vue";

export default {
  name: "DevisClientView",

  components: {
    DevisClientObject,
    DevisClientActions,
    ViewLayout,
  },

  props: {
    id: {
      type: String,
    },

    showPopup: {
      type: Boolean,
    },
  },

  data: () => ({
    devisClient: {},
    tabs: [
      { icon: "mdi-file-table", displayName: "Devis", name: "devisClient" },
      { name: "commentaires" },
      { name: "fichiers" },
    ],
  }),

  apollo: {
    devisClient: {
      query: QUERY_devisClient,
      variables() {
        let idQuery = this.showPopup
          ? this.id
          : this.$route.params.id
          ? this.$route.params.id
          : this.id;
        return {
          id: idQuery,
        };
      },
    },
  },
};
</script>