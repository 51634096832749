<template>
  <v-row>
    <v-col>
      <ContratLocationObject size="xl" :contratLocationID="$route.params.id" />
    </v-col>
    <v-col>
      <FormLayout
        icon="mdi-file-table"
        @modifier="valider"
        buttonIcon="mdi-check-bold"
        buttonText="Valider"
        @reinitialiser="init"
        :item="input"
        :hasResetButton="false"
        titre="Valider un contrat"
        :disabled="!inputIsValid"
      >
        <v-row>
          <v-col>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Points de contrôle</th>
                    <th class="text-left">Résultats</th>
                    <th class="text-left">Informations</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="test of tests">
                    <tr :key="test.value">
                      <td>{{ test.label }}</td>
                      <td>
                        <template v-if="test.type == 'control'">
                          <v-icon color="success" v-if="test.state == true">
                            mdi-check
                          </v-icon>
                          <v-icon color="error" v-if="test.state == false">
                            mdi-alert
                          </v-icon>
                          <v-icon color="info" v-if="test.state == undefined">
                            mdi-help
                          </v-icon>
                        </template>
                        <template v-if="test.type == 'userCheckBox'">
                          <v-checkbox v-model="test.state"></v-checkbox>
                        </template>
                      </td>
                      <td>
                        <p v-for="message of test.messages" :key="message">
                          {{ message }}
                        </p>
                      </td>
                    </tr>
                    <v-simple-table
                      v-if="test.children && test.state == true"
                      :key="'children' + test.value"
                      class="pl-4"
                    >
                      <tbody>
                        <template v-for="childTest of test.children">
                          <tr :key="childTest.value">
                            <td>{{ childTest.label }}</td>
                            <td>
                              <template v-if="childTest.type == 'control'">
                                <v-icon
                                  color="success"
                                  v-if="childTest.state == true"
                                >
                                  mdi-check
                                </v-icon>
                                <v-icon
                                  color="error"
                                  v-if="childTest.state == false"
                                >
                                  mdi-alert
                                </v-icon>
                                <v-icon
                                  color="info"
                                  v-if="childTest.state == undefined"
                                >
                                  mdi-help
                                </v-icon>
                              </template>
                              <template v-if="childTest.type == 'userCheckBox'">
                                <v-checkbox v-model="childTest.state" />
                              </template>
                            </td>
                            <td></td>
                          </tr>
                        </template>
                      </tbody>
                    </v-simple-table>
                  </template>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <DateInput
              v-model="input.dateContrat"
              label="Date du contrat"
              :min="contratLocationDateDernierContrat"
              :max="contratLocationDateMax"
            />
          </v-col>
        </v-row>
      </FormLayout>
    </v-col>
  </v-row>
</template>

<script>
import dayjs from "dayjs";
import { mapState } from "vuex";

import { MUTATION_contratLocationValidate } from "./graphql/mutations";
import {
  QUERY_contratLocation,
  QUERY_contratLocationDateDernierContrat,
} from "./graphql/queries";

import DateInput from "@/components/COMPONENTS-skeleton/Inputs/DateInput";
import ContratLocationObject from "./ContratLocationObject.vue";
import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";

export default {
  name: "ContratLocationValidateForm",

  components: {
    DateInput,
    ContratLocationObject,
    FormLayout,
  },

  props: {
    componentParent: undefined,
  },

  data: () => ({
    contratLocation: {},
    contratLocationDateDernierContrat: null,
    contratLocationDateMax: dayjs().format("YYYY-MM-DD"),
    input: {},
    tests: [
      {
        value: "locataire",
        label: "Locataire",
        state: undefined,
        messages: [],
        type: "control",
      },
      {
        value: "signataireClient",
        label: "Signataire Client",
        state: undefined,
        messages: [],
        type: "control",
        children: [
          {
            value: "signataireClientLienLocataire",
            label:
              "Je me suis assuré que le signataire ait le droit de contractualiser au nom de la société locataire",
            state: undefined,
            messages: [],
            type: "userCheckBox",
          },
        ],
      },

      {
        value: "cession",
        label: "Information de cession",
        state: true,
        messages: [],
        type: "control",
      },
      {
        value: "devisFacture",
        label: "Devis et factures à financer",
        state: true,
        messages: [],
        type: "control",
      },
      {
        value: "loyers",
        label: "Loyers",
        state: true,
        messages: [],
        type: "control",
      },
      {
        value: "facturation",
        label: "Information de facturation",
        state: true,
        messages: [],
        type: "control",
      },
    ],
  }),

  computed: {
    ...mapState(["formsErrors"]),
    inputIsValid() {
      let valid = true;
      this.tests.forEach((test) => {
        if (test.children) {
          test.children.forEach((child) => {
            if (!child.state) {
              valid = false;
            }
          });
        }
        if (!test.state) {
          valid = false;
        }
      });
      return valid;
    },
  },

  watch: {
    // En cas de passage de la vue edit à add par exemple
    $route() {
      this.init();
    },
  },

  mounted: function () {
    this.init();
  },

  methods: {
    async init() {
      let id = this.$route.params.id;
      // On récupère la date de la dernière facture valide
      this.$apollo
        .query({
          query: QUERY_contratLocationDateDernierContrat,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          this.contratLocationDateDernierContrat =
            data.data.contratLocationDateDernierContrat;
        });

      this.$apollo
        .query({
          query: QUERY_contratLocation,
          variables: {
            id: id,
          },
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          let contratLocation = data.data.contratLocation;
          contratLocation.dateContrat = dayjs();
          this.input = JSON.parse(JSON.stringify(contratLocation));
          this.passTest();
        });
    },

    inputConverter(arg) {
      const obj = {
        ...arg,
      };
      if (Object.keys(obj).length == 0) {
        return "";
      }

      let input = {
        id: obj.id,
        dateContrat: obj.dateContrat,
      };
      return input;
    },

    passTest() {
       

      this.tests.forEach((test) => {
        switch (test.value) {
          case "locataire":
            if (this.input.locataire) {
              test.state = true;
            }

            break;
          case "signataireClient":
            if (this.input.signataireClient) {
              if (
                !this.input.signataireClient.adressesMails ||
                !this.input.signataireClient.adressesMails[0]
              ) {
                test.state = false;
                test.messages.push("Le signataire n'a pas d'adresse mail");
              }
              if (
                !this.input.signataireClient.numerosTelephone ||
                !this.input.signataireClient.numerosTelephone[0]
              ) {
                test.state = false;
                test.messages.push(
                  "Le signataire n'a pas de numéro de téléphone"
                );
              }
              if (test.state == undefined) {
                test.state = true;
              }
            } else {
              test.state = false;
              test.messages.push("Le signataire n'est pas défini");
            }
            break;
        }
      });
    },

    valider() {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_contratLocationValidate,
          variables: {
            input: toSend,
          },
        })
        .then(() => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "Le contrat est validé, il n'est plus possible de le modifier",
          });
        });
    },
  },
};
</script>