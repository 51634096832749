<template>
  <div>CompteComptableView</div>
</template>

<script>
export default {
  name: "CompteComptableView",
};
</script>

