<template>
  <div>
    <template v-for="(item, index) in accordEtapeItems">
      <div :key="index">
        <v-card class="mx-auto mb-6" max-width="80%">
          <v-card-title class="primary white--text py-2">
            Etape {{ index + 1 }}
            <v-row class="text-end">
              <v-col class="mt-n10 mr-n6">
                <v-btn
                  @click="moveItem('down', index)"
                  fab
                  x-small
                  dark
                  color="info"
                  class="ml-2"
                  v-if="
                    accordEtapeItems.length > 0 &&
                    index + 1 < accordEtapeItems.length
                  "
                >
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>

                <v-btn
                  @click="moveItem('up', index)"
                  fab
                  x-small
                  dark
                  color="info"
                  class="ml-2"
                  v-if="index > 0 && accordEtapeItems.length > 1"
                >
                  <v-icon>mdi-chevron-up</v-icon>
                </v-btn>

                <v-btn
                  @click="deleteAccordEtape(index)"
                  fab
                  x-small
                  dark
                  color="error"
                  class="ml-2"
                  v-if="index > 0 && accordEtapeItems.length > 1"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="pt-4">
            <AccordEtapeForm
              v-model="accordEtapeItems[index]"
              :type-accord="typeAccord"
              :destinataire-a-specifie="
                accordEtapeItems[index - 1] &&
                accordEtapeItems[index - 1].type ==
                  'DOCUSIGN_SPECIFIER_DESTINATAIRE'
                  ? true
                  : false
              "
            />
          </v-card-text>
        </v-card>

        <v-hover v-slot="{ hover }">
          <v-row class="fill-height">
            <v-col />
            <v-col @click="addAccordEtape(index)" class="clickable">
              <v-icon class="d-flex justify-center" v-if="hover">
                mdi-plus-circle
              </v-icon>
              <v-icon class="d-flex justify-center" v-else>
                mdi-dots-vertical
              </v-icon>
            </v-col>
            <v-col />
          </v-row>
        </v-hover>

        <v-row
          class="fill-height mb-2"
          v-if="index + 1 < accordEtapeItems.length"
        >
          <v-col />
          <v-col cols="2" class="d-flex justify-center">
            <v-select :items="nextAndItems" v-model="item.nextAnd" dense>
              <template v-slot:selection="{ item }">
                <span class="d-flex justify-center" style="width: 100%">
                  {{ item.text }}
                </span>
              </template>
            </v-select>
          </v-col>
          <v-col />
        </v-row>
        <v-row class="fill-height" v-else>
          <v-col />
          <v-col>
            <p class="d-flex justify-center overline">FIN</p>
          </v-col>
          <v-col />
        </v-row>
      </div>
    </template>
  </div>
</template>

<script>
import AccordEtapeForm from "./AccordEtapeForm.vue";

export default {
  name: "AccordEtapeAttach",

  components: {
    AccordEtapeForm,
  },

  props: { typeAccord: undefined, value: null },

  data: () => ({
    accordEtapeItems: [],
    accordEtapes: [],
    nextAndItems: [
      { text: "PUIS", value: true },
      { text: "OU", value: false },
    ],
  }),

  mounted() {
    this.accordEtapeItems = this.value;
  },

  methods: {
    addAccordEtape(index) {
       
      this.accordEtapeItems.splice(index + 1, 0, {
        nextAnd: true,
        destinataire: {
          nom: "",
          email: "",
          personneAssociee: null,
          nextAnd: true,
        },
      });
    },

    deleteAccordEtape(index) {
       
      this.accordEtapeItems.splice(index, 1);
    },

    moveItem: function (sens, itemIndex) {
       
      let tableau = [...this.accordEtapeItems];
      let to = sens == "up" ? itemIndex - 1 : itemIndex + 1;
      tableau.splice(to, 0, tableau.splice(itemIndex, 1)[0]);

      this.accordEtapeItems = tableau;
    },
  },
  watch: {
    value(val) {
      this.accordEtapeItems = val;
    },

    accordEtapeItems: {
      deep: true,
      handler(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>