<template>
  <SelectorLayout
    :addAction="addAction"
    :disabled="disabled"
    @fetch="fetch"
    :label="label"
    :prepend-icon="prependIcon"
    ref="selector"
    v-model="item"
    :multiple="multiple"
  >
    <template v-slot:selection="data">
      <MarqueObject
        :marqueProp="data.item"
        v-bind="data.attrs"
        :input-value="data.selected"
        @click="data.select"
        size="xs"
        action="none"
      />
    </template>

    <template v-slot:item="data">
      <MarqueObject :marqueProp="data.item" size="xs" action="none" />
    </template>
  </SelectorLayout>
</template>

<script>
import { QUERY_marques } from "./graphql/queries";

import MarqueObject from "./MarqueObject.vue";
import SelectorLayout from "@/components/COMPONENTS-skeleton/Layouts/SelectorLayout.vue";

export default {
  name: "MarqueSelector",

  components: {
    MarqueObject,
    SelectorLayout,
  },

  data: () => ({
    item: null,
  }),

  props: {
    addAction: { type: String, default: "/assets/marques/add" },
    disabled: { type: Boolean, default: false },
    errorMessage: { type: String },
    label: { type: String, default: "Marques" },
    multiple: { type: Boolean, default: false },
    prependIcon: { type: String, default: "mdi-material-design" },
    value: null,
  },

  methods: {
    // Fetch les marques en fonction du filtre
    fetch() {
      this.$refs.selector.loading = true;
      let pagination = JSON.parse(JSON.stringify(this.$refs.selector.pagination))
      if(this.item) pagination.filter.customSearch.push({ _id: this.item })
      this.$apollo
        .query({
          query: QUERY_marques,
          variables: {
            pagination
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          this.$refs.selector.items = data.marques;
          this.$refs.selector.loading = false;
        });
    },
  },

  watch: {
    value: {
      deep: true,
      handler(val) {
        this.item = val && val.id ? val.id : val;
        this.fetch();
      },
    },

    item: {
      deep: true,
      handler(val) {
        if (val === null) {
          this.$emit("input", null);
        } else {
          this.$emit("input", val);
        }
      },
    },
  },
};
</script>