<template>
  <v-container class="fluid">
    {{ fichier }}
    <FichierActions :object="fichier" size="xl" :withView="false" />
    <v-row class="align-center mx-auto">
      <v-col cols="12">
        <FichierObject :fichierProp="fichier" size="xl" />
      </v-col>

      <v-col cols="12" xl="6" lg="6" md="12" class="order-2">
        <CommentairesObject typeParent="Fichier" :idParent="fichier.id" />
      </v-col>
      <v-col> <FichierPlayer :objFichier="fichier" /> </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { QUERY_fichier } from "./graphql/queries";
import FichierActions from "./FichierActions.vue";
import FichierObject from "./FichierObject.vue";
import FichierPlayer from "./FichierPlayer.vue";

import CommentairesObject from "@/components/COMPONENTS-collaboration/CommentairesObject.vue";

export default {
  name: "FichierView",
  components: {
    // Pour utilisation dans composant enfant similaire composant parent
    //App
    // MyComponent
    //  ADifferentComponent
    //    MyComponent
    //https://stackoverflow.com/questions/49154490/did-you-register-the-component-correctly-for-recursive-components-make-sure-to

    FichierActions,
    FichierObject,
    FichierPlayer,
    CommentairesObject,
  },
  data: () => ({
    fichier: {},
  }),
  apollo: {
    fichier: {
      query: QUERY_fichier,
      variables() {
        let idQuery = this.showPopup
          ? this.id
          : this.$route.params.id
          ? this.$route.params.id
          : this.id;
        return {
          id: idQuery,
        };
      },
    },
  },
  props: {
    showPopup: {
      type: Boolean,
    },
    id: {
      type: String,
    },
  },
};
</script>

