<template>
  <div>
    <SelectorLayout
      ref="selector"
      v-model="item"
      @fetch="fetch"
      :prepend-icon="prependIcon"
      :multiple="multiple"
      :label="label"
      :addAction="addAction"
    >
      <template v-slot:selection="data">
        <UserObject
          :userProp="data.item"
          size="xs"
          v-bind="data.attrs"
          :input-value="data.selected"
          @click="data.select"
          action="none"
        />
      </template>
      <template v-slot:item="data">
        <UserObject :userProp="data.item" size="xs" action="none" />
      </template>
    </SelectorLayout>
  </div>
</template>

<script>
import { QUERY_users } from "./graphql/queries";
import SelectorLayout from "@/components/COMPONENTS-skeleton/Layouts/SelectorLayout.vue";
import UserObject from "./UserObject.vue";
export default {
  name: "UserSelector",
  components: {
    SelectorLayout,
    UserObject,
  },
  data: () => ({
    item: null,
  }),
  props: {
    addAction: { type: String, default: "/authentification-habilitation/users/add" },
    value: null,
    errorMessage: { type: String },
    label: { type: String, default: "Utilisateur" },
    prependIcon: { type: String, default: "mdi-account-multiple" },
    multiple: { type: Boolean, default: false },
    roleFilter: { type: String },
  },
  methods: {
    fetch() {
      this.$refs.selector.loading = true;
      this.$apollo
        .query({
          query: QUERY_users,
          variables: {
            pagination: this.$refs.selector.pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          this.$refs.selector.items = data.users;
          this.$refs.selector.loading = false;
        });
    },
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        this.item = val && val.id ? val.id : val;
        this.fetch();
      },
    },

    item: {
      deep: true,
      handler(val) {
        if (val === null) {
          this.$emit("input", null);
        } else {
          this.$emit("input", val);
        }
      },
    },
  },
};
</script>

