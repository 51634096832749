<template>
  <div>
    <template v-if="disposition == 'default'">
      <v-card class="mx-auto" max-width="100%" min-height="300">
        <v-form ref="form" @submit.prevent="validForm">
          <v-card-title class="primary white--text rounded py-2" v-if="showTitle">
            <v-icon class="mr-2 white--text">
              <template v-if="buttonIcon">{{ buttonIcon }}</template>
              <template v-else>
                {{ item && item.id ? "mdi-pencil" : "mdi-plus-circle" }}
              </template>
            </v-icon>
            <template v-if="titre">{{ titre }}</template>
            <template v-else>
              {{ item && item.id ? "Editer" : "Ajouter" }} {{ typeItem }}
            </template>
          </v-card-title>

          <v-card-text class="pt-4">
            <v-card-subtitle v-if="subTitle" class="justify-center py-6 pl-8 text-subtitle-1">
              <template>{{ subTitle }}</template>
            </v-card-subtitle>
            <div class="decoFormsIcons"
              style="
                                                                                                                                                                                                                                                                                position: absolute;
                                                                                                                                                                                                                                                                                bottom: 25px;
                                                                                                                                                                                                                                                                                right: 25px;
                                                                                                                                                                                                                                                                                opacity: 0.2;
                                                                                                                                                                                                                                                                              ">
              <v-icon style="font-size: 120px">
                {{ icon ? icon : "mdi-widgets" }}
              </v-icon>
            </div>
            <div class="decoFormsIcons" style="position: absolute; bottom: 5px; right: 5px; opacity: 0.7">
              <v-icon style="font-size: 80px">
                <template v-if="buttonIcon">{{ buttonIcon }}</template>
                <template v-else>
                  {{ item && item.id ? "mdi-pencil" : "mdi-plus" }}
                </template>
              </v-icon>
            </div>

            <v-row class="mt-2" v-if="fromOCR">
              <v-col align="center" justify="center">
                <v-btn color="primary" @click="inputFromPhoto" class="mx-2" v-if="hasResetButton && !hideAllButtons"
                  :disabled="true">
                  <v-icon class="mr-2">mdi-file</v-icon>A partir d'un fichier
                </v-btn>
              </v-col>
              <v-col align="center" justify="center">
                <v-btn color="primary" @click="inputFromPhoto" class="mx-2" v-if="hasResetButton && !hideAllButtons">
                  <v-icon class="mr-2">mdi-camera</v-icon>A partir d'une photo
                </v-btn>
              </v-col>
            </v-row>
            <slot></slot>
            <slot name="editionOnly" v-if="item && item.id"></slot>
          </v-card-text>

          <v-row class="mt-2">
            <v-col align="center" justify="center">
              <v-btn v-if="!hideAllButtons" type="submit" :color="item && item.id ? 'info' : 'primary'" class="mx-2"
                :disabled="disabled">
                <v-icon class="mr-2">
                  <template v-if="buttonIcon">{{ buttonIcon }}</template>
                  <template v-else>
                    {{ item && item.id ? "mdi-pencil" : "mdi-plus" }}
                  </template>
                </v-icon>
                <template v-if="buttonText">{{ buttonText }}</template>
                <template v-else>
                  {{ item && item.id ? "Modifier" : "Ajouter" }}
                </template>
              </v-btn>
              <v-btn color="warning" @click="reinitialiser" class="mx-2" v-if="hasResetButton && !hideAllButtons">
                <v-icon class="mr-2">mdi-reload</v-icon>Réinitialiser
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </template>
    <template v-if="disposition == 'inside'">
      <v-form ref="form" @submit.prevent="validForm">
        <slot />

        <v-row class="mt-2">
          <v-col align="center" justify="center">
            <v-btn v-if="!hideAllButtons" type="submit" :color="item && item.id ? 'info' : 'primary'" class="mx-2"
              :disabled="disabled">
              <v-icon class="mr-2">
                <template v-if="buttonIcon">{{ buttonIcon }}</template>
                <template v-else>
                  {{ item && item.id ? "mdi-pencil" : "mdi-plus" }}
                </template>
              </v-icon>
              <template v-if="buttonText">{{ buttonText }}</template>
              <template v-else>
                {{ item && item.id ? "Modifier" : "Ajouter" }}
              </template>
            </v-btn>
            <v-btn color="warning" @click="reinitialiser" class="mx-2" v-if="hasResetButton && !hideAllButtons">
              <v-icon class="mr-2">mdi-reload</v-icon>Réinitialiser
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <v-fab-transition>
      <v-tooltip bottom v-if="$store.state.dataNotSaved == true">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="warning" fab large dark bottom right fixed style="bottom:80px" v-bind="attrs" v-on="on"
            @click="save">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </template>
        <span>Les modification ne sont pas sauvegardées !</span>
      </v-tooltip>
    </v-fab-transition>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "FormLayout",

  props: {
    disabled: { type: Boolean, default: false },
    buttonIcon: { type: String, default: null },
    buttonText: { type: String },
    disposition: { type: String, default: "default" },
    fromOCR: { type: Boolean, default: false },
    hideAllButtons: { type: Boolean, default: false },
    hasResetButton: { type: Boolean, default: true },
    icon: {},
    item: {},
    showTitle: { type: Boolean, default: true },
    subTitle: { type: String, default: null },
    titre: { type: String, default: null },
    typeItem: { type: String, default: "un item" },
  },
  data: () => ({
    initialItem: {},
  }),

  // Lors de la création d'un selecteur, on écoute grâce à l'id du composant
  // généré si un formulaire renvoie de la data en réponse au clic "ajout"
  created() {
    this.$root.$on("component-" + this._uid, (item) => {
      this.$emit("fromFile", item);
    });
  },

  beforeDestroy() {
    this.$root.$off("component-" + this._uid);
  },

  mounted: function () {
    this.$store.commit("resetFormsErrors");
    // await new Promise(resolve => setTimeout(resolve, 1000));
    // this.checkIfDataModif();
  },

  methods: {
    async checkIfDataModif() {
      let item = JSON.parse(JSON.stringify(this.item));
      let initialItem = JSON.parse(JSON.stringify(this.initialItem));

      // Dans le cas de figure où les clés ne sont pas les mêmes
      // Lors de l'initialisation du formulaire par exemple
      if (!_.isEqual(_.keys(item), _.keys(initialItem))) {
        // On attend une seconde pour être sûr que les composants enfants (Selector) aient bien chargés
        await new Promise(resolve => setTimeout(resolve, 1000));
        item = JSON.parse(JSON.stringify(this.item));
        this.initialItem = item;
        initialItem = this.initialItem;
      }


      // Si modification
      if (item.id) {
        if (!initialItem.id) {

          this.initialItem = item;
          initialItem = this.initialItem;
        }
      }

      if (_.isEqual(item, initialItem)) {
        this.$store.commit("setDataNotSaved", false);
      } else {
        this.$store.commit("setDataNotSaved", true);
      }
    },
    inputFromPhoto() {


      let link = "/fichiers-stockages/fichiers/add";
      link.includes("?") ? (link += "&") : (link += "?");
      link +=
        "popup=true&photo=true&ocr=true&componentParent=component-" + this._uid;

      for (const [key, value] of Object.entries(this.$parent.$props)) {
        if (
          key != "value" &&
          key != "label" &&
          key != "errorMessage" &&
          key != "addAction" &&
          key != "multiple" &&
          key != "prependIcon"
        ) {
          link += "&" + key + "=" + value;
        }
      }

      this.$router.push(link);
    },
    reinitialiser() {
      this.$store.commit("resetFormsErrors");
      this.$emit("reinitialiser");
    },

    save() {
      this.$store.commit("resetFormsErrors");
      this.$emit("save");
    },

    validForm() {
      this.$store.commit("resetFormsErrors");
      this.$store.commit("setDataNotSaved", false);
      this.$emit(this.item && this.item.id ? "modifier" : "ajouter");
    },

  },
  watch: {
    item: {
      handler() {
        // this.checkIfDataModif();
      },
      deep: true,
    },
    // En cas de passage de la vue edit à add par exemple
    $route() {
      this.$store.commit("resetFormsErrors");
      this.$emit("reinitialiser");
    },
  },
};
</script>