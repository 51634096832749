<template>
  <div>
    <template v-if="accord">
      <template v-if="size == 'xl'">
        <ObjectLayout size="xl" :item="accord">
          <template v-slot:icon>mdi-handshake</template>
          <template v-slot:titre>
            Accord : {{ accord.numeroAccord }} - {{ accord.etat }}
          </template>
          <template v-slot:body>
            <v-row>
              <v-col>
                <ObjectLayout v-if="accord.type">
                  <template v-slot:icon>mdi-draw-pen</template>
                  <template v-slot:titre>Type d'accord</template>
                  <template v-slot:content>
                    {{ accord.type }}
                  </template>
                </ObjectLayout>
                <ObjectLayout v-if="accord.numeroAccord">
                  <template v-slot:icon>mdi-counter</template>
                  <template v-slot:titre>Numéro d'accord</template>
                  <template v-slot:content>
                    {{ accord.numeroAccord }}
                  </template>
                </ObjectLayout>
                <ObjectLayout v-if="accord.objet">
                  <template v-slot:icon>mdi-counter</template>
                  <template v-slot:titre>Objet de l'accord</template>
                  <template v-slot:content>
                    {{ accord.objet }}
                  </template>
                </ObjectLayout>
                <ObjectLayout v-if="accord.message">
                  <template v-slot:icon>mdi-counter</template>
                  <template v-slot:titre>Message</template>
                  <template v-slot:content>
                    {{ accord.message }}
                  </template>
                </ObjectLayout>
              </v-col>
              <v-col>
                <v-timeline dense>
                  <v-timeline-item fill-dot class="mb-12" color="primary">
                    <template v-slot:icon>
                      <v-icon dark>mdi-plus-circle</v-icon>
                    </template>
                    <template v-slot:append>
                      <span :class="`headline font-weight-bold `">test</span>
                    </template>
                    {{ accord.dateCreation | shortDateAndHourFR }}<br />
                    Création de l'accord
                  </v-timeline-item>

                  <AccordEtapeObject
                    :accordEtapeID="etape.id"
                    :etape-number="index + 1"
                    :key="render + etape.id"
                    :etape-precedente="
                      accord.etapes[index - 1] ? accord.etapes[index - 1] : null
                    "
                    size="timeline-item"
                    v-for="(etape, index) of accord.etapes"
                    @fetch="rerender()"
                  />
                </v-timeline>
              </v-col>
            </v-row>
          </template>
        </ObjectLayout>
      </template>

      <template v-if="size == 'md'">
        <v-row> {{ accord.numeroAccord }}</v-row>
      </template>
      <template v-if="size == 'xs'">
        <ObjectLayout
          size="xs"
          :onClickPush="`/accord-validation/accords/view/${accord.id}`"
          :action="action"
          :color="color"
        >
          <template v-slot:icon>mdi-handshake</template>
          <template v-slot:content>
            {{ accord.numeroAccord }}
          </template>
          <template v-slot:extracontent> </template>
        </ObjectLayout>
      </template>
    </template>
  </div>
</template>

<script>
import { QUERY_accord } from "./graphql/queries";

import AccordEtapeObject from "./AccordEtapeObject.vue";
import ObjectLayout from "@/components/COMPONENTS-skeleton/Layouts/ObjectLayout.vue";

export default {
  name: "AccordObject",
  components: {
    AccordEtapeObject,
    ObjectLayout,
  },
  props: {
    size: { type: String, default: "md" },
    accordProp: {
      type: Object,
      default() {
        return {};
      },
    },
    accordID: {
      type: String,
    },
    action: { type: String, default: "router" },
  },
  watch: {
    accord: function () {
      this.sortByOrder();
    },

    accordID: function () {
      this.init();
    },
    accordProp: function () {
      this.init();
    },
  },
  computed: {
    color() {
      switch (this.accord.etat) {
        default:
          return "grey";
        case "EN_COURS":
          return "info";
        case "TERMINE_OK":
          return "success";
        case "TERMINE_NOK":
          return "error";
      }
    },
  },
  data: () => ({
    accord: undefined,
    openDialogShowAffaire: false,
    render: 0,
  }),
  mounted: function () {
    this.init();
  },
  methods: {
    init() {
      if (this.accordID) {
        let id = this.accordID;
        this.$apollo
          .query({
            query: QUERY_accord,
            variables: { id: id },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let accord = data.data.accord;

            this.accord = JSON.parse(JSON.stringify(accord));
            this.sortByOrder();
          });
      } else {
        this.accord = JSON.parse(JSON.stringify(this.accordProp));
        this.sortByOrder();
      }
    },
    rerender() {
      this.init();
      this.render += 1;
    },
    sortByOrder() {
      if (this.accord.etapes) {
         
        this.accord.etapes = this.accord.etapes.sort(function (a, b) {
          return a.position - b.position;
        });
      }
    },
  },
};
</script>