var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ListLayout',{ref:"list",attrs:{"headers":_vm.headers},on:{"fetch":_vm.init},scopedSlots:_vm._u([{key:"item.numeroDemande",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.numeroDemande)+" ")]}},{key:"item.montantCapital",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("monetaire")(item.montantCapital))+" ")]}},{key:"item.dateDemande",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("shortDateFR")(item.dateDemande))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('DemandePartenaireActions',{attrs:{"object":item,"fetch":_vm.fetchDemandePartenaires,"size":"xs"}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }