<template>
  <div>
    <v-autocomplete
      v-bind:value="value"
      :items="emails"
      v-on:input="$emit('input', $event)"
      :disabled="$apollo.queries.emails.loading"
      :error-messages="errorMessage"
      chips
      :prepend-icon="prependIcon"
      clearable
      :multiple="multiple"
      :filter="filterEmail"
      :label="label"
      dense
      item-text="id"
    >
      <template v-slot:selection="data">
        <EmailObject
          :emailProp="data.item"
          size="xs"
          v-bind="data.attrs"
          :input-value="data.selected"
          @click="data.select"
          action="none"
        />
      </template>
      <template v-slot:item="data">
        <EmailObject :emailProp="data.item" size="xs" action="none" />
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { QUERY_emails } from "./graphql/queries";
import EmailObject from "./EmailObject.vue";
export default {
  name: "EmailSelector",
  components: {
    EmailObject,
  },
  props: {
    value: null,
    errorMessage: { type: String },
    label: { type: String, default: "Utilisateur" },
    prependIcon: { type: String, default: "mdi-account-multiple" },
    multiple: { type: Boolean, default: false },
    roleFilter: { type: String },
  },
  data: () => ({
    emails: [],
  }),
  apollo: {
    emails: {
      query: QUERY_emails,
      fetchPolicy: "no-cache",
    },
  },
  methods: {
    //Permet de filtrer les entreprises selon deux critères: - Le Numéro Siren, la raison sociale
    filterEmail(item, queryText) {
       
      return (
        item.email.toLowerCase().indexOf(queryText.toLowerCase()) > -1
      );
    },
  },
};
</script>

<style>
</style>