<template>
  <v-form>
    <v-row>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="primary"
            v-bind="attrs"
            v-on="on"
            class="mt-6"
            @click="onChangeButtonClick"
          >
            <v-icon>
              {{ addModele ? "mdi-widgets" : "mdi-package-variant-closed" }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ addModele ? "Autre" : "Modèle" }}</span>
      </v-tooltip>
      <v-col cols="1">
        <v-text-field
          v-model.number="ligneToAdd.quantite"
          type="number"
          label="Quantité"
        />
      </v-col>
      <v-col v-if="addModele" cols="3">
        <MarqueSelector v-model="ligneToAdd.marque" />
      </v-col>
      <v-col v-if="addModele" cols="3">
        <ModeleSelector
          :marque="ligneToAdd.marque"
          v-model="ligneToAdd.modele"
          :disabled="ligneToAdd.marque ? false : true"
        />
      </v-col>
      <v-col v-if="!addModele">
        <v-combobox
          v-model="ligneToAdd.unite"
          :items="devisFournisseurLigneUnite"
          label="Unité"
          chips
          clearable
          item-text="ligneToAdd.unite"
          prepend-icon="mdi-scale"
          dense
          class="pt-1"
        />
      </v-col>
      <v-col v-if="!addModele">
        <v-text-field v-model="ligneToAdd.label" label="Label" />
      </v-col>
      <v-col>
        <v-text-field
          v-model.number="ligneToAdd.prixUnitaire"
          type="number"
          suffix="€"
          label="Prix unitaire H.T."
        />
      </v-col>
      <v-col>
        <v-text-field
          v-model.number="ligneToAdd.tauxTva"
          type="number"
          suffix="%"
          label="Taux T.V.A."
        />
      </v-col>
      <slot name="customLigneForm" :ligneToAdd="ligneToAdd"> </slot>
      <v-col>
        <v-row class="pt-6 justify-center">
          <v-btn color="primary" fab x-small dark @click="addLigne()">
            <v-icon>mdi-plus-circle</v-icon>
          </v-btn>

          <v-btn
            color="warning"
            class="ml-4"
            fab
            x-small
            dark
            @click="resetLigne()"
          >
            <v-icon>mdi-eraser</v-icon>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import _ from "lodash";

import { QUERY_devisFournisseurLigneUnite } from "./graphql/queries";

import MarqueSelector from "@/components/COMPONENTS-assets/MarqueSelector.vue";
import ModeleSelector from "@/components/COMPONENTS-assets/ModeleSelector.vue";

export default {
  name: "DevisClientLigneForm",

  components: {
    MarqueSelector,
    ModeleSelector,
  },

  created() {
    this.$root.$on("changeAddModele", this.changeAddModele);
  },

  props: {
    value: [],
    ligneToAddFromParent: {
      type: Object,
      default() {
        return {};
      }
    }
  },

  data: () => ({
    addModele: true,
    ligneToAdd: {
      quantite: 1
    },
  }),

  apollo: {
    devisFournisseurLigneUnite: {
      query: QUERY_devisFournisseurLigneUnite,
      fetchPolicy: "no-cache",
    },
  },

  mounted: function () {
    this.resetLigne();
  },

  methods: {
    addLigne: function () {
      let tableau = _.concat(this.value, this.ligneToAdd);

      this.$emit("input", tableau);
      this.resetLigne();
    },

    changeAddModele() {
      this.addModele = !this.addModele;
      this.resetLigne();
    },

    onChangeButtonClick() {
      if(this.ligneToAdd.marque || this.ligneToAdd.modele || this.ligneToAdd.unite || this.ligneToAdd.label != "") {
        let toValid = {
          titre: "Changement de formulaire",
          texte: "Vous êtes sur le point de changer de formulaire en ayant des informations non enregistrées. Êtes-vous sûr de vouloir continuer ?",
          type: "warning",
          toReturn: {
            function: "changeAddModele"
          },
        };

        this.$root.$emit("popupValidation", toValid);
      }else {
        this.changeAddModele();
      }
    },

    resetLigne: function () {
      let meta = this.ligneToAdd.meta
        ? JSON.parse(JSON.stringify(this.ligneToAdd.meta))
        : {};

      this.ligneToAdd = {
        quantite: 1,
        label: "",
        prixUnitaire: 0,
        tauxTva: 20,
        meta: meta,
      };
    },
  },

  watch: {
    ligneToAddFromParent(value) {
      if(value.marque) {
        this.addModele = true;
      }else {
        this.addModele = false;
      }

      function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }

      sleep(1).then(() => {this.ligneToAdd = JSON.parse(JSON.stringify(value));})
    }
  }
};
</script>