var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SelectorLayout',{ref:"selector",attrs:{"addAction":_vm.customAddAction,"disabled":_vm.disabled !== undefined
      ? _vm.disabled
      : !_vm.item && !(_vm.fournisseurPreSelect || _vm.clientPreSelect),"label":_vm.label,"prepend-icon":_vm.prependIcon},on:{"change":function($event){return _vm.$emit('change', $event)},"fetch":_vm.fetch},scopedSlots:_vm._u([(_vm.preSelector)?{key:"preSelect",fn:function(){return [(!_vm.item && !_vm.factureByFournisseur)?_c('v-row',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-4",attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.preSelect == 'fournisseur'
                ? (_vm.preSelect = 'client')
                : (_vm.preSelect = 'fournisseur')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-cached ")])],1)]}}],null,false,1283751505)},[_c('span',[_vm._v(_vm._s(_vm.preSelect == "fournisseur" ? "Chercher par client" : "Chercher par fournisseur"))])]),_c('v-col',[(_vm.preSelect == 'fournisseur')?_c('FournisseurSelector',{model:{value:(_vm.fournisseurPreSelect),callback:function ($$v) {_vm.fournisseurPreSelect=$$v},expression:"fournisseurPreSelect"}}):_c('ClientSelector',{model:{value:(_vm.clientPreSelect),callback:function ($$v) {_vm.clientPreSelect=$$v},expression:"clientPreSelect"}})],1)],1):_vm._e()]},proxy:true}:null,{key:"selection",fn:function(data){return [_c('FactureClientObject',_vm._b({attrs:{"factureClientProp":data.item,"size":"xs","input-value":data.selected,"action":"none"},on:{"click":data.select}},'FactureClientObject',data.attrs,false))]}},{key:"item",fn:function(data){return [_c('FactureClientObject',{attrs:{"factureClientProp":data.item,"action":"none"}})]}}],null,true),model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}})}
var staticRenderFns = []

export { render, staticRenderFns }