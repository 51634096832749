<template>
  <div>
    <ActionLayout
      :actions="actions"
      :size="size"
      :action="action"
      @fetch="$emit('fetch')"
      :mainIcon="mainIcon"
    />
  </div>
</template>

<script>
import ActionLayout from "@/components/COMPONENTS-skeleton/Layouts/ActionLayout.vue";
import {
  MUTATION_contratLocationDelete,
  MUTATION_contratLocationUnlock,
  MUTATION_contratLocationUpdate,
} from "./graphql/mutations.js";
export default {
  name: "ContratLocationActions",
  components: {
    ActionLayout,
  },
  props: {
    mainIcon: {
      type: String,
      default: "mdi-file-sign",
    },
    object: {
      type: [Array, Object],
    },
    size: {
      type: String,
      default: "md",
    },
    action: {
      type: String,
      default: "router",
    },
  },
  data: () => ({}),
  computed: {
    actions() {
      return [
        {
          text: "Editer",
          icon: "mdi-pencil",
          path: "/satelease/contratsLocations/edit/" + this.object.id,
          acl: "/satelease/contratsLocations/edit",
          color: "",
          conditions: {
            multiple: false,
            isActif: true,
            objet: { valide: false },
          },
        },
        {
          text: "Valider",
          icon: "mdi-check-bold",
          path: "/satelease/contratsLocations/validate/" + this.object.id,
          acl: "/satelease/contratsLocations/validate",
          color: "info",
          conditions: {
            multiple: false,
            isActif: true,
            objet: { valide: false },
          },
        },
        {
          text: "Supprimer",
          icon: "mdi-delete",
          method: "editIsActif",
          acl: "/satelease/contratsLocations/deleted",
          color: "error",
          conditions: {
            multiple: true,
            isActif: true,
            objet: { valide: false },
          },
        },
        {
          text: "Restauration",
          icon: "mdi-restore",
          method: "editIsActif",
          acl: "/satelease/contratsLocations/edit",
          color: "",
          conditions: {
            multiple: true,
            isActif: false,
            objet: { valide: false },
          },
        },
        {
          text: "Supprimer définitivement",
          icon: "mdi-delete",
          method: "delete",
          acl: "/satelease/contratsLocations/definitive-delete",
          color: "error",
          conditions: {
            multiple: false,
            isActif: false,
            objet: { valide: false },
          },
        },
        {
          text: "Déverrouiller",
          icon: "mdi-lock-open-variant",
          method: "unlock",
          acl: "/satelease/contratsLocations/unlock",
          color: "green",
          conditions: {
            multiple: false,
            isActif: true,
            objet: { valide: true, deverrouille: null },
          },
        },
        {
          text: "Editer",
          icon: "mdi-pencil",
          path: "/satelease/contratsLocations/edit/" + this.object.id,
          acl: "/satelease/contratsLocations/unlock",
          color: "",
          conditions: {
            multiple: false,
            isActif: true,
            objet: { valide: true, deverrouille: true },
          },
        },
        {
          text: "Valider",
          icon: "mdi-check-bold",
          path: "/satelease/contratsLocations/validate/" + this.object.id,
          acl: "/satelease/contratsLocations/unlock",
          color: "info",
          conditions: {
            multiple: false,
            isActif: true,
            objet: { valide: true, deverrouille: true },
          },
        },
        {
          text: "Visualiser",
          icon: "mdi-eye",
          path: "/satelease/contratsLocations/view/" + this.object.id,
          acl: "/satelease/contratsLocations/view",
          color: "",
          conditions: {},
        },
      ];
    },
  },

  beforeDestroy() {
    this.$root.$off("deleteContratLocation", this.deleteContratLocation);
    this.$root.$off("editContratLocation", this.editContratLocation);
    this.$root.$off("unlockContratLocation", this.unlockContratLocation);
  },

  created() {
    this.$root.$on("deleteContratLocation", this.deleteContratLocation);
    this.$root.$on("editContratLocation", this.editContratLocation);
    this.$root.$on("unlockContratLocation", this.unlockContratLocation);
  },

  methods: {
    /**
     * Supprimer un contratLocation grâce à son id
     */
    deleteContratLocation(id) {
      this.$apollo
        .mutate({
          mutation: MUTATION_contratLocationDelete,
          variables: {
            id: id,
          },
        })
        .then(() => {
          this.$emit("fetch");
        });
    },

    /**
     * Fonction à avoir dans tous les composants Actions (infos à changer selon le composant)
     * Permet de lancer la popup de validation
     *
     * obj : l'objet a supprimé
     */
    delete(obj) {
      // infos qui s'afficheront dans le popup
      let toValid = {
        titre: "Suppression définitive d'un contrat de location",
        texte: `Êtes-vous sûr de vouloir supprimer définitivement le contrat de location ${obj.numeroContrat} ?`,
        type: "warning",
        toReturn: {
          function: "deleteContratLocation", // cette fonction change en fonction du composant (ex: PersonneActions.vue ça serait deletePersonne,
          //qu'il faut créer à la place de deleteContratLocations)
          arg: obj.id,
        },
      };

      // Envoi l'info pour ouvrir la popup
      this.$root.$emit("popupValidation", toValid);
    },

    /**
     * Fonction qui édite un contratLocation
     *
     * obj : l'objet à éditer
     */
    async editContratLocation(obj) {
      // Si obj n'est pas un tableau alors on le transforme en tableau
      if (!Array.isArray(obj)) {
        obj = [obj];
      }
      // Selon le schéma, il est obligé d'avoir le siren et la raison sociale dans EntepriseInput
      // Bien vérifier dans le schéma si jamais il y a une erreur du style "$input etc.."
      this.$emit("loading");
      for (let item of obj) {
        let contratLocation = {
          id: item.id,
          numeroContrat: item.numeroContrat,
          affaireAssociee: item.affaireAssociee,
          isActif: !item.isActif,
        };
        await this.$apollo.mutate({
          mutation: MUTATION_contratLocationUpdate,
          variables: {
            input: contratLocation,
          },
        });
      }
      this.$emit("fetch");
    },

    /**
     * Fonction à avoir dans tous les composants (infos à changer)
     */
    editIsActif(obj) {
      // Si obj n'est pas un tableau alors on le transforme en tableau
      if (!Array.isArray(obj)) {
        obj = [obj];
      }
      let toValid = {
        titre: obj[0].isActif
          ? "Suppression de contrat(s) de location"
          : "Restauration de contrat(s) de location",
        texte: obj[0].isActif
          ? `Êtes-vous sûr de vouloir supprimer  ${obj.length} contrat(s) de location ? ` +
            'Il sera toujours présente dans l\'onglet "Corbeille"'
          : `Êtes-vous sûr de vouloir restaurer  ${obj.length} contrat(s) de location ?`,
        type: "warning",
        toReturn: {
          function: "editContratLocation", // à changer selon le composant, comme deleteContratLocations
          arg: obj,
        },
      };

      this.$root.$emit("popupValidation", toValid);
    },

    unlock(obj) {
      let toValid = {
        titre: "Déverrouiller un contrat de location",
        texte: `Êtes-vous sûr de vouloir déverrouiller le contrat de location n°${obj.numeroContrat} ?`,
        type: "warning",
        toReturn: {
          function: "unlockContratLocation",
          arg: obj,
        },
      };

      this.$root.$emit("popupValidation", toValid);
    },

    async unlockContratLocation(obj) {
      await this.$apollo
        .mutate({
          mutation: MUTATION_contratLocationUnlock,
          variables: {
            id: obj.id
          }
        })

      this.$emit('fetch');
    }
  },
};
</script>
