<template>
  <FormLayout
    icon="mdi-home"
    @ajouter="ajouter"
    @modifier="modifier"
    @reinitialiser="init"
    typeItem="un etablissement"
    :item="input"
    :disabled="input.siret ? false : true"
  >
    <v-row>
      <v-col>
        <EntrepriseSelector v-model="input.entreprise" />
      </v-col>
      <v-col>
        <v-switch
          v-model="input.isSiege"
          label="L'établissement est le siège de l'entreprise"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="input.siret"
          name="siret"
          :error-messages="
            formsErrors.etablissementSiret
              ? formsErrors.etablissementSiret[index]
                ? formsErrors.etablissementSiret[index]
                : ''
              : ''
          "
          label="Numéro de SIRET"
        />
      </v-col>
      <v-col>
        <v-text-field
          v-model="input.libelle"
          name="libelle"
          :error-messages="
            formsErrors.etablissementLibelle
              ? formsErrors.etablissementLibelle[index]
                ? formsErrors.etablissementLibelle[index]
                : ''
              : ''
          "
          label="Nom de l'établissement"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <EnseigneSelector v-model="input.enseigne" />
      </v-col>
      <v-col></v-col>
    </v-row>

    <v-subheader>
      <v-icon class="pr-4">mdi-mailbox</v-icon> Adresse postale
    </v-subheader>
    <v-divider></v-divider>
    <AdressePostaleForm 
      @onValid="addAdresse"
    />
  </FormLayout>
</template>

<script>
import { mapState } from "vuex";

import {
  MUTATION_etablissementCreate,
  MUTATION_etablissementUpdate,
} from "./graphql/mutations";
import { QUERY_etablissement } from "./graphql/queries";

import AdressePostaleForm from "./AdressePostaleForm.vue";
import EntrepriseSelector from "./EntrepriseSelector.vue";
import EnseigneSelector from "./EnseigneSelector.vue";
import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";

export default {
  name: "EtablissementForm",

  components: {
    AdressePostaleForm,
    EntrepriseSelector,
    EnseigneSelector,
    FormLayout,
  },

  props: {
    hiddenComponent: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },

  data: () => ({
    input: {
      adresse: {},
    },
    etablissement: {
      id: undefined,
      entreprise: undefined,
      libelle: "",
      siret: "",
      adresse: {
        numeroVoie: "",
        libelleVoie: "",
        codePostal: "",
        libelleCommune: "",
        cedex: "",
        longitude: "",
        latitude: "",
      },
      isSiege: true,
    },
  }),

  watch: {
    etablissement: {
      deep: true,
      handler(val) {
        this.input = val;
      },
    },
  },

  computed: {
    ...mapState(["formsErrors"]),
  },

  mounted: function () {
    this.init();
  },

  methods: {
    addAdresse(adresse) {
      this.input.adresse = adresse;
    },

    ajouter() {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_etablissementCreate,
          variables: {
            input: toSend,
          },
        })
        .then((__) => {
          this.init();
          this.$store.commit("addAlert", {
            type: "info",
            text: "L'établissement a été ajouté",
          });
          this.$root.$emit(
            "annuaire--etablissement-create",
            __.data.etablissementCreate
          );
          this.$root.$emit("skeleton--close");
        });
    },

    init() {
      if (
        this.$route.path.startsWith("/annuaire/etablissements/edit/") &&
        this.$route.params.id
      ) {
        let id = this.$route.params.id;
        this.$apollo
          .query({
            query: QUERY_etablissement,
            variables: { id: id },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            this.etablissement = data.data.etablissement;
            this.input = JSON.parse(JSON.stringify(this.etablissement));
          });
      } else {
        this.input = JSON.parse(JSON.stringify(this.etablissement));
      }
    },

    inputConverter(arg) {
      const obj = { ...arg };
      if (Object.keys(obj).length == 0) {
        return "";
      }

      let input = {
        id: obj.id,
        libelle: obj.libelle,
        siret: obj.siret,
        adresse: {
          numeroVoie: obj.adresse.numeroVoie,
          libelleVoie: obj.adresse.libelleVoie,
          codePostal: obj.adresse.codePostal,
          libelleCommune: obj.adresse.libelleCommune,
          cedex: obj.adresse.cedex,
          longitude: obj.adresse && obj.adresse.longitude ? obj.adresse.longitude.toString() : null,
          latitude: obj.adresse && obj.adresse.latitude ? obj.adresse.latitude.toString() : null,
        },
        entreprise:
          obj.entreprise && obj.entreprise.id
            ? obj.entreprise.id
            : obj.entreprise,
        enseigne:
          obj.enseigne && obj.enseigne.id ? obj.enseigne.id : obj.enseigne,
        isSiege: obj.isSiege,
      };
      return input;
    },

    modifier() {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_etablissementUpdate,
          variables: { input: toSend },
          update: (store, { data: { etablissementUpdate } }) => {
            this.entreprise = etablissementUpdate;
            this.$store.commit("addAlert", {
              type: "info",
              text: "L'établissement a été modifié",
            });
            this.$root.$emit("annuaire--update-etablissements");
            this.$root.$emit("skeleton--close");
          },
        })
        .then(() => {
          this.resetForm();
        });
    },

    resetForm() {
      this.input = this.inputConverter(this.etablissement);
      this.$store.commit("resetFormsErrors");
    },
  },
};
</script>