<template>
  <div>
    <ActionLayout
      :actions="actions"
      :size="size"
      :action="action"
      @fetch="$emit('fetch')"
      :mainIcon="mainIcon"
    />
  </div>
</template>

<script>
import ActionLayout from "@/components/COMPONENTS-skeleton/Layouts/ActionLayout.vue";
import {
  MUTATION_fournisseurDelete,
  MUTATION_fournisseurUpdate,
} from "./graphql/mutations.js";
export default {
  name: "FournisseurActions",
  components: {
    ActionLayout,
  },

  props: {
    mainIcon: {
      type: String,
      default: "mdi-store",
    },
    object: {
      type: [Array, Object],
    },
    size: {
      type: String,
      default: "md",
    },
    action: {
      type: String,
      default: "router",
    },
    multiple: { type: Boolean, default: false },
  },

  computed: {
    actions() {
      return [
        {
          text: "Editer",
          icon: "mdi-pencil",
          path: "/devis-facture/fournisseurs/edit/" + this.object.id,
          acl: "/devis-facture/fournisseurs/edit",
          color: "",
          conditions: { multiple: false, isActif: true },
        },
        {
          text: "Supprimer",
          icon: "mdi-delete",
          method: "editIsActif",
          acl: "/devis-facture/fournisseurs/deleted",
          color: "error",
          conditions: { multiple: true, isActif: true },
        },
        {
          text: "Restauration",
          icon: "mdi-restore",
          method: "editIsActif",
          acl: "/devis-facture/fournisseurs/edit",
          color: "",
          conditions: { multiple: true, isActif: false },
        },
        {
          text: "Supprimer définitivement",
          icon: "mdi-delete",
          method: "delete",
          acl: "/devis-facture/fournisseurs/deleted",
          color: "error",
          conditions: { multiple: false, isActif: false },
        },
        {
          text: "Visualiser",
          icon: "mdi-eye",
          path: "/devis-facture/fournisseurs/view/" + this.object.id,
          acl: "/devis-facture/fournisseurs/view",
          color: "",
          conditions: {},
        },
      ];
    },
  },
  created() {
    this.$root.$on("deleteFournisseur", this.deleteFournisseur);
    this.$root.$on("editFournisseur", this.editFournisseur);
  },

  methods: {
    /**
     * Supprimer une fournisseur grâce à son id
     */
    deleteFournisseur(id) {
      this.$apollo
        .mutate({
          mutation: MUTATION_fournisseurDelete,
          variables: {
            id: id,
          },
        })
        .then(() => {
          this.$emit("fetch");
        });
    },

    /**
     * Fonction à avoir dans tous les composants Actions (infos à changer selon le composant)
     * Permet de lancer la popup de validation
     *
     * obj : l'objet a supprimé
     */
    delete(obj) {
      // infos qui s'afficheront dans le popup
      let toValid = {
        titre: "Suppression définitive d'un fournisseur",
        texte: `Êtes-vous sûr de vouloir supprimer définitivement le fournisseur ${obj.entreprise} ?`,
        type: "warning",
        toReturn: {
          function: "deleteFournisseur", // cette fonction change en fonction du composant (ex: PersonneActions.vue ça serait deletePersonne,
          //qu'il faut créer à la place de deleteFournisseur)
          arg: obj.id,
        },
      };

      // Envoi l'info pour ouvrir la popup
      this.$root.$emit("popupValidation", toValid);
    },

    /**
     * Fonction qui édite une fournisseur
     *
     * obj : l'objet à éditer
     */
    async editFournisseur(obj) {
      // Si obj n'est pas un tableau alors on le transforme en tableau
      if (!Array.isArray(obj)) {
        obj = [obj];
      }
      // Bien vérifier dans le schéma si jamais il y a une erreur du style "$input etc.."
      this.$emit("loading");
      for (let item of obj) {
        let fournisseur = {
          id: item.id,
          isActif: !item.isActif,
        };
        await this.$apollo.mutate({
          mutation: MUTATION_fournisseurUpdate,
          variables: {
            input: fournisseur,
          },
        });
      }
      this.$emit("fetch");
    },

    /**
     * Fonction à avoir dans tous les composants (infos à changer)
     */
    editIsActif(obj) {
      // Si obj n'est pas un tableau alors on le transforme en tableau
      if (!Array.isArray(obj)) {
        obj = [obj];
      }
      let toValid = {
        titre: obj[0].isActif
          ? "Suppression de fournisseur(s)"
          : "Restauration de fournisseur(s)",
        texte: obj.isActif
          ? `Êtes-vous sûr de vouloir supprimer ${obj.length} fournisseur(s) ? ` +
            'Ils seront toujours présents dans l\'onglet "Corbeille"'
          : `Êtes-vous sûr de vouloir restaurer ${obj.length} fournisseur(s) ?`,
        type: "warning",
        toReturn: {
          function: "editFournisseur", // à changer selon le composant, comme deleteFournisseur
          arg: obj,
        },
      };

      this.$root.$emit("popupValidation", toValid);
    },
  },
};
</script>
