<template>
  <div class="mx-auto">
    <v-row>
      <v-col cols="12" xl="6" lg="6" md="6" sm="12">
        <TitleLayout titre="Liste des fichiers" icon="mdi-file-tree" />

        <TypeFichiersList
          :customSearch="customSearch"
          :useCategory="useCategory"
          :meta="meta"
        />
      </v-col>
      <v-col cols="12" xl="6" lg="6" md="6" sm="12">
        <v-row>
          <v-col>
            <TitleLayout titre="Ajouter un fichier" icon="mdi-file-document" />

            <TypeFichierForm
              :authorizedExtensions="authorizedExtensions"
              :useCategory="useCategory"
              :meta="meta"
              class="ma-4"
            />
          </v-col>
        </v-row>
        <v-row v-show="showMaker && !meta.template">
          <v-col>
            <TitleLayout
              titre="Générer un fichier"
              sousTitre="Choisissez le template à partir duquel vous souhaitez générer le document."
              icon="mdi-hammer-wrench"
            />

            <FichierMaker
              :typeParent="meta.typeParent"
              :idParent="meta.idParent"
              :data="data ? data : item"
              :meta="meta"
              @show="showMaker = true"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TypeFichiersList from "./TypeFichiersList.vue";
import TypeFichierForm from "./TypeFichierForm.vue";
import FichierMaker from "./FichierMaker.vue";
import TitleLayout from "@/components/COMPONENTS-skeleton/Layouts/TitleLayout.vue";

export default {
  name: "FichiersObject",
  components: {
    FichierMaker,
    TypeFichiersList,
    TypeFichierForm,
    TitleLayout,
  },
  data: () => ({
    showMaker: false,
  }),
  props: {
    meta: { type: Object },
    data: { type: Object },
    item: { type: Object },
    // idParent: { type: String, default: "" },
    useCategory: { type: Boolean, default: true },
    authorizedExtensions: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  mounted() {},
  computed: {
    customSearch() {
      let toReturn = [];
      let meta = this.meta;
      if (meta.typeParent) {
        toReturn.push({ "meta.typeParent": meta.typeParent });
      }
      if (meta.idParent) {
        toReturn.push({ "meta.idParent": meta.idParent });
      }
      if (meta.template) {
        toReturn.push({ "meta.template": meta.template });
      }
      if (meta.categorie) {
        toReturn.push({ "meta.categorie": meta.categorie });
      }
      return toReturn;
    },
  },
};
</script>
