var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ListLayout',{ref:"list",attrs:{"title":"Liste des modèles","headers":_vm.headers,"noFilter":_vm.noFilter},on:{"fetch":_vm.init,"showMultipleActions":function($event){_vm.showMultipleActions = $event}},scopedSlots:_vm._u([{key:"multipleActions",fn:function(){return [_c('ModeleActions',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMultipleActions),expression:"showMultipleActions"}],attrs:{"multiple":true,"withView":false,"object":_vm.$refs.list ? _vm.$refs.list.itemsSelected : [],"size":"xl"},on:{"fetch":_vm.init,"loading":function($event){_vm.$refs.list.loading = true}}})]},proxy:true},{key:"customFilters",fn:function(){return [_c('MarqueSelector',{attrs:{"addAction":null},model:{value:(_vm.customFilters.marque),callback:function ($$v) {_vm.$set(_vm.customFilters, "marque", $$v)},expression:"customFilters.marque"}})]},proxy:true},{key:"item.modele",fn:function(ref){
var item = ref.item;
return [_c('ModeleObject',{attrs:{"modeleProp":item,"size":"xs"}})]}},{key:"item.marque",fn:function(ref){
var item = ref.item;
return [_c('MarqueObject',{attrs:{"marqueProp":item.marque,"size":"xs"}})]}},{key:"item.assetsCount",fn:function(ref){
var item = ref.item;
return [_c('AssetsCounter',{attrs:{"customSearch":[{ modele: item.id }]}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('ModeleActions',{attrs:{"isActif":_vm.$refs.list.itemsAreActive,"object":item,"size":"xs"},on:{"fetch":_vm.init,"loading":function($event){_vm.$refs.list.loading = true}}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }