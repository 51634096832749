<template>
  <div v-if="this.entreesPlanning != {}">
    <v-row class="fill-height">
      <v-col>
        <v-sheet height="64">
          <v-toolbar flat color="white">
            <v-btn color="primary" dark @click.stop="dialog = true">
              Ajouter un événement
            </v-btn>
            <v-btn
              outlined
              class="mr-4"
              color="grey darken-2"
              @click="setToday"
            >
              Aujourd'hui
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small>mdi-chevron-right</v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Jour</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Semaine</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Mois</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = '4day'">
                  <v-list-item-title>4 jours</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>

        <v-dialog v-model="dialog" max-width="500">
          <v-card>
            <v-container>
              <v-form
                ref="form"
                @submit.prevent="addEvent"
                v-model="valid"
                lazy-validation
              >
                <v-text-field
                  v-model="name"
                  type="text"
                  label="label"
                  :counter="30"
                  :rules="[(v) => !!v || 'Veuillez sélectionner une label']"
                ></v-text-field>
                <v-text-field
                  v-model="details"
                  type="text"
                  label="description"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="start"
                  type="date"
                  label="dateDebut"
                  :rules="[
                    (v) => !!v || 'Veuillez sélectionner une date de début',
                  ]"
                  prepend-icon="mdi-calendar"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="end"
                  type="date"
                  label="dateFin"
                  :rules="[
                    (v) => !!v || 'Veuillez sélectionner une date de fin',
                  ]"
                  prepend-icon="mdi-calendar"
                  required
                ></v-text-field>

                <v-btn
                  type="submit"
                  color="primary"
                  class="mr-4"
                  :disabled="!valid"
                  @click.stop="dialog = false"
                >
                  Créer un événement
                </v-btn>
              </v-form>
            </v-container>
          </v-card>
        </v-dialog>

        <v-sheet height="600" v-if="renderComponent">
          <v-calendar
            locale="fr"
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="events"
            :event-color="getEventColor"
            :type="type"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
            @change="updateRange"
          >
          </v-calendar>
          <v-btn @click="createPlanning">ajouter planning</v-btn>
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <v-card color="grey lighten-4" min-width="350px" flat>
              <v-toolbar :color="selectedEvent.color" dark>
                <v-btn icon text @click.prevent="updateEvent(selectedEvent)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>

                <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text>
                <span v-html="selectedEvent.details"></span>
              </v-card-text>
              <v-card-actions>
                <v-btn text color="secondary" @click="selectedOpen = false">
                  Fermer
                </v-btn>
                <v-btn text @click="show = !show">Modifier</v-btn>
                <v-btn text @click="deleteEvent(selectedEvent)">
                  Effacer
                </v-btn>
              </v-card-actions>
              <v-expand-transition max-width="500">
                <div v-show="show">
                  <v-container>
                    <v-form
                      ref="form"
                      @submit.prevent="updateEvent(selectedEvent)"
                      v-model="valid"
                      lazy-validation
                    >
                      <v-text-field
                        v-model="name"
                        type="text"
                        label="label"
                        :counter="30"
                        :rules="[
                          (v) => !!v || 'Veuillez sélectionner une label',
                        ]"
                      ></v-text-field>

                      <v-text-field
                        v-model="details"
                        type="text"
                        label="description"
                        required
                      ></v-text-field>

                      <v-text-field
                        v-model="start"
                        type="date"
                        label="date de Debut"
                        :rules="[
                          (v) =>
                            !!v || 'Veuillez sélectionner une date de début',
                        ]"
                        prepend-icon="mdi-calendar"
                        required
                      ></v-text-field>

                      <v-text-field
                        v-model="end"
                        type="date"
                        label="date de Fin"
                        :rules="[
                          (v) => !!v || 'Veuillez sélectionner une date de fin',
                        ]"
                        prepend-icon="mdi-calendar"
                        required
                      ></v-text-field>

                      <v-btn
                        type="submit"
                        color="primary"
                        class="mr-4"
                        :disabled="!valid"
                        @click.stop="dialog = false"
                      >
                        valider la modification
                      </v-btn>
                    </v-form>
                  </v-container>
                </div>
              </v-expand-transition>
            </v-card>
          </v-menu>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
  <div v-else>
    <v-container>
      <v-alert
        class="text-center"
        border="left"
        colored-border
        type="info"
        elevation="2"
      >
        Actuellement vous ne possédez pas de calendrier, veuillez créer un
        premier événément
      </v-alert>
      <v-form
        ref="form"
        @submit.prevent="addEvent"
        v-model="valid"
        lazy-validation
      >
        <v-text-field
          v-model="name"
          type="text"
          label="label"
          :counter="30"
          :rules="[(v) => !!v || 'Veuillez sélectionner une label']"
        ></v-text-field>
        <v-text-field
          v-model="details"
          type="text"
          label="description"
          required
        ></v-text-field>

        <v-text-field
          v-model="start"
          type="date"
          label="dateDebut"
          :rules="[(v) => !!v || 'Veuillez sélectionner une date de début']"
          prepend-icon="mdi-calendar"
          required
        ></v-text-field>

        <v-text-field
          v-model="end"
          type="date"
          label="dateFin"
          :rules="[(v) => !!v || 'Veuillez sélectionner une date de fin']"
          prepend-icon="mdi-calendar"
          required
        ></v-text-field>

        <v-btn
          type="submit"
          color="primary"
          class="mr-4"
          :disabled="!valid"
          @click.stop="dialog = false"
        >
          Créer un événement
        </v-btn>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { QUERY_entreesPlanning, QUERY_plannings } from "./graphql/queries";
import {
  MUTATION_planningCreate,
  MUTATION_entreePlanningCreate,
  MUTATION_entreePlanningUpdate,
  MUTATION_entreePlanningDelete,
} from "./graphql/mutations";
export default {
  name: "PlanningCreate",

  data: function () {
    return {
      valid: true,
      entreesPlanning: {},
      renderComponent: true,
      today: new Date().toISOString().substr(0, 10),
      focus: new Date().toISOString().substr(0, 10),
      type: "month",
      typeToLabel: {
        month: "Mois",
        week: "Semaine",
        day: "Jour",
        "4Jour": "4 Jours",
      },
      dateDebut: "",
      dateFin: "",
      label: "",
      description: "",

      name: null, // = label
      details: null, // = description
      start: null, // = date de début
      end: null, // = date de fin

      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "cyan",
        "green",
        "orange",
        "deep-yellow",
      ], // couleur des événements
      currentlyEditing: null,
      selectedEvent: {},
      show: false,
      selectedElement: null,
      selectedOpen: false,
      events: [],
      dialog: false,
      input: {},
      planning: {},
    };
  },

  apollo: {
    entreesPlanning: {
      query: QUERY_entreesPlanning,
    },
    plannings: {
      query: QUERY_plannings,
    },
  },

  computed: {
    monthFormatter() {
      // fonction qui permet de paramétrer le moi
      return this.$refs.calendar.getFormatter({
        timeZone: "UTC",
        month: "long",
      });
    },
  },

  methods: {
    createPlanning: function () {
      const idPlanning = [];
      this.entreesPlanning.map((entree) => {
        idPlanning.push({
          id: entree.id,
        });
      });
      this.input = idPlanning;
       
      this.$apollo

        .mutate({
          mutation: MUTATION_planningCreate,
          variables: {
            input: this.input,
          },
        })

        .then((data) => {
          console.log(data);
        });
    },

    formToInput: function () {
      this.input = {
        dateDebut: this.start,
        dateFin: this.end,
        label: this.name,
        description: this.details,
      };
    },

    editEvent(events) {
      this.currentlyEditing = events.id;
    },

    addEvent: function () {
      this.$refs.form.validate(); // btn ajout événement dans le calendar
      this.formToInput();

      if (this.valid) {
        this.$apollo
          .mutate({
            mutation: MUTATION_entreePlanningCreate,
            variables: {
              input: this.input,
            },
            refetchQueries: [{ query: QUERY_entreesPlanning }], //udpate des entrées plannings
          })
          .then((data) => {
             
            this.$emit("entreeCreated", data.data.entreePlanningCreate.id);
          });
      }
    },

    deleteEvent: function (event) {
      // fonction récupère l'id de l'entrée et la supprime
      this.$apollo
        .mutate({
          mutation: MUTATION_entreePlanningDelete,
          variables: {
            id: event.id,
          },
          refetchQueries: [{ query: QUERY_entreesPlanning }], //refetchQueries permet de mettre à jour les entrées après supression
        })

        .then((data) => {
          console.log(data);
        });
    },

    updateEvent: function (event) {
      // fonction qui permet de modifier et de mettre à jour l'entrée du planning
      this.formToInput();

      if (this.valid) {
        this.input.id = event.id; // je crée un objet qui contient la valeur de l'id de mon entrée
        this.$apollo
          .mutate({
            mutation: MUTATION_entreePlanningUpdate,
            variables: {
              input: this.input,
            },
            refetchQueries: [{ query: QUERY_entreesPlanning }],
          })

          .then((data) => {
            console.log(data);
          });
      }
    },

    setDialogDate({ date }) {
      this.dialogDate = true;
      this.focus = date;
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      // fonction qui retourne la couleur de l'événement.
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev(); // fonction qui permet de faire défiler les mois dans l'ordre inverse de la chronologie
    },
    next() {
      this.$refs.calendar.next(); // fonction qui permet de faire défiler les mois dans l'ordre de la chronologie
    },

    //editEvent(ev) {
    //this.currentlyEditing = ev.id;
    //},

    showEvent({ nativeEvent, event }) {
      // fonction qui sélectionne l'événement avec une valeur false de base qui le laisse fermé
      const open = () => {
        this.selectedEvent = event; // selectEvent est égale à un évènement  du calendar
        this.selectedElement = nativeEvent.target; // selectElement = target de l'évènement  cliquable
        setTimeout(() => (this.selectedOpen = true), 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false; // si n'y a pas d'event à sélectionner il reste à false
        setTimeout(open, 10);
      } else {
        open(); // sinon il sélectionne un event et affichage la V-card
      }

      nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      this.start = start;
      this.end = end;
    },

    rnd(a, b) {
      // fonction ramdom pour attribuer une couleur différente à chaque events
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
  },
  watch: {
    entreesPlanning: function () {
      if (this.entreesPlanning != {}) {
        const evenements = [];
        // On parcourt toutes les entrées planning
        this.entreesPlanning.map((entree) => {
          // On ajoute pour chaque entrée planning un objet dans le tableau évènement de la forme
          // { id: ID, name: String, start: Date, end: Date, color: String }
          evenements.push({
            id: entree.id,
            name: entree.label,
            start: entree.dateDebut,
            details: entree.description,
            end: entree.dateFin,
            color: this.colors[this.rnd(0, this.colors.length - 1)],
          });
        });

        // On update le tableau d'évènements
        this.events = evenements;
      }
    },
  },
};
</script>

