<template>
  <ViewLayout :tabs="tabs" :item="enseigne">
    <template v-slot:enseigne>
      <EnseigneActions :object="enseigne" :withView="false" size="xl" />
      <v-row class="align-center mx-auto">
        <v-col cols="12">
          <EnseigneObject :enseigneProp="enseigne" size="xl" />
        </v-col>
        <v-col cols="12">
          <EtablissementsList
            :enseigneID="enseigne.id"
            :noFilter="true"
            v-if="enseigne.id"
          />
        </v-col>
      </v-row>
    </template>
  </ViewLayout>
</template>

<script>
import { QUERY_enseigne } from "./graphql/queries";
import EnseigneObject from "@/components/COMPONENTS-annuaire/EnseigneObject.vue";
import EtablissementsList from "@/components/COMPONENTS-annuaire/EtablissementsList.vue";
import EnseigneActions from "./EnseigneActions.vue";
import ViewLayout from "@/components/COMPONENTS-skeleton/Layouts/ViewLayout.vue";

export default {
  name: "EnseigneView",

  components: {
    EnseigneObject,
    EnseigneActions,
    EtablissementsList,
    ViewLayout,
  },

  data: () => ({
    enseigne: {},

    tabs: [
      { icon: "mdi-store", displayName: "Enseigne", name: "enseigne" },
      { name: "commentaires" },
      { name: "fichiers" },
    ],
  }),

  apollo: {
    enseigne: {
      query: QUERY_enseigne,
      variables() {
        let idQuery = this.showPopup
          ? this.id
          : this.$route.params.id
          ? this.$route.params.id
          : this.id;
        return {
          id: idQuery,
        };
      },
    },
  },
  props: {
    showPopup: {
      type: Boolean,
    },
    id: {
      type: String,
    },
  },
};
</script>