<template>
  <div>
    <v-autocomplete
      v-bind:value="value"
      v-on:input="$emit('input', $event)"
      :items="fichiers"
      :error-messages="errorMessages"
      chips
      :prepend-icon="prependIcon"
      append-outer-icon="mdi-plus-circle"
      clearable
      :multiple="multiple"
      :filter="filterFiles"
      :label="label"
      dense
      item-text="id"
      @click:append-outer="openDialog = !openDialog"
    >
      <template v-slot:selection="data">
        <FichierObject format="chip" :item="data.item" />
      </template>
      <template v-slot:item="data">
        <FichierObject :item="data.item" />
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { QUERY_fichiers } from "./graphql/queries";
import FichierObject from "./FichierObject.vue";

export default {
  name: "FichierSelector",
  components: {
    FichierObject,
  },
  props: {
    value: null,
    errorMessages: { type: Array },
    label: { type: String, default: "Fichiers" },
    prependIcon: { type: String, default: "mdi-file" },
    multiple: { type: Boolean, default: false },
    without: { type: Array },
    typeParent: { type: String },
    idParent: { type: String },
  },
  data: () => ({
    openDialog: false,
    filesFiltered: [],
  }),
  created() {
    this.$root.$on("fichiers-stockages--update-files", this.updateQuery);
  },
  apollo: {
    fichiers: {
      query: QUERY_fichiers,
      fetchPolicy: "no-cache",
      variables() {
        return {
          pagination: {
            filter: {
              customSearch: [
                { "meta.typeParent": this.typeParent },
                { "meta.idParent": this.idParent },
              ],
            },
          },
        };
      },
    },
  },
  watch: {},
  mounted: function () {},
  methods: {
    filterFiles(item, queryText) {
      return (
        item.displayName.toLowerCase().indexOf(queryText.toLowerCase()) > -1 ||
        item.fileName.toLowerCase().indexOf(queryText.toLowerCase()) > -1
      );
    },
    updateQuery: function () {
      this.$apollo.queries.fichiers.refetch();
    },
  },
};
</script>