<template>
  <div>
    <template v-if="userMe.role == 'NEW_USER'">
      <div class="text-body-1 py-6" align="center">
        Il semblerait que l'adresse
        <span class="font-weight-medium">{{ userMe.email }}</span> ne soit pas
        validée !<br />
        Un email, contenant un lien d'activation vous a été envoyé.
      </div>
      <v-row>
        <v-col align="center">
          <v-btn color="primary" @click="resendEmail()">
            Renvoyer l'email de vérification
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="center">
          <v-btn color="warning" @click="logout"> Se déconnecter </v-btn>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <div class="text-body-1 pb-6" align="center">
        Bonjour
        <span class="font-weight-medium">{{ userMe.email }}</span>
        !<br />
        Pour quelle organisation souhaitez-vous travailler ?
      </div>
      <v-divider />
      <template v-if="habilitationsByMe.length == 0">
        <OrganisationForm disposition="inside" :hasResetButton="false"
          @authentification-habilitation--organisation-create="init()" />
      </template>
      <v-list-item-group two-line v-else>
        <template v-for="(item, index) in habilitationsByMe">
          <v-list-item :key="'habilitation' + index">
            <v-list-item-content @click="connectOrganisation(item.organisation.id)">
              <v-list-item-title v-text="item.organisation.label" />
              <v-list-item-subtitle v-text="item.role" />
            </v-list-item-content>
          </v-list-item>
          <v-divider :key="'div' + item.organisation.id" />
        </template>
      </v-list-item-group>
      <v-btn color="error" @click="logout">
        Déconnexion
      </v-btn>
    </template>
  </div>
</template>
<script>
import {
  onLogout,
  onLogin,
  isOrganisationLogged,
} from "@/components/COMPONENTS-skeleton/vue-apollo";
import {
  QUERY_userLoginOrganisation,
  QUERY_userMe,
  QUERY_resendConfirmationMail,
} from "./graphql/queries";

import OrganisationForm from "./OrganisationForm.vue";


export default {
  name: "OrganisationChoice",
  components: {
    OrganisationForm,
  },
  data() {
    return {
      userMe: {},
      habilitationsByMe: [],
    };
  },
  props: {
    organisation: null,
  },
  computed: {
    // généré par vue-apollo.js, on conserve l'URL d'entrée afin de pouvoir rediriger vers cette dernière une fois authentifié
    startLocation() {
      let startLocation = localStorage.getItem("startLocation");
      return startLocation;
    },
  },
  watch: {
    habilitationsByMe: function () {
      if (
        this.habilitationsByMe.length == 1 &&
        this.userMe.role != "NEW_USER"
      ) {
        this.connectOrganisation(this.habilitationsByMe[0].organisation.id);
      }
    },

    userMe: function () {
      if (this.userMe.role) {
        if (this.userMe.role != "NEW_USER") {
          this.testStartLocation();
        }
      }
    },
  },
  mounted: function () {
    this.init();
  },
  methods: {
    init() {
      if (this.organisation) {
        this.connectOrganisation(this.organisation);
      } else {
        this.$apollo
          .query({
            query: QUERY_userMe,
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            this.userMe = data.data.userMe;
            this.habilitationsByMe = data.data.habilitationsByMe;
            if (
              this.habilitationsByMe.length == 1 &&
              this.userMe.role != "NEW_USER"
            ) {
              this.connectOrganisation(
                this.habilitationsByMe[0].organisation.id
              );
            }
          });
      }
    },

    connectOrganisation(organisationId) {
      if (isOrganisationLogged()) {
        this.testStartLocation();
      } else {
        this.resetTheme();
        this.$apollo
          .mutate({
            mutation: QUERY_userLoginOrganisation,
            fetchPolicy: "no-cache",
            variables: {
              organisationNameOrId: organisationId,
              userAgent: navigator.userAgent,
            },
          })
          .then(async (data) => {
            const { token, refreshToken } = data.data.userLoginOrganisation;

            if (token) {
              await onLogin(
                this.$apollo.provider.defaultClient,
                token,
                refreshToken
              );
              this.testStartLocation();
            } else {
              await this.logout();
              this.$router.push({ path: "/" }).catch(() => { });
            }
          });
      }
    },

    async logout() {
      this.resetTheme();
      await onLogout(this.$apollo.provider.defaultClient);
      this.$emit("logging");
    },

    redirect() { },

    resendEmail() {
      this.$apollo.query({
        query: QUERY_resendConfirmationMail,
      });
    },

    resetTheme() {
      this.$vuetify.theme.dark = false;
      this.$vuetify.theme.themes.light.primary = this.$store.state.colorDefaultLight;
      this.$vuetify.theme.themes.dark.primary = this.$store.state.colorDefaultDark;
    },

    testStartLocation() {
      if (
        !this.startLocation ||
        this.startLocation == "/" ||
        this.startLocation.includes("/verifier") ||
        this.startLocation.startsWith("/?") ||
        this.startLocation.startsWith(
          "/authentification-habilitation/oauth/callback"
        ) ||
        this.startLocation.startsWith(
          "/authentification-habilitation/habilitations"
        ) ||
        this.startLocation.startsWith(
          "/authentification-habilitation/organisations"
        ) ||
        this.startLocation.startsWith(
          "/authentification-habilitation/all"
        ) ||
        this.startLocation.includes("/otp-verification") ||
        this.startLocation.includes("/magic-link") ||
        this.startLocation.includes("/reinitialisation/mot-de-passe")
      ) {
        if (this.userMe.role == "USER") {
          this.$router.push({ path: "/home" }).catch(() => { });
        } else {
          this.$router.push({ path: "/accueil" }).catch(() => { });
        }

      } else {
        this.$router.push({ path: this.startLocation }).catch(() => { });
      }
    },
  },
};
</script>