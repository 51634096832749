<template>
  <div>
    <SelectorLayout
      ref="selector"
      v-model="item"
      @fetch="fetch"
      :prepend-icon="prependIcon"
      :label="label"
      :addAction="addAction"
    >
      <template v-slot:selection="data">
        <PartenaireObject
          :partenaireProp="data.item"
          v-bind="data.attrs"
          :input-value="data.selected"
          @click="data.select"
          size="xs"
          action="none"
        />
      </template>

      <template v-slot:item="data">
        <PartenaireObject :partenaireProp="data.item" />
      </template>
    </SelectorLayout>
  </div>
</template>

<script>
import { QUERY_partenaires } from "./graphql/queries";
import PartenaireObject from "@/components/COMPONENTS-projet-satelease/PartenaireObject.vue";
import SelectorLayout from "@/components/COMPONENTS-skeleton/Layouts/SelectorLayout.vue";
export default {
  name: "PartenairesSelector",
  components: {
    PartenaireObject,
    SelectorLayout,
  },
  data: () => ({
    item: null,
  }),
  props: {
    value: null,
    errorMessage: { type: String },
    label: { type: String, default: "Partenaire" },
    prependIcon: { type: String, default: "mdi-handshake-outline" },
    multiple: { type: Boolean, default: false },
    addAction: { type: String, default: "/satelease/partenaires/add" },
  },

  methods: {
    // Fetch les partenaires en fonction du filtre
    fetch() {
      this.$refs.selector.loading = true;
      this.$apollo
        .query({
          query: QUERY_partenaires,
          variables: {
            pagination: this.$refs.selector.pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          this.$refs.selector.items = data.partenaires;
          this.$refs.selector.loading = false;
        });
    },
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        this.item = val && val.id ? val.id : val;
        this.fetch();
      },
    },

    item: {
      deep: true,
      handler(val) {
        if (val === null) {
          this.$emit("input", null);
        } else {
          this.$emit("input", val);
        }
      },
    },
  },
};
</script>