<template>
  <div>
    <CounterLayout :compteur="fichiersCount">
      <template v-slot:icon>mdi-paperclip</template>
      <template v-slot:badge><slot name="badge"></slot></template>
    </CounterLayout>
  </div>
</template>

<script>
import CounterLayout from "@/components/COMPONENTS-skeleton/Layouts/CounterLayout.vue";
import { QUERY_fichiersCount } from "./graphql/queries";
export default {
  name: "FichiersCounter",
  components: {
    CounterLayout,
  },
  props: {
    customSearch: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data: () => ({}),
  apollo: {
    fichiersCount: {
      query: QUERY_fichiersCount,
      variables() {
        return {
          pagination: {
            filter: {
              customSearch: this.customSearch,
            },
          },
        };
      },
      fetchPolicy: "no-cache",
    },
  },

  created() {
    this.$root.$on("fichiersStockagesUpdateFichiers", this.updateQuery);
  },

  mounted: function () {},
  watch: {
    // filesByTypeAndId: function () {
    //   this.orderFiles();
    // },
  },
  methods: {
    updateQuery: function () {
      this.$apollo.queries.fichiersCount.refetch();
    },
  },
};
</script>

