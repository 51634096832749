<template>
  <ViewLayout :tabs="tabs" :item="fournisseur">
    <template v-slot:fournisseur>
      <FournisseurActions :object="fournisseur" size="xl" />
      <v-row class="align-center mx-auto">
        <v-col cols="12">
          <FournisseurObject :fournisseurProp="fournisseur" size="xl" />
        </v-col>
        <v-col cols="12">
          <DevisFournisseursList
            v-if="fournisseur.id"
            :customSearch="[{ fournisseur: fournisseur.id }]"
            :noFilter="true"
            :title="'Liste des devis de ' + fournisseur.codeFournisseur"
          />
        </v-col>
        <v-col cols="12">
          <FactureFournisseursList
            v-if="fournisseur.id"
            :customSearch="[{ fournisseur: fournisseur.id }]"
            :noFilter="true"
            :title="'Liste des factures de ' + fournisseur.codeFournisseur"
          />
        </v-col>
      </v-row>
    </template>
  </ViewLayout>
</template>

<script>
import { QUERY_fournisseur } from "./graphql/queries";

import DevisFournisseursList from "./DevisFournisseursList.vue";
import FactureFournisseursList from "./FactureFournisseursList.vue";
import FournisseurActions from "./FournisseurActions.vue";
import FournisseurObject from "@/components/COMPONENTS-devis-facture/FournisseurObject.vue";
import ViewLayout from "@/components/COMPONENTS-skeleton/Layouts/ViewLayout.vue";

export default {
  name: "FournisseurView",

  components: {
    DevisFournisseursList,
    FactureFournisseursList,
    FournisseurObject,
    FournisseurActions,
    ViewLayout,
  },

  props: {
    id: {
      type: String,
    },
    showPopup: {
      type: Boolean,
    },
  },

  data: () => ({
    fournisseur: {},
    tabs: [
      {
        icon: "mdi-briefcase-account",
        displayName: "Fournisseur",
        name: "fournisseur",
      },
      { name: "commentaires" },
      { name: "fichiers" },
    ],
  }),

  apollo: {
    fournisseur: {
      query: QUERY_fournisseur,
      variables() {
        let idQuery = this.showPopup
          ? this.id
          : this.$route.params.id
          ? this.$route.params.id
          : this.id;
        return {
          id: idQuery,
        };
      },
    },
  },
};
</script>