<template>
  <ListLayout
    ref="list"
    :headers="headers"
    @fetch="init"
    @showMultipleActions="showMultipleActions = $event"
  >
    <template v-slot:multipleActions>
      <AffaireActions
        :multiple="true"
        :object="$refs.list ? $refs.list.itemsSelected : []"
        size="xl"
        v-show="showMultipleActions"
        @fetch="init"
        @loading="$refs.list.loading = true"
      />
    </template>
    <template v-slot:[`item.object`]="{ item }">
      <AffaireObject :affaireProp="item" size="xs" />
    </template>
    <template v-slot:[`item.dateCreation`]="{ item }">
      {{ item.dateCreation | shortDateFR }}
    </template>
    <template v-slot:[`item.client`]="{ item }">
      <div 
        v-for="(repartition, index) in item.financement.repartition" 
        :key="index"
      >
        <ClientObject
          v-if="index <= 1"
          :clientID="repartition.client.id"
          size="xs"
        />
        <p v-else>...</p>
      </div>
    </template>
    <template v-slot:[`item.montantFinancement`]="{ item }">
      {{ item.montantFinancement | monetaire }}
    </template>

    <template v-slot:[`item.datePrevInstallation`]="{ item }">
      {{ item.datePrevInstallation | shortDateFR }}
    </template>

    <template v-slot:[`item.datePrevLoyer`]="{ item }">
      <AffaireSimulation :affaire="item" size="text-datePremierLoyer" />
    </template>

    <template v-slot:[`item.demandeAccords`]="{ item }">
      <AffaireObject :affaireProp="item" size="xs-demandeAccords" />
    </template>

    <template v-slot:[`item.gainsPotentiels`]="{ item }">
      <AffaireSimulation :affaire="item" size="xs" />
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <AffaireActions
        :object="item"
        size="xs"
        @fetch="init"
        @loading="$refs.list.loading = true"
      />
    </template>
  </ListLayout>
</template>

<script>
import { QUERY_affaires } from "./graphql/queries";

import AffaireActions from "./AffaireActions.vue";
import AffaireObject from "./AffaireObject.vue";
import AffaireSimulation from "./AffaireSimulation";
import ClientObject from "@/components/COMPONENTS-devis-facture/ClientObject.vue";
import ListLayout from "@/components/COMPONENTS-skeleton/Layouts/ListLayout.vue";

export default {
  name: "AffairesList",

  components: {
    AffaireActions,
    AffaireObject,
    AffaireSimulation,
    ClientObject,
    ListLayout,
  },

  data: () => ({
    headers: [
      { text: "", value: "object", sortable: false },
      { text: "Numéro d'affaire", value: "numeroAffaire" },
      { text: "Date", value: "dateCreation" },
      { text: "Client", value: "client", sortable: false },
      { text: "Montant Financé", value: "montantFinancement" },
      { text: "Date installation (prev)", value: "datePrevInstallation" },
      { text: "Date Loyer (prev)", value: "datePrevLoyer" },
      { text: "Demandes d'accords", value: "demandeAccords", sortable: false },
      { text: "Gains potentiels", value: "gainsPotentiels" },
      { text: "Fichiers", value: "fichiers" },
      { text: "Commentaires", value: "commentaires" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    showMultipleActions: false,
  }),

  methods: {
    fetchAffaires() {
      let pagination = JSON.parse(JSON.stringify(this.$refs.list.pagination));
      if (
        this.$route.path.startsWith("/satelease/affaires/list") &&
        this.$route.query.lock
      ) {
        pagination.filter.customSearch.push({
          contratLocation: { $ne: null },
        });
      } else {
        pagination.filter.customSearch.push({
          contratLocation: null,
        });
      }
      this.$refs.list.loading = true;
      this.$apollo
        .query({
          query: QUERY_affaires,
          variables: {
            pagination: pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;

          this.$refs.list.items = data.affaires;
          this.$refs.list.itemsCount = data.affairesCount;
          this.$refs.list.loading = false;
        });
    },
    init() {
      this.fetchAffaires();
    },
  },
};
</script>