<template>
  <div>
    <ActionLayout
      :actions="actions"
      :size="size"
      :action="action"
      @fetch="$emit('fetch')"
      :mainIcon="mainIcon"
    />
  </div>
</template>

<script>
import ActionLayout from "@/components/COMPONENTS-skeleton/Layouts/ActionLayout.vue";

import {
  MUTATION_pointFacturationDelete,
  MUTATION_pointFacturationUpdate,
} from "./graphql/mutations.js";

export default {
  name: "EntrepriseActions",
  components: {
    ActionLayout,
  },

  props: {
    withView: { type: Boolean, default: true },
    mainIcon: { type: String, default: "mdi-wallet" },
    object: { type: [Array, Object] },
    size: { type: String, default: "md" },
    action: { type: String, default: "router" },
    multiple: { type: Boolean, default: false },
  },

  computed: {
    actions() {
      return [
        {
          text: "Editer",
          icon: "mdi-pencil",
          path: "/devis-facture/point-facturations/edit/" + this.object.id,
          acl: "/devis-facture/point-facturations/edit",
          color: "",
          conditions: { multiple: false, isActif: true },
        },
        {
          text: "Supprimer",
          icon: "mdi-delete",
          method: "editIsActif",
          acl: "/devis-facture/point-facturations/deleted",
          color: "error",
          conditions: { multiple: true, isActif: true },
        },
        {
          text: "Restauration",
          icon: "mdi-restore",
          method: "editIsActif",
          acl: "/devis-facture/point-facturations/edit",
          color: "",
          conditions: { multiple: true, isActif: false },
        },
        {
          text: "Supprimer définitivement",
          icon: "mdi-delete",
          method: "delete",
          acl: "/devis-facture/point-facturations/deleted",
          color: "",
          conditions: { multiple: true, isActif: false },
        },
        {
          text: "Visualiser",
          icon: "mdi-eye",
          path: "/devis-facture/point-facturations/view/" + this.object.id,
          acl: "/devis-facture/point-facturations/view",
          color: "",
          conditions: { size: "md xs" },
        },
      ];
    },
  },

  /**
   * Permet d'utiliser la popup de validation
   */
  created() {
    this.$root.$on("deletePointFacturation", this.deletePointFacturation);
    this.$root.$on("editPointFacturation", this.editPointFacturation);
  },

  methods: {
    /**
     * Supprimer une pointFacturation grâce à son id
     */
    deletePointFacturation(id) {
      this.$apollo
        .mutate({
          mutation: MUTATION_pointFacturationDelete,
          variables: { id: id },
        })
        .then(() => {
          this.$emit("fetch");
        });
    },

    delete(obj) {
      // infos qui s'afficheront dans le popup
      let toValid = {
        titre: "Suppression définitive d'un point de facturation",
        texte: `Êtes-vous sûr de vouloir supprimer définitivement le point de facturation ${obj.label} ?`,
        type: "warning",
        toReturn: {
          function: "deletePointFacturation", // cette fonction change en fonction du composant (ex: PersonneActions.vue ça serait deletePersonne,
          //qu'il faut créer à la place de deletePointFacturation)
          arg: obj.id,
        },
      };

      // Envoi l'info pour ouvrir la popup
      this.$root.$emit("popupValidation", toValid);
    },

    async editPointFacturation(obj) {
      if (!Array.isArray(obj)) {
        obj = [obj];
      }
      this.$emit("loading");
      for (let item of obj) {
        let pointFacturation = {
          id: item.id,
          isActif: !item.isActif,
          //Ajouter les champs nécessaires
        };
        await this.$apollo.mutate({
          mutation: MUTATION_pointFacturationUpdate,
          variables: {
            input: pointFacturation,
          },
        });
      }
      this.$emit("fetch");
    },

    editIsActif(obj) {
      // Si obj n'est pas un tableau alors on le transforme en tableau
      if (!Array.isArray(obj)) {
        obj = [obj];
      }
      let toValid = {
        titre: obj[0].isActif
          ? "Suppression de point(s) de facturation"
          : "Restauration de point(s) de facturation",
        texte: obj[0].isActif
          ? `Êtes-vous sûr de vouloir supprimer ${obj.length} point(s) de facturation ? ` +
            'Ils seront toujours présents dans l\'onglet "Corbeille"'
          : `Êtes-vous sûr de vouloir restaurer ${obj.length}  point(s) de facturation ?`,
        type: "warning",
        toReturn: {
          function: "editPointFacturation", // à changer selon le composant, comme deletePointFacturation
          arg: obj,
        },
      };

      this.$root.$emit("popupValidation", toValid);
    },
  },
};
</script>