export default [
  //------------------ COmptes ----------------------
  {
    path: '/comptabilite/comptes',
    name: 'Liste des comptes',
    component: () => import('./CompteComptablesView.vue'),
    meta: {
      acl: "/comptabilite/comptes"
    }
  },
  {
    path: '/comptabilite/comptes/add',
    name: 'Ajouter un compte auxiliaire',
    component: () => import('./CompteComptableForm.vue'),
    meta: {
      acl: "/comptabilite/comptes/add"
    }
  },
  {
    path: '/comptabilite/parametres',
    name: 'Plan comptable',
    component: () => import('./ComptabiliteOrganisationParametresForm.vue'),
    meta: {
      acl: "/comptabilite/parametres"
    }
  },
]