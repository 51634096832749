<template>
  <div>
    <ActionLayout :actions="actions" :size="size" :action="action" />
  </div>
</template>

<script>
import ActionLayout from "@/components/COMPONENTS-skeleton/Layouts/ActionLayout.vue";

import {
  MUTATION_factureFournisseurCreate,
  MUTATION_factureFournisseurDelete,
  MUTATION_factureFournisseurUpdate,
} from "./graphql/mutations.js";

export default {
  name: "DevisActions",

  components: {
    ActionLayout
  },

  props: {
    object: {
      type: [Array, Object],
    },
    size: {
      type: String,
      default: "md",
    },
    action: {
      type: String,
      default: "router",
    },
    multiple: { type: Boolean, default: false },
  },

  computed: {
    actions() {
      return [
        {
          text: "Editer",
          icon: "mdi-pencil",
          path: "/devis-facture/facture-fournisseurs/edit/" + this.object.id,
          acl: "/devis-facture/facture-fournisseurs/edit",
          color: "",
          conditions: { multiple: false, isActif: true },
        },
        {
          text: "Dupliquer",
          icon: "mdi-content-duplicate",
          method: "duplicate",
          acl: "/devis-facture/facture-fournisseurs/add",
          color: "green",
          conditions: { multiple: false, isActif: true },
        },
        {
          text: "Supprimer",
          icon: "mdi-delete",
          method: "editIsActif",
          acl: "/devis-facture/facture-fournisseurs/deleted",
          color: "error",
          conditions: { multiple: true, isActif: true },
        },
        {
          text: "Restauration",
          icon: "mdi-restore",
          method: "editIsActif",
          acl: "/devis-facture/facture-fournisseurs/edit",
          color: "",
          conditions: { multiple: true, isActif: false },
        },
        {
          text: "Supprimer définitivement",
          icon: "mdi-delete",
          method: "delete",
          acl: "/devis-facture/facture-fournisseurs/deleted",
          color: "error",
          conditions: { multiple: false, isActif: false },
        },
        {
          text: "Visualiser",
          icon: "mdi-eye",
          path: "/devis-facture/facture-fournisseurs/view/" + this.object.id,
          acl: "/devis-facture/facture-fournisseurs/view",
          color: "",
          conditions: {},
        },
      ];
    },
  },

  beforeDestroy() {
    this.$root.$off("deleteFacture");
    this.$root.$off("editFacture");
    this.$root.$off("component-" + this._uid);
  },

  created() {
    this.$root.$on("deleteFacture", this.deleteFacture);
    this.$root.$on("editFacture", this.editFacture);
    this.$root.$on("assetsCreateFromFactureLignes", this.duplicateFacture);
  },

  methods: {
    /**
     * Supprimer une facture grâce à son id
     */
    deleteFacture(id) {
      this.$apollo
        .mutate({
          mutation: MUTATION_factureFournisseurDelete,
          variables: {
            id: id,
          },
        })
        .then(() => {
          this.$emit("fetch");
        });
    },

    /**
     * Fonction à avoir dans tous les composants Actions (infos à changer selon le composant)
     * Permet de lancer la popup de validation
     *
     * obj : l'objet a supprimé
     */
    delete(obj) {
      // infos qui s'afficheront dans le popup
      let toValid = {
        titre: "Suppression définitive d'une facture",
        texte: `Êtes-vous sûr de vouloir supprimer définitivement la facture ${obj.numeroFacture} ?`,
        type: "warning",
        toReturn: {
          function: "deleteFacture", // cette fonction change en fonction du composant (ex: PersonneActions.vue ça serait deletePersonne,
          //qu'il faut créer à la place de deleteFacture)
          arg: obj.id,
        },
      };

      // Envoi l'info pour ouvrir la popup
      this.$root.$emit("popupValidation", toValid);
    },

    duplicate(obj) {
      let lignesWithAssets = obj.lignes.filter(e => e.equipements && e.equipements.length > 0);

      if(lignesWithAssets.length > 0) {
        let assets = [];

        lignesWithAssets.forEach(ligne => {
          assets.push(ligne.equipements)
        })

        this.$router.push({ path: `/assets/assets/duplicate?popup=true&componentParent=component-${this._uid}&assets=${JSON.stringify(assets)}` })
      }else {
        this.duplicateFacture(obj);
      }
    },

    duplicateFacture(args) {
      let obj = {};
      if(args.uid == "component-" + this._uid) {
        obj = JSON.parse(JSON.stringify(this.object));
        for(let i = 0; i < obj.lignes.length; i++) {
          for(let j = 0; j < obj.lignes[i].equipements.length; j++) {
            obj.lignes[i].equipements[j] = args.data[i][j]._id;
          }
        }
      }else if(args.id){
        obj = args;
      }

      if(obj.client) {
        for(let i = 0; i < obj.lignes.length; i++) {
          delete obj.lignes[i].__typename;
        }
        delete obj.dateCreation;
        delete obj.__typename;
        delete obj.id;
        delete obj.montantHT,
        delete obj.montantTVA;
        obj.fournisseur = obj.fournisseur && obj.fournisseur.id ? obj.fournisseur.id : obj.fournisseur;
        obj.devisAssocie = obj.devisAssocie && obj.devisAssocie.id ? obj.devisAssocie.id : obj.devisAssocie;
        obj.client = obj.client && obj.client.id ? obj.client.id : obj.client;
        obj.numeroFacture = obj.numeroFacture + " 2";

        this.$apollo
          .mutate({
            mutation: MUTATION_factureFournisseurCreate,
            variables: {
              input: obj
            }
          })
          .then(__ => {
            this.$router.push({ path: `/devis-facture/facture-fournisseurs/edit/${__.data.factureFournisseurCreate.id}` })
          })
      }
    },

    /**
     * Fonction qui édite une facture
     *
     * obj : l'objet à éditer
     */
    async editFacture(obj) {
      // Si obj n'est pas un tableau alors on le transforme en tableau
      if (!Array.isArray(obj)) {
        obj = [obj];
      }
      // Selon le schéma, il est obligé d'avoir le siren et la raison sociale dans EntepriseInput
      // Bien vérifier dans le schéma si jamais il y a une erreur du style "$input etc.."
      this.$emit("loading");
      for (let item of obj) {
        let facture = {
          id: item.id,
          nom: item.nom,
          client: item.client.id,
          isActif: !item.isActif,
        };
        await this.$apollo.mutate({
          mutation: MUTATION_factureFournisseurUpdate,
          variables: {
            input: facture,
          },
        });
      }
      this.$emit("fetch");
    },

    /**
     * Fonction à avoir dans tous les composants (infos à changer)
     */
    editIsActif(obj) {
      // Si obj n'est pas un tableau alors on le transforme en tableau
      if (!Array.isArray(obj)) {
        obj = [obj];
      }
      let toValid = {
        titre: obj[0].isActif
          ? "Suppression de facture(s)"
          : "Restauration de facture(s)",
        texte: obj.isActif
          ? `Êtes-vous sûr de vouloir supprimer ${obj.length} facture(s) ? ` +
          'Elles seront toujours présentes dans l\'onglet "Corbeille"'
          : `Êtes-vous sûr de vouloir restaurer ${obj.length} facture(s) ?`,
        type: "warning",
        toReturn: {
          function: "editFacture", // à changer selon le composant, comme deleteFacture
          arg: obj,
        },
      };

      this.$root.$emit("popupValidation", toValid);
    },
  },
};
</script>
