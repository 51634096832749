<template>
  <ViewLayout :tabs="tabs" :item="marque">
    <template v-slot:marque>
      <MarqueActions :object="marque" :withView="false" size="xl" />
      <v-row class="align-center mx-auto">
        <v-col cols="12">
          <MarqueObject size="xl" :marqueProp="marque" />
        </v-col>
        <v-col cols="12">
          <ModelesList
            :marqueID="marque.id"
            v-if="marque.id"
            :noFilter="true"
          />
        </v-col>
      </v-row>
    </template>
  </ViewLayout>
</template>

<script>
import { QUERY_marque } from "./graphql/queries";
import MarqueObject from "./MarqueObject.vue";
import MarqueActions from "./MarqueActions.vue";
import ModelesList from "./ModelesList.vue";
import ViewLayout from "@/components/COMPONENTS-skeleton/Layouts/ViewLayout.vue";

export default {
  name: "MarqueView",

  components: {
    MarqueObject,
    MarqueActions,
    ModelesList,
    ViewLayout,
  },

  data: () => ({
    marque: {},

    tabs: [
      { icon: "mdi-material-design", displayName: "Marque", name: "marque" },
      { name: "commentaires" },
      { name: "fichiers" },
    ],
  }),

  apollo: {
    marque: {
      query: QUERY_marque,
      variables() {
        let idQuery = this.showPopup
          ? this.id
          : this.$route.params.id
          ? this.$route.params.id
          : this.id;
        return {
          id: idQuery,
        };
      },
    },
  },
  props: {
    showPopup: {
      type: Boolean,
    },
    id: {
      type: String,
    },
  },
};
</script>