import gql from 'graphql-tag';
import { ENTREE_PLANNING_FIELDS, PLANNING_FIELDS } from './fragments'
import { USER_FIELDS } from '@/components/COMPONENTS-authentification-habilitation/graphql/fragments'

export const QUERY_entreesPlanning = gql`
  query {
    entreesPlanning {
      ...EntreePlanningFields
    }
  }${ENTREE_PLANNING_FIELDS}
`;

export const QUERY_plannings = gql`
  query {
    plannings{
      ...PlanningFields
      authorizedUsers{
        user{
          ...UserFields
        }
        right
      }
    }  
  }${PLANNING_FIELDS}${USER_FIELDS}
`;

export const QUERY_planning = gql`
  query($id:ID!){
    planning(id:$id){
      ...PlanningFields
     
    }    
  }${PLANNING_FIELDS}
`;

export const QUERY_entreesPlannings = gql`
  query($input:EntreesPlanningsInput){
    entreesPlannings(input:$input){
      ...EntreePlanningFields
    }
  }${ENTREE_PLANNING_FIELDS}
`
