<template>
  <div>
    <v-row>
      <v-col>
        <v-select
          :items="typeList"
          v-model="input.type"
          label="Action à mener"
          prepend-icon="mdi-account-question"
        />
      </v-col>
      <v-col>
        <v-row>
          <v-col>
            <PersonneSelector
              :addAction="null"
              v-model="input.destinataire.personneAssociee"
              v-if="!destinataireASpecifie"
              dense
            />
          </v-col>
          <v-col cols="2">
            <v-btn class="ml-2 mb-2" icon @click="autoFill">
              <v-icon> mdi-auto-fix </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="!destinataireASpecifie">
      <v-col>
        <v-text-field
          v-model="input.destinataire.nom"
          label="Nom du destinataire"
          prepend-icon="mdi-account"
          dense
        />
      </v-col>
      <v-col>
        <v-text-field
          v-model="input.destinataire.email"
          label="Adresse email"
          prepend-icon="mdi-at"
          dense
        />
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <v-text-field v-model="input.destinataire.nom" label="Rôle" dense />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-textarea
          v-model="input.message"
          label="Message"
          prepend-icon="mdi-message-text"
          dense
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { QUERY_personne } from "@/components/COMPONENTS-annuaire/graphql/queries";

import PersonneSelector from "@/components/COMPONENTS-annuaire/PersonneSelector.vue";

export default {
  name: "AccordEtapeForm",

  components: {
    PersonneSelector,
  },

  props: {
    destinataireASpecifie: { type: Boolean, default: false },
    value: null,
    typeAccord: undefined,
  },

  data: () => ({
    input: {
      destinataire: {
        nom: "",
        email: "",
        personneAssociee: null,
      },
    },
  }),

  computed: {
    typeList() {
      if (this.typeAccord && this.typeAccord == "ACCORD_DOCUSIGN") {
        return [
          {
            text: "Signature électronique",
            value: "DOCUSIGN_SIGNATURE_SIMPLE",
          },
          {
            text: "Signature électronique avec vérification d'identité",
            value: "DOCUSIGN_SIGNATURE_VERIFICATION_IDENTITE_AVANCEE",
          },
          { text: "Reçoit une copie", value: "DOCUSIGN_RECOIT_COPIE" },
          {
            text: "Spécifier un destinataire",
            value: "DOCUSIGN_SPECIFIER_DESTINATAIRE",
          },
          { text: "Approbation", value: "DOCUSIGN_APPROBATION" },
        ];
      } else {
        return [
          {
            text: "Signature éléctronique DocuSign simple ",
            value: "SIGNATURE_ELECTRONIQUE_DOCUSIGN",
          },
          {
            text: "Signature éléctronique DocuSign avec vérification identité avancée ",
            value: "SIGNATURE_ELECTRONIQUE_DOCUSIGN_AVANCEE",
          },
          {
            text: "Signature éléctronique simple ",
            value: "SIGNATURE_ELECTRONIQUE_AIDALINFO",
          },
          { text: "Signature manuscrite", value: "SIGNATURE_MANUSCRITE" },
          { text: "Relecture et validation", value: "RELECTURE_VALIDATION" },
        ];
      }
    },
  },

  mounted() {
    if (this.value && this.value.destinataire) {
      this.input = this.value;
    }
  },

  methods: {
    autoFill() {
      if (this.input.destinataire.personneAssociee) {
         
         
        this.$apollo
          .query({
            query: QUERY_personne,
            variables: {
              id: this.input.destinataire.personneAssociee,
            },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
             
            let personne = data.data.personne;
            this.input.destinataire.nom = personne.prenom + " " + personne.nom;
            if (personne.adressesMails && personne.adressesMails[0]) {
               
              this.input.destinataire.email = personne.adressesMails[0].adresse;
            } else {
              this.input.destinataire.email = "";
            }
          });
      }
    },
  },
  watch: {
    input: {
      deep: true,
      handler(val) {
        if (val === null || val === {}) {
          this.$emit("input", {});
        } else {
          this.$emit("input", val);
        }
      },
    },

    value: function (val) {
      this.input = val;
    },
  },
};
</script>