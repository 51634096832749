import gql from 'graphql-tag';

import { USER_FIELDS, ORGANISATION_FIELDS } from '@/components/COMPONENTS-authentification-habilitation/graphql/fragments';
import { REQUETE_API_ORGANISATION_FIELDS } from './fragments';

export const QUERY_requeteApiOrganisation = gql`
  query($id: ID!) {
    requeteApiOrganisation(id: $id) {
      ...RequeteApiOrganisationFields
      organisation {
        ...OrganisationFields
      }
      user {
        ...UserFields
      }
    }
  }${REQUETE_API_ORGANISATION_FIELDS}, ${USER_FIELDS}, ${ORGANISATION_FIELDS}
`;

export const QUERY_requetesApiOrganisation = gql`
  query($pagination: PaginationObject!) {
    requetesApiOrganisation(pagination: $pagination) {
      ...RequeteApiOrganisationFields
      organisation {
        ...OrganisationFields
      }
      user {
        ...UserFields
      }
    }
    requetesApiOrganisationCount(pagination: $pagination) 
  }${REQUETE_API_ORGANISATION_FIELDS}, ${USER_FIELDS}, ${ORGANISATION_FIELDS}
`;