import gql from 'graphql-tag';
import { ANNUAIREAPPLICATIONPARAMETRES_FIELDS, PERSONNE_FIELDS, ENTREPRISE_FIELDS, ENSEIGNE_FIELDS, ETABLISSEMENT_FIELDS, ADRESSE_POSTALE_FIELDS } from './fragments.js';
import { USER_FIELDS } from '@/components/COMPONENTS-authentification-habilitation/graphql/fragments'

export const QUERY_annuaireApplicationParametres = gql`
  query {
    annuaireApplicationParametres {
      ...AnnuaireApplicationParametresFields
    }
  }${ANNUAIREAPPLICATIONPARAMETRES_FIELDS}
`;

export const QUERY_annuaireOrganisationParametres = gql`
  query {
    annuaireOrganisationParametres {
      id
      entrepriseAPI
    }
  }
`;

export const QUERY_etablissementAdresseLibelleVilles = gql`
query($pagination: PaginationObject){
  etablissementAdresseLibelleVilles(pagination: $pagination)
}
`;

export const QUERY_enseigne = gql`
  query ($id: ID!) {
    enseigne(id: $id) {
      ...EnseigneFields
      createdBy{
        ...UserFields
      }   
    }
  }${ENSEIGNE_FIELDS}${USER_FIELDS}
`;

export const QUERY_enseignes = gql`
  query($pagination: PaginationObject) {
    enseignes (pagination: $pagination){
      ...EnseigneFields

    }
    enseignesCount(pagination: $pagination)
  }${ENSEIGNE_FIELDS}
`;

export const QUERY_enseignesCount = gql`
query($pagination: PaginationObject){
  enseignesCount(pagination: $pagination)
  }
`;

export const QUERY_etablissement = gql`
query($id:ID!){
  etablissement(id:$id){
    ...EtablissementFields
    createdBy{
      ...UserFields
    }
    enseigne{
      ...EnseigneFields
    }
    adresse{
      ...AdressePostaleFields
    }
  }
}${ETABLISSEMENT_FIELDS}${ENTREPRISE_FIELDS}${ENSEIGNE_FIELDS}${USER_FIELDS}
`;

export const QUERY_etablissements = gql`
query($pagination: PaginationObject){
  etablissements(pagination: $pagination){
    ...EtablissementFields
    enseigne{
      ...EnseigneFields
    }
    adresse{
        ...AdressePostaleFields
    }
  }
  etablissementsCount(pagination: $pagination)
  }${ETABLISSEMENT_FIELDS}${ENSEIGNE_FIELDS}${ADRESSE_POSTALE_FIELDS}
`;

export const QUERY_etablissementsCount = gql`
query($pagination: PaginationObject){
  etablissementsCount(pagination: $pagination)
  }
`;

export const QUERY_etablissementsID = gql`
query($pagination: PaginationObject){
  etablissements(pagination: $pagination){
    id
    }
  }
`;

export const QUERY_personne = gql`
query($id:ID!){
  personne(id:$id){
    ...PersonneFields
    createdBy{
        ...UserFields
    }
    fonctions{
      fonction
      entreprise{
        ...EntrepriseFields
      }    
      etablissement{
        ...EtablissementFields
      }
      pouvoirSignature
    }
    associatedUser{
      ...UserFields
    }
  }  
}
${PERSONNE_FIELDS}${USER_FIELDS}${USER_FIELDS}${ENTREPRISE_FIELDS}${ETABLISSEMENT_FIELDS}
`;

export const QUERY_personnes = gql`
query($pagination: PaginationObject){
  personnes(pagination: $pagination){
    ...PersonneFields
    fonctions{
      fonction
      pouvoirSignature
      etablissement{
        ...EtablissementFields
      }
      entreprise{
        ...EntrepriseFields
      } 
    }
    associatedUser{
      ...UserFields
    }
  }
  personnesCount(pagination: $pagination)
}${ENTREPRISE_FIELDS}${ETABLISSEMENT_FIELDS}${PERSONNE_FIELDS}${USER_FIELDS}
`;

export const QUERY_personneFonctions = gql`
query{
  personneFonctions
}
`;

export const QUERY_personnesCount = gql`
query($pagination: PaginationObject){
  personnesCount(pagination: $pagination)
  }
`;

export const QUERY_entreprise = gql`
query($id:ID!){
  entreprise(id:$id){
    ...EntrepriseFields
    createdBy{
        ...UserFields
    }
    etablissements{
      ...EtablissementFields
      adresse{
        ...AdressePostaleFields
      }
    }
    holding{
      ...EntrepriseFields
    }
  }
}${ETABLISSEMENT_FIELDS}${ENTREPRISE_FIELDS}${ADRESSE_POSTALE_FIELDS}${USER_FIELDS}
`;

export const QUERY_entreprises = gql`
query($pagination: PaginationObject){
  entreprises(pagination: $pagination){
    ...EntrepriseFields
    holding{
      ...EntrepriseFields
    }
  }
  entreprisesCount(pagination: $pagination)
}${ENTREPRISE_FIELDS}
`;

export const QUERY_entreprisesID = gql`
query($pagination: PaginationObject){
  entreprises(pagination: $pagination){
    id
  }
}
`;

export const QUERY_entrepriseInformationFromSiren = gql`
query($siren:String!){
  entrepriseInformationFromSiren(siren:$siren)
}
`;

export const QUERY_entrepriseFromSiren = gql`
query($siren:String!){
  entrepriseFromSiren(siren:$siren){
    ...EntrepriseFields
    representants {
      ...PersonneFields
      fonctions{
        fonction
        pouvoirSignature
        etablissement{
          ...EtablissementFields
        }
        entreprise{
          ...EntrepriseFields
        } 
      }
    }
    etablissements{
      ...EtablissementFields
      enseigne{
      ...EnseigneFields
      }
    }
  }
}${ETABLISSEMENT_FIELDS}${ENTREPRISE_FIELDS}${ENSEIGNE_FIELDS}${PERSONNE_FIELDS}
`;

export const QUERY_entreprisesInformationsFromRaisonSociale = gql`
  query($raisonSociale: String!){
    entreprisesInformationsFromRaisonSociale(raisonSociale:$raisonSociale) 
  }
`

export const QUERY_entreprisesInformationsFromFilters = gql`
  query($filters: JSONObject!){
    entreprisesInformationsFromFilters(filters:$filters) 
  }
`
export const QUERY_entreprisesCount = gql`
query($pagination: PaginationObject){
  entreprisesCount(pagination: $pagination)
  }
`;

