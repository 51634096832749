var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ListLayout',{ref:"list",attrs:{"headers":_vm.headers,"title":"Liste des assets","noFilter":_vm.noFilter},on:{"fetch":_vm.init,"showMultipleActions":function($event){_vm.showMultipleActions = $event}},scopedSlots:_vm._u([{key:"multipleActions",fn:function(){return [_c('AssetActions',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMultipleActions),expression:"showMultipleActions"}],attrs:{"multiple":true,"object":_vm.$refs.list ? _vm.$refs.list.itemsSelected : [],"size":"xl"},on:{"fetch":_vm.init,"loading":function($event){_vm.$refs.list.loading = true}}})]},proxy:true},{key:"customFilters",fn:function(){return [_c('MarqueSelector',{attrs:{"addAction":null,"multiple":""},model:{value:(_vm.customFilters.marque),callback:function ($$v) {_vm.$set(_vm.customFilters, "marque", $$v)},expression:"customFilters.marque"}}),_c('ModeleSelector',{attrs:{"marque":_vm.customFilters.marque,"addAction":null,"multiple":""},model:{value:(_vm.customFilters.modele),callback:function ($$v) {_vm.$set(_vm.customFilters, "modele", $$v)},expression:"customFilters.modele"}})]},proxy:true},{key:"item.object",fn:function(ref){
var item = ref.item;
return [_c('AssetObject',{attrs:{"assetProp":item,"size":"xs"}})]}},{key:"item.marque",fn:function(ref){
var item = ref.item;
return [_c('MarqueObject',{attrs:{"marqueProp":item.marque,"size":"xs"}})]}},{key:"item.modele",fn:function(ref){
var item = ref.item;
return [_c('ModeleObject',{attrs:{"modeleProp":item.modele,"size":"xs"}})]}},{key:"item.numeroSerie",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.numeroSerie)+" ")]}},{key:"item.numeroInventaire",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.numeroInventaire)+" ")]}},{key:"item.etatAsset",fn:function(ref){
var item = ref.item;
return [_c('EtatAssetObject',{attrs:{"etatAsset":item.etatAsset,"size":"xs"}})]}},{key:"item.contratsAssocies",fn:function(ref){
var item = ref.item;
return _vm._l((item.contratsAssocies),function(elem,index){return _c('div',{key:index},[_c('ContratLocationObject',{attrs:{"contratLocationProp":elem,"size":"xs"}})],1)})}},{key:"item.finGarantie",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("shortDateFR")(item.finGarantie))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('AssetActions',{attrs:{"object":item,"size":"xs"},on:{"fetch":_vm.init,"loading":function($event){_vm.$refs.list.loading = true}}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }