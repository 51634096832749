<template>
  <ViewLayout v-if="habilitation" :tabs="tabs" :item="habilitation">
    <template v-slot:habilitation>
      <HabilitationActions :object="habilitation" :withView="false" size="xl" />
      <v-row class="align-center mx-auto">
        <v-col cols="12">
          <HabilitationObject size="xl" :habilitationProp="habilitation" />
        </v-col>
      </v-row>
    </template>
  </ViewLayout>
</template>
<script>
import { QUERY_habilitation } from "./graphql/queries";
import HabilitationObject from "./HabilitationObject.vue";
import HabilitationActions from "./HabilitationActions.vue";
import ViewLayout from "@/components/COMPONENTS-skeleton/Layouts/ViewLayout.vue";

export default {
  name: "HabilitationView",
  components: {
    HabilitationObject,
    HabilitationActions,
    ViewLayout,
  },

  data: () => ({
    habilitation: {},
    tabs: [
      {
        icon: "mdi-package-variant-closed",
        displayName: "habilitation",
        name: "habilitation",
      },
      { name: "commentaires" },
      { name: "fichiers" },
    ],
  }),

  methods: {
    init() {
      let idQuery = this.showPopup
              ? this.id
              : this.$route.params.id
              ? this.$route.params.id
              : this.id;

      this.$apollo
        .query({
          query: QUERY_habilitation,
          variables: {
            id: idQuery
          }
        })
        .then(__ => {
          this.habilitation = __.data.habilitation;
        })
    }
  },

  mounted() {
    this.init();
  }
};
</script>