import gql from 'graphql-tag';
import {
  EMAIL_FIELDS,
  EMAIL_TEMPLATE_FIELDS,
  ENVOI_NOTIFICATIONS_ORGANISATION_PARAMETRES_FIELDS,
  TEMPLATE_FIELDS
} from './fragments';

export const QUERY_email = gql`
query($id: ID!){
  email(id: $id){
    ...EmailFields
  }
}${EMAIL_FIELDS}
`;

export const QUERY_emails = gql`
query($pagination: PaginationObject){
  emails(pagination: $pagination){
    ...EmailFields
  }
  emailsCount(pagination: $pagination)
}${EMAIL_FIELDS}
`;

export const QUERY_emailTemplate = gql`
query($id:ID!){
  emailTemplate(id:$id){
    ...EmailTemplateFields
  }
}${EMAIL_TEMPLATE_FIELDS}
`;

export const QUERY_emailTemplates = gql`
query($pagination:PaginationObject){
  emailTemplates(pagination:$pagination){
    ...EmailTemplateFields
  }
}${EMAIL_TEMPLATE_FIELDS}
`;

export const QUERY_emailTemplatesCount = gql`
query($pagination:PaginationObject){
  emailTemplatesCount(pagination:$pagination)
}
`;

export const QUERY_envoiNotificationsOrganisationParametres = gql`
  query {
    envoiNotificationsOrganisationParametres {
      ...EnvoiNotificationsOrganisationParametresFields
    }
  }${ENVOI_NOTIFICATIONS_ORGANISATION_PARAMETRES_FIELDS}
`;

export const QUERY_envoyerEmail = gql`
query($input: EmailInput!, $organisation: String!){
  envoyerEmail(input: $input, organisation: $organisation)
}
`;

export const QUERY_template = gql`
  query($id: Int!) {
    template(id: $id) {
      ...TemplateFields
    }
  }${TEMPLATE_FIELDS}
`;

export const QUERY_templates = gql`
  query($pagination: PaginationObject) {
    templates(pagination: $pagination) {
      ...TemplateFields
    }
  }${TEMPLATE_FIELDS}
`;