<template>
  <div>
    <ActionLayout
      :actions="actions"
      :size="size"
      :action="action"
      @fetch="$emit('fetch')"
      :mainIcon="mainIcon"
    />
  </div>
</template>

<script>
import ActionLayout from "@/components/COMPONENTS-skeleton/Layouts/ActionLayout.vue";
import {
  MUTATION_accordDelete,
  MUTATION_accordUpdate,
} from "./graphql/mutations.js";
export default {
  name: "AccordActions",
  components: {
    ActionLayout,
  },

  props: {
    withView: {
      type: Boolean,
      default: true,
    },
    mainIcon: {
      type: String,
      default: "mdi-handshake",
    },
    object: {
      type: [Array, Object],
    },
    size: {
      type: String,
      default: "md",
    },
    action: {
      type: String,
      default: "router",
    },
    multiple: { type: Boolean, default: false },
  },

  computed: {
    actions() {
      return [
        {
          text: "Editer",
          icon: "mdi-pencil",
          path: "/accord-validation/accords/edit/" + this.object.id,
          acl: "/accord-validation/accords/edit",
          color: "",
          conditions: { multiple: false, isActif: true },
        },
        {
          text: "Dupliquer",
          icon: "mdi-arrange-send-to-back",
          path: "/accord-validation/accords/add?fromAccord=" + this.object.id,
          acl: "/accord-validation/accords/add",
          color: "blue",
          conditions: { multiple: false, isActif: true },
        },
        {
          text: "Supprimer",
          icon: "mdi-delete",
          method: "editIsActif",
          acl: "/accord-validation/accords/deleted",
          color: "error",
          conditions: { multiple: true, isActif: true },
        },
        {
          text: "Restauration",
          icon: "mdi-restore",
          method: "editIsActif",
          acl: "/accord-validation/accords/edit",
          color: "",
          conditions: { multiple: true, isActif: false },
        },
        {
          text: "Supprimer définitivement",
          icon: "mdi-delete",
          method: "delete",
          acl: "/accord-validation/accords/definitive-delete",
          color: "error",
          conditions: { multiple: false, isActif: false },
        },
        {
          text: "Visualiser",
          icon: "mdi-eye",
          path: "/accord-validation/accords/view/" + this.object.id,
          acl: "/accord-validation/accords/view",
          color: "",
          conditions: {},
        },
      ];
    },
  },

  /**
   * Permet d'utiliser la popup de validation
   */
  created() {
    this.$root.$on("deleteAccord", this.deleteAccord);
    this.$root.$on("editAccord", this.editAccord);
  },

  methods: {
    /**
     * Supprimer un accord grâce à son id
     */
    deleteAccord(id) {
      this.$apollo
        .mutate({
          mutation: MUTATION_accordDelete,
          variables: {
            id: id,
          },
        })
        .then(() => {
          this.$emit("fetch");
        });
    },

    /**
     * Fonction à avoir dans tous les composants Actions (infos à changer selon le composant)
     * Permet de lancer la popup de validation
     *
     * obj : l'objet a supprimé
     */
    delete(obj) {
      // infos qui s'afficheront dans le popup
      let toValid = {
        titre: "Suppression définitive d'un accord",
        texte: `Êtes-vous sûr de vouloir supprimer définitivement l'accord ${obj.numeroAccord} ?`,
        type: "warning",
        toReturn: {
          function: "deleteAccord", // cette fonction change en fonction du composant (ex: PersonneActions.vue ça serait deletePersonne,
          //qu'il faut créer à la place de deleteAsset)
          arg: obj.id,
        },
      };

      // Envoi l'info pour ouvrir la popup
      this.$root.$emit("popupValidation", toValid);
    },

    /**
     * Fonction qui édite une asset
     *
     * obj : l'objet à éditer
     */
    async editAccord(obj) {
       
      // Si obj n'est pas un tableau alors on le transforme en tableau
      if (!Array.isArray(obj)) {
        obj = [obj];
      }
      // Selon le schéma, il est obligé d'avoir le siren et la raison sociale dans EntepriseInput
      // Bien vérifier dans le schéma si jamais il y a une erreur du style "$input etc.."
      this.$emit("loading");
      for (let item of obj) {
        let accord = {
          id: item.id,
          isActif: !item.isActif,
        };
        await this.$apollo.mutate({
          mutation: MUTATION_accordUpdate,
          variables: {
            input: accord,
          },
        });
      }
      this.$emit("fetch");
    },

    /**
     * Fonction à avoir dans tous les composants (infos à changer)
     */
    editIsActif(obj) {
      // Si obj n'est pas un tableau alors on le transforme en tableau
      if (!Array.isArray(obj)) {
        obj = [obj];
      }
      let toValid = {
        titre: obj[0].isActif
          ? "Suppression d'accord(s)"
          : "Restauration d'accord(s)",
        texte: obj[0].isActif
          ? `Êtes-vous sûr de vouloir supprimer ${obj.length} accord(s) ? ` +
            'Ils seront toujours présents dans l\'onglet "Corbeille"'
          : `Êtes-vous sûr de vouloir restaurer ${obj.length} accord(s) ?`,
        type: "warning",
        toReturn: {
          function: "editAccord", // à changer selon le composant, comme deleteAsset
          arg: obj,
        },
      };

      this.$root.$emit("popupValidation", toValid);
    },
  },
};
</script>
