<template>
  <FormLayout
    icon="mdi-bank"
    @ajouter="ajouter"
    @modifier="modifier"
    @reinitialiser="init"
    typeItem="Bailleur"
    :item="input"
    :disabled="input.entrepriseAssociee ? false : true"
  >
    <TitleLayout titre="Informations administratives" />
    <EntrepriseSelector v-model="input.entrepriseAssociee" label="Société" />

    <v-row>
      <ItemsTableForm
        v-model="input.documentsAFournir"
        title="Liste des documents à joindre au contrat"
        icon="mdi-file-question"
      />
    </v-row>
    <TitleLayout
      titre="Informations bancaires"
      sousTitre="Utilisées dans les autorisations de prélèvement"
    />
    <template v-if="input.informationCreancier">
      <v-row>
        <v-col>
          <v-text-field
            v-model="input.informationCreancier.raisonSociale"
            label="Raison sociale"
          />
        </v-col>
        <v-col>
          <v-text-field
            prepend-icon="mdi-office-building-marker"
            v-model="input.informationCreancier.adresse"
            label="Adresse"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            prepend-icon="mdi-office-building-marker"
            v-model="input.informationCreancier.cpVille"
            label="Code Postal - Ville"
          />
        </v-col>
        <v-col>
          <v-text-field
            prepend-icon="mdi-flag"
            v-model="input.informationCreancier.pays"
            label="Pays"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            prepend-icon="mdi-contain"
            v-model="input.informationCreancier.ics"
            v-mask="'XXXX XXXX XXX XX'"
            label="Identifiant Créancier SEPA (ICS)"
          />
        </v-col>
      </v-row>
    </template>
  </FormLayout>
</template>

<script>
import { mapState } from "vuex";

import {
  MUTATION_bailleurCreate,
  MUTATION_bailleurUpdate,
} from "./graphql/mutations";
import { QUERY_bailleur } from "./graphql/queries";

import EntrepriseSelector from "@/components/COMPONENTS-annuaire/EntrepriseSelector";
import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";
import ItemsTableForm from "@/components/COMPONENTS-skeleton/ItemsTableForm.vue";
import TitleLayout from "@/components/COMPONENTS-skeleton/Layouts/TitleLayout.vue";

export default {
  name: "BailleurForm",

  components: {
    EntrepriseSelector,
    FormLayout,
    ItemsTableForm,
    TitleLayout,
  },

  props: {
    componentParent: undefined,
  },

  data: () => ({
    bailleur: {
      informationCreancier: {},
    },
    input: {},
  }),

  computed: {
    ...mapState(["formsErrors"]),
  },

  mounted: function () {
    this.init();
  },

  methods: {
    ajouter() {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_bailleurCreate,
          variables: { input: toSend },
        })
        .then((__) => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "Le bailleur a été créé",
          });

          if (this.componentParent) {
            this.$root.$emit(this.componentParent, __.data.bailleurCreate);
          }

          this.$root.$emit("skeleton--close");
          this.init();
        });
    },

    init() {
      if (
        this.$route.path.startsWith(
          "/satelease/refinancement/bailleurs/edit/"
        ) &&
        this.$route.params.id
      ) {
        let id = this.$route.params.id;
        this.$apollo
          .query({
            query: QUERY_bailleur,
            variables: { id: id },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let bailleur = data.data.bailleur;
            this.input = JSON.parse(JSON.stringify(bailleur));
          });
      } else {
        this.input = JSON.parse(JSON.stringify(this.bailleur));
      }
    },

    inputConverter(arg) {
      const obj = JSON.parse(JSON.stringify(arg));

      let input = {
        entrepriseAssociee:
          obj.entrepriseAssociee && obj.entrepriseAssociee.id
            ? obj.entrepriseAssociee.id
            : obj.entrepriseAssociee,
        documentsAFournir: obj.documentsAFournir,
        informationCreancier: {
          raisonSociale: obj.informationCreancier.raisonSociale,
          adresse: obj.informationCreancier.adresse,
          cpVille: obj.informationCreancier.cpVille,
          pays: obj.informationCreancier.pays,
          ics: obj.informationCreancier.ics
            ? obj.informationCreancier.ics.replace(/\s/g, "")
            : "",
        },
      };

      obj.id != undefined ? (input.id = obj.id) : null;

      return input;
    },

    modifier() {
      let toSend = this.inputConverter(this.input);

      this.$apollo
        .mutate({
          mutation: MUTATION_bailleurUpdate,
          variables: { input: toSend },
        })
        .then(() => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "Le bailleur a été modifié",
          });
          this.$root.$emit("skeleton--close");
        });
    },
  },
};
</script>