<template>
  <div class="mx-auto">
    <v-row>
      <v-col cols="12" xl="6" lg="6" md="6" sm="12">
        <TitleLayout
          titre="Liste des templates email"
          icon="mdi-email-newsletter"
        />
        <EmailTemplatesTreeList
          :customSearch="customSearch"
          @duplique="duplique"
        />
      </v-col>
      <v-col cols="12" xl="6" lg="6" md="6" sm="12">
        <v-row>
          <v-col>
            <TitleLayout
              titre="Ajouter un template email"
              icon="mdi-email-newsletter"
            />
            <EmailTemplateForm :meta="meta" ref="emailTemplateForm" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import EmailTemplateForm from "./EmailTemplateForm.vue";
import EmailTemplatesTreeList from "./EmailTemplatesTreeList.vue";
import TitleLayout from "@/components/COMPONENTS-skeleton/Layouts/TitleLayout.vue";

export default {
  name: "EmailTemplatesObject",

  components: {
    EmailTemplateForm,
    EmailTemplatesTreeList,
    TitleLayout,
  },

  props: {
    meta: { type: Object },
    data: { type: Object },
    item: { type: Object },
  },

  computed: {
    customSearch() {
      let toReturn = [];
      let meta = this.meta;
      if (meta.typeParent) {
        toReturn.push({ "meta.typeParent": meta.typeParent });
      }
      if (meta.idParent) {
        toReturn.push({ "meta.idParent": meta.idParent });
      }
      if (meta.template) {
        toReturn.push({ "meta.template": meta.template });
      }
      if (meta.categorie) {
        toReturn.push({ "meta.categorie": meta.categorie });
      }
      return toReturn;
    },
  },

  methods: {
    duplique(val) {
      this.$refs.emailTemplateForm.input = val;
    },
  },
};
</script>
