<template>
  <FormLayout icon="mdi-barcode-scan" @ajouter="ajouter" @modifier="modifier" @reinitialiser="init" typeItem="un modèle"
    :item="input" :disabled="input.marque && input.nom ? false : true">
    <v-row class="justify-center align-center my-10">
      <v-col cols="12" xl="6" lg="6" md="6" sm="6">
        <v-text-field v-model="input.nom" :error-messages="formsErrors.modeleNomExist" name="label"
          label="Intitulé du modèle" prepend-icon="mdi-label" />
      </v-col>
      <v-col cols="12" xl="6" lg="6" md="6" sm="6">
        <MarqueSelector v-model="input.marque" :disabled="marque ? true : false" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field v-model="input.referenceFabricant" :error-messages="formsErrors.modeleReferenceFabricant"
          name="label" label="Référence fabricant" prepend-icon="mdi-barcode" />
      </v-col>
      <v-col>
        <FamilleSelector
          v-model="input.familles"
          :multiple="true"
        />
      </v-col>
    </v-row>
    <v-row just-center v-if="input.propriete">
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-row>
        <v-col cols="12" xl="6" lg="6" md="6" sm="6">
          <div v-for="(item, index) in items" :key="index">
            <template v-if="isFieldUsed(item.fieldName.toLowerCase())">
              <v-divider></v-divider>
              <v-row>
                <v-col>
                  <v-checkbox v-if="item.formType == 'checkbox'" :label="item.fieldName"
                    v-model="input.propriete[item.fieldName.toLowerCase()].value" />
                  <v-switch v-else-if="item.formType == 'switch'" :label="item.fieldName"
                    v-model="input.propriete[item.fieldName.toLowerCase()].value" />
                  <v-text-field v-else-if="item.formType == 'textfield'"
                    v-model="input.propriete[item.fieldName.toLowerCase()].value" :name="item.fieldName.toLowerCase()"
                    :label="formatName(item)" :prepend-icon="item.icon" />
                  <v-select v-else-if="item.formType == 'selector'"
                    v-model="input.propriete[item.fieldName.toLowerCase()].value" :name="item.fieldName.toLowerCase()"
                    :label="formatName(item)" :prepend-icon="item.icon" :items="item.items" />
                  <v-text-field v-else v-model="input.propriete[item.fieldName.toLowerCase()].value"
                    :name="item.fieldName.toLowerCase()" type="number" :label="formatName(item)"
                    :prepend-icon="item.icon" />
                </v-col>
                <v-col v-if="!item.obligatory" cols="2" align="center">
                  <v-btn class="mt-3" @click="removeField(item.fieldName.toLowerCase())" icon>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </div>
        </v-col>
      </v-row>
      <v-card-actions class="pl-0"> </v-card-actions>
      <v-row v-if="itemsListDisplay.length > 0" class="ma-4" justify="center" no-gutters>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="info" v-bind="attrs" v-on="on"> Ajouter plus </v-btn>
          </template>
          <v-list class="d-flex flex-column">
            <v-list-item v-for="(item, index) in itemsListDisplay" :key="index" @click="addField(item.fieldName.toLowerCase())">
              <v-list-item-title>
                <v-icon>{{ item.icon }}</v-icon>
                {{ item.fieldName }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-row>
    </v-row>
  </FormLayout>
</template>

<script>
import { mapState } from "vuex";

import {
  MUTATION_modeleCreate,
  MUTATION_modeleUpdate,
} from "./graphql/mutations";
import { QUERY_assetOrganisationParametres, QUERY_modele } from "./graphql/queries";

import FamilleSelector from "./FamilleSelector.vue";
import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";
import MarqueSelector from "./MarqueSelector.vue";

export default {
  name: "ModeleForm",

  components: {
    FamilleSelector,
    FormLayout,
    MarqueSelector,
  },

  data: () => ({
    modele: {
      propriete: {},
      famille: []
    },
    input: {},
    items: []
  }),

  props: {
    marque: undefined,
    componentParent: undefined,
  },

  computed: {
    ...mapState(["formsErrors"]),

    itemsListDisplay() {
      return this.items.filter(elem => elem.inUse == false);
    }
  },

  mounted: async function () {
    await this.init();
  },

  methods: {
    ajouter() {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_modeleCreate,
          variables: {
            input: toSend,
          },
        })
        .then(async (__) => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "Le modèle a été créé",
          });

          if (this.componentParent) {
            this.$root.$emit(this.componentParent, __.data.modeleCreate);
          }

          this.$root.$emit("skeleton--close");
          await this.init();
        });
    },

    addField(field) {
      let i = this.items.findIndex(elem => elem.fieldName.toLowerCase() == field);
      if (i == -1) return;
      this.items[i].inUse = true;
    },

    formatName(item) {
      return item.unite && item.unite != "" ? item.fieldName + ' (' + item.unite + ')' : item.fieldName;
    },

    async init() {
      this.items = [];
      let res = await this.$apollo
        .query({
          query: QUERY_assetOrganisationParametres,
          fetchPolicy: 'no-cache'
        })

      const data = res.data.assetOrganisationParametres;
      for (let caracteristic of data.caracteristics) {
        this.items.push({
          fieldName: caracteristic.fieldName,
          icon: caracteristic.icon,
          inUse: caracteristic.default || caracteristic.obligatory,
          formType: caracteristic.formType,
          unite: caracteristic.unite,
          items: caracteristic.items && caracteristic.items.length > 0 ? caracteristic.items : undefined,
          obligatory: caracteristic.obligatory,
          default: caracteristic.default
        });
      }

      if (
        this.$route.path.startsWith("/assets/modeles/edit/") &&
        this.$route.params.id
      ) {
        let id = this.$route.params.id;
        res = await this.$apollo
          .query({
            query: QUERY_modele,
            variables: {
              id: id,
            },
            fetchPolicy: 'no-cache'
          })
        let modele = res.data.modele;
        this.input = JSON.parse(JSON.stringify(modele));
        for (let [key, value] of Object.entries(modele.propriete)) {
          if (key != '__typename' && value.value != null) {
            let i = this.items.findIndex(elem => elem.fieldName.toLowerCase() == key);
            if (i != -1) {
              this.items[i].inUse = true;
            }
          }
        }
      } else {
        if (this.marque) {
          this.modele.marque = this.marque;
        }

        this.input = JSON.parse(JSON.stringify(this.modele));
      }

      for (let caracteristic of data.caracteristics) {
        if (!this.input.propriete[caracteristic.fieldName.toLowerCase()]) {
          this.input.propriete[caracteristic.fieldName.toLowerCase()] = {
            value: "",
            unite: ""
          }
        }
      }
    },

    inputConverter(arg) {
      const obj = JSON.parse(JSON.stringify(arg));

      let proprietes = {};
      for (const propriete in obj.propriete) {
        if (obj.propriete[propriete] && obj.propriete[propriete].value != "") {
          proprietes[propriete] = {
            value: obj.propriete[propriete].value,
            unite: this.items.filter(elem => elem.fieldName.toLowerCase() == propriete)[0].unite
          }
        }
      }

      let input = {
        nom: obj.nom,
        marque: obj.marque.id ? obj.marque.id : obj.marque,
        propriete: {
          ...proprietes
        },
        familles: obj.familles,
        referenceFabricant: obj.referenceFabricant,
      };

      obj.id != undefined ? (input.id = obj.id) : null;

      return input;
    },

    isFieldUsed(field) {
      let item = this.items.find(elem => elem.fieldName.toLowerCase() == field);
      return item.inUse;
    },

    modifier() {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_modeleUpdate,
          variables: {
            input: toSend,
          },
        })
        .then(() => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "Le modèle a été modifié",
          });
          this.$root.$emit("skeleton--close");
        });
    },

    removeField(field) {
      let i = this.items.findIndex(elem => elem.fieldName.toLowerCase() == field);
      if (i == -1) return;
      this.items[i].inUse = false;
      this.input.propriete[field] = {
        value: "",
        unite: ""
      }
    },
  },
};
</script>
