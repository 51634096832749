var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_vm._v(" Montant de la facture Hors Taxes : "),_c('MoneyLayout',{attrs:{"montant":_vm.montantFactureHT}})],1),_c('v-col',[_vm._v(" Montant de la facture TTC : "),_c('MoneyLayout',{attrs:{"montant":_vm.montantFactureTTC}})],1)],1),_c('v-data-table',{attrs:{"items":_vm.value,"headers":_vm.headerComputed},on:{"dblclick:row":_vm.editItem},scopedSlots:_vm._u([{key:"item.label",fn:function(ref){
var item = ref.item;
return [(item.equipements[0])?[_vm._l((item.equipements),function(equipement,index){return [_c('AssetObject',{key:index,attrs:{"assetID":equipement && equipement.id ? equipement.id : equipement,"size":"xs"}})]})]:[_vm._v(" "+_vm._s(item.label)+" ")]]}},{key:"item.prixUnitaire",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("monetaire")(item.prixUnitaire))+" ")]}},{key:"item.prixTotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("monetaire")((item.prixUnitaire * item.quantite)))+" ")]}},{key:"item.tauxTva",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.tauxTva)+" % ")]}},_vm._l((_vm.$scopedSlots),function(index,name){return {key:name,fn:function(data){return [_vm._t(name,null,null,data)]}}}),{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"info","fab":"","x-small":"","dark":"","disabled":_vm.value.indexOf(item) > 0 ? false : true},on:{"click":function($event){_vm.moveItem('up', _vm.value.indexOf(item))}}},[_c('v-icon',[_vm._v("mdi-chevron-up")])],1),_c('v-btn',{attrs:{"color":"info","fab":"","x-small":"","dark":"","disabled":_vm.value.indexOf(item) == _vm.value.length - 1 ? true : false},on:{"click":function($event){_vm.moveItem('down', _vm.value.indexOf(item))}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"info","fab":"","x-small":"","dark":""},on:{"click":function($event){_vm.editItem(_vm.value.indexOf(item))}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"error","fab":"","x-small":"","dark":""},on:{"click":function($event){_vm.deleteItem(_vm.value.indexOf(item))}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }