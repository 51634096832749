<template>
  <div>
    <div class="text-h6 blue-grey--text py-2">
      Liste des devis client attachés
    </div>
    <v-data-table
      dense
      :headers="headers"
      :items="deviss"
      :items-per-page="500"
      :hide-default-footer="true"
    >
      <template v-slot:[`item.devis`]="{ item }">
        <DevisClientObject :devisProp="item" size="xs" />
      </template>
      <template v-slot:[`item.client`]="{ item }">
        <ClientObject :clientID="item.client.id" size="xs" />
      </template>
      <template v-slot:[`item.montantAFinancer`]="{ item }">
        {{ item.montantAFinancer | monetaire }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small @click="removeDevis(item)" color="red">
          mdi-pin-off
        </v-icon>
      </template>
    </v-data-table>
    <v-row class="mt-5" justify="center">
      <v-col cols="8"> <DevisClientSelector v-model="devisSelected" /> </v-col>
      <v-col cols="12" xl="2" lg="1" md="2" sm="12" class="px-0">
        <v-btn
          v-bind="size"
          block
          class="py-4"
          color="primary"
          @click="addDevis"
          :disabled="devisSelected ? false : true"
        >
          <v-icon class="mr-2">mdi-pin</v-icon>
          Attacher
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { QUERY_devisClients } from "./graphql/queries";
import ClientObject from "./ClientObject.vue";
import DevisClientObject from "./DevisClientObject.vue";
import DevisClientSelector from "./DevisClientSelector.vue";

export default {
  name: "DevisClientAttach",
  components: {
    ClientObject,
    DevisClientObject,
    DevisClientSelector,
  },
  props: {
    value: [],
  },
  data: () => ({
    deviss: [],
    devisSelected: undefined,
    headers: [
      { text: "Devis", value: "devis", sortable: false },
      { text: "Client", value: "client", sortable: false },
      {
        text: "Montant à financer H.T.",
        value: "montantAFinancer",
        sortable: false,
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    devisItems: [],
  }),
  computed: {
    size() {
      const size = { xs: "x-small", sm: "small", lg: "large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
  },
  methods: {
    addDevis: function () {
      if (this.devisSelected) {
        let found = this.devisItems.find(
          (element) => element == this.devisSelected
        );

        if (!found) {
          this.devisItems.push(this.devisSelected);
        } else {
          this.$store.commit("addAlert", {
            type: "warning",
            text: "Le devis est déjà attaché",
          });
        }
        this.devisSelected = undefined;
      }
    },
    fetchDeviss: function () {
      this.$apollo
        .query({
          query: QUERY_devisClients,
          variables: {
            pagination: {
              limit: 500,
              filter: {
                customSearch: [{ _id: this.devisItems }],
              },
            },
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;

          this.deviss = data.deviss;
        });
    },
    removeDevis(item) {
      let index = this.devisItems.indexOf(item.id);
      if (index > -1) {
        this.devisItems.splice(index, 1);
      }
    },
  },
  watch: {
    value: function (val) {
      this.devisItems = val;
    },
    devisItems: function (val) {
      if (val === null || val === []) {
        this.$emit("input", []);
      } else {
        this.$emit("input", val);
        this.fetchDeviss();
      }
    },
  },
};
</script>

