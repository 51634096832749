<template>
  <div>
    <template v-if="user">
      <!--  SIZE XL -->
      <template v-if="size == 'xl'">
        <ObjectLayout size="xl">
          <template v-slot:icon>mdi-account</template>
          <template v-slot:titre> {{ user.email }}</template>
          <template v-slot:body>
            <ObjectLayout v-if="user.personneAssociee">
              <template v-slot:titre>
                Personne associée à l'utilisateur
              </template>
              <template v-slot:content>
                {{ user.personneAssociee.nom }}
                {{ user.personneAssociee.prenom }}
              </template>
            </ObjectLayout>
            <ObjectLayout v-if="user.email">
              <template v-slot:titre>Email de l'utilisateur</template>
              <template v-slot:content>{{ user.email }}</template>
            </ObjectLayout>
            <ObjectLayout v-if="user.dateCreation">
              <template v-slot:titre>Date de création</template>
              <template v-slot:content>{{
                user.dateCreation | shortDateAndHourFR
              }}</template>
            </ObjectLayout>
            <ObjectLayout v-if="user.dateConnexion">
              <template v-slot:titre>Date de connexion</template>
              <template v-slot:content>{{
                user.dateConnexion | shortDateAndHourFR
              }}</template>
            </ObjectLayout>
            <ObjectLayout v-if="user.role">
              <template v-slot:titre>Rôle de l'utilisateur</template>
              <template v-slot:content>{{ user.role }}</template>
            </ObjectLayout>
          </template>
          <template v-slot:body2>
            <div
              class="d-flex justify-center align-center"
              style="width: 100%; height: 220px"
            >
              <!-- TODO: METTRE UN PERSONNE OBJECT ICI -->
              <!-- <PersonneAvatar
                size="150"
                :personne="user.personneAssociee"
                :justDisplay="true"
                class="mr-2"
                v-if="user.personneAssociee"
              /> -->
            </div>
          </template>
        </ObjectLayout>
      </template>
      <!--  SIZE MD -->
      <template v-if="size == 'md'">
        <v-row>
          <v-list-item-avatar tile>
            <PersonneAvatar
              size="28"
              :personne="user.personneAssociee"
              :justDisplay="true"
              class="mr-2"
              v-if="user.personneAssociee"
            />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-html="user.email"></v-list-item-title>
            <v-list-item-subtitle>
              {{ user.dateConnexion | shortDateFR }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-row>
      </template>
      <!--  SIZE XS -->
      <template v-if="size == 'xs'">
        <ObjectLayout
          :action="action"
          size="xs"
          :onClickPush="`/authentification-habilitation/users/view/${user.id}`"
        >
          <template v-slot:image>
            <PersonneAvatar
              size="14"
              :personne="user.personneAssociee"
              :justDisplay="true"
              v-if="user.personneAssociee"
            />
          </template>
          <template v-slot:content>{{ user.email }}</template>
        </ObjectLayout>
      </template>
    </template>
  </div>
</template>

<script>
import { QUERY_user } from "./graphql/queries";
import ObjectLayout from "@/components/COMPONENTS-skeleton/Layouts/ObjectLayout.vue";
//import TemplatesList from '../COMPONENTS-fichiers-stockages/TemplatesList.vue';
export default {
  name: "UserObject",

  components: {
    ObjectLayout,
    //TemplatesList,
  },

  props: {
    size: { type: String, default: "md" },

    userProp: {
      type: Object,
      default() {
        return {};
      },
    },

    userID: {
      type: String,
    },
    action: { type: String, default: "router" },
  },

  watch: {
    userProp: function () {
      this.init();
    },
    userID: function () {
      this.init();
    },
  },
  computed: {
    label() {
      if (this.user.personneAssociee) {
        return (
          this.user.personneAssociee.prenom +
          " " +
          this.user.personneAssociee.nom
        );
      } else {
        return this.user.email;
      }
    },
  },

  data: () => ({
    user: undefined,
    openDialogShowUser: false,
  }),
  mounted: function () {
    this.init();
  },

  methods: {
    init() {
      if (this.userID) {
        let id = this.userID;
        this.$apollo
          .query({
            query: QUERY_user,
            variables: { id: id },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let user = data.data.user;
            this.user = JSON.parse(JSON.stringify(user));
          });
      } else {
        this.user = JSON.parse(JSON.stringify(this.userProp));
      }
    },
  },
};
</script>

