<template>
  <v-container fluid>
    <v-stepper vertical v-model="stepper">
      <v-stepper-step step="1"> Reprise de données </v-stepper-step>
      <v-stepper-content step="1">
        <v-row class="pt-8">
          <v-col>
            <p class="font-weight-regular text-center">
              Vous pouvez sélectionner une affaire à partir de laquelle vous
              voulez créer un nouveau contrat !
            </p>
            <AffaireSelector v-model="affaireReprise" />
            <AffaireObject
              v-if="affaireReprise"
              :affaireID="affaireReprise"
              size="xl"
            />
          </v-col>
        </v-row>
        <v-col align="center">
          <v-btn color="primary" @click="stepper++" class="mt-4">
            Etape suivante
          </v-btn>
        </v-col>
      </v-stepper-content>
      <v-stepper-step step="2"> Informations du contrat </v-stepper-step>
      <v-stepper-content step="2">
        <ContratLocationForm :fromAffaire="affaireReprise" />
      </v-stepper-content>
      <v-stepper-step step="3"> Signature du contrat </v-stepper-step>
      <v-stepper-content step="3"> </v-stepper-content>
      <v-stepper-step step="4"> Livraison et installation</v-stepper-step>
      <v-stepper-content step="4"> </v-stepper-content>
      <v-stepper-step step="5"> Cession du contrat </v-stepper-step>
      <v-stepper-content step="5"> </v-stepper-content>
    </v-stepper>
  </v-container>
</template>

<script>
import AffaireSelector from "./AffaireSelector.vue";
import AffaireObject from "./AffaireObject.vue";
import ContratLocationForm from "./ContratLocationForm.vue";
import { mapState } from "vuex";

export default {
  name: "ContratLocationProcessus",
  components: {
    AffaireObject,
    AffaireSelector,
    ContratLocationForm,
  },
  props: {
    contratLocation: {
      type: Object,
      default() {
        return {
          id: undefined,
        };
      },
    },
  },
  data: () => ({
    affaireReprise: undefined,
    projetSateLeaseOrganisationParametres: {},

    stepper: 1,
    panel: [0, 1, 2, 3],
    input: {},
  }),
  watch: {
    //Si on desactive l'input, on supprime la donnée stockée
    numeroContratInput: function (val) {
      if (!val) {
        this.input.numeroContrat = this.contrat.numeroContrat;
      }
    },
    stepper: function (val) {
      console.log("stepper", val);
      // A l'étape 2 on récupère les informations de l'affaire pour les intégrer au contrat
    },
  },
  computed: {
    ...mapState(["formsErrors"]),
  },
  mounted: async function () {},

  methods: {},
};
</script>