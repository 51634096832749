<template>
  <v-container>
    <v-form @submit.prevent="validForm" class="justify-center">
      <TitleLayout titre="Un lien de confirmation sera envoyé afin de vérifier la validité de l'adresse mail saisie" />
      <v-text-field
        v-model="input.email"
        label="Adresse mail"
        prepend-icon="mdi-at"
        :error-messages="formsErrors.userEmail"
      ></v-text-field>

      <v-row class="mt-2">
        <v-col align="center" justify="center">
          <v-btn
            type="submit"
            color="primary"
            class="mx-2"
          >
            <v-icon class="mr-2">
              mdi-pencil
            </v-icon>
            Modifier
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import TitleLayout from "@/components/COMPONENTS-skeleton/Layouts/TitleLayout.vue";
import { MUTATION_userMeEmailUpdate } from "./graphql/mutations.js";
import { mapState } from "vuex";

export default {
  name: "UserMeEditEmail",

  components: {
    TitleLayout
  },

  data: () => ({
    input: {
      email: ""
    }
  }),

   computed: {
    ...mapState(["formsErrors"]),
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.input = {
        email: ''
      }
    },

    validForm() {
      this.$apollo
        .mutate({
          mutation: MUTATION_userMeEmailUpdate,
          variables: {
            email: this.input.email
          }
        })
        .then(() => {
          this.$store.commit("addAlert", {
            type: "success",
            text: "Mail envoyé",
          });
          this.$root.$emit("skeleton--close");
          this.init();
          this.$router.push({ path: '/authentification-habilitation/user-me/edit' });
        })
        .catch(() => {
          this.init();
        })
    }
  }
}
</script>