<template>
  <v-container fluid class="verif y-12">
    <v-sheet
      light
      elevation="7"
      max-height="300"
      max-width="900"
      class="mx-auto"
    >
      <v-row class="text-center">
        <v-col
          cols="12"
          class="
            secondary--text
            text-center text-lg-h4 text-md-h4 text-sm-h5 text-h6 text-uppercase
            py-10
          "
          >Validation de votre compte</v-col
        >
        <v-col
          lg="12"
          md="10"
          sm="10"
          cols="10"
          class="
            text-lg-subtitle-1 text-md-subtitle-2 text-sm-body-2 text-caption
            font-weight-medium
          "
          ><UserUrlCodeVerif @confirmed="onConfirm"
        /></v-col>

        <v-spacer></v-spacer>
        <v-col class="py-10" cols="" xl="12" lg="12" md="12" sm="12">
          <template v-if="this.isConfirmed">
            <v-btn
              class="
                text-lg-subtitle-1
                text-md-subtitle-2
                text-sm-body-2
                text-caption
              "
              color="primary"
              @click="reconnection"
              >Reconnexion</v-btn
            >
          </template>
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>

<script>
import UserUrlCodeVerif from "./UserUrlCodeVerif.vue";
import { onLogout } from "@/components/COMPONENTS-skeleton/vue-apollo";

export default {
  name: "UserFriendlyUrlCodeVerif",

  data: function () {
    return {
      isConfirmed: false,
    };
  },

  components: {
    UserUrlCodeVerif,
  },

  methods: {
    onConfirm() {
      this.isConfirmed = true;
    },

    async reconnection() {
      await onLogout(this.$apollo.provider.defaultClient);
      this.$router.push({ name: "Home" });
    },
  },
};
</script>
<style scoped>
.verif {
  /* background-image: url("../../assets/3459071.jpg"); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  height: 100vh;
}
.encadrement {
  border: solid 2px;
  border-color: #dcdcdc;
}
</style>