<template>
  <div>
    <ListLayout
      ref="list"
      :headers="headers"
      :title="title"
      :noFilter="noFilter"
      @fetch="init"
      @showMultipleActions="showMultipleActions = $event"
    >
      <template v-slot:multipleActions>
        <FactureClientActions
          :multiple="true"
          :withView="false"
          :object="$refs.list ? $refs.list.itemsSelected : []"
          size="xl"
          v-show="showMultipleActions"
          @fetch="init"
          @loading="$refs.list.loading = true"
        />
      </template>
      <template v-slot:customFilters>
        <ClientSelector :addAction="null" v-model="customFilters.client" />
      </template>
      <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
        <slot :name="name" v-bind="data"></slot>
      </template>
      <template v-slot:[`item.object`]="{ item }">
        <FactureClientObject :factureClientProp="item" size="xs" />
      </template>
      <template v-slot:[`item.dateFacture`]="{ item }">
        {{ item.dateFacture | shortDateFR }}
      </template>
      <template v-slot:[`item.dateEcheance`]="{ item }">
        {{ item.dateEcheance | shortDateFR }}
      </template>
      <template v-slot:[`item.client`]="{ item }">
        <ClientObject
          :clientID="item.client.id"
          size="xs"
          action="popup"
          v-if="item.client.id"
        />
      </template>
      <template v-slot:[`item.fournisseur`]="{ item }">
        <FournisseurObject
          :fournisseurID="item.fournisseur.id"
          size="xs"
          action="popup"
          v-if="item.fournisseur"
        />
      </template>
      <template v-slot:[`item.regle`]="{ item }">
        <template v-if="item.valide">
          <template v-if="item.regle">
            <v-chip class="ma-2" color="success" text-color="white">
              Facture réglée
            </v-chip>
          </template>
          <template v-if="!item.regle && getRetardReglement(item) > 0">
            <v-chip class="ma-2" color="warning" text-color="white">
              Facture non réglée
            </v-chip>
          </template>
          <template v-if="!item.regle && getRetardReglement(item) < 0">
            <v-chip class="ma-2" color="error" text-color="white">
              Règlement en retard
              <b class="pl-2">{{ -getRetardReglement(item) }} j</b>
            </v-chip>
          </template>
        </template>
      </template>
      <template v-slot:[`item.montantHT`]="{ item }">
        <MoneyLayout :montant="item.montantHT" />
      </template>
      <template v-slot:[`item.montantTVA`]="{ item }">
        <MoneyLayout :montant="item.montantTVA" />
      </template>
      <template v-slot:[`item.devisAssocie`]="{ item }">
        <DevisClientObject
          v-if="item.devisAssocie && item.devisAssocie.id"
          size="xs"
          :devisClientID="item.devisAssocie.id"
        />
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <FactureClientActions
          :object="item"
          size="xs"
          @fetch="init"
          @loading="$refs.list.loading = true"
        />
      </template>
    </ListLayout>
  </div>
</template>

<script>
import dayjs from "dayjs";

import { QUERY_factureClients } from "./graphql/queries";

import ClientObject from "./ClientObject.vue";
import ClientSelector from "./ClientSelector.vue";
import DevisClientObject from "./DevisClientObject.vue";
import FactureClientActions from "./FactureClientActions.vue";
import FactureClientObject from "./FactureClientObject.vue";
import FournisseurObject from "./FournisseurObject.vue";
import ListLayout from "@/components/COMPONENTS-skeleton/Layouts/ListLayout.vue";
import MoneyLayout from "@/components/COMPONENTS-skeleton/Layouts/MoneyLayout.vue";

export default {
  name: "FactureClientsList",

  components: {
    ClientObject,
    ClientSelector,
    DevisClientObject,
    FactureClientActions,
    FactureClientObject,
    FournisseurObject,
    ListLayout,
    MoneyLayout,
  },

  props: {
    customSearch: {
      type: Array,
      default() {
        return undefined;
      },
    },
    title: {
      type: String,
      default: "Liste des factures",
    },
    noFilter: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },

  data: () => ({
    customFilters: {
      client: null,
      fournisseur: null,
    },
    headers: [
      { text: "", value: "object", sortable: false },
      { text: "Numero de facture", value: "numeroFacture" },
      { text: "Date", value: "dateFacture" },
      { text: "Echéance", value: "dateEcheance" },
      { text: "Client", value: "client", sortable: false },
      { text: "Montant HT", value: "montantHT" },
      { text: "Montant TVA", value: "montantTVA" },
      { text: "Règlement", value: "regle", sortable: false },
      { text: "Devis attaché", value: "devisAssocie", sortable: false },
      { text: "Fichiers", value: "fichiers", sortable: false },
      { text: "Commentaires", value: "commentaires", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    showMultipleActions: false,
  }),

  methods: {
    fetchFactureClients() {
       
      this.$refs.list.loading = true;
      let pagination = JSON.parse(JSON.stringify(this.$refs.list.pagination));
      if (this.customSearch) {
        this.customSearch.forEach((element) => {
          pagination.filter.customSearch.push(element);
        });
      }
      
      if(!this.noFilter) {
        if (this.customFilters.client) {
          pagination.filter.customSearch.push({
            client: this.customFilters.client,
          });
        }
        if (this.customFilters.fournisseur) {
          pagination.filter.customSearch.push({
            fournisseur: this.customFilters.fournisseur,
          });
        }
      }

      this.$refs.list.loading = true;
      this.$apollo
        .query({
          query: QUERY_factureClients,
          variables: {
            pagination: pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          this.$refs.list.items = data.factureClients;
          this.$refs.list.itemsCount = data.factureClientsCount;
          this.$refs.list.loading = false;
        });
    },
    getRetardReglement(factureClient) {
      return dayjs(factureClient.dateEcheance).diff(dayjs(), "days");
    },
    init() {
      this.fetchFactureClients();
    },
  },
  watch: {
    customFilters: {
      deep: true,
      handler() {
        this.$refs.list.currentPage = 1;
        this.init();
      },
    },
  },
};
</script>