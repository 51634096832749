<template>
  <v-card>
    <v-card-title class="primary white--text rounded">
      <v-icon class="mr-2 white--text">mdi-map-marker</v-icon>
      Carte des établissements
      <v-spacer></v-spacer>
    </v-card-title>
    <AdressePostalesMap :adresses="adresses" @clickedMarker="changeSelected">
      <template v-slot:card-content v-if="selected != null">
        <EtablissementObject
          size="xs"
          :etablissementProp="etablissements[selected]"
        />
      </template>
    </AdressePostalesMap>
  </v-card>
</template>

<script>
import { MUTATION_downloadURLCreate } from "@/components/COMPONENTS-fichiers-stockages/graphql/mutations";
import AdressePostalesMap from "./AdressePostalesMap.vue";
import EtablissementObject from "./EtablissementObject.vue";

export default {
  name: "EtablissementsMap",
  components: {
    AdressePostalesMap,
    EtablissementObject,
  },
  props: {
    etablissements: [],
  },
  data: () => ({
    selected: null,
    adresses: [],
    logos: [],
    adressesRetrieved: false,
  }),

  mounted() {
    if (this.etablissements.length > 0) {
      this.init();
    }
  },

  watch: {
    etablissements: function () {
      this.init();
    },
  },
  methods: {
    init() {
      if (!this.adressesRetrieved) {
        this.adressesRetrieved = true;
        this.getAdresses().then((val) => {
          this.adresses = val;
        });
      }
    },

    changeSelected(val) {
      this.selected = val;
    },

    async getAdresses() {
      let adresses = [];
      for (let etablissement of this.etablissements) {
        let adresse = etablissement.adresse;
        adresse.idParent = etablissement.id;
        adresse.label = etablissement.libelle;
        if (etablissement.entreprise.logo) {
          if (this.logos[etablissement.entreprise.logo]) {
            adresse.icon = this.logos[etablissement.entreprise.logo];
          } else {
            this.logos[etablissement.entreprise.logo] = await this.$apollo
              .mutate({
                mutation: MUTATION_downloadURLCreate,
                variables: {
                  id: etablissement.entreprise.logo.id,
                },
              })
              .then((data) => {
                return data.data.downloadURLCreate.url;
              });
            adresse.icon = this.logos[etablissement.entreprise.logo];
          }
        }
        adresses.push(adresse);
      }
      return adresses;
    },
  },
};
</script>