<template>
  <div>
    <ActionLayout
      :actions="actions"
      :size="size"
      :action="action"
      @fetch="$emit('fetch')"
      :mainIcon="mainIcon"
      :multiple="multiple"
    />
  </div>
</template>

<script>
import ActionLayout from "@/components/COMPONENTS-skeleton/Layouts/ActionLayout.vue";
import {
  MUTATION_downloadURLCreate,
  MUTATION_fichierUpdate,
  MUTATION_fichierDelete,
} from "./graphql/mutations";
export default {
  name: "FichierActions",
  components: {
    ActionLayout,
  },
  props: {
    withView: { type: Boolean, default: true },
    mainIcon: { type: String, default: "mdi-file" },
    object: { type: [Array, Object] },
    size: { type: String, default: "md" },
    action: { type: String, default: "router" },
    multiple: { type: Boolean, default: false },
  },
  data: () => ({}),
  computed: {
    actions() {
      return [
        {
          text: "Editer",
          icon: "mdi-pencil",
          path: "/fichiers-stockages/fichiers/edit/" + this.object.id,
          acl: "/fichiers-stockages/fichiers/edit",
          color: "",
          conditions: { multiple: false, isActif: true },
        },
        {
          text: "Supprimer",
          icon: "mdi-delete",
          method: "editIsActif",
          acl: "/fichiers-stockages/fichiers/deleted",
          color: "error",
          conditions: { multiple: true, isActif: true },
        },
        {
          text: "Télécharger",
          icon: "mdi-download",
          method: "downloadFile",
          acl: "/fichiers-stockages/fichiers/download",
          color: "",
          conditions: { multiple: false, isActif: true },
        },
        {
          text: "Restauration",
          icon: "mdi-restore",
          method: "editIsActif",
          acl: "/fichiers-stockages/fichiers/edit",
          color: "",
          conditions: { multiple: true, isActif: false },
        },
        {
          text: "Supprimer définitivement",
          icon: "mdi-delete",
          method: "delete",
          acl: "/fichiers-stockages/fichiers/definitive-delete",
          color: "error",
          conditions: { multiple: false, isActif: false },
        },
        {
          text: "Visualiser",
          icon: "mdi-eye",
          path: "/fichiers-stockages/fichiers/view/" + this.object.id,
          acl: "/fichiers-stockages/fichiers/view",
          color: "",
          conditions: {},
        },
      ];
    },
  },
  created() {
    if (!this.$root._events.deleteFichier)
      this.$root.$on("deleteFichier", this.deleteFichier);
    if (!this.$root._events.editFichier)
      this.$root.$on("editFichier", this.editFichier);
  },
  methods: {
    downloadFile(obj) {
      this.$apollo
        .mutate({
          mutation: MUTATION_downloadURLCreate,
          variables: {
            id: obj.id,
          },
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          let fileUrl = data.data.downloadURLCreate.url;
          //window.location.assign(fileUrl);
          let link = document.createElement("a"); //create 'a' element
          link.setAttribute("href", fileUrl); //replace "file" with link to file you want to download
          link.setAttribute("target", "_blank");
          link.setAttribute("download", fileUrl); // replace "file" here too
          link.click(); //virtually click <a> element to initiate download
        });
    },

    delete(obj) {
      // infos qui s'afficheront dans le popup
      let toValid = {
        titre: "Suppression définitive d'un fichier",
        texte: `Êtes-vous sûr de vouloir supprimer définitivement le fichier ${obj.displayName} ?`,
        type: "warning",
        toReturn: {
          function: "deleteFichier", // cette fonction change en fonction du composant (ex: PersonneActions.vue ça serait deletePersonne,
          //qu'il faut créer à la place de deleteMarque)
          arg: obj.id,
        },
      };

      // Envoi l'info pour ouvrir la popup
      this.$root.$emit("popupValidation", toValid);
    },

    /**
     * Supprimer une etablissement grâce à son id
     */
    deleteFichier(id) {
      this.$apollo
        .mutate({
          mutation: MUTATION_fichierDelete,
          variables: { id: id },
        })
        .then(() => {
          this.$emit("fetch");
        });
    },

    /**
     * Fonction qui édite un fichier
     *
     * obj : l'objet à éditer
     */
    async editFichier(obj) {
      // Si obj n'est pas un tableau alors on le transforme en tableau
      if (!Array.isArray(obj)) {
        obj = [obj];
      }

      for (let item of obj) {
        let fichier = {
          id: item.id,
          isActif: !item.isActif,
        };
        await this.$apollo.mutate({
          mutation: MUTATION_fichierUpdate,
          variables: {
            input: fichier,
          },
        });
      }
      this.$emit("fetch");
    },

    /**
     * Fonction à avoir dans tous les composants (infos à changer)
     */
    editIsActif(obj) {
      // Si obj n'est pas un tableau alors on le transforme en tableau
      if (!Array.isArray(obj)) {
        obj = [obj];
      }

      let toValid = {
        titre: obj[0].isActif
          ? "Suppression de fichier(s)"
          : "Restauration de fichier(s)",
        texte: obj[0].isActif
          ? `Êtes-vous sûr de vouloir supprimer ${obj.length} fichier(s) ? ` +
            'Ils seront toujours présents dans l\'onglet "Corbeille"'
          : `Êtes-vous sûr de vouloir restaurer ${obj.length} fichier(s) ?`,
        type: "warning",
        toReturn: {
          function: "editFichier", // à changer selon le composant, comme deleteEtablissement
          arg: obj,
        },
      };

      this.$root.$emit("popupValidation", toValid);
    },
  },
};
</script>

