var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ListLayout',{ref:"list",attrs:{"headers":_vm.headers,"title":"Liste des requêtes d'organisation"},on:{"fetch":_vm.init},scopedSlots:_vm._u([{key:"item.object",fn:function(ref){
var item = ref.item;
return [_c('RequetesApiOrganisationObject',{attrs:{"requetesApiOrganisationProp":item,"size":"xs"}})]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('UserObject',{attrs:{"userProp":item.user,"size":"xs"}})]}},(_vm.acl('/admin-provider/requetes-api/all'))?{key:"item.organisation",fn:function(ref){
var item = ref.item;
return [_c('OrganisationObject',{attrs:{"organisationProp":item.organisation,"size":"xs"}})]}}:null,{key:"item.requeteName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.requeteName)+" ")]}},{key:"item.apiName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.apiName)+" ")]}},{key:"item.requeteDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("shortDateAndHourFR")(item.requeteDate))+" ")]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }