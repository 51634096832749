<template>
  <v-container fluid>
    <Assistant :message="messageAide" />
    <FormLayout icon="mdi-domain" @ajouter="ajouter" @modifier="modifier" @reinitialiser="init" @save="save"
      typeItem="une entreprise" :item="input" :disabled="sirenIsActive ? input.siren ? false : true : false">
      <EntreprisesListByRaisonSociale v-if="raisonSociale" :raisonSociale="raisonSociale" :returnOnlySiren="true"
        @getEntreprise="setEntreprise" />
      <TitleLayout titre="Informations administratives" />
      <v-row align="center">
        <v-col md="3" cols="12" align="center">
          <FichierLogo v-model="input.logo" :text="input.raisonSociale" size="120" align="center"
            @update-image="updateLogo" />
        </v-col>
        <v-col>
          <v-row>
            <v-col>
              <v-row align="center">
                <v-checkbox
                  v-model="sirenIsActive"
                  hide-details
                  class="shrink mr-2 mt-0"
                ></v-checkbox>
                <v-text-field :disabled="!sirenIsActive" v-model="input.siren" :error-messages="formsErrors.entrepriseSiren" type="number"
                  label="Numéro de SIREN" append-outer-icon="mdi-auto-fix" required v-mask="'#########'"
                  @click:append-outer="getEntrepriseInformationFromSiren()" />
              </v-row>
            </v-col>
            <v-col>
              <v-text-field v-model="input.raisonSociale" :error-messages="formsErrors.entrepriseRaisonSociale"
                label="Raison sociale" required append-outer-icon="mdi-auto-fix"
                @click:append-outer="getEntreprisesFromRaisonSociale()" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field v-model="input.nomCommercial" :error-messages="formsErrors.entrepriseNomCommercial"
                label="Nom commercial [Facultatif]" />
            </v-col>
            <v-col>
              <EntrepriseSelector label="Maison mère" v-model="input.holding" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field v-model="input.numeroTvaIntra" :error-messages="formsErrors.entrepriseNumeroTvaIntra"
                label="Numéro de TVA" />
            </v-col>
            <v-col>
              <v-text-field v-model="input.capital" :error-messages="formsErrors.entrepriseCapital" label="Capital"
                type="number" prepend-icon="mdi-cash-plus" suffix="€" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field v-model="input.formeJuridique" :error-messages="formsErrors.formeJuridique"
                label="Forme Juridique" prepend-icon="mdi-scale-balance" />
            </v-col>
            <v-col>
              <v-text-field v-model="input.villeRCS" :error-messages="formsErrors.entrepriseVilleRCS" label="Ville RCS" />
            </v-col>
            <v-row v-show="false">
              <EtablissementForm ref="etbForm" />
            </v-row>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field v-model="input.codeNaf.code" :error-messages="formsErrors.codeNaf" label="Code NAF" />
            </v-col>
            <v-col>
              <v-text-field v-model="input.codeNaf.libelle" :error-messages="formsErrors.libelleNaf"
                label="Libellé code NAF" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </FormLayout>
  </v-container>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";

import {
  MUTATION_entrepriseCreate,
  MUTATION_entrepriseUpdate,
  MUTATION_personneCreate,
} from "./graphql/mutations";
import {
  QUERY_entreprise,
  QUERY_entreprises,
  QUERY_entrepriseInformationFromSiren,
  QUERY_etablissements
} from "./graphql/queries";

import Assistant from "@/components/COMPONENTS-skeleton/Assistant.vue";
import EntreprisesListByRaisonSociale from "./EntreprisesListByRaisonSociale.vue";
import EntrepriseSelector from "./EntrepriseSelector.vue";
import EtablissementForm from "./EtablissementForm.vue";
import FichierLogo from "@/components/COMPONENTS-fichiers-stockages/FichierLogo.vue";
import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";
import TitleLayout from "@/components/COMPONENTS-skeleton/Layouts/TitleLayout.vue";

export default {
  name: "EntrepriseForm",

  components: {
    Assistant,
    EntreprisesListByRaisonSociale,
    EntrepriseSelector,
    EtablissementForm,
    FichierLogo,
    FormLayout,
    TitleLayout,
  },

  props: {
    componentParent: undefined,
    withoutRepresentantsAdd: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    // Permet de savoir si la baguette a été executée avec succès
    getEntrepriseInformationFromSirenExecuted: false,
    entreprise: {
      id: undefined,
      siren: "",
      raisonSociale: "",
      nomCommercial: "",
      numeroTvaIntra: "",
      holding: null,
      capital: 0,
      villeRCS: "",
      codeNaf: {
        code: "",
        libelle: ""
      },
      logo: {
        id: "",
      },
      etablissements: [],
      meta: {},
    },
    panel: [0, 1],
    panelEtb: [0],
    input: {
      codeNaf: {
        code: "",
        libelle: ""
      }
    },
    // Représentant(s) de l'entreprise à transformer en Personne
    representants: [],
    raisonSociale: null,
    sirenIsActive: true
  }),

  computed: {
    ...mapState(["formsErrors"]),

    messageAide: function () {
      let text = `Pensez à utiliser la baguette magique dans le champs SIREN afin de gagner du temps.`;
      if (this.getEntrepriseInformationFromSirenExecuted) {
        text = `<br/> Tu es un sorcier Harry !`;
      }
      return text;
    },
  },

  mounted: function () {
    this.init();
  },

  methods: {
    async ajouter() {
      await this.save();
      this.$root.$emit("skeleton--close");
      this.init();
    },

    // Permet de récupérer les informations d'une entreprise à partir du SIREN (basé sur une API tierce)
    getEntrepriseInformationFromSiren() {
      this.$store.commit("resetFormsErrors");
      this.$apollo
        .query({
          query: QUERY_entrepriseInformationFromSiren,
          variables: { siren: this.input.siren },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          let data = __.data;
          this.getEntrepriseInformationFromSirenExecuted = true;

          if (data.entrepriseInformationFromSiren.representants) {
            this.representants =
              data.entrepriseInformationFromSiren.representants;
            if (this.withoutRepresentantsAdd) {
              this.$emit("representantsInfos", this.representants)
            }
          }

          this.input.etablissements = [];
          this.input = {
            ...this.input,
            ...data.entrepriseInformationFromSiren.entreprise,
          };
        });
    },

    getEntreprisesFromRaisonSociale() {
      this.raisonSociale = this.input.raisonSociale
    },

    init() {
      if (
        this.$route.path.startsWith("/annuaire/entreprises/edit/") &&
        this.$route.params.id
      ) {
        let id = this.$route.params.id;
        this.$apollo
          .query({
            query: QUERY_entreprise,
            variables: { id: id },
          })
          .then((data) => {
            this.entreprise = data.data.entreprise;
            this.input = JSON.parse(JSON.stringify(this.entreprise));
            this.sirenIsActive = !this.input.isInternationale;
          });
      } else {
        this.input = JSON.parse(JSON.stringify(this.entreprise));
        this.sirenIsActive = !this.input.isInternationale;
      }
    },

    //Permet de faire le ménage dans l'objet "Entreprise" récupéré depuis GraphQL afin d'en faire un objet "Input"
    // Permet de respecter le principe d'évolution
    inputConverter(arg) {
      const obj = JSON.parse(JSON.stringify(arg));
      if (_.isEmpty(obj)) {
        return "";
      }

      let etablissements = [];
      if (!obj.id) {
        for (let objEtablissement of obj.etablissements) {
          let inputEtb = this.$refs.etbForm.inputConverter(objEtablissement);
          etablissements.push(inputEtb);
        }
      }

      let input = {
        id: obj.id,
        holding: obj.holding && obj.holding.id ? obj.holding.id : obj.holding,
        siren: obj.siren,
        isInternationale: !this.sirenIsActive,
        formeJuridique: obj.formeJuridique,
        raisonSociale: obj.raisonSociale,
        nomCommercial: obj.nomCommercial,
        numeroTvaIntra: obj.numeroTvaIntra,
        etablissements: etablissements.length == 0 ? undefined : etablissements,
        villeRCS: obj.villeRCS,
        codeNaf: obj.codeNaf,
        capital: parseFloat(obj.capital),
        logo: {
          id: obj.logo && obj.logo.id ? obj.logo.id : "",
        },
        meta: obj.meta,
      };

      return input;
    },

    async modifier() {
      await this.save()
      this.$root.$emit("skeleton--close")
      this.init()
    },

    async save() {
      this.$store.commit("resetFormsErrors");
      let toSend = this.inputConverter(this.input);

      // Dans le cadre d'une sauvegarde en modification
      if (toSend.id) {
        this.$apollo.mutate({
          mutation: MUTATION_entrepriseUpdate,
          variables: { input: toSend },
          refetchQueries: [{ query: QUERY_entreprises }],
          update: (store, { data: { entrepriseUpdate } }) => {
            // On met à jour l'input pour intégrer l'ID en cas de création
            // Le FormLayout récupère alors l'input actualisé, permet de continuer à travailler sur l'objet
            // En modification
            this.input = JSON.parse(JSON.stringify(entrepriseUpdate))
            this.$emit("entrepriseReturn", entrepriseUpdate);
            this.$store.commit("addAlert", {
              type: "info",
              text: "L'entreprise a été modifiée",
            });
          },
        });
      } else {
        // Dans le cadre d'une sauvegarde en ajout
        let entrepriseCreee;

        await this.$apollo
          .mutate({
            mutation: MUTATION_entrepriseCreate,
            variables: { input: toSend },
            refetchQueries: [{ query: QUERY_entreprises }],
          })
          .then((__) => {
            this.$store.commit("addAlert", {
              type: "info",
              text: "L'entreprise a été ajoutée",
            });

            if (toSend.etablissements) {
              let etbLength = toSend.etablissements.length;

              this.$store.commit("addAlert", {
                type: "info",
                text: `${etbLength} établissement${etbLength > 1 ? "s" : ""} ${etbLength > 1 ? "ont" : "a"
                  } été créé${etbLength > 1 ? "s" : ""}`,
              });
            }


            if (this.componentParent) {
              this.$root.$emit(this.componentParent, __.data.entrepriseCreate);
            }
            this.input = JSON.parse(JSON.stringify(__.data.entrepriseCreate))
            entrepriseCreee = __.data.entrepriseCreate;

          });

        if (!this.withoutRepresentantsAdd) {
          // Si il y a des représentants, on les créé en tant que Personne
          if (this.representants.length > 0) {
            let siege = {};
            // On recherche le siege social de l'entreprise pour la fonction
            let res = await this.$apollo.query({
              query: QUERY_etablissements,
              variables: {
                pagination: {
                  limit: 1,
                  skip: 0,
                  sort: "",
                  filter: {
                    customSearch: [
                      { entreprise: entrepriseCreee.id },
                      { isSiege: true },
                    ],
                  },
                },
              },
            });

            siege = res.data.etablissements[0];

            if (siege) {
              // Puis on créé un à un les représentants
              for (const representant of this.representants) {
                representant.fonctions[0].etablissement = siege.id;
                representant.fonctions[0].entreprise = entrepriseCreee.id;
                await this.$apollo.mutate({
                  mutation: MUTATION_personneCreate,
                  variables: {
                    input: representant,
                  },
                });
              }
            }

            this.$store.commit("addAlert", {
              type: "info",
              text: `${this.representants.length} personne${this.representants.length > 1 ? "s" : ""
                } ${this.representants.length > 1 ? "ont" : "a"} été créée${this.representants.length > 1 ? "s" : ""
                }`,
            });
          }
        }
      }

    },

    setEntreprise(siren) {
      this.input.siren = siren;
      this.getEntrepriseInformationFromSiren();
    },

    updateLogo(idLogo) {
      this.input.logo = {};
      this.input.logo.id = idLogo;
    },
  },
};
</script>
