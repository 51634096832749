<template>
  <FormLayout
    icon="mdi-email"
    @ajouter="ajouter"
    @reinitialiser="init"
    typeItem="un email"
    :item="input"
    :disabled="input.subject && input.sender && input.to && input.body ? false : true"
  >
    <v-text-field
      v-model="input.subject"
      name="subject"
      label="Objet"
    />
    <v-text-field
      v-model="input.sender"
      name="sender"
      label="Expéditeur"
    />
    <v-text-field
      v-model="input.to"
      name="to"
      label="Destinataire"
    />
    <v-textarea
      v-model="input.body"
      name="body"
      label="Contenu"
    />
  </FormLayout>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";

import {
  QUERY_envoyerEmail,
} from "./graphql/queries.js";

import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";
import { QUERY_organisationCurrent } from '../COMPONENTS-authentification-habilitation/graphql/queries.js';

export default {
  name: "EmailForm",

  components: {
    FormLayout,
  },

  props: {
    componentParent: undefined,
  },

  data: () => ({
    input: {},
    email: {},
  }),

  computed: {
    ...mapState(["formsErrors"]),
  },

  mounted: function () {
    this.init();
  },

  methods: {
    ajouter() {
      let toSend = this.inputConverter(this.input);

      this.$apollo
        .query({
          query: QUERY_organisationCurrent
        })
        .then(__ => {
          let organisation = __.data.organisationCurrent.id;
          
          this.$apollo
            .mutate({
              mutation: QUERY_envoyerEmail,
              variables: { input: toSend, organisation },
            })
            .then((__) => {
              this.$store.commit("addAlert", {
                type: "info",
                text: "L'email a été envoyé",
              });

              if (this.componentParent) {
                this.$root.$emit(this.componentParent, __.data.envoyerEmail);
              }

              this.$root.$emit("skeleton--close");
              this.init();
            });
        });
    },

    getHtmlContent() {
      return `<html><body>${this.input.body.replace('<', '&60').replace('>', '&62').replace('\n', '<br>')}</body></html>`;
    },

    init() {
      this.input = {};
      this.email = {};
    },

    inputConverter(arg) {
      const obj = JSON.parse(JSON.stringify(arg));
      if (_.isEmpty(obj)) {
        return "";
      }

      let input = {
        id: obj.id,
        subject: obj.subject,
        sender: {
          name: obj.sender.split('@')[0].toUpperCase(),
          email: obj.sender
        },
        to: [{
          name: obj.to,
          email: obj.to
        }],
        htmlContent: this.getHtmlContent()
      };

      return input;
    },
  },
};
</script>
