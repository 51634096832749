<template>
  <div>FichierImageSelector</div>
</template>

<script>
export default {
  name: "FichierImageSelector",
  components: {},
  props: {},
  data: () => ({
    images: [{ text: "", url: "" }],
  }),
  methods: {},
};
</script>

