<template>
  <FormLayout
    icon="mdi-email-variant"
    @ajouter="ajouter"
    @modifier="modifier"
    @reinitialiser="init"
    :hasResetButton="hasResetButton"
    typeItem="une enveloppe théorique"
    :item="input"
    :disabled="false"
  >
    <v-row>
      <v-col>
        <BailleurSelector v-model="input.bailleur" />
      </v-col>
      <v-col>
        <ClientSelector
          v-model="input.locataire"
          :isSiege="true"
          label="Locataire"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field v-model="input.montant" label="Montant" />
      </v-col>
      <v-col>
        <v-text-field v-model="input.tauxBailleur" label="Taux Bailleur" />
      </v-col>
    </v-row>
    <FinancementForm
      ref="financementForm"
      v-model="input.financement"
      :loyers="false"
    />
    <FactureFournisseurAttach v-model="input.facturesFournisseurs" />
    <v-row>
      <v-col></v-col>
      <v-col></v-col>
    </v-row>
  </FormLayout>
</template>

<script>
import { mapState } from "vuex";

import {
  MUTATION_enveloppeTheoriqueCreate,
  MUTATION_enveloppeTheoriqueUpdate,
} from "./graphql/mutations";
import { QUERY_enveloppeTheorique } from "./graphql/queries";

import BailleurSelector from "./BailleurSelector.vue";
import ClientSelector from "@/components/COMPONENTS-devis-facture/ClientSelector.vue";
import FactureFournisseurAttach from "@/components/COMPONENTS-devis-facture/FactureFournisseurAttach.vue";
import FinancementForm from "./FinancementForm.vue";
import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";

export default {
  name: "PointFacturationForm",

  components: {
    BailleurSelector,
    ClientSelector,
    FactureFournisseurAttach,
    FinancementForm,
    FormLayout,
  },

  props: {
    hasResetButton: { type: Boolean, default: true },
    componentParent: undefined,
  },

  data: () => ({
    input: {},
    enveloppeTheorique: {
      id: undefined,
      facturesFournisseurs: [],
      financement: {
        // Taux d'interet
        rate: undefined,
        // Nombre de périodes principales
        nper: undefined,
        // Nombre de périodes complémentaires
        nperComp: undefined,
        // Present value (should be entered as negative)
        capitalAfinancer: undefined,
        //Future Value (valeur résiduelle)
        valeurFinFinancement: undefined,
        //Is payment made at the beginning of périod ?
        isBeginning: undefined,
        // Compounding Frequency : 12 pour par mois
        cf: undefined,
        // Payment Frequency : 12 pour par mois
        pf: undefined,
        loyers: [],
        repartition: [],
      },
    },
  }),

  computed: {
    ...mapState(["formsErrors"]),
  },

  mounted: function () {
    this.init();
  },

  methods: {
    ajouter() {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_enveloppeTheoriqueCreate,
          variables: {
            input: toSend,
          },
        })
        .then((__) => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "Votre enveloppe théorique a été ajoutée",
          });

          if (this.componentParent) {
            this.$root.$emit(
              this.componentParent,
              __.data.enveloppeTheoriqueCreate
            );
          }

          this.$root.$emit("skeleton--close");
          this.init();
        });
    },

    init() {
      if (
        this.$route.path.startsWith(
          "/satelease/refinancement/enveloppeTheoriques/edit/"
        ) &&
        this.$route.params.id
      ) {
        let id = this.$route.params.id;
        this.$apollo
          .query({
            query: QUERY_enveloppeTheorique,
            variables: {
              id: id,
            },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let enveloppeTheorique = data.data.enveloppeTheorique;
            this.input = JSON.parse(JSON.stringify(enveloppeTheorique));
          });
      } else {
        this.input = JSON.parse(JSON.stringify(this.enveloppeTheorique));
      }
    },

    inputConverter(arg) {
       
      const obj = JSON.parse(JSON.stringify(arg));
       
      const facturesFournisseurs = [];
      if (obj.facturesFournisseurs) {
        for (let facturesFournisseur of obj.facturesFournisseurs) {
          if (facturesFournisseur.id) {
            facturesFournisseurs.push(facturesFournisseur.id);
          } else {
            facturesFournisseurs.push(facturesFournisseur);
          }
        }
      }
       

      let input = {
        bailleur:
          obj.bailleur && obj.bailleur.id ? obj.bailleur.id : obj.bailleur,
        facturesFournisseurs: facturesFournisseurs,
        financement: this.$refs.financementForm.inputConverter(obj.financement),
        locataire:
          obj.locataire && obj.locataire.id ? obj.locataire.id : obj.locataire,
        montant: obj.montant,
        tauxBailleur: obj.tauxBailleur,
      };
       

      obj.id != undefined ? (input.id = obj.id) : null;

      return input;
    },

    modifier() {
      let toSend = this.inputConverter(this.input);

      this.$apollo
        .mutate({
          mutation: MUTATION_enveloppeTheoriqueUpdate,
          variables: {
            input: toSend,
          },
        })
        .then(() => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "L'enveloppe théorique a été modifiée",
          });
          this.$root.$emit("skeleton--close");
        });
    },
  },
};
</script>