export default [
  {
    path: "/annuaire/parametres/application",
    name: "Paramètres d'application Annuaire",
    component: () => import('./AnnuaireApplicationParametresForm.vue'),
    meta: {
      acl: "/annuaire/parametres/application"
    }
  },
  // ---------------------- PERSONNE ----------------------
  {
    path: '/annuaire/personnes',
    name: 'Gestion des personnes',
    component: () => import('./PersonnesList.vue'),
    meta: {
      acl: "/annuaire/personnes"
    }
  },
  {
    path: '/annuaire/personnes/view/:id',
    name: 'Visualiser une personne de l\'annuaire',
    component: () => import('./PersonneView.vue'),
    meta: {
      acl: "/annuaire/personnes/view"
    }
  },
  {
    path: '/annuaire/personnes/add',
    name: 'Ajout d\'une personne dans l\'annuaire',
    component: () => import('./PersonneForm.vue'),
    meta: {
      acl: "/annuaire/personnes/add"
    }
  },
  {
    path: '/annuaire/personnes/edit/:id',
    name: 'Editer une personne',
    component: () => import('./PersonneForm.vue'),
    meta: {
      acl: "/annuaire/personnes/edit"
    }
  },
  {
    path: '/annuaire/personnes/list',
    name: 'Liste des contacts',
    component: () => import('./PersonnesList.vue'),
    meta: {
      acl: "/annuaire/personnes/list"
    }
  },
  {
    path: '/annuaire/personnes/deleted',
    name: 'Liste des contacts supprimées',
    component: () => import('./PersonnesList.vue'),
    meta: {
      acl: "/annuaire/personnes/deleted"
    }
  },

  // ---------------------- ENTREPRISE ----------------------
  {
    path: '/annuaire/entreprises',
    name: 'Gestion des entreprises',
    component: () => import('./EntreprisesList.vue'),
    meta: {
      acl: "/annuaire/entreprises"
    }
  },
  {
    path: '/annuaire/entreprises/list',
    name: 'Liste des entreprises',
    component: () => import('./EntreprisesList.vue'),
    meta: {
      acl: "/annuaire/entreprises/list"
    }
  },
  {
    path: '/annuaire/entreprises/view/:id',
    name: 'Visualisation d\'une entreprise',
    component: () => import('./EntrepriseView.vue'),
    meta: {
      acl: "/annuaire/entreprises/view"
    }
  },
  {
    path: '/annuaire/entreprises/add',
    name: 'Ajouter une entreprise',
    component: () => import('./EntrepriseForm.vue'),
    meta: {
      acl: "/annuaire/entreprises/add"
    }
  },
  {
    path: '/annuaire/entreprises/edit/:id',
    name: 'Editer une entreprise',
    component: () => import('./EntrepriseForm.vue'),
    meta: {
      acl: "/annuaire/entreprises/edit"
    }
  },
  {
    path: '/annuaire/entreprises/deleted',
    name: 'Liste des entreprises supprimées',
    component: () => import('./EntreprisesList.vue'),
    meta: {
      acl: "/annuaire/entreprises/deleted"
    }
  },

  // ---------------------- ETABLISSEMENT ----------------------
  {
    path: '/annuaire/etablissements',
    name: 'Gestion des établissements',
    component: () => import('./EtablissementsList.vue'),
    meta: {
      acl: "/annuaire/etablissements"
    }
  },
  {
    path: '/annuaire/etablissements/list',
    name: 'Liste des établissements',
    component: () => import('./EtablissementsList.vue'),
    meta: {
      acl: "/annuaire/etablissements/list"
    }
  },
  {
    path: '/annuaire/etablissements/add',
    name: 'Ajouter un établissement',
    component: () => import('./EtablissementForm.vue'),
    meta: {
      acl: "/annuaire/etablissements/add"
    }
  },

  {
    path: '/annuaire/etablissements/edit/:id',
    name: 'Editer un établissement',
    component: () => import('./EtablissementForm.vue'),
    meta: {
      acl: "/annuaire/etablissements/edit"
    }
  },
  {
    path: '/annuaire/etablissements/deleted',
    name: 'Liste des établissements supprimés',
    component: () => import('./EtablissementsList.vue'),
    meta: {
      acl: "/annuaire/etablissements/deleted"
    }
  },
  {
    path: '/annuaire/etablissements/view/:id',
    name: 'Visualiser un etablissement',
    component: () => import('./EtablissementView.vue'),
    meta: {
      acl: "/annuaire/etablissements/view"
    }
  },

  // ---------------------- ENSEIGNE ----------------------
  {
    path: '/annuaire/enseignes',
    name: 'Gestion des enseignes',
    component: () => import('./EnseignesList.vue'),
    meta: {
      acl: "/annuaire/enseignes"
    }
  },
  {
    path: '/annuaire/enseignes/list',
    name: 'Liste des enseignes',
    component: () => import('./EnseignesList.vue'),
    meta: {
      acl: "/annuaire/enseignes/list"
    }
  },
  {
    path: '/annuaire/enseignes/deleted',
    name: 'Liste des enseignes supprimés',
    component: () => import('./EnseignesList.vue'),
    meta: {
      acl: "/annuaire/enseignes/deleted"
    }
  },
  {
    path: '/annuaire/enseignes/view/:id',
    name: 'Visualisation d\'une enseigne',
    component: () => import('./EnseigneView.vue'),
    meta: {
      acl: "/annuaire/enseignes/view"
    }
  },
  {
    path: '/annuaire/enseignes/add',
    name: 'Ajouter une enseigne',
    component: () => import('./EnseigneForm.vue'),
    meta: {
      acl: "/annuaire/enseignes/add"
    }
  },
  {
    path: '/annuaire/enseignes/edit/:id',
    name: 'Editer une enseignes',
    component: () => import('./EnseigneForm.vue'),
    meta: {
      acl: "/annuaire/enseignes/edit"
    }
  },

  {
    path: '/annuaire/parametres',
    name: 'Annuaire',
    component: () => import('./AnnuaireOrganisationParametresForm.vue'),
    meta: {
      acl: "/annuaire/parametres"
    }
  }
]