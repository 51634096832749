export default [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    meta: {
      public: true, // Permet d'accéder même si pas connecté
      emptyLayout: true // Permet de ne pas avoir les éléments de menu, utile pour les pages en dehors de l'applicatif
    }
  },
  {
    path: '/accueil',
    name: 'Accueil',
    component: () => import('@/views/Accueil.vue'),
    meta: {
      acl: "/accueil"
    }
  },
  {
    path: '/dev',
    name: 'Dev',
    component: () => import('./DevTest.vue'),
    meta: {
      acl: "/dev"
    }
  }
]