<template>
  <div>
    <template v-if="demandePartenaire">
      <template v-if="size == 'xl'">
        <ObjectLayout size="xl">
          <template v-slot:icon>mdi-folder-move</template>
          <template v-slot:titre>
            {{ demandePartenaire.numeroAffaire }}</template
          >
          <template v-slot:body>
            <ObjectLayout v-if="demandePartenaire.numeroAffaire[0]">
              <template v-slot:titre>Numéro d'affaire</template>
              <template v-slot:content>
                {{ demandePartenaire.numeroAffaire }}
              </template>
            </ObjectLayout>
            <ObjectLayout v-if="demandePartenaire.dateCreation[0]">
              <template v-slot:titre>Date de création</template>
              <template v-slot:content>
                {{ demandePartenaire.dateCreation }}
              </template>
            </ObjectLayout>
            <ObjectLayout v-if="demandePartenaire.client[0]">
              <template v-slot:titre>Client</template>
              <template v-slot:content>
                {{ demandePartenaire.client }}
              </template>
            </ObjectLayout>
            <ObjectLayout v-if="demandePartenaire.etablissementLivraison[0]">
              <template v-slot:titre
                >Etablissement(s) qui seront livrés</template
              >
              <template v-slot:content>
                {{ demandePartenaire.etablissementsLivraison }}
              </template>
            </ObjectLayout>
            <ObjectLayout v-if="demandePartenaire.datePrevInstallation[0]">
              <template v-slot:titre
                >Date prévisionnelle d'installation des équipements</template
              >
              <template v-slot:content>
                {{ demandePartenaire.datePrevInstallation }}
              </template>
            </ObjectLayout>
          </template>
        </ObjectLayout>
      </template>
      <template v-if="size == 'md'">
        <ObjectLayout size="md">
          <template v-slot:icon>mdi-folder-move</template>
          <template v-slot:content>
            {{ demandePartenaire.numeroAffaire }}</template
          >
        </ObjectLayout>
      </template>
      <template v-if="size == 'xs'">
        <ObjectLayout size="xs">
          <template v-slot:icon>mdi-folder-move</template>
          <template v-slot:content>
            {{ demandePartenaire.numeroAffaire }}</template
          >
          <template v-slot:extracontent>
            <v-icon small class="mr-2">mdi-domain</v-icon>
            {{ demandePartenaire.client }}
          </template>
        </ObjectLayout>
      </template>
    </template>
  </div>
</template>

<script>
import { QUERY_demandePartenaire } from "./graphql/queries";
import ObjectLayout from "@/components/COMPONENTS-skeleton/Layouts/ObjectLayout.vue";
export default {
  name: "DemandePartenaireObject",

  components: {
    ObjectLayout,
  },

  data: () => ({
    demandePartenaire: undefined,
  }),

  props: {
    size: { type: String, default: "md" },
    demandePartenaireProp: {
      type: Object,
      default() {
        return {};
      },
    },
    demandePartenaireID: {
      type: String,
    },
    action: { type: String, default: "router" },
  },
  watch: {
    demandePartenaireProp: function () {
      this.init();
    },
    demandePartenaireID: function () {
      this.init();
    },
  },

  mounted: function () {
    this.init();
  },
  methods: {
    init() {
      if (this.demandePartenaireID) {
        let id = this.demandePartenaireID;
        this.$apollo
          .query({
            query: QUERY_demandePartenaire,
            variables: { id: id },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let demandePartenaire = data.data.demandePartenaire;
            this.demandePartenaire = JSON.parse(
              JSON.stringify(demandePartenaire)
            );
          });
      } else {
        this.demandePartenaire = JSON.parse(
          JSON.stringify(this.demandePartenaireProp)
        );
      }
    },
  },
};
</script>

