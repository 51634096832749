<template>
  <div v-if="fonction">
    <template v-if="size == 'xl'">
      <ObjectLayout :action="action" size="xl" :item="fonction">
        <template v-slot:icon>exemple: mdi-material-design</template>
        <template v-slot:titre> {{ fonction.fonction }}</template>
        <template v-slot:body>
          <ObjectLayout v-if="fonction.fonction" class="py-2">
            <template v-slot:icon>mdi-tag-text</template>
            <template v-slot:titre>Dénomination de la fonction</template>
            <template v-slot:content>{{ fonction.fonction }}</template>
          </ObjectLayout>
        </template>
      </ObjectLayout>
    </template>
    <template v-if="size == 'md'">
      <ObjectLayout size="md">
        <template v-slot:icon>exemple: mdi-material-design</template>
        <template v-slot:content>{{ fonction.fonction }}</template>
        <template v-slot:extracontent>
          <v-icon small class="mr-2">mdi-material-design</v-icon>
          {{ fonction.entreprise.raisonSociale }}
        </template>
      </ObjectLayout>
    </template>
    <template v-if="size == 'xs'">
      <ObjectLayout
        size="xs"
        :action="action"
        v-if="fonction.etablissement.libelle"
      >
        <template v-slot:icon>mdi-briefcase</template>
        <template v-slot:tooltip v-if="tooltip">
          {{ fonction.etablissement.libelle }}<br />
          <b class="font-weight-bold">Fonction:</b> {{ fonction.fonction }}
          <br />
          <b class="font-weight-bold">Entreprise:</b>
          {{ fonction.entreprise.raisonSociale }}<br />
          <b class="font-weight-bold">Siren:</b>
          {{ fonction.entreprise.siren }}<br />
          <b class="font-weight-bold">N° TVA:</b>
          {{ fonction.entreprise.numeroTvaIntra }}<br />
          <b class="font-weight-bold">Pouvoir signature:</b>
          <v-icon :color="fonction.pouvoirSignature ? 'success' : 'error'">{{
            fonction.pouvoirSignature ? "mdi-pen" : "mdi-pen-off"
          }}</v-icon
          ><br />
        </template>
        <template v-slot:content>
          {{ fonction.fonction }}
        </template>

        <template v-slot:subcontent>
          {{ fonction.entreprise.raisonSociale }}
        </template>

        <template v-slot:extracontent>
          {{ fonction.etablissement.libelle }}
        </template>
        <template v-slot:suppleycontent>
          <v-icon :color="fonction.pouvoirSignature ? 'success' : 'error'">{{
            fonction.pouvoirSignature ? "mdi-pen" : "mdi-pen-off"
          }}</v-icon>
        </template>
      </ObjectLayout>
    </template>
  </div>
</template>

<script>
import ObjectLayout from "@/components/COMPONENTS-skeleton/Layouts/ObjectLayout.vue";
export default {
  name: "FonctionObject",

  components: {
    ObjectLayout,
  },

  props: {
    action: {
      type: String,
      default: "none",
    },

    fonction: {
      type: Object,
      default() {
        return {};
      },
    },

    size: { type: String, default: "md" },

    tooltip: {
      type: Boolean,
      default: true,
    },
  }
};
</script>