import gql from 'graphql-tag';
import { FICHIER_FIELDS, DOWNLOADURL_FIELDS, UPLOADURL_FIELDS } from './fragments';


export const MUTATION_downloadURLCreate = gql`
mutation($id:ID!){
  downloadURLCreate(id:$id){
    ...DownloadURLFields
  }
}${DOWNLOADURL_FIELDS}
`;

export const MUTATION_downloadURLsPublicCreate = gql`
  mutation($ids: [ID]) {
    downloadURLsPublicCreate(ids: $ids) {
      ...DownloadURLFields
      idFichier
    }
  }${DOWNLOADURL_FIELDS}
`;

export const MUTATION_downloadUrlLogoAndBgOrganisationCreate = gql`
  mutation ($pagination: PaginationObject) {
    downloadUrlLogoAndBgOrganisationCreate(pagination: $pagination)
  }
`;

export const MUTATION_fichiersTemplatesDefaultCreate = gql`
  mutation($types: [String]) {
    fichiersTemplatesDefaultCreate(types: $types)
  }
`;

export const MUTATION_fichiersTemplatesDefaultDelete = gql`
  mutation {
    fichiersTemplatesDefaultDelete
  }
`;

export const MUTATION_fichierCreate = gql`
mutation($input: FichierInput){
  fichierCreate(input: $input){
    ...FichierFields
  }
}${FICHIER_FIELDS}
`;


export const MUTATION_fichierFromTemplateCreate = gql`
mutation($input:FichierFromTemplateInput){
  fichierFromTemplateCreate(input:$input){
    ...FichierFields
  }
}${FICHIER_FIELDS}
`;

export const MUTATION_fichierDelete = gql`
mutation($id:ID!){
  fichierDelete(id:$id) {
    ...FichierFields
  }
}${FICHIER_FIELDS}
`;

export const MUTATION_fichierMetaFavorite = gql`
mutation($input: FichierMetaFavoriteInput){
  fichierMetaFavorite (input: $input){
    ...FichierFields
  }
}${FICHIER_FIELDS}
`;


export const MUTATION_fichierUpdate = gql`
mutation($input: FichierInput){
  fichierUpdate(input: $input){
    ...FichierFields
  }
}${FICHIER_FIELDS}
`;

export const MUTATION_uploadURLCreate = gql`
mutation($fileName:String!) {
  uploadURLCreate(fileName: $fileName){
    ...UploadURLFields
  }
}${UPLOADURL_FIELDS}
`;