<template>
  <div>CessionsList</div>
</template>

<script>
export default {
  name: "CessionsList",
  components: {},
};
</script>

