<template>
  <div>
    <template v-if="assetEmplacement">
      <template v-if="size == 'xl'">
        <ObjectLayout :action="action" size="xl" :item="assetEmplacement">
          <template v-slot:icon>'exemple: mdi-material-design'</template>
          <template v-slot:titre> {{ assetEmplacement.id }}</template>
          <template v-slot:body>
            <ObjectLayout v-if="assetEmplacement.id" class="py-2">
              <template v-slot:icon>mdi-tag-text</template>
              <template v-slot:titre
                >Dénomination de l'assetEmplacement</template
              >
              <template v-slot:content>{{ assetEmplacement.id }}</template>
            </ObjectLayout>
          </template>
        </ObjectLayout>
      </template>
      <template v-if="size == 'md'">
        <ObjectLayout size="md">
          <template v-slot:icon>'exemple: mdi-material-design'</template>
          <template v-slot:content>{{ assetEmplacement.id }}</template>
          <template v-slot:extracontent>
            <v-icon small class="mr-2">mdi-material-design</v-icon>
            {{ assetEmplacement.numeroInventaire }}
          </template>
        </ObjectLayout>
      </template>
      <template v-if="size == 'xs'">
        <ObjectLayout
          size="xs"
          :action="action"
          :onClickPush="`/COMPONENTS/assetEmplacements/view/${assetEmplacement.id}`"
        >
          <template v-slot:icon>'exemple: mdi-material-design'</template>
          <template v-slot:content
            >{{ assetEmplacement.numeroInventaire }} -
            {{ assetEmplacement.id }}</template
          >
        </ObjectLayout>
      </template>
    </template>
  </div>
</template>

<script>
import { QUERY_assetEmplacement } from "./graphql/queries";
import ObjectLayout from "@/components/COMPONENTS-skeleton/Layouts/ObjectLayout.vue";
export default {
  name: "AssetEmplacementObject",

  components: {
    ObjectLayout,
  },

  props: {
    size: { type: String, default: "md" },
    assetEmplacementProp: {
      type: Object,
      default() {
        return {};
      },
    },
    assetEmplacementID: {
      type: String,
    },
    action: {
      type: String,
      default: "router",
    },
  },

  watch: {
    assetEmplacementProp: function () {
      this.init();
    },
    assetEmplacementID: function () {
      this.init();
    },
  },

  data: () => ({
    assetEmplacement: undefined,
  }),

  mounted: function () {
    this.init();
  },

  methods: {
    init() {
      if (this.assetEmplacementID) {
        let id = this.assetEmplacementID;
        this.apollo
          .query({
            query: QUERY_assetEmplacement,
            variables: { id: id },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let assetEmplacement = data.data.assetEmplacement;
            this.assetEmplacement = JSON.parse(
              JSON.stringify(assetEmplacement)
            );
          });
      } else {
        this.assetEmplacement = JSON.parse(
          JSON.stringify(this.assetEmplacementProp)
        );
      }
    },
  },
};
</script>