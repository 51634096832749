<template>
  <ViewLayout :tabs="tabs" :item="asset">
    <template v-slot:asset>
      <AssetActions :object="asset" size="xl" />
      <v-row class="align-center mx-auto">
        <v-col cols="12">
          <AssetObject size="xl" :assetProp="asset" />
        </v-col>
        <v-col>
          <!-- <v-row><AssetEmplacementsList /></v-row>
          <v-row><AssetEmplacementForm /></v-row> -->
        </v-col>
      </v-row>
    </template>
  </ViewLayout>
</template>

<script>
import { QUERY_asset } from "./graphql/queries";

// import AssetEmplacementsList from "./AssetEmplacementsList.vue";
// import AssetEmplacementForm from "./AssetEmplacementForm.vue";
import AssetObject from "./AssetObject.vue";
import AssetActions from "./AssetActions.vue";
import ViewLayout from "@/components/COMPONENTS-skeleton/Layouts/ViewLayout.vue";

export default {
  name: "AssetView",

  components: {
    // AssetEmplacementsList,
    // AssetEmplacementForm,
    AssetObject,
    AssetActions,
    ViewLayout,
  },

  data: () => ({
    asset: {},
    tabs: [
      {
        icon: "mdi-package-variant-closed",
        displayName: "Asset",
        name: "asset",
      },
      { name: "commentaires" },
      { name: "fichiers" },
    ],
  }),

  apollo: {
    asset: {
      query: QUERY_asset,
      variables() {
        let idQuery = this.showPopup
          ? this.id
          : this.$route.params.id
          ? this.$route.params.id
          : this.id;
        return {
          id: idQuery,
        };
      },
    },
  },
};
</script>