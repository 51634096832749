<template>
  <FormLayout
    icon="mdi-store"
    @ajouter="ajouter"
    @modifier="modifier"
    @save="save"
    @reinitialiser="init"
    typeItem="une famille"
    :item="input"
    :disabled="input.label ? false : true"
  >
    <v-row class="justify-center align-center">
      <v-col cols="12" xl="4" lg="4" md="4" sm="12">
        <v-text-field
          v-model="input.label"
          :error-messages="formsErrors.familleLabel"
          name="label"
          label="Label"
        />
      </v-col>
    </v-row>
    <v-row class="justify-center align-center">
      <v-col cols="12" xl="4" lg="4" md="4" sm="12">
        <FamilleSelector
          v-model="input.parent"
          label="Famille parent"
        />
      </v-col>
    </v-row>
  </FormLayout>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";

import {
  MUTATION_familleCreate,
  MUTATION_familleUpdate,
} from "./graphql/mutations";
import { QUERY_famille, QUERY_familles } from "./graphql/queries";

import FamilleSelector from "@/components/COMPONENTS-assets/FamilleSelector.vue"
import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";

export default {
  name: "FamilleForm",

  components: {
    FamilleSelector,
    FormLayout,
  },

  props: {
    componentParent: undefined,
  },

  data: () => ({
    input: {},
    famille: {},
  }),

  computed: {
    ...mapState(["formsErrors"]),
  },

  watch: {
    // En cas de passage de la vue edit à add par exemple
    $route() {
      this.init();
    },
  },

  mounted: function () {
    this.init();
  },

  methods: {
    async ajouter() {
      await this.save()
      this.$root.$emit("skeleton--close")
      this.init()
    },

    init() {
      if (this.$route.params.id) {
        let id = this.$route.params.id;
        this.$apollo
          .query({
            query: QUERY_famille,
            variables: { id: id },
          })
          .then((data) => {
            this.famille = data.data.famille;
            this.input = JSON.parse(JSON.stringify(this.famille));
          });
      } else {
        this.input = JSON.parse(JSON.stringify(this.famille));
      }
    },

    inputConverter(arg) {
      const obj = JSON.parse(JSON.stringify(arg));
      if (_.isEmpty(obj)) {
        return "";
      }

      let input = {
        id: obj.id,
        label: obj.label,
        parent: obj.parent
      };
      return input;
    },

    async modifier() {
      await this.save()
      this.$root.$emit("skeleton--close")
      this.init()
    },

    async save() {
      this.$store.commit("resetFormsErrors");
      let toSend = this.inputConverter(this.input);

      if(toSend.id) {
        this.$apollo.mutate({
          mutation: MUTATION_familleUpdate,
          variables: { input: toSend },
          refetchQueries: [{ query: QUERY_familles }],
          update: (store, { data: { familleUpdate } }) => {
            this.$emit("familleReturn", familleUpdate);
            this.$store.commit("addAlert", {
              type: "info",
              text: "La famille a été modifiée",
            });
            this.$root.$emit("asset--update-famille");
            this.$root.$emit("skeleton--close");
          },
        });
      }else {
        this.$apollo
          .mutate({
            mutation: MUTATION_familleCreate,
            variables: { input: toSend },
          })
          .then((__) => {
            this.$store.commit("addAlert", {
              type: "info",
              text: "La famille a été ajoutée",
            });

            if (this.componentParent) {
              this.$root.$emit(this.componentParent, __.data.familleCreate);
            }

            this.$root.$emit("skeleton--close");
            this.init();
          });
      }
    },
  },
};
</script>
