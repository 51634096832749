import gql from "graphql-tag";

export const BON_DE_COMMANDE_FOURNISSEUR_FIELDS = gql`
  fragment BonDeCommandeFournisseurFields on BonDeCommandeFournisseur{
    dateCreation
    id
    isActif
    meta
    montantHT
    montantTVA
    numeroBonDeCommande
  }
`;

export const BON_DE_COMMANDE_FOURNISSEUR_LIGNE_FIELDS = gql`
fragment BonDeCommandeFournisseurLigneFields on BonDeCommandeFournisseurLigne{
  label
  meta
  prixUnitaire
  quantite
  tauxTva
  unite
}
`;

export const CLIENT_FIELDS = gql`
  fragment ClientFields on Client{
    id
    isActif
    codeClient
  }
`;

export const DEVIS_CLIENT_FIELDS = gql`
  fragment DevisClientFields on DevisClient{
    dateCreation
    id
    isActif
    meta
    montantHT
    montantTVA
    numeroDevis    
    factureAssociee {
      id
    }
  }
`;

export const DEVIS_CLIENT_LIGNE_FIELDS = gql`
fragment DevisClientLigneFields on DevisClientLigne{
  quantite
  unite
  label
  prixUnitaire
  tauxTva
  meta
}
`;

export const DEVIS_FOURNISSEUR_FIELDS = gql`
  fragment DevisFournisseurFields on DevisFournisseur{
    dateCreation
    id
    isActif
    meta
    montantHT
    montantTVA
    numeroDevis
    factureAssociee {
      id
    }
  }
`;

export const DEVIS_FOURNISSEUR_LIGNE_FIELDS = gql`
fragment DevisFournisseurLigneFields on DevisFournisseurLigne{
  label
  meta
  prixUnitaire
  quantite
  tauxTva
  unite
}
`;

export const FACTURE_CLIENT_FIELDS = gql`
  fragment FactureClientFields on FactureClient{
    id
    isActif
    dateCreation
    dateFacture
    dateEcheance
    numeroFacture
    montantHT
    montantRegle
    montantTVA
    meta
    regle
    valide
    devisAssocie {
      id
    }
  }
`;

export const FACTURE_CLIENT_LIGNE_FIELDS = gql`
fragment FactureClientLigneFields on FactureClientLigne{
  compteAnalytique
  label
  meta
  prixUnitaire
  quantite
  tauxTva
  unite 
}
`;

export const FACTURE_FOURNISSEUR_FIELDS = gql`
  fragment FactureFournisseurFields on FactureFournisseur{
    dateCreation
    dateEcheance
    dateFacture
    dateInstallation
    id
    isActif
    meta
    montantHT
    montantTVA
    numeroFacture
    devisAssocie {
      id
    }
  }
`;

export const FACTURE_FOURNISSEUR_LIGNE_FIELDS = gql`
fragment FactureFournisseurLigneFields on FactureFournisseurLigne{
  compteAnalytique
  label
  meta
  prixUnitaire
  quantite
  tauxTva
  unite
}
`;

export const FOURNISSEUR_FIELDS = gql`
  fragment FournisseurFields on Fournisseur{
    id
    isActif
    codeFournisseur
  }
`;

export const MOYENPAIEMENT_FIELDS = gql`
  fragment MoyenPaiementFields on MoyenPaiement{
    id
    isActif
    label
    type
    compteBancaire{
      titulaire
      domiciliation
      bic
      iban
      rib
    }
  }
`;

export const POINTFACTURATION_FIELDS = gql`
  fragment PointFacturationFields on PointFacturation{
    id
    isActif
    label
    adresseFacturation {
      numeroVoie
      libelleVoie
      libelleCommune
      codePostal
      cedex
    }
  }
`;

export const REGLEMENT_CLIENT_FIELDS = gql`
  fragment ReglementClientFields on ReglementClient{
    dateCreation
    dateReglement
    id
    identifiantReglement
    isActif
    modeReglement
    montantReglement
  }
`;

export const REGLEMENT_FOURNISSEUR_FIELDS = gql`
  fragment ReglementFournisseurFields on ReglementFournisseur{
    dateCreation
    dateReglement
    id
    identifiantReglement
    isActif
    modeReglement
    montantReglement
  }
`;