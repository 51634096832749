<template>
  <v-container fluid>
    <v-form ref="form" @submit.prevent="validForm">
      <v-card-title class="primary white--text rounded">
        <v-icon class="mr-2 white--text">
          {{ partenaire.id ? "mdi-pencil" : "mdi-plus-circle" }}
        </v-icon>
        {{
          partenaire.id
            ? "Formulaire de modification d'un partenaire"
            : "Formulaire d'ajout de partenaire"
        }}
      </v-card-title>
      <v-expansion-panels multiple v-model="panel">
        <v-expansion-panel>
          <v-expansion-panel-header>
            Informations du partenaire
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <EntrepriseSelector
              v-model="input.entrepriseAssociee"
              label="Société"
            />
            <UserSelector
              v-model="input.usersAssocies"
              label="Utilisateurs chez le partenaire"
              :multiple="true"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-row class="mt-2">
          <v-col align="center" justify="center">
            <v-btn type="submit" color="primary">{{
              partenaire.id ? "Modifier" : "Ajouter"
            }}</v-btn>
          </v-col>
        </v-row>
      </v-expansion-panels>
    </v-form>
  </v-container>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";
import { QUERY_partenaire } from "./graphql/queries";
import EntrepriseSelector from "@/components/COMPONENTS-annuaire/EntrepriseSelector";
import UserSelector from "@/components/COMPONENTS-authentification-habilitation/UserSelector.vue";
import {
  MUTATION_partenaireCreate,
  MUTATION_partenaireUpdate,
} from "./graphql/mutations";
export default {
  name: "PartenaireForm",
  components: {
    EntrepriseSelector,
    UserSelector,
  },
  data: () => ({
    panel: [0, 1],
    input: {},
  }),
  props: {
    partenaire: {
      type: Object,
      default() {
        return {
          entrepriseAssociee: null,
          usersAssocies: null,
          documentsAFournir: [],
        };
      },
    },
  },
  computed: {
    ...mapState(["formsErrors"]),
  },

  created() {
    if (!this.$root._events.ajouter) {
      this.$root.$on("addPartenaire", this.ajouter);
    }
    if (!this.$root._events.modifier) {
      this.$root.$on("updatePartenaire", this.modifier);
    }
  },

  mounted: function () {
    this.init();
  },
  methods: {
    init() {
      if (this.$route.params.id) {
        let id = this.$route.params.id;

        this.$apollo
          .query({
            query: QUERY_partenaire,
            variables: {
              id: id,
            },
          })
          .then((data) => {
            let partenaire = data.data.partenaire;
            this.input = JSON.parse(JSON.stringify(partenaire));
            // this.updatePanelEtb();
          });
      } else {
        this.input = JSON.parse(JSON.stringify(this.partenaire));
      }
    },
    inputConverter(arg) {
      const obj = JSON.parse(JSON.stringify(arg));
      if (_.isEmpty(obj)) {
        return "";
      }
      let input = {
        id: obj.id,
        usersAssocies:
          obj.usersAssocies && obj.usersAssocies.id
            ? obj.usersAssocies.id
            : obj.usersAssocies,
        entrepriseAssociee:
          obj.entrepriseAssociee && obj.entrepriseAssociee.id
            ? obj.entrepriseAssociee.id
            : obj.entrepriseAssociee,
      };
      return input;
    },
    validForm() {
      this.partenaire.id ? this.modifier() : this.ajouter();
    },
    ajouter() {
      let toSend = this.inputConverter(this.input);
      this.$apollo.mutate({
        mutation: MUTATION_partenaireCreate,
        variables: {
          input: toSend,
        },
        update: (store, { data: { partenaireCreat } }) => {
          this.$emit("partenaireReturn", partenaireCreat);
          this.$store.commit("addAlert", {
            type: "info",
            text: "Le partenaire a été ajouté",
          });
          this.resetForm();
        },
      });
    },
    modifier() {
      let toSend = this.inputConverter(this.input);
      this.$apollo.mutate({
        mutation: MUTATION_partenaireUpdate,
        variables: {
          input: toSend,
        },
        update: (store, { data: { partenaireUpdate } }) => {
          this.$emit("partenaireReturn", partenaireUpdate);
          this.$store.commit("addAlert", {
            type: "info",
            text: "Le partenaire a été modifié",
          });
        },
      });
    },
  },
};
</script>


