<template>
  <SelectorLayout
    ref="selector"
    v-model="item"
    @fetch="fetch"
    :prepend-icon="prependIcon"
    :label="label"
    :addAction="addAction"
    :disabled="disabled"
  >
    <template v-slot:selection="data">
      <ReglementFournisseurObject
        :reglementFournisseurProp="data.item"
        v-bind="data.attrs"
        :input-value="data.selected"
        @click="data.select"
        size="xs"
        action="none"
      />
    </template>

    <template v-slot:item="data">
      <ReglementFournisseurObject
        :reglementFournisseurProp="data.item"
        size="xs"
        action="none"
      />
    </template>
  </SelectorLayout>
</template>

<script>
import { QUERY_reglementFournisseurs } from "./graphql/queries";
import ReglementFournisseurObject from "./ReglementFournisseurObject.vue";
import SelectorLayout from "@/components/COMPONENTS-skeleton/Layouts/SelectorLayout.vue";

export default {
  name: "ReglementFournisseurSelector",

  components: {
    ReglementFournisseurObject,
    SelectorLayout,
  },

  props: {
    disabled: { type: Boolean, default: false },
    addAction: {
      type: String,
      default: "/devis-facture/reglementFournisseurs/add",
    },
    errorMessage: { type: String },
    label: { type: String, default: "ReglementFournisseurs" },
    multiple: { type: Boolean, default: false },
    prependIcon: { type: String, default: "mdi-cash-register" },
    value: null,
  },

  data: () => ({
    item: null,
  }),

  methods: {
    fetch() {
      this.$refs.selector.loading = true;
      this.$apollo
        .query({
          query: QUERY_reglementFournisseurs,
          variables: {
            pagination: this.$refs.selector.pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          this.$refs.selector.items = data.reglementFournisseurs;
          this.$refs.selector.loading = false;
        });
    },
  },

  watch: {
    value: {
      deep: true,
      handler(val) {
        this.item = val && val.id ? val.id : val;
        this.fetch();
      },
    },

    item: {
      deep: true,
      handler(val) {
        if (val === null) {
          this.$emit("input", null);
        } else {
          this.$emit("input", val);
        }
      },
    },
  },
};
</script>