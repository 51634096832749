<template>
  <ViewLayout :tabs="tabs">
    <template v-slot:templates>
      <h5>
        Ajoutez ici vos templates au format word (.docx). Ces derniers sont
        ensuite disponible dans l'applicatifs dans les fabriques à fichiers.
      </h5>
      <TemplatesList
        :items="templatesAvailable"
        typeParent="DevisFactureOrganisationParametres"
        :idParent="devisFactureOrganisationParametres.id"
      />
    </template>
    <template v-slot:parametres>
      <FormLayout
        icon="mdi-cog"
        @modifier="modifier"
        @reinitialiser="init"
        titre="Paramètres de l'annuaire"
        :item="input"
      >
        <v-row class="justify-center">
          <v-col cols="6">
            <FournisseurSelector
              label="Fournisseur attaché à l'organisation"
              v-model="input.isFournisseur"
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model="input.nbJourDefaultFactureClientEcheance"
              type="number"
              label="Nombre de jour avant échéance d'une facture client"
              persistent-hint
              prepend-icon="mdi-cash"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field 
              v-model="input.caractereNommage"
              label="Caractère(s) utilisé(s) dans les nommages"
              prepend-icon="mdi-file-document-edit-outline"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-btn color="error" @click="patchFacturesLignesMeta">
            Patch facture fournisseurs et client (meta lignes)
          </v-btn>
        </v-row>
      </FormLayout>
    </template>
  </ViewLayout>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";

import { MUTATION_devisFactureOrganisationParametresUpdate } from "./graphql/mutations";
import { QUERY_devisFactureOrganisationParametres, QUERY_patchFacturesLignesMeta } from "./graphql/queries";

import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";
import FournisseurSelector from "./FournisseurSelector.vue";
import TemplatesList from "@/components/COMPONENTS-fichiers-stockages/TemplatesList";
import ViewLayout from "@/components/COMPONENTS-skeleton/Layouts/ViewLayout.vue";

export default {
  name: "DevisFactureOrganisationParametres",

  components: {
    FormLayout,
    FournisseurSelector,
    TemplatesList,
    ViewLayout,
  },

  data: () => ({
    input: {},
    devisFactureOrganisationParametres: {},
    tabs: [
      { icon: "mdi-cog", displayName: "Paramètres", name: "parametres" },
      { icon: "mdi-cog", displayName: "Templates", name: "templates" },
    ],
    templatesAvailable: [
      {
        type: "DevisClient",
        categories: [{ value: "Devis", acl: "USER" }],
      },
      {
        type: "FactureClient",
        categories: [{ value: "Facture validée", acl: "USER" }, { value: "Facture non validée", acl: "USER" }],
      },
    ],
  }),

  computed: {
    ...mapState(["formsErrors"]),
  },

  mounted: function () {
    this.init();
  },

  methods: {
    init() {
      this.$apollo
        .query({
          query: QUERY_devisFactureOrganisationParametres,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          let devisFactureOrganisationParametres =
            data.data.devisFactureOrganisationParametres;
          this.input = JSON.parse(
            JSON.stringify(devisFactureOrganisationParametres)
          );
        });
    },
    inputConverter(arg) {
      const obj = { ...arg };
      if (_.isEmpty(obj)) {
        return "";
      }

      let input = {
        isFournisseur:
          obj.isFournisseur && obj.isFournisseur.id
            ? obj.isFournisseur.id
            : obj.isFournisseur,
        nbJourDefaultFactureClientEcheance: parseInt(
          obj.nbJourDefaultFactureClientEcheance
        ),
        caractereNommage: obj.caractereNommage
      };

      return input;
    },

    modifier() {
      let toSend = this.inputConverter(this.input);
      this.$apollo.mutate({
        mutation: MUTATION_devisFactureOrganisationParametresUpdate,
        variables: {
          input: toSend,
        },
        update: (
          store,
          { data: { devisFactureOrganisationParametresUpdate } }
        ) => {
          this.$emit(
            "devisFactureOrganisationParametresUpdateReturn",
            devisFactureOrganisationParametresUpdate
          );
          this.$store.commit("addAlert", {
            type: "info",
            text: "Le paramètrage a été modifié",
          });
          this.$root.$emit("devis-facture--update-OrganisationParametres");
          this.$root.$emit("skeleton--close");
        },
      });
    },

    patchFacturesLignesMeta() {
      this.$apollo
        .query({
          query: QUERY_patchFacturesLignesMeta,
          fetchPolicy: 'no-cache'
        })
        .then(() => {
          this.$store.commit("addAlert", {
            type: "success",
            text: "Patch appliqué",
          });
          this.$root.$emit("skeleton--close");
        })
    }
  },
};
</script>
