import gql from 'graphql-tag';

import { USER_FIELDS, ORGANISATION_FIELDS } from '@/components/COMPONENTS-authentification-habilitation/graphql/fragments';
import { REQUETE_API_ORGANISATION_FIELDS } from './fragments';

export const MUTATION_requeteApiOrganisationCreate = gql`
  mutation($input: RequeteApiOrganisationInput) {
    requeteApiOrganisationCreate(input: $input) {
      ...RequeteApiOrganisationFields
      organisation {
        ...OrganisationFields
      }
      user {
        ...UserFields
      }
    }
  }${REQUETE_API_ORGANISATION_FIELDS}, ${USER_FIELDS}, ${ORGANISATION_FIELDS}
`;