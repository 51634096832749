<template>
  <v-container fluid class="pa-0">
    <v-tabs
      v-model="currentTab"
      class="pb-2"
      fixed-tabs
      show-arrows
      height="45"
    >
      <v-tabs-slider color="primary"></v-tabs-slider>

      <v-tab v-for="tab in tabs" :key="tab.name">
        <template v-if="tab.name == 'fichiers'">
          <v-icon small class="mr-2">mdi-paperclip</v-icon>
          <FichiersCounter
            :customSearch="
              item
                ? [
                    { 'meta.typeParent': item.__typename },
                    { 'meta.idParent': item.id },
                  ]
                : []
            "
          >
            <template v-slot:badge> Fichiers </template>
          </FichiersCounter>
        </template>
        <template v-if="tab.name == 'commentaires'">
          <v-icon small class="mr-2">mdi-comment</v-icon>
          <CommentairesCounter
            :customSearch="
              item
                ? [
                    { 'meta.typeParent': item.__typename },
                    { 'meta.idParent': item.id },
                  ]
                : []
            "
          >
            <template v-slot:badge> Commentaires </template>
          </CommentairesCounter>
        </template>
        <template v-else>
          <v-icon v-if="tab.icon" small class="mr-2">{{ tab.icon }}</v-icon>
          {{ tab.displayName }}
        </template>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="currentTab">
      <v-tab-item v-for="tab in tabs" :key="tab.name" class="mb-5">
        <template v-if="tab.name == 'commentaires'">
          <CommentairesObject
            class="py-1"
            :typeParent="item.__typename"
            :idParent="item.id"
          />
        </template>
        <template v-if="tab.name == 'fichiers'">
          <FichiersObject
            class="py-1"
            :meta="{
              typeParent: item.__typename,
              idParent: item.id,
            }"
            :data="data ? data : item"
          />
        </template>
        <slot v-else :name="tab.name"></slot>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import CommentairesCounter from "@/components/COMPONENTS-collaboration/CommentairesCounter.vue";
import CommentairesObject from "@/components/COMPONENTS-collaboration/CommentairesObject.vue";
import FichiersCounter from "@/components/COMPONENTS-fichiers-stockages/FichiersCounter.vue";

import FichiersObject from "@/components/COMPONENTS-fichiers-stockages/FichiersObject.vue";
export default {
  name: "ViewLayout",
  components: {
    CommentairesCounter,
    CommentairesObject,
    FichiersCounter,
    FichiersObject,
  },
  props: {
    data: {},
    item: {},
    tabs: [],
  },
  data: () => ({
    currentTab: null,
  }),
  mounted() {
    this.tabs.forEach(function (tab, index) {
      if (tab.default) {
        this.currentTab = index;
      }
    }, this);
  },
  watch: {},
};
</script>