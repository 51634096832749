<template>
  <ListLayout ref="list" :headers="headers" @fetch="init">
    <template v-slot:[`item.entrepriseAssociee`]="{ item }">
      <EntrepriseObject :entrepriseProp="item.entrepriseAssociee" size="xs" />
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <PartenaireActions :object="item" :fetch="fetchPartenaires" size="xs" />
    </template>
  </ListLayout>
</template>

<script>
import ListLayout from "@/components/COMPONENTS-skeleton/Layouts/ListLayout.vue";
import { QUERY_partenaires } from "./graphql/queries";
import EntrepriseObject from "@/components/COMPONENTS-annuaire/EntrepriseObject.vue";
import PartenaireActions from "./PartenaireActions.vue";
export default {
  name: "PartenairesList",

  components: {
    PartenaireActions,
    EntrepriseObject,
    ListLayout,
  },
  data: () => ({
    // Recherche tapée par l'utilisateur dans le champ "Recherche"
    search: "",

    // Les colonnes ainsi que leur nom, id et options
    headers: [
      { text: "Bailleur", value: "entrepriseAssociee", sortable: false },
      { text: "Fichiers", value: "fichiers" },
      { text: "Commentaires", value: "commentaires" },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),

  methods: {
    fetchPartenaires() {
      this.$refs.list.loading = true;
      this.$apollo
        .query({
          query: QUERY_partenaires,
          variables: {
            pagination: this.$refs.list.pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          this.$refs.list.items = data.partenaires;
          this.$refs.list.itemsCount = data.partenairesCount;
          this.$refs.list.loading = false;
        });
    },
    init() {
      this.fetchPartenaires();
    },
  },
};
</script>

 