<template>
  <FormLayout
    icon="mdi-wallet"
    @ajouter="ajouter"
    @modifier="modifier"
    @reinitialiser="init"
    :disposition="disposition"
    :hasResetButton="hasResetButton"
    typeItem="un point de facturation"
    :item="input"
    :disabled="!input.client ? true : false"
  >
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="input.label"
          :error-messages="formsErrors.pointFacturationLabel"
          label="Libellé"
          prepend-icon="mdi-tag-text"
        />
      </v-col>
      <v-col cols="6">
        <ClientSelector
          v-model="input.client"
          :disabled="client ? true : false"
        />
      </v-col>
    </v-row>
    <v-col v-if="clientSelected">
      <v-row>
        <v-subheader>
          <v-icon class="pr-4">mdi-mailbox</v-icon> Adresse de facturation
        </v-subheader>
      </v-row>
      <v-row>
        <AdressePostaleForm
          @onValid="addAdresse"
          :adresseProp="clientSelected.etablissement.adresse"
        />
      </v-row>
    </v-col>
  </FormLayout>
</template>
<script>
import { mapState } from "vuex";

import {
  MUTATION_pointFacturationCreate,
  MUTATION_pointFacturationUpdate,
} from "./graphql/mutations";
import { QUERY_pointFacturation, QUERY_client } from "./graphql/queries";

import AdressePostaleForm from "@/components/COMPONENTS-annuaire/AdressePostaleForm.vue"
import ClientSelector from "./ClientSelector.vue";
import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";

export default {
  name: "PointFacturationForm",

  components: {
    AdressePostaleForm,
    ClientSelector,
    FormLayout,
  },

  data: () => ({
    input: {},
    pointFacturation: {},
    clientSelected: null
  }),

  props: {
    client: undefined,
    hasResetButton: { type: Boolean, default: true },
    disposition: { type: String, default: "default" },
    componentParent: undefined,
  },

  computed: {
    ...mapState(["formsErrors"]),
  },
  mounted: function () {
    this.init();
  },
  methods: {
    addAdresse(adresse) {
      this.input.adresseFacturation = adresse;
      console.log(this.input)
    },

    ajouter() {
      let toSend = this.inputConverter(this.input);
      console.log("toSend", toSend)
      this.$apollo
        .mutate({
          mutation: MUTATION_pointFacturationCreate,
          variables: {
            input: toSend,
          },
        })
        .then((__) => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "Votre point de facturation a été ajouté",
          });

          if (this.componentParent) {
            this.$root.$emit(
              this.componentParent,
              __.data.pointFacturationCreate
            );
          }

          this.$root.$emit("skeleton--close");
          this.init();
        });
    },

    init() {
      if (
        this.$route.path.startsWith(
          "/devis-facture/point-facturations/edit/"
        ) &&
        this.$route.params.id
      ) {
        let id = this.$route.params.id;
        this.$apollo
          .query({
            query: QUERY_pointFacturation,
            variables: {
              id: id,
            },
          })
          .then((data) => {
            let pointFacturation = data.data.pointFacturation;
            this.input = JSON.parse(JSON.stringify(pointFacturation));
          });
      } else {
        if (this.client) {
          this.pointFacturation.client = this.client;
        }
        this.input = JSON.parse(JSON.stringify(this.pointFacturation));
      }
    },

    inputConverter(arg) {
      const obj = JSON.parse(JSON.stringify(arg));

      let input = {
        label: obj.label,
        iban: obj.iban,
        client: obj.client && obj.client.id ? obj.client.id : obj.client,
        adresseFacturation: obj.adresseFacturation
      };

      obj.id != undefined ? (input.id = obj.id) : null;

      return input;
    },

    modifier() {
      let toSend = this.inputConverter(this.input);

      this.$apollo
        .mutate({
          mutation: MUTATION_pointFacturationUpdate,
          variables: {
            input: toSend,
          },
        })
        .then(() => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "Le point de facturation a été modifié",
          });
          this.$root.$emit("skeleton--close");
        });
    },
  },

  watch: {
    input: {
      deep: true,
      handler(val) {
        if(val.client) {
          this.$apollo
            .query({
              query: QUERY_client,
              variables: {
                id: val.client
              },
              fetchPolicy: 'no-cache'
            }).then(__ => {
              const data = __.data.client;
              this.clientSelected = data;
            })
        }else {
          this.clientSelected = null;
        }
      }
    }
  }
};
</script>