<template>
  <div>
    <CounterLayout :compteur="commentairesCount">
      <template v-slot:icon>mdi-comment</template>
      <template v-slot:badge><slot name="badge"></slot></template>
    </CounterLayout>
  </div>
</template>

<script>
import CounterLayout from "@/components/COMPONENTS-skeleton/Layouts/CounterLayout.vue";
import { QUERY_commentairesCount } from "./graphql/queries";
export default {
  name: "CommentairesCounter",
  components: {
    CounterLayout,
  },
  props: {
    customSearch: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data: () => ({}),
  apollo: {
    commentairesCount: {
      query: QUERY_commentairesCount,
      variables() {
        return {
          pagination: {
            filter: {
              customSearch: this.customSearch,
            },
          },
        };
      },
      fetchPolicy: "no-cache",
    },
  },
  created() {
    this.$root.$on("collaborationUpdateCommentaires", this.updateQuery);
  },

  mounted: function () {},
  methods: {
    updateQuery: function () {
      this.$apollo.queries.commentairesCount.refetch();
    },
  },
};
</script>

