<template>
  <ViewLayout :tabs="tabs" :item="reglementFournisseur">
    <template v-slot:reglementFournisseur>
      <ReglementFournisseurActions :object="reglementFournisseur" size="xl" />

      <v-row class="align-center mx-auto">
        <v-col cols="12">
          <ReglementFournisseurObject
            size="xl"
            :reglementFournisseurProp="reglementFournisseur"
          />
        </v-col>
      </v-row>
    </template>
  </ViewLayout>
</template>
<script>
import { QUERY_reglementFournisseur } from "./graphql/queries";

import ReglementFournisseurActions from "./ReglementFournisseurActions.vue";
import ReglementFournisseurObject from "./ReglementFournisseurObject.vue";
import ViewLayout from "@/components/COMPONENTS-skeleton/Layouts/ViewLayout.vue";

export default {
  name: "ReglementFournisseurView",
  components: {
    ReglementFournisseurActions,
    ReglementFournisseurObject,
    ViewLayout,
  },

  data: () => ({
    reglementFournisseur: {},
    tabs: [
      {
        icon: "mdi-cash-register",
        displayName: "reglement fournisseur",
        name: "reglementFournisseur",
      },
      { name: "commentaires" },
      { name: "fichiers" },
    ],
  }),

  apollo: {
    reglementFournisseur: {
      query: QUERY_reglementFournisseur,
      variables() {
        let idQuery = this.showPopup
          ? this.id
          : this.$route.params.id
          ? this.$route.params.id
          : this.id;
        return {
          id: idQuery,
        };
      },
    },
  },
};
</script>