
// import { QUERY_etablissement } from "@/components/COMPONENTS-annuaire/graphql/queries"
import { MUTATION_clientUpdate } from "../graphql/mutations"
import { QUERY_clients } from "../graphql/queries"


export default async function (vue) {
  console.log('ici UPDATE FILE devis Facture')
  console.log('TOKEN UTILISE', localStorage.getItem('apollo-token'))
  let __ = await vue.$apollo.query({
    query: QUERY_clients,
    variables: {},
    fetchPolicy: "no-cache"
  })
  console.log('nous sommes là')
  console.log(__)
  let clients = __.data.clients
  for (let client of clients) {
    if (!client.entreprise && client.etablissement) {
      console.log({ client })
      let input = {
        id: client.id,
        entreprise: client.etablissement.entreprise.id
      }
      await vue.$apollo.mutate({
        mutation: MUTATION_clientUpdate,
        variables: { input: input }
      })
    }
  }



}