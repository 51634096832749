<template>
  <SelectorLayout
    ref="selector"
    v-model="item"
    @fetch="fetch"
    :prepend-icon="prependIcon"
    :label="label"
    :addAction="addAction"
    :disabled="disabled"
  >
    <template v-slot:selection="data">
      <ClientObject
        :clientProp="data.item"
        v-bind="data.attrs"
        :input-value="data.selected"
        @click="data.select"
        size="xs"
        action="none"
      />
    </template>

    <template v-slot:item="data">
      <ClientObject :clientProp="data.item" size="xs" action="none" />
    </template>
  </SelectorLayout>
</template>

<script>
import { QUERY_clients } from "./graphql/queries";
import {
  QUERY_entreprisesID,
  QUERY_etablissementsID,
} from "@/components/COMPONENTS-annuaire/graphql/queries";

import ClientObject from "./ClientObject";
import SelectorLayout from "@/components/COMPONENTS-skeleton/Layouts/SelectorLayout.vue";

export default {
  name: "ClientSelector",

  components: {
    ClientObject,
    SelectorLayout,
  },

  props: {
    addAction: { type: String, default: "/devis-facture/clients/add" },
    disabled: { type: Boolean, default: false },
    errorMessage: { type: String },
    isSiege: { type: Boolean, default: false },
    label: { type: String, default: "Client" },
    multiple: { type: Boolean, default: false },
    prependIcon: { type: String, default: "mdi-briefcase-account" },
    value: null,
  },

  data: () => ({
    item: null,
  }),

  mounted() {
    this.item = this.value && this.value.id ? this.value.id : this.value;
    this.fetch();
  },

  methods: {
    // Fetch les clients en fonction du filtre
    fetch() {
      this.$refs.selector.loading = true;
      let pagination = JSON.parse(
        JSON.stringify(this.$refs.selector.pagination)
      );
      pagination.limit = 0;

      this.$apollo
        .query({
          query: QUERY_entreprisesID,
          variables: {
            pagination: pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          let entreprisesID = [];
          __.data.entreprises.forEach((elem) => {
            entreprisesID.push(elem.id);
          });
          let paginationEtb = JSON.parse(JSON.stringify(pagination));
          // pagination limit à 0 = renvoie de l'ensemble des résultats (5 par défaut)
          paginationEtb.limit = 0;
          if (entreprisesID[0]) {
            paginationEtb.filter.toFind = "";
            paginationEtb.filter.customSearch.push({
              entreprise: entreprisesID,
            });
            if (this.isSiege) {
              paginationEtb.filter.customSearch.push({
                isSiege: true,
              });
            }
          }

          this.$apollo
            .query({
              query: QUERY_etablissementsID,
              variables: {
                pagination: paginationEtb,
              },
              fetchPolicy: "no-cache",
            })
            .then((__) => {
              let etablissementsID = [];
              __.data.etablissements.forEach((elem) => {
                etablissementsID.push(elem.id);
              });
              let paginationClients = JSON.parse(JSON.stringify(pagination));
              paginationClients.limit = 0;
              if (etablissementsID[0]) {
                paginationClients.filter.toFind = "";
                paginationClients.filter.customSearch.push({
                  etablissement: etablissementsID,
                });
              }

              if(this.item) paginationClients.filter.customSearch.push({ _id: this.item })
              this.$apollo
                .query({
                  query: QUERY_clients,
                  variables: {
                    pagination: paginationClients,
                  },
                  fetchPolicy: "no-cache",
                })
                .then((__) => {
                  const data = __.data;
                  this.$refs.selector.items = data.clients;
                  this.$refs.selector.loading = false;
                });
            });
        });
    },
  },

  watch: {
    value: {
      deep: true,
      handler(val) {
        this.item = val && val.id ? val.id : val;
        this.fetch();
      },
    },

    item: {
      deep: true,
      handler(val) {
        if (val === null) {
          this.$emit("input", null);
        } else {
          this.$emit("input", val);
        }
      },
    },
  },
};
</script>