<template>
  <v-container>
    <v-form @submit.prevent="validForm" class="justify-center">
      <v-text-field
        v-if="!resetPassword"
        v-model="input.oldPassword"
        label="Ancien mot de passe"
        :type="showOldPassword ? 'text' : 'password'"
        prepend-icon="mdi-lock"
        :append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showOldPassword = !showOldPassword"
        autocomplete
        :error-messages="errorMessages.oldPassword"
      />
      <v-text-field
        v-model="input.password"
        label="Nouveau mot de passe"
        :type="showPassword ? 'text' : 'password'"
        prepend-icon="mdi-lock"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showPassword = !showPassword"
        autocomplete
        :error-messages="errorMessages.password"
      />
      <v-text-field
        v-model="input.passwordConfirmation"
        label="Confirmez le nouveau mot de passe"
        :type="showPasswordConfirmation ? 'text' : 'password'"
        prepend-icon="mdi-lock"
        :append-icon="showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showPasswordConfirmation = !showPasswordConfirmation"
        autocomplete
        :error-messages="errorMessages.password"
      />

      <v-row class="mt-2">
        <v-col align="center" justify="center">
          <v-btn
            type="submit"
            color="primary"
            class="mx-2"
          >
            <v-icon class="mr-2">
              mdi-pencil
            </v-icon>
            Modifier
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  name: "UserEditPasswordForm",

  data: () => ({
    input: {
      password: '', 
      passwordConfirmation: '',
      oldPassword: ''
    },
    showPassword: false,
    showPasswordConfirmation: false,
    showOldPassword: false
  }),

  props: {
    errorMessages: {
      type: [Array, Object],
    },
    resetPassword: {
      type: Boolean, default: false
    }
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.input = {
        password: '',
        passwordConfirmation: '',
        oldPassword: ''
      }
    },

    validForm() {
      this.$emit('validForm', this.input);
      this.init();
    } 
  }
}
</script>