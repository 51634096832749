<template>
  <ListLayout
    ref="list"
    :headers="headers"
    :noFilter="noFilter"
    @fetch="init"
    @showMultipleActions="showMultipleActions = $event"
    :title="title"
  >
    <template v-slot:multipleActions>
      <PointFacturationActions
        :multiple="true"
        :object="$refs.list ? $refs.list.itemsSelected : []"
        size="xl"
        v-show="showMultipleActions"
        @fetch="init"
        @loading="$refs.list.loading = true"
      />
    </template>

    <template v-slot:[`item.object`]="{ item }">
      <PointFacturationObject :pointFacturationProp="item" size="xs" />
    </template>

    <template v-slot:[`item.client`]="{ item }">
      <ClientObject :clientID="item.client.id" size="xs" v-if="item.client" />
    </template>

    <template v-slot:[`item.moyenPaiements`]="{ item }">
      <MoyenPaiementsCounter :customSearch="[{ pointFacturation: item.id }]" />
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <PointFacturationActions
        :object="item"
        size="xs"
        @fetch="init"
        @loading="$refs.list.loading = true"
      />
    </template>
  </ListLayout>
</template>

<script>
import { QUERY_pointFacturations } from "./graphql/queries";

import ClientObject from "./ClientObject.vue";
import ListLayout from "@/components/COMPONENTS-skeleton/Layouts/ListLayout.vue";
import MoyenPaiementsCounter from "./MoyenPaiementsCounter.vue";
import PointFacturationActions from "./PointFacturationActions.vue";
import PointFacturationObject from "./PointFacturationObject.vue";

export default {
  name: "PointFacturationsList",

  components: {
    ClientObject,
    ListLayout,
    MoyenPaiementsCounter,
    PointFacturationActions,
    PointFacturationObject,
  },

  props: {
    customSearch: {
      type: Array,
      default() {
        return undefined;
      },
    },
    noFilter: {
      type: Boolean,
      default() {
        return false;
      },
    },
    title: {
      type: String,
      default: "Liste des points de facturation",
    },
  },

  data: () => ({
    customFilters: {},
    headers: [
      { text: "", value: "object", sortable: false },
      { text: "Client", value: "client", sortable: false },
      { text: "Moyens de paiement", value: "moyenPaiements" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    search: null,
    showMultipleActions: false,
  }),

  methods: {
    fetchPointFacturations() {
      this.$refs.list.loading = true;

      let pagination = JSON.parse(JSON.stringify(this.$refs.list.pagination));

      if (this.customSearch) {
        this.customSearch.forEach((element) => {
          pagination.filter.customSearch.push(element);
        });
      }

      this.$apollo
        .query({
          query: QUERY_pointFacturations,
          variables: {
            pagination: pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          this.$refs.list.items = data.pointFacturations;
          this.$refs.list.itemsCount = data.pointFacturationsCount;
          this.$refs.list.loading = false;
        });
    },

    init() {
      this.fetchPointFacturations();
    },
  },

  watch: {
    customFilters: {
      deep: true,
      handler() {
        this.$refs.list.currentPage = 1;
        this.init();
      },
    },
  },
};
</script>