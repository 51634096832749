export default [
  // ---------------------- FICHIER ----------------------
  {
    path: '/fichiers-stockages/fichiers/pdf/view',
    name: 'Visualisation d\'un pdf',
    component: () => import('./FichierPDFPlayer.vue'),
    meta: {
      acl: '/fichiers-stockages/fichiers/pdf/view'
    }
  },
  {
    path: '/fichiers-stockages/fichiers',
    name: 'Gestion des fichiers',
    component: () => import('./FichiersList.vue'),
    meta: {
      acl: "/fichiers-stockages/fichiers"
    }
  },
  {
    path: '/fichiers-stockages/fichiers/view/:id',
    name: 'Fichier',
    component: () => import('./FichierView.vue'),
    meta: {
      acl: "/fichiers-stockages/fichiers/view"
    }
  },
  {
    path: '/fichiers-stockages/fichiers/add',
    name: 'Ajout de fichier',
    component: () => import('./FichierForm.vue'),
    meta: {
      acl: "/fichiers-stockages/fichiers/add"
    }
  },
  {
    path: '/fichiers-stockages/fichiers/edit/:id',
    name: 'Editer un fichier',
    component: () => import('./FichierForm.vue'),
    meta: {
      acl: "/fichiers-stockages/fichiers/edit"
    }
  },
  {
    path: '/fichiers-stockages/fichiers/list',
    name: 'Liste des fichiers',
    component: () => import('./FichiersList.vue'),
    meta: {
      acl: "/fichiers-stockages/fichiers/list"
    }
  },
  {
    path: '/fichiers-stockages/fichiers/deleted',
    name: 'Liste des fichiers supprimées',
    component: () => import('./FichiersList.vue'),
    meta: {
      acl: "/fichiers-stockages/fichiers/deleted"
    }
  }
]