<template>
  <div>AvoirsList, COUCOU LEO</div>
</template>

<script>
export default {
  name: "AvoirsList",
};
</script>

