<template>
  <div>CessionForm</div>
</template>

<script>
export default {
  name: "CessionForm",
};
</script>

