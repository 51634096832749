<template>
  <v-container class="fill-height fullBG" fluid>
    <v-row align="center" justify="center">
      <v-col lg="5">
        <v-container>
          <v-card elevation="2" max-width="600" class="mx-auto pb-6">
            <v-img
              height="280px"
              src="./assets/UserUrlCodeVerif_01.jpg"
              class="grey--text align-end"
            />
            <v-card-text>
              <template v-if="verified == 'desac'">
                <p
                  class="
                    text-center
                    text-lg-subtitle-1
                    text-md-subtitle-2
                    text-sm-body-2
                    text-caption
                  "
                >
                  Vérification de votre compte en cours, ne rafraîchissez pas la
                  page
                </p>
              </template>

              <template v-else-if="verified == 'expires'">
                <p
                  class="
                    text-center
                    text-lg-subtitle-1
                    text-md-subtitle-2
                    text-sm-body-2
                    text-caption
                  "
                >
                  Le lien a expiré, vous allez être redirigé.
                </p>
              </template>

              <template v-else-if="verified == 'OK'">
                <p
                  class="
                    text-center
                    text-lg-subtitle-1
                    text-md-subtitle-2
                    text-sm-body-2
                    text-caption
                  "
                >
                  Votre compte est validé, vous allez être redirigé vers la page
                  de connexion !
                </p>
              </template>
            </v-card-text>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { onLogin, onLogout } from "@/components/COMPONENTS-skeleton/vue-apollo.js";
import { QUERY_userLoginMagicLink } from './graphql/queries';

export default {
  name: "UserMagicLink",

  data: function () {
    return {
      verified: "desac",
    };
  },

  mounted: function () {
    this.init();
  },

  methods: {
    init() {
      this.$apollo
        .mutate({
          mutation: QUERY_userLoginMagicLink,
          variables: {
            code: this.$route.params.code
          },
          fetchPolicy: 'no-cache'
        })
        .then(async (__) => {
          const data = __.data;

          this.verified = 'OK';
          await onLogin(
            this.$apollo.provider.defaultClient,
            data.userLoginMagicLink.token
          );
        })
        .catch(async () => {
          this.verified = 'expires';
          await onLogout(this.$apollo.provider.defaultClient);
        })
        .finally(() => {
          setTimeout(() => {
            this.$router.push({ path: "/" });
          }, 3000);
        });
    }
  }
};
</script>