<template>
  <div>CessionView</div>
</template>

<script>
export default {
  name: "CessionView",
};
</script>

