var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ListLayout',{ref:"list",attrs:{"headers":_vm.headers,"noFilter":_vm.noFilter,"title":"Liste des règlements client"},on:{"fetch":_vm.init,"showMultipleActions":function($event){_vm.showMultipleActions = $event}},scopedSlots:_vm._u([{key:"multipleActions",fn:function(){return [_c('ReglementClientActions',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMultipleActions),expression:"showMultipleActions"}],attrs:{"multiple":true,"object":_vm.$refs.list ? _vm.$refs.list.itemsSelected : [],"size":"xl"},on:{"fetch":_vm.init,"loading":function($event){_vm.$refs.list.loading = true}}})]},proxy:true},{key:"customFilters",fn:function(){return [_c('ClientSelector',{attrs:{"addAction":null},model:{value:(_vm.customFilters.client),callback:function ($$v) {_vm.$set(_vm.customFilters, "client", $$v)},expression:"customFilters.client"}})]},proxy:true},{key:"item.object",fn:function(ref){
var item = ref.item;
return [_c('ReglementClientObject',{attrs:{"reglementClientProp":item,"size":"xs"}})]}},{key:"item.client",fn:function(ref){
var item = ref.item;
return [_c('ClientObject',{attrs:{"clientID":item.client.id,"size":"xs"}})]}},{key:"item.factureClient",fn:function(ref){
var item = ref.item;
return [(item.factureClient)?_c('FactureClientObject',{attrs:{"factureClientID":item.factureClient.id,"size":"xs"}}):_c('v-chip',{attrs:{"color":"warning"}},[_vm._v("Facture à attacher")])]}},{key:"item.dateReglement",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("shortDateFR")(item.dateReglement))+" ")]}},{key:"item.montantReglement",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("monetaire")(item.montantReglement))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('ReglementClientActions',{attrs:{"object":item,"size":"xs"},on:{"fetch":_vm.init,"loading":function($event){_vm.$refs.list.loading = true}}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }