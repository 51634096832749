<template>
  <ListLayout
    ref="list"
    :headers="headers"
    :title="title"
    :noFilter="noFilter"
    @fetch="init"
    @showMultipleActions="showMultipleActions = $event"
  >
    <template v-slot:multipleActions v-if="multiple">
      <DemandeAccordActions
        :multiple="true"
        :object="$refs.list ? $refs.list.itemsSelected : []"
        size="xl"
        v-show="showMultipleActions"
        @fetch="init"
        @loading="$refs.list.loading = true"
      />
    </template>

    <template v-slot:[`item.object`]="{ item }">
      <DemandeAccordObject :demandeAccordProp="item" size="xs" />
    </template>
    <template v-slot:[`item.dateDemande`]="{ item }">
      {{ item.dateDemande | shortDateFR }}
    </template>

    <template v-slot:[`item.affaire`]="{ item }">
      <AffaireObject :affaireProp="item.affaire" size="xs" />
    </template>

    <template v-slot:[`item.affaire.locataire`]="{ item }">
      <ClientObject v-if="item.affaire.locataire" :clientID="item.affaire.locataire.id" size="xs" />
    </template>

    <template v-slot:[`item.bailleur`]="{ item }">
      <BailleurObject
        v-if="item.bailleur"
        :bailleurProp="item.bailleur"
        size="xs"
      />
    </template>
    <template v-slot:[`item.etat`]="{ item }">
      <DemandeAccordObject :demandeAccordProp="item" size="xs-etat" />
    </template>
    <template v-slot:[`item.taux`]="{ item }">
      {{ item.taux ? Number(item.taux.toFixed(3)) + " %" : "" }}
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <DemandeAccordActions
        :object="item"
        size="xs"
        @fetch="init"
        @loading="$refs.list.loading = true"
      />
    </template>
  </ListLayout>
</template>

<script>
import AffaireObject from "./AffaireObject.vue";
import BailleurObject from "./BailleurObject.vue";
import DemandeAccordActions from "./DemandeAccordActions.vue";
import DemandeAccordObject from "./DemandeAccordObject.vue";
import ClientObject from "@/components/COMPONENTS-devis-facture/ClientObject.vue";
import ListLayout from "@/components/COMPONENTS-skeleton/Layouts/ListLayout.vue";

import { QUERY_demandeAccords } from "./graphql/queries";

export default {
  name: "DemandeAccordsList",
  components: {
    AffaireObject,
    BailleurObject,
    ClientObject,
    DemandeAccordObject,
    DemandeAccordActions,
    ListLayout,
  },

  props: {
    customSearch: {
      type: Array,
      default() {
        return undefined;
      },
    },
    multiple: { type: Boolean, default: true },
    noFilter: {
      type: Boolean,
      default() {
        return false;
      },
    },
    title: {
      type: String,
      default: "Liste des demandes d'accord",
    },
  },

  data: () => ({
    customFilters: {
      affaire: null,
    },

    // Les colonnes ainsi que leur nom, id et options
    headers: [
      { text: "", value: "object", sortable: false },
      { text: "Date de demande", value: "dateDemande" },
      { text: "Affaire", value: "affaire", sortable: false },
      { text: "Locataire", value: "affaire.locataire", sortable: false },
      { text: "Bailleur", value: "bailleur", sortable: false },
      { text: "Etat de la demande", value: "etat", sortable: false },
      { text: "Taux", value: "taux" },
      { text: "Fichiers", value: "fichiers" },
      { text: "Commentaires", value: "commentaires" },
      { text: "Actions", value: "actions", sortable: false },
    ],

    // Recherche tapée par l'utilisateur dans le champ "Recherche"
    search: null,

    showMultipleActions: false,
  }),

  methods: {
    fetchDemandeAccords() {
      this.$refs.list.loading = true;
      let pagination = JSON.parse(JSON.stringify(this.$refs.list.pagination));

      if (this.customSearch) {
        this.customSearch.forEach((element) => {
          pagination.filter.customSearch.push(element);
        });
      }

      if (this.customFilters.affaire) {
        pagination.filter.customSearch.push({
          affaire: this.customFilters.affaire,
        });
      }

      this.$apollo
        .query({
          query: QUERY_demandeAccords,
          variables: {
            pagination: pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          console.log(data)
          this.$refs.list.items = data.demandeAccords;
          this.$refs.list.itemsCount = data.demandeAccordsCount;
          this.$refs.list.loading = false;
        });
    },

    init() {
      this.fetchDemandeAccords();
    },
  },

  watch: {
    customFilters: {
      deep: true,
      handler() {
        this.$refs.list.currentPage = 1;
        this.init();
      },
    },
  },
};
</script>