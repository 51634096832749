<template>
  <div>
    <v-row>
      <v-col v-if="hasPreSelectSlot">
        <slot name="preSelect" />
      </v-col>
      <v-col>
        <v-autocomplete :dense="dense" ref="autocomplete" v-bind:value="value" :items="items"
          v-on:input="$emit('input', $event)" :error-messages="errorMessages" :disabled="disabled" chips
          :prepend-icon="prependIcon" clearable :filter="filter" @click:clear="$emit('input', null)" :multiple="multiple"
          :label="label" item-text="id" :search-input.sync="toFind" :loading="loading"
          loading-text="Chargement des données..." :append-outer-icon="addAction ? 'mdi-plus-circle' : null"
          @click:append-outer="appendClick" @change="$emit('change', $event)">
          <template v-slot:no-data="" v-if="!toFind">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Commencez à saisir pour activer la recherche
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
            <slot :name="name" v-bind="data"></slot>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "SelectorLayout",
  props: {
    addAction: { type: String, default: null },
    dense: { type: Boolean, default: true },
    errorMessages: { type: String },
    filter: {
      type: Function,
      default() {
        return true;
      },
    },
    label: { type: String, default: "Sélecteur" },
    multiple: { type: Boolean, default: false },
    prependIcon: { type: String, default: "mdi-home" },
    value: null,
    disabled: { type: Boolean, default: false },
  },
  data: () => ({
    items: [],
    toFind: null,
    loading: false,
  }),
  // Lors de la création d'un selecteur, on écoute grâce à l'id du composant
  // généré si un formulaire renvoie de la data en réponse au clic "ajout"
  created() {
    this.$root.$on("component-" + this._uid, (item) => {
      this.$emit("input", item);
    });
  },

  beforeDestroy() {
    this.$root.$off("component-" + this._uid);
  },
  computed: {
    hasPreSelectSlot() {
      return !!this.$slots.preSelect;
    },
    pagination() {
      let pagination = {
        filter: {
          customSearch: [
            {
              isActif: true,
            },
          ],
        },
        sort: "",
        limit: 20
      };
      // if (this.value) {
      //   pagination.filter.customSearch.push({
      //     _id: this.value && this.value.id ? this.value.id : this.value,
      //   });
      // }
      if (this.toFind) {
        pagination.filter.toFind = this.toFind;
      }
      return pagination;
    },
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        this.toFind = null;
        if (val === null) {
          this.items = [];
          this.$emit("input", null);
        }
      },
    },
    pagination: function () {
      if (this.value || this.toFind) {
        this.$emit("fetch");
      }
    },
  },
  methods: {
    appendClick() {
      this.$refs.autocomplete.blur();
      let link = this.addAction;
      link.includes("?") ? (link += "&") : (link += "?");
      link += "popup=true&componentParent=component-" + this._uid;

      for (const [key, value] of Object.entries(this.$parent.$props)) {
        if (
          key != "value" &&
          key != "label" &&
          key != "errorMessage" &&
          key != "addAction" &&
          key != "multiple" &&
          key != "prependIcon"
        ) {
          link += "&" + key + "=" + value;
        }
      }
      this.$router.push(link);
    },
  },
};
</script>
