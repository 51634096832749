<template>
  <FormLayout
    icon="mdi-key"
    @ajouter="ajouter"
    @modifier="modifier"
    @reinitialiser="init"
    typeItem="une habilitation"
    :disabled="
      input.organisation && input.utilisateur && input.role ? false : true
    "
    :item="input"
  >
    <v-row>
      <v-col>
        <OrganisationSelector v-model="input.organisation" />
      </v-col>
      <v-col>
        <UserSelector v-model="input.utilisateur" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
          :items="roles"
          v-model="input.role"
          :error-messages="formsErrors.userRole"
          label="Rôle"
          prepend-icon="mdi-account-key"
        />
      </v-col>
    </v-row>
  </FormLayout>
</template>
<script>
import { mapState } from "vuex";
import { QUERY_habilitation, QUERY_habilitations } from "./graphql/queries";
import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";
import OrganisationSelector from "./OrganisationSelector.vue";
import UserSelector from "./UserSelector.vue";
import {
  MUTATION_habilitationCreate,
  MUTATION_habilitationUpdate,
} from "./graphql/mutations";
export default {
  name: "HabilitationForm",
  components: {
    FormLayout,
    OrganisationSelector,
    UserSelector,
  },
  data: function () {
    return {
      input: {},
      roles: [
        "ADMIN",
        "DIRIGEANT",
        "GESTIONNAIRE",
        "COMMERCIAL",
        "CLIENT",
        "PARTENAIRE",
      ],
    };
  },
  props: {
    habilitation: {
      type: Object,
      default() {
        return {
          id: undefined,
        };
      },
    },
  },
  computed: {
    ...mapState(["formsErrors"]),
  },
  mounted: function () {
    this.init();
  },
  methods: {
    init() {
      if (this.$route.params.id) {
        let id = this.$route.params.id;
        this.$apollo
          .query({
            query: QUERY_habilitation,
            variables: {
              id: id,
            },
          })
          .then((data) => {
            let habilitation = data.data.habilitation;
            this.input = JSON.parse(JSON.stringify(habilitation));
          });
      } else {
        this.input = JSON.parse(JSON.stringify(this.habilitation));
      }
    },

    ajouter() {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_habilitationCreate,
          variables: {
            input: toSend,
          },
        })
        .then((__) => {
          this.init();
          this.$store.commit("addAlert", {
            type: "info",
            text: "Votre habilitation a été ajoutée",
          });
          this.$root.$emit("skeleton--close");
          this.$root.$emit(
            "authentification-habilitation--habilitation-create",
            __.data.habilitationCreate
          );
          this.$root.$emit("skeleton--close");
        });
    },

    inputConverter(arg) {
      const obj = JSON.parse(JSON.stringify(arg));

      let input = {
        organisation:
          obj.organisation && obj.organisation.id
            ? obj.organisation.id
            : obj.organisation,
        utilisateur:
          obj.utilisateur && obj.utilisateur.id
            ? obj.utilisateur.id
            : obj.utilisateur,
        role: obj.role,
      };

      obj.id != undefined ? (input.id = obj.id) : null;

      return input;
    },

    modifier() {
      let toSend = this.inputConverter(this.input);
      this.$apollo.mutate({
        mutation: MUTATION_habilitationUpdate,
        variables: {
          input: toSend,
        },
        refetchQueries: [
          {
            query: QUERY_habilitations,
          },
        ],
        update: (store, { data: { habilitationUpdate } }) => {
          this.$emit("habilitationReturn", habilitationUpdate);
          this.$store.commit("addAlert", {
            type: "info",
            text: "Votre habilitation a été modifiée",
          });
          this.$root.$emit(
            "authentification-habilitation--update-habilitations"
          );
          this.$root.$emit("skeleton--close");
        },
      });
    },
  },
};
</script>