<template>
  <div>
    <template v-if="client">
      <template v-if="size == 'xl'">
        <ObjectLayout :action="action" size="xl" :item="client">
          <template v-slot:icon>mdi-briefcase-account</template>
          <template v-slot:titre>
            {{ client.codeClient }}
          </template>
          <template v-slot:body>
            <ObjectLayout>
              <template v-slot:titre>Code client</template>
              <template v-slot:content>{{ client.codeClient }}</template>
            </ObjectLayout>

            <ObjectLayout>
              <template v-slot:titre>Entreprise</template>
              <template v-slot:content>
                <EntrepriseObject
                  v-if="client.entreprise"
                  :entrepriseProp="client.entreprise"
                  size="xs"
                />
              </template>
            </ObjectLayout>

            <ObjectLayout>
              <template v-slot:titre>Etablissement</template>
              <template v-slot:content>
                <EtablissementObject
                  v-if="client.etablissement"
                  :etablissementProp="client.etablissement"
                  size="xs"
                />
              </template>
            </ObjectLayout>
          </template>
        </ObjectLayout>
      </template>
      <template v-if="size == 'md'">
        <ObjectLayout size="md"> </ObjectLayout>
      </template>
      <template v-if="size == 'xs'">
        <ObjectLayout
          :action="action"
          size="xs"
          :onClickPush="`/devis-facture/clients/view/${client.id}`"
        >
          <template v-slot:image>
            <FichierLogo
              size="14"
              v-model="client.entreprise.logo"
              :text="client.entreprise.raisonSociale"
              :justDisplay="true"
              v-if="client.entreprise"
            />
          </template>

          <template v-slot:content>
            <span>
              {{ client.codeClient }}

              <v-icon small v-if="client.etablissement.isSiege" class="mt-n1">
                mdi-crown
              </v-icon>
            </span>
          </template>
          <template v-slot:extracontent>
            <template v-if="client.entreprise">
              <v-icon small>
                <slot name="icon">mdi-chevron-right </slot>
              </v-icon>
              {{ client.entreprise.raisonSociale }}
            </template>

            <v-icon small>
              <slot name="icon">mdi-chevron-right </slot>
            </v-icon>
            {{ client.etablissement.libelle }}

            <v-icon small>
              <slot name="icon">mdi-chevron-right </slot>
            </v-icon>
            {{ client.etablissement.adresse.libelleCommune }}
          </template>
        </ObjectLayout>
      </template>
    </template>
  </div>
</template>

<script>
import { QUERY_client } from "./graphql/queries";

import EntrepriseObject from "@/components/COMPONENTS-annuaire/EntrepriseObject.vue";
import EtablissementObject from "@/components/COMPONENTS-annuaire/EtablissementObject.vue";
import FichierLogo from "@/components/COMPONENTS-fichiers-stockages/FichierLogo.vue";
import ObjectLayout from "@/components/COMPONENTS-skeleton/Layouts/ObjectLayout.vue";

export default {
  name: "ClientObject",

  components: {
    EntrepriseObject,
    EtablissementObject,
    FichierLogo,
    ObjectLayout,
  },

  props: {
    action: { type: String, default: "router" },
    clientID: {
      type: String,
    },
    clientProp: {
      type: Object,
      default() {
        return {};
      },
    },
    size: { type: String, default: "md" },
  },

  data: () => ({
    client: undefined,
  }),

  watch: {
    clientID: function () {
      this.init();
    },
    clientProp: function () {
      this.init();
    },
  },

  mounted: function () {
    this.init();
  },

  methods: {
    init() {
      if (this.clientID) {
        let id = this.clientID;
        this.$apollo
          .query({
            query: QUERY_client,
            variables: { id: id },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let client = data.data.client;
            this.client = JSON.parse(JSON.stringify(client));
          });
      } else {
        this.client = JSON.parse(JSON.stringify(this.clientProp));
      }
    },
  },
};
</script>