<template>
  <!-- Etend et complète DevisList se trouvant dans devis-facture -->
  <div>
    <FactureFournisseursList ref="list">
      <!-- <template v-slot:[`item.aFinancer`]="{ item }"> {{ item }} </template> -->
    </FactureFournisseursList>
  </div>
</template>

<script>
import FactureFournisseursList from "@/components/COMPONENTS-devis-facture/FactureFournisseursList.vue";

export default {
  name: "CustomFactureFournisseursList",

  components: {
    FactureFournisseursList,
  },
};
</script>
