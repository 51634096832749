<template>
  <v-form @submit.prevent="envoyer">
    <v-text-field v-model="input.email" label="Adresse mail" prepend-icon="mdi-at" :rules="emailRules"
      :error-messages="formsErrors.email" />
    <v-row>
      <v-col>
        <v-text-field v-model="input.password" label="Mot de passe" :type="showPassword ? 'text' : 'password'"
          prepend-icon="mdi-lock" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword" autocomplete :rules="mdpRules" />
      </v-col>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon class="mt-6">
            <v-icon @click="resetPassword" color="primary" v-bind="attrs" v-on="on">
              mdi-lock-reset
            </v-icon>
          </v-btn>
        </template>
        <span>Réinitialiser le mot de passe</span>
      </v-tooltip>
    </v-row>
    <p align="center">
      <a @click="sendMagicLink" href="#">
        Recevoir un lien de connexion<br />(Renseigner l'email)
      </a>
    </p>
    <v-row>
      <v-col align="center">
        <v-btn color="primary" type="submit"> Se connecter </v-btn>
      </v-col>
      <v-col align="center">
        <v-btn @click="effacer"> Effacer </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col align="center">
        <OAuthLoginList />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapState } from "vuex";

import { QUERY_userLogin, QUERY_userMagicLinkGetLink } from "./graphql/queries";
import { MUTATION_userSendResetPassword } from "./graphql/mutations";

import { onLogin } from "@/components/COMPONENTS-skeleton/vue-apollo";
import OAuthLoginList from "./OAuthLoginList.vue";

export default {
  name: "LoginForm",

  components: {
    OAuthLoginList,
  },

  computed: {
    ...mapState(["formsErrors"]),
  },

  data: function () {
    return {
      input: {
        email: "",
        password: "",
      },
      showPassword: false,
      emailRules: [(v) => /.+@.+\..+/.test(v) || "L'email est invalide"],
      mdpRules: [(v) => v.length > 0 || "Le mot de passe est invalide"],
    };
  },

  methods: {
    envoyer: function () {
      this.$apollo
        .mutate({
          mutation: QUERY_userLogin,
          variables: {
            input: this.input,
          },
        })
        .then(async (data) => {
          await onLogin(
            this.$apollo.provider.defaultClient,
            data.data.userLogin.token,
            data.data.userLogin.refreshToken
          );
          this.$emit("logging");
        })
        .catch(() => {
          this.effacer();
        });
    },

    effacer: function () {
      this.input = {
        email: "",
        password: "",
      };
    },

    async resetPassword() {
      this.$apollo
        .mutate({
          mutation: MUTATION_userSendResetPassword,
          variables: {
            email: this.input.email,
          },
        })
        .then(() => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "Le lien de réinitialisation de mot de passe a été envoyé sur votre boite mail",
          });
          this.$root.$emit("skeleton--close");
        });
    },

    async sendMagicLink() {
      this.$apollo
        .query({
          query: QUERY_userMagicLinkGetLink,
          variables: {
            email: this.input.email,
          },
          fetchPolicy: "no-cache",
        })
        .then(() => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "Le lien a été envoyé sur votre boite mail",
          });
          this.$root.$emit("skeleton--close");
        });
    },
  },
};
</script>
