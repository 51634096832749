var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ListLayout',{ref:"list",attrs:{"headers":_vm.headers},on:{"fetch":_vm.init,"showMultipleActions":function($event){_vm.showMultipleActions = $event}},scopedSlots:_vm._u([{key:"multipleActions",fn:function(){return [_c('ContratLocationActions',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMultipleActions),expression:"showMultipleActions"}],attrs:{"multiple":true,"object":_vm.$refs.list ? _vm.$refs.list.itemsSelected : [],"size":"xl"},on:{"fetch":_vm.init,"loading":function($event){_vm.$refs.list.loading = true}}})]},proxy:true},{key:"item.object",fn:function(ref){
var item = ref.item;
return [_c('ContratLocationObject',{attrs:{"contratLocationProp":item,"size":"xs"}})]}},{key:"item.etat",fn:function(ref){
var item = ref.item;
return [(item.etat == 'ATTENTE_VALIDATION')?[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"indigo","text-color":"white"}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',[_vm._v("mdi-timer")])],1),_vm._v(" En attente de validation ")],1)]:_vm._e(),(item.etat == 'ATTENTE_LIVRAISON')?[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"teal","text-color":"white"}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',[_vm._v("mdi-truck-delivery")])],1),_vm._v(" En attente de livraison ")],1)]:_vm._e()]}},{key:"item.dateCreation",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("shortDateFR")(item.dateCreation))+" ")]}},{key:"item.datePrevInstallation",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("shortDateFR")(item.datePrevInstallation))+" ")]}},{key:"item.datePrevLoyer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("shortDateFR")(item.datePrevInstallation))+" ")]}},{key:"item.locataire",fn:function(ref){
var item = ref.item;
return [(item.locataire && item.locataire.id)?_c('ClientObject',{attrs:{"size":"xs","action":"popup","clientID":item.locataire.id}}):_vm._e()]}},{key:"item.capitalAfinancer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("monetaire")(item.financement.capitalAfinancer))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('ContratLocationActions',{attrs:{"object":item,"size":"xs"},on:{"fetch":_vm.init,"loading":function($event){_vm.$refs.list.loading = true}}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }