var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ListLayout',{ref:"list",attrs:{"headers":_vm.headers,"noFilter":_vm.noFilter,"title":_vm.title},on:{"fetch":_vm.init,"showMultipleActions":function($event){_vm.showMultipleActions = $event}},scopedSlots:_vm._u([{key:"multipleActions",fn:function(){return [_c('PointFacturationActions',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMultipleActions),expression:"showMultipleActions"}],attrs:{"multiple":true,"object":_vm.$refs.list ? _vm.$refs.list.itemsSelected : [],"size":"xl"},on:{"fetch":_vm.init,"loading":function($event){_vm.$refs.list.loading = true}}})]},proxy:true},{key:"item.object",fn:function(ref){
var item = ref.item;
return [_c('PointFacturationObject',{attrs:{"pointFacturationProp":item,"size":"xs"}})]}},{key:"item.client",fn:function(ref){
var item = ref.item;
return [(item.client)?_c('ClientObject',{attrs:{"clientID":item.client.id,"size":"xs"}}):_vm._e()]}},{key:"item.moyenPaiements",fn:function(ref){
var item = ref.item;
return [_c('MoyenPaiementsCounter',{attrs:{"customSearch":[{ pointFacturation: item.id }]}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('PointFacturationActions',{attrs:{"object":item,"size":"xs"},on:{"fetch":_vm.init,"loading":function($event){_vm.$refs.list.loading = true}}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }