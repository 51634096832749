
<template>
  <v-container fluid>
    <v-form ref="form" @submit.prevent="validForm">
      <v-card-title class="primary white--text rounded">
        <v-icon class="mr-2 white--text">
          {{ demandePartenaire.id ? "mdi-pencil" : "mdi-plus-circle" }}
        </v-icon>
        {{
          demandePartenaire.id
            ? "Formulaire de modification d'une demande de partenaire"
            : "Formulaire d'ajout de demande de partenaire"
        }}
      </v-card-title>
      <v-stepper vertical v-model="stepper">
        <v-stepper-step
          step="0"
          v-if="acl('/satelease/partenariat/partenaires/list')"
        >
          Identification du partenaire
        </v-stepper-step>
        <v-stepper-content step="0">
          <v-card>
            <v-card-text>
              <PartenaireSelector v-model="input.partenaire" />
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="primary"
                @click="stepper++"
                :disabled="input.partenaire ? false : true"
              >
                Suivant
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <v-stepper-step step="1"> Identification du client </v-stepper-step>
        <v-stepper-content step="1">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col>
                  <EntrepriseSirenFindOrCreateSelector v-model="input.client" />
                </v-col>
                <v-col></v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="primary"
                @click="stepper++"
                :disabled="input.client ? false : true"
              >
                Suivant
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-step step="2"> Objet du financement </v-stepper-step>
        <v-stepper-content step="2">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="input.montantCapital"
                    label="Montant du capital à financer"
                    suffix="€"
                  />
                </v-col>
                <v-col>
                  <v-select
                    :items="categoriesEquipement"
                    v-model="input.categorie"
                    label="Type d'équipement"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    v-model="input.duree"
                    :items="dureesFinancement"
                    label="Durée du financement"
                  />
                </v-col>
                <v-col>
                  <v-select
                    v-model="input.periodicite"
                    label="Périodicité des paiements"
                    :items="periodicite"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="primary"
                type="submit"
                :disabled="
                  input.montantCapital &&
                  input.categorie &&
                  input.duree &&
                  input.periodicite
                    ? false
                    : true
                "
              >
                Soumettre ma demande
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
      </v-stepper>
    </v-form>
  </v-container>
</template>

<script>
import _ from "lodash";
import { MUTATION_demandePartenaireCreate } from "./graphql/mutations";
import EntrepriseSirenFindOrCreateSelector from "@/components/COMPONENTS-annuaire/EntrepriseSirenFindOrCreateSelector";
import PartenaireSelector from "./PartenaireSelector.vue";
export default {
  name: "DemandePartenaireForm",
  components: {
    EntrepriseSirenFindOrCreateSelector,
    PartenaireSelector,
  },
  data: () => ({
    stepper: 1,
    input: {},
    categoriesEquipement: [
      "Matériel informatique",
      "Machine industrielle",
      "Mobilier froid",
      "Autre",
    ],
    dureesFinancement: [
      { text: "12 mois", value: 12 },
      { text: "24 mois", value: 24 },
      { text: "36 mois", value: 36 },
      { text: "48 mois", value: 48 },
      { text: "60 mois", value: 60 },
    ],
    periodicite: [
      { text: "Loyers mensuels", value: 12 },
      { text: "Loyers trimestiels", value: 4 },
      { text: "Loyers semestriels", value: 2 },
      { text: "Loyers annuels", value: 1 },
    ],
    demandePartenaire: {
      id: undefined,
      partenaire: undefined,
      client: undefined,
      montantCapital: undefined,
      duree: 48,
      categorie: "Autre",
      periodicite: 4,
    },
  }),
  props: {},
  computed: {},

  created() {
    if (!this.$root._events.ajouter) {
      this.$root.$on("addDemandePartenaire", this.ajouter);
    }
    if (!this.$root._events.modifier) {
      this.$root.$on("updateDemandePartenaire", this.modifier);
    }
  },

  mounted: function () {
    if (this.acl("/satelease/partenariat/partenaires/list")) {
      this.stepper = 0;
    }
    this.init();
  },
  methods: {
    init() {
      if (this.$route.params.id) {
        let id = this.$route.params.id;
        console.log(id);
      } else {
        this.input = JSON.parse(JSON.stringify(this.demandePartenaire));
      }
    },
    inputConverter(arg) {
      const obj = JSON.parse(JSON.stringify(arg));
      if (_.isEmpty(obj)) {
        return "";
      }
      let input = {
        partenaire:
          obj.partenaire && obj.partenaire.id
            ? obj.partenaire.id
            : obj.partenaire,
        client: obj.client && obj.client.id ? obj.client.id : obj.client,
        montantCapital: parseInt(obj.montantCapital),
        duree: obj.duree,
        categorie: obj.categorie,
        periodicite: obj.periodicite,
      };
      return input;
    },
    validForm() {
      this.demandePartenaire.id ? this.modifier() : this.ajouter();
    },
    ajouter() {
      let toSend = this.inputConverter(this.input);
      this.$apollo.mutate({
        mutation: MUTATION_demandePartenaireCreate,
        variables: { input: toSend },
        update: (store, { data: { demandePartenaireCreate } }) => {
          this.$emit("demandePartenaireReturn", demandePartenaireCreate);
          this.$store.commit("addAlert", {
            type: "info",
            text: "La demande de partenaire a été ajouté",
          });
          this.resetForm();
        },
      });
    },
    soumettreDemande() {},
  },
};
</script>

