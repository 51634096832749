<template>
  <ActionLayout
    :actions="actions"
    :size="size"
    :action="action"
    :mainIcon="mainIcon"
  />
</template>

<script>
import ActionLayout from "@/components/COMPONENTS-skeleton/Layouts/ActionLayout.vue";

import {
  MUTATION_bonDeCommandeFournisseurDelete,
  MUTATION_bonDeCommandeFournisseurUpdate,
} from "./graphql/mutations.js";

export default {
  name: "EntrepriseActions",

  components: {
    ActionLayout,
  },

  props: {
    withView: { type: Boolean, default: true },
    mainIcon: { type: String, default: "mdi-file-send" },
    object: { type: [Array, Object] },
    size: { type: String, default: "md" },
    action: { type: String, default: "router" },
    multiple: { type: Boolean, default: false },
  },

  computed: {
    actions() {
      return [
        {
          text: "Editer",
          icon: "mdi-pencil",
          path:
            "/devis-facture/bon-de-commande-fournisseurs/edit/" +
            this.object.id,
          acl: "/devis-facture/bon-de-commande-fournisseurs/edit",
          color: "",
          conditions: { multiple: false, isActif: true },
        },
        {
          text: "Supprimer",
          icon: "mdi-delete",
          method: "editIsActif",
          acl: "/devis-facture/bon-de-commande-fournisseurs/deleted",
          color: "error",
          conditions: { multiple: true, isActif: true },
        },
        {
          text: "Restauration",
          icon: "mdi-restore",
          method: "editIsActif",
          acl: "/devis-facture/bon-de-commande-fournisseurs/edit",
          color: "",
          conditions: { multiple: true, isActif: false },
        },
        {
          text: "Supprimer définitivement",
          icon: "mdi-delete",
          method: "delete",
          acl: "/devis-facture/bon-de-commande-fournisseurs/deleted",
          color: "",
          conditions: { multiple: true, isActif: false },
        },
        {
          text: "Visualiser",
          icon: "mdi-eye",
          path:
            "/devis-facture/bon-de-commande-fournisseurs/view/" +
            this.object.id,
          acl: "/devis-facture/bon-de-commande-fournisseurs/view",
          color: "",
          conditions: { size: "md xs" },
        },
      ];
    },
  },

  /**
   * Permet d'utiliser la popup de validation
   */
  created() {
    this.$root.$on(
      "deleteBonDeCommandeFournisseur",
      this.deleteBonDeCommandeFournisseur
    );
    this.$root.$on(
      "editBonDeCommandeFournisseur",
      this.editBonDeCommandeFournisseur
    );
  },

  methods: {
    /**
     * Supprimer une bonDeCommandeFournisseur grâce à son id
     */
    deleteBonDeCommandeFournisseur(id) {
      this.$apollo
        .mutate({
          mutation: MUTATION_bonDeCommandeFournisseurDelete,
          variables: { id: id },
        })
        .then(() => {
          this.$emit("fetch");
        });
    },

    delete(obj) {
      // infos qui s'afficheront dans le popup
      let toValid = {
        titre: "Suppression définitive d'une bonDeCommandeFournisseur",
        texte: `Êtes-vous sûr de vouloir supprimer définitivement l'bonDeCommandeFournisseur ?`,
        type: "warning",
        toReturn: {
          function: "deleteBonDeCommandeFournisseur", // cette fonction change en fonction du composant (ex: PersonneActions.vue ça serait deletePersonne,
          //qu'il faut créer à la place de deleteBonDeCommandeFournisseur)
          arg: obj.id,
        },
      };

      // Envoi l'info pour ouvrir la popup
      this.$root.$emit("popupValidation", toValid);
    },

    async editBonDeCommandeFournisseur(obj) {
      if (!Array.isArray(obj)) {
        obj = [obj];
      }
      this.$emit("loading");
      for (let item of obj) {
        let bonDeCommandeFournisseur = {
          id: item.id,
          //Ajouter les champs nécessaires
        };
        await this.$apollo.mutate({
          mutation: MUTATION_bonDeCommandeFournisseurUpdate,
          variables: {
            input: bonDeCommandeFournisseur,
          },
        });
      }
      this.$emit("fetch");
    },

    editIsActif(obj) {
      // Si obj n'est pas un tableau alors on le transforme en tableau
      if (!Array.isArray(obj)) {
        obj = [obj];
      }
      let toValid = {
        titre: obj[0].isActif
          ? "Suppression d'une bonDeCommandeFournisseur"
          : "Restauration d'une bonDeCommandeFournisseur",
        texte: obj[0].isActif
          ? `Êtes-vous sûr de vouloir supprimer ${obj.length} bonDeCommandeFournisseur(s) ? ` +
            'Elle seront toujours présentes dans l\'onglet "Corbeille"'
          : `Êtes-vous sûr de vouloir restaurer ${obj.length} bonDeCommandeFournisseur(s) ?`,
        type: "warning",
        toReturn: {
          function: "editBonDeCommandeFournisseur", // à changer selon le composant, comme deleteBonDeCommandeFournisseur
          arg: obj,
        },
      };

      this.$root.$emit("popupValidation", toValid);
    },
  },
};
</script>