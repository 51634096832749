<template>
  <div>
    <ActionLayout
      :actions="actions"
      :size="size"
      :action="action"
      @fetch="$emit('fetch')"
      :mainIcon="mainIcon"
    />
  </div>
</template>

<script>
import ActionLayout from "@/components/COMPONENTS-skeleton/Layouts/ActionLayout.vue";
// import {
//   MUTATION_templateDelete,
//   MUTATION_templateUpdate,
// } from "./graphql/mutations.js";

export default {
  name: "TemplateActions",

  components: {
    ActionLayout,
  },

  props: {
    mainIcon: { type: String, default: "mdi-home" },
    object: { type: [Array, Object] },
    size: { type: String, default: "md" },
    action: { type: String, default: "router" },
    multiple: { type: Boolean, default: false },
  },
  data: () => ({
    template: undefined,
  }),

  computed: {
    actions() {
      return [
        {
          text: "Editer",
          icon: "mdi-pencil",
          path: "/envoi-notifications/templates/edit/" + this.object.id,
          acl: "/envoi-notifications/templates/edit",
          color: "",
          conditions: { multiple: false, isActif: true },
        },
        {
          text: "Supprimer",
          icon: "mdi-delete",
          method: "editIsActif",
          acl: "/envoi-notifications/templates/deleted",
          color: "error",
          conditions: { multiple: true, isActif: true },
        },
        {
          text: "Restauration",
          icon: "mdi-restore",
          method: "editIsActif",
          acl: "/envoi-notifications/templates/edit",
          color: "",
          conditions: { multiple: true, isActif: false },
        },
        {
          text: "Supprimer définitivement",
          icon: "mdi-delete",
          method: "delete",
          acl: "/envoi-notifications/templates/deleted",
          color: "",
          conditions: { multiple: false, isActif: false },
        },
        {
          text: "Visualiser",
          icon: "mdi-eye",
          path: "/envoi-notifications/templates/view/" + this.object.id,
          acl: "/envoi-notifications/templates/view",
          color: "",
          conditions: { size: "md xs" },
        },
      ];
    },
  },
  created() {
    this.$root.$on("deleteTemplate", this.deleteTemplate);
    this.$root.$on("editTemplate", this.editTemplate);
  },

  methods: {
    deleteTemplate(id) {
      console.log(id);
      // this.$apollo
      //   .mutate({
      //     mutation: MUTATION_templateDelete,
      //     variables: { id: id },
      //   })
      //   .then(() => {
      //     this.$emit("fetch");
      //   });
    },

    delete(obj) {
      let toValid = {
        titre: "Suppression définitive d'un template",
        texte: `Êtes-vous sûr de vouloir supprimer définitivement le template n°${obj.id} ?`,
        type: "warning",
        toReturn: {
          function: "deleteTemplate",
          arg: obj.id,
        },
      };

      this.$root.$emit("popupValidation", toValid);
    },

    async editTemplate(obj) {
      console.log(obj);
      // if (!Array.isArray(obj)) {
      //   obj = [obj];
      // }

      // this.$emit("loading");

      // let template = {
      //   id: obj.id,
      //   name: obj.name,
      //   subject: obj.subject
      // };

      // await this.$apollo.mutate({
      //   mutation: MUTATION_templateUpdate,
      //   variables: {
      //     input: template,
      //   },
      // });

      // this.$emit("fetch");
    },

    editIsActif(obj) {
      if (!Array.isArray(obj)) {
        obj = [obj];
      }
      let toValid = {
        titre: obj[0].isActif
          ? "Suppression d'un template"
          : "Restauration d'un template",
        texte: obj[0].isActif
          ? `Êtes-vous sûr de vouloir supprimer le template n°${obj.id} ? ` +
            'Il sera toujours présent dans l\'onglet "Corbeille"'
          : `Êtes-vous sûr de vouloir restaurer le template n°${obj.id} ?`,
        type: "warning",
        toReturn: {
          function: "editTemplate",
          arg: obj,
        },
      };

      this.$root.$emit("popupValidation", toValid);
    },
  },
};
</script>