import gql from 'graphql-tag';
import {
  ASSET_FIELDS,
  ASSET_ORGANISATION_PARAMETRES_FIELDS,
  LIENSERVICE_FIELDS,
  FAMILLE_FIELDS,
  MODELE_FIELDS,
  MARQUE_FIELDS,
  AUTRE_FIELDS,
  LIENSERVICEHISTORIQUEENTREE_FIELDS
} from './fragments';
import { FICHIER_FIELDS } from '@/components/COMPONENTS-fichiers-stockages/graphql/fragments';
import { USER_FIELDS } from '@/components/COMPONENTS-authentification-habilitation/graphql/fragments';
import { FOURNISSEUR_FIELDS } from '@/components/COMPONENTS-devis-facture/graphql/fragments';

export const QUERY_asset = gql`
query($id: ID!){
  asset(id: $id){
    ...AssetFields
    marque{
        ...MarqueFields
    }
    modele{
      ...ModeleFields
      marque{
        ...MarqueFields
      }
    }
    photos{
      ...FichierFields
    }
    createdBy{
        ...UserFields
    }   
  }
}${ASSET_FIELDS},${MODELE_FIELDS},${FICHIER_FIELDS},${MARQUE_FIELDS},${USER_FIELDS}
`;

export const QUERY_assets = gql`
query($pagination: PaginationObject){
  assets(pagination: $pagination){
    ...AssetFields
    marque{
      ...MarqueFields
    }    
    modele{
      ...ModeleFields
      marque{
        ...MarqueFields
      }
    }
    photos{
      ...FichierFields
    }
  }  
  assetsCount(pagination:$pagination)
}${ASSET_FIELDS},${MODELE_FIELDS},${FICHIER_FIELDS},${MARQUE_FIELDS}
`;

export const QUERY_assetsCount = gql`
query($pagination: PaginationObject){
  assetsCount(pagination:$pagination)
  }
`;

export const QUERY_assetOrganisationParametres = gql`
  query {
    assetOrganisationParametres {
      ...AssetOrganisationParametresFields
    }
  }${ASSET_ORGANISATION_PARAMETRES_FIELDS}
`;

// export const QUERY_assetEmplacement = gql``;

// export const QUERY_assetEmplacements = gql``;

// export const QUERY_assetEmplacementsCount = gql``;

export const QUERY_lienService = gql`
query($id: ID!, $redirect:Boolean){
  lienService(id: $id, redirect:$redirect){
    ...LienServiceFields
    asset{
      ...AssetFields
      marque{
        ...MarqueFields
      }
      modele{
        ...ModeleFields
        marque{
          ...MarqueFields
        }
      }
    }
    fournisseur{
      ...FournisseurFields
    }
    createdBy{
        ...UserFields
    }
    historique{
        ...LienServiceHistoriqueEntreeFields
        user{
          ...UserFields
        }
    }  
  }
}${ASSET_FIELDS},${FOURNISSEUR_FIELDS},${LIENSERVICE_FIELDS},${USER_FIELDS},${LIENSERVICEHISTORIQUEENTREE_FIELDS},${USER_FIELDS}${MARQUE_FIELDS}${MODELE_FIELDS}
`;

export const QUERY_lienServices = gql`
query($pagination: PaginationObject){
  lienServices(pagination: $pagination){
    ...LienServiceFields
    asset{
      ...AssetFields
      
    }
    fournisseur{
      ...FournisseurFields
    }
    createdBy{
        ...UserFields
    }
    historique{
        ...LienServiceHistoriqueEntreeFields
        user{
          ...UserFields
        }
    }  

  }
  lienServicesCount(pagination:$pagination)
}${ASSET_FIELDS},${FOURNISSEUR_FIELDS},${LIENSERVICE_FIELDS},${USER_FIELDS},${LIENSERVICEHISTORIQUEENTREE_FIELDS},${USER_FIELDS}
`;

export const QUERY_lienServicesCount = gql`
query($pagination: PaginationObject){
  lienServicesCount(pagination: $pagination)
}
`;

export const QUERY_famille = gql`
  query($id: ID!) {
    famille(id: $id) {
      ...FamilleFields
      parent {
        ...FamilleFields
      }
      createdBy{
        ...UserFields
      } 
    }
  }${FAMILLE_FIELDS}${USER_FIELDS}
`;

export const QUERY_familles = gql`
  query($pagination: PaginationObject) {
    familles(pagination: $pagination) {
      ...FamilleFields
      parent {
        ...FamilleFields
      }
    }
  }${FAMILLE_FIELDS}
`;


export const QUERY_marque = gql`
query($id: ID!){
  marque(id: $id){
    ...MarqueFields
    logo{
      ...FichierFields
    }
    createdBy{
        ...UserFields
    }
  }
}${MARQUE_FIELDS},${FICHIER_FIELDS},${USER_FIELDS}
`;

export const QUERY_marques = gql`
query($pagination: PaginationObject){
  marques(pagination: $pagination) {
    ...MarqueFields
    logo{
      ...FichierFields
    }
  }
  marquesCount(pagination: $pagination)
}${MARQUE_FIELDS},${FICHIER_FIELDS}
`;

export const QUERY_marquesCount = gql`
query($pagination: PaginationObject){
  marquesCount(pagination: $pagination)
}
`;

export const QUERY_modele = gql`
query($id: ID!){
  modele(id: $id){
    ...ModeleFields
    marque{
      ...MarqueFields
    }
    autres{
      ...AutreFields
    }
    createdBy{
        ...UserFields
    }
  }
}${MODELE_FIELDS},${MARQUE_FIELDS},${AUTRE_FIELDS},${USER_FIELDS}
`;

export const QUERY_modeles = gql`
query($pagination: PaginationObject){
  modeles(pagination: $pagination) {
    ...ModeleFields
    marque{
      ...MarqueFields
    }
    autres{
      ...AutreFields
    }
  }
  modelesCount(pagination: $pagination)
}${MODELE_FIELDS},${MARQUE_FIELDS},${AUTRE_FIELDS}
`;

export const QUERY_modelesCount = gql`
query($pagination: PaginationObject){
  modelesCount(pagination: $pagination)
}
`;



