
<template>
  <div>
    <ActionLayout
      :actions="actions"
      :size="size"
      :action="action"
      :mainIcon="mainIcon"
    />
  </div>
</template>

<script>
import ActionLayout from "@/components/COMPONENTS-skeleton/Layouts/ActionLayout.vue";
import {
  MUTATION_partenaireDelete,
  MUTATION_partenaireUpdate,
} from "./graphql/mutations.js";
export default {
  name: "PartenaireActions",
  components: {
    ActionLayout,
  },

  props: {
    withView: { type: Boolean, default: true },
    mainIcon: { type: String, default: "mdi-handshake-outline" },
    object: { type: [Array, Object] },
    size: { type: String, default: "md" },
    action: { type: String, default: "router" },
    fetch: {
      type: Function,
      default() {
         
      },
    },
  },

  computed: {
    actions() {
      let toReturn = [];
      if (this.object.IsActif) {
        toReturn.push(
          {
            text: "Editer",
            icon: "mdi-pencil",
            path: "/satelease/partenariat/partenaires/edit/" + this.object.id,
            acl: "/satelease/partenariat/partenaires/edit",
            color: "",
          },
          {
            text: "Supprimer",
            icon: "mdi-delete",
            method: "editIsActif",
            acl: "/satelease/partenariat/partenaires/deleted",
            color: "error",
          }
        );
      } else {
        toReturn.push(
          {
            text: "Restauration",
            icon: "mdi-restore",
            method: "editIsActif",
            acl: "/satelease/partenariat/partenaires/edit",
            color: "",
          },
          {
            text: "Supprimer définitivement",
            icon: "mdi-delete",
            method: "delete",
            acl: "/satelease/partenariat/partenaires/definitive-delete",
            color: "error",
          }
        );
      }
      if (this.withView) {
        toReturn.unshift({
          text: "Visualiser",
          icon: "mdi-eye",
          path: "/satelease/partenariat/partenaires/view/" + this.object.id,
          acl: "/satelease/partenariat/partenaires/view",
          color: "",
        });
      }
      return toReturn;
    },
  },

  created() {
    if (!this.$root._events.deletePartenaire) {
      this.$root.$on("deletePartenaire", this.deletePartenaire);
    }
    if (!this.$root._events.editPartenaire) {
      this.$root.$on("editPartenaire", this.editPartenaire);
    }
  },

  methods: {
    /**
     * Supprimer un partenaire grâce à son id
     */
    deletePartenaire(id) {
      this.$apollo
        .mutate({
          mutation: MUTATION_partenaireDelete,
          variables: { id: id },
        })
        .then(() => {
          this.fetch();
        });
    },

    /**
     * Fonction à avoir dans tous les composants Actions (infos à changer selon le composant)
     * Permet de lancer la popup de validation
     *
     * obj : l'objet a supprimé
     */
    delete(obj) {
      // infos qui s'afficheront dans le popup
      let toValid = {
        titre: "Suppression définitive d'un partenaire",
        texte: `Êtes-vous sûr de vouloir supprimer définitivement le partenaire ${obj.numeroContrat} ?`,
        type: "warning",
        toReturn: {
          function: "deletePartenaire", // cette fonction change en fonction du composant (ex: PersonneActions.vue ça serait deletePersonne,
          //qu'il faut créer à la place de deletePartenaire)
          arg: obj.id,
        },
      };

      // Envoi l'info pour ouvrir la popup
      this.$root.$emit("popupValidation", toValid);
    },

    /**
     * Fonction qui édite un partenaire
     *
     * obj : l'objet à éditer
     */
    editPartenaire(obj) {
      // Selon le schéma, il est obligé d'avoir le siren et la raison sociale dans EntepriseInput
      // Bien vérifier dans le schéma si jamais il y a une erreur du style "$input etc.."
      let partenaire = {
        id: obj.id,
        numeroContrat: obj.numeroContrat,
        affaireAssociee: obj.affaireAssociee,
        isActif: !obj.isActif,
      };
      this.$apollo
        .mutate({
          mutation: MUTATION_partenaireUpdate,
          variables: {
            input: partenaire,
          },
        })
        .then(() => {
          this.fetch();
        });
    },

    /**
     * Fonction à avoir dans tous les composants (infos à changer)
     */
    editIsActif(obj) {
      let toValid = {
        titre: obj.isActif
          ? "Suppression d'un partenaire"
          : "Restauration d'un partenaire",
        texte: obj.isActif
          ? `Êtes-vous sûr de vouloir supprimer le partenaire ${obj.numeroContrat} ? ` +
            'Il sera toujours présente dans l\'onglet "Corbeille"'
          : `Êtes-vous sûr de vouloir restaurer le partenaire ${obj.numeroContrat} ?`,
        type: "warning",
        toReturn: {
          function: "editPartenaire", // à changer selon le composant, comme deletePartenaire
          arg: obj,
        },
      };

      this.$root.$emit("popupValidation", toValid);
    },
  },
};
</script>

