import gql from 'graphql-tag';

export const ACCORD_FIELDS = gql`
  fragment AccordFields on Accord{
    dateCreation
    etat
    id
    isActif
    message
    numeroAccord
    objet
    type
  }
`;

export const ACCORD_ETAPE_FIELDS = gql`
  fragment AccordEtapeFields on AccordEtape{
    id
    dateCreation
    etat
    type
    message
    nextAnd
    destinataire{
      personneAssociee{
        id
      }
      nom
      email
    }
  }
`