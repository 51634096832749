<template>
  <ListLayout
    ref="list"
    :headers="headers"
    :noFilter="noFilter"
    @fetch="init"
    @showMultipleActions="showMultipleActions = $event"
    title="Liste des règlements client"
  >
    <template v-slot:multipleActions>
      <ReglementClientActions
        :multiple="true"
        :object="$refs.list ? $refs.list.itemsSelected : []"
        size="xl"
        v-show="showMultipleActions"
        @fetch="init"
        @loading="$refs.list.loading = true"
      />
    </template>
    <template v-slot:customFilters>
      <ClientSelector :addAction="null" v-model="customFilters.client" />
    </template>
    <template v-slot:[`item.object`]="{ item }">
      <ReglementClientObject :reglementClientProp="item" size="xs" />
    </template>
    <template v-slot:[`item.client`]="{ item }">
      <ClientObject :clientID="item.client.id" size="xs" />
    </template>
    <template v-slot:[`item.factureClient`]="{ item }">
      <FactureClientObject
        :factureClientID="item.factureClient.id"
        size="xs"
        v-if="item.factureClient"
      />

      <v-chip v-else color="warning">Facture à attacher</v-chip>
    </template>
    <template v-slot:[`item.dateReglement`]="{ item }">
      {{ item.dateReglement | shortDateFR }}
    </template>
    <template v-slot:[`item.montantReglement`]="{ item }">
      {{ item.montantReglement | monetaire }}
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <ReglementClientActions
        :object="item"
        size="xs"
        @fetch="init"
        @loading="$refs.list.loading = true"
      />
    </template>
  </ListLayout>
</template>

<script>
import { QUERY_reglementClients } from "./graphql/queries";

import ClientObject from "./ClientObject.vue";
import ClientSelector from "./ClientSelector.vue";
import FactureClientObject from "./FactureClientObject.vue";
import ListLayout from "@/components/COMPONENTS-skeleton/Layouts/ListLayout.vue";
import ReglementClientActions from "./ReglementClientActions.vue";
import ReglementClientObject from "./ReglementClientObject.vue";

export default {
  name: "ReglementClientsList",

  components: {
    ClientObject,
    ClientSelector,
    FactureClientObject,
    ReglementClientActions,
    ReglementClientObject,
    ListLayout,
  },

  props: {
    customSearch: {
      type: Array,
      default() {
        return undefined;
      },
    },
    noFilter: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },

  data: () => ({
    customFilters: {
      client: null,
    },
    headers: [
      { text: "", value: "object", sortable: false },
      { text: "Client", value: "client", sortable: false },
      { text: "Facture", value: "factureClient", sortable: false },
      { text: "Date", value: "dateReglement" },
      { text: "Montant", value: "montantReglement" },
      { text: "Fichiers", value: "fichiers" },
      { text: "Commentaires", value: "commentaires" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    search: null,
    showMultipleActions: false,
  }),

  methods: {
    fetchReglementClients() {
      this.$refs.list.loading = true;
      let pagination = JSON.parse(JSON.stringify(this.$refs.list.pagination));

      if (this.customSearch) {
        this.customSearch.forEach((element) => {
          pagination.filter.customSearch.push(element);
        });
      }

      if (this.customFilters.client) {
        pagination.filter.customSearch.push({
          client: this.customFilters.client,
        });
      }

      this.$apollo
        .query({
          query: QUERY_reglementClients,
          variables: {
            pagination: pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          this.$refs.list.items = data.reglementClients;
          this.$refs.list.itemsCount = data.reglementClientsCount;
          this.$refs.list.loading = false;
        });
    },
    init() {
      this.fetchReglementClients();
    },
  },

  watch: {
    customFilters: {
      deep: true,
      handler() {
        this.$refs.list.currentPage = 1;
        this.init();
      },
    },
  },
};
</script>