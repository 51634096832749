<template>
  <ViewLayout :tabs="tabs">
    <template v-slot:parametres>
      <FormLayout
        icon="mdi-domain"
        @modifier="modifier"
        @reinitialiser="init"
        typeItem="les paramètres de l'organisation"
        :item="input"
      >
        <v-row class="ma-4" justify="center" no-gutters>
          <FichierLogo size="128" v-model="input.logo" :meta="meta" />
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="input.organisationName"
              label="Nom de l'organisation"
              prepend-icon="mdi-domain"
              :error-messages="formsErrors.organisationName"
              :rules="[(value) => value.length <= 20 || 'Max 20 caractères']"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="input.applicationName"
              label="Nom de l'application"
              prepend-icon="mdi-form-textbox"
            />
          </v-col>
        </v-row>
        <v-row v-if="!organisationID">
          <v-col>
            <v-input
              :messages="[
                'Changer l\'organisation du menu pour l\'ensemble de l\'organisation',
              ]"
              prepend-icon="mdi-format-list-bulleted-type"
            >
              <v-btn small @click="orderMenu"> Réorganiser le menu </v-btn>
            </v-input>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-subheader class="pl-0">
              Nombre maximum d'équipements par utilisateur
            </v-subheader>
            <v-slider v-model="input.nbEquipementsMax" max="10" min="1" thumb-label="always" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12"> </v-col>
        </v-row>

        <v-row v-if="!organisationID">
          <v-col cols="12">
            <v-subheader class="pl-0">
              Background de l'organisation
            </v-subheader>
            <TypeFichierForm
              :authorizedExtensions="['png', 'jpg', 'jpeg', 'webp']"
              :useCategory="false"
              :securise="false"
              :meta="{
                typeParent:
                  'AuthentificationHabilitationOrganisationParametres',
                idParent: authentificationHabilitationOrganisationParametres.id,
                nameParent:
                  authentificationHabilitationOrganisationParametres.organisationName &&
                  authentificationHabilitationOrganisationParametres
                    .organisationName.length <= 20
                    ? authentificationHabilitationOrganisationParametres.organisationName
                    : undefined,
                typeFichier: 'background',
                isPublic: true,
              }"
              class="ma-4"
            />
            <v-carousel hide-delimiters @change="fetchBackgroundsUrl" >
              <v-carousel-item
                v-for="n in carouselPages"
                :key="n"
              >
                <v-row>
                  <v-col
                    v-for="(item, index) in urlsBackgrounds"
                    :key="index"
                  >
                    <v-hover
                      v-slot="{ hover }"
                    >
                    <div>
                        {{hover}}
                        <v-img
                          :aspect-ratio="16/9"
                          width="200"
                          :src="item.url"
                        />
                      </div>
                    </v-hover>
                  </v-col>
                </v-row>
              </v-carousel-item>
            </v-carousel>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols ="12" >
            <v-subheader class="pl-0">
              Changer la couleur de l'application
            </v-subheader>
            <v-btn
              :color='input.color'
              large
              class="mt-12"
              outlined: true
              @click="
                $router.push({ path: `/authentification-habilitation/parametres/color-selector?popup=true&componentParent=component-${_uid}` })
              "
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols ="12" >
            <v-subheader class="pl-0">
              Ajouter des URLS personnalisées
            </v-subheader>
            <v-text-field
              v-model="urlCourante"
              prefix="https://"
              label="Ajout d'une URL personnalisée"
              persistent-hint
              append-outer-icon="mdi-plus-circle"
              @click:append-outer="addURL"
              :rules="[(value) => input.urls.indexOf(value) == -1 || 'L\'URL est déjà ajoutée']"
              :error-messages="
                formsErrors.authentificationHabilitationOrganisationParametresUrlDuplicate 
                ? formsErrors.authentificationHabilitationOrganisationParametresUrlDuplicate
                : formsErrors.authentificationHabilitationOrganisationParametresUrlCharacters 
                ? formsErrors.authentificationHabilitationOrganisationParametresUrlCharacters
                : ''
              "
            />
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      URL
                    </th>
                     <th class="text-left">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="url in input.urls"
                    :key="url"
                  >
                    <td>{{ url }}</td>
                    <td>
                      <v-icon
                        @click="editUrl(url)"
                      >
                        mdi-pencil
                      </v-icon>
                      <v-icon
                        @click="deleteUrl(url)"
                        color="error"
                      >
                        mdi-delete
                      </v-icon>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </FormLayout>
    </template>
    <template v-slot:mails>
      <v-alert text color="info">
        <div>Editez ici vos templates d'email servant aux notifications</div>
      </v-alert>
      <EmailTemplatesList
        :items="emailTemplatesAvailable"
        typeParent="AuthentificationHabilitationOrganisationParametres"
        :idParent="input.id"
      />
    </template>
  </ViewLayout>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";
import { 
  QUERY_authentificationHabilitationOrganisationParametres, 
  QUERY_authentificationHabilitationOrganisationParametresByNameOrId 
} from "./graphql/queries";
import { MUTATION_authentificationHabilitationOrganisationParametresUpdate } from "./graphql/mutations";
import { QUERY_fichiers } from "@/components/COMPONENTS-fichiers-stockages/graphql/queries";
import { MUTATION_downloadURLsPublicCreate } from "@/components/COMPONENTS-fichiers-stockages/graphql/mutations";

import EmailTemplatesList from "@/components/COMPONENTS-envoi-notifications/EmailTemplatesList.vue";
import FichierLogo from "@/components/COMPONENTS-fichiers-stockages/FichierLogo.vue";
import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";
import TypeFichierForm from "@/components/COMPONENTS-fichiers-stockages/TypeFichierForm.vue";
import ViewLayout from "@/components/COMPONENTS-skeleton/Layouts/ViewLayout.vue";

export default {
  name: "AuthentificationHabilitationOrganisationParametresForm",
  components: {
    EmailTemplatesList,
    FichierLogo,
    FormLayout,
    TypeFichierForm,
    ViewLayout
  },
  data: () => ({
    authentificationHabilitationOrganisationParametres: {
      id: "",
    },
    backgroundsCount: 0,
    emailTemplatesAvailable: [
      {
        type: "VerifUser",
        categories: [
          { text: "Confirmation de l'adresse mail", value: "Envoi Mail confirmation boite mail", acl: "USER" },
          { text: "Mot de passe oublié", value: "Mot de passe oublié", acl: "USER" },
          { text: "Authentification par lien de connexion", value: "Envoi Mail Magic Link", acl: "USER"},
          { text: "Code de connexion par mail", value: "Envoi Mail otp", acl: "USER"}
        ],
      },
    ],
    input: {
      nbEquipementsMax: 5,
      organisationName: "",
      urls: []
    },
    urlCourante: "",
    urlsBackgrounds: [],
    tabs: [
      { icon: "mdi-cog", displayName: "Paramètres", name: "parametres" },
      {
        icon: "mdi-email-newsletter",
        displayName: "Mails",
        name: "mails"
      },
    ],
  }),

  props: {
    organisationID: null
  },

  created() {
    this.$root.$on("skeleton--mainMenuSaveMenuOrder", this.saveMenuOrder);
    this.$root.$on("component-" + this._uid, this.changeColor);
    this.$root.$on("fichiersStockagesUpdateFichiers", this.fetchBackgroundsUrl);
  },

  beforeDestroy() {
    this.$root.$off("component-" + this._uid);
  },

  mounted: async function () {
    await this.init();
  },

  computed: {
    ...mapState(["formsErrors"]),

    carouselPages() {
      return Math.ceil(this.backgroundsCount / 5);
    },

    meta() {
      let res = [{ typeParent: "AuthentificationHabilitationOrganisationParametres", typeFichier: "logo", isPublic: true }];

      if(this.input.organisationName && this.input.organisationName != "") {
        res[0].nameParent = this.authentificationHabilitationOrganisationParametres.organisationName;
      }else {
        res[0].idParent = this.authentificationHabilitationOrganisationParametres.id;
      }

      return res
    }
  },

  watch: {
    authentificationHabilitationOrganisationParametres: function (val) {
      this.input = JSON.parse(JSON.stringify(val));
    },

    async organisationID() {
      await this.init();
    }
  },

  methods: {
    addURL() {
      if(this.input.urls.indexOf(this.urlCourante) == -1) {
        this.input.urls.push(this.urlCourante);
        this.urlCourante = "";
      }
    },

    changeColor(color) {
      this.input.color = color;
    },

    deleteUrl(url) {
      let index = this.input.urls.indexOf(url);
      if(index != -1) {
        if(this.input.urls.length == 1 || index == 0) {
          this.input.urls.shift();
        }else {
          this.input.urls.splice(index, index)
        }
      }
    },

    editUrl(url) {
      if(this.urlCourante == "") {
        this.deleteUrl(url);
        this.urlCourante = url;
      }
    },

    async fetchBackgroundsUrl(index) {
      if(!index) index = 0;
      let resFichiers = await this.$apollo
        .query({
          query: QUERY_fichiers,
          variables: {
            pagination: {
              limit: 5,
              page: index + 1,
              filter: {
                customSearch: [{ 'meta.typeFichier': 'background' }, { 'meta.typeParent': 'AuthentificationHabilitationOrganisationParametres' },
                { 'meta.idParent': this.authentificationHabilitationOrganisationParametres.id}]
              }
            }
          },
          fetchPolicy: 'no-cache'
        });

      let fichiers = resFichiers.data.fichiers;
      this.backgroundsCount = resFichiers.data.fichiersCount;

      let resUrls = await this.$apollo
        .mutate({
          mutation: MUTATION_downloadURLsPublicCreate,
          variables: {
            ids: fichiers.map(elem => elem.id)
          },
          fetchPolicy: 'no-cache'
        });

      this.urlsBackgrounds = resUrls.data.downloadURLsPublicCreate;
    },

    async init() {
      if(this.organisationID) {
        let resParams = await this.$apollo
          .query({
            query: QUERY_authentificationHabilitationOrganisationParametresByNameOrId,
            variables: {
              organisationNameOrId: this.organisationID
            },
            fetchPolicy: "no-cache",
          });

        let authentificationHabilitationOrganisationParametres =
            resParams.data.authentificationHabilitationOrganisationParametresByNameOrId;
          this.authentificationHabilitationOrganisationParametres = JSON.parse(
            JSON.stringify(authentificationHabilitationOrganisationParametres)
          );
      }else {
        let resParams = await this.$apollo
        .query({
          query: QUERY_authentificationHabilitationOrganisationParametres,
          fetchPolicy: "no-cache",
        });

        let authentificationHabilitationOrganisationParametres =
            resParams.data.authentificationHabilitationOrganisationParametres;
          this.authentificationHabilitationOrganisationParametres = JSON.parse(
            JSON.stringify(authentificationHabilitationOrganisationParametres)
          );

        await this.fetchBackgroundsUrl();
      }

      this.input.color = this.$vuetify.theme.themes.light.primary;
    },

    inputConverter(arg) {
      const obj = { ...arg };

      if (_.isEmpty(obj)) {
        return "";
      }

      let input = {
        nbEquipementsMax: obj.nbEquipementsMax,
        organisationName: obj.organisationName ? obj.organisationName.toLowerCase() : "",
        logo: obj.logo && obj.logo.id ? obj.logo.id : obj.logo,
        background: obj.background,
        customMainMenuOrder: obj.customMainMenuOrder,
        color: obj.color,
        urls: obj.urls, 
        applicationName: obj.applicationName,
        organisation: this.organisationID ? this.organisationID : undefined
      };

      return input;
    },

    modifier() {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation:
            MUTATION_authentificationHabilitationOrganisationParametresUpdate,
          variables: {
            input: toSend,
          },
        })
        .then(() => {
          if(!this.organisationID && this.input.color && this.input.color != "") {
            this.$vuetify.theme.themes.light.primary = this.input.color;
            this.$vuetify.theme.themes.dark.primary = this.input.color;
          }

          this.$store.commit("addAlert", {
            type: "info",
            text: "Le paramètrage de l'organisation a été modifié",
          });
          this.$root.$emit("skeleton--close");
        });
    },

    orderMenu() {
      this.$root.$emit("skeleton--openMenu");
      this.$root.$emit("skeleton--mainMenuChangeMenuOrder");
    },

    saveMenuOrder(items) {
      this.input.customMainMenuOrder = items;
      this.modifier();
    },
  },
};
</script>