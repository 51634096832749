<template>
  <ViewLayout :tabs="tabs" :item="devisFournisseur">
    <template v-slot:devisFournisseur>
      <DevisFournisseurActions
        :object="devisFournisseur"
        :withView="false"
        size="xl"
        mainIcon="mdi-file-cad"
      />
      <v-row class="align-center mx-auto">
        <v-col>
          <DevisFournisseurObject
            size="xl"
            :devisFournisseurProp="devisFournisseur"
          />
        </v-col>
      </v-row>
    </template>
  </ViewLayout>
</template>

<script>
import { QUERY_devisFournisseur } from "./graphql/queries";

import DevisFournisseurObject from "./DevisFournisseurObject.vue";
import DevisFournisseurActions from "./DevisFournisseurActions.vue";
import ViewLayout from "@/components/COMPONENTS-skeleton/Layouts/ViewLayout.vue";

export default {
  name: "DevisFournisseurView",

  components: {
    DevisFournisseurObject,
    DevisFournisseurActions,
    ViewLayout,
  },

  props: {
    id: {
      type: String,
    },

    showPopup: {
      type: Boolean,
    },
  },

  data: () => ({
    devisFournisseur: {},
    tabs: [
      {
        icon: "mdi-file-cad",
        displayName: "Devis",
        name: "devisFournisseur",
      },
      { name: "commentaires" },
      { name: "fichiers" },
    ],
  }),

  apollo: {
    devisFournisseur: {
      query: QUERY_devisFournisseur,
      variables() {
        let idQuery = this.showPopup
          ? this.id
          : this.$route.params.id
          ? this.$route.params.id
          : this.id;
        return {
          id: idQuery,
        };
      },
    },
  },
};
</script>