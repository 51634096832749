var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-h6 blue-grey--text py-2"},[_vm._v("Liste des assets attachés")]),_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.assets,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.asset",fn:function(ref){
var item = ref.item;
return [_c('AssetObject',{attrs:{"assetProp":item,"size":"xs"}})]}},{key:"item.marque",fn:function(ref){
var item = ref.item;
return [_c('MarqueObject',{attrs:{"marqueProp":item.modele.marque,"size":"xs"}})]}},{key:"item.modele",fn:function(ref){
var item = ref.item;
return [_c('ModeleObject',{attrs:{"modeleProp":item.modele,"size":"xs"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":"blue"},on:{"click":function($event){return _vm.editAsset(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.removeAsset(item)}}},[_vm._v(" mdi-pin-off ")])]}}],null,true)}),_c('v-row',{staticClass:"mt-5"},[_c('v-col',[_c('AssetSelector',{model:{value:(_vm.assetSelected),callback:function ($$v) {_vm.assetSelected=$$v},expression:"assetSelected"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.assetSelected ? false : true},on:{"click":_vm.addAsset}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-pin")]),_vm._v(" Attacher ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }