import gql from 'graphql-tag';

import { AUTHENTIFICATION_HABILITATION_APPLICATION_PARAMETRES_FIELDS, EQUIPEMENT_FIELDS, HABILITATION_FIELDS, ORGANISATION_FIELDS, USER_FIELDS, AUTHENTIFICATION_HABILITATION_ORGANISATION_PARAMETRES_FIELDS } from './fragments'
import { PERSONNE_FIELDS } from '@/components/COMPONENTS-annuaire/graphql/fragments'

export const QUERY_authentificationHabilitationApplicationParametres = gql`
  query {
    authentificationHabilitationApplicationParametres {
      ...AuthentificationHabilitationApplicationParametresFields
    }
  }${AUTHENTIFICATION_HABILITATION_APPLICATION_PARAMETRES_FIELDS}
`;

export const QUERY_authentificationHabilitationOrganisationParametres = gql`
  query {
    authentificationHabilitationOrganisationParametres {
      ...AuthentificationHabilitationOrganisationParametresFields
      organisation{
        ...OrganisationFields
      }
    }
  }${AUTHENTIFICATION_HABILITATION_ORGANISATION_PARAMETRES_FIELDS},${ORGANISATION_FIELDS}
`;

export const QUERY_authentificationHabilitationOrganisationParametresLogoBase64 = gql`
  query {
    authentificationHabilitationOrganisationParametres {
      id
        logo {
          id
          base64Content
        }
    }
  }
`;

export const QUERY_authentificationHabilitationOrganisationParametresByNameOrId = gql`
  query($organisationNameOrId: String!) {
    authentificationHabilitationOrganisationParametresByNameOrId(organisationNameOrId: $organisationNameOrId) {
      ...AuthentificationHabilitationOrganisationParametresFields
      organisation{
        ...OrganisationFields
      }
    }
  }${AUTHENTIFICATION_HABILITATION_ORGANISATION_PARAMETRES_FIELDS},${ORGANISATION_FIELDS}
`;

export const QUERY_authentificationHabilitationOrganisationParametresCustomMainMenuOrder = gql`
query {
    authentificationHabilitationOrganisationParametres {
      customMainMenuOrder
    }
  }
`;

export const QUERY_equipement = gql`
query($id: ID!){
  equipement(id: $id){
    ...EquipementFields   
  }
}${EQUIPEMENT_FIELDS}
`

export const QUERY_equipements = gql`
  query($pagination: PaginationObject, $all: Boolean){
    equipements(pagination: $pagination, all: $all){
      ...EquipementFields  
    }
    equipementsCount(pagination: $pagination, all: $all)
  }${EQUIPEMENT_FIELDS}
`;

export const QUERY_equipementsCount = gql`
query($pagination: PaginationObject){
  equipementsCount(pagination: $pagination)
  }
`;

export const QUERY_habilitation = gql`
  query ($id: ID!) {
    habilitation(id: $id){
    ...HabilitationFields
    
    organisation{
      ...OrganisationFields
    }   
    utilisateur{
      ...UserFields
    }
    createdBy{
      ...UserFields
    }
  }

  }${HABILITATION_FIELDS}${ORGANISATION_FIELDS}${USER_FIELDS}
`;

export const QUERY_habilitations = gql`
  query($pagination: PaginationObject){
    habilitations(pagination: $pagination){
      ...HabilitationFields
      organisation{
        ...OrganisationFields
      }
      utilisateur{
        ...UserFields
      }
    }
    habilitationsCount(pagination: $pagination)
  }${HABILITATION_FIELDS}${ORGANISATION_FIELDS}${USER_FIELDS}
`;

export const QUERY_habilitationsCount = gql`
query($pagination: PaginationObject){
  habilitationsCount(pagination: $pagination)
  }
`;

export const QUERY_resendConfirmationMail = gql`
  query($user: ID) {
    resendConfirmationMail(user: $user) {
      confirmationCode
    }
  }
`;

export const QUERY_systemContext = gql`
  query{
    systemContext   
}
`;

export const QUERY_user = gql`
query($id: ID!){
  user(id: $id){
    ...UserFields
    personneAssociee{
      ...PersonneFields
    }
  }
}${USER_FIELDS}${PERSONNE_FIELDS}
`;

export const QUERY_user_email = gql`
  query($id: ID) {
    user(id: $id) {
      email
    }
  }
`;

export const QUERY_user2FAGetOTPByEmail = gql`
  query {
    user2FAGetOTPByEmail
  }
`;

export const QUERY_userLogin = gql`
  mutation($input: UserInput) {
    userLogin(input: $input) {
      token
      refreshToken
    }
  }
`;

export const QUERY_userLogin2FA = gql`
  mutation($otp: String!) {
    userLogin2FA(otp: $otp) {
      token
      refreshToken
    }
  }
`;

export const QUERY_userLoginByRefreshToken = gql`
  mutation {
    userLoginByRefreshToken {
      token
      refreshToken
    }
  }
`;

export const QUERY_userLoginOAuthGoogle = gql`
  mutation($code: String!) {
    userLoginOAuthGoogle(code: $code) {
      token
      refreshToken
    }
  }
`;

export const QUERY_userLoginOAuthOffice = gql`
  mutation($code: String!) {
    userLoginOAuthOffice(code: $code) {
      token
      refreshToken
    }
  }
`;

export const QUERY_userLoginOrganisation = gql`
  mutation($organisationNameOrId: String!, $userAgent: String) {
    userLoginOrganisation(organisationNameOrId: $organisationNameOrId, userAgent: $userAgent) {
      token
      refreshToken
    }
  }
`;

export const QUERY_userLoginUpdater = gql`
  mutation($id:ID!){
    userLoginUpdater(id:$id){
      token
    }
  }
`;

export const QUERY_userLoginUsurpationByHabilitation = gql`
  mutation($id:ID!, $motif: String!){
    userLoginUsurpationByHabilitation(id:$id, motif: $motif){
      token
    }
  }
`;

export const QUERY_userLoginToken = gql`
  mutation {
    userLoginToken {
      token
      refreshToken
    }
  }
`;

export const QUERY_userLoginUsurpationByUser = gql`
  mutation($id:ID!){
    userLoginUsurpationByUser(id:$id){
      token
    }
  }
`;

export const QUERY_userLoginMagicLink = gql`
  mutation($code: String!) {
    userLoginMagicLink(code: $code) {
      token
    }
  }
`;

export const QUERY_userMagicLinkGetLink = gql`
  query($email: String!) {
    userMagicLinkGetLink(email: $email)
  }
`;

export const QUERY_userMe = gql`
  query {
    userMe {
      ...UserFields
      personneAssociee{
        ...PersonneFields
      } 
      equipements{
        ...EquipementFields
      }
    }
    habilitationsByMe {
      role
      organisation {
        id
        label
        fileStorage{
          id
        }
      }
    }
  }${EQUIPEMENT_FIELDS}${PERSONNE_FIELDS}${USER_FIELDS}
`;

export const QUERY_userMeConnected = gql`
  query {
    userMe {
      ...UserFields
      personneAssociee{
        ...PersonneFields
      } 
      equipements{
        ...EquipementFields
      }
    }
    habilitationsByMe {
      role
      organisation {
        id
        label
        fileStorage{
          id
        }
      }
    }
    organisationCurrent {
      ...OrganisationFields
    }
  }${EQUIPEMENT_FIELDS}${PERSONNE_FIELDS}${USER_FIELDS}${ORGANISATION_FIELDS}
`;

export const QUERY_users = gql`
  query($pagination: PaginationObject){
    users(pagination: $pagination){
      ...UserFields
      personneAssociee{
        ...PersonneFields
      }
    }
    usersCount(pagination: $pagination)
  }${USER_FIELDS}${PERSONNE_FIELDS}
`;

export const QUERY_usersCount = gql`
  query($pagination: PaginationObject){
    usersCount(pagination: $pagination)
    }
`;

export const QUERY_userTokenIs2FA = gql`
  query {
    userTokenIs2FA
  }
`;

export const QUERY_userTokenIsValid = gql`
  query {
    userTokenIsValid
  }
`;

export const QUERY_userVerifyCodePasswordReset = gql`
  query($code: String!) {
    userVerifyCodePasswordReset(code: $code)
  }
`;

export const QUERY_organisation = gql`
  query ($id: ID!) {
    organisation(id: $id){
    ...OrganisationFields
  }

  }${ORGANISATION_FIELDS}
`;

export const QUERY_organisationCurrent = gql`
  query {
    organisationCurrent {
      ...OrganisationFields
    }
  }${ORGANISATION_FIELDS}
`;

export const QUERY_organisations = gql`
  query($pagination:PaginationObject) {
    organisations(pagination:$pagination) {
    ...OrganisationFields
  }
  organisationsCount(pagination:$pagination)
  }${ORGANISATION_FIELDS}
`;

export const QUERY_organisationsCount = gql`
  query($pagination:PaginationObject) {
    organisationsCount(pagination:$pagination)
  }
`;

export const QUERY_organisationByUrl = gql`
  query($url: String!) {
    organisationByUrl(url: $url)
  }
`