<template>
  <FormLayout
    icon="mdi-link-box"
    @ajouter="ajouter"
    @modifier="modifier"
    @reinitialiser="reinitialiser"
    typeItem="un lien de service"
    :item="input"
  >
    <v-row>
      <v-col>
        <AssetSelector
          label="Equipement associé au lien"
          v-model="input.asset"
        />
      </v-col>
      <v-col>
        <FournisseurSelector
          label="Reservé à un fournisseur"
          v-model="input.fournisseur"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="input.lien"
          label="Lien personnalisé"
        />
      </v-col>
    </v-row>
  </FormLayout>
</template>

<script>
import {
  MUTATION_lienServiceCreate,
  MUTATION_lienServiceUpdate,
} from "./graphql/mutations";
import { QUERY_lienService } from "./graphql/queries";
import _ from "lodash";

import AssetSelector from "./AssetSelector.vue";
import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";
import FournisseurSelector from "@/components/COMPONENTS-devis-facture/FournisseurSelector.vue";
import { mapState } from "vuex";

export default {
  name: "LienServiceForm",

  components: {
    AssetSelector,
    FormLayout,
    FournisseurSelector,
  },

  data: () => ({
    input: {},
    lienService: {},
  }),

  computed: {
    ...mapState(["formsErrors"]),
  },

  methods: {
    ajouter() {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_lienServiceCreate,
          variables: {
            input: toSend,
          },
        })
        .then((__) => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "Le lien de service a été créé",
          });
          this.$root.$emit(
            "assets--lienService-create",
            __.data.lienServiceCreate
          );
          this.$root.$emit("skeleton--close");
          this.init();
        });
    },
    inputConverter(arg) {
      const obj = JSON.parse(JSON.stringify(arg));
      if (_.isEmpty(obj)) {
        return {};
      }

      let input = {
        id: obj.id,
        asset: obj.asset,
        fournisseur: obj.fournisseur,
        lien: obj.lien
      };

      return input;
    },
    modifier() {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_lienServiceUpdate,
          variables: {
            input: toSend,
          },
        })
        .then(() => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "Le lien de service a été modifié",
          });
          this.$root.$emit("assetsLienServicesRefetch");
          this.reinitialiser();
        });
    },
    reinitialiser() {
      //Clone le props dans data
      this.input = this.inputConverter(this.lienService);
    },
  },
  mounted: function () {
    if (this.$route.params.id) {
      let id = this.$route.params.id;
      this.$apollo
        .query({
          query: QUERY_lienService,
          variables: {
            id: id,
          },
        })
        .then((data) => {
          let lienService = data.data.lienService;
          this.input = JSON.parse(JSON.stringify(lienService));
        });
    } else {
      this.input = JSON.parse(JSON.stringify(this.lienService));
    }
  },
};
</script>