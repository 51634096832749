<template>
  <v-navigation-drawer v-model="drawerRight" clipped right absolute temporary :stateless="!current"
    :width="$vuetify.breakpoint.xs ? '100%' : '90%'" style="position: fixed">
    <v-btn style="position:fixed;top:4px;left:4px;z-index:100;" icon small @click="drawerRight = false">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-container fluid class="pt-10 px-6">
      <component v-bind="popups[number].params" :is="popups[number].composant.default"
        v-if="popups[number] && popups[number].composant" />
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "SkeletonPopup",
  props: {
    number: { type: Number, default: 0 },
  },
  mounted() { },
  data: () => ({}),
  computed: {
    ...mapState(["popups"]),
    current() {
      if (this.popups.length == this.number + 1) {
        return true;
      }
      return false;
    },

    drawerRight: {
      get() {
        if (this.popups[this.number]) {
          return true;
        } else {
          return false;
        }
      },
      set(val) {
        if (val == false) {
          this.$store.commit("resetPopup");
        }
      },
    },
  },
};
</script>