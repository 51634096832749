var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-tabs',{staticClass:"pb-2",attrs:{"fixed-tabs":"","show-arrows":"","height":"45"},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_vm._l((_vm.tabs),function(tab){return _c('v-tab',{key:tab.name},[(tab.name == 'fichiers')?[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-paperclip")]),_c('FichiersCounter',{attrs:{"customSearch":_vm.item
              ? [
                  { 'meta.typeParent': _vm.item.__typename },
                  { 'meta.idParent': _vm.item.id } ]
              : []},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_vm._v(" Fichiers ")]},proxy:true}],null,true)})]:_vm._e(),(tab.name == 'commentaires')?[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-comment")]),_c('CommentairesCounter',{attrs:{"customSearch":_vm.item
              ? [
                  { 'meta.typeParent': _vm.item.__typename },
                  { 'meta.idParent': _vm.item.id } ]
              : []},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_vm._v(" Commentaires ")]},proxy:true}],null,true)})]:[(tab.icon)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(_vm._s(tab.icon))]):_vm._e(),_vm._v(" "+_vm._s(tab.displayName)+" ")]],2)})],2),_c('v-tabs-items',{model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},_vm._l((_vm.tabs),function(tab){return _c('v-tab-item',{key:tab.name,staticClass:"mb-5"},[(tab.name == 'commentaires')?[_c('CommentairesObject',{staticClass:"py-1",attrs:{"typeParent":_vm.item.__typename,"idParent":_vm.item.id}})]:_vm._e(),(tab.name == 'fichiers')?[_c('FichiersObject',{staticClass:"py-1",attrs:{"meta":{
            typeParent: _vm.item.__typename,
            idParent: _vm.item.id,
          },"data":_vm.data ? _vm.data : _vm.item}})]:_vm._t(tab.name)],2)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }