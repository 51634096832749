<template>
  <div>
    <template v-if="fichier">
      <template v-if="size == 'xl'">
        <ObjectLayout :action="action" size="xl">
          <template v-slot:icon>mdi-file</template>
          <template v-slot:titre>
            {{ fichier.displayName }}
          </template>
          <template v-slot:body>
            <ObjectLayout>
              <template v-slot:titre>Fichier crée par</template>
              <template v-slot:content>{{ fichier.createdBy }}</template>
            </ObjectLayout>
            <ObjectLayout>
              <template v-slot:titre>Fichier crée le</template>
              <template v-slot:content>{{ fichier.dateCreation }}</template>
            </ObjectLayout>
          </template>
        </ObjectLayout>
      </template>
      <template v-if="size == 'md'">
        <ObjectLayout size="md"> </ObjectLayout>
      </template>
      <template v-if="size == 'xs'">
        <ObjectLayout
          :action="action"
          size="xs"
          :onClickPush="`/fichiers-stockages/fichiers/view/${fichier.id}`"
        >
          <template v-slot:icon>mdi-file</template>
          <template v-slot:content>
            {{ fichier.displayName }}
          </template>
        </ObjectLayout>
      </template>
    </template>
  </div>
</template>

<script>
import { QUERY_fichier } from "./graphql/queries";
import ObjectLayout from "@/components/COMPONENTS-skeleton/Layouts/ObjectLayout.vue";

export default {
  name: "FichierObject",
  components: {
    ObjectLayout,
  },
  props: {
    size: { type: String, default: "md" },
    fichierProp: {
      type: Object,
      default() {
        return {};
      },
    },
    fichierID: {
      type: String,
    },
    action: { type: String, default: "router" },
  },
  watch: {
    fichierProp: function () {
      this.init();
    },
    fichierID: function () {
      this.init();
    },
  },
  data: () => ({
    fichier: undefined,
  }),
  mounted: function () {
    this.init();
  },
  methods: {
    init() {
      if (this.fichierID) {
        let id = this.fichierID;
        this.$apollo
          .query({
            query: QUERY_fichier,
            variables: { id: id },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let fichier = data.data.fichier;
            this.fichier = JSON.parse(JSON.stringify(fichier));
          });
      } else {
        this.fichier = JSON.parse(JSON.stringify(this.fichierProp));
      }
    },
  },
};
</script>

