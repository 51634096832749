var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ListLayout',{ref:"list",attrs:{"headers":_vm.headers,"title":_vm.title,"noFilter":_vm.noFilter},on:{"fetch":_vm.init,"showMultipleActions":function($event){_vm.showMultipleActions = $event}},scopedSlots:_vm._u([(_vm.multiple)?{key:"multipleActions",fn:function(){return [_c('DevisFournisseurActions',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMultipleActions),expression:"showMultipleActions"}],attrs:{"multiple":true,"object":_vm.$refs.list ? _vm.$refs.list.itemsSelected : [],"size":"xl"},on:{"fetch":_vm.init,"loading":function($event){_vm.$refs.list.loading = true}}})]},proxy:true}:null,{key:"customFilters",fn:function(){return [_c('v-checkbox',{attrs:{"label":"Attaché à une facture"},model:{value:(_vm.customFilters.isAttached),callback:function ($$v) {_vm.$set(_vm.customFilters, "isAttached", $$v)},expression:"customFilters.isAttached"}}),_c('ClientSelector',{attrs:{"addAction":null},model:{value:(_vm.customFilters.client),callback:function ($$v) {_vm.$set(_vm.customFilters, "client", $$v)},expression:"customFilters.client"}}),_c('FournisseurSelector',{attrs:{"addAction":null},model:{value:(_vm.customFilters.fournisseur),callback:function ($$v) {_vm.$set(_vm.customFilters, "fournisseur", $$v)},expression:"customFilters.fournisseur"}})]},proxy:true},_vm._l((_vm.$scopedSlots),function(index,name){return {key:name,fn:function(data){return [_vm._t(name,null,null,data)]}}}),{key:"item.object",fn:function(ref){
var item = ref.item;
return [_c('DevisFournisseurObject',{attrs:{"devisFournisseurProp":item,"size":"xs"}})]}},{key:"item.dateCreation",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("shortDateFR")(item.dateCreation))+" ")]}},{key:"item.client",fn:function(ref){
var item = ref.item;
return [(item.client.id)?_c('ClientObject',{attrs:{"clientID":item.client.id,"size":"xs","action":"popup"}}):_vm._e()]}},{key:"item.fournisseur",fn:function(ref){
var item = ref.item;
return [(item.fournisseur)?_c('FournisseurObject',{attrs:{"fournisseurID":item.fournisseur.id,"size":"xs","action":"popup"}}):_vm._e()]}},{key:"item.montantHT",fn:function(ref){
var item = ref.item;
return [_c('MoneyLayout',{attrs:{"montant":item.montantHT}})]}},{key:"item.montantTVA",fn:function(ref){
var item = ref.item;
return [_c('MoneyLayout',{attrs:{"montant":item.montantTVA}})]}},{key:"item.factureAssociee",fn:function(ref){
var item = ref.item;
return [(item.factureAssociee && item.factureAssociee.id)?_c('FactureFournisseurObject',{attrs:{"size":"xs","factureFournisseurID":item.factureAssociee.id}}):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('DevisFournisseurActions',{attrs:{"object":item,"size":"xs"},on:{"fetch":_vm.init,"loading":function($event){_vm.$refs.list.loading = true}}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }