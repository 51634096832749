<template>
  <v-container>
    <p class="text-center" v-if="!items[0]">
      Il n'y a pas de template d'email associé
    </p>
    <v-treeview dense :items="items" open-on-click>
      <template v-slot:prepend="{}">
        <v-icon> mdi-email-newsletter </v-icon>
      </template>
      <template slot="label" slot-scope="{ item }">
        <template v-if="item.systemEmailTemplate">
          <v-badge color="info" content="Système" inline>
            {{ item.name }}
          </v-badge>
        </template>
        <template v-else> {{ item.name }}</template>
      </template>
      <template slot="append" slot-scope="{ item }">
        <v-btn
          icon
          @click="favoriteItem(item)"
          v-if="!item.systemEmailTemplate"
        >
          <v-icon :color="item.meta.favorite ? 'primary' : null">
            mdi-star
          </v-icon>
        </v-btn>
        <v-btn icon @click="dupliqueItem(item)">
          <v-icon> mdi-content-copy </v-icon>
        </v-btn>
        <v-btn icon @click="deleteItem(item)" v-if="!item.systemEmailTemplate">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-treeview>
  </v-container>
</template>

<script>
import { QUERY_emailTemplates } from "./graphql/queries";

import {
  MUTATION_emailTemplateDelete,
  MUTATION_emailTemplateMetaFavorite,
} from "./graphql/mutations";

export default {
  name: "EmailTemplatesTreeList",
  components: {},
  props: {
    customSearch: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data: () => ({
    items: [],
  }),

  created() {
    this.$root.$on("envoiNotificationUpdateEmailTemplate", this.init);
    this.$root.$on("deleteEmailTemplate", this.deleteEmailTemplate);
  },

  mounted: function () {
    this.init();
  },

  methods: {
    deleteItem(item) {
      let toValid = {
        titre: "Suppression d'un template d'email",
        texte: `Êtes-vous sûr de vouloir supprimer le template ${item.name} ?`,
        type: "warning",
        toReturn: {
          function: "deleteEmailTemplate",
          arg: item.id,
        },
      };
      this.$root.$emit("popupValidation", toValid);
    },

    deleteEmailTemplate(id) {
       
      this.$apollo
        .mutate({
          mutation: MUTATION_emailTemplateDelete,
          variables: { id: id },
        })
        .then(() => {
          this.$root.$emit("envoiNotificationUpdateEmailTemplate");
        })
        .catch((e) => {
          console.error(e);
        });
    },

    dupliqueItem(item) {
      this.$emit("duplique", { ...item });
    },

    favoriteItem(item) {
       
       
      this.$apollo
        .mutate({
          mutation: MUTATION_emailTemplateMetaFavorite,
          variables: {
            input: {
              idEmailTemplate: item.id,
              customSearch: this.customSearch,
            },
          },
        })
        .then(() => {
          this.$root.$emit("envoiNotificationUpdateEmailTemplate");
        })
        .catch((e) => {
          console.error(e);
        });
    },
    init() {
      this.$apollo
        .query({
          query: QUERY_emailTemplates,
          variables: {
            pagination: {
              limit: 0,
              filter: {
                customSearch: this.customSearch,
              },
            },
          },
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          this.items = data.data.emailTemplates;
           
        });
    },
  },
};
</script>