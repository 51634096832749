<template>
  <div>
    <v-row dense>
      <v-col cols="3">
        <v-text-field
          v-model="adresse.numeroVoie"
          name="numeroVoie"
          label="N°"
          :disabled="isValid"
        />
      </v-col>
      <v-col cols="9">
        <v-text-field
          v-model="adresse.libelleVoie"
          name="libelleVoie"
          label="Voie"
          :disabled="isValid"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="3">
        <v-text-field
          v-model="adresse.codePostal"
          name="codePostal"
          label="Code postal"
          :disabled="isValid"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="adresse.libelleCommune"
          name="libelle_commune"
          label="Commune"
          :disabled="isValid"
        />
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field
          v-model="adresse.cedex"
          name="cedex"
          label="Cedex"
          :disabled="isValid"
        />
      </v-col>
    </v-row>

    <v-btn
      :color="isValid ? 'warning' : 'primary'"
      @click="change"
    >
      {{  isValid ? "Modifier l'adresse" : "Valider l'adresse"  }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "AdressePostaleForm",

  data: () => ({
    adresse: {},
    isValid: false
  }),

  props: {
    adresseProp: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  
  methods: {
    change() {
      if(this.isValid) {
        this.isValid = false;
      }else {
        this.valid();
      }
    },

    valid() {
      this.$emit('onValid', this.adresse);
      this.isValid = true;
    }
  },

  watch: {
    adresseProp: {
      immediate: true,
      handler(val) {
        this.adresse = {
          numeroVoie: val.numeroVoie,
          libelleVoie: val.libelleVoie,
          codePostal: val.codePostal,
          libelleCommune: val.libelleCommune,
          cedex: val.cedex
        }
      }
    }
  }
}
</script>