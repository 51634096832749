<template>
  <div>
    <v-dialog
      v-model="dialog"
    >
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Raison sociale
              </th>
              <th class="text-left">
                Siren
              </th>
              <th class="text-left">
                Code postal du siège
              </th>
              <th class="text-left">
                Forme juridique
              </th>
              <th class="text-left"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in entreprises"
              :key="item.siren"
            >
              <td>{{ item.raisonSociale }}</td>
              <td>{{ item.siren }}</td>
              <td>{{ item.codePostalSiege }}</td>
              <td>{{ item.formeJuridique }}</td>
              <td>
                <v-btn
                  color="primary"
                  @click="getEntreprise(item.siren)"
                >
                  <v-icon>
                    mdi-check
                  </v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-dialog>
  </div>
</template>

<script>
import { QUERY_entrepriseInformationFromSiren, QUERY_entreprisesInformationsFromRaisonSociale } from './graphql/queries';

export default {
  name: "EntreprisesListByRaisonSociale",

  data: () => ({
    dialog: true,
    entreprises: []
  }),

  methods: {
    fetchEntreprises() {
      this.dialog = true;
      this.entreprises = [];

      this.$apollo
        .query({
          query: QUERY_entreprisesInformationsFromRaisonSociale,
          variables: { raisonSociale: this.raisonSociale },
          fetchPolicy: "no-cache",
        })
        .then(async (__) => {
          const data = __.data.entreprisesInformationsFromRaisonSociale;
          console.log(data)
          this.entreprises = data;
          console.log(this.entreprises)
        });
    },

    getEntreprise(siren) {
      if(this.returnOnlySiren) {
        this.$emit('getEntreprise', siren)
        this.dialog = false;
      }else {
        this.$apollo
          .query({
            query: QUERY_entrepriseInformationFromSiren,
            variables: { siren },
            fetchPolicy: 'no-cache'
          })
          .then(__ => {
            this.$emit('getEntreprise', __.data.entrepriseInformationFromSiren)
            this.dialog = false;
          })
      }
    }
  },

  props: {
    raisonSociale: {
      type: String,
      default() {
        return null;
      }
    },

    returnOnlySiren: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    raisonSociale: {
      immediate: true,
      handler(val) {
        if(val && val != "") {
          this.fetchEntreprises();
        }
      }
    },
  }
}
</script>