<template>
  <div v-if="organisationProp">
    <template v-if="size == 'xs'">
      <v-btn rounded class="pa-0" @click="makeUpdates">
        <CounterLayout :compteur="updates.length">
          <template v-slot:icon>mdi-update</template>
        </CounterLayout>
      </v-btn>
    </template>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { AUTH_TOKEN } from "@/components/COMPONENTS-skeleton/vue-apollo.js";

import { MUTATION_organisationUpdate } from "./graphql/mutations";
import { QUERY_userLoginUpdater } from "./graphql/queries";

import CounterLayout from "@/components/COMPONENTS-skeleton/Layouts/CounterLayout.vue";

export default {
  name: "OrganisationUpdater",

  components: {
    CounterLayout,
  },

  props: {
    organisationProp: null,
    size: { type: String, default: "xs" },
  },

  data: () => ({
    updates: [],
  }),

  mounted() {
    this.getUpdate();
  },

  methods: {
    getUpdate() {
      let dateUpdateOrganisation = this.organisationProp.dateMiseAJour;

      // On récupère la liste des fichiers de mises à jour des répertoires enfants de @/components
      const files = require
        .context("/src/components/", true, /\.update\.js$/)
        .keys();

      files.forEach((file) => {
        let strDate = file.slice(file.length - 30, file.length - 10);
        let componentsName = file.slice(2, file.length - 39);
        if (dayjs(strDate).isAfter(dateUpdateOrganisation)) {
          let module = require(`/src/components/${file.substring(2)}`);
          this.updates.push({
            date: dayjs(strDate),
            function: module.default,
            components: componentsName,
          });
          // module.default(this);
        }
      });
      this.updates.sort(function (a, b) {
        if (a.date < b.date) return -1;
        if (a.date > b.date) return 1;
      });
    },

    async makeUpdates() {
      let currentToken = JSON.parse(
        JSON.stringify(localStorage.getItem(AUTH_TOKEN))
      );

      for (let update of this.updates) {
        console.info("🚀🚀🚀🚀🚀🚀");
        console.info(
          "On applique la mise à jour de " +
            update.components +
            " du " +
            update.date.format("DD-MM-YYYY hh:mm")
        );

        let __ = await this.$apollo.mutate({
          mutation: QUERY_userLoginUpdater,
          variables: {
            id: this.organisationProp.id,
          },
          fetchPolicy: "no-cache",
        });

        let updateToken = __.data.userLoginUpdater.token;

        localStorage.setItem(AUTH_TOKEN, updateToken);

        await update.function(this);

        localStorage.setItem(AUTH_TOKEN, currentToken);
        await this.$apollo.mutate({
          mutation: MUTATION_organisationUpdate,
          variables: {
            input: {
              id: this.organisationProp.id,
              dateMiseAJour: update.date,
            },
          },
        });
      }

      this.$emit("fetch");
    },
  },
};
</script>
