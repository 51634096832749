<template>
  <ListLayout ref="list" :headers="headers" @fetch="init">
    <template v-slot:[`item.object`]="{ item }">
      <OrganisationObject :organisationProp="item" size="xs" />
    </template>

    <template v-slot:[`item.label`]="{ item }">
      {{ item.label }}
    </template>

    <template v-slot:[`item.habilitationCount`]="{ item }">
      <HabilitationCounter :customSearch="[{ organisation: item.id }]" />
    </template>

    <template v-slot:[`item.updater`]="{ item }">
      <OrganisationUpdater :organisationProp="item" @fetch="init" />
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <OrganisationActions
        :object="item"
        :fetch="fetchOrganisations"
        size="xs"
      />
    </template>
  </ListLayout>
</template>

<script>
import { QUERY_organisations } from "./graphql/queries";

import HabilitationCounter from "./HabilitationCounter.vue";
import ListLayout from "@/components/COMPONENTS-skeleton/Layouts/ListLayout.vue";
import OrganisationActions from "./OrganisationActions.vue";
import OrganisationObject from "./OrganisationObject.vue";
import OrganisationUpdater from "./OrganisationUpdater.vue";

export default {
  name: "OrganisationsList",

  components: {
    HabilitationCounter,
    ListLayout,
    OrganisationObject,
    OrganisationActions,
    OrganisationUpdater,
  },

  data: () => ({
    search: null,

    headers: [
      { text: "Organisation", value: "object", sortable: false },
      { text: "Label", value: "label" },
      {
        text: "Habilitations",
        value: "habilitationCount",
        sortable: false,
      },
      {
        text: "Mise à jour",
        value: "updater",
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),

  mounted() {},
  methods: {
    fetchOrganisations() {
      this.$refs.list.items = [];
      this.$refs.list.loading = true;
      
      this.$apollo
        .query({
          query: QUERY_organisations,
          variables: {
            pagination: this.$refs.list.pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          this.$refs.list.items = data.organisations;
          this.$refs.list.itemsCount = data.organisationsCount;
          this.$refs.list.loading = false;
        });
    },
    init() {
      this.fetchOrganisations();
    },
  },
};
</script>

