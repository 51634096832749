<template>
  <div>
    <template v-for="(action, index) in actionsToDisplay">
      <template v-if="size == 'xs'">
        <v-tooltip bottom :key="'action' + index" v-if="acl(action.acl)">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              :color="action.color"
              v-bind="attrs"
              v-on="on"
              small
              class="mr-2"
              @click="
                action.path
                  ? clickAction(action.path, action.popup)
                  : executeParent(action.method)
              "
            >
              {{ action.icon }}
            </v-icon>
          </template>
          <span>{{ action.text }}</span>
        </v-tooltip>
      </template>
    </template>

    <template v-if="size == 'md'">
      <v-speed-dial
        :key="'action' + index"
        class="mt-9"
        v-model="fab"
        bottom
        open-on-hover="hover"
        left
        :direction="direction"
        :transition="transition"
      >
        <template v-slot:activator>
          <v-btn x-small v-model="fab" color="blue darken-2" dark fab>
            <v-icon v-if="fab"> mdi-close </v-icon>
            <v-icon v-else> mdi-plus </v-icon>
          </v-btn>
        </template>
        <template v-for="(action, index) in actionsToDisplay">
          <v-btn
            :key="'action' + index"
            v-if="acl(action.acl)"
            x-small
            fab
            dark
            class="ml-2"
            :color="action.color"
            @click="clickAction(action.path)"
          >
            <v-icon>{{ action.icon }}</v-icon>
          </v-btn>
        </template>
      </v-speed-dial>
    </template>

    <template v-if="size == 'xl'">
      <v-speed-dial
        v-model="fab"
        fixed
        bottom
        right
        direction="top"
        transition="slide-y-reverse-transition"
        style="z-index: 5; bottom: 60px"
      >
        <template v-slot:activator>
          <v-btn v-model="fab" color="primary" dark fab>
            <v-icon v-if="fab"> mdi-chevron-down </v-icon>
            <v-icon v-else>
              {{
                conditions.multiple ? "mdi-checkbox-multiple-marked" : mainIcon
              }}
            </v-icon>
          </v-btn>
        </template>
        <template v-for="(action, index) in actionsToDisplay">
          <v-tooltip left :key="'subButton' + index" v-if="acl(action.acl)">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                v-bind="attrs"
                v-on="on"
                dark
                small
                :color="action.color ? action.color : 'primary'"
                @click="
                  action.path
                    ? clickAction(action.path, action.popup)
                    : executeParent(action.method)
                "
              >
                <v-icon>{{ action.icon }}</v-icon>
              </v-btn>
            </template>
            <span>{{ action.text }}</span>
          </v-tooltip>
        </template>
      </v-speed-dial>
    </template>
  </div>
</template>

<script>
export default {
  name: "ActionLayout",

  props: {
    actions: {
      type: Array,
      default() {
        return [];
      },
    },

    mainIcon: { type: String, default: "mdi-flask" },

    size: { type: String, default: "md" },
  },

  data: () => ({
    direction: "left",
    fab: false,
    transition: "slide-y-reverse-transition",
  }),

  // Lors de la création d'un selecteur, on écoute grâce à l'id du composant
  // généré si un formulaire renvoie de la data en réponse au clic "ajout"
  created() {
    this.$root.$on("component-" + this._uid, (data) => {
      this.$emit("fetch", data);
    });
  },

  beforeDestroy() {
    this.$root.$off("component-" + this._uid);
  },

  computed: {
    actionsToDisplay() {
      let toDisplay = [];
      let object = this.$parent.object;
      this.actions.forEach((action) => {
        if (
          action.conditions &&
          action.conditions.objet !== undefined &&
          !Array.isArray(object)
        ) {
          for (const [key, value] of Object.entries(action.conditions.objet)) {
            if (object[key] != value) {
              return;
            }
          }
        }
        action.conditions = action.conditions ? action.conditions : {};
        if (
          (action.conditions.size !== undefined &&
            action.conditions.size.includes(this.size)) ||
          action.conditions.size === undefined
        ) {
          if (
            (action.conditions.isActif !== undefined &&
              this.conditions.isActif === action.conditions.isActif) ||
            action.conditions.isActif === undefined
          ) {
            if (this.conditions.multiple && action.conditions.multiple) {
              toDisplay.push(action);
            }
            if (
              !this.conditions.multiple &&
              (!action.conditions.single === false ||
                action.conditions.single === undefined)
            ) {
              toDisplay.push(action);
            }
          }
        }
      });
      return toDisplay;
    },

    conditions() {
      let object = this.$parent.object;
      return {
        multiple: this.$parent.multiple,
        isActif: Array.isArray(object)
          ? object[0]
            ? object[0].isActif
            : undefined
          : object.isActif,
        object: object,
      };
    },
  },

  methods: {
    //Lors du clic sur un objet compatible (xs)
    clickAction(path, popup = false) {
      // Par défaut on utilise router.push, on actualise la vue chargée dans router-view
      if (!popup) {
        this.$router.push({ path: path });
      } else if (popup) {
        // si on choisit d'utiliser une popup, on ajoute une option à la fin de l'URL
        // router/index.js se charge de la suite
        let link = path;
        link.includes("?") ? (link += "&") : (link += "?");

        link += "popup=true&componentParent=component-" + this._uid;

        this.$router.push(link);
      }
    },
    executeParent(method) {
      this.$parent[method](this.$parent.object);
    },
  },
};
</script>