<template>
  <FormLayout
    icon="mdi-application-cog"
    typeItem="les paramètres de l'application"
    :item="input"
    :hideAllButtons="true"
  >
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Jetons totaux
            </th>
            <th class="text-left">
              Jetons restants
            </th>
            <th class="text-left">
              Jetons utilisés
            </th>
            <th class="text-left">
              Jetons Pay As You Go
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ input.suiviJetons.jetonsTotal }}</td>
            <td>{{ input.suiviJetons.jetonsRestants }}</td>
            <td>{{ (input.suiviJetons.jetonsTotal - input.suiviJetons.jetonsRestants).toFixed(1) }}</td>
            <td>{{ input.suiviJetons.jetonsPayAsYouGo }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </FormLayout>
</template>

<script>
import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";

import { QUERY_annuaireApplicationParametres } from "./graphql/queries.js";

export default {
  name: "AuthentificationHabilitationApplicationParametresForm",

  components: {
    FormLayout
  },

  data: () => ({
    annuaireApplicationParametres: {
      id: ""
    },

    input: {
      suiviJetons: {}
    }
  }),

  methods: {
    init() {
      this.$apollo
        .query({
          query: QUERY_annuaireApplicationParametres,
          fetchPolicy: "no-cache",
        })
        .then(__ => {
          this.annuaireApplicationParametres = JSON.parse(
            JSON.stringify(__.data.annuaireApplicationParametres)
          );
        })
    },
  },

  mounted() {
    this.init();
  },

  watch: {
    annuaireApplicationParametres: function (val) {
      this.input = JSON.parse(JSON.stringify(val));
    },
  }
}
</script>
