<template>
  <div>
    <template v-if="habilitation">
      <template v-if="size == 'xl'">
        <ObjectLayout :action="action" size="xl" :item="habilitation">
          <template v-slot:icon>'mdi-key'</template>
          <template v-slot:titre> {{ habilitation.id }}</template>
          <template v-slot:body>
            <ObjectLayout v-if="habilitation.id" class="py-2">
              <template v-slot:icon>mdi-tag-text</template>
              <template v-slot:titre>Dénomination de l'habilitation</template>
              <template v-slot:content>{{ habilitation.id }}</template>
            </ObjectLayout>
          </template>
        </ObjectLayout>
      </template>
      <template v-if="size == 'md'">
        <ObjectLayout size="md">
          <template v-slot:icon>'mdi-key'</template>
          <template v-slot:content>{{ habilitation.id }}</template>
          <template v-slot:extracontent>
            <v-icon small class="mr-2">mdi-material-design</v-icon>
            {{ habilitation.id }}
          </template>
        </ObjectLayout>
      </template>
      <template v-if="size == 'xs'">
        <ObjectLayout
          size="xs"
          :action="action"
          :onClickPush="`/authentification-habilitation/habilitations/view/${habilitation.id}`"
        >
          <template v-slot:icon>'mdi-key'</template>
          <template v-slot:content
            >{{ habilitation.numeroInventaire }} -
            {{ habilitation.id }}</template
          >
        </ObjectLayout>
      </template>
    </template>
  </div>
</template>

<script>
import { QUERY_habilitation } from "./graphql/queries";
import ObjectLayout from "@/components/COMPONENTS-skeleton/Layouts/ObjectLayout.vue";
export default {
  name: "HabilitationObject",

  components: {
    ObjectLayout,
  },

  props: {
    size: { type: String, default: "md" },
    habilitationProp: {
      type: Object,
      default() {
        return {};
      },
    },
    habilitationID: {
      type: String,
    },
    action: {
      type: String,
      default: "router",
    },
  },

  watch: {
    habilitationProp: function () {
      this.init();
    },
    habilitationID: function () {
      this.init();
    },
  },

  data: () => ({
    habilitation: undefined,
  }),

  mounted: function () {
    this.init();
  },

  methods: {
    init() {
      if (this.habilitationID) {
        let id = this.habilitationID;
        this.$apollo
          .query({
            query: QUERY_habilitation,
            variables: { id: id },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let habilitation = data.data.habilitation;
            this.habilitation = JSON.parse(JSON.stringify(habilitation));
          });
      } else {
        this.habilitation = JSON.parse(JSON.stringify(this.habilitationProp));
      }
    },
  },
};
</script>