<template>
  <div>ComptabiliteOrganisationParametresForm</div>
</template>

<script>
export default {
  name: "ComptabiliteOrganisationParametresForm",

  components: {},

  data: () => ({}),

  methods: {}
}
</script>