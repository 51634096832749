<template>
  <div>AvoirSelector</div>
</template>

<script>
export default {
  name: "AvoirSelector",
};
</script>

