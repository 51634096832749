var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"justify-center",attrs:{"elevation":"2"}},[_c('TitleLayout',{attrs:{"titre":"Géolocalisation","sousTitre":!_vm.location
        ? 'Pour accéder à votre géolocalisation, cliquez sur le boutton'
        : 'Pour réinitialiser les cordonnées, cliquez sur le bouton ci-dessous'}}),_c('v-row',{staticClass:"text-center"},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.location)?[_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"10"}},[_c('GmapMap',{staticStyle:{"width":"100%","height":"320px"},attrs:{"center":{
              lat: parseFloat(_vm.location.coords.latitude),
              lng: parseFloat(_vm.location.coords.longitude),
            },"zoom":10,"map-type-id":"terrain","options":{
              zoomControl: true,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: true,
              disableDefaultUi: false,
            }}},[_c('GmapMarker',{attrs:{"position":{
                lat: parseFloat(_vm.location.coords.latitude),
                lng: parseFloat(_vm.location.coords.longitude),
              },"draggable":true,"clickable":true},on:{"drag":_vm.updateCoordinates,"click":function($event){_vm.center = {
                  lat: parseFloat(_vm.latitude),
                  lng: parseFloat(_vm.longitude),
                }}}})],1)],1)]:[_c('div',{staticClass:"py-3"})]],2),_c('v-col',{staticClass:"py-4",attrs:{"cols":"12"}},[_c('v-btn',{class:{ primary: !_vm.location, warning: _vm.location },on:{"click":function($event){!_vm.location ? _vm.locateMe() : _vm.reloadLocation()}}},[_vm._v(" "+_vm._s(!_vm.location ? "Récupérer la position" : " Réinitialiser"))])],1),(_vm.errorStr)?_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" Désolé, mais l'erreur suivante s'est produit: "+_vm._s(_vm.errorStr)+" ")]):_vm._e(),(_vm.gettingLocation)?_c('v-col',{attrs:{"cols":"12"}},[_c('i',[_vm._v(" Obtenir votre position...")])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }