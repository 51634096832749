<template>
  <v-container class="fill-height fullBG" fluid>
    <v-row align="center" justify="center">
      <v-col lg="5">
        <v-container>
          <v-card elevation="2" max-width="600" class="mx-auto pb-6">
            <v-img
              height="280px"
              src="./assets/UserUrlCodeVerif_01.jpg"
              class="grey--text align-end"
            />
            <v-card-text>
              <template v-if="verified == 'desac'">
                <p
                  class="
                    text-center
                    text-lg-subtitle-1
                    text-md-subtitle-2
                    text-sm-body-2
                    text-caption
                  "
                >
                  Vérification du lien en cours, ne rafraîchissez pas la
                  page
                </p>
              </template>

              <template v-else-if="verified == 'expires'">
                <p
                  class="
                    text-center
                    text-lg-subtitle-1
                    text-md-subtitle-2
                    text-sm-body-2
                    text-caption
                  "
                >
                  Le lien a expiré, vous allez être redirigé.
                </p>
              </template>

              <template v-else-if="verified == 'OK'">
                <p
                  class="
                    text-center
                    text-lg-subtitle-1
                    text-md-subtitle-2
                    text-sm-body-2
                    text-caption
                  "
                >
                  <UserEditPasswordForm 
                    @validForm="validForm"
                    :errorMessages="formsErrors"
                    :resetPassword="true"
                  />
                </p>
              </template>
            </v-card-text>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserEditPasswordForm from "./UserEditPasswordForm.vue";
import { QUERY_userVerifyCodePasswordReset } from './graphql/queries';
import { MUTATION_userResetPassword } from './graphql/mutations';
import { mapState } from "vuex";

export default {
  name: "UserMagicLink",

  components: {
    UserEditPasswordForm
  },

  computed: {
    ...mapState(["formsErrors"]),
  },

  data: function () {
    return {
      verified: "desac",
    };
  },

  mounted: function () {
    this.init();
  },

  methods: {
    init() {
      this.$apollo
        .query({
          query: QUERY_userVerifyCodePasswordReset,
          variables: {
            code: this.$route.params.code
          },
          fetchPolicy: 'no-cache'
        })
        .then(() => {
          this.verified = 'OK';
        })
        .catch(() => {
          this.verified = 'expires';
          setTimeout(() => {
            this.$router.push({ path: "/" });
          }, 3000);
        });
    },

    validForm(input) {
      this.$apollo
        .mutate({
          mutation: MUTATION_userResetPassword,
          variables: {
            code: this.$route.params.code,
            password: input.password,
            passwordConfirmation: input.passwordConfirmation
          }
        })
        .then(() => {
          this.$store.commit("addAlert", {
            type: "success",
            text: "Le mot de passe a été modifié",
          });
          this.$root.$emit("skeleton--close");
           
          this.$router.push({ path: '/' });
        })
    }
  }
};
</script>