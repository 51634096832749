<template>
  <div>
    <v-list subheader min-width="350" class="ml-10">
      <v-subheader class="ml-n10 text-subtitle-1">
        <v-icon class="mr-2">{{ icon }}</v-icon
        >{{ title }}
      </v-subheader>
      <v-list-item v-for="(item, i) in input" :key="item + i">
        <v-list-item-avatar>
          <v-icon> mdi-chevron-triple-right</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-text="item"></v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon color="red" @click="deleteEntry(i)"> mdi-delete </v-icon>
        </v-list-item-icon>
      </v-list-item>
      <v-list-item>
        <v-text-field
          v-model="entry"
          :prepend-icon="icon"
          append-outer-icon="mdi-plus-circle"
          @click:append-outer="addEntry()"
        />
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  name: "ItemsTableForm",
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    title: {
      type: String,
      default() {
        return "Liste";
      },
    },
    icon: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    input: [],
    entry: "",
  }),
  mounted: function () {
    this.input = JSON.parse(JSON.stringify(this.value));
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.input = this.value;
      },
    },
    input: {
      deep: true,
      handler() {
        this.handleInput();
      },
    },
  },
  methods: {
    addEntry() {
      if (this.entry && this.entry != "") {
        this.input.push(this.entry);
      }
      this.entry = "";
    },
    handleInput() {
      this.$emit("input", this.input);
    },
    deleteEntry(index) {
      this.input.splice(index, 1);
    },
  },
};
</script>

<style>
</style>