export function financementToString(financement) {
  const tauxTVA = 0.2
  let toReturn = { ...financement }
  if (toReturn.isBeginning) {
    toReturn.isBeginningString = "à échoir";
  } else {
    toReturn.isBeginningString = "à terme échu";
  }
  toReturn.capitalAfinancerString = Intl.NumberFormat(
    "fr-FR"
  ).format(toReturn.capitalAfinancer);
  let cfString = "";
  if (toReturn.cf) {
    let cf = toReturn.cf;
    if (cf == 12 || cf == "mensuelOpti") {
      cfString = "Mensuelle";
    } else if (cf == 4 || cf == "trimestrielOpti") {
      cfString = "Trimestrielle";
    } else if (cf == 2 || cf == "semestrielOPti") {
      cfString = "Semestrielle";
    } else if (cf == 1 || cf == "annuelOPti") {
      cfString = "Annuelle";
    }
  }
  toReturn.cfString = cfString;
  let loyersString = [];
  let loyersStringSansTVA = [];
  let compteur = 0;
  let lastLoyer = null;
  let complementaire = false
  function write() {
    let loyerString = ""
    let loyerStringSansTVA = ""
    loyerString += compteur + " ";
    loyerString += compteur > 1 ? "loyers" : "loyer";
    loyerString +=
      " de " + 
      parseFloat(lastLoyer)
        .toLocaleString("fr-FR", {
          style: "currency",
          currency: "EUR",
        }) + " HT";
    loyerStringSansTVA = JSON.parse(JSON.stringify(loyerString))

    loyerString += ", soit " + compteur + " ";
    loyerString += compteur > 1 ? "loyers" : "loyer";
    loyerString += " de " + 
    parseFloat(lastLoyer * (1 + tauxTVA))
      .toLocaleString("fr-FR", {
        style: "currency",
        currency: "EUR",
      })  + " TTC";

    loyersString.push(loyerString)
    loyersStringSansTVA.push(loyerStringSansTVA)
    loyerString
  }
  if (toReturn.loyers && toReturn.loyers.length > 0) {
    for (let [i, loyer] of toReturn.loyers.entries()) {
      if (loyer.pmt) {
        let actualLoyer = loyer.pmt.toFixed(2);

        if (!lastLoyer) {
          lastLoyer = actualLoyer;
          compteur++;
        } else {
          if (lastLoyer == actualLoyer && complementaire == loyer.complementaire) {
            compteur++;
          } else {
            write(i);
            lastLoyer = actualLoyer;
            complementaire = loyer.complementaire
            compteur = 1;
          }
        }
        if (i + 1 == toReturn.loyers.length) {
          write(i);
        }
      }
    }
  }
  toReturn.loyersString = loyersString;
  toReturn.loyersStringSansTVA = loyersStringSansTVA;

  return toReturn
}