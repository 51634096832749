<template>
  <div>
    <v-col cols="auto" class="flex-grow-1 flex-shrink-0">
      <v-responsive class="overflow-y-hidden fill-height" height="60vh">
        <v-card flat class="d-flex flex-column fill-height">
          <v-card-text
            class="flex-grow-1 overflow-x-hidden overflow-y-auto"
            id="chat"
          >
            <template v-for="(commentaire, i) in commentaires">
              <CommentaireObject
                :key="i"
                :commentaire="commentaire"
                @replyTo="$emit('replyTo', $event)"
              />
            </template>
          </v-card-text>
        </v-card>
      </v-responsive>
    </v-col>
  </div>
</template>

<script>
import { QUERY_commentaires } from "./graphql/queries";

import CommentaireObject from "./CommentaireObject.vue";
export default {
  name: "CommentairesList",
  components: {
    CommentaireObject,
  },
  props: {
    customSearch: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data: () => ({}),
  apollo: {
    commentaires: {
      query: QUERY_commentaires,
      variables() {
        return {
          pagination: {
            filter: {
              customSearch: this.customSearch,
            },
          },
        };
      },
      fetchPolicy: "no-cache",
    },
  },
  created() {
    this.$root.$on("collaborationUpdateCommentaires", this.updateQuery);
  },
  watch: {
    commentaires: {
      deep: true,
      handler() {
        this.scrollDown();
      },
    },
  },
  methods: {
    scrollDown: function () {
      let container = this.$el.querySelector("#chat");
      let hauteurChat = container.scrollHeight + 500;
      container.scrollTop = hauteurChat;
    },
    updateQuery: function () {
      this.$apollo.queries.commentaires.refetch();
      // this.scrollDown();
    },
  },
};
</script>

