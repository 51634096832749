<template>
    <div class="btn-floating ml-4"  width="100%" >
        <v-speed-dial v-model="fab" :top="top" :bottom="bottom" :right="right" :left="left" :direction="direction" :hover="hover" :transition="transition" fixed>
            <template v-slot:activator>
                        <v-btn
                          v-model="fab"
                          color="blue darken-2"
                          dark
                          fab
                        >
                          <v-icon v-if="fab">
                            mdi-close
                          </v-icon>
                          <v-icon v-else>
                            mdi-account-circle
                          </v-icon>
                        </v-btn>
            </template>
            <v-btn
              fab
              dark
              small
              color="green"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>

            <v-btn
              fab
              dark
              small
              color="indigo"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>

            <v-btn
              fab
              dark
              small
              color="red"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>

        </v-speed-dial>
</div>
   

          

</template>

<script>
export default {
    name: "btnFloating",


    data: () => ({
        direction: "top",
        fab: true,
        fling: false,
        hover: false,
        tabs: null,
        top: false,
        right: true,
        bottom: true,
        left: false,
        transition: 'slide-y-reverse-transition'

    }),
    watch: {
        top(val) {
            this.bottom = !val
        },
        right(val) {
            this.left = !val
        },
        bottom(val) {
            this.top = !val
        },
        left(val) {
            this.right = !val
        },
    },

    computed: {
        activeFab() {
            switch (this.tabs) {
                case 'one':
                    return { 'class': 'purple', icon: 'account_circle' }
                case 'two':
                    return { 'class': 'red', icon: 'edit' }
                case 'three':
                    return { 'class': 'green', icon: 'keyboard_arrow_up' }
                default:
                    return {}
            }
        }
    }

}
</script>

<style>
.btn-floating {
    position: absolute;
    bottom: 70px;
    z-index: 5;
}
</style>