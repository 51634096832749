<template>
  <v-container class="fluid">
    <EquipementActions :object="equipement" size="xl" />
    <v-row class="align-center mx-auto">
      <v-col>
        <EquipementObject size="xl" :equipementProp="equipement" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { QUERY_equipement } from "./graphql/queries";
import EquipementObject from "./EquipementObject.vue";
import EquipementActions from "./EquipementActions.vue";
export default {
  name: "EquipementView",

  components: {
    EquipementObject,
    EquipementActions,
  },

  apollo: {
    equipement: {
      query: QUERY_equipement,
      variables() {
        let idQuery = this.showPopup
          ? this.id
          : this.$route.params.id
          ? this.$route.params.id
          : this.id;
        return {
          id: idQuery,
        };
      },
    },
  },
  props: {
    showPopup: {
      type: Boolean,
    },
    id: {
      type: String,
    },
  },
};
</script>