<template>
  <FormLayout
    icon="mdi-briefcase-account"
    @ajouter="ajouter"
    @modifier="modifier"
    @reinitialiser="init"
    typeItem="un client"
    :item="input"
    :disabled="input.etablissement ? false : true"
  >
    <v-row>
      <v-col>
        <EntrepriseSelector v-model="input.entreprise" />
      </v-col>
      <v-col>
        <EtablissementSelector
          :disabled="input.entreprise ? false : true"
          :entreprise="input.entreprise"
          :error-messages="formsErrors.clientEtablissement"
          label="Etablissement client"
          v-model="input.etablissement"
        />
      </v-col>
      <v-col>
        <UserSelector v-model="input.users" :multiple="true" />
      </v-col>
    </v-row>
  </FormLayout>
</template>

<script>
import { mapState } from "vuex";

import {
  MUTATION_clientCreate,
  MUTATION_clientUpdate,
} from "./graphql/mutations";
import { QUERY_client } from "./graphql/queries";

import EntrepriseSelector from "@/components/COMPONENTS-annuaire/EntrepriseSelector.vue";
import EtablissementSelector from "@/components/COMPONENTS-annuaire/EtablissementSelector";
import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";
import UserSelector from "@/components/COMPONENTS-authentification-habilitation/UserSelector.vue";

export default {
  name: "ClientForm",

  components: {
    EntrepriseSelector,
    EtablissementSelector,
    FormLayout,
    UserSelector,
  },

  props: {
    componentParent: undefined,
  },

  data: () => ({
    client: {},
    input: {},
  }),

  computed: {
    ...mapState(["formsErrors"]),
  },

  mounted: function () {
    this.init();
  },

  methods: {
    ajouter() {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_clientCreate,
          variables: {
            input: toSend,
          },
        })
        .then((__) => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "Le client a été ajouté",
          });

          if (this.componentParent) {
            this.$root.$emit(this.componentParent, __.data.clientCreate);
          }

          this.$root.$emit("skeleton--close");

          this.init();
        });
    },

    init() {
      if (
        this.$route.path.startsWith("/devis-facture/clients/edit/") &&
        this.$route.params.id
      ) {
        let id = this.$route.params.id;
        this.$apollo
          .query({
            query: QUERY_client,
            variables: {
              id: id,
            },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let client = data.data.client;
            this.input = JSON.parse(JSON.stringify(client));
            // this.updatePanelEtb();
          });
      } else {
        this.input = JSON.parse(JSON.stringify(this.client));
      }
    },

    inputConverter(arg) {
      const obj = JSON.parse(JSON.stringify(arg));
      let users = [];
      if (obj.users) {
        for (let objUser of obj.users) {
          users.push(objUser && objUser.id ? objUser.id : objUser);
        }
      }

      let input = {
        id: obj.id,
        entreprise:
          obj.entreprise && obj.entreprise.id
            ? obj.entreprise.id
            : obj.entreprise,
        etablissement:
          obj.etablissement && obj.etablissement.id
            ? obj.etablissement.id
            : obj.etablissement,
        users: users,
      };
      return input;
    },

    modifier() {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_clientUpdate,
          variables: {
            input: toSend,
          },
        })
        .then(() => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "Le client a été modifié",
          });
          this.$root.$emit("skeleton--close");
        });
    },
  },
};
</script>
