<template>
  <v-form>
    <v-row>
      <v-col>
        <v-select
          v-model="input.type"
          :items="[
            {
              text: 'Facturation classique',
              value: 'FACTURATION_CLASSIQUE',
            },
            {
              text: 'Facturation clients',
              value: 'FACTURATION_CLIENTS',
            },
          ]"
          label="Type de facturation"
          prepend-icon="mdi-timeline-help"
        />
      </v-col>
    </v-row>
    <template v-if="input.type == 'FACTURATION_CLASSIQUE'">
      <v-alert text dense color="info" icon="mdi-chat" border="left">
        En facturation classique, le locataire, porte l'intégralité de la
        facturation sur un de ses points de facturation.
      </v-alert>
    </template>

    <template v-if="input.type == 'FACTURATION_CLIENTS'">
      <v-alert text dense color="info" icon="mdi-chat" border="left">
        En facturation des clients (facturation multiple), le locataire est
        garant auprès du bailleur. La facturation est répartie sur les points de
        facturations des sites d'installation.
      </v-alert>
    </template>

    <v-row justify="center">
      <v-col>
        <v-row class="my-2">
          <v-col align="center" justify="center">
            <v-btn
              @click="calcRepartition()"
              :color="
                input.repartition && input.repartition[0] ? '' : 'primary'
              "
            >
              <v-icon class="mr-2">mdi-calculator</v-icon>
              {{
                input.repartition && input.repartition[0]
                  ? "Recalculer la répartition de facturation"
                  : "Calcul de la répartition de facturation"
              }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <template v-for="(ligne, index) in input.repartition">
      <div :key="'repartition' + index">
        <v-row class="pt-3">
          <v-col cols="4">
            <ClientSelector
              v-model="ligne.client"
              :addAction="null"
              :disabled="true"
            />
          </v-col>
          <v-col>
            <PointFacturationSelector
              v-model="ligne.pointFacturation"
              :client="ligne.client"
            />
          </v-col>
          <v-col>
            <MoyenPaiementSelector
              v-model="ligne.moyenPaiement"
              :pointFacturation="ligne.pointFacturation"
              :disabled="ligne.pointFacturation ? false : true"
            />
          </v-col>
          <v-col cols="1">
            <v-text-field
              v-model.number="ligne.tauxFacturation"
              type="number"
              suffix="%"
              label="Répartition"
              prepend-icon="mdi-percent"
              class="mt-n1"
            />
          </v-col>
          <v-col cols="3">
            <v-row>
              <v-col>
                <v-checkbox
                  label="Garantie Bailleur"
                  v-model="ligne.garantieBailleur"
                />
              </v-col>
              <v-col>
                <v-checkbox
                  label="Mandat de gestion"
                  v-model="ligne.prelevementPourCompte"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </div>
    </template>
  </v-form>
</template>
 
<script>
import _ from "lodash";

import ClientSelector from "@/components/COMPONENTS-devis-facture/ClientSelector.vue";
import MoyenPaiementSelector from "@/components/COMPONENTS-devis-facture/MoyenPaiementSelector.vue";
import PointFacturationSelector from "@/components/COMPONENTS-devis-facture/PointFacturationSelector.vue";

export default {
  name: "ContratLocationFacturationForm",

  components: {
    ClientSelector,
    MoyenPaiementSelector,
    PointFacturationSelector,
  },

  props: {
    contratLocation: { type: Object },
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data: () => ({
    input: {},
    headers: [{ text: "Client", value: "client", sortable: false }],
  }),

  mounted: function () {
    this.input = JSON.parse(JSON.stringify(this.value));
  },

  watch: {
    value: {
      deep: true,
      handler() {
        this.input = this.value;
      },
    },
    input: {
      deep: true,
      handler() {
        this.handleInput();
      },
    },
  },

  methods: {
    calcRepartition() {
      let contratLocation = JSON.parse(JSON.stringify(this.contratLocation));

      let repartition = [];
      if (this.input.type == "FACTURATION_CLASSIQUE") {
        let ligne = {
          client: contratLocation.locataire,
          pointFacturation: undefined,
          moyenPaiement: undefined,
          tauxFacturation: 100,
          garantieBailleur: true,
          prelevementPourCompte: false,
        };

        repartition.push(ligne);
      }
      if (this.input.type == "FACTURATION_CLIENTS") {
        let ligneLocataire = {
          client: contratLocation.locataire,
          pointFacturation: undefined,
          moyenPaiement: undefined,
          tauxFacturation: 0,
          garantieBailleur: true,
          prelevementPourCompte: false,
        };
        repartition.push(ligneLocataire);

        for (let ligneRepartition of contratLocation.financement.repartition) {
          let ligne = {
            client: ligneRepartition.client,
            pointFacturation: undefined,
            moyenPaiement: undefined,
            tauxFacturation: ligneRepartition.partCapital,
            garantieBailleur: false,
            prelevementPourCompte: true,
          };
          repartition.push(ligne);
        }
      }
      this.$set(this.input, "repartition", repartition);
    },

    handleInput() {
      this.$emit("input", this.input);
    },

    inputConverter(arg) {
      if (_.isEmpty(arg)) {
        return {};
      }
      const obj = JSON.parse(JSON.stringify(arg));

      let repartition = [];

      for (let objLigne of obj.repartition) {
        let inputLigne = {
          client:
            objLigne.client && objLigne.client.id
              ? objLigne.client.id
              : objLigne.client,

          pointFacturation:
            objLigne.pointFacturation && objLigne.pointFacturation.id
              ? objLigne.pointFacturation.id
              : objLigne.pointFacturation,

          moyenPaiement:
            objLigne.moyenPaiement && objLigne.moyenPaiement.id
              ? objLigne.moyenPaiement.id
              : objLigne.moyenPaiement,

          tauxFacturation: objLigne.tauxFacturation,

          garantieBailleur: objLigne.garantieBailleur,

          prelevementPourCompte: objLigne.prelevementPourCompte,
        };
        repartition.push(inputLigne);
      }

      let input = {
        type: obj.type,
        repartition: repartition,
      };

      return input;
    },
  },
};
</script>