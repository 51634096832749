<template>
  <div>
    <VuePdfEmbed v-if="url" :source="url" />
  </div>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed.js'

import {
  MUTATION_downloadURLCreate
} from "./graphql/mutations";

export default {
  name: "FichierPDFPlayer",

  components: {
    VuePdfEmbed
  },

  data: () => ({
    url: null
  }),

  props: {
    value: undefined,
  },

  methods: {
    init() {
      if(this.value.startsWith('http')) {
        this.url = this.value;
      }else {
        this.$apollo
          .mutate({
            mutation: MUTATION_downloadURLCreate,
            variables: {
              id: this.value,
            },
            fetchPolicy: 'no-cache'
          })
          .then(__ => {
            this.url = __.data.downloadURLCreate.url;
          })
      }
    }
  },
  
  mounted() {
    this.init();
  },

  watch: {
    value() {
      this.init();
    }
  }
};
</script>

