<template>
  <div>CompteComptableForm</div>
</template>

<script>
import { QUERY_planComptable } from "./graphql/queries";
export default {
  name: "CompteComptableForm",
  components: {},
  data: () => ({}),
  apollo: {
    planComptable: {
      query: QUERY_planComptable,
      fetchPolicy: "no-cache",
    },
  },
  methods: {},
};
</script>

