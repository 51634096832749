export default [
  // EMAILS
  {
    path: '/envoi-notifications/emails',
    name: 'Gestion des emails',
    component: () => import('./EmailsList.vue'),
    meta: {
      acl: "/envoi-notifications/emails"
    }
  },
  {
    path: '/envoi-notifications/emails/view/:id',
    name: 'Vue d\'un email',
    component: () => import('./EmailView.vue'),
    meta: {
      acl: "/envoi-notifications/emails/view"
    }
  },
  {
    path: '/envoi-notifications/emails/add',
    name: 'Ajouter un email',
    component: () => import('./EmailForm.vue'),
    meta: {
      acl: "/envoi-notifications/emails/add"
    }
  },
  {
    path: '/envoi-notifications/emails/edit/:id',
    name: 'Editer un email',
    component: () => import('./EmailForm.vue'),
    meta: {
      acl: "/envoi-notifications/emails/edit"
    }
  },

  {
    path: '/envoi-notifications/emails/list',
    name: 'Liste des emails',
    component: () => import('./EmailsList.vue'),
    meta: {
      acl: "/envoi-notifications/emails/list"
    }
  },
  {
    path: '/envoi-notifications/emails/deleted',
    name: 'Liste des emails supprimés',
    component: () => import('./EmailsList.vue'),
    meta: {
      acl: "/envoi-notifications/emails/deleted"
    }
  },
  //TEMPLATES
  {
    path: '/envoi-notifications/emailtemplates',
    name: 'Gestion des templates Test',
    component: () => import('./EmailTemplatesList.vue'),
    meta: {
      acl: "/envoi-notifications/emailtemplates"
    }
  },

  //TEMPLATES
  {
    path: '/envoi-notifications/templates',
    name: 'Gestion des templates',
    component: () => import('./TemplatesList.vue'),
    meta: {
      acl: "/envoi-notifications/templates"
    }
  },
  {
    path: '/envoi-notifications/templates/view/:id',
    name: 'Vue d\'un template',
    component: () => import('./TemplateView.vue'),
    meta: {
      acl: "/envoi-notifications/templates/view"
    }
  },
  {
    path: '/envoi-notifications/templates/add',
    name: 'Ajouter un template',
    component: () => import('./TemplateForm.vue'),
    meta: {
      acl: "/envoi-notifications/templates/add"
    }
  },
  {
    path: '/envoi-notifications/templates/edit/:id',
    name: 'Editer un template',
    component: () => import('./TemplateForm.vue'),
    meta: {
      acl: "/envoi-notifications/templates/edit"
    }
  },

  {
    path: '/envoi-notifications/templates/list',
    name: 'Liste des templates',
    component: () => import('./TemplatesList.vue'),
    meta: {
      acl: "/envoi-notifications/templates/list"
    }
  },
  {
    path: '/envoi-notifications/templates/deleted',
    name: 'Liste des templates supprimés',
    component: () => import('./TemplatesList.vue'),
    meta: {
      acl: "/envoi-notifications/templates/deleted"
    }
  },

  {
    path: '/envoi-notifications/parametres',
    name: 'Envoi Notifications',
    component: () => import('./EnvoiNotificationsOrganisationParametresForm.vue'),
    meta: {
      acl: "/envoi-notifications/parametres"
    }
  }
]