<template>
  <v-card class="mx-auto" width="256" tile>
    <v-navigation-drawer permanent>
      <v-system-bar></v-system-bar>
      <v-list>
        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="title">
              Liste des entrées plannings
            </v-list-item-title>
            <v-list-item-subtitle
              >selectionnez un planning</v-list-item-subtitle
            >
          </v-list-item-content>

          <v-list-item-action>
            <v-icon>mdi-menu-down</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list nav dense>
        <v-list-item-group v-model="selectedItem" color="primary">
          <v-list-item v-for="(item, i) in items" :key="i">
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>

            <v-list-item-content prepend-icon="mdi-calendar">
              <v-list-item-title v-text="item.label"></v-list-item-title>
              <v-list-item-title v-text="item.dateDebut"></v-list-item-title>
              <v-list-item-title v-text="item.dateFin"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import { QUERY_entreesPlanning } from "./graphql/queries";
export default {
  data: () => ({
    selectedItem: 0,
    items: [],
    dateDebut: "",
    dateFin: "",
    label: "",
    description: "",
    icon: "mdi-clipboard-text",
  }),
  apollo: {
    entreesPlanning: {
      query: QUERY_entreesPlanning,
    },
  },
  methods: {},

  watch: {
    entreesPlanning: function () {
      if (this.entreesPlanning != {}) {
        const planning = [];

        this.entreesPlanning.map((entree) => {
          planning.push({
            id: entree.id,
            label: entree.label,
            dateDebut: entree.dateDebut,
            description: entree.description,
            dateFin: entree.dateFin,
            icon: this.icon,
          });
        });

        this.items = planning;
      }
    },
  },
};
</script>
