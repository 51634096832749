<template>
  <div>
    <template v-if="!value">
      <v-text-field v-model="sirenToFind" label="Numéro de SIREN" :disabled="disabled" />
      <v-row>
        <v-col align="center" justify="center">
          <v-btn color="primary" @click="findEntrepriseFromSiren()" :disabled="disabled">
            Rechercher
          </v-btn>
        </v-col></v-row>
    </template>
    <template v-else-if="!disabled">
      <v-row class="mt-2">
        <v-col cols="12">
          <EntrepriseObject :entrepriseID="value" size="xl" />
        </v-col>
        <v-col cols="12" align="center" justify="center">
          <v-btn color="warning" @click="$emit('input', null)">
            Annuler
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import EntrepriseObject from "./EntrepriseObject.vue";
import { QUERY_entrepriseFromSiren } from "./graphql/queries";
export default {
  name: "EntrepriseSirenFindOrCreateSelector",
  components: {
    EntrepriseObject,
  },
  props: {
    value: null,
    getRepresentantsInfos: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    sirenToFind: "",
  }),
  methods: {
    findEntrepriseFromSiren() {
      this.$apollo
        .query({
          query: QUERY_entrepriseFromSiren,
          variables: { siren: this.sirenToFind },
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          this.$emit("input", data.data.entrepriseFromSiren.id);
          if (this.getRepresentantsInfos) {
            this.$emit("representantsInfos", data.data.entrepriseFromSiren.representants);
          }
        });
    },
  },
};
</script>