<template>
  <div>CompteComptableActions</div>
</template>

<script>
export default {
  name: "CompteComptableActions",
};
</script>

