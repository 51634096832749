<template>
  <v-banner v-if="isUsurping" two-line>
    <v-avatar slot="icon" color="primary accent-4" size="40">
      <v-icon icon="mdi-lock" color="white"> mdi-lock </v-icon>
    </v-avatar>
    Vous utilisez le mode miroir, celui-ci vous permet d'obtenir
    temporairement les habilitations d'un autre utilisateur.
    <br />
    Cette option a été développée à des fins d'assistance et de dépannage, cette
    action a été enregistrée et notifiée à l'utilisateur.
    <br />
    Temps restant : {{ timeLeft }}
    <template v-slot:actions>
      <v-btn text color="primary accent-4" @click="returnSession()">
        Retourner vers sa session
      </v-btn>
    </template>
  </v-banner>
</template>

<script>
import {
  isUsurping,
  refreshSession,
} from "@/components/COMPONENTS-skeleton/vue-apollo.js";
import format from "format-duration";

export default {
  name: "HabilitationUsurpationBanner",

  computed: {
    isUsurping() {
      return isUsurping();
    },

    timeLeft() {
      return format(this.timeLeftSecs * 1000);
    },
  },

  data: () => ({
    usurpationDate: null,
    timeLeftSecs: 0,
  }),

  methods: {
    init() {
      if (this.isUsurping) {
        this.usurpationDate = parseInt(localStorage.getItem("usurpation-date"));
        this.timeLeftSecs =
          900 - Math.trunc((Date.now() - this.usurpationDate) / 1000);
        this.timeLeftTimer();
      }
    },

    async returnSession() {
      await refreshSession(this.$apollo.provider.defaultClient);
    },

    timeLeftTimer() {
      if (this.timeLeftSecs > 0) {
        setTimeout(() => {
          this.timeLeftSecs--;
          this.timeLeftTimer();
        }, 1000);
      } else {
        this.returnSession();
      }
    },
  },

  mounted() {
    this.init();
  },
};
</script>