import gql from 'graphql-tag';
import {
  ACCORD_FIELDS,
  ACCORD_ETAPE_FIELDS
} from "./fragments.js";
import { USER_FIELDS } from '@/components/COMPONENTS-authentification-habilitation/graphql/fragments';
import { PERSONNE_FIELDS } from '@/components/COMPONENTS-annuaire/graphql/fragments.js';

export const QUERY_accord = gql`
  query($id:ID!){
    accord(id:$id){
      ...AccordFields
      createdBy{
          ...UserFields
      }
      etapes{
        ...AccordEtapeFields     
      }
    }
  }${ACCORD_FIELDS},${ACCORD_ETAPE_FIELDS},${USER_FIELDS}
`;

export const QUERY_accords = gql`
  query($pagination:PaginationObject){
    accords(pagination:$pagination){
      ...AccordFields
    }
    accordsCount(pagination: $pagination)
  }${ACCORD_FIELDS}
`;


export const QUERY_accordsCount = gql`
  query($pagination:PaginationObject){
    accordsCount(pagination:$pagination)
  }
`;


export const QUERY_accordEtape = gql`
query($id:ID!){
  accordEtape(id:$id){
    ...AccordEtapeFields
    destinataire{
      nom
      email
      personneAssociee{  
        ...PersonneFields
      }
    }
  }
}${ACCORD_ETAPE_FIELDS},${PERSONNE_FIELDS}
`;

export const QUERY_accordEtapes = gql`
  query($pagination:PaginationObject){
    accordEtapes(pagination:$pagination){
      ...AccordEtapeFields
      destinataire{
        nom
        email
        personneAssociee{  
          ...PersonneFields
        }
      }
    }
    accordsCount(pagination: $pagination)
  }${ACCORD_ETAPE_FIELDS},${PERSONNE_FIELDS}
`;

export const QUERY_accordEtapesCount = gql`
  query($pagination:PaginationObject){
    accordEtapesCount(pagination:$pagination)
  }
`;
