<template>
  <v-form
    ref="form"
    @submit.prevent="validateForm"
    v-model="valid"
    lazy-validation
  >
    <v-row>
      <v-col>
        <v-select
          v-model="entreePlanning.planningAssocie"
          :items="items"
          prepend-icon="mdi-calendar-blank-multiple"
          item-text="label"
          item-value="id"
          :rules="mustBePutRule"
        />
        <DateInput
          v-model="entreePlanning.dateDebut"
          label="Date de début"
          :min="min"
          :max="max"
          :allowed_dates="allowed_dates"
          @input="testRules(0)"
          :rules="[...mustBePutRule, ...coherenceDateBeforeRule]"
          required
        />
        <DateInput
          v-model="entreePlanning.dateFin"
          label="Date de fin"
          :min="min"
          :max="max"
          :allowed_dates="allowed_dates"
          @input="testRules(1)"
          :rules="[...mustBePutRule, ...coherenceDateAfterRule]"
          required
        />
        <v-text-field
          v-model="entreePlanning.label"
          :counter="30"
          :rules="mustBePutRule"
          label="Label"
        />
        <v-text-field
          v-model="entreePlanning.description"
          label="Description"
        />

        <v-sheet>
          <v-toolbar flat color="white">
            <v-btn :color="colorEntree" dark @click.stop="dialog = true">
              Changer la couleur
            </v-btn>
          </v-toolbar>
        </v-sheet>
        <v-dialog v-model="dialog" max-width="500">
          <v-card>
            <v-container>
              <v-color-picker v-model="colorEntree"></v-color-picker>
            </v-container>
          </v-card>
        </v-dialog>
        <v-row class="justify-center py-3">
          <v-btn class="mx-2" type="submit" :disabled="!valid"> ajouter</v-btn>
          <v-btn class="mx-2" @click="reset">Effacer </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import DateInput from "@/components/COMPONENTS-skeleton/Inputs/DateInput";
import { QUERY_entreesPlanning } from "./graphql/queries";
import {
  MUTATION_entreePlanningCreate,
  MUTATION_entreePlanningUpdate,
} from "./graphql/mutations";

export default {
  name: "EntreePlanningForm_OLD",
  components: {
    DateInput,
  },
  props: {
    plannings: {
      type: Array,
      default() {
        return [];
      },
    },
    initialEntreePlanning: {
      type: Object,
      default() {
        return {
          id: "",
          planningAssocie: "",
          dateDebut: "",
          dateFin: "",
          label: "",
          description: "",
          color: "",
        };
      },
    },
    min: {
      type: String,
      default: "2000-01-01",
    },
    max: {
      type: String,
      default: "2099-12-31",
    },
    allowed_dates: {
      type: Function,
      default: () => true,
    },
  },

  data: function () {
    return {
      valid: true,
      items: [],
      input: {},
      mustBePutRule: [(v) => !!v || "Ce champ ne peut pas être vide"],
      coherenceDateBeforeRule: [true],
      coherenceDateAfterRule: [true],
      type: "hex",
      hex: "#1976D2",
      hexa: "##1976D2FF",
      dialog: false,
      justCreated: null,
      entreePlanning: {},
    };
  },

  computed: {
    colorEntree: {
      get() {
        return this[this.type];
      },
      set(v) {
        this[this.type] = v;
      },
    },
  },

  mounted: function () {
    this.items = this.plannings;
    this.entreePlanning.planningAssocie = this.plannings[0].id;
    this.entreePlanning = this.inputConverter(this.initialEntreePlanning);
  },
  watch: {
    plannings: function (val) {
      this.items = val;
      this.entreePlanning.planningAssocie = this.plannings[0].id;
    },
    initialEntreePlanning: function () {
      //Clone le props dans data en le convertissant en input valide
      this.entreePlanning = this.inputConverter(this.initialEntreePlanning);
    },
  },
  methods: {
    inputConverter(arg) {
      const obj = JSON.parse(JSON.stringify(arg));
      /*for (objetUsers of obj.authorizedUsers) {
        let inputUser = {
          id: objUsers.id,
          user: objUsers.user,
          right: objUser.right,
        };
        numerosTelephone.push(inputNum);
      }*/
      let input = {
        id: obj.id,
        dateDebut: obj.dateDebut,
        dateFin: obj.dateFin,
        description: obj.description,
        label: obj.label,
        color: obj.color,
      };
      return input;
    },

    testRules: function (index) {
      const test = this.entreePlanning.dateDebut <= this.entreePlanning.dateFin;

      if (test) {
        this.coherenceDateBeforeRule = [true];
        this.coherenceDateAfterRule = [true];
        return;
      }

      if (index > 0)
        this.coherenceDateAfterRule = [
          "La date de fin ne peut pas être avant la date de début",
        ];
      else
        this.coherenceDateBeforeRule = [
          "La date de début ne peut pas être après la date de fin",
        ];
    },

    /*SwitchInputForm: function () {        
      if (this.entreePlanning.id) {
        this.validateForm();
      } else {
        this.updateEvent();
      }
    },*/

    validateForm: function () {
      this.$refs.form.validate(); // méthode qui vérifie les champs obligatoire pour la validation formulaire.
      // fonction qui permet l'envoi des données formulaire en base de donnée rattaché à l'input "ajouter".

      if (this.valid) {
        // 
        this.$apollo
          .mutate({
            mutation: MUTATION_entreePlanningCreate,
            variables: {
              input: this.entreePlanning,
            },
            //refetchQueries: [{ query: QUERY_plannings }],
            update: (store, { data: { entreePlanningCreate } }) => {
              this.justCreated = entreePlanningCreate;
              this.$store.commit("addAlert", {
                type: "success",
                text: "L'événement a bien été ajoutée'",
              });
              this.$root.$emit("close-dialog");
              this.$refs.form.reset();
            },

            // TODO : marche pas
            /*refetchQueries: [
              {
                query: QUERY_entreesPlanning,
                variables: {
                  input: {
                    idPlannings: this.planningAssocie,
                  },
                },
              },
            ],*/
          })

          .then((data) => {
            this.$emit("entreeCreated", data.data.entreePlanningCreate);
             
          });
      }
    },

    updateEvent: function () {
      // fonction qui permet de modifier et de mettre à jour l'entrée du planning
       
      if (this.valid) {
        this.$apollo
          .mutate({
            mutation: MUTATION_entreePlanningUpdate,
            variables: {
              input: this.entreePlanning,
            },

            refetchQueries: [
              {
                query: QUERY_entreesPlanning,
              },
            ],
            update: (store, { data: { entreePlanningUpdate } }) => {
              this.justCreated = entreePlanningUpdate;
              this.$store.commit("addAlert", {
                type: "success",
                text: "L'événement  a bien été modifiée'",
              });
              this.$root.$emit("close-dialog");
              this.$refs.form.reset();
            },
          })
          .then((data) => {
            console.log(data);
          });
      }
    },

    reset: function () {
      // méthode permettant de réinitialiser les champs.rattaché à l'input "effacer".
      this.$refs.form.reset();
    },
  },
};
</script>


