<template>
  <div>
    <v-expansion-panels class="mb-6">
      <v-expansion-panel v-for="item in items" :key="item.type">
        <v-expansion-panel-header expand-icon="mdi-menu-down">
          <v-row no-gutters>
            <v-col cols="11">
              <p>
                Liste des templates pour les mails concernant les objets de type
                <b>{{ item.type }}</b>
              </p>
            </v-col>
            <EmailTemplatesCounter
              :customSearch="[{ 'meta.typeParent': item.type }]"
            />
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-expansion-panels class="mb-6">
            <v-expansion-panel
              v-for="categorie in item.categories"
              :key="categorie.value"
            >
              <v-expansion-panel-header expand-icon="mdi-menu-down">
                <v-row no-gutters>
                  <v-col cols="11">
                    <p>
                      Liste des templates pour les mails concernant
                      <b>{{ item.type }} - {{ categorie.text }}</b>
                    </p>
                  </v-col>
                  <EmailTemplatesCounter
                    :customSearch="[
                      { 'meta.typeParent': item.type },
                      { 'meta.categorie': categorie.value },
                    ]"
                  />
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col>
                    <EmailTemplatesObject
                      :meta="{
                        typeParent: item.type,
                        categorie: categorie.value,
                      }"
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import EmailTemplatesCounter from "./EmailTemplatesCounter.vue";
import EmailTemplatesObject from "./EmailTemplatesObject.vue";

export default {
  name: "EmailTemplatesList",
  components: {
    EmailTemplatesCounter,
    EmailTemplatesObject,
  },
  props: {
    items: [],
  },
  data: () => ({}),
};
</script>