<template>
  <ListLayout
    ref="list"
    :headers="headers"
    @fetch="init"
    @showMultipleActions="showMultipleActions = $event"
    title="Liste des entreprises"
  >
    <template v-slot:multipleActions>
      <EntrepriseActions
        :multiple="true"
        :object="$refs.list ? $refs.list.itemsSelected : []"
        size="xl"
        v-show="showMultipleActions"
        @fetch="init"
        @loading="$refs.list.loading = true"
      />
    </template>
    <template v-slot:customFilters> </template>
    <template v-slot:[`item.object`]="{ item }">
      <EntrepriseObject :entrepriseProp="item" size="xs" />
    </template>

    <template v-slot:[`item.nbEtablissements`]="{ item }">
      <v-chip :color="item.nbEtablissements > 0 ? 'success' : ''">
        <v-avatar left>
          <v-icon>mdi-home</v-icon>
        </v-avatar>
        <strong>
          {{ item.nbEtablissements > 0 ? item.nbEtablissements : "0" }}
        </strong>
      </v-chip>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <EntrepriseActions
        :object="item"
        size="xs"
        @fetch="init"
        @loading="$refs.list.loading = true"
      />
    </template>
  </ListLayout>
</template>

<script>
import { QUERY_entreprises } from "./graphql/queries";

import EntrepriseActions from "./EntrepriseActions.vue";
import EntrepriseObject from "./EntrepriseObject.vue";
import ListLayout from "@/components/COMPONENTS-skeleton/Layouts/ListLayout.vue";

export default {
  name: "EntreprisesList",

  components: {
    EntrepriseObject,
    EntrepriseActions,
    ListLayout,
  },

  data: () => ({
    // Recherche tapée par l'utilisateur dans le champ "Recherche"
    search: "",
    showMultipleActions: false,
    // Les colonnes ainsi que leur nom, id et options
    headers: [
      { text: "", value: "object", sortable: false },
      { text: "Raison Sociale", value: "raisonSociale" },
      { text: "SIREN", value: "siren" },
      { text: "Numéro de TVA", value: "numeroTvaIntra" },
      { text: "Etablissements", value: "nbEtablissements" },
      { text: "Fichiers", value: "fichiers" },
      { text: "Commentaires", value: "commentaires" },
      { text: "Actions", value: "actions", sortable: false },
    ],

    customSearch: [],
    entreprisesAreActive: null,
  }),

  methods: {
    fetchEntreprises() {
      this.$refs.list.loading = true;
      this.$apollo
        .query({
          query: QUERY_entreprises,
          variables: {
            pagination: this.$refs.list.pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          this.$refs.list.items = data.entreprises;
          this.$refs.list.itemsCount = data.entreprisesCount;
          this.$refs.list.loading = false;
        });
    },
    init() {
      this.fetchEntreprises();
    },
  },
};
</script>