<template>
  <div>
    <template v-if="devisFournisseur">
      <template v-if="size == 'xl'">
        <ObjectLayout :action="action" size="xl" :item="devisFournisseur">
          <template v-slot:icon>mdi-file-cad</template>
          <template v-slot:titre>
            Devis n°{{ devisFournisseur.numeroDevis }}
          </template>
          <template v-slot:body>
            <v-row>
              <v-col>
                <ObjectLayout>
                  <template v-slot:titre>Date de création</template>
                  <template v-slot:content>
                    {{ devisFournisseur.dateCreation | shortDateFR }}
                  </template>
                </ObjectLayout>
              </v-col>
              <v-col>
                <ObjectLayout>
                  <template v-slot:titre>Numéro du devis</template>
                  <template v-slot:content>{{
                    devisFournisseur.numeroDevis
                  }}</template>
                </ObjectLayout>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ObjectLayout v-if="devisFournisseur.client">
                  <template v-slot:titre>Client</template>
                  <template v-slot:content>
                    <ClientObject
                      :clientProp="devisFournisseur.client"
                      size="xs"
                    />
                  </template>
                </ObjectLayout>
              </v-col>
              <v-col>
                <ObjectLayout v-if="devisFournisseur.fournisseur">
                  <template v-slot:titre>Fournisseur</template>
                  <template v-slot:content>
                    <FournisseurObject
                      :fournisseurProp="devisFournisseur.fournisseur"
                      size="xs"
                    />
                  </template>
                </ObjectLayout>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <DevisFournisseurLignesList
                  :actions="false"
                  :value="devisFournisseur.lignes"
                />
              </v-col>
            </v-row>
          </template>
        </ObjectLayout>
      </template>
      <template v-if="size == 'md'">
        <ObjectLayout :action="action" size="md">
          <template v-slot:icon>mdi-file-cad</template>
          <template v-slot:titre>
            {{ devisFournisseur.numeroDevis }} -
            {{ devisFournisseur.montantHT | monetaire }}
          </template>
          <template v-slot:content>
            <ClientObject
              :clientID="devisFournisseur.client.id"
              size="xs"
              action="none"
            />
          </template>
          <template v-slot:extracontent> </template>
        </ObjectLayout>
      </template>
      <template v-if="size == 'xs'">
        <ObjectLayout
          size="xs"
          :onClickPush="`/devis-facture/devis-fournisseurs/view/${devisFournisseur.id}`"
          :action="action"
        >
          <template v-slot:icon>mdi-file-cad</template>
          <template v-slot:content>
            {{ devisFournisseur.numeroDevis }}
          </template>
          <template v-slot:extracontent>
            {{ devisFournisseur.montantHT | monetaire }}
          </template>
        </ObjectLayout>
      </template>
    </template>
  </div>
</template>

<script>
import { QUERY_devisFournisseur } from "./graphql/queries";

import ClientObject from "./ClientObject.vue";
import DevisFournisseurLignesList from "./DevisFournisseurLignesList";
import FournisseurObject from "./FournisseurObject.vue";
import ObjectLayout from "@/components/COMPONENTS-skeleton/Layouts/ObjectLayout.vue";

export default {
  name: "DevisFournisseurObject",

  components: {
    ClientObject,
    DevisFournisseurLignesList,
    FournisseurObject,
    ObjectLayout,
  },

  props: {
    action: { type: String, default: "router" },
    devisFournisseurID: {
      type: String,
    },
    devisFournisseurProp: {
      type: Object,
      default() {
        return {};
      },
    },
    size: { type: String, default: "md" },
  },

  data: () => ({
    devisFournisseur: undefined,
  }),

  watch: {
    devisFournisseurProp: function () {
      this.init();
    },

    devisFournisseurID: function () {
      this.init();
    },
  },

  mounted: function () {
    this.init();
  },

  methods: {
    init() {
      if (this.devisFournisseurID) {
        let id = this.devisFournisseurID;
        this.$apollo
          .query({
            query: QUERY_devisFournisseur,
            variables: { id: id },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let devisFournisseur = data.data.devisFournisseur;
            this.devisFournisseur = JSON.parse(
              JSON.stringify(devisFournisseur)
            );
          });
      } else {
        this.devisFournisseur = JSON.parse(
          JSON.stringify(this.devisFournisseurProp)
        );
      }
    },
  },
};
</script>