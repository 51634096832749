<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="512"
      @keydown.esc="dialog = false"
      @keydown.enter="
        dialog = false;
        valid();
      "
    >
      <v-card>
        <v-toolbar :color="type" dark>
          <v-icon>
            {{ type == "info" ? "mdi-information" : null }}
            {{ type == "warning" ? "mdi-alert-octagon" : null }}
            {{ type == "error" ? "mdi-cancel" : null }}
            {{ type == "success" ? "mdi-check" : null }}
          </v-icon>
          <v-card-title class="headline">
            {{ titre }}
          </v-card-title>
        </v-toolbar>
        <v-card-text class="pt-5">{{ texte }}</v-card-text>
        <v-card-actions>
          <v-checkbox
            v-if="this.extraValidation"
            v-model="checkbox"
            label="Je confirme avoir pris connaissance de la situation de mon compte en exécutant cette action"
          />
          <v-spacer></v-spacer>
          <v-btn :disabled="this.extraValidation ? !this.checkbox : false" color="primary" text @click="valid"> OUI </v-btn>
          <v-btn text @click="dialog = false"> NON </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "PopupValidation",
  data: () => ({
    dialog: false,
    titre: "Attention ",
    texte: "Êtes-vous sûr de vouloir effectuer cette action ?",
    toReturn: { function: "", arg: null },
    type: "info",
    extraValidation: false,
    checkbox: false
  }),
  created() {
    this.$root.$on("popupValidation", this.open);
  },
  methods: {
    open(info) {
      this.dialog = true;

      info.titre ? (this.titre = info.titre) : null;
      info.texte ? (this.texte = info.texte) : null;
      info.toReturn ? (this.toReturn = info.toReturn) : null;
      info.type ? (this.type = info.type) : null;
      info.extraValidation ? this.extraValidation = info.extraValidation : null;
    },
    valid() {
      this.$root.$emit(this.toReturn.function, this.toReturn.arg);
      this.dialog = false;
    },
  },
};
</script>

<style>
</style>