<template>
  <div>
    <ListLayout
      ref="list"
      :headers="headers"
      @fetch="init"
      @showMultipleActions="showMultipleActions = $event"
    >
      <template v-slot:multipleActions>
        <BailleurActions
          :multiple="true"
          :object="$refs.list ? $refs.list.itemsSelected : []"
          size="xl"
          v-show="showMultipleActions"
          @fetch="init"
          @loading="$refs.list.loading = true"
        />
      </template>
      <template v-slot:[`item.entrepriseAssociee`]="{ item }">
        <BailleurObject :bailleurProp="item" size="xs" />
      </template>
      <template v-slot:[`item.demandesAccordCount`]="{ item }">
        <DemandeAccordCounter :customSearch="[{ bailleur: item.id }]" />
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <BailleurActions
          :object="item"
          size="xs"
          @fetch="init"
          @loading="$refs.list.loading = true"
        />
      </template>
    </ListLayout>
  </div>
</template>

<script>
import BailleurActions from "./BailleurActions.vue";
import BailleurObject from "./BailleurObject.vue";
import DemandeAccordCounter from "./DemandeAccordsCounter.vue";
import ListLayout from "@/components/COMPONENTS-skeleton/Layouts/ListLayout.vue";
import { QUERY_bailleurs } from "./graphql/queries";
export default {
  name: "BailleursList",
  components: {
    BailleurActions,
    BailleurObject,
    DemandeAccordCounter,
    ListLayout,
  },
  data: () => ({
    headers: [
      { text: "Bailleur", value: "entrepriseAssociee", sortable: false },
      {
        text: "Demandes d'accord",
        value: "demandesAccordCount",
        sortable: false,
      },
      { text: "Montant des cessions", sortable: false },
      { text: "Fichiers", value: "fichiers" },
      { text: "Commentaires", value: "commentaires" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    showMultipleActions: false,
  }),
  methods: {
    fetchBailleurs() {
      this.$refs.list.loading = true;
      this.$apollo
        .query({
          query: QUERY_bailleurs,
          variables: {
            pagination: this.$refs.list.pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          this.$refs.list.items = data.bailleurs;
          this.$refs.list.itemsCount = data.bailleursCount;
          this.$refs.list.loading = false;
        });
    },
    init() {
      this.fetchBailleurs();
    },
  },
};
</script>