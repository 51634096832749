<template>
  <div>
    <template v-if="affaire">
      <template v-if="size == 'xl'">
        <ObjectLayout size="xl">
          <template v-slot:icon>mdi-chart-timeline-variant-shimmer</template>
          <template v-slot:titre>
            Simulation des gains liés à l'affaire : {{ affaire.numeroAffaire }}
          </template>
          <template v-slot:body>
            <template v-if="infosOk">
              <v-row justify="center">
                <v-col>
                  <div class="text-h2 text-center my-5">{{ sommeGains }} €</div>
                  <div>
                    <AffaireSimulationChart
                      :chartData="dataCollection"
                      style="width: 80%; margin: auto"
                      v-if="dataCollection"
                      class="mb-5"
                    />
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <ObjectLayout>
                    <template v-slot:icon>mdi-calendar</template>
                    <template v-slot:titre>Date du premier loyer</template>
                    <template v-slot:content>
                      {{ datePremierLoyer | shortDateFR }}
                    </template>
                  </ObjectLayout>
                </v-col>
                <v-col>
                  <ObjectLayout>
                    <template v-slot:icon>mdi-calendar</template>
                    <template v-slot:titre>Date de fin de contrat</template>
                    <template v-slot:content>
                      {{ dateFinContrat | shortDateFR }}
                    </template>
                  </ObjectLayout>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <ObjectLayout>
                    <template v-slot:icon>mdi-calendar</template>
                    <template v-slot:titre> Date de dernière échéance</template>
                    <template v-slot:content>
                      {{ dateDerniereEcheance | shortDateFR }}
                    </template>
                  </ObjectLayout>
                </v-col>
                <v-col>
                  <ObjectLayout>
                    <template v-slot:icon>mdi-cash</template>
                    <template v-slot:titre>
                      Montant des loyers intercalaires
                    </template>
                    <template v-slot:content>
                      {{ montantLoyersIntercallaires | monetaire }}
                    </template>
                  </ObjectLayout>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <ObjectLayout>
                    <template v-slot:icon>mdi-percent</template>
                    <template v-slot:titre> Meilleur taux d'accord </template>
                    <template v-slot:content>
                      {{ (bestTauxAccord / 100) | pourcent }}
                    </template>
                  </ObjectLayout>
                </v-col>
                <v-col>
                  <ObjectLayout>
                    <template v-slot:icon>mdi-cash</template>
                    <template v-slot:titre> Valeur du spread </template>
                    <template v-slot:content>
                      {{ spread | monetaire }}
                    </template>
                  </ObjectLayout>
                </v-col>
              </v-row>
            </template>
            <template v-else>
              <v-row>
                <v-col cols="2">
                  <v-icon x-large color="primary">mdi-space-invaders</v-icon>
                </v-col>
                <v-col>
                  <p class="font-weight-medium">
                    "Désolé, je ne peux pas établir la simulation car je n'ai
                    pas suffisament d'informations concernant l'affaire..."
                  </p>
                </v-col>
              </v-row>
            </template>
          </template>
        </ObjectLayout>
      </template>
      <template v-if="size == 'xs'">
        <ObjectLayout size="xs" v-if="infosOk">
          <template v-slot:icon>mdi-space-invaders</template>
          <template v-slot:content> {{ sommeGains }} € </template>
        </ObjectLayout>
      </template>
      <template v-if="size == 'text-datePremierLoyer'">
        {{ datePremierLoyer | shortDateFR }}
      </template>
    </template>
  </div>
</template>

<script>
import AffaireSimulationChart from "./AffaireSimulationChart.vue";
// import MoneyLayout from "@/components/COMPONENTS-skeleton/Layouts/MoneyLayout.vue";
import ObjectLayout from "@/components/COMPONENTS-skeleton/Layouts/ObjectLayout.vue";
import { financementJS } from "@aidalinfo/calculs-financiers";
export default {
  name: "AffaireSimulation",
  components: {
    AffaireSimulationChart,
    ObjectLayout,
  },
  props: {
    affaire: {
      type: Object,
    },
    size: { type: String, default: "md" },
  },
  data: () => ({
    echeances: [],
    dataCollection: null,
    dateDebutContrat: undefined,
    datePremierLoyer: undefined,
    dateDerniereEcheance: undefined,
    dateFinContrat: undefined,
    montantLoyersIntercallaires: undefined,
    spread: undefined,
  }),
  computed: {
    infosOk() {
      if (this.affaire.financement) {
        if (
          this.affaire.datePrevInstallation &&
          this.affaire.financement.rate &&
          this.affaire.financement.nper &&
          this.affaire.financement.loyers[0] &&
          this.affaire.financement.capitalAfinancer &&
          this.affaire.financement.isBeginning &&
          this.affaire.financement.valeurFinFinancement &&
          this.affaire.financement.cf &&
          this.affaire.demandeAccords[0]
        ) {
          let accord = false;
          for (let demandeAccord of this.affaire.demandeAccords) {
            if (demandeAccord.etat == "valid") {
              accord = true;
            }
            if (accord) {
              return true;
            }
          }
        }
      }

      return false;
    },
    bestTauxAccord() {
      let bestTaux = undefined;
      if (this.affaire.demandeAccords[0]) {
        for (let demandeAccord of this.affaire.demandeAccords) {
          if (demandeAccord.etat == "valid") {
            if (!bestTaux || bestTaux > demandeAccord.taux) {
              bestTaux = demandeAccord.taux;
            }
          }
        }
      }
      return bestTaux;
    },
    sommeGains() {
      let somme = 0;
      somme =
        this.spread +
        this.montantLoyersIntercallaires +
        this.affaire.fraisDossier;
      return somme.toFixed(2);
    },
  },
  mounted: function () {
    this.init();
  },
  watch: {
    affaire: {
      deep: true,
      handler() {
        this.init();
      },
    },
  },
  methods: {
    init() {
      if (this.infosOk) {
        this.calcGlobal();
        this.fillData();
      }
    },
    calcGlobal() {
      let financement = this.affaire.financement;
      financement.datePrevInstallation = this.affaire.datePrevInstallation;
      let objFinancement = financementJS(financement);
      let objCession = financementJS(financement);

      objCession.actualisationChaineLoyer(this.bestTauxAccord);
      this.spread =
        objCession.capitalAfinancer - objFinancement.capitalAfinancer;
      objFinancement.calcInitialLoyers();

      this.datePremierLoyer =
        objFinancement.datePremierLoyer.format("YYYY-MM-DD");
      this.montantLoyersIntercallaires =
        objFinancement.montantLoyersIntercallaires;

      this.dateFinContrat =
        objFinancement.loyers[objFinancement.loyers.length - 1].dateFin;
      this.dateDerniereEcheance = this.affaire.financement.isBeginning
        ? objFinancement.loyers[objFinancement.loyers.length - 1].dateDebut
        : objFinancement.loyers[objFinancement.loyers.length - 1].dateFin;
    },
    fillData() {
      this.dataCollection = {
        labels: ["Spread", "Loyers intercallaires", "Frais de dossier"],
        datasets: [
          {
            label: "Spread",
            backgroundColor: ["#03A9F4", "#FFC107", "#FF5722"],
            data: [
              this.spread.toFixed(2),
              this.montantLoyersIntercallaires.toFixed(2),
              this.affaire.fraisDossier.toFixed(2),
            ],
          },
        ],
      };
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
    },
  },
};
</script>