<template>
  <v-dialog
    v-model="isOpen"
    width="auto"
    max-width="95vw"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="dialog-bottom-transition"
  >
    <v-card min-height="90vh">
      <v-toolbar dense>
        <v-btn icon @click="$emit('update:openDialog', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text class="text--primary pa-0">
        <v-container>
          <Alert v-if="$vuetify.breakpoint.xsOnly" />
          <v-row>
            <v-col
              v-if="this.$slots.leftPane"
              cols="3"
              class="d-none d-sm-flex secondary"
            >
              <v-sheet class="d-flex flex-column pt-6 secondary">
                <div
                  class="text-body-1 align-self-center flex-grow-1 text-center"
                >
                  <slot name="leftPane"></slot>
                </div>
              </v-sheet>
            </v-col>
            <v-col v-if="openDialog">
              <slot></slot>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Alert from "./Alert.vue";
// Pour fermer la fenêtre de dialog depuis un enfant :
// this.$root.$emit("close-dialog");
export default {
  name: "SkeletonDialog",
  components: {
    Alert,
  },
  props: {
    title: { type: String, default: "" },
    openDialog: { Boolean },
  },
  data: () => ({}),
  computed: {
    isOpen: {
      get() {
        return this.openDialog;
      },
      set(value) {
        this.$emit("update:openDialog", value);
      },
    },
  },
  created() {
    this.$root.$on("skeleton--close", this.closeDialog);
  },

  methods: {
    closeDialog: function () {
      this.isOpen = false;
    },
  },
};
</script>

<style>
</style>