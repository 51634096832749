<template>
  <div>PlanningForm</div>
</template>

<script>
export default {
  name: "PlanningForm",
};
</script>

