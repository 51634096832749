<template>
  <ListLayout
    ref="list"
    :headers="headers"
    title="Historique des liens de services"
    :noFilter="noFilter"
    @fetch="init"
    @showMultipleActions="showMultipleActions = $event"
  >
    <!-- <template v-slot:multipleActions>
      <LienServiceActions
        :multiple="true"
        :withView="false"
        :object="$refs.list ? $refs.list.itemsSelected : []"
        size="xl"
        v-show="showMultipleActions"
        @fetch="init"
        @loading="$refs.list.loading = true"
      />
    </template> -->

    <template v-slot:[`item.historique.date`]="{ item }">
      {{ item.historique.date | shortDateAndHourFR }}
    </template>

    <template v-slot:[`item.historique.user`]="{ item }">
      {{ item.historique.user }}
    </template>

    <!--<template v-slot:[`item.actions`]="{ item }">
      <LienServiceActions
        :object="item"
        size="xs"
        @fetch="init"
        @loading="$refs.list.loading = true"
      /> 
    </template>-->
  </ListLayout>
</template>

<script>
import ListLayout from "@/components/COMPONENTS-skeleton/Layouts/ListLayout.vue";
/*import LienServiceActions from "./LienServiceActions.vue";*/
import { QUERY_lienServices } from "./graphql/queries";
export default {
  name: "LienServiceHistorique",

  components: {
    /* LienServiceActions,*/
    ListLayout,
  },

  props: {
    noFilter: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },

  data: () => ({
    customFilters: {
      lienServiceID: null,
      asset: null,
      fournisseur: null,
    },

    headers: [
      { text: "Numéro de lien", value: "numeroLienService" },
      { text: "Utilisateur", value: "historique.user" },
      { text: "Date", value: "historique.date" },
      /* { text: "Actions", value: "actions", sortable: false },*/
    ],
    search: null,
    showMultipleActions: false,
    date: {},
  }),

  methods: {
    fetchItems() {
      this.$refs.list.items = [];
      this.$refs.list.loading = true;
      let pagination = JSON.parse(JSON.stringify(this.$refs.list.pagination));

      if (this.customFilters.asset) {
        pagination.filter.customSearch.push({
          asset: this.customFilters.asset,
        });
      }

      if (this.customFilters.fournisseur) {
        pagination.filter.customSearch.push({
          fournisseur: this.customFilters.fournisseur,
        });
      }

      this.$apollo
        .query({
          query: QUERY_lienServices,
          variables: {
            pagination: pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          this.$refs.list.items = data.lienServices;

          this.$refs.list.items.filter(function (elem) {
            elem.historique.filter(function (element) {
              elem.historique.date = element.date;
              elem.historique.user = element.user.email;
            });
          });

          this.$refs.list.itemsCount = data.lienServicesCount;
          this.$refs.list.loading = false;
        });
    },
    init() {
      this.fetchItems();
    },
  },

  watch: {
    customFilters: {
      deep: true,
      handler() {
        this.$refs.list.currentPage = 1;
        this.fetchItems();
      },
    },
  },
};
</script>