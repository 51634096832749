var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ListLayout',{ref:"list",attrs:{"headers":_vm.headers,"title":_vm.title,"noFilter":_vm.noFilter},on:{"fetch":_vm.init,"showMultipleActions":function($event){_vm.showMultipleActions = $event}},scopedSlots:_vm._u([(_vm.multiple)?{key:"multipleActions",fn:function(){return [_c('DemandeAccordActions',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMultipleActions),expression:"showMultipleActions"}],attrs:{"multiple":true,"object":_vm.$refs.list ? _vm.$refs.list.itemsSelected : [],"size":"xl"},on:{"fetch":_vm.init,"loading":function($event){_vm.$refs.list.loading = true}}})]},proxy:true}:null,{key:"item.object",fn:function(ref){
var item = ref.item;
return [_c('DemandeAccordObject',{attrs:{"demandeAccordProp":item,"size":"xs"}})]}},{key:"item.dateDemande",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("shortDateFR")(item.dateDemande))+" ")]}},{key:"item.affaire",fn:function(ref){
var item = ref.item;
return [_c('AffaireObject',{attrs:{"affaireProp":item.affaire,"size":"xs"}})]}},{key:"item.affaire.locataire",fn:function(ref){
var item = ref.item;
return [(item.affaire.locataire)?_c('ClientObject',{attrs:{"clientID":item.affaire.locataire.id,"size":"xs"}}):_vm._e()]}},{key:"item.bailleur",fn:function(ref){
var item = ref.item;
return [(item.bailleur)?_c('BailleurObject',{attrs:{"bailleurProp":item.bailleur,"size":"xs"}}):_vm._e()]}},{key:"item.etat",fn:function(ref){
var item = ref.item;
return [_c('DemandeAccordObject',{attrs:{"demandeAccordProp":item,"size":"xs-etat"}})]}},{key:"item.taux",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.taux ? Number(item.taux.toFixed(3)) + " %" : "")+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('DemandeAccordActions',{attrs:{"object":item,"size":"xs"},on:{"fetch":_vm.init,"loading":function($event){_vm.$refs.list.loading = true}}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }