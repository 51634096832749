<template>
  <div>
    <template v-if="factureClient">
      <template v-if="size == 'xl'">
        <ObjectLayout :action="action" size="xl" :item="factureClient">
          <template v-slot:icon>mdi-file-table</template>
          <template v-slot:titre>
            Facture n°{{ factureClient.numeroFacture }}
          </template>
          <template v-slot:body>
            <v-row>
              <v-col>
                <ObjectLayout>
                  <template v-slot:icon>mdi-widgets</template>
                  <template v-slot:titre>Etat de la facture</template>
                  <template v-slot:content>
                    <span
                      :class="
                        factureClient.valide ? 'success--text' : 'warning--text'
                      "
                    >
                      {{ factureClient.valide ? "Validée" : "Temporaire" }}
                    </span>
                  </template>
                </ObjectLayout>
              </v-col>
              <v-col>
                <ObjectLayout>
                  <template v-slot:icon>mdi-counter</template>
                  <template v-slot:titre>Numéro de la facture</template>
                  <template v-slot:content>
                    {{ factureClient.numeroFacture }}
                  </template>
                </ObjectLayout>
              </v-col>
            </v-row>
            <v-row v-if="factureClient.valide">
              <v-col>
                <ObjectLayout>
                  <template v-slot:icon>mdi-calendar</template>
                  <template v-slot:titre>Date de facture</template>
                  <template v-slot:content>
                    {{ factureClient.dateFacture | shortDateFR }}
                  </template>
                </ObjectLayout>
              </v-col>
              <v-col>
                <ObjectLayout>
                  <template v-slot:icon>mdi-calendar</template>
                  <template v-slot:titre>Date d'échéance</template>
                  <template v-slot:content>
                    {{ factureClient.dateEcheance | shortDateFR }}
                  </template>
                </ObjectLayout>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ObjectLayout v-if="factureClient.client">
                  <template v-slot:titre>Client</template>
                  <template v-slot:content>
                    <ClientObject
                      :clientProp="factureClient.client"
                      size="xs"
                    />
                  </template>
                </ObjectLayout>
              </v-col>
              <v-col>
                <ObjectLayout v-if="factureClient.fournisseur">
                  <template v-slot:titre>Fournisseur</template>
                  <template v-slot:content>
                    <FournisseurObject
                      :fournisseurProp="factureClient.fournisseur"
                      size="xs"
                    />
                  </template>
                </ObjectLayout>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <FactureClientLignesList
                  :actions="false"
                  :value="factureClient.lignes"
                />
              </v-col>
            </v-row>
          </template>
        </ObjectLayout>
      </template>
      <template v-if="size == 'md'">
        <ObjectLayout :action="action" size="md">
          <template v-slot:icon>mdi-file-table</template>
          <template v-slot:titre>
            {{ factureClient.numeroFacture }} -
            {{ factureClient.montantHT | monetaire }}
          </template>
          <template v-slot:content>
            <ClientObject
              :clientID="factureClient.client.id"
              size="xs"
              action="none"
            />
          </template>
          <template v-slot:extracontent> </template>
        </ObjectLayout>
      </template>

      <template v-if="size == 'xs'">
        <ObjectLayout
          size="xs"
          :onClickPush="`/devis-facture/facture-clients/view/${factureClient.id}`"
          :action="action"
          :color="factureClient.valide ? 'success' : 'warning'"
        >
          <template v-slot:icon>mdi-file-cad</template>
          <template v-slot:content>
            {{ factureClient.numeroFacture }}
          </template>
          <template v-slot:extracontent>
            {{ factureClient.montantHT | monetaire }}
          </template>
        </ObjectLayout>
      </template>
    </template>
  </div>
</template>

<script>
import { QUERY_factureClient } from "./graphql/queries";

import ClientObject from "./ClientObject.vue";
import FactureClientLignesList from "./FactureClientLignesList.vue";
import FournisseurObject from "./FournisseurObject.vue";
import ObjectLayout from "@/components/COMPONENTS-skeleton/Layouts/ObjectLayout.vue";

export default {
  name: "FactureObject",

  components: {
    ClientObject,
    FactureClientLignesList,
    FournisseurObject,
    ObjectLayout,
  },

  props: {
    action: { type: String, default: "router" },
    factureClientID: {
      type: String,
    },
    factureClientProp: {
      type: Object,
      default() {
        return {};
      },
    },
    size: { type: String, default: "md" },
  },

  data: () => ({
    factureClient: undefined,
  }),

  watch: {
    factureClientID: function () {
      this.init();
    },
    factureClientProp: function () {
      this.init();
    },
  },

  mounted: function () {
    this.init();
  },

  methods: {
    init() {
      if (this.factureClientID) {
        let id = this.factureClientID;
        this.$apollo
          .query({
            query: QUERY_factureClient,
            variables: { id: id },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let factureClient = data.data.factureClient;
            this.factureClient = JSON.parse(JSON.stringify(factureClient));
          });
      } else {
        this.factureClient = JSON.parse(JSON.stringify(this.factureClientProp));
      }
    },
  },
};
</script>
