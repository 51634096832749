import gql from 'graphql-tag';

export const FICHIER_FIELDS = gql`
    fragment FichierFields on Fichier {
      dateCreation
      displayName
      extension
      fileName
      id
      isActif
      meta
      note
      ocrData{
        description
      }
      personalData
      securise
      size
      type
    }
`;

export const DOWNLOADURL_FIELDS = gql`
 fragment DownloadURLFields on DownloadURL{
   url
 }
`;

export const UPLOADURL_FIELDS = gql`
  fragment UploadURLFields on UploadURL{
    fileName
    url
  }
`;
