import gql from 'graphql-tag';

export const AUTHENTIFICATION_HABILITATION_APPLICATION_PARAMETRES_FIELDS = gql`
  fragment AuthentificationHabilitationApplicationParametresFields on AuthentificationHabilitationApplicationParametres {
    id
    isSignUpBlocked
  }
`;

export const AUTHENTIFICATION_HABILITATION_ORGANISATION_PARAMETRES_FIELDS = gql`
  fragment AuthentificationHabilitationOrganisationParametresFields on AuthentificationHabilitationOrganisationParametres {
    background
    customMainMenuOrder
    id
    logo{
      id
    }
    nbEquipementsMax
    organisationName
    color
    urls
    applicationName
  }
`;

export const EQUIPEMENT_FIELDS = gql`
  fragment EquipementFields on Equipement {
    browserName
    id
    isMobile
    lastConnexion
    OSName
}`

export const HABILITATION_FIELDS = gql`
  fragment HabilitationFields on Habilitation{
    id
    role
    dateConnexion
}`

export const ORGANISATION_FIELDS = gql`
  fragment OrganisationFields on Organisation{
    dateMiseAJour
    id
    isActif
    label
}`

export const USER_FIELDS = gql`
  fragment UserFields on User {
    dateConnexion
    dateCreation
    email
    id
    is2FAEnabled
    isActif
    meta
    preferences
    role
    verify
    thirdPartyConnexion
  }  
`