<template>
  <FormLayout
    icon="mdi-account"
    @ajouter="ajouter"
    @modifier="modifier"
    @reinitialiser="init"
    typeItem="un utilisateur"
    :disabled="input.email ? false : true"
    :disposition="disposition"
    :hasResetButton="hasResetButton"
    :item="input"
  >
    <v-row>
      <v-col>
        <v-text-field
          v-model="input.email"
          label="Adresse mail"
          prepend-icon="mdi-account-circle"
          :error-messages="formsErrors.userEmail"
          :rules="emailRules"
        />
      </v-col>
      <template v-if="!acl('/authentification-habilitation/all/users')">
        <v-col v-if="showRoles">
          <div>
            <v-select
              :items="roles"
              v-model="input.role"
              :error-messages="formsErrors.userRole"
              label="Rôle"
              prepend-icon="mdi-account-key"
            />
          </div>
        </v-col>
      </template>
      <template v-else>
        <v-col>
          <v-text-field
            v-model="input.password"
            label="Mot de passe"
            hint="Le mot de passe est généré automatiquement"
            persistent-hint
            prepend-icon="mdi-counter"
            :disabled="generatePassword"
            :error-messages="formsErrors.userPassword"
          />
          <v-switch v-model="generatePassword" />
        </v-col>
      </template>
    </v-row>
  </FormLayout>
</template>
<script>
import { mapState } from "vuex";
import { QUERY_user } from "./graphql/queries";
import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";
import { MUTATION_userCreate, MUTATION_userUpdate } from "./graphql/mutations";

export default {
  name: "UserForm",
  components: {
    FormLayout,
  },
  data: function () {
    return {
      input: {},
      emailRules: [
        (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "L'email doit être valide",
      ],
      roles: [
        "ADMIN",
        "DIRIGEANT",
        "GESTIONNAIRE",
        "COMMERCIAL",
        "CLIENT",
        "PARTENAIRE",
      ],
      generatePassword: true,
    };
  },
  props: {
    user: {
      type: Object,
      default() {
        return {
          id: undefined,
        };
      },
    },
    hasResetButton: { type: Boolean, default: true },
    disposition: { type: String, default: "default" },
    showRoles: { type: Boolean, default: true },
  },
  computed: {
    ...mapState(["formsErrors"]),
  },
  mounted: function () {
    this.init();
  },
  methods: {
    init() {
      if (this.$route.params.id) {
        let id = this.$route.params.id;
        this.$apollo
          .query({
            query: QUERY_user,
            variables: {
              id: id,
            },
          })
          .then((data) => {
            let user = data.data.user;
            this.input = JSON.parse(JSON.stringify(user));
          });
      } else {
        this.input = JSON.parse(JSON.stringify(this.user));
      }
    },

    ajouter() {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_userCreate,
          variables: {
            input: toSend,
          },
        })
        .then((__) => {
          this.init();
          this.$store.commit("addAlert", {
            type: "info",
            text: "Le compte utilisateur a été créé",
          });
          this.$root.$emit("skeleton--close");
          this.$root.$emit(
            "authentification-habilitation--user-create",
            __.data.userCreate
          );
          this.$root.$emit("skeleton--close");
        });
    },

    inputConverter(arg) {
      const obj = JSON.parse(JSON.stringify(arg));

      let input = {
        email: obj.email,
        password: obj.password,
        role: obj.role,
      };

      obj.id != undefined ? (input.id = obj.id) : null;

      return input;
    },

    modifier() {
      let toSend = this.inputConverter(this.input);
      this.$apollo.mutate({
        mutation: MUTATION_userUpdate,
        variables: {
          input: toSend,
        },
        update: (store, { data: { userUpdate } }) => {
          this.$emit("userReturn", userUpdate);
          this.$store.commit("addAlert", {
            type: "info",
            text: "Le compte utilisateur a été modifié",
          });
          this.$root.$emit("authentification-habilitation--update-users");
          this.$root.$emit("skeleton--close");
        },
      });
    },
  },

  watch: {
    //Si on desactive l'input, on supprime la donnée stockée
    generatePassword(val) {
      if (val) {
        this.input.password = "";
      }
    },

    user() {
      this.init();
    },
  },
};
</script>