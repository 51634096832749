<template>
  <ViewLayout :tabs="tabs" :item="contratLocation" :data="fichierMakerData">
    <template v-slot:contratLocation>
      <ContratLocationActions :object="contratLocation" :withView="false" size="xl" />

      <ContratLocationObject :contratLocationProp="contratLocation" size="xl" />
    </template>
    <template v-slot:devisFactureFournisseurs>
      <v-row>
        <v-col>
          <DevisFournisseursList :noFilter="true" :multiple="false" :customSearch="[{ _id: devisFournisseursID }]" />
        </v-col>
        <v-col>
          <FactureFournisseursList :noFilter="true" :multiple="false" :customSearch="[{ _id: facturesFournisseursID }]" />
        </v-col>
      </v-row>
    </template>
  </ViewLayout>
</template>

<script>
import { financementToString } from "./helpers/financementToString";

import {
  QUERY_contratLocation,
  QUERY_projetSateLeaseOrganisationParametres,
} from "./graphql/queries";

import { QUERY_authentificationHabilitationOrganisationParametresLogoBase64 } from "../COMPONENTS-authentification-habilitation/graphql/queries";

import ContratLocationObject from "./ContratLocationObject.vue";
import ContratLocationActions from "./ContratLocationActions.vue";
import DevisFournisseursList from "@/components/COMPONENTS-devis-facture/DevisFournisseursList.vue";
import FactureFournisseursList from "@/components/COMPONENTS-devis-facture/FactureFournisseursList.vue";
import ViewLayout from "@/components/COMPONENTS-skeleton/Layouts/ViewLayout.vue";

export default {
  name: "ContratLocationView",

  components: {
    ContratLocationObject,
    ContratLocationActions,
    DevisFournisseursList,
    FactureFournisseursList,
    ViewLayout,
  },

  props: {
    showPopup: {
      type: Boolean,
    },
    id: {
      type: String,
    },
  },

  id: {
    type: String,
  },
  data: () => ({
    contratLocation: {},
    projetSateLeaseOrganisationParametres: {},
    tabs: [
      {
        icon: "mdi-file-sign",
        displayName: "Contrat de location",
        name: "contratLocation",
      },
      {
        icon: "mdi-file-compare",
        displayName: "Devis - Factures",
        name: "devisFactureFournisseurs",
      },
      { name: "commentaires" },
      { name: "fichiers" },
    ],
  }),
  apollo: {
    contratLocation: {
      query: QUERY_contratLocation,
      variables() {
        let idQuery = this.showPopup
          ? this.id
          : this.$route.params.id
            ? this.$route.params.id
            : this.id;
        return {
          id: idQuery,
        };
      },
      fetchPolicy: "no-cache",
    },
    projetSateLeaseOrganisationParametres: {
      query: QUERY_projetSateLeaseOrganisationParametres,
    },
    authentificationHabilitationOrganisationParametres: {
      query: QUERY_authentificationHabilitationOrganisationParametresLogoBase64,
      fetchPolicy: 'no-cache'
    }
  },
  computed: {
    devisFournisseursID() {
      let IDs = [];
      if (this.contratLocation.devisFournisseurs && this.contratLocation.devisFournisseurs[0]) {
        this.contratLocation.devisFournisseurs.forEach((devis) => {
          IDs.push(devis.id);
        });
      }

      return IDs;
    },

    facturesFournisseursID() {
      let IDs = [];
      if (
        this.contratLocation.facturesFournisseurs &&
        this.contratLocation.facturesFournisseurs[0]
      ) {
        this.contratLocation.facturesFournisseurs.forEach((facture) => {
          IDs.push(facture.id);
        });
      }

      return IDs;
    },

    fichierMakerData() {
      if (this.contratLocation.id && this.projetSateLeaseOrganisationParametres && this.authentificationHabilitationOrganisationParametres) {
        let toReturn = { ...this.contratLocation };

        if (toReturn.financement) {

          toReturn.financement = financementToString(toReturn.financement);
          toReturn.financement.repartition.forEach((repartition) => {
            let loyers = JSON.parse(JSON.stringify(toReturn.financement.loyers));

            loyers.forEach((loyer) => {
              loyer.pmt = loyer.pmt * (repartition.partCapital / 100);
            });
            let financement = {
              cf: toReturn.financement.cf,
              isBeginning: toReturn.financement.isBeginning,
              capitalAfinancer:
                toReturn.financement.capitalAfinancer *
                (repartition.partCapital / 100),
              loyers: loyers,
            };

            repartition.financement = financementToString(financement);
          });
        }

        // Tableau des équipements sans répartition
        toReturn.equipements = [];
        // Tableau des sites et de leurs équipements
        toReturn.equipementsParSite = [];
        if (toReturn.devisFournisseurs) {
          // Pour chaque devis on créé un tableau de client vierge
          toReturn.devisFournisseurs.forEach((devis) => {
            let found = toReturn.equipementsParSite.find(
              (element) => element.client.id == devis.client.id
            );
            if (!found) {
              devis.client.etablissement.adresse.libelleVoie = devis.client.etablissement.adresse.libelleVoie.toLowerCase().split(' ')
            .map((mot, index) => mot.split('')
              .map((e, i) => i == 0 ? e.toUpperCase()
                : i == mot.length - 1 && index != toReturn.locataire.etablissement.adresse.libelleVoie.split(' ').length - 1 ? e + " " : e))
            .map(e => e.join('')).join('')
              toReturn.equipementsParSite.push({
                client: devis.client,
                equipements: [],
              });
            }
          });
          // Pour chaque facture on créé un tableau de client vierge
          toReturn.facturesFournisseurs.forEach((facture) => {
            let found = toReturn.equipementsParSite.find(
              (element) => element.client.id == facture.client.id
            );
            if (!found) {
              toReturn.equipementsParSite.push({
                client: facture.client,
                equipements: [],
              });
            }
          });
        }
        // On ajoute les équipements des devis
        if (toReturn.devisFournisseurs) {
          toReturn.devisFournisseurs.forEach((devis) => {
            let found = toReturn.equipementsParSite.find(
              (element) => element.client.id == devis.client.id
            );

            devis.lignes.forEach((ligne) => {
              if (ligne.meta.financement && ligne.meta.apparaitDansContrat) {
                let ligneToPush = {
                  quantite: ligne.quantite,
                  unite: ligne.unite,
                  label: ligne.label,
                  numerosSerie: [],
                };
                if (ligne.modele) {
                  ligneToPush.unite = "Equipement(s)";
                  ligneToPush.label =
                    ligne.modele.marque.nom + " - " + ligne.modele.nom;
                }
                found.equipements.push(ligneToPush);
                toReturn.equipements.push(ligneToPush);
              }
            });
          });
        }

        // On ajoute les équipements des factures
        if (toReturn.facturesFournisseurs) {
          toReturn.facturesFournisseurs.forEach((facture) => {
            let found = toReturn.equipementsParSite.find(
              (element) => element.client.id == facture.client.id
            );

            facture.lignes.forEach((ligne) => {
              if (ligne.meta.financement && ligne.meta.apparaitDansContrat) {
                let ligneToPush = {
                  quantite: ligne.quantite,
                  unite: ligne.unite,
                  label: ligne.label,
                  numerosSerie: [],
                };
                if (
                  ligne.equipements &&
                  ligne.equipements[0] &&
                  ligne.equipements[0].modele
                ) {
                  ligneToPush.unite = "Equipement(s)";
                  ligneToPush.label =
                    ligne.equipements[0].modele.marque.nom +
                    " - " +
                    ligne.equipements[0].modele.nom;
                }
                ligne.equipements.forEach((equipement) => {
                  if (equipement.numeroSerie) {
                    ligneToPush.numerosSerie.push(equipement.numeroSerie);
                  }
                });
                // si pas de numéro de série on met un tiret
                if (ligneToPush.numerosSerie.length == 0) {
                  ligneToPush.numerosSerie.push("-");
                }
                found.equipements.push(ligneToPush);
                toReturn.equipements.push(ligneToPush);
              }
            });
          });
        }

        toReturn.autorisationsPrelevements = [];
        let creantierLoueur =
          this.projetSateLeaseOrganisationParametres.loueurInformationsCreancier;
        if (toReturn.facturation) {
          toReturn.facturation.repartition.forEach((rep) => {
            if (
              rep.moyenPaiement &&
              rep.client.etablissement &&
              creantierLoueur
            ) {
              let autorisation = {};



              autorisation.debiteur = {
                raisonSociale: rep.client.etablissement.entreprise.raisonSociale,
                adresse:
                  rep.client.etablissement.adresse.numeroVoie + " " +
                  rep.client.etablissement.adresse.libelleVoie,
                cpVille:
                  rep.client.etablissement.adresse.codePostal + " " +
                  rep.client.etablissement.adresse.libelleCommune + " " +
                  rep.client.etablissement.adresse.cedex,
                siren: rep.client.etablissement.entreprise.siren,
                //TODO passer le pays dans adresse d'etb
                pays: "FRANCE",
                iban: rep.moyenPaiement.compteBancaire.iban,
                bic: rep.moyenPaiement.compteBancaire.bic,
              };
              if (rep.prelevementPourCompte) {
                autorisation.creantier = {
                  raisonSociale: creantierLoueur.raisonSociale,
                  adresse: creantierLoueur.adresse,
                  cpVille: creantierLoueur.cpVille,
                  pays: creantierLoueur.pays,
                  ics: creantierLoueur.ics,
                };
              } else {


                autorisation.creantier = {
                  raisonSociale: toReturn.bailleur
                    ? toReturn.bailleur.informationCreancier.raisonSociale
                    : "",
                  adresse: toReturn.bailleur
                    ? toReturn.bailleur.informationCreancier.adresse
                    : "",
                  cpVille: toReturn.bailleur
                    ? toReturn.bailleur.informationCreancier.cpVille
                    : "",
                  pays: toReturn.bailleur
                    ? toReturn.bailleur.informationCreancier.pays
                    : "",
                  ics: toReturn.bailleur
                    ? toReturn.bailleur.informationCreancier.ics
                    : "",
                };

              }

              toReturn.autorisationsPrelevements.push(autorisation);
            }
          });
        }

        let logo = this.authentificationHabilitationOrganisationParametres.logo;

        toReturn.logo = logo ? logo.base64Content : null;

        toReturn.locataire.entreprise.capitalEnForme = !toReturn.locataire.entreprise.capital ? '0,00 €'
          : parseFloat(toReturn.locataire.entreprise.capital)
            .toLocaleString("fr-FR", {
              style: "currency",
              currency: "EUR",
            });

        toReturn.locataire.entreprise.sirenEnForme = toReturn.locataire.entreprise.siren.split('').map((e, i) => {
          return ((i + 1) % 3 == 0) && i != toReturn.locataire.entreprise.siren.length - 1 ? e + " " : e;
        }).join('');

        toReturn.locataire.entreprise.raisonSociale = toReturn.locataire.entreprise.raisonSociale.toUpperCase();

        toReturn.locataire.etablissement.adresse.libelleVoie =
          toReturn.locataire.etablissement.adresse.libelleVoie.toLowerCase().split(' ')
            .map((mot, index) => mot.split('')
              .map((e, i) => i == 0 ? e.toUpperCase()
                : i == mot.length - 1 && index != toReturn.locataire.etablissement.adresse.libelleVoie.split(' ').length - 1 ? e + " " : e))
            .map(e => e.join('')).join('')

        return toReturn;
      }

      return {}
    },
  },
};
</script>