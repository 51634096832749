<template>
  <FormLayout
    icon="mdi-cash-register"
    @ajouter="ajouter"
    @modifier="modifier"
    @reinitialiser="init"
    :hasResetButton="hasResetButton"
    typeItem="ReglementFournisseur"
    :item="input"
    :disabled="false"
  >
    <v-row> </v-row>
  </FormLayout>
</template>

<script>
import { mapState } from "vuex";

import {
  MUTATION_reglementFournisseurCreate,
  MUTATION_reglementFournisseurUpdate,
} from "./graphql/mutations";
import { QUERY_reglementFournisseur } from "./graphql/queries";

import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";

export default {
  name: "PointFacturationForm",

  components: {
    FormLayout,
  },

  props: {
    hasResetButton: { type: Boolean, default: true },
    disposition: { type: String, default: "default" },
    componentParent: undefined,
  },

  data: () => ({
    reglementFournisseur: {
      id: undefined,
    },
    input: {},
  }),

  computed: {
    ...mapState(["formsErrors"]),
  },

  mounted: function () {
    this.init();
  },

  methods: {
    ajouter() {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_reglementFournisseurCreate,
          variables: {
            input: toSend,
          },
        })
        .then((__) => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "Votre ReglementFournisseur a été ajouté",
          });

          if (this.componentParent) {
            this.$root.$emit(
              this.componentParent,
              __.data.reglementFournisseurCreate
            );
          }

          this.$root.$emit("skeleton--close");
          this.init();
        });
    },

    init() {
      if (
        this.$route.path.startsWith(
          "/devis-facture/reglementFournisseurs/edit/"
        ) &&
        this.$route.params.id
      ) {
        let id = this.$route.params.id;
        this.$apollo
          .query({
            query: QUERY_reglementFournisseur,
            variables: {
              id: id,
            },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let reglementFournisseur = data.data.reglementFournisseur;
            this.input = JSON.parse(JSON.stringify(reglementFournisseur));
          });
      } else {
        this.input = JSON.parse(JSON.stringify(this.reglementFournisseur));
      }
    },

    inputConverter(arg) {
      const obj = JSON.parse(JSON.stringify(arg));

      let input = {};

      obj.id != undefined ? (input.id = obj.id) : null;

      return input;
    },

    modifier() {
      let toSend = this.inputConverter(this.input);

      this.$apollo
        .mutate({
          mutation: MUTATION_reglementFournisseurUpdate,
          variables: {
            input: toSend,
          },
        })
        .then(() => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "ReglementFournisseur a été modifié",
          });
          this.$root.$emit("skeleton--close");
        });
    },
  },
};
</script>