import gql from 'graphql-tag';
import { COMMENTAIRE_FIELDS } from './fragments';

export const MUTATION_commentaireCreate = gql`
mutation($input: CommentaireInput!){
  commentaireCreate(input:$input){
    ...CommentaireFields
  }  
}${COMMENTAIRE_FIELDS}
`;

export const MUTATION_commentaireDelete = gql`
mutation($id:ID!){
  commentaireDelete(id:$id)
}
`;

export const MUTATION_commentaireUpdate = gql`
mutation($id:ID!,$input:CommentaireInput){
  commentaireUpdate(id:$id,input:$input){
    ...CommentaireFields
  }
}${COMMENTAIRE_FIELDS}
`;