<template>
  <FormLayout
    icon="mdi-file-send"
    @ajouter="ajouter"
    @modifier="modifier"
    @reinitialiser="init"
    :hasResetButton="hasResetButton"
    typeItem="un bon de commande fournisseur"
    :item="input"
    :disabled="false"
  >
    <v-alert text color="info">
      <h3 class="text-h5">Reprise de données</h3>
      <div>
        Sélectionnez un devis fournisseur à partir duquel vous souhaitez générer
        votre bon de commande. <br />
        Utilisez la baguette magique pour auto alimenter les champs du
        formulaire.
      </div>

      <v-divider class="my-4 info" style="opacity: 0.22"></v-divider>
      <v-row>
        <v-col>
          <DevisFournisseurSelector v-model="input.devisFournisseur" />
        </v-col>
        <v-col cols="2">
          <v-btn
            icon
            color="primary"
            :disabled="input.devisFournisseur ? false : true"
            @click="repriseDataFromDevisFournisseur(input.devisFournisseur)"
          >
            <v-icon>mdi-auto-fix</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
    <v-row>
      <v-col>
        <FournisseurSelector v-model="input.fournisseur" />
      </v-col>
      <v-col>
        <ClientSelector
          v-model="input.client"
          label="Site d'installation (lieu de livraison)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <DevisFournisseurLignesList
          typeItem="du bon de commande"
          v-model="input.lignes"
          ref="list"
          @formValue="$refs.lignesForm.ligneToAdd = $event"
        >
          <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
            <slot :name="name" v-bind="data"></slot>
          </template>
        </DevisFournisseurLignesList>

        <DevisFournisseurLigneForm v-model="input.lignes" ref="lignesForm">
          <template v-slot:customLigneForm="{ ligneToAdd }">
            <slot name="customLigneForm" :ligneToAdd="ligneToAdd"> </slot>
          </template>
        </DevisFournisseurLigneForm>
      </v-col>
    </v-row>
  </FormLayout>
</template>

<script>
import { mapState } from "vuex";

import {
  MUTATION_bonDeCommandeFournisseurCreate,
  MUTATION_bonDeCommandeFournisseurUpdate,
} from "./graphql/mutations";
import {
  QUERY_bonDeCommandeFournisseur,
  QUERY_devisFournisseur,
} from "./graphql/queries";

import ClientSelector from "./ClientSelector.vue";
import DevisFournisseurLigneForm from "./DevisFournisseurLigneForm.vue";
import DevisFournisseurLignesList from "./DevisFournisseurLignesList.vue";
import DevisFournisseurSelector from "./DevisFournisseurSelector.vue";
import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";
import FournisseurSelector from "./FournisseurSelector.vue";

export default {
  name: "PointFacturationForm",

  components: {
    ClientSelector,
    DevisFournisseurLigneForm,
    DevisFournisseurLignesList,
    DevisFournisseurSelector,
    FormLayout,
    FournisseurSelector,
  },

  props: {
    hasResetButton: { type: Boolean, default: true },
    disposition: { type: String, default: "default" },
    componentParent: undefined,
  },

  data: () => ({
    bonDeCommandeFournisseur: {
      id: undefined,
    },
    input: {},
  }),

  computed: {
    ...mapState(["formsErrors"]),
  },

  mounted: function () {
    this.init();
  },

  methods: {
    ajouter() {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_bonDeCommandeFournisseurCreate,
          variables: {
            input: toSend,
          },
        })
        .then((__) => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "Votre BonDeCommandeFournisseur a été ajouté",
          });

          if (this.componentParent) {
            this.$root.$emit(
              this.componentParent,
              __.data.bonDeCommandeFournisseurCreate
            );
          }

          this.$root.$emit("skeleton--close");
          this.init();
        });
    },

    init() {
      if (
        this.$route.path.startsWith(
          "/devis-facture/bon-de-commande-fournisseurs/edit/"
        ) &&
        this.$route.params.id
      ) {
        let id = this.$route.params.id;
        this.$apollo
          .query({
            query: QUERY_bonDeCommandeFournisseur,
            variables: {
              id: id,
            },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let bonDeCommandeFournisseur = data.data.bonDeCommandeFournisseur;
            this.input = JSON.parse(JSON.stringify(bonDeCommandeFournisseur));
          });
      } else {
        this.input = JSON.parse(JSON.stringify(this.bonDeCommandeFournisseur));
      }
    },

    inputConverter(arg) {
      const obj = JSON.parse(JSON.stringify(arg));

      let lignes = [];
      for (let objLigne of obj.lignes) {
        let inputLigne = {
          quantite: objLigne.quantite,
          unite: objLigne.unite,
          label: objLigne.label,
          modele:
            objLigne.modele && objLigne.modele.id
              ? objLigne.modele.id
              : objLigne.modele,
          prixUnitaire: objLigne.prixUnitaire,
          tauxTva: objLigne.tauxTva,
          meta: objLigne.meta,
        };
        lignes.push(inputLigne);
      }

      let input = {
        id: obj.id,
        devisFournisseur:
          obj.devisFournisseur && obj.devisFournisseur.id
            ? obj.devisFournisseur.id
            : obj.devisFournisseur,
        client: obj.client && obj.client.id ? obj.client.id : obj.client,
        fournisseur:
          obj.fournisseur && obj.fournisseur.id
            ? obj.fournisseur.id
            : obj.fournisseur,
        lignes: lignes,
        meta: obj.meta,
      };

      obj.id != undefined ? (input.id = obj.id) : null;

      return input;
    },

    modifier() {
      let toSend = this.inputConverter(this.input);

      this.$apollo
        .mutate({
          mutation: MUTATION_bonDeCommandeFournisseurUpdate,
          variables: {
            input: toSend,
          },
        })
        .then(() => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "BonDeCommandeFournisseur a été modifié",
          });
          this.$root.$emit("skeleton--close");
        });
    },
    repriseDataFromDevisFournisseur(id) {
       
      this.$apollo
        .query({
          query: QUERY_devisFournisseur,
          variables: { id: id },
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          let reprise = data.data.devisFournisseur;
          reprise.devisFournisseur = reprise.id;
          reprise.id = undefined;
          this.input = this.inputConverter(reprise);
        });
    },
  },
};
</script>