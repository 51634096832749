<template>
  <v-row>
    <v-col>
      <FactureClientObject size="xl" :factureClientID="$route.params.id" />
    </v-col>
    <v-col>
      <FormLayout
        icon="mdi-file-table"
        @modifier="valider"
        buttonIcon="mdi-check-bold"
        buttonText="Valider"
        @reinitialiser="init"
        :item="input"
        :hasResetButton="false"
        titre="Valider une facture"
      >
        <v-row>
          <v-col>
            <DateInput
              v-model="input.dateFacture"
              label="Date de facturation"
              :min="factureClientDateDerniereFacture"
              :max="factureClientDateMax"
            />
          </v-col>
          <v-col>
            <DateInput v-model="input.dateEcheance" label="Date d'échéance" />
          </v-col>
        </v-row>
      </FormLayout>
    </v-col>
  </v-row>
</template>

<script>
import dayjs from "dayjs";
import { mapState } from "vuex";

import { MUTATION_factureClientValidate } from "./graphql/mutations";
import {
  QUERY_devisFactureOrganisationParametres,
  QUERY_factureClient,
  QUERY_factureClientDateDerniereFacture,
} from "./graphql/queries";

import DateInput from "@/components/COMPONENTS-skeleton/Inputs/DateInput";
import FactureClientObject from "./FactureClientObject.vue";
import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";

export default {
  name: "FactureClientValidateForm",

  components: {
    DateInput,
    FactureClientObject,
    FormLayout,
  },

  props: {
    componentParent: undefined,
  },

  data: () => ({
    devisFactureOrganisationParametres: {},
    factureClient: { lignes: [] },
    factureClientDateDerniereFacture: null,
    factureClientDateMax: dayjs().format("YYYY-MM-DD"),
    input: {},
  }),

  computed: {
    ...mapState(["formsErrors"]),
  },

  watch: {
    // En cas de passage de la vue edit à add par exemple
    $route() {
      this.init();
    },
  },

  mounted: function () {
    this.init();
  },

  methods: {
    async init() {
      let id = this.$route.params.id;

      this.$apollo
        .query({
          query: QUERY_factureClient,
          variables: {
            id: id,
          },
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          let factureClient = data.data.factureClient;
          if(factureClient.valide) {
            this.$router.push({ path: '/' }).catch(() => {});
          }

          factureClient.dateFacture = dayjs();
          factureClient.dateEcheance = dayjs(factureClient.dateFacture).add(
            this.devisFactureOrganisationParametres
              .nbJourDefaultFactureClientEcheance,
            "day"
          );
          this.input = JSON.parse(JSON.stringify(factureClient));
        });

      // On récupère la date de la dernière facture valide
      this.$apollo
        .query({
          query: QUERY_factureClientDateDerniereFacture,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          this.factureClientDateDerniereFacture =
            data.data.factureClientDateDerniereFacture;
        });

      // On récupère les paramètrages
      this.$apollo
        .query({
          query: QUERY_devisFactureOrganisationParametres,
        })
        .then((data) => {
          this.devisFactureOrganisationParametres =
            data.data.devisFactureOrganisationParametres;
        });
    },

    inputConverter(arg) {
      const obj = {
        ...arg,
      };
      if (Object.keys(obj).length == 0) {
        return "";
      }

      let input = {
        id: obj.id,
        dateFacture: obj.dateFacture,
        dateEcheance: obj.dateEcheance,
      };
      return input;
    },

    valider() {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_factureClientValidate,
          variables: {
            input: toSend,
          },
        })
        .then(() => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "La facture est validée, il n'est plus possible de la modifier",
          });
        });
    },
  },
};
</script>