<template>
  <UserEditPasswordForm 
    :errorMessages="formsErrors"
    @validForm="validForm"
  />
</template>

<script>
import UserEditPasswordForm from "./UserEditPasswordForm.vue";
import { MUTATION_userMePasswordUpdate } from "./graphql/mutations.js";
import { mapState } from "vuex";

export default {
  name: "UserMeEditPassword",

  components: {
    UserEditPasswordForm
  },

  computed: {
    ...mapState(["formsErrors"]),
  },

  methods: {
    validForm(input) {
      if(input.password != input.passwordConfirmation) {
        this.$store.commit("addAlert", {
          type: "warning",
          text: "Les mots de passe ne correspondent pas",
        });
        this.$root.$emit("skeleton--close");
      }else {
        this.$apollo
          .mutate({
            mutation: MUTATION_userMePasswordUpdate,
            variables: {
              password: input.password,
              oldPassword: input.oldPassword
            }
          })
          .then(() => {
            this.$store.commit("addAlert", {
              type: "success",
              text: "Le mot de passe a été modifié",
            });
            this.$root.$emit("skeleton--close");
            this.$router.push({ path: '/authentification-habilitation/user-me/edit' });
          })
      }
    }
  }
}
</script>