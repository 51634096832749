<template>
  <SelectorLayout
    :addAction="addAction"
    :disabled="disabled"
    @fetch="fetch"
    :label="label"
    :prepend-icon="prependIcon"
    ref="selector"
    v-model="item"
  >
    <template v-slot:selection="data">
      <MoyenPaiementObject
        :moyenPaiementProp="data.item"
        v-bind="data.attrs"
        :input-value="data.selected"
        @click="data.select"
        size="xs"
        action="none"
      />
    </template>

    <template v-slot:item="data">
      <MoyenPaiementObject
        :moyenPaiementProp="data.item"
        size="xs"
        action="none"
      />
    </template>
  </SelectorLayout>
</template>

<script>
import { QUERY_moyenPaiements } from "./graphql/queries";

import MoyenPaiementObject from "./MoyenPaiementObject.vue";
import SelectorLayout from "@/components/COMPONENTS-skeleton/Layouts/SelectorLayout.vue";

export default {
  name: "MoyenPaiementSelector",

  components: {
    MoyenPaiementObject,
    SelectorLayout,
  },

  props: {
    addAction: { type: String, default: "/devis-facture/moyen-paiements/add" },
    disabled: { type: Boolean, default: false },
    errorMessage: { type: String },
    label: { type: String, default: "MoyenPaiements" },
    multiple: { type: Boolean, default: false },
    pointFacturation: null,
    prependIcon: { type: String, default: "mdi-cash-fast" },
    value: null,
  },

  data: () => ({
    item: null,
  }),

  mounted() {
    this.item = this.value && this.value.id ? this.value.id : this.value;
    if (this.pointFacturation) {
      this.fetch();
    }
  },

  methods: {
    fetch() {
      this.$refs.selector.loading = true;
      let pagination = JSON.parse(
        JSON.stringify(this.$refs.selector.pagination)
      );
      if (this.pointFacturation) {
        pagination.filter.customSearch.push({
          pointFacturation: this.pointFacturation,
        });
      }
      this.$apollo
        .query({
          query: QUERY_moyenPaiements,
          variables: {
            pagination: pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          this.$refs.selector.items = data.moyenPaiements;
          this.$refs.selector.loading = false;
        });
    },
  },

  watch: {
    value: {
      deep: true,
      handler(val) {
        this.item = val && val.id ? val.id : val;
        this.fetch();
      },
    },

    item: {
      deep: true,
      handler(val) {
        if (val === null) {
          this.$emit("input", null);
        } else {
          this.$emit("input", val);
        }
      },
    },

    pointFacturation: function (val) {
      if (val) {
        this.fetch();
      }
    },
  },
};
</script>