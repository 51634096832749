<template>
  <div>
    <FormLayout
      icon="mdi-package-variant-closed"
      @ajouter="ajouter"
      @reinitialiser="init"
      typeItem="des assets"
    >
      <v-card
        v-for="(ligne, i) of assetsToDuplicate"
        :key="i"
        
        elevation="6"
        class="mb-10 pt-5 pl-5"
      >
        <div
          v-for="(asset, j) in ligne"
          :key="j"
        >
          <AssetObject 
            :assetProp="asset"
            size="xs"
            styleXs="max-width: calc(55vw - 32px);position: relatif;text-align: left;"
          />

          <v-card-text class="text-left">
            <v-row>
              <v-col cols="4">
                <v-text-field
                  v-model="newNumerosSerie[i][j]"
                  label="Nouveau numéro de série"
                  dense
                />
              </v-col>
            </v-row>
          </v-card-text>
        </div>
      </v-card>
    </FormLayout>
  </div>
</template>

<script>
import AssetObject from "./AssetObject.vue";
import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";

import { MUTATION_assetsCreateFromFactureLignes } from "./graphql/mutations";

export default {
  name: "AssetsDuplicate",

  components: {
    AssetObject,
    FormLayout
  },

  props: {
    componentParent: undefined,
    assets: {
      type: String,
      default: null
    }
  },

  data: () => ({
    assetsToDuplicate: [],
    newNumerosSerie: []
  }),

  methods: {
    async ajouter() {
      let input = this.inputConverter(this.newNumerosSerie);
      
      this.$apollo
        .mutate({
          mutation: MUTATION_assetsCreateFromFactureLignes,
          variables: {
            input
          }
        })
        .then(__ => {
          this.$root.$emit("assetsCreateFromFactureLignes", { data: __.data.assetsCreateFromFactureLignes, uid: this.componentParent })
        })
    },

    init() {
      this.newNumerosSerie = [];
      for(let i = 0; i < this.assetsToDuplicate.length; i++) {
        this.newNumerosSerie.push([]);
      }
    },

    inputConverter(arg) {
      const objs = JSON.parse(JSON.stringify(arg));

      let input = [];

      for(let [i, obj] of objs.entries()) {
        let ligne = [];
        for(let [j, num] of obj.entries()) {
          let asset = this.assetsToDuplicate[i][j];
          asset.numeroSerie = num;
          delete asset.id;
          delete asset.dateCreation;
          delete asset.numeroInventaire;
          delete asset.__typename;

          ligne.push(asset)
        }
        input.push(ligne)
      }

      return input;
    },
  },

  watch: {
    assets: {
      handler(val) {
        this.assetsToDuplicate = JSON.parse(val);
        this.init();
      },

      immediate: true
    }
  }
}
</script>