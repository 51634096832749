export default [
  //Paramètre
  {
    path: '/devis-facture/parametres',
    name: 'Paramètres Devis Facture',
    component: () => import('./DevisFactureOrganisationParametresForm.vue'),
    meta: {
      acl: "/devis-facture/parametres"
    }
  },
  //Bon de commande Fournisseur
  {
    path: '/devis-facture/bon-de-commande-fournisseurs',
    name: 'Gestion des bons de commande',
    component: () => import('./BonDeCommandeFournisseursList.vue'),
    meta: {
      acl: "/devis-facture/bon-de-commande-fournisseurs"
    }
  },
  {
    path: '/devis-facture/bon-de-commande-fournisseurs/list',
    name: 'Liste des bons de commande',
    component: () => import('./BonDeCommandeFournisseursList.vue'),
    meta: {
      acl: "/devis-facture/bon-de-commande-fournisseurs/list"
    }
  },
  {
    path: '/devis-facture/bon-de-commande-fournisseurs/add',
    name: 'Ajouter un bon de commande ',
    component: () => import('./BonDeCommandeFournisseurForm.vue'),
    meta: {
      acl: "/devis-facture/bon-de-commande-fournisseurs/add"
    }
  },
  {
    path: '/devis-facture/bon-de-commande-fournisseurs/edit/:id',
    name: 'Editer un bon de commande',
    component: () => import('./BonDeCommandeFournisseurForm.vue'),
    meta: {
      acl: "/devis-facture/bon-de-commande-fournisseurs/edit"
    }
  },
  {
    path: '/devis-facture/bon-de-commande-fournisseurs/view/:id',
    name: 'Visualiser un bon de commande',
    component: () => import('./BonDeCommandeFournisseurView.vue'),
    meta: {
      acl: "/devis-facture/bon-de-commande-fournisseurs/view"
    }
  },
  {
    path: '/devis-facture/bon-de-commande-fournisseurs/deleted',
    name: 'Liste des bons de commande supprimés',
    component: () => import('./BonDeCommandeFournisseursList.vue'),
    meta: {
      acl: "/devis-facture/bon-de-commande-fournisseurs/deleted"
    }
  },


  //Clients
  {
    path: '/devis-facture/clients',
    name: 'Gestion des clients',
    component: () => import('./ClientsList.vue'),
    meta: {
      acl: "/devis-facture/clients"
    }
  },
  {
    path: '/devis-facture/clients/list',
    name: 'Liste des clients',
    component: () => import('./ClientsList.vue'),
    meta: {
      acl: "/devis-facture/clients/list"
    }
  },
  {
    path: '/devis-facture/clients/add',
    name: 'Ajouter un client',
    component: () => import('./ClientForm.vue'),
    meta: {
      acl: "/devis-facture/clients/add"
    }
  },
  {
    path: '/devis-facture/clients/edit/:id',
    name: 'Editer un client',
    component: () => import('./ClientForm.vue'),
    meta: {
      acl: "/devis-facture/clients/edit"
    }
  },
  {
    path: '/devis-facture/clients/view/:id',
    name: 'Visualiser un client',
    component: () => import('./ClientView.vue'),
    meta: {
      acl: "/devis-facture/clients/view"
    }
  },
  {
    path: '/devis-facture/clients/deleted',
    name: 'Liste des clients supprimés',
    component: () => import('./ClientsList.vue'),
    meta: {
      acl: "/devis-facture/clients/deleted"
    }
  },

  //DevisClient
  {
    path: '/devis-facture/devis-clients',
    name: 'Gestion des devis clients',
    component: () => import('./DevisClientsList.vue'),
    meta: {
      acl: "/devis-facture/devis-clients"
    }
  },
  {
    path: '/devis-facture/devis-clients/list',
    name: 'Liste des devis clients',
    component: () => import('./DevisClientsList.vue'),
    meta: {
      acl: "/devis-facture/devis-clients/list"
    }
  },
  {
    path: '/devis-facture/devis-clients/add',
    name: 'Ajouter un devis client',
    component: () => import('./DevisClientForm.vue'),
    meta: {
      acl: "/devis-facture/devis-clients/add"
    }
  },
  {
    path: '/devis-facture/devis-clients/edit/:id',
    name: 'Editer un devis client',
    component: () => import('./DevisClientForm.vue'),
    meta: {
      acl: "/devis-facture/devis-clients/edit"
    }
  },
  {
    path: '/devis-facture/devis-clients/view/:id',
    name: 'Visualiser un devis client',
    component: () => import('./DevisClientView.vue'),
    meta: {
      acl: "/devis-facture/devis-clients/view"
    }
  },
  {
    path: '/devis-facture/devis-clients/deleted',
    name: 'Liste des devis client supprimés',
    component: () => import('./DevisClientsList.vue'),
    meta: {
      acl: "/devis-facture/devis-clients/deleted"
    }
  },

  //Devis Fournisseurs
  {
    path: '/devis-facture/devis-fournisseurs',
    name: 'Gestion des devis fournisseurs',
    component: () => import('./DevisFournisseursList.vue'),
    meta: {
      acl: "/devis-facture/devis-fournisseurs"
    }
  },
  {
    path: '/devis-facture/devis-fournisseurs/list',
    name: 'Liste des devis fournisseurs',
    component: () => import('./DevisFournisseursList.vue'),
    meta: {
      acl: "/devis-facture/devis-fournisseurs/list"
    }
  },
  {
    path: '/devis-facture/devis-fournisseurs/add',
    name: 'Ajouter un devis fournisseur',
    component: () => import('./DevisFournisseurForm.vue'),
    meta: {
      acl: "/devis-facture/devis-clifournisseursents/add"
    }
  },
  {
    path: '/devis-facture/devis-fournisseurs/edit/:id',
    name: 'Editer un devis fournisseur',
    component: () => import('./DevisFournisseurForm.vue'),
    meta: {
      acl: "/devis-facture/devis-fournisseurs/edit"
    }
  },
  {
    path: '/devis-facture/devis-fournisseurs/view/:id',
    name: 'Visualiser un devis fournisseur',
    component: () => import('./DevisFournisseurView.vue'),
    meta: {
      acl: "/devis-facture/devis-fournisseurs/view"
    }
  },
  {
    path: '/devis-facture/devis-fournisseurs/deleted',
    name: 'Liste des devis fournisseurs supprimés',
    component: () => import('./DevisFournisseursList.vue'),
    meta: {
      acl: "/devis-facture/devis-fournisseurs/deleted"
    }
  },

  //Facture Client
  {
    path: '/devis-facture/facture-clients',
    name: 'Gestion des factures clients',
    component: () => import('./FactureClientsList.vue'),
    meta: {
      acl: "/devis-facture/facture-clients"
    }
  },
  {
    path: '/devis-facture/facture-clients/list',
    name: 'Liste des factures clients',
    component: () => import('./FactureClientsList.vue'),
    meta: {
      acl: "/devis-facture/facture-clients/list"
    }
  },
  {
    path: '/devis-facture/facture-clients/add',
    name: 'Ajouter une facture client',
    component: () => import('./FactureClientForm.vue'),
    meta: {
      acl: "/devis-facture/facture-clients/add"
    }
  },
  {
    path: '/devis-facture/facture-clients/edit/:id',
    name: 'Editer une facture client',
    component: () => import('./FactureClientForm.vue'),
    meta: {
      acl: "/devis-facture/facture-clients/edit"
    }
  },
  {
    path: '/devis-facture/facture-clients/validate/:id',
    name: 'Valider une facture client',
    component: () => import('./FactureClientValidateForm.vue'),
    meta: {
      acl: "/devis-facture/facture-clients/validate"
    }
  },
  {
    path: '/devis-facture/facture-clients/view/:id',
    name: 'Visualiser une facture client',
    component: () => import('./FactureClientView.vue'),
    meta: {
      acl: "/devis-facture/facture-clients/view"
    }
  },
  {
    path: '/devis-facture/facture-clients/deleted',
    name: 'Liste des factures clients supprimées',
    component: () => import('./FactureClientsList.vue'),
    meta: {
      acl: "/devis-facture/facture-clients/deleted"
    }
  },

  //Facture Fournisseur
  {
    path: '/devis-facture/facture-fournisseurs',
    name: 'Gestion des factures fournisseurs',
    component: () => import('./FactureFournisseursList.vue'),
    meta: {
      acl: "/devis-facture/facture-fournisseurs"
    }
  },
  {
    path: '/devis-facture/facture-fournisseurs/list',
    name: 'Liste des factures fournisseurs',
    component: () => import('./FactureFournisseursList.vue'),
    meta: {
      acl: "/devis-facture/facture-fournisseurs/list"
    }
  },
  {
    path: '/devis-facture/facture-fournisseurs/add',
    name: 'Ajouter une facture fournisseur',
    component: () => import('./FactureFournisseurForm.vue'),
    meta: {
      acl: "/devis-facture/facture-fournisseurs/add"
    }
  },
  {
    path: '/devis-facture/facture-fournisseurs/edit/:id',
    name: 'Editer une facture fournisseur',
    component: () => import('./FactureFournisseurForm.vue'),
    meta: {
      acl: "/devis-facture/facture-fournisseurs/edit"
    }
  },
  {
    path: '/devis-facture/facture-fournisseurs/view/:id',
    name: 'Visualiser une facture fournisseur',
    component: () => import('./FactureFournisseurView.vue'),
    meta: {
      acl: "/devis-facture/facture-fournisseurs/view"
    }
  },
  {
    path: '/devis-facture/facture-fournisseurs/deleted',
    name: 'Liste des factures fournisseurs supprimées',
    component: () => import('./FactureFournisseursList.vue'),
    meta: {
      acl: "/devis-facture/facture-fournisseurs/deleted"
    }
  },

  //  Fournisseurs
  {
    path: '/devis-facture/fournisseurs',
    name: 'Gestion des fournisseurs',
    component: () => import('./FournisseursList.vue'),
    meta: {
      acl: "/devis-facture/fournisseurs"
    }
  },
  {
    path: '/devis-facture/fournisseurs/list',
    name: 'Liste des fournisseurs',
    component: () => import('./FournisseursList.vue'),
    meta: {
      acl: "/devis-facture/fournisseurs/list"
    }
  },
  {
    path: '/devis-facture/fournisseurs/add',
    name: 'Ajouter un fournisseur',
    component: () => import('./FournisseurForm.vue'),
    meta: {
      acl: "/devis-facture/fournisseurs/add"
    }
  },
  {
    path: '/devis-facture/fournisseurs/edit/:id',
    name: 'Editer un fournisseur',
    component: () => import('./FournisseurForm.vue'),
    meta: {
      acl: "/devis-facture/fournisseurs/edit"
    }
  },
  {
    path: '/devis-facture/fournisseurs/view/:id',
    name: 'Visualiser un fournisseur',
    component: () => import('./FournisseurView.vue'),
    meta: {
      acl: "/devis-facture/fournisseurs/view"
    }
  },
  {
    path: '/devis-facture/fournisseurs/deleted',
    name: 'Liste des fournisseur supprimés',
    component: () => import('./FournisseursList.vue'),
    meta: {
      acl: "/devis-facture/fournisseurs/deleted"
    }
  },
  //  Moyens de paiement
  {
    path: '/devis-facture/moyen-paiements',
    name: 'Gestion des moyens de paiement',
    component: () => import('./MoyenPaiementsList.vue'),
    meta: {
      acl: "/devis-facture/moyen-paiements"
    }
  },
  {
    path: '/devis-facture/moyen-paiements/list',
    name: 'Liste des moyens de paiement',
    component: () => import('./MoyenPaiementsList.vue'),
    meta: {
      acl: "/devis-facture/moyen-paiements/list"
    }
  },
  {
    path: '/devis-facture/moyen-paiements/add',
    name: 'Ajouter un moyen de paiement',
    component: () => import('./MoyenPaiementForm.vue'),
    meta: {
      acl: "/devis-facture/moyen-paiements/add"
    }
  },
  {
    path: '/devis-facture/moyen-paiements/edit/:id',
    name: 'Editer un moyen de paiement',
    component: () => import('./MoyenPaiementForm.vue'),
    meta: {
      acl: "/devis-facture/moyen-paiements/edit"
    }
  },
  {
    path: '/devis-facture/moyen-paiements/view/:id',
    name: 'Visualiser un moyen de paiement',
    component: () => import('./MoyenPaiementView.vue'),
    meta: {
      acl: "/devis-facture/moyen-paiements/view"
    }
  },
  {
    path: '/devis-facture/moyen-paiements/deleted',
    name: 'Liste des moyens de paiement supprimés',
    component: () => import('./MoyenPaiementsList.vue'),
    meta: {
      acl: "/devis-facture/moyen-paiements/deleted"
    }
  },
  //  Points de facturation
  {
    path: '/devis-facture/point-facturations',
    name: 'Gestion des points de facturation',
    component: () => import('./PointFacturationsList.vue'),
    meta: {
      acl: "/devis-facture/point-facturations"
    }
  },
  {
    path: '/devis-facture/point-facturations/list',
    name: 'Liste des points de facturation',
    component: () => import('./PointFacturationsList.vue'),
    meta: {
      acl: "/devis-facture/point-facturations/list"
    }
  },
  {
    path: '/devis-facture/point-facturations/add',
    name: 'Ajouter un point de facturation',
    component: () => import('./PointFacturationForm.vue'),
    meta: {
      acl: "/devis-facture/point-facturations/add"
    }
  },
  {
    path: '/devis-facture/point-facturations/edit/:id',
    name: 'Editer un point de facturation',
    component: () => import('./PointFacturationForm.vue'),
    meta: {
      acl: "/devis-facture/point-facturations/edit"
    }
  },
  {
    path: '/devis-facture/point-facturations/view/:id',
    name: 'Visualiser un point de facturation',
    component: () => import('./PointFacturationView.vue'),
    meta: {
      acl: "/devis-facture/point-facturations/view"
    }
  },
  {
    path: '/devis-facture/point-facturations/deleted',
    name: 'Liste des points de facturation supprimés',
    component: () => import('./PointFacturationsList.vue'),
    meta: {
      acl: "/devis-facture/point-facturations/deleted"
    }
  },
  {
    path: '/devis-facture/point-facturations/selector',
    name: 'Sélection d\'un point de facturation',
    component: () => import('./PointFacturationSelector.vue'),
    meta: {
      acl: "/devis-facture/point-facturations/selector"
    }
  },
  //  Réglement client
  {
    path: '/devis-facture/reglement-clients',
    name: 'Gestion des règlements client',
    component: () => import('./ReglementClientsList.vue'),
    meta: {
      acl: "/devis-facture/reglement-clients"
    }
  },
  {
    path: '/devis-facture/reglement-clients/list',
    name: 'Liste des règlements client',
    component: () => import('./ReglementClientsList.vue'),
    meta: {
      acl: "/devis-facture/reglement-clients/list"
    }
  },
  {
    path: '/devis-facture/reglement-clients/add',
    name: 'Ajouter un règlement client',
    component: () => import('./ReglementClientForm.vue'),
    meta: {
      acl: "/devis-facture/reglement-clients/add"
    }
  },
  {
    path: '/devis-facture/reglement-clients/edit/:id',
    name: 'Editer un règlement client',
    component: () => import('./ReglementClientForm.vue'),
    meta: {
      acl: "/devis-facture/reglement-clients/edit"
    }
  },
  {
    path: '/devis-facture/reglement-clients/view/:id',
    name: 'Visualiser un règlement client',
    component: () => import('./ReglementClientView.vue'),
    meta: {
      acl: "/devis-facture/reglement-clients/view"
    }
  },
  {
    path: '/devis-facture/reglement-clients/deleted',
    name: 'Liste des règlements client supprimés',
    component: () => import('./ReglementClientsList.vue'),
    meta: {
      acl: "/devis-facture/reglement-clients/deleted"
    }
  },

  //  Réglement fournisseur
  {
    path: '/devis-facture/reglement-fournisseurs',
    name: 'Gestion des règlements fournisseur',
    component: () => import('./ReglementFournisseursList.vue'),
    meta: {
      acl: "/devis-facture/reglement-fournisseurs"
    }
  },
  {
    path: '/devis-facture/reglement-fournisseurs/list',
    name: 'Liste des règlements fournisseur',
    component: () => import('./ReglementFournisseursList.vue'),
    meta: {
      acl: "/devis-facture/reglement-fournisseurs/list"
    }
  },
  {
    path: '/devis-facture/reglement-fournisseurs/add',
    name: 'Ajouter un règlement fournisseur',
    component: () => import('./ReglementFournisseurForm.vue'),
    meta: {
      acl: "/devis-facture/reglement-fournisseurs/add"
    }
  },
  {
    path: '/devis-facture/reglement-fournisseurs/edit/:id',
    name: 'Editer un règlement fournisseur',
    component: () => import('./ReglementFournisseurForm.vue'),
    meta: {
      acl: "/devis-facture/reglement-fournisseurs/edit"
    }
  },
  {
    path: '/devis-facture/reglement-fournisseurs/view/:id',
    name: 'Visualiser un règlement fournisseur',
    component: () => import('./ReglementFournisseurView.vue'),
    meta: {
      acl: "/devis-facture/reglement-fournisseurs/view"
    }
  },
  {
    path: '/devis-facture/reglement-fournisseurs/deleted',
    name: 'Liste des règlements fournisseur supprimés',
    component: () => import('./ReglementFournisseursList.vue'),
    meta: {
      acl: "/devis-facture/reglement-fournisseurs/deleted"
    }
  },

  //  Services
  {
    path: '/devis-facture/services',
    name: 'Gestion des services',
    component: () => import('./ServicesList.vue'),
    meta: {
      acl: "/devis-facture/services"
    }
  },
  {
    path: '/devis-facture/services/list',
    name: 'Liste des services',
    component: () => import('./ServicesList.vue'),
    meta: {
      acl: "/devis-facture/services/list"
    }
  },
  {
    path: '/devis-facture/services/add',
    name: 'Ajouter un service',
    component: () => import('./ServiceForm.vue'),
    meta: {
      acl: "/devis-facture/services/add"
    }
  },
  {
    path: '/devis-facture/services/edit/:id',
    name: 'Editer un service',
    component: () => import('./ServiceForm.vue'),
    meta: {
      acl: "/devis-facture/services/edit"
    }
  },
  {
    path: '/devis-facture/services/view/:id',
    name: 'Visualiser un service',
    component: () => import('./ServiceView.vue'),
    meta: {
      acl: "/devis-facture/services/view"
    }
  },
  {
    path: '/devis-facture/services/deleted',
    name: 'Liste des services supprimés',
    component: () => import('./ServicesList.vue'),
    meta: {
      acl: "/devis-facture/services/deleted"
    }
  }
]
