<template>
  <div style="position: relative">
    <v-card
      v-if="showSlot"
      :style="slotStyle"
      :height="cardHeight"
      :width="cardWidth"
      flat
    >
      <slot name="card-content"></slot>
    </v-card>

    <GmapMap
      ref="map"
      :center="this.center"
      style="width: 100%; height: 60vh; margin: 0; padding: 0; overflow: hidden;"
      :zoom="10"
      map-type-id="roadmap"
      :options="{
        disableDefaultUI: true,
        zoomControl: true,
      }"
      @dragstart="showSlot = false"
      @zoom_changed="showSlot = false"
      @click="showSlot = false"
      @tilesloaded="fitMap"
    >
      <template v-if="google">
        <GmapCluster :zoomOnClick="true" :maxZoom="18">
          <template v-for="(adresse, index) in adresses">
            <GmapMarker
              v-if="adresse.latitude && adresse.longitude"
              :key="index"
              :ref="'map' + index"
              :position="{
                lat: parseFloat(adresse.latitude),
                lng: parseFloat(adresse.longitude),
              }"
              :icon="
                adresse.icon
                  ? {
                      url: adresse.icon,
                      scaledSize: new google.maps.Size(44, 44),
                    }
                  : null
              "
              @click="clickOnMarker(index, $event)"
            >
            </GmapMarker>
          </template>
        </GmapCluster>
      </template>
    </GmapMap>
  </div>
</template>

<script>
import { gmapApi } from "vue2-google-maps";
import GmapCluster from "vue2-google-maps/dist/components/cluster";

export default {
  name: "AdressePostalesMap",

  components: {
    GmapCluster,
  },

  props: {
    adresses: [],
    geolocation: { type: Boolean, default: false }
  },

  data: () => ({
    //Coordonnées par défaut à Paris
    center: {
      lat: 48.866669,
      lng: 2.33333,
    },
    mapHeight: "",
    mapWidth: "",
    cardHeight: 60,
    cardWidth: 275,
    x: 0,
    y: 0,
    showSlot: false,
    currentBounds: null,
    firstTilesLoad: false
  }),

  computed: {
    // https://www.npmjs.com/package/vue2-google-maps
    // Instance de la librairie Google
    google: gmapApi,

    slotStyle() {
      // Calcul le style des cards, positionnement auto selon emplacement sur la carte
      let mapHeight = this.$refs.map.$el.clientHeight;
      let mapWidth = this.$refs.map.$el.clientWidth;
      let positionTop = 0;
      let positionLeft = 0;

      //Si dépasse par le haut
      if (this.y - this.cardHeight - 40 < -(mapHeight / 2)) {
        positionTop = mapHeight / 2 + this.y + 8;
      } else {
        positionTop = mapHeight / 2 + this.y - this.cardHeight - 48;
      }

      //Si dépasse par la droite
      if (this.x + this.cardWidth / 2 > mapWidth / 2) {
        positionLeft = mapWidth / 2 + this.x - this.cardWidth;
      }
      // Si dépasse par la gauche
      else if (this.x - this.cardWidth < -(mapWidth / 2)) {
        positionLeft = mapWidth / 2 + this.x;
      } else {
        positionLeft = mapWidth / 2 + this.x - this.cardWidth / 2;
      }

      let style = {
        "background-color": "transparent",
        position: "absolute",
        "z-index": "1",
        top: positionTop + "px",
        left: positionLeft + "px",
      };

      return style;
    },
  },

  methods: {
    clickOnMarker(index, event) {
      this.x = event.pixel.x;
      this.y = event.pixel.y;
      this.$emit("clickedMarker", index);
      this.showSlot = true;
    },
    
    //Fonction qui permet d'espacer les marqueurs qui seraient au même endroit afin de les différencier et les rendre cliquable
    deplaceSameAdresses() {
      let coordsLat = [];
      let coordsLong = [];
      for (let [i, adresse] of this.adresses.entries()) {
        for (let [j, coordLat] of coordsLat.entries()) {
          if (adresse.latitude && adresse.longitude) {
            if (adresse.latitude == coordLat) {
              if (adresse.longitude == coordsLong[j]) {
                adresse.latitude = parseFloat(adresse.latitude) + j * 0.00001;
                adresse.longitude = parseFloat(adresse.longitude) + j * 0.00001;
              }
            }
          }
        }
        if (adresse.latitude && adresse.longitude) {
          coordsLat[i] = adresse.latitude;
          coordsLong[i] = adresse.longitude;
        }
      }
    },

    // Fonction qui permet de centrer et placer la carte selon les marqueurs présents dans le cluster ou selon la localisation de l'utilisateur
    async fitMap() {
      if(this.google && !this.firstTilesLoad) {
        this.firstTilesLoad = true;

        if(this.geolocation && ("geolocation" in navigator)) {
          navigator.geolocation.getCurrentPosition((position) => {
            this.center = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
          });

          await this.getCurrentBounds();

          this.$emit("currentBounds", this.currentBounds)
        }else {
          const bounds = new this.google.maps.LatLngBounds();

          let locations = this.adresses.map((adresse) => {
            if (adresse.latitude && adresse.longitude) {
              return {
                lat: parseFloat(adresse.latitude),
                lng: parseFloat(adresse.longitude),
              };
            }
          });

          locations = locations.filter((location) => location != undefined);
          locations.map((location) => {
            bounds.extend(location);
          });

          this.$refs.map.$mapPromise.then((map) => {
            map.fitBounds(bounds);
          });
        }
      }
    },

    /**
     * Permet de récupérer et stocker les coordonnées des bords de la map
     */
    async getCurrentBounds() {
      await this.$refs.map.$mapPromise.then((map) => {
        const bds = map.getBounds();
        const South_Lat = bds.getSouthWest().lat();
        const South_Lng = bds.getSouthWest().lng();
        const North_Lat = bds.getNorthEast().lat();
        const North_Lng = bds.getNorthEast().lng();

        this.currentBounds = {
          bottomLeft: {
            lat: South_Lat,
            lng: South_Lng,
          },
          topRight: {
            lat: North_Lat,
            lng: North_Lng,
          },
        };
      });
    }
  },

  watch: {
    adresses: function () {
      this.deplaceSameAdresses();
    },
  },
};
</script>