<template>
  <ViewLayout :tabs="tabs" :item="modele">
    <template v-slot:modele>
      <ModeleActions :object="modele" :withView="false" size="xl" />
      <v-row class="align-center mx-auto">
        <v-col cols="12">
          <ModeleObject size="xl" :modeleProp="modele" />
        </v-col>
        <v-col cols="12">
          <AssetsList :modeleID="modele.id" v-if="modele.id" :noFilter="true" />
        </v-col>
      </v-row>
    </template>
  </ViewLayout>
</template>

<script>
import { QUERY_modele } from "./graphql/queries";
import ModeleObject from "./ModeleObject.vue";
import ModeleActions from "./ModeleActions.vue";
import AssetsList from "./AssetsList.vue";
import ViewLayout from "@/components/COMPONENTS-skeleton/Layouts/ViewLayout.vue";

export default {
  name: "ModeleView",

  components: {
    ModeleObject,
    ModeleActions,
    AssetsList,
    ViewLayout,
  },
  data: () => ({
    modele: {},

    tabs: [
      { icon: "mdi-barcode-scan", displayName: "Modele", name: "modele" },
      { name: "commentaires" },
      { name: "fichiers" },
    ],
  }),

  apollo: {
    modele: {
      query: QUERY_modele,
      variables() {
        let idQuery = this.showPopup
          ? this.id
          : this.$route.params.id
          ? this.$route.params.id
          : this.id;
        return {
          id: idQuery,
        };
      },
    },
  },
  props: {
    showPopup: {
      type: Boolean,
    },
    id: {
      type: String,
    },
  },
};
</script>