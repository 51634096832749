<template>
  <v-container>
    <p class="text-center" v-if="!items[0]">Il n'y a pas de fichier associé</p>
    <v-treeview dense :items="items" item-key="name" open-on-click>
      <template v-slot:prepend="{ item, open }">
        <template v-if="item.children">
          <v-badge color="primary" :content="item.children.length" class="mr-4">
            <v-icon>
              {{ open ? "mdi-folder-open" : "mdi-folder" }}
            </v-icon>
          </v-badge>
        </template>

        <v-icon v-else @click="getFileId(item.id)">
          {{
            extensions[item.extension]
              ? extensions[item.extension]
              : "mdi-file-question-outline"
          }}
        </v-icon>
      </template>
      <template slot="label" slot-scope="{ item }">
        <template v-if="item.recent && !item.newFile">
          <v-badge color="info" content="Récent" inline>
            {{ item.name }}{{ item.extension ? "." + item.extension : "" }}
          </v-badge>
        </template>

        <template v-else-if="item.newFile">
          <v-badge color="success" content="Nouveau" inline>
            {{ item.name }}{{ item.extension ? "." + item.extension : "" }}
          </v-badge>
        </template>

        <template v-else>
          {{ item.name }}{{ item.extension ? "." + item.extension : "" }}
        </template>
      </template>
      <template slot="append" slot-scope="{ item }">
        <template v-if="!item.children">
          <v-btn v-if="item.extension == 'pdf'" icon @click="viewPDF(item)">
            <v-icon>mdi-eye</v-icon>
          </v-btn>
          <v-btn icon @click="favoriteItem(item)">
            <v-icon :color="item.favorite ? 'primary' : null">mdi-star</v-icon>
          </v-btn>
          <v-btn icon @click="fileDownload(item)">
            <v-icon>mdi-download</v-icon>
          </v-btn>
          <v-btn icon @click="deleteItem(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </template>
    </v-treeview>
  </v-container>
</template>

<script>
import _ from "lodash";
import dayjs from "dayjs";

import {
  MUTATION_downloadURLCreate,
  MUTATION_fichierDelete,
  MUTATION_fichierMetaFavorite,
} from "./graphql/mutations";
import { QUERY_fichiers } from "./graphql/queries";

export default {
  name: "TypeFichiersList",

  components: {
  },

  props: {
    customSearch: {
      type: Array,
      default() {
        return [];
      },
    },
    useCategory: { type: Boolean, default: true },
  },

  data: () => ({
    active: [],
    items: [],
    fichiers: [],
    isInit: false,
    extensions: {
      bmp: "mdi-file-image",
      doc: "mdi-file-word",
      docx: "mdi-file-word",
      html: "mdi-language-html5",
      jpg: "mdi-file-image",
      jpeg: "mdi-file-image",
      js: "mdi-nodejs",
      json: "mdi-code-json",
      md: "mdi-language-markdown",
      pdf: "mdi-file-pdf-box",
      png: "mdi-file-image",
      txt: "mdi-file-document-outline",
      xls: "mdi-file-excel",
      xlsx: "mdi-file-excel",
    },
    currentFile: {}
  }),

  computed: {
    meta() {
      let typeParent = undefined;
      let template = undefined;
      let idParent = undefined;

      for (let search of this.customSearch) {
        if (search["meta.typeParent"]) {
          typeParent = search["meta.typeParent"];
        }
        if (search["meta.template"]) {
          template = search["meta.template"];
        }
        if (search["meta.idParent"]) {
          idParent = search["meta.idParent"];
        }
      }
      return {
        typeParent,
        template,
        idParent,
      };
    },
  },

  created() {
    this.$root.$on("fichiersStockagesUpdateFichiers", this.updateQuery);
    this.$root.$on("deleteFile", this.deleteFile);
  },

  beforeDestroy() {
    this.$root.$off("fichiersStockagesUpdateFichiers", this.updateQuery);
    this.$root.$off("deleteFile", this.deleteFile);
  },

  mounted() {
    this.init();
  },

  watch: {
    fichiers: function () {
      this.useCategory
        ? this.orderFilesWithCategory()
        : this.orderFilesWithoutCategory();
    },
  },

  methods: {
    deleteItem(item) {
      let toValid = {
        titre: "Suppression d'un fichier",
        texte: `Êtes-vous sûr de vouloir supprimer le fichier ${item.name} ?`,
        type: "warning",
        toReturn: {
          function: "deleteFile",
          arg: item.id,
        },
      };
      this.$root.$emit("popupValidation", toValid);
    },

    deleteFile(id) {
      this.$apollo
        .mutate({
          mutation: MUTATION_fichierDelete,
          variables: { id: id },
        })
        .then(() => {
          this.$root.$emit("fichiersStockagesUpdateFichiers");
        })
        .catch((e) => {
          console.error(e);
        });
    },

    getFileId(id) {
      this.$emit("idFileClicked", id);
    },

    async getFileUrl(id) {
      let res = await this.$apollo
        .mutate({
          mutation: MUTATION_downloadURLCreate,
          variables: {
            id,
          },
          fetchPolicy: 'no-cache'
        });
      
      return res.data.downloadURLCreate.url;
    },

    favoriteItem: function (item) {
      this.$apollo
        .mutate({
          mutation: MUTATION_fichierMetaFavorite,
          variables: {
            input: {
              idFichier: item.id,
              customSearch: this.customSearch,
            },
          },
        })
        .then(() => {
          this.$root.$emit("fichiersStockagesUpdateFichiers");
        })
        .catch((e) => {
          console.error(e);
        });
    },

    fileDownload: async function (item) {
      let fileUrl = await this.getFileUrl(item.id);
      //window.location.assign(fileUrl);
      let link = document.createElement("a"); //create 'a' element
      link.setAttribute("href", fileUrl); //replace "file" with link to file you want to download
      link.setAttribute("target", "_blank");
      link.setAttribute("download", fileUrl); // replace "file" here too
      link.click(); //virtually click <a> element to initiate download
    },

    init() {
      this.$apollo
        .query({
          query: QUERY_fichiers,
          variables: {
            pagination: {
              limit: 0,
              filter: {
                customSearch: this.customSearch,
              },
            },
          },
          fetchPolicy: 'no-cache'
        })
        .then(__ => {
          const data = __.data.fichiers;
          this.fichiers = data;

          if(!this.isInit) {
            this.isInit = true;
            this.init();
          }
        })
    },

    orderFilesWithCategory: function () {
      let items = [];
      this.fichiers.forEach((file) => {
        let item = {
          id: file.id,
          name: file.displayName,
          extension: file.extension,
          favorite:
            file.meta && file.meta.favorite ? file.meta.favorite : false,
          // On considère un fichier comme "récent s'il a moins de 24h"
          recent: dayjs().isBefore(dayjs(file.dateCreation).add(1, "day")),
          // On considère un fichier comme "récent s'il a moins de 10minutes"
          newFile: dayjs().isBefore(
            dayjs(file.dateCreation).add(10, "minutes")
          ),
        };
        file.meta.forEach((meta) => {
          if (meta.typeParent == this.meta.typeParent) {
            if (
              (this.meta.template && meta.template == this.meta.template) ||
              (this.meta.idParent && meta.idParent == this.meta.idParent)
            ) {
              item.favorite = meta.favorite ? meta.favorite : false;
              if (meta.categorie) {
                let indexOf = _.findIndex(items, { name: meta.categorie });
                if (indexOf < 0) {
                  let folder = {
                    name: meta.categorie,
                    children: [item],
                  };
                  items.push(folder);
                } else {
                  items[indexOf].children.push(item);
                }
              } else {
                items.push(item);
              }
            }
          }
        });
      });

      items = _.orderBy(items, ["name"], "asc");
      this.items = items;
    },

    orderFilesWithoutCategory() {
       
      let items = [];

      this.fichiers.forEach((file) => {
        let item = {
          id: file.id,
          name: file.displayName,
          extension: file.extension,
        };

        file.meta.forEach((meta) => {
          if (meta.typeParent == this.meta.typeParent) {
            if (
              (this.meta.template && meta.template == this.meta.template) ||
              (this.meta.idParent && meta.idParent == this.meta.idParent)
            ) {
              item.favorite = meta.favorite ? meta.favorite : false;
            }
          }
        });

        items.push(item);
      });

      items = _.orderBy(items, ["name"], "asc");
      this.items = items;
    },

    async setCurrentFile(item) {
      let url = await this.getFileUrl(item.id);
      this.currentFile = {
        url,
        id: item.id,
        extension: item.extension
      }
    },

    async viewPDF(item) {
      await this.setCurrentFile(item);
      console.log(this.currentFile)
      this.$router.push({ path: `/fichiers-stockages/fichiers/pdf/view?popup=true&value=${this.currentFile.id}` });
    },

    updateQuery: function () {
      this.init();
    },
  },
};
</script>

