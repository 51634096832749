<template>
  <FormLayout
    icon="mdi-cash-fast"
    @ajouter="ajouter"
    @modifier="modifier"
    @reinitialiser="init"
    :disposition="disposition"
    :hasResetButton="hasResetButton"
    typeItem="un moyen de paiement"
    :item="input"
    :disabled="
      input.type == 'PRELEVEMENT_BANCAIRE' && !input.compteBancaire.iban
        ? true
        : false
    "
  >
    <TitleLayout titre="Informations administratives" />
    <v-row>
      <v-col>
        <PointFacturationSelector
          v-model="input.pointFacturation"
          label="Point de facturation associé"
          :disabled="pointFacturation ? true : false"
        />
      </v-col>
      <v-col>
        <v-select
          v-model="input.type"
          :items="typeMoyenPaiement"
          label="Type de moyen de paiement"
          :error-messages="formsErrors.moyenPaiementType"
        />
      </v-col>
    </v-row>
    <template v-if="input.type == 'PRELEVEMENT_BANCAIRE'">
      <TitleLayout titre="Informations bancaires" />
      <v-row>
        <v-col>
          <v-text-field
            prepend-icon="mdi-account"
            v-model="input.compteBancaire.titulaire"
            label="Titulaire du compte"
          />
        </v-col>
        <v-col>
          <v-text-field
            prepend-icon="mdi-office-building-marker"
            v-model="input.compteBancaire.domiciliation"
            label="Domiciliation"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="input.compteBancaire.iban"
            label="IBAN*"
            prepend-icon="mdi-contain"
            v-mask="'CC## BBBB BBBB BBBB BBBB BBBB BBB'"
            @input="generateRIB"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            prepend-icon="mdi-contain"
            v-model="input.compteBancaire.bic"
            label="Code BIC"
          />
        </v-col>
        <v-col>
          <v-text-field
            prepend-icon="mdi-contain"
            v-model="input.compteBancaire.rib"
            label="RIB"
            :disabled="true"
          />
        </v-col>
      </v-row>
    </template>
  </FormLayout>
</template>
<script>
import { mapState } from "vuex";

import {
  MUTATION_moyenPaiementCreate,
  MUTATION_moyenPaiementUpdate,
} from "./graphql/mutations";
import { QUERY_moyenPaiement } from "./graphql/queries";

import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";
import PointFacturationSelector from "./PointFacturationSelector.vue";
import TitleLayout from "@/components/COMPONENTS-skeleton/Layouts/TitleLayout.vue";

export default {
  name: "MoyenPaiementForm",

  components: {
    FormLayout,
    PointFacturationSelector,
    TitleLayout,
  },

  data: () => ({
    input: {},
    typeMoyenPaiement: [
      {
        text: "Prélévement bancaire",
        value: "PRELEVEMENT_BANCAIRE",
      },
    ],
    moyenPaiement: {
      id: undefined,
      type: "PRELEVEMENT_BANCAIRE",
      compteBancaire: {},
    },
  }),

  props: {
    componentParent: undefined,
    disposition: { type: String, default: "default" },
    hasResetButton: { type: Boolean, default: true },
    pointFacturation: undefined,
  },

  computed: {
    ...mapState(["formsErrors"]),
  },

  mounted: function () {
    this.init();
  },

  methods: {
    ajouter() {
      let toSend = this.inputConverter(this.input);
      console.log(toSend)
      this.$apollo
        .mutate({
          mutation: MUTATION_moyenPaiementCreate,
          variables: {
            input: toSend,
          },
        })
        .then((__) => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "Votre moyen de paiement a été ajouté",
          });
          if (this.componentParent) {
            this.$root.$emit(this.componentParent, __.data.moyenPaiementCreate);
          }
          this.$root.$emit("skeleton--close");
          this.init();
        });
    },

    generateRIB() {
      let iban = this.input.compteBancaire.iban.replace(/\s/g, "");

      this.input.compteBancaire.rib = iban.slice(4, 32);
    },

    init() {
      if (
        this.$route.path.startsWith("/devis-facture/moyen-paiements/edit/") &&
        this.$route.params.id
      ) {
        let id = this.$route.params.id;
        this.$apollo
          .query({
            query: QUERY_moyenPaiement,
            variables: {
              id: id,
            },
          })
          .then((data) => {
            let moyenPaiement = data.data.moyenPaiement;
            this.input = JSON.parse(JSON.stringify(moyenPaiement));
          });
      } else {
        if (this.pointFacturation) {
          this.moyenPaiement.pointFacturation = this.pointFacturation;
        }
        this.input = JSON.parse(JSON.stringify(this.moyenPaiement));
      }
    },

    inputConverter(arg) {
      const obj = JSON.parse(JSON.stringify(arg));

      let input = {
        type: obj.type,
        pointFacturation:
          obj.pointFacturation && obj.pointFacturation.id
            ? obj.pointFacturation.id
            : obj.pointFacturation,
        compteBancaire: {
          titulaire: obj.compteBancaire.titulaire,
          domiciliation: obj.compteBancaire.domiciliation,
          iban: obj.compteBancaire.iban.replace(/\s/g, ""),
          bic: obj.compteBancaire.bic,
          rib: obj.compteBancaire.rib,
        },
      };

      obj.id != undefined ? (input.id = obj.id) : null;

      return input;
    },

    modifier() {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_moyenPaiementUpdate,
          variables: {
            input: toSend,
          },
        })
        .then(() => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "Votre moyen de paiement a été modifié",
          });
          this.$root.$emit("skeleton--close");
        });
    },
  },
};
</script>