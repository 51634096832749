<template>
  <ListLayout ref="list" :headers="headers" @fetch="init">
     <template v-slot:[`item.object`]="{ item }">
      <EmailObject :emailProp="item" size="xs"/>
    </template>
     <template v-slot:[`item.subject`]="{ item }">
      {{ item.subject }}
    </template>
    <template v-slot:[`item.sender`]="{ item }">
      {{ item.sender.email }}
    </template>
    <template v-slot:[`item.to`]="{ item }">
      {{ displayTo(item.to) }}
    </template>
    <template v-slot:[`item.dateEnvoi`]="{ item }">
      {{ item.dateEnvoi | shortDateAndHourFR }}
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <EmailActions :object="item" :fetch="fetchEmails" size="xs" />
    </template>
  </ListLayout>
</template>

<script>
import ListLayout from "@/components/COMPONENTS-skeleton/Layouts/ListLayout.vue";
import EmailActions from "./EmailActions.vue";
import { QUERY_emails, QUERY_envoiNotificationsOrganisationParametres } from "./graphql/queries";
import EmailObject from "./EmailObject.vue";
export default {
  name:"EmailsList",

  components: {
    ListLayout,
    EmailActions,
    EmailObject,
  },

  data: () => ({
    headers: [
      { text: "", value: "object", sortable: false },
      { text: "Objet", value: "subject" },
      { text: "Expéditeur", value: "sender" },
      { text: "Destinataire(s)", value: "to" },
      { text: "Date d'envoi", value: "dateEnvoi" },
      { text: "Actions", value: "actions", sortable: false },
    ],

    customFilters: {
      providerName: ""
    },
  }),

  methods: {
    displayTo(to) {
      let emails = to.map(elem => elem.email);
      let tos = emails.join(', ');
      let display = (to.length > 1) ? tos.substring(0, 30) + ' ...' : tos;

      return display;
    },

    async fetchEmails() {
      await this.fetchOrgParams();

      this.$refs.list.loading = true;
      let pagination = JSON.parse(JSON.stringify(this.$refs.list.pagination));

      if (this.customFilters.providerName != "") {
        pagination.filter.customSearch.push({
          providerName: this.customFilters.providerName
        });
      }
      
      this.$apollo
        .query({
          query: QUERY_emails,
          variables: {
            pagination: pagination
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          this.$refs.list.items = data.emails;
          this.$refs.list.itemsCount = data.emailsCount;
          this.$refs.list.loading = false;
        });
    },

    async fetchOrgParams() {
      let res = await this.$apollo.query({
          query: QUERY_envoiNotificationsOrganisationParametres
        });
      
      this.customFilters.providerName = res.data.envoiNotificationsOrganisationParametres.providerName;
    },

    init() {
      this.fetchEmails();
    },
  },

 watch: {
  customFilters: {
    deep: true,
    handler() {
      this.$refs.list.currentPage = 1;
      this.init();
    },
  },
 }
}
</script>

<style>

</style>