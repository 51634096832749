<template>
  <div>
    <template v-if="entreprise">
      <!-- SIZE XL -->
      <template v-if="size == 'xl'">
        <ObjectLayout size="xl" :item="entreprise">
          <template v-slot:icon>mdi-domain</template>
          <template v-slot:titre> {{ entreprise.raisonSociale }}</template>
          <template v-slot:body>
            <ObjectLayout v-if="entreprise.raisonSociale">
              <template v-slot:titre>Raison Sociale</template>
              <template v-slot:content>
                {{ entreprise.raisonSociale }}
              </template>
            </ObjectLayout>
            <ObjectLayout v-if="entreprise.nomCommercial">
              <template v-slot:titre> Nom commercial </template>
              <template v-slot:content>
                {{ entreprise.nomCommercial }}
              </template>
            </ObjectLayout>
            <ObjectLayout v-if="entreprise.siren">
              <template v-slot:titre>Numéro de SIREN</template>
              <template v-slot:content>
                {{ entreprise.siren }}
              </template>
            </ObjectLayout>
            <ObjectLayout v-if="entreprise.numeroTvaIntra">
              <template v-slot:titre>Numéro de TVA intracommunautaire</template>
              <template v-slot:content>
                {{ entreprise.numeroTvaIntra }}
              </template>
            </ObjectLayout>
            <ObjectLayout v-if="entreprise.capital">
              <template v-slot:titre>Capital social</template>
              <template v-slot:content>
                {{ entreprise.capital | monetaire }}
              </template>
            </ObjectLayout>
            <ObjectLayout v-if="entreprise.villeRCS">
              <template v-slot:titre>Enregistrée au R.C.S. de</template>
              <template v-slot:content>
                {{ entreprise.villeRCS }}
              </template>
            </ObjectLayout>
            <ObjectLayout v-if="entreprise.codeNaf && (entreprise.codeNaf.code || entreprise.codeNaf.libelle)">
              <template v-slot:titre>Code Naf</template>
              <template v-slot:content>
                <span v-if="entreprise.codeNaf.code">{{entreprise.codeNaf.code}}</span>
                <span v-if="entreprise.codeNaf.code && entreprise.codeNaf.libelle"> : </span>
                <span v-if="entreprise.codeNaf.libelle">{{entreprise.codeNaf.libelle}}</span>
              </template>
            </ObjectLayout>
            <ObjectLayout>
              <template v-slot:titre>Etablissements</template>
              <template v-slot:content>
                {{ entreprise.nbEtablissements }}
              </template>
            </ObjectLayout>
            <ObjectLayout v-if="entreprise.holding && entreprise.holding.id">
              <template v-slot:titre>Entreprise mère</template>
              <template v-slot:content>
                <EntrepriseObject :entrepriseID="entreprise.holding.id" size="xs" />
              </template>
            </ObjectLayout>
          </template>
          <template v-slot:body2>
            <div
              class="d-flex justify-center align-center"
              style="width: 100%; height: 220px"
            >
              <FichierLogo
                size="128"
                v-model="entreprise.logo"
                :text="entreprise.raisonSociale"
                :justDisplay="true"
              />
            </div>
          </template>
        </ObjectLayout>
      </template>
      <!--  SIZE LG -->
      <template v-if="size == 'lg'">
        <ObjectLayout size="lg" :item="entreprise">
          <template v-slot:body>
            <div class="text-center">
              <FichierLogo
                size="120"
                v-model="entreprise.logo"
                :text="entreprise.raisonSociale"
                :justDisplay="true"
              />
            </div>
          </template>
          <template v-slot:body2>
            <v-list-item-content>
              <v-list-item-subtitle>Société</v-list-item-subtitle>
              <v-list-item-title
                class="font-weight-bold text-body-2"
                v-html="entreprise.raisonSociale"
              />
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-subtitle
                class="font-weight-bold"
                v-html="entreprise.siege"
              />
            </v-list-item-content>
          </template>
        </ObjectLayout>
        {{ entreprise.etablissements.libelle }}
      </template>
      <!--  SIZE MD -->
      <template v-if="size == 'md'">
        <v-row>
          <v-list-item-avatar tile>
            <FichierLogo
              size="32"
              v-model="entreprise.logo"
              :text="entreprise.raisonSociale"
              :justDisplay="true"
              class="mx-2"
            />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-html="entreprise.raisonSociale" />
            <v-list-item-subtitle v-html="entreprise.siren" />
          </v-list-item-content>
        </v-row>
      </template>
      <!--  SIZE XS -->
      <template v-if="size == 'xs' || size == 'xs-details'">
        <ObjectLayout
          :action="action"
          size="xs"
          :onClickPush="`/annuaire/entreprises/view/${entreprise.id}`"
        >
          <template v-slot:image>
            <FichierLogo
              size="14"
              v-model="entreprise.logo"
              :text="entreprise.raisonSociale"
              :justDisplay="true"
            />
          </template>

          <template v-slot:content>
            {{ entreprise.raisonSociale }}
          </template>
          <template v-slot:subcontent>{{ entreprise.siren }}</template>
          <template v-slot:extracontent v-if="size == 'xs-details'">
            <EtablissementObject
              :etablissementProp="entreprise.siege"
              size="xs-ville"
              action="popup"
            />
          </template>
          <template v-slot:tooltip v-if="tooltip">
            <template v-if="entreprise.siren">
              <b class="font-weight-bold">Siren:</b>
              {{ entreprise.siren }}
              <br />
            </template>
            <template v-if="entreprise.raisonSociale">
              <b class="font-weight-bold">Société:</b>
              {{ entreprise.raisonSociale }} <br />
            </template>
            <template v-if="entreprise.numeroTvaIntra">
              <b class="font-weight-bold">N° TVA:</b>
              {{ entreprise.numeroTvaIntra }}<br />
            </template>
            <template v-if="entreprise.nbEtablissements">
              <b class="font-weight-bold">Nombre établissement:</b>
              {{ entreprise.nbEtablissements }}
            </template>
          </template>
        </ObjectLayout>
      </template>
    </template>
  </div>
</template>

<script>
import { QUERY_entreprise } from "./graphql/queries";

import EtablissementObject from "./EtablissementObject.vue";
import FichierLogo from "@/components/COMPONENTS-fichiers-stockages/FichierLogo.vue";
import ObjectLayout from "@/components/COMPONENTS-skeleton/Layouts/ObjectLayout.vue";

export default {
  name: "EntrepriseObject",

  components: {
    FichierLogo,
    EtablissementObject,
    ObjectLayout,
  },

  props: {
    size: { type: String, default: "md" },
    entrepriseProp: {
      type: Object,
      default() {
        return {};
      },
    },
    entrepriseID: {
      type: String,
    },
    action: {
      type: String,
      default: "router",
    },

    tooltip: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    entreprise: undefined,
  }),

  mounted: function () {
    this.init();
  },

  watch: {
    entrepriseProp: function () {
      this.init();
    },
    entrepriseID: function () {
      this.init();
    },
  },

  methods: {
    init() {
      if (this.entrepriseID) {
        let id = this.entrepriseID;
        this.$apollo
          .query({
            query: QUERY_entreprise,
            variables: { id: id },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let entreprise = data.data.entreprise;
            this.entreprise = JSON.parse(JSON.stringify(entreprise));
          });
      } else {
        this.entreprise = JSON.parse(JSON.stringify(this.entrepriseProp));
      }
    },
  },
};
</script>