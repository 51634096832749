<template>
  <div>
    <v-row>
      <v-col align="center" justify="center">
        <div class="text-body1 text-xl-h6 text-lg-h6 text-md-h6 text-sm-h6">
          Capital à financer
        </div>
        <div
          class="
            text-h5 text-xl-h4 text-lg-h4 text-md-h4 text-sm-h4
            primary--text
          "
        >
          {{ capitalTotal | monetaire }} H.T.
        </div>
      </v-col>
      <v-col align="center" justify="center">
        <div class="text-body1 text-xl-h6 text-lg-h6 text-md-h6 text-sm-h6">
          T.V.A. à financer
        </div>
        <div
          class="
            text-h5 text-xl-h4 text-lg-h4 text-md-h4 text-sm-h4
            primary--text
          "
        >
          {{ tvaTotale | monetaire }}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col align="center" justify="center">
        <div class="text-h6 blue-grey--text py-2">
          Répartition du capital à financer
        </div>
        <v-data-table
          dense
          :headers="headersCapitaux"
          :items="capitaux"
          :hide-default-footer="true"
        >
          <template v-slot:[`item.client`]="{ item }">
            <ClientObject :clientID="item.client.id" size="xs" />
          </template>
          <template v-slot:[`item.capital`]="{ item }">
            {{ item.capital | monetaire }}
          </template>
          <template v-slot:[`item.tva`]="{ item }">
            {{ item.tva | monetaire }}
          </template>
          <template v-slot:[`item.pourcent`]="{ item }">
            <span class="font-weight-bold">
              {{ (item.capital / capitalTotal) | pourcent }}
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ClientObject from "@/components/COMPONENTS-devis-facture/ClientObject.vue";

export default {
  name: "FinancementCapitalAttach",

  components: {
    ClientObject,
  },

  props: {
    devisFournisseurs: [],
    factureFournisseurs: [],
  },

  data: () => ({
    headersCapitaux: [
      { text: "Client", value: "client", sortable: false },
      { text: "Capital", value: "capital", sortable: false },
      { text: "T.V.A.", value: "tva", sortable: false },
      { text: "%", value: "pourcent", sortable: false },
    ],
  }),

  computed: {
    // On génère un tableau des capitaux à financer par client
    capitaux() {
      let capitaux = [];
      // Pour chaque devis, on alimente le tableau de client
      if (this.devisFournisseurs) {
        this.devisFournisseurs.forEach((devis) => {
          let found = capitaux.find(
            (element) => element.client.id == devis.client.id
          );
          if (!found) {
            capitaux.push({ client: devis.client, capital: 0, tva: 0 });
          }
        });
        // On reboucle sur les devis pour alimenter le capital de chaque client
        // On en profite pour alimenter le montant à financer de chaque devis
        this.devisFournisseurs.forEach((devis) => {
          devis.montantAFinancer = 0;
          let found = capitaux.find(
            (element) => element.client.id == devis.client.id
          );
          // Pour chaque ligne du devis, on vérifie s'il faut la financer et on alimente le capital du client
          devis.lignes.forEach((ligne) => {
            if (ligne.meta.financement) {
              devis.montantAFinancer += ligne.quantite * ligne.prixUnitaire;
              found.capital += ligne.quantite * ligne.prixUnitaire;
              found.tva +=
                (ligne.quantite * ligne.prixUnitaire * ligne.tauxTva) / 100;
            }
          });
        });
        let totalCapital = 0;
        capitaux.forEach((elem) => {
          totalCapital += elem.capital;
        });
        capitaux.forEach((elem) => {
          elem.pourcent = (elem.capital / totalCapital) * 100;
        });
      }
      // Pour chaque facture, on alimente le tableau de client
      if (this.factureFournisseurs) {
        this.factureFournisseurs.forEach((facture) => {
          let found = capitaux.find(
            (element) => element.client.id == facture.client.id
          );
          if (!found) {
            capitaux.push({ client: facture.client, capital: 0, tva: 0 });
          }
        });
        // On reboucle sur les devis pour alimenter le capital de chaque client
        // On en profite pour alimenter le montant à financer de chaque devis
        this.factureFournisseurs.forEach((facture) => {
          facture.montantAFinancer = 0;
          let found = capitaux.find(
            (element) => element.client.id == facture.client.id
          );
          // Pour chaque ligne du devis, on vérifie s'il faut la financer et on alimente le capital du client
          facture.lignes.forEach((ligne) => {
            if (ligne.meta.financement) {
              facture.montantAFinancer += ligne.quantite * ligne.prixUnitaire;
              found.capital += ligne.quantite * ligne.prixUnitaire;
              found.tva +=
                (ligne.quantite * ligne.prixUnitaire * ligne.tauxTva) / 100;
            }
          });
        });
        let totalCapital = 0;
        capitaux.forEach((elem) => {
          totalCapital += elem.capital;
        });
        capitaux.forEach((elem) => {
          elem.pourcent = (elem.capital / totalCapital) * 100;
        });
      }

      this.$emit("capitaux", capitaux);
      return capitaux;
    },
    capitalTotal() {
      let capitalAfinancer = 0;
      this.capitaux.forEach((elem) => {
        capitalAfinancer += elem.capital;
      });
      this.$emit("capitalAfinancer", capitalAfinancer);
      return capitalAfinancer;
    },
    tvaTotale() {
      let tvaAFinancer = 0;
      this.capitaux.forEach((elem) => {
        tvaAFinancer += elem.tva;
      });
      return tvaAFinancer;
    },
  },
  methods: {},
  watch: {},
};
</script>