<template>
  <CounterLayout :compteur="devisFournisseursCount">
    <template v-slot:icon>mdi-file-cad</template>
  </CounterLayout>
</template>

<script>
import { QUERY_devisFournisseursCount } from "./graphql/queries";

import CounterLayout from "@/components/COMPONENTS-skeleton/Layouts/CounterLayout.vue";

export default {
  name: "DevisFournisseurCounter",

  components: {
    CounterLayout,
  },

  props: {
    customSearch: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data: () => ({}),

  apollo: {
    devisFournisseursCount: {
      query: QUERY_devisFournisseursCount,
      variables() {
        return {
          pagination: {
            filter: {
              customSearch: this.customSearch,
            },
          },
        };
      },
      fetchPolicy: "no-cache",
    },
  },

  created() {
    if (!this.$root._events.sateleaseUpdateDevis) {
      this.$root.$on("sateleaseUpdateDevis", this.updateQuery);
    }
  },

  mounted: function () {},

  methods: {
    updateQuery: function () {
      this.$apollo.queries.devisFournisseursCount.refetch();
    },
  },
};
</script>
