<template>
  <div v-if="!disableOauth">
    <p class="font-weight-light">
      Vous pouvez également vous connecter en utilisant :
    </p>
    <v-row>
      <v-col>
        <v-btn text color="success" :href="googleURL">
          <v-icon left> mdi-google </v-icon>
          Google
        </v-btn>
      </v-col>

      <v-col>
        <v-btn text color="warning" :href="officeURL">
          <v-icon left> mdi-microsoft-office </v-icon>
          Office365
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "OAuthLoginList",

  computed: {
    ...mapState(["env"]),
    disableOauth() {
      if (this.env["VUE_APP_OAUTH_DISABLED"] !== undefined) {
        return this.env["VUE_APP_OAUTH_DISABLED"];
      } else return false

    },
    // URL vers la page de consentement Google
    googleURL() {
      // Racine de l'URL
      const googleRootURL = "https://accounts.google.com/o/oauth2/v2/auth";
      const options = {
        // URL de redirection une fois connecté à un compte Google
        redirect_uri: this.env["VUE_APP_OAUTH_CALLBACK_GOOGLE"],
        // CLIENT_ID de l'appli Google
        client_id: this.env["VUE_APP_OAUTH_GOOGLE_CLIENT_ID"],
        access_type: "offline",
        response_type: "code",
        prompt: "consent",
        // Portée des informations à récupérer
        scope: [
          "https://www.googleapis.com/auth/userinfo.profile",
          "https://www.googleapis.com/auth/userinfo.email",
        ].join(" "),
      };

      // Permet d'ajouter les paramètres à l'URL de manière simple
      const qs = new URLSearchParams(options);

      return `${googleRootURL}?${qs.toString()}`;
    },

    // URL vers la page de consentement Office365
    officeURL() {
      // Racine de l'URL
      const officeRootURL =
        "https://login.microsoftonline.com/common/oauth2/v2.0/authorize";
      const options = {
        // Option obligatoire (cf. doc Microsoft)
        tenant: "common",
        redirect_uri: this.env["VUE_APP_OAUTH_CALLBACK_OFFICE"],
        client_id: this.env["VUE_APP_OAUTH_OFFICE_CLIENT_ID"],
        response_type: "code",
        response_mode: "query",
        prompt: "consent",
        scope: "https://graph.microsoft.com/email",
      };

      const qs = new URLSearchParams(options);

      return `${officeRootURL}?${qs.toString()}`;
    },
  },
};
</script>