import gql from 'graphql-tag';

export const EMAIL_FIELDS = gql`
  fragment EmailFields on Email {
    dateEnvoi
    events {
      name
      time
    }
    id
    providerId
    providerName
    sender {
      name
      email
    }
    subject
    to {
      name
      email
    }
  }  
`;

export const EMAIL_TEMPLATE_FIELDS = gql`
  fragment EmailTemplateFields on EmailTemplate {
    emailObject
    htmlContent
    id
    meta{
      categorie
      favorite
      typeParent
    }
    name
    systemEmailTemplate
  }
`;

export const ENVOI_NOTIFICATIONS_ORGANISATION_PARAMETRES_FIELDS = gql`
  fragment EnvoiNotificationsOrganisationParametresFields on EnvoiNotificationsOrganisationParametres {
    id
    providerKeyAPI
    providerName
    emailSend
  }
`;

export const TEMPLATE_FIELDS = gql`
  fragment TemplateFields on Template {
    dateCreation
    htmlContent 
    id 
    name 
    subject 
  }
`;