<template>
  <ListLayout
    ref="list"
    :headers="headers"
    @fetch="init"
    @showMultipleActions="showMultipleActions = $event"
  >
    <template v-slot:multipleActions>
      <ContratLocationActions
        :multiple="true"
        :object="$refs.list ? $refs.list.itemsSelected : []"
        size="xl"
        v-show="showMultipleActions"
        @fetch="init"
        @loading="$refs.list.loading = true"
      />
    </template>

    <template v-slot:[`item.object`]="{ item }">
      <ContratLocationObject :contratLocationProp="item" size="xs" />
    </template>
    <template v-slot:[`item.etat`]="{ item }">
      <template v-if="item.etat == 'ATTENTE_VALIDATION'">
        <v-chip class="ma-2" color="indigo" text-color="white">
          <v-avatar left>
            <v-icon>mdi-timer</v-icon>
          </v-avatar>
          En attente de validation
        </v-chip>
      </template>
      <template v-if="item.etat == 'ATTENTE_LIVRAISON'">
        <v-chip class="ma-2" color="teal" text-color="white">
          <v-avatar left>
            <v-icon>mdi-truck-delivery</v-icon>
          </v-avatar>
          En attente de livraison
        </v-chip>
      </template>
    </template>
    <template v-slot:[`item.dateCreation`]="{ item }">
      {{ item.dateCreation | shortDateFR }}
    </template>

    <template v-slot:[`item.datePrevInstallation`]="{ item }">
      {{ item.datePrevInstallation | shortDateFR }}
    </template>

    <template v-slot:[`item.datePrevLoyer`]="{ item }">
      {{ item.datePrevInstallation | shortDateFR }}
    </template>

    <template v-slot:[`item.locataire`]="{ item }">
      <ClientObject v-if="item.locataire && item.locataire.id" size="xs" action="popup" :clientID="item.locataire.id" />
    </template>

    <template v-slot:[`item.capitalAfinancer`]="{ item }">
      {{ item.financement.capitalAfinancer | monetaire }}
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <ContratLocationActions
        :object="item"
        size="xs"
        @fetch="init"
        @loading="$refs.list.loading = true"
      />
    </template>
  </ListLayout>
</template>

<script>
import { QUERY_contratLocations } from "./graphql/queries";

import ContratLocationActions from "./ContratLocationActions.vue";
import ContratLocationObject from "./ContratLocationObject.vue";
import ClientObject from "@/components/COMPONENTS-devis-facture/ClientObject.vue";
import ListLayout from "@/components/COMPONENTS-skeleton/Layouts/ListLayout.vue";

export default {
  name: "ContratLocationsList",

  components: {
    ContratLocationObject,
    ClientObject,
    ListLayout,
    ContratLocationActions,
  },

  data: () => ({
    headers: [
      { text: "", value: "object", sortable: false },
      { text: "Numéro de contrat", value: "numeroContrat" },
      { text: "Etat", value: "etat", sortable: false },
      { text: "Date de création", value: "dateCreation" },
      { text: "Locataire", value: "locataire", sortable: false },
      { text: "Capital à financer", value: "capitalAfinancer" },
      { text: "Fichiers", value: "fichiers" },
      { text: "Commentaires", value: "commentaires" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    search: null,
    showMultipleActions: false,
  }),

  methods: {
    fetchContratLocations() {
      this.$refs.list.loading = true;
      this.$apollo
        .query({
          query: QUERY_contratLocations,
          variables: {
            pagination: this.$refs.list.pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;

          this.$refs.list.items = data.contratLocations;
          this.$refs.list.itemsCount = data.contratLocationsCount;
          this.$refs.list.loading = false;
        });
    },

    init() {
      this.fetchContratLocations();
    },
  },
};
</script>