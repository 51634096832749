<template>
  <v-container>
    TemplateForm
  </v-container>
</template>

<script>
export default {
  name: "TemplateForm",
  
  data: () => ({}),

  mounted() {
    this.init();
  },

  methods: {
    init() {
      //TODO
    }
  }
}
</script>