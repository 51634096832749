import gql from 'graphql-tag';

export const QUERY_planComptable = gql`
  query {
    planComptable {
      classes{
        label
        numero
        comptes{
          numero
          label
          description
          systeme
          type
        }
      }
    }
}
`