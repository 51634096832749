<template>
  <div>
    <ActionLayout
      :actions="actions"
      :size="size"
      :action="action"
      @fetch="$emit('fetch')"
      :mainIcon="mainIcon"
    />
  </div>
</template>

<script>
import ActionLayout from "@/components/COMPONENTS-skeleton/Layouts/ActionLayout.vue";

import {
  MUTATION_assetEmplacementDelete,
  MUTATION_assetEmplacementUpdate,
} from "./graphql/mutations.js";

export default {
  name: "AssetEmplacementActions",
  components: {
    ActionLayout,
  },

  props: {
    withView: { type: Boolean, default: true },
    mainIcon: { type: String, default: "mdi-store" },
    object: { type: [Array, Object] },
    size: { type: String, default: "md" },
    action: { type: String, default: "router" },
    multiple: { type: Boolean, default: false },
  },

  computed: {
    actions() {
      return [
        {
          text: "Editer",
          icon: "mdi-pencil",
          path: "/assets/assetEmplacements/edit/" + this.object.id,
          acl: "/assets/assetEmplacements/edit",
          color: "",
          conditions: { multiple: false, isActif: true },
        },
        {
          text: "Supprimer",
          icon: "mdi-delete",
          method: "editIsActif",
          acl: "/assets/assetEmplacements/deleted",
          color: "error",
          conditions: { multiple: true, isActif: true },
        },
        {
          text: "Restauration",
          icon: "mdi-restore",
          method: "editIsActif",
          acl: "/assets/assetEmplacements/edit",
          color: "",
          conditions: { multiple: true, isActif: false },
        },
        {
          text: "Supprimer définitivement",
          icon: "mdi-delete",
          method: "delete",
          acl: "/assets/assetEmplacements/deleted",
          color: "",
          conditions: { multiple: true, isActif: false },
        },
        {
          text: "Visualiser",
          icon: "mdi-eye",
          path: "/assets/assetEmplacements/view/" + this.object.id,
          acl: "/assets/assetEmplacements/view",
          color: "",
          conditions: { size: "md xs" },
        },
      ];
    },
  },

  /**
   * Permet d'utiliser la popup de validation
   */
  created() {
    this.$root.$on("deleteAssetEmplacement", this.deleteAssetEmplacement);
    this.$root.$on("editAssetEmplacement", this.editAssetEmplacement);
  },

  methods: {
    /**
     * Supprimer une assetEmplacement grâce à son id
     */
    deleteAssetEmplacement(id) {
      this.apollo
        .mutate({
          mutation: MUTATION_assetEmplacementDelete,
          variables: { id: id },
        })
        .then(() => {
          this.emit("fetch");
        });
    },

    delete(obj) {
      // infos qui s'afficheront dans le popup
      let toValid = {
        titre: "Suppression définitive d'une assetEmplacement",
        texte: `Êtes-vous sûr de vouloir supprimer définitivement l'assetEmplacement ${obj.raisonSociale} ?`,
        type: "warning",
        toReturn: {
          function: "deleteAssetEmplacement", // cette fonction change en fonction du composant (ex: PersonneActions.vue ça serait deletePersonne,
          //qu'il faut créer à la place de deleteAssetEmplacement)
          arg: obj.id,
        },
      };

      // Envoi l'info pour ouvrir la popup
      this.root.emit("popupValidation", toValid);
    },

    async editAssetEmplacement(obj) {
      if (!Array.isArray(obj)) {
        obj = [obj];
      }
      this.emit("loading");
      for (let item of obj) {
        let assetEmplacement = {
          id: item.id,
          //Ajouter les champs nécessaires
        };
        await this.apollo.mutate({
          mutation: MUTATION_assetEmplacementUpdate,
          variables: {
            input: assetEmplacement,
          },
        });
      }
      this.emit("fetch");
    },

    editIsActif(obj) {
      // Si obj n'est pas un tableau alors on le transforme en tableau
      if (!Array.isArray(obj)) {
        obj = [obj];
      }
      let toValid = {
        titre: obj[0].isActif
          ? "Suppression d'une assetEmplacement"
          : "Restauration d'une assetEmplacement",
        texte: obj[0].isActif
          ? `Êtes-vous sûr de vouloir supprimer ${obj.length} assetEmplacement(s) ? ` +
            'Elle seront toujours présentes dans l\'onglet "Corbeille"'
          : `Êtes-vous sûr de vouloir restaurer ${obj.length} assetEmplacement(s) ?`,
        type: "warning",
        toReturn: {
          function: "editAssetEmplacement", // à changer selon le composant, comme deleteAssetEmplacement
          arg: obj,
        },
      };

      this.root.emit("popupValidation", toValid);
    },
  },
};
</script>