import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const ALERT_SHOW_TIME = 5000;


export default new Vuex.Store({
  state: {
    //alerts est un tableau qui contient l'ensemble des alertes retournées par l'applicatif, se vide automatiquement
    alerts: [],
    // dataNotSaved est un boolean qui indique si un formulaire est en cours et non sauvegardé
    dataNotSaved: null,
    //env est un tableau qui contient l'ensemble des variables d'environnement retournées par l'applicatif
    env: [],
    //formsErrors est une tableau qui contient l'ensemble des erreurs de formulaires remontées par l'applicatif
    formsErrors: [],
    // popup est un objet qui contient les informations de la popup (composant à charger, variables, etc)
    popups: [],
    // Filtre à appliquer sur les éléments de l'UI
    filter: null,
    // Couleur de base de l'app en clair (en hexadécimal)
    colorDefaultLight: null,
    // Couleur de base de l'app en foncé (en hexadécimal)
    colorDefaultDark: null
  },
  mutations: {
    // Ajoute une alerte s'afficant en haut du logiciel, son affichage est temporaire
    addAlert(state, { type, text }) {
      //On passe un timer à l'alerte afin d'avoir un index unique pour l'affichage (v-for)
      state.alerts.push({ showing: true, type: type, text: text, createdAt: Date.now() })
      // On masque l'alerte au bout de 5 secondes
      const last = state.alerts.length - 1
      setTimeout(function () { state.alerts[last].showing = false }, ALERT_SHOW_TIME ? ALERT_SHOW_TIME : 5000);

    },
    //Ajoute des erreurs de formulaire (attend un array)
    addFormsError(state, errors = []) {
      state.formsErrors = errors
    },
    //Ajoute un composant à un popup
    addPopup(state, { composantName, params }) {
      //On repasse les boolean en boolean (passage en string lors de la mise en URL)
      for (let [key, value] of Object.entries(params)) {
        if (value == "true") {
          params[key] = true
        }
        if (value == "false") {
          params[key] = false
        }
      }
      state.popups.push({ composant: composantName, params: params })
    },
    // Change la valeur de dataNoteSaved
    setDataNotSaved(state, value) {
      state.dataNotSaved = value
      if (value == true) {
        window.onbeforeunload = function () {
          return value;
        };
      } else {
        window.onbeforeunload = null;
      }


    },
    // Change la couleur du thème clair par défaut
    setColorDefaultLight(state, color) {
      state.colorDefaultLight = color;
    },
    // Change la couleur du thème sombre par défaut
    setColorDefaultDark(state, color) {
      state.colorDefaultDark = color;
    },
    //Reset les erreurs de formulaire
    resetFormsErrors(state) {
      state.formsErrors = []
    },
    //Supprime le contenu de la popup
    resetPopup(state) {

      state.popups.splice(state.popups.length - 1, 1)
    },
    // Défini les variables d'environnements, elles sont retournées par le BACKEND
    // Elles définissent le contexte d'execution du Frontend
    // Par exemple : Instance Pulse Prod // Instance Pulse Pre Prod // Instance client XX Prod...
    setEnv(state, env = []) {
      state.env = env
    },
    //Défini un filte
    setFilter(state, text) {
      state.filter = text
    },
  }
})