<template>
  <v-container v-if="userMe" >
    <v-form @submit.prevent="validForm" class="justify-center" v-if="!qrCode">
      <v-text-field
        v-model="input.password"
        label="Mot de passe"
        :type="showPassword ? 'text' : 'password'"
        prepend-icon="mdi-lock"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showPassword = !showPassword"
        autocomplete
        :error-messages="formsErrors.userPassword"
      />

      <v-row class="mt-2">
        <v-col align="center" justify="center">
          <v-btn
            type="submit"
            :color="userMe.is2FAEnabled ? 'error' : 'success'"
            class="mx-2"
          >
            <v-icon class="mr-2">
              {{ userMe.is2FAEnabled ? 'mdi-close' : 'mdi-check' }}
            </v-icon>
            {{ userMe.is2FAEnabled ? 'Désactiver 2FA' : 'Activer 2FA' }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <div v-else>
      <v-row class="mt-2" v-if="otpVerif">
        <v-col align="center" justify="center">
          <v-otp-input
            v-model="input.otp"
            @finish="onOTPFinish"
            :error-messages="formsErrors.userOTPVerification"
          />
        </v-col>
      </v-row>
      <div v-else>
        <v-row class="mt-2">
          <v-col align="center" justify="center">
            <img :src="qrCode" />
          </v-col>
        </v-row>
        <v-row class="mt-2">
          <v-col align="center" justify="center">
            <v-btn
              color="primary"
              @click="otpVerif = true"
            >
              Suivant
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
import { MUTATION_userMe2FAActivate, MUTATION_userMe2FAUpdate } from "./graphql/mutations.js";
import { QUERY_userMe } from './graphql/queries.js';
import { mapState } from "vuex";

export default {
  name: "UserMeEdit2FA",

  components: {
  },

  data: () => ({
    input: {
      password: "",
      otp: ""
    },
    userMe: null,
    showPassword: false,
    qrCode: null,
    otpVerif: false
  }),

  computed: {
    ...mapState(["formsErrors"])
  },

  methods: {
    init() {
      this.input = {
        password: ""
      };

      this.$apollo
        .query({
          query: QUERY_userMe,
          fetchPolicy: 'no-cache'
        })
        .then(__ => {
          const data = __.data;
          this.userMe = data.userMe;
        })
    },

    onOTPFinish() {
      this.$apollo
        .mutate({
          mutation: MUTATION_userMe2FAActivate,
          variables: {
            otp: this.input.otp
          }
        })
        .then(__ => {
          if(__.data.userMe2FAActivate) {
            this.$store.commit("addAlert", {
              type: "success",
              text: "La double authentification a été activée",
            });
            this.$root.$emit("skeleton--close");
            this.init();
            this.$router.push({ path: '/authentification-habilitation/user-me/edit' });
          }
        })
    },

    validForm() {
      this.$apollo
        .mutate({
          mutation: MUTATION_userMe2FAUpdate,
          variables: {
            password: this.input.password,
            is2FAEnabled: !this.userMe.is2FAEnabled
          }
        })
        .then(__ => {
          const res = __.data.userMe2FAUpdate;

          if(res) {
            if(res == "OK") {
              this.$store.commit("addAlert", {
                type: "success",
                text: "La double authentification a été supprimée",
              });
              this.$root.$emit("skeleton--close");
              this.init();
              this.$router.push({ path: '/authentification-habilitation/user-me/edit' });
            }else {
              this.qrCode = res;
            }
          }

          this.init();
        })
        .catch(() => {
          this.init();
        })
    }
  },

  mounted() {
    this.init();
  },

  watch: {
    formsErrors: {
      deep: true,
      handler() {
        this.input.otp = "";
      },
    }
  }
}
</script>