<template>
  <ListLayout
    ref="list"
    :headers="headers"
    @fetch="init"
    @showMultipleActions="showMultipleActions = $event"
  >
    <template v-slot:multipleActions>
      <MarqueActions
        :multiple="true"
        :object="$refs.list ? $refs.list.itemsSelected : []"
        size="xl"
        v-show="showMultipleActions"
        @fetch="init"
        @loading="$refs.list.loading = true"
      />
    </template>
    <template v-slot:[`item.modelesCount`]="{ item }">
      <ModelesCounter :customSearch="[{ marque: item.id }]" />
    </template>
    <template v-slot:[`item.object`]="{ item }">
      <MarqueObject :marqueProp="item" size="xs" />
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <MarqueActions :object="item" :fetch="fetchMarques" size="xs" />
    </template>
  </ListLayout>
</template>

<script>
import ListLayout from "@/components/COMPONENTS-skeleton/Layouts/ListLayout.vue";
import MarqueActions from "./MarqueActions.vue";
import { QUERY_marques } from "./graphql/queries";
import MarqueObject from "./MarqueObject.vue";
import ModelesCounter from "./ModelesCounter.vue";
export default {
  name: "MarquesList",

  components: {
    ModelesCounter,
    MarqueObject,
    MarqueActions,
    ListLayout,
  },

  data: () => ({
    headers: [
      /*{ text: "Logo", value: "logo" },*/
      { text: "", value: "object", sortable: false },
      { text: "Modeles", value: "modelesCount", sortable: false },
      { text: "Fichiers", value: "fichiers" },
      { text: "Commentaires", value: "commentaires" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    showMultipleActions: false,
  }),

  methods: {
    fetchMarques() {
      this.$refs.list.loading = true;
      this.$apollo
        .query({
          query: QUERY_marques,
          variables: {
            pagination: this.$refs.list.pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          this.$refs.list.items = data.marques;
          this.$refs.list.itemsCount = data.marquesCount;
          this.$refs.list.loading = false;
        });
    },
    init() {
      this.fetchMarques();
    },
  },
};
</script>