<template>
  <ViewLayout :tabs="tabs" :item="demandeAccord">
    <template v-slot:demandeAccord>
      <DemandeAccordActions
        :object="demandeAccord"
        :withView="false"
        size="xl"
      />
      <v-row class="align-center mx-auto">
        <v-col cols="12">
          <DemandeAccordObject size="xl" :demandeAccordProp="demandeAccord" />
        </v-col>
      </v-row>
    </template>
  </ViewLayout>
</template>

<script>
import { QUERY_demandeAccord } from "./graphql/queries";

import DemandeAccordActions from "./DemandeAccordActions.vue";
import DemandeAccordObject from "./DemandeAccordObject.vue";

import ViewLayout from "@/components/COMPONENTS-skeleton/Layouts/ViewLayout.vue";

export default {
  name: "AssetView",

  components: {
    DemandeAccordActions,
    DemandeAccordObject,
    ViewLayout,
  },

  data: () => ({
    demandeAccord: {},
    tabs: [
      {
        icon: "mdi-cash-check",
        displayName: "Demande d'accord",
        name: "demandeAccord",
      },
      { name: "commentaires" },
      { name: "fichiers" },
    ],
  }),

  apollo: {
    demandeAccord: {
      query: QUERY_demandeAccord,
      variables() {
        let idQuery = this.showPopup
          ? this.id
          : this.$route.params.id
          ? this.$route.params.id
          : this.id;
        return {
          id: idQuery,
        };
      },
    },
  },
  props: {
    showPopup: {
      type: Boolean,
    },
    id: {
      type: String,
    },
  },
};
</script>