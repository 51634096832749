<template>
  <div>
    <div class="text-h6 blue-grey--text py-2">
      Liste des factures fournisseurs attachées
    </div>
    <v-data-table
      dense
      :headers="headers"
      :items="factureFournisseurs"
      :items-per-page="500"
      :hide-default-footer="true"
    >
      <template v-slot:[`item.factureFournisseur`]="{ item }">
        <FactureFournisseurObject :factureFournisseurProp="item" size="xs" />
      </template>
      <template v-slot:[`item.client`]="{ item }">
        <ClientObject :clientID="item.client.id" size="xs" />
      </template>
      <template v-slot:[`item.fournisseur`]="{ item }">
        <FournisseurObject :fournisseurID="item.fournisseur.id" size="xs" />
      </template>
      <template v-slot:[`item.montantAFinancer`]="{ item }">
        {{ item.montantAFinancer | monetaire }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small @click="removeFactureFournisseur(item)" color="red">
          mdi-pin-off
        </v-icon>
      </template>
    </v-data-table>
    <v-row class="mt-5" justify="center">
      <v-col cols="8">
        <FactureFournisseurSelector v-model="factureFournisseurSelected" />
      </v-col>
      <v-col cols="12" xl="2" lg="2" md="2" sm="12" class="px-0">
        <v-btn
          v-bind="size"
          block
          class="py-4"
          color="primary"
          @click="addFactureFournisseur"
          :disabled="factureFournisseurSelected ? false : true"
        >
          <v-icon class="mr-2">mdi-pin</v-icon>
          Attacher
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { QUERY_factureFournisseurs } from "./graphql/queries";

import ClientObject from "./ClientObject.vue";
import FactureFournisseurObject from "./FactureFournisseurObject.vue";
import FactureFournisseurSelector from "./FactureFournisseurSelector.vue";
import FournisseurObject from "./FournisseurObject.vue";

export default {
  name: "FactureFournisseurAttach",

  components: {
    ClientObject,
    FactureFournisseurObject,
    FactureFournisseurSelector,
    FournisseurObject
  },

  props: {
    value: [],
  },

  data: () => ({
    factureFournisseurs: [],
    factureFournisseurSelected: undefined,
    headers: [
      { text: "Facture", value: "factureFournisseur", sortable: false },
      { text: "Client", value: "client", sortable: false },
      { text: "Fournisseur", value: "fournisseur", sortable: false },
      {
        text: "Montant à financer H.T.",
        value: "montantAFinancer",
        sortable: false,
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    factureFournisseurItems: [],
  }),

  computed: {
    size() {
      const size = { xs: "x-small", sm: "small", lg: "large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
  },

  methods: {
    addFactureFournisseur() {
      if (this.factureFournisseurSelected) {
        let found = this.factureFournisseurItems.find(
          (element) => element == this.factureFournisseurSelected
        );
        if (!found) {
          this.factureFournisseurItems.push(this.factureFournisseurSelected);
        } else {
          this.$store.commit("addAlert", {
            type: "warning",
            text: "La facture est déjà attachée",
          });
        }
        this.factureFournisseurSelected = undefined;
      }
    },

    fetchFactureFournisseurs() {
      this.$apollo
        .query({
          query: QUERY_factureFournisseurs,
          variables: {
            pagination: {
              limit: 500,
              filter: {
                customSearch: [{ _id: this.factureFournisseurItems }],
              },
            },
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          console.log(data)
          this.factureFournisseurs = data.factureFournisseurs;
        });
    },

    removeFactureFournisseur(item) {
      let index = this.factureFournisseurItems.indexOf(item.id);
      if (index > -1) {
        this.factureFournisseurItems.splice(index, 1);
      }
    },
  },

  watch: {
    value: function (val) {
      this.factureFournisseurItems = val;
    },
    factureFournisseurItems: function (val) {
      if (val === null || val === []) {
        this.$emit("input", []);
      } else {
        this.$emit("input", val);
        this.fetchFactureFournisseurs();
      }
    },
  },
};
</script>
