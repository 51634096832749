<template>
  <v-list dense>
    <template v-for="(item, index) in items">
      <template
        v-if="
          (!item.acl || acl(item.acl)) &&
          (!item.aclMeta || aclMeta(item.aclMeta))
        "
      >
        <template v-if="editMenuOrder">
          <div v-bind:key="'btn' + item.title" style="float: left">
            <v-btn
              color="primary"
              icon
              x-small
              dark
              @click="moveMenuItem('up', index)"
              :disabled="index > 0 ? false : true"
            >
              <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              icon
              x-small
              dark
              @click="moveMenuItem('down', index)"
              :disabled="index == items.length - 1 ? true : false"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </div>
        </template>
        <template v-if="item.children">
          <v-list-group
            v-bind:key="'group' + item.title"
            :prepend-icon="item.icon"
            no-action
            v-if="show(item)"
          >
            <template v-slot:activator>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </template>
            <template v-for="subitem in item.children">
              <MainMenuItem
                v-if="
                  (!subitem.acl || acl(subitem.acl)) &&
                  (!subitem.aclMeta || aclMeta(subitem.aclMeta))
                "
                v-bind:key="subitem.title"
                v-bind:item="subitem"
                class="groupItems"
              />
            </template>
          </v-list-group>
        </template>

        <MainMenuItem
          v-else-if="show(item)"
          v-bind:key="item.title"
          v-bind:item="item"
        />
      </template>
    </template>
    <template v-if="editMenuOrder">
      <div align="center" justify="center">
        <v-btn @click="saveMenuOrder" color="primary">Terminer</v-btn>
      </div>
    </template>
  </v-list>
</template>
<script>
import _ from "lodash";
import MainMenuItem from "./MainMenuItem.vue";
import { mapState } from "vuex";
import { isLogged } from "@/components/COMPONENTS-skeleton/vue-apollo";
import { QUERY_authentificationHabilitationOrganisationParametresCustomMainMenuOrder } from "@/components/COMPONENTS-authentification-habilitation/graphql/queries";
export default {
  name: "MainMenu",
  created() {
    this.$root.$on("skeleton--mainMenuChangeMenuOrder", this.changeMenuOrder);
    this.$root.$on("skeleton-get-submenu", this.findSubmenus(this.$route.path));
  },
  components: {
    MainMenuItem,
  },
  mounted: function () {
    this.init();
  },
  computed: {
    ...mapState(["filter"]),
  },
  data() {
    return {
      items: [],
      editMenuOrder: false,
    };
  },
  watch: {
    items() {
      this.findSubmenus(this.$route.path);
    },
    $route(to) {
      this.findSubmenus(to.path);
    },
  },
  methods: {
    async init() {
      let items = [];
      //On récupère les menus depuis les fichiers mainmenu.json des différents jeux de composants chargés
      const files = require
        .context("/src/components/", true, /mainmenu.json$/)
        .keys();
      for (let jsonFile of files) {
        let file = jsonFile.substring(2);
        let module = require(`/src/components/${file}`);
        for (let [, item] of module.items.entries()) {
          let found = items.find((element) => element.title === item.title);
          if (found && item.children[0]) {
            for (let child of item.children) {
              let foundChildren = found.children.find(
                (element) => element.title === child.title
              );
              if (!foundChildren) {
                found.children.push(child);
              }
            }
          } else {
            items.push(item);
          }
        }
      }
      // On récupère le paramètrage de l'organisation (un tableau de title pour trier nos items)
      if (await isLogged()) {
        this.$apollo
          .query({
            query:
              QUERY_authentificationHabilitationOrganisationParametresCustomMainMenuOrder,
          })
          .then((data) => {
            let customItems =
              data.data.authentificationHabilitationOrganisationParametres
                .customMainMenuOrder;
            if (customItems) {
              // On tri le tableau items en se basant sur l'ordre du tableau de String renvoyé par la query
              // On compare l'index de chaque entrée par rapport au title d'items
              items.sort(function (a, b) {
                return (
                  customItems.indexOf(a.title) - customItems.indexOf(b.title)
                );
              });
            }
            this.items = items;
          })
          .catch(() => {
            this.items = items;
          });
      } else {
        this.items = items;
      }
    },
    findSubmenus: function (path) {
      let submenuItems = [];
      if (this.items[0]) {
        this.items.forEach((element) => {
          if (element.children) {
            element.children.forEach((childrenElement) => {
              if (
                path == childrenElement.path ||
                _.startsWith(path, childrenElement.path + "/")
              ) {
                if (childrenElement.subMenuItems) {
                  submenuItems = childrenElement;
                }
              }
            });
          }
          if (path == element.path || _.startsWith(path, element.path + "/")) {
            if (element.subMenuItems) {
              submenuItems = element;
            }
          }
        });
        this.$emit("setSubMenuItems", submenuItems);
      }
    },

    show(item) {
      let state = false;
      if (item.filters) {
        for (let filter of item.filters) {
          if (filter == this.filter) {
            state = true;
          }
        }
      } else {
        state = true;
      }
      return state;
    },

    moveMenuItem: function (sens, itemIndex) {
      let tableau = [...this.items];
      let to = sens == "up" ? itemIndex - 1 : itemIndex + 1;
      tableau.splice(to, 0, tableau.splice(itemIndex, 1)[0]);
      this.items = tableau;
    },
    changeMenuOrder() {
      this.editMenuOrder = true;
      // On demande à ouvrir le menu (App.vue)
      this.$emit("open");
    },
    saveMenuOrder() {
      let toReturn = this.items.map(function (a) {
         
        return a.title;
      });
      // A améliorer car trop dépendant de la fenêtre parent
      this.$root.$emit("skeleton--mainMenuSaveMenuOrder", toReturn);
      this.editMenuOrder = false;
    },
  },
};
</script>
<style scoped>
.groupItems {
  padding-left: 28px !important;
}
</style>