<template>
  <ViewLayout :tabs="tabs" :item="entreprise">
    <template v-slot:entreprise>
      <EntrepriseActions :object="entreprise" :withView="false" size="xl" />
      <v-row class="align-center mx-auto">
        <v-col cols="12">
          <EntrepriseObject :entrepriseProp="entreprise" size="xl" />
        </v-col>
        <v-col cols="12">
          <EtablissementsList
            :entrepriseID="entreprise.id"
            v-if="entreprise.id"
            :noFilter="true"
          />
        </v-col>
        <v-col cols="12">
          <EtablissementsMap
            v-if="entreprise.etablissements.length > 0"
            class="mt-10"
            :etablissements="entreprise.etablissements"
          />
        </v-col>
      </v-row>
    </template>
  </ViewLayout>
</template>

<script>
import { QUERY_entreprise } from "./graphql/queries";
import EntrepriseActions from "./EntrepriseActions.vue";
import EtablissementsMap from "./EtablissementsMap.vue";
import ViewLayout from "@/components/COMPONENTS-skeleton/Layouts/ViewLayout.vue";
export default {
  name: "EntrepriseView",
  components: {
    // Pour utilisation dans composant enfant similaire composant parent
    //App
    // MyComponent
    //  ADifferentComponent
    //    MyComponent
    //https://stackoverflow.com/questions/49154490/did-you-register-the-component-correctly-for-recursive-components-make-sure-to
    EntrepriseActions,
    EtablissementsMap,
    EntrepriseObject: () => import("./EntrepriseObject.vue"),
    EtablissementsList: () => import("./EtablissementsList.vue"),
    ViewLayout,
  },
  data: () => ({
    entreprise: {
      etablissements: [],
    },

    tabs: [
      { icon: "mdi-domain", displayName: "Entreprise", name: "entreprise" },
      { name: "commentaires" },
      { name: "fichiers" },
    ],
  }),
  apollo: {
    entreprise: {
      query: QUERY_entreprise,
      variables() {
        let idQuery = this.showPopup
          ? this.id
          : this.$route.params.id
          ? this.$route.params.id
          : this.id;
        return {
          id: idQuery,
        };
      },
      fetchPolicy: "no-cache",
    },
  },
  props: {
    showPopup: {
      type: Boolean,
    },
    id: {
      type: String,
    },
  },
};
</script>