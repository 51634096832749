<template>
  <ListLayout
    ref="list"
    :headers="headers"
    title="Liste des assets"
    :noFilter="noFilter"
    @fetch="init"
    @showMultipleActions="showMultipleActions = $event"
  >
    <template v-slot:multipleActions>
      <AssetActions
        :multiple="true"
        :object="$refs.list ? $refs.list.itemsSelected : []"
        size="xl"
        v-show="showMultipleActions"
        @fetch="init"
        @loading="$refs.list.loading = true"
      />
    </template>

    <template v-slot:customFilters>
      <MarqueSelector v-model="customFilters.marque" :addAction="null" multiple />
      <ModeleSelector
        :marque="customFilters.marque"
        v-model="customFilters.modele"
        :addAction="null"
        multiple
      />
    </template>

    <template v-slot:[`item.object`]="{ item }">
      <AssetObject :assetProp="item" size="xs" />
    </template>

    <template v-slot:[`item.marque`]="{ item }">
      <MarqueObject :marqueProp="item.marque" size="xs" />
    </template>

    <template v-slot:[`item.modele`]="{ item }">
      <ModeleObject :modeleProp="item.modele" size="xs" />
    </template>

    <template v-slot:[`item.numeroSerie`]="{ item }">
      {{ item.numeroSerie }}
    </template>
    <template v-slot:[`item.numeroInventaire`]="{ item }">
      {{ item.numeroInventaire }}
    </template>

    <template v-slot:[`item.etatAsset`]="{ item }">
      <EtatAssetObject :etatAsset="item.etatAsset" size="xs" />
    </template>

    <template v-slot:[`item.contratsAssocies`]="{ item }">
      <div
        v-for="(elem, index) in item.contratsAssocies"
        :key="index"
      >
        <ContratLocationObject 
          :contratLocationProp="elem"
          size="xs"
        />
      </div>
    </template>

    <template v-slot:[`item.finGarantie`]="{ item }">
      {{ item.finGarantie | shortDateFR }}
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <AssetActions
        :object="item"
        size="xs"
        @fetch="init"
        @loading="$refs.list.loading = true"
      />
    </template>
  </ListLayout>
</template>

<script>
import { QUERY_assets } from "@/components/COMPONENTS-assets/graphql/queries";
import { QUERY_contratLocationsFromAssets } from "./graphql/queries";

import AssetActions from "@/components/COMPONENTS-assets/AssetActions.vue";
import AssetObject from "@/components/COMPONENTS-assets/AssetObject.vue";
import ContratLocationObject from "./ContratLocationObject.vue";
import EtatAssetObject from "@/components/COMPONENTS-assets/EtatAssetObject.vue";
import ListLayout from "@/components/COMPONENTS-skeleton/Layouts/ListLayout.vue";
import MarqueObject from "@/components/COMPONENTS-assets/MarqueObject.vue";
import MarqueSelector from "@/components/COMPONENTS-assets/MarqueSelector.vue";
import ModeleObject from "@/components/COMPONENTS-assets/ModeleObject.vue";
import ModeleSelector from "@/components/COMPONENTS-assets/ModeleSelector.vue";

export default {
  name: "CustomAssetsList",

  components: {
    AssetActions,
    AssetObject,
    ContratLocationObject,
    EtatAssetObject,
    ListLayout,
    MarqueObject,
    MarqueSelector,
    ModeleObject,
    ModeleSelector,
  },

  props: {
    modeleID: null,
    noFilter: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },

  data: () => ({
    customFilters: {
      marque: null,
      modele: null,
    },

    headers: [
      { text: "Asset", value: "object", sortable: false },
      { text: "Marque", value: "marque" },
      { text: "Modèle", value: "modele" },
      { text: "Numéro d'inventaire", value: "numeroInventaire" },
      { text: "Numéro de série", value: "numeroSerie" },
      { text: "Etat", value: "etatAsset" },
      { text: "Contrats", value: "contratsAssocies" },
      { text: "Fin de garantie", value: "finGarantie" },
      { text: "Fichiers", value: "fichiers" },
      { text: "Commentaires", value: "commentaires" },
      { text: "Actions", value: "actions", sortable: false },
    ],

    search: null,

    showMultipleActions: false,
  }),

  methods: {
    fetchAssets() {
      this.$refs.list.items = [];
      this.$refs.list.loading = true;
      let pagination = JSON.parse(JSON.stringify(this.$refs.list.pagination));

      if (this.modeleID) {
        pagination.filter.customSearch.push({ modele: this.modeleID });
      } else {
        if (this.customFilters.marque && this.customFilters.marque.length > 0) {
          pagination.filter.customSearch.push({
            marque: this.customFilters.marque,
          });
        }
        if (this.customFilters.modele && this.customFilters.modele.length > 0) {
          pagination.filter.customSearch.push({
            modele: this.customFilters.modele,
          });
        }
      }

      this.$apollo
        .query({
          query: QUERY_assets,
          variables: {
            pagination: pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then(async (__) => {
          // Récupération des contrats associés aux assets pour les afficher
          let resContrats = await this.$apollo
            .query({
              query: QUERY_contratLocationsFromAssets,
              variables: {
                ids: __.data.assets.map(e => e.id)
              }
            })

          let dataContrats = resContrats.data.contratLocationsFromAssets;

          const data = __.data.assets.map((e) => {
            // On ajoute les contrats associés en remplacant _id par id pour que le router marche quand on clique sur ContratLocationObject
            e.contratsAssocies = 
              ((dataContrats.find(elem => elem.idAsset == e.id)).contrats)
                .map((element => { 
                  let idElem = element._id;
                  element.id = idElem;

                  return element;
                }));

            // On récupère la date d'échéance de la facture pour calculer la date de fin de garantie de l'asset
            if((dataContrats.find(elem => elem.idAsset == e.id)).contrats.length > 0) {
              let dateEcheance = new Date(
                // On prend le 1er contrat car un asset ne doit être lié qu'à un seul contrat
                // On utilise un tableau pour les contrats pour voir si jamais un asset est utilisé 2x directement depuis la liste
                // Comme ça l'anomalie est détectable directement depuis la liste et facilement corrigeable
                ((dataContrats.find(elem => elem.idAsset == e.id)).contrats[0].facturesFournisseurs
                  .find(elem => typeof elem != 'string')).dateEcheance
              );

              e.finGarantie = new Date(dateEcheance.setFullYear(dateEcheance.getFullYear() + (e.dureeGarantie ? e.dureeGarantie : 0))).toISOString();
            }
            
            return e;
          })
            
          this.$refs.list.items = data;
          this.$refs.list.itemsCount = __.data.assetsCount;
          this.$refs.list.loading = false;
        });
    },

    init() {
      this.fetchAssets();
    },
  },

  watch: {
    customFilters: {
      deep: true,
      handler() {
        this.$refs.list.currentPage = 1;
        this.init();
      },
    },

    entrepriseID() {
      this.fetchAssets();
    },
  },
};
</script>