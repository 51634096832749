<template>
  <div>
    <template v-if="enveloppeTheorique">
      <template v-if="size == 'xl'">
        <ObjectLayout :action="action" size="xl" :item="enveloppeTheorique">
          <template v-slot:icon>mdi-email-variant</template>
          <template v-slot:titre> {{ enveloppeTheorique.id }}</template>
          <template v-slot:body>
            <ObjectLayout v-if="enveloppeTheorique.id" class="py-2">
              <template v-slot:icon>mdi-tag-text</template>
              <template v-slot:titre
                >Dénomination de l'enveloppeTheorique</template
              >
              <template v-slot:content>{{ enveloppeTheorique.id }}</template>
            </ObjectLayout>
          </template>
        </ObjectLayout>
      </template>

      <template v-if="size == 'md'">
        <ObjectLayout size="md">
          <template v-slot:icon>mdi-email-variant</template>
          <template v-slot:content>{{ enveloppeTheorique.id }}</template>
          <template v-slot:extracontent>
            <v-icon small class="mr-2">mdi-material-design</v-icon>
            {{ enveloppeTheorique.id }}
          </template>
        </ObjectLayout>
      </template>

      <template v-if="size == 'xs'">
        <ObjectLayout
          size="xs"
          :action="action"
          :onClickPush="`/satelease/refinancement/enveloppeTheoriques/view/${enveloppeTheorique.id}`"
        >
          <template v-slot:icon>mdi-email-variant</template>
          <template v-slot:content>
            {{ enveloppeTheorique.id }} - {{ enveloppeTheorique.id }}
          </template>
        </ObjectLayout>
      </template>
    </template>
  </div>
</template>

<script>
import { QUERY_enveloppeTheorique } from "./graphql/queries";

import ObjectLayout from "@/components/COMPONENTS-skeleton/Layouts/ObjectLayout.vue";

export default {
  name: "EnveloppeTheoriqueObject",

  components: {
    ObjectLayout,
  },

  props: {
    action: {
      type: String,
      default: "router",
    },

    enveloppeTheoriqueID: {
      type: String,
    },

    enveloppeTheoriqueProp: {
      type: Object,
      default() {
        return {};
      },
    },

    size: { type: String, default: "md" },
  },

  data: () => ({
    enveloppeTheorique: undefined,
  }),

  watch: {
    enveloppeTheoriqueID: function () {
      this.init();
    },

    enveloppeTheoriqueProp: function () {
      this.init();
    },
  },

  mounted: function () {
    this.init();
  },

  methods: {
    init() {
      if (this.enveloppeTheoriqueID) {
        let id = this.enveloppeTheoriqueID;
        this.$apollo
          .query({
            query: QUERY_enveloppeTheorique,
            variables: { id: id },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let enveloppeTheorique = data.data.enveloppeTheorique;
            this.enveloppeTheorique = JSON.parse(
              JSON.stringify(enveloppeTheorique)
            );
          });
      } else {
        this.enveloppeTheorique = JSON.parse(
          JSON.stringify(this.enveloppeTheoriqueProp)
        );
      }
    },
  },
};
</script>