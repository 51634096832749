<template>
  <ListLayout ref="list" :headers="headers" title="Liste des établissements" :noFilter="noFilter" @fetch="init"
    @showMultipleActions="showMultipleActions = $event">
    <template v-slot:multipleActions>
      <EtablissementActions :multiple="true" :object="$refs.list ? $refs.list.itemsSelected : []" size="xl"
        v-show="showMultipleActions" @fetch="init" @loading="$refs.list.loading = true" />
    </template>
    <template v-slot:customFilters>
      <AdressePostaleVilleSelector v-model="customFilters.libelleCommune" />
      <EntrepriseSelector v-model="customFilters.entreprise" :addAction="null" />
      <EnseigneSelector v-model="customFilters.enseigne" :addAction="null" />
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-checkbox v-model="customFilters.isSiege" prepend-icon="mdi-crown" class="mt-2" />
          </div>


        </template>
        <span>Sièges sociaux uniquement</span>
      </v-tooltip>

    </template>
    <template v-slot:[`item.object`]="{ item }">
      <EtablissementObject :etablissementProp="item" size="xs" />
    </template>
    <template v-slot:[`item.enseigne`]="{ item }">
      <EnseigneObject :enseigneProp="item.enseigne" size="xs" v-if="item.enseigne" />
    </template>
    <template v-slot:[`item.personnes`]="{ item }">
      <PersonnesCounter :customSearch="[{ 'fonctions.etablissement': item.id }]" />
    </template>
    <template v-slot:[`item.entreprise`]="{ item }">
      <EntrepriseObject :entrepriseProp="item.entreprise" size="xs" />
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <EtablissementActions :object="item" size="xs" @fetch="init" @loading="$refs.list.loading = true" />
    </template>
  </ListLayout>
</template>

<script>
import AdressePostaleVilleSelector from "./AdressePostaleVilleSelector.vue";
import EnseigneObject from "./EnseigneObject.vue";
import EntrepriseObject from "./EntrepriseObject.vue";
import EntrepriseSelector from "./EntrepriseSelector.vue";
import EnseigneSelector from "./EnseigneSelector.vue";
import EtablissementActions from "./EtablissementActions.vue";
import EtablissementObject from "./EtablissementObject.vue";
import ListLayout from "@/components/COMPONENTS-skeleton/Layouts/ListLayout.vue";
import PersonnesCounter from "./PersonnesCounter.vue";

import { QUERY_etablissements } from "./graphql/queries";

export default {
  name: "EtablissementsList",
  components: {
    AdressePostaleVilleSelector,
    EtablissementObject,
    EtablissementActions,
    EnseigneObject,
    EntrepriseObject,
    EntrepriseSelector,
    EnseigneSelector,
    ListLayout,
    PersonnesCounter,
  },

  props: {
    entrepriseID: null,
    enseigneID: null,
    noFilter: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },

  data: () => ({
    customFilters: {
      entreprise: null,
      enseigne: null,
      libelleCommune: null,
      isSiege: false
    },
    headers: [
      { text: "", value: "object", sortable: false },
      { text: "Nom de l'établissement", value: "libelle" },
      { text: "SIRET", value: "siret" },
      { text: "Entreprise", value: "entreprise" },
      { text: "Enseigne", value: "enseigne" },
      { text: "Personnes", value: "personnes", sortable: false },
      { text: "Fichiers", value: "fichiers" },
      { text: "Commentaires", value: "commentaires" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    search: null,
    showMultipleActions: false,
  }),

  methods: {
    fetchEtablissements() {
      this.$refs.list.items = [];
      this.$refs.list.loading = true;
      let pagination = JSON.parse(JSON.stringify(this.$refs.list.pagination));

      if (this.entrepriseID) {
        pagination.filter.customSearch.push({ entreprise: this.entrepriseID });
      } else if (this.customFilters.entreprise) {
        pagination.filter.customSearch.push({
          entreprise: this.customFilters.entreprise,
        });
      }

      if (this.enseigneID) {
        pagination.filter.customSearch.push({ enseigne: this.enseigneID });
      } else if (this.customFilters.enseigne) {
        pagination.filter.customSearch.push({
          enseigne: this.customFilters.enseigne,
        });
      }

      if (this.customFilters.libelleCommune) {
        pagination.filter.customSearch.push({
          "adresse.libelleCommune": this.customFilters.libelleCommune,
        });
      }

      if (this.customFilters.isSiege) {
        pagination.filter.customSearch.push({ isSiege: true })
      }

      this.$apollo
        .query({
          query: QUERY_etablissements,
          variables: {
            pagination: pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          this.$refs.list.items = data.etablissements;
          this.$refs.list.itemsCount = data.etablissementsCount;
          this.$refs.list.loading = false;
        });
    },

    init() {
      this.fetchEtablissements();
    },
  },

  watch: {
    customFilters: {
      deep: true,
      handler() {
        this.$refs.list.currentPage = 1;
        this.init();
      },
    },
    entrepriseID() {
      this.init();
    },
    enseigneID() {
      this.init();
    },
  },
};
</script>