import gql from 'graphql-tag';
import {
  BON_DE_COMMANDE_FOURNISSEUR_FIELDS,
  BON_DE_COMMANDE_FOURNISSEUR_LIGNE_FIELDS,
  CLIENT_FIELDS,
  DEVIS_CLIENT_FIELDS,
  DEVIS_CLIENT_LIGNE_FIELDS,
  DEVIS_FOURNISSEUR_FIELDS,
  DEVIS_FOURNISSEUR_LIGNE_FIELDS,
  FACTURE_CLIENT_FIELDS,
  FACTURE_CLIENT_LIGNE_FIELDS,
  FACTURE_FOURNISSEUR_FIELDS,
  FACTURE_FOURNISSEUR_LIGNE_FIELDS,
  FOURNISSEUR_FIELDS,
  POINTFACTURATION_FIELDS,
  MOYENPAIEMENT_FIELDS,
  REGLEMENT_CLIENT_FIELDS,
  REGLEMENT_FOURNISSEUR_FIELDS
} from './fragments';

import { ADRESSE_POSTALE_FIELDS, ENSEIGNE_FIELDS, ENTREPRISE_FIELDS, ETABLISSEMENT_FIELDS } from '@/components/COMPONENTS-annuaire/graphql/fragments';
import { USER_FIELDS } from '@/components/COMPONENTS-authentification-habilitation/graphql/fragments';
import { ASSET_FIELDS, MARQUE_FIELDS, MODELE_FIELDS } from '@/components/COMPONENTS-assets/graphql/fragments';

export const QUERY_patchFacturesLignesMeta = gql`
  query {
    patchFacturesLignesMeta
  }
`;

export const QUERY_avoirsCount = gql`
query($pagination: PaginationObject){
  avoirsCount(pagination:$pagination)
  }
`;

export const QUERY_bonDeCommandeFournisseur = gql`
query($id:ID!){
  bonDeCommandeFournisseur(id:$id){
    ...BonDeCommandeFournisseurFields
    client{
      ...ClientFields
      etablissement{
        ...EtablissementFields
      }
    }
    createdBy{
        ...UserFields
    }

    fournisseur{
      ...FournisseurFields
      entreprise{
        ...EntrepriseFields
      }
    }
   
    lignes{
      ...BonDeCommandeFournisseurLigneFields
      modele{
        ...ModeleFields
      }
    }
    
  }
}
${BON_DE_COMMANDE_FOURNISSEUR_FIELDS},
${BON_DE_COMMANDE_FOURNISSEUR_LIGNE_FIELDS},
${CLIENT_FIELDS},
${ENTREPRISE_FIELDS},
${ETABLISSEMENT_FIELDS},
${FOURNISSEUR_FIELDS},
${MODELE_FIELDS},
${USER_FIELDS}
`;

export const QUERY_bonDeCommandeFournisseurs = gql`
query($pagination: PaginationObject){
  bonDeCommandeFournisseurs(pagination: $pagination){
    ...BonDeCommandeFournisseurFields
    client{
      ...ClientFields
    }
    fournisseur{
      ...FournisseurFields
    }
    lignes{
      label
      meta
      prixUnitaire
      quantite
      tauxTva
      unite
    }   
  }
  bonDeCommandeFournisseursCount(pagination: $pagination)
}${BON_DE_COMMANDE_FOURNISSEUR_FIELDS},${CLIENT_FIELDS},${FOURNISSEUR_FIELDS}
`;

export const QUERY_bonDeCommandeFournisseursCount = gql`
query($pagination: PaginationObject){
  bonDeCommandeFournisseursCount(pagination:$pagination)
  }
`;

export const QUERY_bonDeCommandeFournisseurLigneUnite = gql`
  query{
    bonDeCommandeFournisseurLigneUnite
  }
`;



export const QUERY_client = gql`
query($id:ID!){
  client(id:$id){
    ...ClientFields
    createdBy{
        ...UserFields
    }
    dateCreation
    entreprise{
      ...EntrepriseFields
    }
    etablissement{
      ...EtablissementFields
      enseigne {
        ...EnseigneFields
      }
      adresse{
        ...AdressePostaleFields
      }
      
    }
  }
}${ADRESSE_POSTALE_FIELDS},${CLIENT_FIELDS},${ENSEIGNE_FIELDS}${ENTREPRISE_FIELDS},${ETABLISSEMENT_FIELDS}${USER_FIELDS}
`;

export const QUERY_clients = gql`
query($pagination: PaginationObject){
  clients(pagination:$pagination){
    ...ClientFields
    entreprise{
      ...EntrepriseFields
    }
    etablissement{
      ...EtablissementFields
      adresse{
        ...AdressePostaleFields
      }
     
    }
  }
  clientsCount(pagination: $pagination)
}${ADRESSE_POSTALE_FIELDS},${CLIENT_FIELDS},${ENTREPRISE_FIELDS},${ETABLISSEMENT_FIELDS}
`;

export const QUERY_clientsCount = gql`
query($pagination: PaginationObject){
  clientsCount(pagination:$pagination)
  }
`;

export const QUERY_devisClient = gql`
query($id:ID!){
  devisClient(id:$id){
    ...DevisClientFields
    client{
      ...ClientFields
      etablissement{
        enseigne {
          ...EnseigneFields
        }
        ...EtablissementFields
      }
    }
    createdBy{
        ...UserFields
    }
    lignes{
      ...DevisClientLigneFields
      modele{
        ...ModeleFields
      }
      marque{
        ...MarqueFields
      }
    }    
  }
}${CLIENT_FIELDS},
${DEVIS_CLIENT_FIELDS},
${DEVIS_CLIENT_LIGNE_FIELDS},
${ENSEIGNE_FIELDS}
${ETABLISSEMENT_FIELDS},
${MARQUE_FIELDS},
${MODELE_FIELDS},
${USER_FIELDS}
`;

export const QUERY_devisClients = gql`
query($pagination: PaginationObject){
  devisClients(pagination: $pagination){
    ...DevisClientFields
    client{
      ...ClientFields
    }
    lignes{
      quantite
      unite
      label
      prixUnitaire
      tauxTva
      meta
    }
  }
  devisClientsCount(pagination: $pagination)
}${CLIENT_FIELDS},${DEVIS_CLIENT_FIELDS}
`;

export const QUERY_devisClientsCount = gql`
query($pagination: PaginationObject){
  devisClientsCount(pagination:$pagination)
  }
`;

export const QUERY_devisClientLigneUnite = gql`
  query{
    devisClientLigneUnite
  }
`;

export const QUERY_devisFournisseur = gql`
query($id:ID!){
  devisFournisseur(id:$id){
    ...DevisFournisseurFields
    client{
      ...ClientFields
      etablissement{
        enseigne {
          ...EnseigneFields
        }
        ...EtablissementFields
      }
    }
    createdBy{
        ...UserFields
    }

    fournisseur{
      ...FournisseurFields
      entreprise{
        ...EntrepriseFields
      }
    }
   
    lignes{
      ...DevisFournisseurLigneFields
      modele{
        ...ModeleFields
      }
      marque{
        ...MarqueFields
      }
    }
    
  }
}
${CLIENT_FIELDS},
${DEVIS_FOURNISSEUR_FIELDS},
${DEVIS_FOURNISSEUR_LIGNE_FIELDS},
${ENSEIGNE_FIELDS},
${ENTREPRISE_FIELDS},
${ETABLISSEMENT_FIELDS},
${FOURNISSEUR_FIELDS},
${MARQUE_FIELDS},
${MODELE_FIELDS},
${USER_FIELDS}
`;

export const QUERY_devisFournisseurs = gql`
query($pagination: PaginationObject){
  devisFournisseurs(pagination: $pagination){
    ...DevisFournisseurFields
    client{
      ...ClientFields
    }
    fournisseur{
      ...FournisseurFields
    }
    lignes{
      label
      meta
      prixUnitaire
      quantite
      tauxTva
      unite
    }   
  }
  devisFournisseursCount(pagination: $pagination)
}${CLIENT_FIELDS},${DEVIS_FOURNISSEUR_FIELDS},${FOURNISSEUR_FIELDS}
`;

export const QUERY_devisFournisseursCount = gql`
query($pagination: PaginationObject){
  devisFournisseursCount(pagination:$pagination)
  }
`;

export const QUERY_devisFournisseurLigneUnite = gql`
  query{
    devisFournisseurLigneUnite
  }
`;

export const QUERY_devisFactureOrganisationParametres = gql`
query{
  devisFactureOrganisationParametres{
    id
    nbJourDefaultFactureClientEcheance
    caractereNommage
  }
}
`

export const QUERY_factureClient = gql`
query($id: ID!){
  factureClient(id: $id){
    ...FactureClientFields
    client{
      ...ClientFields
      etablissement{
        enseigne {
          ...EnseigneFields
        }
        ...EtablissementFields
      }
      entreprise{
        ...EntrepriseFields
      }
    }
    createdBy{
        ...UserFields
    }
    lignes{
      ...FactureClientLigneFields
      equipements{
        ...AssetFields
        modele{
          ...ModeleFields
          marque{
            ...MarqueFields
          }
        }
      }
    }   
    moyenPaiement {
      ...MoyenPaiementFields
    }
    pointFacturation {
      ...PointFacturationFields
    }
  }
}${ASSET_FIELDS},
${CLIENT_FIELDS},
${ENSEIGNE_FIELDS},
${ENTREPRISE_FIELDS},
${ETABLISSEMENT_FIELDS},
${FACTURE_CLIENT_FIELDS},
${FACTURE_CLIENT_LIGNE_FIELDS},
${MARQUE_FIELDS},
${MODELE_FIELDS},
${MOYENPAIEMENT_FIELDS}
${POINTFACTURATION_FIELDS}
${USER_FIELDS}
`;

export const QUERY_factureClientDateDerniereFacture = gql`
query{
  factureClientDateDerniereFacture
}
`;

export const QUERY_factureClients = gql`
query($pagination: PaginationObject){
  factureClients(pagination: $pagination){
    ...FactureClientFields
  
    lignes{
      quantite
      unite
      label
      prixUnitaire
      tauxTva
      meta
    }
    client{
      ...ClientFields
      etablissement{
        ...EtablissementFields
      }
    }
  }
  factureClientsCount(pagination: $pagination)
}${CLIENT_FIELDS},${ETABLISSEMENT_FIELDS},${FACTURE_CLIENT_FIELDS}
`;

export const QUERY_factureClientsCount = gql`
query($pagination: PaginationObject){
  factureClientsCount(pagination:$pagination)
  }
`;

export const QUERY_factureClientLigneCompteAnalytique = gql`
  query{
    factureClientLigneCompteAnalytique
  }
`;

export const QUERY_factureClientLigneUnite = gql`
  query{
    factureClientLigneUnite
  }
`;

export const QUERY_factureFournisseur = gql`
query($id: ID!){
  factureFournisseur(id: $id){
    ...FactureFournisseurFields
    client{
      ...ClientFields
      etablissement{
        enseigne {
          ...EnseigneFields
        }
        ...EtablissementFields
      }
    }
    fournisseur{
      ...FournisseurFields
      entreprise{
        ...EntrepriseFields
      }
    }
    lignes{
      ...FactureFournisseurLigneFields
      equipements{
        ...AssetFields
        modele{
          ...ModeleFields
          marque{
            ...MarqueFields
          }
        }
      }
    }
  }
}${ASSET_FIELDS},
${CLIENT_FIELDS},
${FOURNISSEUR_FIELDS},
${ENSEIGNE_FIELDS},
${ENTREPRISE_FIELDS},
${ETABLISSEMENT_FIELDS},
${FACTURE_FOURNISSEUR_FIELDS},
${FACTURE_FOURNISSEUR_LIGNE_FIELDS},
${MARQUE_FIELDS},
${MODELE_FIELDS}
`;

export const QUERY_factureFournisseurs = gql`
query($pagination: PaginationObject){
  factureFournisseurs(pagination: $pagination){
    ...FactureFournisseurFields
    client{
      ...ClientFields
    }
    lignes{
      quantite
      unite
      label
      prixUnitaire
      tauxTva
      meta
      equipements{
        ...AssetFields
        modele{
          ...ModeleFields
          marque{
            ...MarqueFields
          }
        }
      }
    }
    fournisseur{
      ...FournisseurFields
    }
  }
  factureFournisseursCount(pagination: $pagination)
}${ASSET_FIELDS}${CLIENT_FIELDS},${FOURNISSEUR_FIELDS},${FACTURE_FOURNISSEUR_FIELDS}${MARQUE_FIELDS}${MODELE_FIELDS}
`;

export const QUERY_factureFournisseursCount = gql`
query($pagination: PaginationObject){
  factureFournisseursCount(pagination:$pagination)
  }
`;

export const QUERY_factureFournisseurLigneCompteAnalytique = gql`
  query{
    factureFournisseurLigneCompteAnalytique
  }
`;

export const QUERY_factureFournisseurLigneUnite = gql`
  query{
    factureFournisseurLigneUnite
  }
`;

export const QUERY_fournisseur = gql`
  query($id:ID!){
    fournisseur(id:$id){
      ...FournisseurFields
      entreprise{
        ...EntrepriseFields
      }
      users{
        ...UserFields
      }
    }
  }${FOURNISSEUR_FIELDS}${ENTREPRISE_FIELDS}${USER_FIELDS}
`;

export const QUERY_fournisseurs = gql`
query($pagination: PaginationObject){
  fournisseurs(pagination: $pagination){
    ...FournisseurFields
    entreprise{
      ...EntrepriseFields
    }
  }
  fournisseursCount(pagination: $pagination)
}${FOURNISSEUR_FIELDS}${ENTREPRISE_FIELDS}
`;

export const QUERY_fournisseursCount = gql`
query($pagination: PaginationObject){
  fournisseursCount(pagination:$pagination)
  }
`;

export const QUERY_moyenPaiement = gql`
  query($id:ID!){
    moyenPaiement(id:$id){
      ...MoyenPaiementFields
      pointFacturation{
        ...PointFacturationFields
        client{
          ...ClientFields
        }
      }
    }
  }${CLIENT_FIELDS}${MOYENPAIEMENT_FIELDS}${POINTFACTURATION_FIELDS}
`;

export const QUERY_moyenPaiements = gql`
query($pagination: PaginationObject){
  moyenPaiements(pagination: $pagination){
    ...MoyenPaiementFields
    pointFacturation{
        ...PointFacturationFields
        client{
          ...ClientFields
        }
      }
  }
  moyenPaiementsCount(pagination: $pagination)
}${CLIENT_FIELDS}${MOYENPAIEMENT_FIELDS}${POINTFACTURATION_FIELDS}
`;

export const QUERY_moyenPaiementsCount = gql`
query($pagination: PaginationObject){
  moyenPaiementsCount(pagination:$pagination)
  }
`;

export const QUERY_pointFacturation = gql`
  query($id:ID!){
    pointFacturation(id:$id){
      ...PointFacturationFields
      client{
        ...ClientFields
      }
    }
  }${CLIENT_FIELDS}${POINTFACTURATION_FIELDS}
`;

export const QUERY_pointFacturations = gql`
query($pagination: PaginationObject){
  pointFacturations(pagination: $pagination){
    ...PointFacturationFields
    client{
        ...ClientFields
      }
  }
  pointFacturationsCount(pagination: $pagination)
}${CLIENT_FIELDS}${POINTFACTURATION_FIELDS}
`;

export const QUERY_pointFacturationsCount = gql`
query($pagination: PaginationObject){
  pointFacturationsCount(pagination:$pagination)
  }
`;

export const QUERY_reglementClient = gql`
  query($id:ID!){
    reglementClient(id:$id){
      ...ReglementClientFields
      client{
        ...ClientFields
      }
      createdBy{
        ...UserFields
      }
      factureClient{
        ...FactureClientFields
      }
    }
  }${CLIENT_FIELDS},${FACTURE_CLIENT_FIELDS},${REGLEMENT_CLIENT_FIELDS},${USER_FIELDS}
`;

export const QUERY_reglementClients = gql`
query($pagination: PaginationObject){
  reglementClients(pagination: $pagination){
    ...ReglementClientFields
    client{
        id
    }
    factureClient{
        id
    }
  }
  reglementClientsCount(pagination: $pagination)
}${REGLEMENT_CLIENT_FIELDS}
`;

export const QUERY_reglementClientsCount = gql`
query($pagination: PaginationObject){
  reglementClientsCount(pagination:$pagination)
  }
`;


export const QUERY_reglementFournisseur = gql`
  query($id:ID!){
    reglementFournisseur(id:$id){
      ...ReglementFournisseurFields
    }
  }${REGLEMENT_FOURNISSEUR_FIELDS}
`;

export const QUERY_reglementFournisseurs = gql`
query($pagination: PaginationObject){
  reglementFournisseurs(pagination: $pagination){
    ...ReglementFournisseurFields
  }
  reglementFournisseursCount(pagination: $pagination)
}${REGLEMENT_FOURNISSEUR_FIELDS}
`;

export const QUERY_reglementFournisseursCount = gql`
query($pagination: PaginationObject){
  reglementFournisseursCount(pagination:$pagination)
  }
`;