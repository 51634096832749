import { render, staticRenderFns } from "./UserOTPVerification.vue?vue&type=template&id=0d398a0d&"
import script from "./UserOTPVerification.vue?vue&type=script&lang=js&"
export * from "./UserOTPVerification.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VOtpInput } from 'vuetify/lib/components/VOtpInput';
installComponents(component, {VOtpInput})
