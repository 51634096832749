<template>
  <div>
    <div class="text-h6 blue-grey--text py-2">
      Liste des devis fournisseurs attachés
    </div>
    <v-data-table
      dense
      :headers="headers"
      :items="devisFournisseurs"
      :items-per-page="500"
      :hide-default-footer="true"
    >
      <template v-slot:[`item.devisFournisseur`]="{ item }">
        <DevisFournisseurObject :devisFournisseurProp="item" size="xs" />
      </template>
      <template v-slot:[`item.client`]="{ item }">
        <ClientObject :clientID="item.client.id" size="xs" />
      </template>
      <template v-slot:[`item.fournisseur`]="{ item }">
        <FournisseurObject :fournisseurID="item.fournisseur.id" size="xs" />
      </template>
      <template v-slot:[`item.montantAFinancer`]="{ item }">
        {{ item.montantAFinancer | monetaire }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small @click="removeDevisFournisseur(item)" color="red">
          mdi-pin-off
        </v-icon>
      </template>
    </v-data-table>
    <v-row class="mt-5" justify="center">
      <v-col cols="8">
        <DevisFournisseurSelector v-model="devisFournisseurSelected" />
      </v-col>
      <v-col cols="12" xl="2" lg="1" md="2" sm="12" class="px-0">
        <v-btn
          v-bind="size"
          block
          class="py-4"
          color="primary"
          @click="addDevisFournisseur"
          :disabled="devisFournisseurSelected ? false : true"
        >
          <v-icon class="mr-2">mdi-pin</v-icon>
          Attacher
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { QUERY_devisFournisseurs } from "./graphql/queries";
import ClientObject from "./ClientObject.vue";
import DevisFournisseurObject from "./DevisFournisseurObject.vue";
import DevisFournisseurSelector from "./DevisFournisseurSelector.vue";
import FournisseurObject from "./FournisseurObject.vue";

export default {
  name: "DevisFournisseurAttach",
  components: {
    ClientObject,
    DevisFournisseurObject,
    DevisFournisseurSelector,
    FournisseurObject
  },
  props: {
    value: [],
  },
  data: () => ({
    devisFournisseurs: [],
    devisFournisseurSelected: undefined,
    headers: [
      { text: "Devis", value: "devisFournisseur", sortable: false },
      { text: "Client", value: "client", sortable: false },
      { text: "Fournisseur", value: "fournisseur", sortable: false },
      {
        text: "Montant à financer H.T.",
        value: "montantAFinancer",
        sortable: false,
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    devisFournisseurItems: [],
  }),
  computed: {
    size() {
      const size = { xs: "x-small", sm: "small", lg: "large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
  },
  methods: {
    addDevisFournisseur: function () {
      if (this.devisFournisseurSelected) {
        let found = this.devisFournisseurItems.find(
          (element) => element == this.devisFournisseurSelected
        );

        if (!found) {
          this.devisFournisseurItems.push(this.devisFournisseurSelected);
        } else {
          this.$store.commit("addAlert", {
            type: "warning",
            text: "Le devis est déjà attaché",
          });
        }
        this.devisFournisseurSelected = undefined;
      }
    },
    fetchDevisFournisseurs: function () {
      this.$apollo
        .query({
          query: QUERY_devisFournisseurs,
          variables: {
            pagination: {
              limit: 500,
              filter: {
                customSearch: [{ _id: this.devisFournisseurItems }],
              },
            },
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;

          this.devisFournisseurs = data.devisFournisseurs;
        });
    },
    removeDevisFournisseur(item) {
      let index = this.devisFournisseurItems.indexOf(item.id);
      if (index > -1) {
        this.devisFournisseurItems.splice(index, 1);
      }
    },
  },
  watch: {
    value: function (val) {
      this.devisFournisseurItems = val;
    },
    devisFournisseurItems: function (val) {
      if (val === null || val === []) {
        this.$emit("input", []);
      } else {
        this.$emit("input", val);
        this.fetchDevisFournisseurs();
      }
    },
  },
};
</script>