import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { fromPromise } from "apollo-link";
import { createApolloClient, restartWebsockets } from '@aidalinfo/vue-graphql-client';
import { onError } from "apollo-link-error";
import store from './store'
import router from './router'
import { QUERY_userTokenIs2FA, QUERY_userTokenIsValid, QUERY_userLoginByRefreshToken } from "../COMPONENTS-authentification-habilitation/graphql/queries.js";
// Install the vue plugin
Vue.use(VueApollo)


// Name of the localStorage item
export const AUTH_TOKEN = 'apollo-token'
const REFRESH_TOKEN = 'apollo-refresh-token'

const USURPATION_DATE = 'usurpation-date'
const START_LOCATION = 'startLocation'

// Http endpoint
const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP

// On conserve la route d'accès ici, utilisé par OrganisationChoice pour rediriger vers l'URL initiale lorsque l'on est authentifié
localStorage.setItem(START_LOCATION, router.history._startLocation)

/**
 * Permet de récupérer un nouveau token d'accès à partir du refresh token
 */

export const getNewToken = () => {
  console.log('getNewToken 1')
  // Si tentative de connexion alors token pas bon
  // On met le refresh token à la place du token d'accès pour pouvoir passer la gateway
  localStorage.setItem(AUTH_TOKEN, localStorage.getItem(REFRESH_TOKEN))
  // Puis on récupère les nouveaux tokens
  return apolloClient.query({
    query: QUERY_userLoginByRefreshToken,
    fetchPolicy: "no-cache"
  }).then((__) => {
    let tokens = __.data.userLoginByRefreshToken;
    localStorage.setItem(AUTH_TOKEN, tokens.token);
    localStorage.setItem(REFRESH_TOKEN, tokens.refreshToken);
    localStorage.removeItem(USURPATION_DATE)
    return tokens;
  }).catch((e) => {
    onLogout(apolloClient);
    console.warn(e)
    router.push({ path: "/" }).catch(() => { });
  })
};

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors) {
    console.log('graphQLErrors')
    console.log(graphQLErrors[0].message)
    if (graphQLErrors[0].extensions.code == "BAD_AUTHENTICATION") {
      console.log('BAD_AUTHENTICATION')
      if (graphQLErrors[0].message == "authentificationHabilitation.backend.session.expired") {
        console.log('authentificationHabilitation.backend.session.expired')
      }
      if (graphQLErrors[0].message != "Boucle token") {
        if (localStorage.getItem(REFRESH_TOKEN)) {
          return fromPromise(getNewToken().catch(() => {
            return;
          })).filter((value) => Boolean(value)).flatMap((tokens) => {
            if (tokens.token == null) {
              onLogout(apolloClient);
              router.push({ path: "/" }).catch(() => { });
            }

            // Modify the operation context with a new token
            const oldHeaders = operation.getContext().headers;

            operation.setContext({
              headers: {
                ...oldHeaders,
                Authorization: 'Bearer ' + localStorage.getItem(AUTH_TOKEN)
              },
            });

            // Retry the request, returning the new observable
            return forward(operation);
          })

        } else {
          onLogout(apolloClient)
          router.push({ path: '/' }).catch(() => { })
          console.log('icici 1')
          window.location.reload()
        }
      } else {
        // Si l'utilisateur n'a pas de refresh token, on le déconnecte
        onLogout(apolloClient);
        router.push({ path: '/' }).catch(() => { })
        console.log('icici 2')
        window.location.reload()
      }
    } else {
      if (graphQLErrors[0].message == "Cannot return null for non-nullable field Query.userMe.") {
        onLogout(apolloClient);
        router.push({ path: "/" }).catch(() => { });
      }
      //On ajoute l'alerte au store vuex afin de l'envoyer à l'affichage
      store.commit("addAlert", {
        type: "warning",
        text: graphQLErrors[0].message,
      });
    }
    if (graphQLErrors[0].extensions.code == "BAD_USER_INPUT") {
      console.warn(graphQLErrors[0])
      store.commit("addFormsError", graphQLErrors[0].extensions.validationErrors)
    }
  }
}
);

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  wsEndpoint: null,
  // LocalStorage token
  tokenName: AUTH_TOKEN,
  refreshTokenName: REFRESH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  link: errorLink

  // Override default cache
  // cache: myCache

  // Override the way the Authorization header is set
  // getAuth: (tokenName) => ...

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
}


// Create apollo client
const { apolloClient, wsClient } = createApolloClient({
  ...defaultOptions
})
apolloClient.wsClient = wsClient;

// Call this in the Vue app file
export function createProvider() {
  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        // fetchPolicy: 'cache-and-network',
      },
    },
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.warn('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message)
    },
  })

  return apolloProvider
}
// Fonction qui retourne si un utilisateur est connecté
// Maxime 27/01/21
// TODO: très simple doit être améliorer pour vérifier si jeton est ok
export async function isLogged() {
  let token = localStorage.getItem(AUTH_TOKEN);
  if (token) {
    let res;
    try {
      res = await apolloClient.query({
        query: QUERY_userTokenIsValid,
        fetchPolicy: 'no-cache'
      });
    } catch (e) {
      return false;
    }

    return res.data && res.data.userTokenIsValid ? true : false;
  } else {
    return false;
  }
}

export function isUsurping() {
  return (localStorage.getItem(USURPATION_DATE)) ? true : false;
}

export function isOrganisationLogged() {
  let accessToken = localStorage.getItem(AUTH_TOKEN);
  let refreshToken = localStorage.getItem(REFRESH_TOKEN);
  if (accessToken == "undefined" || accessToken == "null" || refreshToken == "undefined" || refreshToken == "null" || accessToken == null || refreshToken == null) {
    return false;
  }

  return true;
}

export async function onUsurpationLogin(apolloClient, usurpationToken) {
  if (typeof localStorage !== 'undefined' && usurpationToken) {
    localStorage.setItem(AUTH_TOKEN, usurpationToken);
    localStorage.setItem(USURPATION_DATE, Date.now());
    localStorage.removeItem(START_LOCATION);
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('%cError on cache reset (login)', 'color: orange;', e.message)
  }
}

// Manually call this when user log out
export async function onUsurpationLogout(apolloClient) {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(USURPATION_DATE);
    localStorage.removeItem(START_LOCATION);
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('%cError on cache reset (logout)', 'color: orange;', e.message)
  }
}

// Manually call this when user log in
export async function onLogin(apolloClient, token, refreshToken) {
  if (typeof localStorage !== 'undefined' && token) {
    localStorage.setItem(AUTH_TOKEN, token)
    localStorage.setItem(REFRESH_TOKEN, refreshToken)
    localStorage.removeItem(USURPATION_DATE)
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('%cError on cache reset (login)', 'color: orange;', e.message)
  }
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
  console.log('onLogout')
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN)
    localStorage.removeItem(USURPATION_DATE)
    localStorage.removeItem(START_LOCATION)
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('%cError on cache reset (logout)', 'color: orange;', e.message)
  }
}

export async function getCurrentTokenState() {
  let isLogged = (localStorage.getItem(AUTH_TOKEN) ? true : false);
  if (isLogged) {
    let res = await apolloClient.query({
      query: QUERY_userTokenIs2FA,
      fetchPolicy: 'no-cache'
    });

    return (res.data.userTokenIs2FA ? '2fa' : 'login');
  }

  return 'logout';
}

export async function refreshSession(apolloClient) {
  await getNewToken();

  localStorage.removeItem(START_LOCATION);
  localStorage.removeItem(USURPATION_DATE);
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('%cError on cache reset (logout)', 'color: orange;', e.message)
  }
  router.push({ path: "/" }).catch(() => { });
}