<template>
  <FormLayout
    icon="mdi-file-table"
    @ajouter="ajouter"
    @modifier="modifier"
    @reinitialiser="init"
    typeItem="une facture fournisseur"
    :item="input"
    :disabled="input.client && input.lignes && input.lignes[0] ? false : true"
  >
    <v-row>
      <v-col>
        <ClientSelector
          v-model="input.client"
          label="Client"
          :disabled="client ? true : false"
        />
      </v-col>
      <v-col>
        <FournisseurSelector
          v-model="input.fournisseur"
          :disabled="fournisseur ? true : false"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="input.numeroFacture"
          :error-messages="formsErrors.FactureFournisseurNumeroFacture"
          label="Numéro de la facture"
          prepend-icon="mdi-form-textbox"
        />
      </v-col>
      <v-col>
        <DateInput v-model="input.dateFacture" label="Date de facturation" />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex flex-row align-center">
        <DateInput
          hint="A saisir si différent de la date de facturation"
          persistent-hint
          v-model="input.dateInstallation"
          label="Date d'installation"
          prepend-icon="mdi-calendar-check"
          :max="input.dateFacture"
          :disabled="!input.dateInstallationSwitch"
        />
        <v-switch v-model="input.dateInstallationSwitch" />
      </v-col>
      <v-col>
        <DateInput
          v-model="input.dateEcheance"
          label="Date d'échéance"
          prepend-icon="mdi-calendar-end"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-checkbox
          label="Facture à retirer lors de la comptabilité"
          v-model="input.noComptabilite"
          hide-details
          class="shrink mr-2 mt-0"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <FactureFournisseurLignesList
          v-model="input.lignes"
          ref="lignesList"
          @formValue="ligneTmp = $event"
        >
          <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
            <slot :name="name" v-bind="data"></slot>
          </template>
        </FactureFournisseurLignesList>

        <FactureFournisseurLigneForm v-model="input.lignes" ref="lignesForm" :ligneToAddFromParent="ligneTmp">
          <template v-slot:customLigneForm="{ ligneToAdd }">
            <slot name="customLigneForm" :ligneToAdd="ligneToAdd"> </slot>
          </template>
        </FactureFournisseurLigneForm>
      </v-col>
    </v-row>
  </FormLayout>
</template>

<script>
import dayjs from "dayjs";
import { mapState } from "vuex";

import {
  MUTATION_factureFournisseurCreate,
  MUTATION_factureFournisseurUpdate,
} from "./graphql/mutations";
import { QUERY_factureFournisseur } from "./graphql/queries";

import ClientSelector from "./ClientSelector.vue";
import DateInput from "@/components/COMPONENTS-skeleton/Inputs/DateInput";
import FactureFournisseurLigneForm from "./FactureFournisseurLigneForm.vue";
import FactureFournisseurLignesList from "./FactureFournisseurLignesList.vue";
import FournisseurSelector from "./FournisseurSelector.vue";
import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";

export default {
  name: "FactureFournisseurForm",

  components: {
    ClientSelector,
    DateInput,
    FactureFournisseurLigneForm,
    FactureFournisseurLignesList,
    FournisseurSelector,
    FormLayout,
  },

  props: {
    client: undefined,
    componentParent: undefined,
    fournisseur: undefined,
  },

  data: () => ({
    factureFournisseur: { lignes: [] },
    input: {},
    ligneTmp: {}
  }),

  computed: {
    ...mapState(["formsErrors"]),
  },

  watch: {
    // En cas de passage de la vue edit à add par exemple
    $route() {
      this.init();
    },
  },

  mounted: function () {
    this.init();
  },

  methods: {
    ajouter() {
      let toSend = this.inputConverter(this.input);
      console.log(toSend)
      this.$apollo
        .mutate({
          mutation: MUTATION_factureFournisseurCreate,
          variables: {
            input: toSend,
          },
        })
        .then((__) => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "La facture a été ajoutée",
          });

          if (this.componentParent) {
            this.$root.$emit(
              this.componentParent,
              __.data.factureFournisseurCreate
            );
            this.$root.$emit("skeleton--close");
          }else {
            this.$router.push({ path: `/devis-facture/facture-fournisseurs/edit/${__.data.factureFournisseurCreate.id}`})
          }

          this.init();
        });
    },

    async init() {
      // Si on est dans le cas d'une édition
      if (
        this.$route.path.startsWith(
          "/devis-facture/facture-fournisseurs/edit/"
        ) &&
        this.$route.params.id
      ) {
        let id = this.$route.params.id;
        this.$apollo
          .query({
            query: QUERY_factureFournisseur,
            variables: {
              id: id,
            },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let factureFournisseur = data.data.factureFournisseur;
            for(let ligne of factureFournisseur.lignes) {
              if(ligne.meta == null) {
                ligne.meta = {
                  financement: true,
                  apparaitDansContrat: true
                }
              }
            }
            this.input = JSON.parse(JSON.stringify(factureFournisseur));
          });
      } else {
        if (this.client) {
          this.factureFournisseur.client = this.client;
        }
        if (this.fournisseur) {
          this.factureFournisseur.fournisseur = this.fournisseur;
        }
        this.factureFournisseur.noComptabilite = false;
        this.factureFournisseur.dateFacture = dayjs();
        this.factureFournisseur.dateEcheance = dayjs().add(30, "day");
        this.input = JSON.parse(JSON.stringify(this.factureFournisseur));
      }
    },

    inputConverter(arg) {
      const obj = {
        ...arg,
      };
      if (Object.keys(obj).length == 0) {
        return "";
      }
      let lignes = [];
      for (let objLigne of obj.lignes) {
        let equipements = [];
        for (let objEquipement of objLigne.equipements) {
          equipements.push(
            objEquipement && objEquipement.id ? objEquipement.id : objEquipement
          );
        }
        let inputLigne = {
          compteAnalytique: objLigne.compteAnalytique,
          quantite: objLigne.quantite,
          equipements: equipements,
          unite: objLigne.unite,
          label: objLigne.label,
          prixUnitaire: objLigne.prixUnitaire,
          tauxTva: objLigne.tauxTva,
          meta: objLigne.meta,
        };
        lignes.push(inputLigne);
      }

      let input = {
        id: obj.id,
        client: obj.client && obj.client.id ? obj.client.id : obj.client,
        fournisseur:
          obj.fournisseur && obj.fournisseur.id
            ? obj.fournisseur.id
            : obj.fournisseur,
        noComptabilite: obj.noComptabilite,
        numeroFacture: obj.numeroFacture,
        lignes: lignes,
        meta: obj.meta,
        dateEcheance: obj.dateEcheance,
        dateInstallation: obj.dateInstallationSwitch ? obj.dateInstallation : undefined,
        dateFacture: obj.dateFacture
      };
      return input;
    },

    modifier() {
      this.$store.commit("resetFormsErrors");
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_factureFournisseurUpdate,
          variables: {
            input: toSend,
          },
        })
        .then(() => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "La facture a été modifiée",
          });
          this.$root.$emit("skeleton--close");
        });
    },
  },
};
</script>