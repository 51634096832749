<template>
  <div>
    <ListLayout ref="list" :headers="headers" @fetch="init">
      <template v-slot:[`item.browserName`]="{ item }">
        {{ item.browserName }}
      </template>
      <template v-slot:[`item.OSName`]="{ item }">
        {{ item.OSName }}
      </template>
      <template v-slot:[`item.isMobile`]="{ item }">
        <v-icon v-if="item.isMobile">mdi-cellphone</v-icon>
        <v-icon v-else>mdi-laptop</v-icon>
      </template>
      <template v-slot:[`item.lastConnexion`]="{ item }">
        {{ item.lastConnexion | shortDateAndHourFR }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <EquipementActions :object="item" :fetch="fetchEquipements" size="xs" />
      </template>
    </ListLayout>
  </div>
</template>
<script>
import ListLayout from "@/components/COMPONENTS-skeleton/Layouts/ListLayout.vue";
import EquipementActions from "./EquipementActions.vue";
import { QUERY_equipements } from "./graphql/queries";
export default {
  name: "EquipementsList",

  components: {
    ListLayout,
    EquipementActions,
  },

  data: () => ({
    search: null,

    headers: [
      { text: "Navigateur", value: "browserName" },
      { text: "Système d'exploitation", value: "OSName" },
      { text: "Appareil", value: "isMobile" },
      { text: "Dernière connexion", value: "lastConnexion" },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),

  methods: {
    fetchEquipements() {
      this.$refs.list.loading = true;
      this.$apollo
        .query({
          query: QUERY_equipements,
          variables: {
            pagination: this.$refs.list.pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          this.$refs.list.items = data.equipements;
          this.$refs.list.itemsCount = data.equipementsCount;
          this.$refs.list.loading = false;
        });
    },
    init() {
      this.fetchEquipements();
    },
  },
};
</script>