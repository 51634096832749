<template>
  <div>
    <template v-if="size == 'xl'">
      <v-card class="mx-auto" max-width="100%" min-height="300">
        <v-card-title
          v-if="hasTitreSlot"
          :class="`${
            item && item.isActif == false ? 'warning' : 'primary'
          } white--text py-2`"
        >
          <v-row>
            <v-col>
              <v-icon v-if="hasIconSlot" dark class="mr-2">
                <slot name="icon"></slot>
              </v-icon>
              <slot name="titre"></slot>
            </v-col>
          </v-row>

          <v-icon v-if="item && item.isActif == false" dark class="mr-2">
            mdi-delete
          </v-icon>
        </v-card-title>

        <v-card-text>
          <v-card-subtitle
            v-if="subTitle"
            class="justify-center py-6 pl-8 text-subtitle-1"
          >
            <template>{{ subTitle }}</template>
          </v-card-subtitle>
          <div
            v-if="hasIconSlot"
            style="position: absolute; bottom: 10px; right: 10px; opacity: 0.2"
          >
            <v-icon style="font-size: 120px"><slot name="icon"></slot></v-icon>
          </div>
          <v-row justify-space-between>
            <v-col>
              <slot name="body"> </slot>
            </v-col>
            <v-col v-if="hasBody2Slot">
              <slot name="body2"> </slot>
            </v-col>
          </v-row>
        </v-card-text>
        <v-sheet
          color="info"
          class="px-6 style-inline"
          v-if="item && (item.dateCreation || item.createdBy)"
        >
          <p class="mb-n2 text-caption">
            <v-icon class="mt-n1" small>mdi-information</v-icon>
            Créé
            <span v-if="item.dateCreation">
              le {{ item.dateCreation | shortDateFR }}
            </span>
            <span v-if="item.createdBy"> par {{ item.createdBy.email }} </span>
          </p>
        </v-sheet>
      </v-card>
    </template>
    <template v-if="size == 'lg'">
      <v-card class="mx-auto" max-width="370" min-height="200">
        <v-card-title v-if="hasTitreSlot" class="primary white--text py-2">
          <v-icon v-if="hasIconSlot" dark class="mr-2">
            <slot name="icon"></slot>
          </v-icon>
          <slot name="titre"></slot>
        </v-card-title>
        <v-col>
          <slot name="body"></slot>
        </v-col>
        <v-card-text>
          <v-row align="center">
            <v-col cols="12">
              <slot name="body"></slot>
            </v-col>
            <v-col cols="12" v-if="hasBody2Slot">
              <slot name="body2"></slot>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>

    <template v-if="size == 'xs'">
      <div
        :style="styleXs"
      >
        <v-tooltip
          bottom
          :disabled="!hasTooltipSlot"
          max-width="250"
          min-height="100"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              :color="color"
              :class="`font-weight-medium mt-1 ${
                hasExtracontentSlot ? '' : ''
              }`"
              @click="onClickPush && action != 'none' ? clickAction() : false"
              pill
              style="z-index: 2"
              v-bind="attrs"
              v-on="on"
            >
              <v-avatar
                v-if="hasImageSlot"
                class="mr-2 ml-n3"
                style="min-width: 32px !important"
              >
                <slot name="image">NA</slot>
              </v-avatar>
              <v-avatar left v-if="hasIconSlot">
                <v-icon small>
                  <slot name="icon">mdi-chevron-right </slot>
                </v-icon>
              </v-avatar>

              <v-col class="mt-1">
                <v-row>
                  <span class="text-truncate pr-2">
                    <slot name="content"></slot>
                  </span>
                </v-row>

                <v-row v-if="hasSubcontentSlot" class="text-caption">
                  <div class="mt-n1">
                    <slot name="subcontent"></slot>
                  </div>
                </v-row>
              </v-col>
            </v-chip>

            <v-chip
              class="font-weight-medium pr-0 mt-1 ml-n10 pl-12"
              :color="subcolor"
              v-if="hasExtracontentSlot"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on" class="text-truncate mr-2">
                    <slot name="extracontent">NA</slot>
                  </span>
                </template>
                <span><slot name="extracontent"></slot></span>
              </v-tooltip>

              <v-chip class="font-weight-medium" v-if="hasSuppleycontentSlot">
                <div class="mt-n1"><slot name="suppleycontent"></slot></div>
              </v-chip>
            </v-chip>
          </template>
          <p
            v-if="hasTooltipSlot"
            class="white--text font-weight-regular text-subtitle-2"
          >
            <slot name="tooltip" />
          </p>
        </v-tooltip>
      </div>
    </template>

    <template v-if="size == 'md'">
      <v-col class="d-inline-flex align-center pa-2">
        <div class="mr-2">
          <v-icon><slot name="icon">mdi-chevron-right </slot></v-icon>
        </div>
        <div :class="color ? color + '--text' : null">
          <p class="mb-0"><slot name="titre">Titre</slot></p>
          <p class="mb-0 font-weight-bold">
            <slot name="content">NA</slot>
          </p>
        </div>
      </v-col>
    </template>
    <template v-if="size == 'text'">
      <v-tooltip bottom :disabled="!hasTooltipSlot" max-width="200">
        <template v-slot:activator="{ on, attrs }">
          <span class="font-weight-medium" v-bind="attrs" v-on="on">
            <v-icon dense><slot name="icon">mdi-user </slot></v-icon>
            <slot></slot>
          </span>
        </template>
        <p v-if="hasTooltipSlot" class="text-subtitle-2 text-center">
          <slot name="tooltip" />
        </p>
      </v-tooltip>
    </template>
  </div>
</template>

<script>
export default {
  name: "ObjectLayout",
  props: {
    item: { type: Object, default: undefined },
    size: { type: String, default: "md" },
    color: { type: String, default: "" },
    subcolor: { type: String, default: "primary" },
    onClickPush: { type: String, default: undefined },
    subTitle: { type: String, default: null },
    styleXs: {
      type: String,
      default() {
        return "max-width: calc(55vw - 32px);position: relatif;text-align: right;";
      }
    },
    //router = router.push normal
    //popup = router push dans popup
    //none = pas d'action
    action: { type: String, default: "router" },
  },
  computed: {
    hasContentSlot() {
      return !!this.$slots.content;
    },
    hasExtracontentSlot() {
      return !!this.$slots.extracontent;
    },
    hasSuppleycontentSlot() {
      return !!this.$slots.suppleycontent;
    },
    hasImageSlot() {
      return !!this.$slots.image;
    },
    hasIconSlot() {
      return !!this.$slots.icon;
    },
    hasSubcontentSlot() {
      return !!this.$slots.subcontent;
    },
    hasTitreSlot() {
      return !!this.$slots.titre;
    },
    hasBody2Slot() {
      return !!this.$slots.body2;
    },
    hasBody3Slot() {
      return !!this.$slots.body3;
    },
    hasTooltipSlot() {
      return !!this.$slots.tooltip;
    },
  },
  methods: {
    //Lors du clic sur un objet compatible (xs)
    clickAction() {
      // Par défaut on utilise router.push, on actualise la vue chargée dans router-view
      if (this.action == "router") {
        this.$router.push({ path: this.onClickPush });
      } else if (this.action == "popup") {
        // si on choisit d'utiliser une popup, on ajoute une option à la fin de l'URL
        // router/index.js se charge de la suite
        this.$router.push({ path: this.onClickPush + "?popup=true" });
      }
    },
  },
};
</script>

<style>
.style-inline > * {
  display: inline-block;
}
</style>