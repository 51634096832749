<template>
  <div>
    <ViewLayout :tabs="tabs" :item="etablissement">
      <template v-slot:etablissement v-if="etablissement.id">
        <EtablissementActions
          :object="etablissement"
          :withView="false"
          size="xl"
        />
        <v-row class="align-center mx-auto">
          <v-col cols="12">
            <EtablissementObject :etablissementProp="etablissement" size="xl" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <PersonnesList
              :noFilter="true"
              :etablissementID="etablissement.id"
            />
          </v-col>
        </v-row>
      </template>
    </ViewLayout>
  </div>
</template>

<script>
import EtablissementActions from "./EtablissementActions.vue";
import EtablissementObject from "./EtablissementObject.vue";
import PersonnesList from "./PersonnesList.vue";
import { QUERY_etablissement } from "./graphql/queries";
import ViewLayout from "@/components/COMPONENTS-skeleton/Layouts/ViewLayout.vue";
export default {
  name: "EtablissementView",
  components: {
    EtablissementObject,
    EtablissementActions,
    PersonnesList,
    ViewLayout,
  },
  props: {
    showPopup: {
      type: Boolean,
    },
    id: {
      type: String,
    },
  },
  data: () => ({
    etablissement: {},
    tabs: [
      { icon: "mdi-home", displayName: "Etablissement", name: "etablissement" },
      { name: "commentaires" },
      { name: "fichiers" },
    ],
  }),
  apollo: {
    etablissement: {
      query: QUERY_etablissement,
      variables() {
        let idEtablissement = this.showPopup
          ? this.id
          : this.$route.params.id
          ? this.$route.params.id
          : this.id;
        return {
          id: idEtablissement,
        };
      },
    },
  },
};
</script>