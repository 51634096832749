<template>
  <div>
    <v-container>
      <UserMeActions :object="userMe" size="xl" />
      <v-row class="justify-center">
        <v-col cols="8" class="justify-center">
          <UserObject :userProp="userMe" size="xl" class="py-4" />
          <EquipementsList class="py-4" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { QUERY_userMe } from "./graphql/queries";
import UserMeActions from "@/components/COMPONENTS-authentification-habilitation/UserMeActions.vue";
import EquipementsList from "@/components/COMPONENTS-authentification-habilitation/EquipementsList.vue";
import UserObject from "@/components/COMPONENTS-authentification-habilitation/UserObject.vue";

export default {
  name: "UserMeView",

  components: {
    UserObject,
    EquipementsList,
    UserMeActions,
  },

  data: () => ({
    userMe: {},
  }),

  apollo: {
    userMe: {
      query: QUERY_userMe,
    },
  },
  props: {
    showPopup: {
      type: Boolean,
    },
    id: {
      type: String,
    },
  },
};
</script>


