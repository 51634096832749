<template>
  <div>Redirection...</div>
</template>

<script>
import { QUERY_lienService } from "./graphql/queries";
export default {
  name: "LienServiceRedirecter",
  methods: {
    redirect() {
      let id = this.$route.query.lienservice;

      this.$apollo
        .query({
          query: QUERY_lienService,
          variables: { id: id, redirect: true },
          fetchPolicy: "no-cache",
        })
        .then((data) => {
           
          let lienService = data.data.lienService;
          if (lienService.asset) {
            this.$router.push("/assets/assets/view/" + lienService.asset.id);
          } else {
             
            this.$router.push(
              "/assets/lienservices/actionsView/" + lienService.id
            );
          }
        });
    },
  },
  mounted() {
    this.redirect();
  },
};
</script>