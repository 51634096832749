<template>
  <div>CessionObject</div>
</template>

<script>
export default {
  name: "CessionObject",
};
</script>

