<template>
  <div>
    <UserEditPasswordForm 
      :resetPassword="true"
      :errorMessages="formsErrors"
      @validForm="validForm"
    />
  </div>
</template>

<script>
import UserEditPasswordForm from "./UserEditPasswordForm.vue";
import { MUTATION_userResetPasswordById } from "./graphql/mutations.js";
import { mapState } from "vuex";

export default {
  name: "UserPasswordResetProvider",

  components: {
    UserEditPasswordForm
  },

  computed: {
    ...mapState(["formsErrors"]),
  },

  methods: {
    validForm(input) {
      this.$apollo
        .mutate({
          mutation: MUTATION_userResetPasswordById,
          variables: {
            id: this.$route.params.user_id,
            password: input.password,
            passwordConfirmation: input.passwordConfirmation
          },
          fetchPolicy: 'no-cache'
        })
        .then(() => {
          this.$store.commit("addAlert", {
            type: "success",
            text: "Le mot de passe a été modifié",
          });
          this.$root.$emit("skeleton--close");
        })
    }
  }
}
</script>