// Correction bug build 
// https://cli.vuejs.org/guide/browser-compatibility.html#usebuiltins-usage
import 'core-js/stable'; import 'regenerator-runtime/runtime';
import Vue from 'vue'
import App from './components/COMPONENTS-skeleton/App.vue'
import store from './components/COMPONENTS-skeleton/store'
import vuetify from './plugins/vuetify';
import VueMask from 'v-mask'
import { createProvider } from './components/COMPONENTS-skeleton/vue-apollo'
import router from './components/COMPONENTS-skeleton/router'
import { AuthorizationVuePlugin } from '@aidalinfo/authorization-package';
import VueSignaturePad from 'vue-signature-pad';
import { FiltersPlugin } from '@aidalinfo/lib-vue-helpers-package'

import * as VueGoogleMaps from 'vue2-google-maps'


Vue.config.productionTip = false
Vue.use(AuthorizationVuePlugin);
Vue.use(VueSignaturePad);
Vue.use(FiltersPlugin);
Vue.use(VueMask, {
  placeholders: {
    B: /[A-Z0-9]/,
    C: /[A-Z]/
  }
})
Vue.use(VueGoogleMaps, {
  load: {
    key: "DEFINE_IN_APP.VUE"
  },
});

new Vue({
  vuetify,
  apolloProvider: createProvider(),
  store,
  router,
  render: h => h(App)
}).$mount('#app')