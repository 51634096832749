<template>
  <div>AvoirView</div>
</template>

<script>
export default {
  name: "AvoirView",
};
</script>

