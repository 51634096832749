var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ListLayout',{ref:"list",attrs:{"headers":_vm.headers},on:{"fetch":_vm.init,"showMultipleActions":function($event){_vm.showMultipleActions = $event}},scopedSlots:_vm._u([{key:"multipleActions",fn:function(){return [_c('AffaireActions',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMultipleActions),expression:"showMultipleActions"}],attrs:{"multiple":true,"object":_vm.$refs.list ? _vm.$refs.list.itemsSelected : [],"size":"xl"},on:{"fetch":_vm.init,"loading":function($event){_vm.$refs.list.loading = true}}})]},proxy:true},{key:"item.object",fn:function(ref){
var item = ref.item;
return [_c('AffaireObject',{attrs:{"affaireProp":item,"size":"xs"}})]}},{key:"item.dateCreation",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("shortDateFR")(item.dateCreation))+" ")]}},{key:"item.client",fn:function(ref){
var item = ref.item;
return _vm._l((item.financement.repartition),function(repartition,index){return _c('div',{key:index},[(index <= 1)?_c('ClientObject',{attrs:{"clientID":repartition.client.id,"size":"xs"}}):_c('p',[_vm._v("...")])],1)})}},{key:"item.montantFinancement",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("monetaire")(item.montantFinancement))+" ")]}},{key:"item.datePrevInstallation",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("shortDateFR")(item.datePrevInstallation))+" ")]}},{key:"item.datePrevLoyer",fn:function(ref){
var item = ref.item;
return [_c('AffaireSimulation',{attrs:{"affaire":item,"size":"text-datePremierLoyer"}})]}},{key:"item.demandeAccords",fn:function(ref){
var item = ref.item;
return [_c('AffaireObject',{attrs:{"affaireProp":item,"size":"xs-demandeAccords"}})]}},{key:"item.gainsPotentiels",fn:function(ref){
var item = ref.item;
return [_c('AffaireSimulation',{attrs:{"affaire":item,"size":"xs"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('AffaireActions',{attrs:{"object":item,"size":"xs"},on:{"fetch":_vm.init,"loading":function($event){_vm.$refs.list.loading = true}}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }