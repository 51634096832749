<template>
  <div class="v-alert-container">
    <v-alert
      v-for="(alert, index) in alerts"
      :key="index"
      v-model="alert.showing"
      dense
      :type="alert.type"
      dismissible
      class="mb-0"
      app
    >
      {{ alert.text }}
    </v-alert>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "SkeletonAlert",
  data: () => ({}),
  computed: {
    ...mapState(["alerts"]),
  },
};
</script>
<style scoped>
.v-alert-container {
  position: fixed;
  top: 64px;
  width: 100%;
  margin: 0 auto;
  z-index: 10;
}
</style>