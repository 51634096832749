<template>
  <v-form>
    <v-row>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="primary"
            v-bind="attrs"
            v-on="on"
            class="mt-6"
            @click="onChangeButtonClick"
          >
            <v-icon>
              {{ addEquipement ? "mdi-widgets" : "mdi-package-variant-closed" }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ addEquipement ? "Autre" : "Equipements" }}</span>
      </v-tooltip>
      <v-col>
        <v-combobox
          v-model="ligneToAdd.compteAnalytique"
          :items="factureFournisseurLigneCompteAnalytique"
          label="Compte Analytique"
          chips
          clearable
          item-text="ligneToAdd.compteAnalytique"
          prepend-icon="mdi-table"
          dense
          class="pt-1"
        />
      </v-col>
      <v-col cols="1">
        <v-text-field
          v-model.number="ligneToAdd.quantite"
          type="number"
          label="Quantité"
          :disabled="addEquipement"
        />
      </v-col>

      <v-col>
        <v-combobox
          v-model="ligneToAdd.unite"
          :items="factureFournisseurLigneUnite"
          :label="addEquipement ? 'Equipement' : 'Unité'"
          chips
          clearable
          item-text="ligneToAdd.unite"
          prepend-icon="mdi-scale"
          dense
          class="pt-1"
          :disabled="addEquipement"
        />
      </v-col>
      <v-col v-if="!addEquipement">
        <v-text-field v-model="ligneToAdd.label" label="Label" />
      </v-col>

      <v-col>
        <v-text-field
          v-model.number="ligneToAdd.prixUnitaire"
          type="number"
          suffix="€"
          label="Prix unitaire H.T."
        />
      </v-col>
      <v-col>
        <v-text-field
          v-model.number="ligneToAdd.tauxTva"
          type="number"
          suffix="%"
          label="Taux T.V.A."
        />
      </v-col>
      <v-col cols="3">
        <slot name="customLigneForm" :ligneToAdd="ligneToAdd"> </slot>
      </v-col>
      <v-col>
        <v-row class="pt-6 justify-center">
          <v-btn color="primary" fab x-small dark @click="addLigne()">
            <v-icon>mdi-plus-circle</v-icon>
          </v-btn>

          <v-btn
            color="warning"
            class="ml-4"
            fab
            x-small
            dark
            @click="resetLigne()"
          >
            <v-icon>mdi-eraser</v-icon>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <template v-if="addEquipement">
      <AssetAttach v-model="ligneToAdd.equipements" />
    </template>
  </v-form>
</template>

<script>
import _ from "lodash";

import {
  QUERY_factureFournisseurLigneCompteAnalytique,
  QUERY_factureFournisseurLigneUnite,
} from "./graphql/queries";

import AssetAttach from "@/components/COMPONENTS-assets/AssetAttach.vue";

export default {
  name: "FactureFournisseurLigneForm",

  components: {
    AssetAttach,
  },

  created() {
    this.$root.$on("changeAddEquipement", this.changeAddEquipement);
  },

  props: {
    value: [],
    ligneToAddFromParent: {
      type: Object,
      default() {
        return {};
      }
    }
  },

  data: () => ({
    addEquipement: false,
    ligneToAdd: {
      quantite: 1
    },
  }),

  apollo: {
    factureFournisseurLigneCompteAnalytique: {
      query: QUERY_factureFournisseurLigneCompteAnalytique,
      fetchPolicy: "no-cache",
    },
    factureFournisseurLigneUnite: {
      query: QUERY_factureFournisseurLigneUnite,
      fetchPolicy: "no-cache",
    },
  },

  mounted: function () {
    this.resetLigne();
  },

  methods: {
    addLigne: function () {
      let tableau = _.concat(this.value, this.ligneToAdd);

      this.$emit("input", tableau);
      this.resetLigne();
    },

    changeAddEquipement() {
      this.addEquipement = !this.addEquipement;
      this.resetLigne();
    },

    onChangeButtonClick() {
      if(this.ligneToAdd.equipements && this.ligneToAdd.equipements.length > 0 || this.ligneToAdd.label != "") {
        let toValid = {
          titre: "Changement de formulaire",
          texte: "Vous êtes sur le point de changer de formulaire en ayant des informations non enregistrées. Êtes-vous sûr de vouloir continuer ?",
          type: "warning",
          toReturn: {
            function: "changeAddEquipement"
          },
        };

        this.$root.$emit("popupValidation", toValid);
      }else {
        this.changeAddEquipement();
      }
    },

    resetLigne: function () {
      let meta = this.ligneToAdd.meta
        ? JSON.parse(JSON.stringify(this.ligneToAdd.meta))
        : {};
      this.ligneToAdd = {
        compteAnalytique: "",
        quantite: 1,
        label: "",
        prixUnitaire: 0,
        equipements: [],
        tauxTva: 20,
        meta: meta,
      };
    },
  },

  watch: {
    "ligneToAdd.equipements": function (val) {
      this.ligneToAdd.unite = "Equipement(s)";
      this.ligneToAdd.quantite = val.length == 0 ? 1 : val.length;
    },

    ligneToAddFromParent(value) {
      if(value.equipements && value.equipements.length > 0) {
        this.addEquipement = true;
      }else {
        this.addEquipement = false;
      }

      function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }

      sleep(1).then(() => {this.ligneToAdd = JSON.parse(JSON.stringify(value));})
    }
  },
};
</script>