<template>
  <v-form @submit.prevent="validForm" class="mx-5">
    <template v-if="replyTo">
      <v-row align="center">
        <v-col>
          <CommentaireObject :commentaire="replyTo" size="s" />
        </v-col>
        <v-btn icon @click="$emit('replyTo', null)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-row>
    </template>
    <template v-for="type in types">
      <v-btn
        :key="type.value"
        icon
        :color="input.type == type.value ? type.value : ''"
        @click="input.type = type.value"
      >
        <v-icon>{{ type.icon }}</v-icon>
      </v-btn>
    </template>
    <v-row>
      <v-col>
        <v-textarea
          no-details
          outlined
          :color="input.type"
          append-outer-icon="mdi-send"
          v-model="input.texte"
          :label="
            method == 'reply'
              ? 'Répondre'
              : input.id
              ? 'Editer un commentaire'
              : 'Nouveau commentaire'
          "
          @keydown.enter="$event => validForm($event)"
          @click:append-outer="validForm()"
          required
        >
        </v-textarea>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import _ from "lodash";
import CommentaireObject from "./CommentaireObject.vue";
import {
  MUTATION_commentaireCreate,
  MUTATION_commentaireUpdate,
} from "./graphql/mutations";
export default {
  name: "CommentaireForm",
  components: {
    CommentaireObject,
  },
  props: {
    commentaire: {
      type: Object,
      default() {
        return {
          id: undefined,
          type: "info",
          texte: "",
        };
      },
    },
    replyTo: { type: Object },
    method: { type: String },
    typeParent: { type: String },
    idParent: { type: String },
  },
  data: () => ({
    input: {},
    types: [
      { text: "Validation", value: "success", icon: "mdi-check-circle" },
      { text: "Information", value: "info", icon: "mdi-information" },
      { text: "Attention", value: "warning", icon: "mdi-alert-circle" },
      { text: "Problème", value: "error", icon: "mdi-close-circle" },
    ],
  }),

  mounted: function () {
    this.resetForm();
  },
  watch: {
    method: function () {
      this.resetForm();
    },
  },

  created() {
    if (!this.$root._events.addCommentaire) {
      this.$root.$on("addCommentaire", this.ajouter);
    }
    if (!this.$root._events.updateCommentaire) {
      this.$root.$on("updateCommentaire", this.modifier);
    }
  },
  methods: {
    ajouter(idCommentaireParent = undefined) {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_commentaireCreate,
          variables: {
            input: toSend,
            idCommentaireParent: idCommentaireParent,
          },
        })
        .then(() => {
          this.$root.$emit("collaborationUpdateCommentaires");
          this.$parent.methodForm = undefined;
          this.resetForm();
        });
    },
    inputConverter(arg) {
      const obj = JSON.parse(JSON.stringify(arg));
      if (_.isEmpty(obj)) {
        return "";
      }
      let input = {
        commentaireParent: this.replyTo ? this.replyTo.id : null,
        type: obj.type,
        texte: obj.texte,
        meta: [
          {
            typeParent: this.typeParent,
            idParent: this.idParent,
          },
        ],
      };
      return input;
    },
    modifier() {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_commentaireUpdate,
          variables: {
            id: this.commentaire.id,
            input: toSend,
          },
        })
        .then(() => {
          this.$root.$emit("collaborationUpdateCommentaires");
          this.$parent.methodForm = undefined;
        });
    },

    resetForm() {
      if (this.method == "reply") {
        this.input = {
          id: undefined,
          type: "info",
          texte: "",
        };
      } else {
        this.input = JSON.parse(JSON.stringify(this.commentaire));
      }
      this.$emit("replyTo", null);
    },
    validForm(e) {
      if(e && !e.shiftKey) {
        if (this.method == "reply") {
          this.ajouter(this.commentaire.id);
        } else {
          this.commentaire.id ? this.modifier() : this.ajouter();
        }
      }
    },
  },
};
</script>

