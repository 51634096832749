<template>
  <div>CessionSelector</div>
</template>

<script>
export default {
  name: "CessionSelector",
};
</script>

