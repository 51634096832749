<template>
  <div
    v-if="userMe.preferences && userMe.preferences.assistant"
    class="assistant ml-4"
    width="100%"
  >
    <p
      class="font-weight-medium box sb3 elevation-5"
      v-html="message"
      v-if="msgVisibility"
      @click="msgVisibilityToogle()"
    ></p>
    <v-btn class="mt-3 ml-n6" fab large @click="msgVisibilityToogle()">
      <v-icon x-large color="primary" class="mb-1"> mdi-robot </v-icon>
    </v-btn>
  </div>
</template>

<script>
import { QUERY_userMe } from "@/components/COMPONENTS-authentification-habilitation/graphql/queries";
export default {
  name: "SkeletonAssistant",
  data: () => ({
    msgVisibility: true,
    userMe: {},
  }),
  apollo: {
    userMe: {
      query: QUERY_userMe,
    },
  },
  props: {
    message: { type: String, default: "Hello !" },
    mood: { type: String, default: "default" },
  },
  watch: {
    message: function () {
      this.msgVisibility = true;
    },
  },
  methods: {
    msgVisibilityToogle() {
      this.msgVisibility = !this.msgVisibility;
    },
  },
};
</script>

<style lang="scss">
// @import url("https://fonts.googleapis.com/css2?family=Comic+Neue:wght@700&display=swap");

.assistant {
  position: fixed;
  bottom: 64px;
  z-index: 5;
  // font-family: "Comic Neue", cursive;
}
.box {
  width: 300px;
  margin: 50px auto;
  background: var(--v-primary-base);
  padding: 20px;
  text-align: center;
  color: #fff;
  position: relative;
}

.sb3:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid var(--v-primary-base);
  border-right: 10px solid transparent;
  border-top: 10px solid var(--v-primary-base);
  border-bottom: 10px solid transparent;
  left: 19px;
  bottom: -19px;
}
</style>