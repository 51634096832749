var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ListLayout',{ref:"list",attrs:{"headers":_vm.headers},on:{"fetch":_vm.init,"showMultipleActions":function($event){_vm.showMultipleActions = $event}},scopedSlots:_vm._u([{key:"multipleActions",fn:function(){return [_c('FichierActions',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMultipleActions),expression:"showMultipleActions"}],attrs:{"multiple":true,"withView":false,"object":_vm.$refs.list ? _vm.$refs.list.itemsSelected : [],"size":"xl"},on:{"fetch":_vm.init}})]},proxy:true},{key:"customFilters",fn:function(){return [_c('FichierTypeSelector',{model:{value:(_vm.customFilters.type),callback:function ($$v) {_vm.$set(_vm.customFilters, "type", $$v)},expression:"customFilters.type"}})]},proxy:true},{key:"item.object",fn:function(ref){
var item = ref.item;
return [_c('FichierObject',{attrs:{"fichierProp":item,"size":"xs"}})]}},{key:"item.createdBy",fn:function(ref){
var item = ref.item;
return [_c('UserObject',{attrs:{"userID":item.createdBy,"size":"xs"}})]}},{key:"item.personalData",fn:function(ref){
var item = ref.item;
return [(item.personalData)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check-circle ")]):_vm._e(),(!item.personalData)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-close-circle ")]):_vm._e()]}},{key:"item.securise",fn:function(ref){
var item = ref.item;
return [(item.securise)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check-circle ")]):_vm._e(),(!item.securise)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-close-circle ")]):_vm._e()]}},{key:"item.dateCreation",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("shortDateFR")(item.dateCreation))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('FichierActions',{attrs:{"isActif":_vm.$refs.list.itemsAreActive,"object":item,"size":"xs"},on:{"fetch":_vm.init}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }