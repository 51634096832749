<template>
  <div>
    <template v-if="reglementClient">
      <template v-if="size == 'xl'">
        <ObjectLayout :action="action" size="xl" :item="reglementClient">
          <template v-slot:icon>mdi-cash-register</template>

          <template v-slot:titre>
            <template v-if="reglementClient.client">
              {{ reglementClient.dateReglement | shortDateFR }} -
              {{ reglementClient.client.codeClient }} -
              {{ reglementClient.montantReglement | monetaire }}
            </template>
          </template>
          <template v-slot:body>
            <v-row>
              <v-col>
                <ObjectLayout v-if="reglementClient.client" class="py-2">
                  <template v-slot:icon>mdi-briefcase-account</template>
                  <template v-slot:titre> Client </template>
                  <template v-slot:content>
                    <ClientObject
                      :clientID="reglementClient.client.id"
                      size="xs"
                    />
                  </template>
                </ObjectLayout>
              </v-col>
              <v-col>
                <ObjectLayout v-if="reglementClient.factureClient" class="py-2">
                  <template v-slot:icon>mdi-file-table</template>
                  <template v-slot:titre> Facture associée </template>
                  <template v-slot:content>
                    <FactureClientObject
                      :factureClientID="reglementClient.factureClient.id"
                      size="xs"
                    />
                  </template>
                </ObjectLayout>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ObjectLayout class="py-2">
                  <template v-slot:icon>mdi-calendar</template>
                  <template v-slot:titre> Date de règlement </template>
                  <template v-slot:content>
                    {{ reglementClient.dateReglement | shortDateFR }}
                  </template>
                </ObjectLayout>
              </v-col>
              <v-col>
                <ObjectLayout class="py-2">
                  <template v-slot:icon>mdi-cash</template>
                  <template v-slot:titre> Montant du règlement </template>
                  <template v-slot:content>
                    {{ reglementClient.montantReglement | monetaire }}
                  </template>
                </ObjectLayout>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ObjectLayout class="py-2">
                  <template v-slot:icon>mdi-form-select</template>
                  <template v-slot:titre> Mode de règlement </template>
                  <template v-slot:content>
                    {{ reglementClient.modeReglement }}
                  </template>
                </ObjectLayout>
              </v-col>
              <v-col>
                <ObjectLayout class="py-2">
                  <template v-slot:icon>mdi-tag</template>
                  <template v-slot:titre> Identifiant du règlement </template>
                  <template v-slot:content>
                    {{ reglementClient.identifiantReglement }}
                  </template>
                </ObjectLayout>
              </v-col>
            </v-row>
          </template>
        </ObjectLayout>
      </template>
      <template v-if="size == 'md'">
        <ObjectLayout size="md">
          <template v-slot:icon>mdi-cash-register</template>
          <template v-slot:content>{{ reglementClient.id }}</template>
          <template v-slot:extracontent>
            <v-icon small class="mr-2">mdi-material-design</v-icon>
            {{ reglementClient.id }}
          </template>
        </ObjectLayout>
      </template>
      <template v-if="size == 'xs'">
        <ObjectLayout
          size="xs"
          :action="action"
          :onClickPush="`/devis-facture/reglement-clients/view/${reglementClient.id}`"
        >
          <template v-slot:icon>mdi-cash-register</template>
          <template v-slot:content>
            {{ reglementClient.dateReglement | shortDateFR }} -
            {{ reglementClient.montantReglement | monetaire }}
          </template>
        </ObjectLayout>
      </template>
    </template>
  </div>
</template>

<script>
import { QUERY_reglementClient } from "./graphql/queries";

import ClientObject from "./ClientObject.vue";
import FactureClientObject from "./FactureClientObject.vue";
import ObjectLayout from "@/components/COMPONENTS-skeleton/Layouts/ObjectLayout.vue";

export default {
  name: "ReglementClientObject",

  components: {
    ClientObject,
    FactureClientObject,
    ObjectLayout,
  },

  props: {
    action: {
      type: String,
      default: "router",
    },

    reglementClientProp: {
      type: Object,
      default() {
        return {};
      },
    },

    reglementClientID: {
      type: String,
    },

    size: { type: String, default: "md" },
  },

  data: () => ({
    reglementClient: undefined,
  }),

  watch: {
    reglementClientID: function () {
      this.init();
    },

    reglementClientProp: function () {
      this.init();
    },
  },

  mounted: function () {
    this.init();
  },

  methods: {
    init() {
      if (this.reglementClientID) {
        let id = this.reglementClientID;
        this.$apollo
          .query({
            query: QUERY_reglementClient,
            variables: { id: id },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let reglementClient = data.data.reglementClient;
            this.reglementClient = JSON.parse(JSON.stringify(reglementClient));
          });
      } else {
        this.reglementClient = JSON.parse(
          JSON.stringify(this.reglementClientProp)
        );
      }
    },
  },
};
</script>