var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ListLayout',{ref:"list",attrs:{"headers":_vm.headers},on:{"fetch":_vm.init,"showMultipleActions":function($event){_vm.showMultipleActions = $event}},scopedSlots:_vm._u([{key:"multipleActions",fn:function(){return [_c('FournisseurActions',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMultipleActions),expression:"showMultipleActions"}],attrs:{"multiple":true,"object":_vm.$refs.list ? _vm.$refs.list.itemsSelected : [],"size":"xl"},on:{"fetch":_vm.init,"loading":function($event){_vm.$refs.list.loading = true}}})]},proxy:true},{key:"item.fournisseur",fn:function(ref){
var item = ref.item;
return [_c('FournisseurObject',{attrs:{"fournisseurProp":item,"size":"xs"}})]}},{key:"item.devisFournisseurCount",fn:function(ref){
var item = ref.item;
return [_c('DevisFournisseurCounter',{attrs:{"customSearch":[{ fournisseur: item.id }]}})]}},{key:"item.factureFournisseursCount",fn:function(ref){
var item = ref.item;
return [_c('FactureFournisseursCounter',{attrs:{"customSearch":[{ fournisseur: item.id }]}})]}},{key:"item.servicesCount",fn:function(ref){
var item = ref.item;
return [_c('ServicesCounter',{attrs:{"customSearch":[{ fournisseur: item.id }]}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('FournisseurActions',{attrs:{"isActif":_vm.$refs.list.itemsAreActive,"object":item,"size":"xs"},on:{"fetch":_vm.init,"loading":function($event){_vm.$refs.list.loading = true}}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }