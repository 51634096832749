<template>
  <FormLayout
    icon="mdi-file-table"
    @ajouter="ajouter"
    @modifier="modifier"
    @reinitialiser="init"
    typeItem="une facture client"
    :item="input"
    :disabled="input.client && input.lignes && input.lignes[0] ? false : true"
  >
    <v-row>
      <v-col>
        <ClientSelector
          v-model="input.client"
          label="Client"
          :disabled="client ? true : false"
        />
      </v-col>
      <v-col>
        <PointFacturationSelector
          :client="input.client"
          v-model="input.pointFacturation"
          :disabled="input.client ? false : true"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <MoyenPaiementSelector
          v-model="input.moyenPaiement"
          :pointFacturation="input.pointFacturation"
          :disabled="input.pointFacturation ? false : true"
        />
      </v-col>
      <v-col></v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-checkbox
          label="Facture à retirer lors de la comptabilité"
          v-model="input.noComptabilite"
          hide-details
          class="shrink mr-2 mt-0"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <FactureClientLignesList
          v-model="input.lignes"
          ref="lignesList"
          @formValue="ligneTmp = $event"
        >
          <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
            <slot :name="name" v-bind="data"></slot>
          </template>
        </FactureClientLignesList>

        <FactureClientLigneForm v-model="input.lignes" ref="lignesForm" :ligneToAddFromParent="ligneTmp">
          <template v-slot:customLigneForm="{ ligneToAdd }">
            <slot name="customLigneForm" :ligneToAdd="ligneToAdd"> </slot>
          </template>
        </FactureClientLigneForm>
      </v-col>
    </v-row>
  </FormLayout>
</template>

<script>
import { mapState } from "vuex";

import {
  MUTATION_factureClientCreate,
  MUTATION_factureClientUpdate,
} from "./graphql/mutations";
import { QUERY_factureClient } from "./graphql/queries";

import ClientSelector from "./ClientSelector.vue";
import FactureClientLigneForm from "./FactureClientLigneForm.vue";
import FactureClientLignesList from "./FactureClientLignesList.vue";
import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";
import MoyenPaiementSelector from "./MoyenPaiementSelector.vue";
import PointFacturationSelector from "./PointFacturationSelector.vue";

export default {
  name: "FactureClientForm",

  components: {
    ClientSelector,
    FactureClientLigneForm,
    FactureClientLignesList,
    FormLayout,
    MoyenPaiementSelector,
    PointFacturationSelector,
  },

  props: {
    client: undefined,
    componentParent: undefined,
  },

  data: () => ({
    factureClient: { lignes: [] },
    input: {},
    ligneTmp: {}
  }),

  computed: {
    ...mapState(["formsErrors"]),
  },

  watch: {
    // En cas de passage de la vue edit à add par exemple
    $route() {
      this.init();
    },
  },

  mounted: function () {
    this.init();
  },

  methods: {
    ajouter() {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_factureClientCreate,
          variables: {
            input: toSend,
          },
        })
        .then((__) => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "La facture a été ajoutée",
          });

          if (this.componentParent) {
            this.$root.$emit(this.componentParent, __.data.factureClientCreate);
            this.$root.$emit("skeleton--close");
          }else {
            this.$router.push({ path: `/devis-facture/facture-clients/edit/${__.data.factureClientCreate.id}` })
          }

          this.init();
        });
    },

    async init() {
      // Si on est dans le cas d'une édition
      if (
        this.$route.path.startsWith("/devis-facture/facture-clients/edit/") &&
        this.$route.params.id
      ) {
        let id = this.$route.params.id;
        this.$apollo
          .query({
            query: QUERY_factureClient,
            variables: {
              id: id,
            },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let factureClient = data.data.factureClient;
            if(factureClient.valide) {
              this.$router.push({ path: '/' }).catch(() => {});
            }

            for(let ligne of factureClient.lignes) {
              if(ligne.meta == null) {
                ligne.meta = {
                  financement: true,
                  apparaitDansContrat: true
                }
              }
            }
            
            this.input = JSON.parse(JSON.stringify(factureClient));
          });
      } else {
        if (this.client) {
          this.factureClient.client = this.client;
        }
        this.factureClient.noComptabilite = false;
        this.input = JSON.parse(JSON.stringify(this.factureClient));
      }
    },

    inputConverter(arg) {
      const obj = {
        ...arg,
      };
      if (Object.keys(obj).length == 0) {
        return "";
      }
      let lignes = [];
      for (let objLigne of obj.lignes) {
        let equipements = [];
        for (let objEquipement of objLigne.equipements) {
          equipements.push(
            objEquipement && objEquipement.id ? objEquipement.id : objEquipement
          );
        }
        let inputLigne = {
          compteAnalytique: objLigne.compteAnalytique,
          quantite: objLigne.quantite,
          equipements: equipements,
          unite: objLigne.unite,
          label: objLigne.label,
          prixUnitaire: objLigne.prixUnitaire,
          tauxTva: objLigne.tauxTva,
          meta: objLigne.meta,
        };
        lignes.push(inputLigne);
      }

      let input = {
        id: obj.id,
        client: obj.client && obj.client.id ? obj.client.id : obj.client,
        lignes: lignes,
        meta: obj.meta,
        moyenPaiement:
          obj.moyenPaiement && obj.moyenPaiement.id
            ? obj.moyenPaiement.id
            : obj.moyenPaiement,
        noComptabilite: obj.noComptabilite,
        numeroFacture: obj.numeroFacture,
        pointFacturation:
          obj.pointFacturation && obj.pointFacturation.id
            ? obj.pointFacturation.id
            : obj.pointFacturation,
      };
      return input;
    },

    modifier() {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_factureClientUpdate,
          variables: {
            input: toSend,
          },
        })
        .then(() => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "La facture a été modifiée",
          });
          this.$root.$emit("skeleton--close");
        });
    },
  },
};
</script>