<template>
  <div>
    <ActionLayout
      :actions="actions"
      :size="size"
      :action="action"
      @fetch="$emit('fetch')"
      :mainIcon="mainIcon"
    />
  </div>
</template>

<script>
import ActionLayout from "@/components/COMPONENTS-skeleton/Layouts/ActionLayout.vue";
import {
  MUTATION_entreePlanningDelete,
  MUTATION_entreePlanningUpdate,
} from "./graphql/mutations.js";
export default {
  name: "EntreePlanningActions",
  components: {
    ActionLayout,
  },
  props: {
    withView: { type: Boolean, default: true },
    mainIcon: { type: String, default: "mdi-home" },
    object: { type: [Array, Object] },
    size: { type: String, default: "md" },
    action: { type: String, default: "router" },
    multiple: { type: Boolean, default: false },
  },
  computed: {
    actions() {
      return [
        {
          text: "Editer",
          icon: "mdi-pencil",
          path: "/plannings/entreeplanning/edit/" + this.object.id,
          acl: "/plannings/entreeplanning/edit",
          color: "",
          conditions: { multiple: false, isActif: true },
        },
        {
          text: "Supprimer",
          icon: "mdi-delete",
          method: "editIsActif",
          acl: "/plannings/entreeplanning/deleted",
          color: "",
          conditions: { multiple: true, isActif: true },
        },
        {
          text: "Restauration",
          icon: "mdi-restore",
          method: "editIsActif",
          acl: "/plannings/entreeplanning/edit",
          color: "",
          conditions: { multiple: true, isActif: false },
        },
        {
          text: "Supprimer définitivement",
          icon: "mdi-delete",
          method: "delete",
          acl: "/plannings/entreeplanning/deleted",
          color: "warning",
          conditions: { multiple: false, isActif: false },
        },
        {
          text: "Visualiser",
          icon: "mdi-eye",
          path: "/plannings/entreeplanning/view/" + this.object.id,
          acl: "/plannings/entreeplanning/view/",
          color: "",
          conditions: { size: "md xs" },
        },
      ];
    },
  },
  created() {
    this.$root.$on("deleteEntreePlanning", this.deleteEntreePlanning);
    this.$root.$on("editEntreePlanning", this.editEntreePlanning);
  },
  methods: {
    /**
     * Supprimer une entrée de planning grâce à son id
     */
     deleteEntreePlanning(id) {
      this.$apollo
        .mutate({
          mutation: MUTATION_entreePlanningDelete,
          variables: { id: id },
        })
        .then(() => {
          this.$emit("fetch");
        });
    },

    /**
     * Fonction à avoir dans tous les composants Actions (infos à changer selon le composant)
     * Permet de lancer la popup de validation
     *
     * obj : l'objet a supprimé
     */
    delete(obj) {
      // infos qui s'afficheront dans le popup
      let toValid = {
        titre: "Suppression définitive d'une enseigne",
        texte: `Êtes-vous sûr de vouloir supprimer définitivement le planning ${obj.nom} ?`,
        type: "warning",
        toReturn: {
          function: "deleteEntreePlanning",
          arg: obj.id,
        },
      };

      // Envoi l'info pour ouvrir la popup
      this.$root.$emit("popupValidation", toValid);
    },

    /**
     * Fonction qui édite une entreePlanning
     *
     * obj : l'objet à éditer
     */
    async editEntreePlanning(obj) {
      // Si obj n'est pas un tableau alors on le transforme en tableau
      if (!Array.isArray(obj)) {
        obj = [obj];
      }
      // Selon le schéma, il est obligé d'avoir le siren et la raison sociale dans EntepriseInput
      // Bien vérifier dans le schéma si jamais il y a une erreur du style "$input etc.."
      this.$emit("loading");
      for (let item of obj) {
        let entreePlanning = {
          id: item.id,
          isActif: !item.isActif,
        };
        
        await this.$apollo.mutate({
          mutation: MUTATION_entreePlanningUpdate,
          variables: {
            input: entreePlanning,
          },
        });
      }
      this.$emit("fetch");
    },

    /**
     * Fonction à avoir dans tous les composants (infos à changer)
     */
    editIsActif(obj) {
      if (!Array.isArray(obj)) {
        obj = [obj];
      }

      let toValid = {
        titre: obj.isActif
          ? "Suppression du planning"
          : "Restauration du planning",
        texte: obj.isActif
          ? `Êtes-vous sûr de vouloir supprimer ${obj.length} planning(s) ? ` +
            'Elles seront toujours présentes dans l\'onglet "Corbeille"'
          : `Êtes-vous sûr de vouloir restaurer ${obj.length} plann(s) ?`,
        type: "warning",
        toReturn: {
          function: "editEntreePlanning", // à changer selon le composant, comme deleteEnseigne
          arg: obj,
        },
      };

      this.$root.$emit("popupValidation", toValid);
    },
  },
};
</script>

