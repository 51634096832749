<template>
  <div>
    <template v-if="etablissement">
      <template v-if="size == 'xl'">
        <ObjectLayout size="xl" :item="etablissement">
          <template v-slot:icon>mdi-store-marker</template>
          <template v-slot:titre>
            {{
            etablissement.entreprise && etablissement.entreprise.raisonSociale
            ? etablissement.entreprise.raisonSociale
            : ""
            }}
            -
            <p class="font-weight-regular mb-0 ml-2">
              {{ etablissement.libelle }}
              <v-tooltip bottom v-if="etablissement.isSiege">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon dark v-bind="attrs" v-on="on" class="mt-n1">
                    mdi-crown
                  </v-icon>
                </template>
                <span>Siège social</span>
              </v-tooltip>
            </p>
          </template>
          <template v-slot:body>
            <AdressePostaleObject v-if="
              etablissement.adresse.latitude &&
              etablissement.adresse.longitude
            " :adresse="etablissement.adresse" size="xl" />
          </template>
          <template v-slot:body2>
            <AdressePostaleObject v-if="
              etablissement.adresse.latitude &&
              etablissement.adresse.longitude
            " :adresse="etablissement.adresse" size="md" />
            <ObjectLayout>
              <template v-slot:titre>SIRET</template>
              <template v-slot:content>
                {{ etablissement.siret }}
              </template>
            </ObjectLayout>
            <ObjectLayout v-if="etablissement.entreprise">
              <template v-slot:icon>mdi-domain</template>
              <template v-slot:titre>Entreprise</template>
              <template v-slot:content>
                <EntrepriseObject size="xs" :entrepriseProp="etablissement.entreprise" />
              </template>
            </ObjectLayout>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" v-bind="attrs" v-on="on" @click="useGeocodage" :disabled="isBtnDisabled">
                  Géocodage
                </v-btn>
              </template>
              <span>Utilise l'API de Google pour changer d'emplacement s'il est
                erroné</span>
            </v-tooltip>
          </template>
        </ObjectLayout>
      </template>

      <v-row v-if="size == 'md'">
        <v-list-item-content>
          <v-list-item-title v-html="etablissement.libelle"></v-list-item-title>
          <v-list-item-subtitle v-html="
            etablissement.siret +
            ' - ' +
            etablissement.adresse.codePostal +
            ' - ' +
            etablissement.adresse.libelleCommune
          " />
        </v-list-item-content>
      </v-row>
      <template v-if="size == 'xs' || size == 'xs-ville'">
        <ObjectLayout :action="action" size="xs" :onClickPush="`/annuaire/etablissements/view/${etablissement.id}`"
          color="primary">
          <template v-slot:icon>
            {{ etablissement.isSiege ? "mdi-crown" : "mdi-home" }}
          </template>
          <template v-slot:content>
            {{
            size == "xs"
            ? etablissement.libelle + " - " + etablissement.siret
            : etablissement.adresse.libelleCommune
            }}
          </template>

          <template v-slot:subcontent v-if="size == 'xs'">
            {{ etablissement.adresse.libelleCommune }}
          </template>
          <template v-slot:tooltip v-if="tooltip">
            <template v-if="etablissement.libelle">
              {{ etablissement.libelle.length > 25 ? `${etablissement.libelle.slice(0, 25)}...` : etablissement.libelle }} <br />
            </template>
            <template v-if="etablissement.siret">
              <b class="font-weight-bold">Siret:</b> {{ etablissement.siret }}
              <br />
            </template>
            <template v-if="
              etablissement.adresse.libelleVoie &&
              etablissement.adresse.codePostal &&
              etablissement.adresse.libelleCommune
            ">
              <b class="font-weight-bold">Adresse:</b>
              {{ etablissement.adresse.numeroVoie }}
              {{ etablissement.adresse.libelleVoie }}<br />
              {{ etablissement.adresse.codePostal }}<br />
              {{ etablissement.adresse.libelleCommune }}<br />
            </template>

            <template v-if="etablissement.entreprise.raisonSociale">
              <b class="font-weight-bold">Entreprise:</b>
              {{ etablissement.entreprise.raisonSociale }} <br />
            </template>
          </template>
        </ObjectLayout>
      </template>
    </template>
  </div>
</template>

<script>
import dayjs from "dayjs";

import { MUTATION_etablissementGeocodeAddress } from "./graphql/mutations";
import { QUERY_etablissement } from "./graphql/queries";

import AdressePostaleObject from "./AdressePostaleObject.vue";
import EntrepriseObject from "./EntrepriseObject.vue";
import ObjectLayout from "@/components/COMPONENTS-skeleton/Layouts/ObjectLayout.vue";

export default {
  name: "EtablissementObject",
  components: {
    AdressePostaleObject,
    EntrepriseObject,
    ObjectLayout,
  },
  props: {
    action: { type: String, default: "router" },
    etablissementProp: {
      type: Object,
      default() {
        return {};
      },
    },
    etablissementID: {
      type: String,
    },

    size: {
      type: String,
      default: "md",
    },

    tooltip: {
      type: Boolean,
      default: true,
    },
  },

  watch: {
    etablissementProp: function () {
      this.init();
    },
    etablissementID: function () {
      this.init();
    },
  },
  data: () => ({
    etablissement: undefined,
  }),
  mounted: function () {
    this.init();
  },

  computed: {
    isBtnDisabled() {
      if (this.etablissement) {
        if (this.etablissement.meta && this.etablissement.meta.date_geocodage) {
          let date = dayjs(this.etablissement.meta.date_geocodage);
          let dateFuture = date.add(15, "d");

          return dateFuture.isAfter(dayjs());
        }

        return false;
      }

      return true;
    },
  },

  methods: {
    init() {
      if (this.etablissementID) {
        let id = this.etablissementID;
        this.$apollo
          .query({
            query: QUERY_etablissement,
            variables: { id: id },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let etablissement = data.data.etablissement;
            this.etablissement = JSON.parse(JSON.stringify(etablissement));
          });
      } else {
        this.etablissement = JSON.parse(JSON.stringify(this.etablissementProp));
      }
    },
    useGeocodage() {
      this.$apollo
        .mutate({
          mutation: MUTATION_etablissementGeocodeAddress,
          variables: {
            id: this.etablissement.id,
          },
        })
        .then((__) => {
          const data = __.data;
          let etablissement = JSON.parse(JSON.stringify(this.etablissement));
          etablissement.adresse.latitude =
            data.etablissementGeocodeAddress.adresse.latitude;
          etablissement.adresse.longitude =
            data.etablissementGeocodeAddress.adresse.longitude;
          etablissement.meta.date_geocodage =
            data.etablissementGeocodeAddress.meta.date_geocodage;
          this.$emit("input", etablissement);
        });
    },
  },
};
</script>