<template>
  <ListLayout
    ref="list"
    :headers="headers"
    title="Liste des familles"
    :noFilter="noFilter"
    @fetch="init"
    @showMultipleActions="showMultipleActions = $event"
  >
    <template v-slot:multipleActions>
      <FamilleActions
        :multiple="true"
        :object="$refs.list ? $refs.list.itemsSelected : []"
        size="xl"
        v-show="showMultipleActions"
        @fetch="init"
        @loading="$refs.list.loading = true"
      />
    </template>

    <template v-slot:customFilters>
      <FamilleSelector v-model="customFilters.parent" :addAction="null" />
    </template>

    <template v-slot:[`item.object`]="{ item }">
      <FamilleObject :familleProp="item" size="xs" />
    </template>

    <template v-slot:[`item.label`]="{ item }">
      {{ item.label }}
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <FamilleActions
        :object="item"
        size="xs"
        @fetch="init"
        @loading="$refs.list.loading = true"
      />
    </template>
  </ListLayout>
</template>

<script>
import { QUERY_familles } from "./graphql/queries";

import FamilleActions from "./FamilleActions.vue";
import FamilleObject from "./FamilleObject.vue";
import FamilleSelector from "./FamilleSelector.vue"
import ListLayout from "@/components/COMPONENTS-skeleton/Layouts/ListLayout.vue";

export default {
  name: "FamillesList",

  components: {
    FamilleActions,
    FamilleObject,
    FamilleSelector,
    ListLayout,
  },

  props: {
    noFilter: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },

  data: () => ({
    customFilters: {
      parent: null
    },

    headers: [
      { text: "Famille", value: "object", sortable: false },
      { text: "Label", value: "label" },
      { text: "Fichiers", value: "fichiers" },
      { text: "Commentaires", value: "commentaires" },
      { text: "Actions", value: "actions", sortable: false },
    ],

    search: null,

    showMultipleActions: false,
  }),

  methods: {
    fetchFamilles() {
      this.$refs.list.items = [];
      this.$refs.list.loading = true;
      let pagination = JSON.parse(JSON.stringify(this.$refs.list.pagination));

      if (this.customFilters.parent) {
        pagination.filter.customSearch.push({
          parent: this.customFilters.parent,
        });
      }

      this.$apollo
        .query({
          query: QUERY_familles,
          variables: {
            pagination: pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          this.$refs.list.items = data.familles;
          this.$refs.list.itemsCount = data.famillesCount;
          this.$refs.list.loading = false;
        });
    },

    init() {
      this.fetchFamilles();
    },
  },

  watch: {
    customFilters: {
      deep: true,
      handler() {
        this.$refs.list.currentPage = 1;
        this.init();
      },
    },
  },
};
</script>