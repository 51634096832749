<template>
  <div>
    <template v-if="isLogged && !isUsurping">
      <v-menu v-model="menu" offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-list-item-title v-bind="attrs" v-on="on">
            <PersonneObject
              :personneProp="userMe.personneAssociee"
              v-bind="attrs"
              v-on="on"
              v-if="userMe.personneAssociee"
              size="xs"
              action="none"
            />

            <template v-else>
              <v-avatar color="primary" size="36">
                <v-icon dark> mdi-account-circle </v-icon>
              </v-avatar>
            </template>
          </v-list-item-title>
        </template>
        <v-card>
          <v-list>
            <v-list-item>
              <v-col cols="9">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ userMe.email }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ userMe.role }} - {{ organisationCurrent.label }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-col>
              <v-col cols="3" v-if="habilitationsByMe.length > 1">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mx-2"
                      color="primary"
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="changeOrganisation"
                    >
                      <v-icon dark> mdi-swap-horizontal </v-icon>
                    </v-btn>
                  </template>
                  <span>Changer d'organisation</span>
                </v-tooltip>
              </v-col>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list>
            <v-list-item class="mx-auto">
              <v-list-item-content>
                <v-btn
                  color="primary"
                  @click="accountParameter"
                >
                  Paramètres <v-icon class="ma-2">mdi-cogs</v-icon>
                </v-btn></v-list-item-content
              >
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-switch
                  v-model="$vuetify.theme.dark"
                  @change="changeDarkTheme"
                  color="primary"
                ></v-switch>
              </v-list-item-action>
              <v-list-item-title>Thème sombre</v-list-item-title>
              <v-list-item-icon>
                <v-icon>{{
                  $vuetify.theme.dark ? "mdi-weather-night" : "mdi-weather-sunny"
                }}</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-switch
                  v-model="preferencesAssistant"
                  @change="changePreferencesAssistant"
                  color="primary"
                ></v-switch>
              </v-list-item-action>

              <v-list-item-title>Assistant</v-list-item-title>
              <v-list-item-icon>
                <v-icon>{{
                  preferencesAssistant ? "mdi-robot" : "mdi-robot-off"
                }}</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-switch
                  v-model="preferencesMesElements"
                  @change="changeMesElements"
                  color="primary"
                ></v-switch>
              </v-list-item-action>
              <v-list-item-title>Mes éléments uniquement</v-list-item-title>
              <v-list-item-icon>
                <v-icon>{{
                  preferencesMesElements ? "mdi-account" : "mdi-account-off"
                }}</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="logout">
              <v-icon>mdi-logout</v-icon>
              Déconnexion
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </template>
  </div>
</template>
<script>
import {
  onLogin,
  onLogout,
  isLogged,
} from "@/components/COMPONENTS-skeleton/vue-apollo";
import { isUsurping } from "@/components/COMPONENTS-skeleton/vue-apollo.js";
import { QUERY_userMeConnected, QUERY_userLoginToken, QUERY_authentificationHabilitationOrganisationParametres } from "./graphql/queries";
import { MUTATION_userPreferencesUpdate } from "./graphql/mutations";
import PersonneObject from "@/components/COMPONENTS-annuaire/PersonneObject.vue";

export default {
  components: {
    PersonneObject,
  },

  data: () => ({
    isLogged: null,
    userMe: {},
    habilitationsByMe: [],
    organisationCurrent: {},
    menu: false,
    preferencesAssistant: true,
    preferencesMesElements: false
  }),

  computed: {
    isUsurping() {
      return isUsurping();
    },
  },

  watch: {
    userMe: function (val) {
      this.$vuetify.theme.dark =
        val.preferences && val.preferences.darkTheme != undefined
          ? val.preferences.darkTheme
          : false;

      this.preferencesAssistant =
        val.preferences && val.preferences.assistant != undefined
          ? val.preferences.assistant
          : true;

      this.preferencesMesElements =
        val.preferences && val.preferences.mesElements != undefined
          ? val.preferences.mesElements
          : false;
    },
  },
  mounted: async function () {
    await this.init();
  },
  methods: {
    async init() {
      this.isLogged = await isLogged();
      if (this.isLogged) {
        this.$apollo
          .query({
            query: QUERY_userMeConnected,
          })
          .then((data) => {
            let userMe = data.data.userMe;
            let organisationCurrent = data.data.organisationCurrent;
            let habilitationsByMe = data.data.habilitationsByMe;
            this.userMe = JSON.parse(JSON.stringify(userMe));
            this.organisationCurrent = JSON.parse(
              JSON.stringify(organisationCurrent)
            );
            this.habilitationsByMe = JSON.parse(
              JSON.stringify(habilitationsByMe)
            );
          });


        this.$apollo
          .query({
            query: QUERY_authentificationHabilitationOrganisationParametres,
            fetchPolicy: 'no-cache'
          })
          .then(__ => {
            const data = __.data.authentificationHabilitationOrganisationParametres;
            if(data.color != "" && data.color != null && data.color != undefined) {
              this.$vuetify.theme.themes.light.primary = data.color;
              this.$vuetify.theme.themes.dark.primary = data.color;
            }
          })
      }

      this.$emit("changeOrganisation");
    },
    async logout() {
      this.$vuetify.theme.dark = false;
      this.$vuetify.theme.themes.light.primary = this.$store.state.colorDefaultLight;
      this.$vuetify.theme.themes.dark.primary = this.$store.state.colorDefaultDark;
      await onLogout(this.$apollo.provider.defaultClient);
      this.$router.push({ path: `/` }).catch(() => {});
    },

    accountParameter() {
      if (this.userMe) {
        this.$router
          .push({
            path: `/authentification-habilitation/user-me/view/`,
            params: { id: this.userMe.id },
          })
          .catch(() => {});
      } else {
        this.$router.push({ path: `/` });
      }
    },

    changeDarkTheme(preference) {
      this.$apollo.mutate({
        mutation: MUTATION_userPreferencesUpdate,
        variables: {
          input: {
            darkTheme: preference,
          },
        },
      });
    },

    changeMesElements() {
      this.$apollo.mutate({
        mutation: MUTATION_userPreferencesUpdate,
        variables: {
          input: {
            mesElements: this.preferencesMesElements,
          },
        },
      });
    },

    changeOrganisation() {
      this.$apollo
        .mutate({
          mutation: QUERY_userLoginToken,
        })
        .then(async (__) => {
          const data = __.data;
          localStorage.removeItem("startLocation")
          await onLogin(
            this.$apollo.provider.defaultClient,
            data.userLoginToken.token,
            data.userLoginToken.refreshToken
          );
          this.$router.push({ path: "/" });
        });
    },

    changePreferencesAssistant() {
      this.$apollo.mutate({
        mutation: MUTATION_userPreferencesUpdate,
        variables: {
          input: {
            assistant: this.preferencesAssistant,
          },
        },
      });
    },
  },
};
</script>