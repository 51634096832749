<template>
  <ViewLayout :tabs="tabs" :item="pointFacturation">
    <template v-slot:pointFacturation>
      <PointFacturationActions :object="pointFacturation" size="xl" />

      <v-row class="align-center mx-auto">
        <v-col cols="12">
          <PointFacturationObject
            size="xl"
            :pointFacturationProp="pointFacturation"
          />
        </v-col>
        <v-col cols="12">
          <MoyenPaiementsList
            v-if="pointFacturation.id"
            :customSearch="[{ pointFacturation: pointFacturation.id }]"
          />
        </v-col>
      </v-row>
    </template>
  </ViewLayout>
</template>

<script>
import { QUERY_pointFacturation } from "./graphql/queries";

import MoyenPaiementsList from "./MoyenPaiementsList.vue";
import PointFacturationObject from "./PointFacturationObject.vue";
import PointFacturationActions from "./PointFacturationActions.vue";
import ViewLayout from "@/components/COMPONENTS-skeleton/Layouts/ViewLayout.vue";

export default {
  name: "PointFacturationView",

  components: {
    MoyenPaiementsList,
    PointFacturationActions,
    PointFacturationObject,
    ViewLayout,
  },

  data: () => ({
    pointFacturation: {},
    tabs: [
      {
        icon: "mdi-wallet",
        displayName: "Point de facturation",
        name: "pointFacturation",
      },
      { name: "commentaires" },
      { name: "fichiers" },
    ],
  }),

  apollo: {
    pointFacturation: {
      query: QUERY_pointFacturation,
      variables() {
        let idQuery = this.showPopup
          ? this.id
          : this.$route.params.id
          ? this.$route.params.id
          : this.id;
        return {
          id: idQuery,
        };
      },
    },
  },
};
</script>
