import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import { vueAcl } from '@aidalinfo/authorization-package';
import { isLogged } from '../vue-apollo';

Vue.use(VueRouter)
//On récupère la liste des fichiers "routes.js" présent dans les répertoires enfants 
// de @/components
const files = require.context('/src/components/', true, /routes.js$/).keys()

// Le tableau qui sera passé à vueRouter
let routesArray = []
// Pour chaque fichier trouvé, on le load et on en pousse le contenu dans le tableau
for (let routerFile of files) {
  let file = routerFile.substring(2)
  let module = require(`/src/components/${file}`)
  routesArray.push(...module.default)
}
// Permet de remplacer les routes par celles qui ont la meta "replace"
routesArray.forEach(function (item) {
  if (item.meta && item.meta.replace) {
    for (let i = 0; i < routesArray.length; i++) {
      if (routesArray[i].path == item.path && !routesArray[i].meta.replace) {
        routesArray[i].path += "/replaced"
        routesArray[i].name += "/replaced"
      }
    }
  }
})

// On définit le router
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routesArray
})
// Actions qui se déroulent avant le changement de route
router.beforeEach(async (to, from, next) => {
  // ON vérifie s'il n'y a pas de la données non sauvegardée
  if (store.state.dataNotSaved) {
    // Si oui, on demande confirmation
    if (confirm("Attention, vous avez des données non sauvegardées, voulez-vous les perdre ?")) {
      // Si oui, on supprime la note
      store.commit('setDataNotSaved', false)
    } else {
      // Sinon, on annule le changement de route
      return
    }
  }
  // On détecte si on souhaite charger la vue dans une popup
  if (to.query.popup) {
    let composant = undefined
    // Si une route matche avec le chemin passé
    if (to.matched[0]) {

      //Si il s'agit d'une promise
      if (typeof to.matched[0].components.default == 'function') {
        composant = await to.matched[0].components.default()
      } else {
        // Sinon
        composant = to.matched[0].components
      }
    } else {
      // Si la route est invalide, (pas matché avec la regex générée par vue router)
      // on ne fait rien
      return
    }
    // On récupère les paramètres de l'url(par exemple l'id), il seront renvoyé en prop
    let params = to.params

    params = { ...params, ...to.query }
    // On informe l'appli à l'aide de vuex que l'on souhaite une popup avec le nom du composant
    // ainsi que les params qui seront passés en prop au composant

    store.commit('addPopup', {
      composantName: composant,
      params: params,
    })
    return
  }

  // Système d'ACL basé sur vueACL( librairie maison)
  // Utilise les méta pour savoir l'habilitation associée à la route
  if (!to.meta.public) {
    // Si la page n'est pas publique et que l'utilisateur n'est pas loggé,
    // on renvoie vers la racine
    if (!(await isLogged())) {
      return next(`/${to.query.org ? '?org=' + to.query.org : ''}`);
    }
  }

  if (from.query.org) {
    if ((from.path == '/' && to.path != '/') || (from.path != '/' && to.path == '/')) {
      if (!to.query.org) {
        return next(`${to.path}?org=${from.query.org}`);
      }
    }
  }

  if (to.meta.acl) {
    // Si il y a une acl et  que l'on n'est pas habilité à aller sur une page, 
    // on renvoie vers la racine
    if (!vueAcl(to.meta.acl)) {
      console.warn('Vous n\'êtes pas habilité à accéder à cette page !', to.meta.acl);
      return next('/');
    }
  }
  next();
});

export default router
