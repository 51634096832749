<template>
  <ViewLayout :tabs="tabs" :item="template">
    <template v-slot:template>
      <TemplateActions :object="template" size="xl" />

      <v-row class="align-center mx-auto">
        <v-col cols="12">
          <TemplateObject size="xl" :templateProp="template" />
        </v-col>
      </v-row>
    </template>
  </ViewLayout>
</template>
<script>
import { QUERY_template } from "./graphql/queries";

import TemplateObject from "./TemplateObject.vue";
import TemplateActions from "./TemplateActions.vue";
import ViewLayout from "@/components/COMPONENTS-skeleton/Layouts/ViewLayout.vue";

export default {
  name: "TemplateView",

  components: {
    TemplateActions,
    TemplateObject,
    ViewLayout,
  },

  data: () => ({
    template: {},
    tabs: [
      {
        icon: "mdi-format-float-left",
        displayName: "Template",
        name: "template",
      },
      { name: "commentaires" },
      { name: "fichiers" },
    ],
  }),

  apollo: {
    template: {
      query: QUERY_template,
      variables() {
        let idQuery = this.showPopup
          ? this.id
          : this.$route.params.id
          ? this.$route.params.id
          : this.id;
        return {
          id: parseInt(idQuery),
        };
      },
    },
  },
};
</script>