<template>
  <ListLayout
    ref="list"
    :headers="headers"
    title="Liste des assets"
    :noFilter="noFilter"
    @fetch="init"
    @showMultipleActions="showMultipleActions = $event"
  >
    <template v-slot:multipleActions>
      <AssetActions
        :multiple="true"
        :object="$refs.list ? $refs.list.itemsSelected : []"
        size="xl"
        v-show="showMultipleActions"
        @fetch="init"
        @loading="$refs.list.loading = true"
      />
    </template>

    <template v-slot:customFilters>
      <MarqueSelector v-model="customFilters.marque" :addAction="null" />
      <ModeleSelector
        :marque="customFilters.marque"
        v-model="customFilters.modele"
        :addAction="null"
      />
    </template>

    <template v-slot:[`item.object`]="{ item }">
      <AssetObject :assetProp="item" size="xs" />
    </template>

    <template v-slot:[`item.marque`]="{ item }">
      <MarqueObject :marqueProp="item.marque" size="xs" />
    </template>

    <template v-slot:[`item.modele`]="{ item }">
      <ModeleObject :modeleProp="item.modele" size="xs" />
    </template>

    <template v-slot:[`item.numeroSerie`]="{ item }">
      {{ item.numeroSerie }}
    </template>

    <template v-slot:[`item.etatAsset`]="{ item }">
      <EtatAssetObject :etatAsset="item.etatAsset" size="xs" />
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <AssetActions
        :object="item"
        size="xs"
        @fetch="init"
        @loading="$refs.list.loading = true"
      />
    </template>
  </ListLayout>
</template>

<script>
import { QUERY_assets } from "./graphql/queries";

import AssetActions from "./AssetActions.vue";
import AssetObject from "./AssetObject.vue";
import EtatAssetObject from "./EtatAssetObject.vue";
import ListLayout from "@/components/COMPONENTS-skeleton/Layouts/ListLayout.vue";
import MarqueObject from "./MarqueObject.vue";
import MarqueSelector from "./MarqueSelector.vue";
import ModeleObject from "./ModeleObject.vue";
import ModeleSelector from "./ModeleSelector.vue";

export default {
  name: "AssetsList",

  components: {
    AssetActions,
    AssetObject,
    EtatAssetObject,
    ListLayout,
    MarqueObject,
    MarqueSelector,
    ModeleObject,
    ModeleSelector,
  },

  props: {
    modeleID: null,
    noFilter: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },

  data: () => ({
    customFilters: {
      marque: null,
      modele: null,
    },

    headers: [
      { text: "Asset", value: "object", sortable: false },
      { text: "Marque", value: "marque" },
      { text: "Modèle", value: "modele" },
      { text: "Numéro de série", value: "numeroSerie" },
      { text: "Etat", value: "etatAsset" },
      { text: "Fichiers", value: "fichiers" },
      { text: "Commentaires", value: "commentaires" },
      { text: "Actions", value: "actions", sortable: false },
    ],

    search: null,

    showMultipleActions: false,
  }),

  methods: {
    fetchAssets() {
      this.$refs.list.items = [];
      this.$refs.list.loading = true;
      let pagination = JSON.parse(JSON.stringify(this.$refs.list.pagination));

      if (this.modeleID) {
        pagination.filter.customSearch.push({ modele: this.modeleID });
      } else {
        if (this.customFilters.marque) {
          pagination.filter.customSearch.push({
            marque: this.customFilters.marque,
          });
        }
        if (this.customFilters.modele) {
          pagination.filter.customSearch.push({
            modele: this.customFilters.modele,
          });
        }
      }

      this.$apollo
        .query({
          query: QUERY_assets,
          variables: {
            pagination: pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          this.$refs.list.items = data.assets;
          this.$refs.list.itemsCount = data.assetsCount;
          this.$refs.list.loading = false;
        });
    },

    init() {
      this.fetchAssets();
    },
  },

  watch: {
    customFilters: {
      deep: true,
      handler() {
        this.$refs.list.currentPage = 1;
        this.init();
      },
    },

    entrepriseID() {
      this.fetchAssets();
    },
  },
};
</script>