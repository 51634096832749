<template>
  <ViewLayout :tabs="tabs" :item="bailleur">
    <template v-slot:bailleur>
      <BailleurActions :object="bailleur" :withView="false" size="xl" />
      <BailleurObject size="xl" :bailleurProp="bailleur" />
    </template>
  </ViewLayout>
</template>

<script>
import { QUERY_bailleur } from "./graphql/queries";
import BailleurObject from "./BailleurObject.vue";
import BailleurActions from "./BailleurActions.vue";
import ViewLayout from "@/components/COMPONENTS-skeleton/Layouts/ViewLayout.vue";

export default {
  name: "BailleurView",

  components: {
    BailleurObject,
    BailleurActions,
    ViewLayout,
  },

  data: () => ({
    bailleur: {},
    tabs: [
      {
        icon: "mdi-bank",
        displayName: "Bailleur",
        name: "bailleur",
      },
      { name: "commentaires" },
      { name: "fichiers" },
    ],
  }),

  apollo: {
    bailleur: {
      query: QUERY_bailleur,
      variables() {
        let idQuery = this.showPopup
          ? this.id
          : this.$route.params.id
          ? this.$route.params.id
          : this.id;
        return {
          id: idQuery,
        };
      },
    },
  },
  props: {
    showPopup: {
      type: Boolean,
    },
    id: {
      type: String,
    },
  },
};
</script>

