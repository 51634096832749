<template>
  <ListLayout
    ref="list"
    :headers="headers"
    :noFilter="noFilter"
    @fetch="init"
    @showMultipleActions="showMultipleActions = $event"
    title="Liste des reglementFournisseurs"
  >
    <template v-slot:multipleActions>
      <ReglementFournisseurActions
        :multiple="true"
        :object="$refs.list ? $refs.list.itemsSelected : []"
        size="xl"
        v-show="showMultipleActions"
        @fetch="init"
        @loading="$refs.list.loading = true"
      />
    </template>
    <template v-slot:customFilters></template>
    <template v-slot:[`item.object`]="{ item }">
      <ReglementFournisseurObject :reglementFournisseurProp="item" size="xs" />
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <ReglementFournisseurActions
        :object="item"
        size="xs"
        @fetch="init"
        @loading="$refs.list.loading = true"
      />
    </template>
  </ListLayout>
</template>

<script>
import { QUERY_reglementFournisseurs } from "./graphql/queries";

import ListLayout from "@/components/COMPONENTS-skeleton/Layouts/ListLayout.vue";
import ReglementFournisseurActions from "./ReglementFournisseurActions.vue";
import ReglementFournisseurObject from "./ReglementFournisseurObject.vue";

export default {
  name: "ReglementFournisseursList",

  components: {
    ReglementFournisseurActions,
    ReglementFournisseurObject,
    ListLayout,
  },

  props: {
    noFilter: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },

  data: () => ({
    customFilters: {},
    headers: [
      { text: "", value: "object", sortable: false },
      { text: "Fichiers", value: "fichiers" },
      { text: "Commentaires", value: "commentaires" },
      { text: "Actions", value: "actions", sortable: false },
    ],

    search: null,

    showMultipleActions: false,
  }),

  methods: {
    fetchReglementFournisseurs() {
      this.$refs.list.items = [];
      this.$refs.list.loading = true;
      this.$apollo
        .query({
          query: QUERY_reglementFournisseurs,
          variables: {
            pagination: this.$refs.list.pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          this.$refs.list.items = data.reglementFournisseurs;
          this.$refs.list.itemsCount = data.reglementFournisseursCount;
          this.$refs.list.loading = false;
        });
    },
    init() {
      this.fetchReglementFournisseurs();
    },
  },
};
</script>