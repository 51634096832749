<template>
    
  <div>
  <template v-if="requetesApiOrganisation">
    <template v-if="size == 'xl'">
        <ObjectLayout size="xl">
          <template v-slot:icon>mdi-api</template>
          <template v-slot:titre> {{ requetesApiOrganisation.apiName }}</template>
          <template v-slot:body>
            <ObjectLayout v-if="requetesApiOrganisation.apiName">
              <template v-slot:titre>
                Nom de l'API
              </template>
              <template v-slot:content>
                {{ requetesApiOrganisation.apiName}}
              </template>
            </ObjectLayout>
            <ObjectLayout v-if="requetesApiOrganisation.requeteName">
              <template v-slot:titre>Nom de la requête</template>
              <template v-slot:content>{{ requetesApiOrganisation.requeteName }}</template>
            </ObjectLayout>
            <ObjectLayout v-if="requetesApiOrganisation.requeteDate">
              <template v-slot:titre>Date de la requête</template>
              <template v-slot:content>{{
                requetesApiOrganisation.requeteDate | shortDateAndHourFR
              }}</template>
            </ObjectLayout>
          </template>
        </ObjectLayout>
      </template>
      <!-- XS -->
      <template v-if="size == 'xs'">
        <ObjectLayout
          size="xs"
          :onClickPush="`/admin-provider/requetes-api/view/${requetesApiOrganisation.id}`"
        >
          <template v-slot:content>{{ requetesApiOrganisation.apiName }}</template>
        </ObjectLayout>
      </template>
    </template>
    </div>
</template>

<script>
// import { QUERY_requetesApiOrganisation } from "./graphql/queries";
import ObjectLayout from "@/components/COMPONENTS-skeleton/Layouts/ObjectLayout.vue";

// onClickPush=`/admin-provider/requetes-api/view/${requeteApiOrganisation.id}`
export default {
    name: "RequetesApiOrganisationObject",

    components: {
      ObjectLayout
    },

    props: {
      size: { type: String, default: "md" },
      
      requetesApiOrganisationProp: {
        type: Object,
        default() {
          return {};
        }
      },
    },

    watch: {
      requetesApiOrganisationProp: function () {
        this.init();
      }
    },

    data: () => ({
      requetesApiOrganisation: undefined,
    }),
  
    mounted: function () {
      this.init();
    },

    methods: {
      init() { 
        this.requetesApiOrganisation = JSON.parse(JSON.stringify(this.requetesApiOrganisationProp)) 
      }
    }
}
</script>