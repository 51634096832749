<template>
  <div>
    <template v-if="personne && personne.nom">
      <template v-if="size == 'xl'">
        <ObjectLayout :action="action" size="xl" :item="personne">
          <template v-slot:icon>mdi-card-account-mail-outline</template>
          <template v-slot:titre>
            {{ personne.prenom }} {{ personne.nom }}</template
          >

          <template v-slot:body>
            <ObjectLayout v-if="personne.nom">
              <template v-slot:titre>Nom</template>
              <template v-slot:content>
                {{ personne.nom }}
              </template>
            </ObjectLayout>
            <ObjectLayout v-if="personne.prenom">
              <template v-slot:titre>Prénom</template>
              <template v-slot:content>
                {{ personne.prenom }}
              </template>
            </ObjectLayout>
            <ObjectLayout v-if="personne.dateNaissance">
              <template v-slot:titre>Date de naissance</template>
              <template v-slot:content>
                {{ formatDate(personne.dateNaissance) }}
              </template>
            </ObjectLayout>
            <ObjectLayout v-if="personne.adressesMails && personne.adressesMails.length > 0">
              <template v-slot:titre>Adresse email</template>
              <template v-slot:content>
                <template v-for="(adresseMail, i) in personne.adressesMails">
                  <span :key="'adresseMail' + personne.id + i">
                    {{ adresseMail.adresse }}
                    <br />
                  </span>
                </template>
              </template>
            </ObjectLayout>
            <ObjectLayout v-if="personne.numerosTelephone && personne.numerosTelephone.length > 0">
              <template v-slot:titre>Numéros de téléphone</template>
              <template v-slot:content>
                <template
                  v-for="(numeroTelephone, i) in personne.numerosTelephone"
                >
                  <span :key="'numeroTelephone' + personne.id + i">
                    {{ numeroTelephone.indicatif.flag }}
                    {{ numeroTelephone.indicatif.dialCode }}
                    {{ numeroTelephone.numero }}
                    <br />
                  </span>
                </template>
              </template>
            </ObjectLayout>
            <ObjectLayout v-if="personne.fonctions[0]">
              <template v-slot:titre>Fonctions occupées</template>
              <template v-slot:content>
                <template v-for="(fonction, i) in personne.fonctions">
                  <FonctionObject
                    :key="'fonction' + personne.id + i"
                    :fonction="fonction"
                    size="xs"
                  />
                </template>
              </template>
            </ObjectLayout>
            <ObjectLayout
              v-if="personne.associatedUser && personne.associatedUser.label"
            >
              <template v-slot:titre>utilisateur associé</template>
              <template v-slot:content>
                {{ personne.associatedUser.label }}
              </template>
            </ObjectLayout>
          </template>

          <template v-slot:body2>
            <div
              class="d-flex justify-center align-center"
              style="width: 100%; height: 220px"
            >
              <FichierLogo
                class="py-5"
                v-model="personne.avatar"
                :tile="false"
                :text="personne.prenom + ' ' + personne.nom"
                :size="120"
                justDisplay
              />
            </div>
          </template>
        </ObjectLayout>
      </template>

      <template v-if="size == 'md'">
        <ObjectLayout :action="action" size="md">
          <template v-slot:image>
            <FichierLogo
              size="42"
              v-model="personne.avatar"
              :text="personne.prenom + ' ' + personne.nom"
              justDisplay
              :tile="false"
            />
          </template>
          <ObjectLayout>
            <template v-slot:titre>Adresse email</template>
            <template v-slot:content>
              {{ personne.prenom }} {{ personne.nom }}
            </template>
            <template v-slot:extracontent>
              <slot name="extracontent"></slot>
            </template>
            <template
              v-slot:subcontent
              v-if="personne.fonctions && personne.fonctions[0]"
            >
              {{ personne.fonctions[0].etablissement.entreprise.raisonSociale }}
              -
              {{ personne.fonctions[0].fonction }}
            </template>
          </ObjectLayout>
        </ObjectLayout>
      </template>

      <template v-if="size == 'xs'">
        <ObjectLayout
          :action="action"
          size="xs"
          :onClickPush="`/annuaire/personnes/view/${personne.id}`"
        >
          <template v-slot:image>
            <FichierLogo
              size="14"
              justDisplay
              :tile="false"
              v-model="personne.avatar"
              :text="personne.prenom + ' ' + personne.nom"
            />
          </template>
          <template v-slot:tooltip v-if="tooltip">
            <b class="font-weight-bold">Nom:</b> {{ personne.nom }} <br />
            <b class="font-weight-bold">Prénom:</b> {{ personne.prenom }} <br />
            <template
              v-if="personne.adressesMails && personne.adressesMails[0]"
            >
              <b class="font-weight-bold"> Email: </b>
              {{ personne.adressesMails[0].adresse.length > 30 ? `${personne.adressesMails[0].adresse.slice(0, 25)}...` 
                : personne.adressesMails[0].adresse }}<br />
            </template>
            <template v-if="personne.fonctions && personne.fonctions[0]">
              <b class="font-weight-bold">Fonction: </b> {{ personne.fonctions[0].fonction }} - {{ personne.fonctions[0].entreprise.raisonSociale }} <br />
            </template>
          </template>
          <template v-slot:content>
            {{ personne.prenom }} {{ personne.nom }}
          </template>
        </ObjectLayout>
      </template>
    </template>
  </div>
</template>

<script>
import { QUERY_personne } from "./graphql/queries";

import FichierLogo from "@/components/COMPONENTS-fichiers-stockages/FichierLogo.vue";
import FonctionObject from "./FonctionObject.vue";
import ObjectLayout from "@/components/COMPONENTS-skeleton/Layouts/ObjectLayout.vue";

import dayjs from "dayjs";

export default {
  name: "PersonneObject",

  components: {
    FichierLogo,
    FonctionObject,
    ObjectLayout,
  },

  props: {
    action: {
      type: String,
      default: "router",
    },
    personneID: {
      type: String,
    },
    personneProp: {
      type: Object,
      default() {
        return {};
      },
    },
    size: {
      type: String,
      default: "md",
    },
    tooltip: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    personne: undefined,
  }),

  watch: {
    personneID: function () {
      this.init();
    },
    personneProp: function () {
      this.init();
    },
  },

  mounted: function () {
    this.init();
  },

  methods: {
    formatDate(date) {
      return dayjs(date).format('DD/MM/YYYY');
    },

    getFirstFonction() {
      if (this.personne.fonctions[0]) {
        return (
          this.personne.fonctions[0].fonction +
          " chez " +
          this.personne.fonctions[0].entreprise.raisonSociale
        );
      } else {
        return "Pas de fonction connue";
      }
    },

    init() {
      if (this.personneID) {
        let id = this.personneID;
        this.$apollo
          .query({
            query: QUERY_personne,
            variables: {
              id: id,
            },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let personne = data.data.personne;
            this.personne = JSON.parse(JSON.stringify(personne));
          });
      } else {
        this.personne = JSON.parse(JSON.stringify(this.personneProp));
      }
    },
  },
};
</script>