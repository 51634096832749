export default [
  // Plannings
  {
    path: '/planning/plannings',
    name: 'Gestion des plannings',
    component: () => import('./PlanningsList.vue'),
    meta: {
      acl: "/planning/plannings",
    }
  },
  {
    path: '/planning/plannings/list',
    name: 'Liste des plannings',
    component: () => import('./PlanningsList.vue'),
    meta: {
      acl: "/planning/plannings/list",
    }
  },
  {
    path: '/planning/plannings/add',
    name: 'Ajouter un planning',
    component: () => import('./PlanningForm.vue'),
    meta: {
      acl: "/planning/plannings/add",
    }
  },
  {
    path: '/planning/plannings/edit/:id',
    name: 'Editer un planning',
    component: () => import('./PlanningForm.vue'),
    meta: {
      acl: "/planning/plannings/edit",
    }
  },
  {
    path: '/planning/plannings/view/:id',
    name: 'Visualiser un planning',
    component: () => import('./PlanningView.vue'),
    meta: {
      acl: "/planning/plannings/view",
    }
  },
  {
    path: '/planning/plannings/deleted',
    name: 'Liste des plannings supprimés',
    component: () => import('./PlanningsList.vue'),
    meta: {
      acl: "/planning/plannings/deleted",
    }
  },

];