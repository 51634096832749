<template>
  <div>
    <template v-if="modele">
      <template v-if="size == 'xl'">
        <ObjectLayout :action="action" size="xl" :item="modele">
          <template v-slot:icon>mdi-barcode-scan</template>
          <template v-slot:titre> {{ modele.nom }}</template>
          <template v-slot:body>
            <ObjectLayout v-if="modele.nom" class="py-2">
              <template v-slot:titre>Nom du modèle</template>
              <template v-slot:content>{{ modele.nom }}</template>
            </ObjectLayout>
            <ObjectLayout v-if="modele.marque && modele.marque.nom" class="py-2">
              <template v-slot:titre>
                Nom de la marque associé au modèle
              </template>
              <template v-slot:content>{{ modele.marque.nom }}</template>
            </ObjectLayout>
          </template>
          <template v-slot:body2>
            <div v-if="modele.propriete">
              <ObjectLayout v-for="(item, key, index) in modele.propriete" :key="index" class="py-2">

                <template v-slot:titre>

                  {{ key.charAt(0).toUpperCase() }}{{ key.slice(1, key.length) }}

                </template>
                <template v-slot:content>
                  <template v-if="item != null">
                    {{ item.value == true && typeof item.value == 'boolean' ? '' : item.value }} {{ item.unite != "" ?
                      item.unite : '' }}
                  </template>
                </template>

              </ObjectLayout>
            </div>
          </template>
        </ObjectLayout>
      </template>
      <template v-if="size == 'xs'">
        <ObjectLayout size="xs" :action="action" :onClickPush="`/assets/modeles/view/${modele.id}`">
          <template v-slot:tooltip v-if="tooltip">
            <template v-if="modele.nom">
              <b class="font-weight-bold">Modèle:</b> {{ modele.nom }} <br />
            </template>
            <template v-if="modele.marque && modele.marque.nom">
              <b class="font-weight-bold">Marque:</b> {{ modele.marque.nom }}
              <br />
            </template>
            <template v-if="modele.propriete">
              <div v-for="(item, key, index) in modele.propriete" :key="index">
                <template v-if="item != null">
                  <b class="font-weight-bold">{{ key.charAt(0).toUpperCase() }}{{ key.slice(1, key.length) }}</b>
                  {{ item.value == true && typeof item.value == 'boolean' ? '' : ': ' + item.value }} {{ item.unite != ""
                    ?
                    item.unite : '' }}<br />
                </template>
              </div>
            </template>
          </template>
          <template v-slot:icon>mdi-barcode-scan</template>
          <template v-slot:content>{{ modele.nom }}</template>
        </ObjectLayout>
      </template>
      <template v-if="size == 'md'">
        <ObjectLayout size="md">
          <template v-slot:icon>mdi-barcode-scan</template>
          <template v-slot:content>{{ modele.nom }}</template>
        </ObjectLayout>
      </template>
    </template>
  </div>
</template>

<script>
import { QUERY_modele } from "./graphql/queries";
import ObjectLayout from "@/components/COMPONENTS-skeleton/Layouts/ObjectLayout.vue";
export default {
  name: "ModeleObject",
  components: {
    ObjectLayout,
  },

  props: {
    size: { type: String, default: "md" },

    modeleProp: {
      type: Object,
      default() {
        return {};
      },
    },
    modeleID: {
      type: String,
    },

    action: {
      type: String,
      default: "router",
    },

    tooltip: {
      type: Boolean,
      default: true,
    },
  },

  watch: {
    modeleProp: function () {
      this.init();
    },
    modeleID: function () {
      this.init();
    },
  },

  data: () => ({
    modele: undefined,
  }),

  mounted: function () {
    this.init();
  },

  methods: {
    init() {
      if (this.modeleID) {
        let id = this.modeleID;
        this.$apollo
          .query({
            query: QUERY_modele,
            variables: { id: id },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let modele = data.data.modele;
            this.modele = JSON.parse(JSON.stringify(modele));
          });
      } else {
        this.modele = JSON.parse(JSON.stringify(this.modeleProp));
      }
    },
  },
};
</script>