<template>
  <div>
    <template v-if="size == 'l'">
      <div class="commentaire" :id="commentaire.id">
        <div :class="isSender ? 'isSender ' : ''">
          <v-icon :color="commentaire.type" class="type">{{ typeIcon }}</v-icon>

          <UserObject size="xs" :userProp="commentaire.user" class="sender" />

          <div class="font-weight-medium box sb3 elevation-5">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon class="actionBtn" v-bind="attrs" v-on="on" dark>
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="$emit('replyTo', commentaire)">
                  <v-list-item-icon>
                    <v-icon>mdi-reply</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title> Répondre </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="methodForm = 'edit'">
                  <v-list-item-icon>
                    <v-icon>mdi-pencil</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title> Editer </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="deleteItem(commentaire)">
                  <v-list-item-icon>
                    <v-icon>mdi-delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title> Supprimer </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>

            <span class="date">
              {{ commentaire.dateCreation | shortDateAndHourFR }}
            </span>
            <CommentaireObject
              v-if="commentaire.commentaireParent"
              size="s"
              class="mt-2"
              :commentaire="commentaire.commentaireParent"
            />
            <p class="white--text">{{ commentaire.texte }}</p>
          </div>
        </div>
      </div>
    </template>
    <template v-if="size == 's'">
      <a :href="`#${commentaire.id}`" style="text-decoration: none">
        <div class="commentaire commentaire-s">
          <UserObject size="xs" :userProp="commentaire.user" class="sender" />
          <div class="grey lighten-4 font-weight-medium box-s elevation-5">
            <p class="grey--text text--darken-2">
              {{ commentaire.texte | truncate(250, " ...") }}
            </p>
          </div>
        </div>
      </a>
    </template>
  </div>
</template>

<script>
import UserObject from "@/components/COMPONENTS-authentification-habilitation/UserObject.vue";
import { QUERY_userMe } from "@/components/COMPONENTS-authentification-habilitation/graphql/queries";
import { MUTATION_commentaireDelete } from "./graphql/mutations";

export default {
  name: "CommentaireObject",
  components: {
    UserObject,
  },
  props: {
    commentaire: { type: Object },
    size: { type: String, default: "l" },
  },
  data: () => ({
    methodForm: undefined,
  }),
  apollo: {
    userMe: {
      query: QUERY_userMe,
    },
  },
  created() {
    this.$root.$on("deleteCommentaire", this.deleteCommentaire);
  },
  computed: {
    isSender() {
      return this.userMe.id == this.commentaire.user.id ? true : false;
    },
    typeIcon() {
      let icon = "";
      switch (this.commentaire.type) {
        case "info":
          icon = "mdi-information";
          break;
        case "success":
          icon = "mdi-check-circle";
          break;
        case "warning":
          icon = "mdi-alert-circle";
          break;
        case "error":
          icon = "mdi-close-circle";
          break;
      }
      return icon;
    },
  },
  methods: {
    deleteCommentaire: function (id) {
      this.$apollo
        .mutate({
          mutation: MUTATION_commentaireDelete,
          variables: { id: id },
        })
        .then(() => {
          this.$root.$emit("collaboration--update-commentaires");
        });
    },
    deleteItem: function (item) {
      let toValid = {
        titre: "Suppression d'un commentaire",
        texte: `Êtes-vous sûr de vouloir supprimer le commentaire ?`,
        type: "warning",
        toReturn: {
          function: "deleteCommentaire",
          arg: item.id,
        },
      };
      this.$root.$emit("popupValidation", toValid);
    },
  },
};
</script>

<style scoped>
.commentaire {
  position: relative;
}
.commentaire > div {
  width: 80%;
}
.commentaire-s > div {
  width: 100%;
}

.commentaire .type {
  position: absolute;
  top: 70px;
  left: -16px;
}
.box {
  border-radius: 10px;
  max-width: 100%;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 30px;
  background: var(--v-primary-base);
  padding: 15px;
  padding-top: 30px;
  text-align: left;
  position: relative;
}
.box-s {
  border-radius: 10px;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 10px;
  margin-bottom: 10px;

  padding: 15px;
  padding-top: 30px;
  text-align: left;
  position: relative;
  font-size: 0.9em;
  font-weight: 400 !important;
}
.box p {
  margin-bottom: 0;
}
.date {
  font-size: 0.8em;
  display: block;
  position: absolute;
  color: inherit !important;
  bottom: -24px;
  right: 10px;
}
.isSender {
  position: relative;
  right: -20%;
}
.isSender .box {
  background: var(--v-secondary-base);
  max-width: 100%;
}
.isSender .date {
  right: inherit;
}

.isSender .sb3:before {
  left: inherit;
  right: 19px;
  /* border-left: 10px solid var(--v-secondary-base); */
  border-left: 10px solid transparent;
  /* border-right: 10px solid transparent; */
  border-right: 10px solid var(--v-secondary-base);
  /* border-top: 10px solid var(--v-secondary-base); */
  border-top: 10px solid var(--v-secondary-base);
  /* border-bottom: 10px solid transparent; */
  border-bottom: 10px solid transparent;
}

.sender {
  margin-top: -32px;
  position: relative;
  top: 30px;
  left: 20px;
}

.sb3:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid var(--v-primary-base);
  border-right: 10px solid transparent;
  border-top: 10px solid var(--v-primary-base);
  border-bottom: 10px solid transparent;
  left: 19px;
  bottom: -19px;
}

.actionBtn {
  z-index: 7;
  position: absolute;
  right: 0;
  top: 0;
}
</style>