<template>
  <v-container>
    <DemandePartenaireActions
      :object="demandePartenaire"
      :withView="false"
      size="xl"
    />
    <v-row class="align-center mx-auto">
      <v-col cols="12">
        <DemandePartenaireObject
          :demandePartenaireProp="demandePartenaire"
          size="xl"
        />
      </v-col>
      <v-col cols="12">
        <DemandePartenairesList
          :demandePartenaireID="demandePartenaire.id"
          :noFilter="true"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { QUERY_demandePartenaire } from "./graphql/queries";
import DemandePartenaireObject from "@/components/COMPONENTS-projet-satelease/DemandePartenaireObject.vue";
import DemandePartenairesList from "@/components/COMPONENTS-projet-satelease/DemandePartenairesList.vue";
import DemandePartenaireActions from "./DemandePartenaireActions.vue";
export default {
  name: "DemandePartenaireView",

  components: {
    DemandePartenaireObject,
    DemandePartenaireActions,
    DemandePartenairesList,
  },

  data: () => ({
    demandePartenaire: {},
  }),

  apollo: {
    demandePartenaire: {
      query: QUERY_demandePartenaire,
      variables() {
        let idQuery = this.showPopup
          ? this.id
          : this.$route.params.id
          ? this.$route.params.id
          : this.id;
        return {
          id: idQuery,
        };
      },
    },
  },
  props: {
    showPopup: {
      type: Boolean,
    },
    id: {
      type: String,
    },
  },
};
</script>

