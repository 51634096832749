<template>
  <div>AvoirForm</div>
</template>

<script>
export default {
  name: "AvoirForm",
};
</script>

