<template>
  <ListLayout
    ref="list"
    :headers="headers"
    :noFilter="noFilter"
    @fetch="init"
    @showMultipleActions="showMultipleActions = $event"
    title="Liste des enveloppes théoriques"
  >
    <template v-slot:multipleActions>
      <EnveloppeTheoriqueActions
        :multiple="true"
        :object="$refs.list ? $refs.list.itemsSelected : []"
        size="xl"
        v-show="showMultipleActions"
        @fetch="init"
        @loading="$refs.list.loading = true"
      />
    </template>
    <template v-slot:customFilters> </template>
    <template v-slot:[`item.object`]="{ item }">
      <EnveloppeTheoriqueObject :enveloppeTheoriqueProp="item" size="xs" />
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <EnveloppeTheoriqueActions
        :object="item"
        size="xs"
        @fetch="init"
        @loading="$refs.list.loading = true"
      />
    </template>
  </ListLayout>
</template>

<script>
import { QUERY_enveloppeTheoriques } from "./graphql/queries";

import EnveloppeTheoriqueActions from "./EnveloppeTheoriqueActions.vue";
import EnveloppeTheoriqueObject from "./EnveloppeTheoriqueObject.vue";
import ListLayout from "@/components/COMPONENTS-skeleton/Layouts/ListLayout.vue";

export default {
  name: "EnveloppeTheoriquesList",

  components: {
    EnveloppeTheoriqueActions,
    EnveloppeTheoriqueObject,
    ListLayout,
  },

  props: {
    noFilter: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },

  data: () => ({
    customFilters: {},
    headers: [
      { text: "", value: "object", sortable: false },
      { text: "Fichiers", value: "fichiers" },
      { text: "Commentaires", value: "commentaires" },
      { text: "Actions", value: "actions", sortable: false },
    ],

    search: null,

    showMultipleActions: false,
  }),

  methods: {
    fetchEnveloppeTheoriques() {
      this.$refs.list.items = [];
      this.$refs.list.loading = true;
      this.$apollo
        .query({
          query: QUERY_enveloppeTheoriques,
          variables: {
            pagination: this.$refs.list.pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          this.$refs.list.items = data.enveloppeTheoriques;
          this.$refs.list.itemsCount = data.enveloppeTheoriquesCount;
          this.$refs.list.loading = false;
        });
    },
    init() {
      this.fetchEnveloppeTheoriques();
    },
  },
};
</script>