<template>
  <div>
    <v-combobox
      v-bind:value="value"
      v-on:input="$emit('input', $event)"
      :items="fichierTypes"
      label="Type de fichier"
      :error-messages="errorMessages"
      :search-input.sync="search"
      chips
      clearable
      multiple
      item-text="value"
      prepend-icon="mdi-file-question"
      dense
    />
  </div>
</template>

<script>
import { QUERY_fichierTypes } from "./graphql/queries";
export default {
  name: "FichierTypeSelector",
  props: ["value", "errorMessages"],
  data: () => ({
    search: "",
  }),
  watch: {
    //Evite de retourner un champ null lors de la suppression d'un chip
    value: function (val) {
      if (val == null) {
        this.$emit("input", "");
      } else {
        this.$emit("input", val);
      }
    },
    search: function () {
      this.$apollo.queries.fichierTypes.refetch();
    },
  },
  apollo: {
    fichierTypes: {
      query: QUERY_fichierTypes,
      variables() {
        return {
          pagination: {
            filter: {
              toFind: this.search,
            },
          },
        };
      },
      fetchPolicy: "cache-and-network",
    },
  },
  methods: {
    refetch(val) {
      this.search = val;
      this.$apollo.queries.fichierTypes.refetch();
    },
  },
};
</script>