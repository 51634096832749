<template>
    <div v-if="template">
      <template v-if="size == 'xl'">
        <ObjectLayout :action="action" size="xl" :item="template">
          <template v-slot:icon>mdi-format-float-left</template>
          <template v-slot:titre> {{ template.name }}</template>
          <template v-slot:body>
            <ObjectLayout v-if="template.name" class="py-2">
              <template v-slot:icon>mdi-tag-text</template>
              <template v-slot:titre>Dénomination du template</template>
              <template v-slot:content>{{ template.name }}</template>
            </ObjectLayout>
          </template>
        </ObjectLayout>
      </template>
      <template v-if="size == 'md'">
        <ObjectLayout size="md">
          <template v-slot:icon>mdi-format-float-left</template>
          <template v-slot:content>{{ template.name }}</template>
          <template v-slot:extracontent>
            <v-icon small class="mr-2">mdi-material-design</v-icon>
            {{ template.subject }}
          </template>
        </ObjectLayout>
      </template>
      <template v-if="size == 'xs'">
        <ObjectLayout
          size="xs"
          :action="action"
          :onClickPush="`/envoi-notifications/templates/view/${template.id}`"
        >
          <template v-slot:icon>mdi-format-float-left</template>
          <template v-slot:content
            >{{ template.subject }} - {{ template.name }}</template
          >
        </ObjectLayout>
      </template>
    </div>
</template>

<script>
import { QUERY_template } from "./graphql/queries";
import ObjectLayout from "@/components/COMPONENTS-skeleton/Layouts/ObjectLayout.vue";
export default {
 name: 'TemplateObject',

  components: {
    ObjectLayout,
  },

  props: {
    action: {
      type: String,
      default: "router",
    },

    templateProp: {
      type: Object,
      default() {
        return {};
      },
    },

    templateID: {
      type: String,
    },

    size: { type: String, default: "md" },
  },

  data: () => ({
    template: undefined,
  }),

  watch: {
    templateID: function () {
      this.init();
    },

    templateProp: function () {
      this.init();
    },
  },

  mounted: function () {
    this.init();
  },

  methods: {
    init() {
      if (this.templateID) {
        let id = this.templateID;
        this.$apollo
          .query({
            query: QUERY_template,
            variables: { id: id },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let template = data.data.template;
            this.template = JSON.parse(JSON.stringify(template));
          });
      } else {
        this.template = JSON.parse(JSON.stringify(this.templateProp));
      }
    },
  },
};
</script>