var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ListLayout',{ref:"list",attrs:{"headers":_vm.headers},on:{"fetch":_vm.init},scopedSlots:_vm._u([{key:"item.object",fn:function(ref){
var item = ref.item;
return [_c('EmailObject',{attrs:{"emailProp":item,"size":"xs"}})]}},{key:"item.subject",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.subject)+" ")]}},{key:"item.sender",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.sender.email)+" ")]}},{key:"item.to",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.displayTo(item.to))+" ")]}},{key:"item.dateEnvoi",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("shortDateAndHourFR")(item.dateEnvoi))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('EmailActions',{attrs:{"object":item,"fetch":_vm.fetchEmails,"size":"xs"}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }