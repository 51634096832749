<template>
  <FormLayout
    icon="icon"
    @ajouter="ajouter"
    @modifier="modifier"
    @reinitialiser="init"
    typeItem="un/une assetEmplacement"
  >
    TEST
    <v-row> </v-row>
  </FormLayout>
</template>
<script>
import { mapState } from "vuex";
import {
  QUERY_assetEmplacement,
  QUERY_assetEmplacements,
} from "./graphql/queries";
import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";
import {
  MUTATION_assetEmplacementCreate,
  MUTATION_assetEmplacementUpdate,
} from "./graphql/mutations";
export default {
  name: "AssetEmplacementForm",
  components: {
    FormLayout,
  },
  data: function () {
    return {
      input: {},
    };
  },
  props: {
    assetEmplacement: {
      type: Object,
      default() {
        return {
          id: undefined,
        };
      },
    },
  },
  computed: {
    ...mapState(["formsErrors"]),
  },
  mounted: function () {
    this.init();
  },
  methods: {
    init() {
      if (this.route.params.id) {
        let id = this.route.params.id;
        this.$apollo
          .query({
            query: QUERY_assetEmplacement,
            variables: {
              id: id,
            },
          })
          .then((data) => {
            let assetEmplacement = data.data.assetEmplacement;
            this.input = JSON.parse(JSON.stringify(assetEmplacement));
          });
      } else {
        this.input = JSON.parse(JSON.stringify(this.assetEmplacement));
      }
    },

    ajouter() {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_assetEmplacementCreate,
          variables: {
            input: toSend,
          },
        })
        .then((__) => {
          this.reinitialiser();
          this.store.commit("addAlert", {
            type: "info",
            text: "Votre assetEmplacement a été ajouté",
          });

          this.root.emit(
            "assets--assetEmplacement-create",
            __.data.assetEmplacementCreate
          );

          this.root.emit("skeleton--close");
        });
    },

    inputConverter(arg) {
      const obj = JSON.parse(JSON.stringify(arg));

      let input = {};

      obj.id != undefined ? (input.id = obj.id) : null;

      return input;
    },

    modifier() {
      let toSend = this.inputConverter(this.input);
      this.$apollo.mutate({
        mutation: MUTATION_assetEmplacementUpdate,
        variables: {
          input: toSend,
        },
        refetchQueries: [
          {
            query: QUERY_assetEmplacements,
          },
        ],
        update: (store, { data: { assetEmplacementUpdate } }) => {
          this.emit("assetEmplacementReturn", assetEmplacementUpdate);
          this.store.commit("addAlert", {
            type: "info",
            text: "Votre assetEmplacement a été modifié",
          });
          this.root.emit("assets--update-assetEmplacements");
          this.root.emit("skeleton--close");
        },
      });
    },
  },
};
</script>