<template>
  <v-container>
    <PartenaireActions :object="partenaire" :withView="false" size="xl" />
    <v-row>
      <v-col cols="12" xl="6" lg="6" md="6" sm="12">
        <PartenaireObject :partenaire="partenaire" size="xl" />
      </v-col>
      <v-col cols="12" xl="6" lg="6" md="6" sm="12">
        <v-card>
          <v-card-title class="primary white--text">
            <v-icon dark class="mr-2">mdi-plus-circle</v-icon>
            Formulaire d'ajout de partenaire
          </v-card-title>
          <PartenaireForm class="py-12 mx-5" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { QUERY_partenaire } from "./graphql/queries";
import PartenaireObject from "@/components/COMPONENTS-projet-satelease/PartenaireObject.vue";
import PartenaireForm from "@/components/COMPONENTS-projet-satelease/PartenaireForm.vue";
import PartenaireActions from "./PartenaireActions.vue";
export default {
  name: "PartenaireView",

  components: {
    PartenaireObject,
    PartenaireForm,
    PartenaireActions,
  },

  data: () => ({
    partenaire: {},
  }),

  props: {
    id: { type: String },
  },

  apollo: {
    partenaire: {
      query: QUERY_partenaire,
      variables() {
        let idpartenaire = this.$route.params.id
          ? this.$route.params.id
          : this.id;
        return {
          id: idpartenaire,
        };
      },
    },
  },
};
</script>

