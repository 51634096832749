<template>
  <FormLayout icon="mdi-briefcase-clock" @ajouter="ajouter" @modifier="modifier" @reinitialiser="init"
    typeItem="une affaire" :item="input" :disabled="false">
    <Assistant :message="message" mood="happy" />
    <TitleLayout titre="Informations administratives" />
    <v-row class="justify-center">
      <v-col cols="12" xl="6" lg="6" md="6" sm="6" class="d-flex flex-row align-center">
        <v-text-field v-model="input.numeroAffaire" label="Numéro d'affaire"
          hint="Le numéro d'affaire est automatiquement généré, à saisir uniquement en cas de reprise de données"
          persistent-hint prepend-icon="mdi-counter" :disabled="!numeroAffaireInput"
          @focus="currentlyActiveField = 'numeroAffaire'" @blur="currentlyActiveField = ''" />
        <v-switch v-model="numeroAffaireInput" />
      </v-col>

      <v-col cols="12" xl="6" lg="6" md="6" sm="6">
        <ClientSelector v-model="input.locataire" label="Locataire (siège)" :isSiege="true" />
      </v-col>

      <v-col cols="12" xl="6" lg="6" md="6" sm="6">
        <PersonneSelector v-model="input.signataireClient" label="Signataire" prepend-icon="mdi-fountain-pen-tip" />
      </v-col>
      <v-col cols="12" xl="6" lg="6" md="6" sm="6">
        <DateInput v-model="input.datePrevInstallation" label="Date prévisionnelle d'installation" />
      </v-col>
    </v-row>
    <TitleLayout titre="Devis et factures founisseurs à financer" />
    <FinancementCapitalAttach @capitalAfinancer="setInputFinancementCapitalAfinancer"
      @capitaux="setInputFinancementRepartition" :devisFournisseurs="$refs.devisFournisseurAttach
        ? $refs.devisFournisseurAttach.devisFournisseurs
        : []
        " :factureFournisseurs="$refs.factureFournisseurAttach
    ? $refs.factureFournisseurAttach.factureFournisseurs
    : []
    " />

    <v-row class="justify-center align-center">
      <v-col cols="12">
        <DevisFournisseurAttach ref="devisFournisseurAttach" v-model="input.devisFournisseurs" />
      </v-col>
      <v-col cols="12">
        <FactureFournisseurAttach ref="factureFournisseurAttach" v-model="input.facturesFournisseurs" />
      </v-col>
    </v-row>
    <TitleLayout titre="Informations financières" />
    <v-row>
      <v-col cols="9" xl="6" lg="6" md="6" sm="6">
        <v-text-field v-model="input.fraisDossier" type="number" label="Frais de dossier" persistent-hint suffix="€"
          prepend-icon="mdi-cash" append-icon="mdi-calculator" @click:append="calcFraisDossier" />
      </v-col>
      <v-col cols="12">
        <FinancementForm v-model="input.financement" :datePrevInstallation="input.datePrevInstallation"
          ref="financementForm" />
      </v-col>
    </v-row>
    <TitleLayout titre="Rétrocession" />
    <v-row>
      <v-col cols="12" xl="6" lg="6" md="6" sm="6">
        <PersonneSelector v-model="input.commissionPersonnes" label="Apporteurs d'affaire (personnes)"
          prepend-icon="mdi-account-cash" :multiple="true" />
      </v-col>
      <v-col cols="12" xl="6" lg="6" md="6" sm="6">
        <EntrepriseSelector v-model="input.commissionEntreprises" label="Apporteurs d'affaire (entreprises)"
          :multiple="true" />
      </v-col>
    </v-row>
  </FormLayout>
</template>

<script>
import { mapState } from "vuex";

import {
  MUTATION_affaireCreate,
  MUTATION_affaireUpdate,
} from "./graphql/mutations";
import {
  QUERY_affaire,
  QUERY_projetSateLeaseOrganisationParametres,
} from "./graphql/queries";

import Assistant from "@/components/COMPONENTS-skeleton/Assistant.vue";
import ClientSelector from "@/components/COMPONENTS-devis-facture/ClientSelector.vue";
import DateInput from "@/components/COMPONENTS-skeleton/Inputs/DateInput";
import DevisFournisseurAttach from "@/components/COMPONENTS-devis-facture/DevisFournisseurAttach.vue";
import EntrepriseSelector from "@/components/COMPONENTS-annuaire/EntrepriseSelector";
import FactureFournisseurAttach from "@/components/COMPONENTS-devis-facture/FactureFournisseurAttach.vue";
import FinancementCapitalAttach from "./FinancementCapitalAttach.vue";
import FinancementForm from "./FinancementForm.vue";
import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";
import PersonneSelector from "@/components/COMPONENTS-annuaire/PersonneSelector";
import TitleLayout from "@/components/COMPONENTS-skeleton/Layouts/TitleLayout.vue";

export default {
  name: "AffaireForm",

  components: {
    Assistant,
    ClientSelector,
    DateInput,
    DevisFournisseurAttach,
    EntrepriseSelector,
    FactureFournisseurAttach,
    FinancementCapitalAttach,
    FinancementForm,
    FormLayout,
    PersonneSelector,
    TitleLayout,
  },

  props: {
    componentParent: undefined,
    fromAffaire: { type: String, default: undefined },
  },

  data: () => ({
    affaire: {
      dateCreation: new Date().toISOString().substr(0, 10),
      datePrevInstallation: undefined,
      datePrevLoyer: undefined,
      devisFournisseurs: [],
      facturesFournisseurs: [],
      financement: {
        // Present value (should be entered as negative)
        capitalAfinancer: undefined,
        // Compounding Frequency : 12 pour par mois
        cf: undefined,
        //Is payment made at the beginning of périod ?
        isBeginning: undefined,
        loyers: [],
        // Nombre de périodes principales
        nper: undefined,
        // Nombre de périodes complémentaires
        nperComp: undefined,
        // Payment Frequency : 12 pour par mois
        pf: undefined,
        // Taux d'interet
        rate: undefined,
        repartition: [],
        //Future Value (valeur résiduelle)
        valeurFinFinancement: undefined,
      },
      fraisDossier: undefined,
      id: undefined,
      locataire: null,
      numeroAffaire: "",
      signatairesClient: [],
    },
    currentlyActiveField: "",
    input: {},
    menuDatePickerDateCreation: false,
    numeroAffaireInput: false,
    projetSateLeaseOrganisationParametres: {},
  }),

  watch: {
    fromAffaire: function () {
      this.init();
    },
    //Si on desactive l'input, on supprime la donnée stockée
    numeroAffaireInput: function (val) {
      if (!val) {
        this.input.numeroAffaire = this.affaire.numeroAffaire;
      }
    },
  },

  computed: {
    ...mapState(["formsErrors"]),

    devisFournisseurs() {
      return this.input.devisFournisseurs;
    },

    message() {
      let text = `Chouette ! On va maintenant créer une affaire !`;
      if (this.input.id) {
        text = `Allez, on modifie l'affaire !`;
      }
      if (!this.input.client) {
        text += "<br/>Commencez par ajouter un client !";
      }
      return text;
    },

    size() {
      const size = { xs: "x-small", sm: "small", lg: "large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
  },

  mounted: async function () {
    await this.$apollo
      .query({
        query: QUERY_projetSateLeaseOrganisationParametres,
      })
      .then((data) => {
        this.projetSateLeaseOrganisationParametres =
          data.data.projetSateLeaseOrganisationParametres;
      });

    this.init();
  },

  methods: {
    ajouter() {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_affaireCreate,
          variables: { input: toSend },
        })
        .then((__) => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "L'affaire a été ajoutée",
          });

          if (this.componentParent) {
            this.$root.$emit(this.componentParent, __.data.affaireCreate);
            this.$root.$emit("skeleton--close");
          } else {
            this.$router.push({ path: `/satelease/affaires/edit/${__.data.affaireCreate.id}` })
          }

          this.init();
        });
    },

    calcFraisDossier() {
      const fraisDossierPourcentage =
        this.projetSateLeaseOrganisationParametres.fraisDossierPourcentage;
      const fraisDossierMinimum =
        this.projetSateLeaseOrganisationParametres.fraisDossierMinimum;
      let montantFraisDossier =
        this.input.financement.capitalAfinancer > 0
          ? (this.input.financement.capitalAfinancer *
            fraisDossierPourcentage) /
            100 <
            fraisDossierMinimum
            ? fraisDossierMinimum
            : (this.input.financement.capitalAfinancer *
              fraisDossierPourcentage) /
            100
          : fraisDossierMinimum;
      this.$set(this.input, "fraisDossier", parseFloat(montantFraisDossier).toFixed(2));
    },

    init() {
      if (
        this.$route.path.startsWith("/satelease/affaires/edit/") &&
        this.$route.params.id
      ) {
        let id = this.$route.params.id;
        this.$apollo
          .query({
            query: QUERY_affaire,
            variables: { id: id },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let affaire = data.data.affaire;
            this.input = this.inputConverter(affaire);
            // this.updatePanelEtb();
          });
      } else if (this.$route.query.fromAffaire || this.fromAffaire) {
        let fromAffaire = this.$route.query.fromAffaire
          ? this.$route.query.fromAffaire
          : this.fromAffaire;

        this.repriseDataFromAffaire(fromAffaire);
      } else {
        this.input = JSON.parse(JSON.stringify(this.affaire));
        //On applique le paramètrage par défaut
        this.input.financement.rate =
          this.projetSateLeaseOrganisationParametres.affaireFinancementDefaultRate;
        this.input.financement.nper =
          this.projetSateLeaseOrganisationParametres.affaireFinancementDefaultNpPrincipales;
        this.input.financement.nperComp =
          this.projetSateLeaseOrganisationParametres.affaireFinancementDefaultNpComplementaires;
        this.input.financement.valeurFinFinancement =
          this.projetSateLeaseOrganisationParametres.affaireFinancementDefaultFV;
        this.input.financement.isBeginning =
          this.projetSateLeaseOrganisationParametres.affaireFinancementDefaultIsBeginning;
        this.input.financement.cf =
          this.projetSateLeaseOrganisationParametres.affaireFinancementDefaultCf;
        this.input.financement.pf =
          this.projetSateLeaseOrganisationParametres.affaireFinancementDefaultCf;
      }
    },

    inputConverter(arg) {
      const obj = JSON.parse(JSON.stringify(arg));

      let commissionPersonnes = [];
      if (obj.commissionPersonnes) {
        for (let commissionPersonne of obj.commissionPersonnes) {
          commissionPersonnes.push(
            commissionPersonne.id ? commissionPersonne.id : commissionPersonne
          );
        }
      }

      let commissionEntreprises = [];
      if (obj.commissionEntreprises) {
        for (let commissionEntreprise of obj.commissionEntreprises) {
          commissionEntreprises.push(
            commissionEntreprise.id
              ? commissionEntreprise.id
              : commissionEntreprise
          );
        }
      }

      let devisFournisseurs = [];
      if (obj.devisFournisseurs) {
        for (let objDevisFournisseur of obj.devisFournisseurs) {
          devisFournisseurs.push(
            objDevisFournisseur.id
              ? objDevisFournisseur.id
              : objDevisFournisseur
          );
        }
      }

      let facturesFournisseurs = [];
      if (obj.facturesFournisseurs) {
        for (let facturesFournisseur of obj.facturesFournisseurs) {
          facturesFournisseurs.push(
            facturesFournisseur.id
              ? facturesFournisseur.id
              : facturesFournisseur
          );
        }
      }

      let input = {
        numeroAffaire: obj.numeroAffaire,
        financement: this.$refs.financementForm.inputConverter(obj.financement),
        locataire:
          obj.locataire && obj.locataire.id ? obj.locataire.id : obj.locataire,
        commissionPersonnes: commissionPersonnes,
        commissionEntreprises: commissionEntreprises,
        signataireClient:
          obj.signataireClient && obj.signataireClient.id
            ? obj.signataireClient.id
            : obj.signataireClient,
        datePrevInstallation: obj.datePrevInstallation,
        devisFournisseurs: devisFournisseurs,
        facturesFournisseurs: facturesFournisseurs,
        fraisDossier: parseFloat(obj.fraisDossier),
      };

      obj.id != undefined ? (input.id = obj.id) : null;

      return input;
    },

    modifier() {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_affaireUpdate,
          variables: { input: toSend },
        })
        .then(() => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "L'affaire a été modifiée",
          });
          this.$root.$emit("skeleton--close");
        });
    },

    repriseDataFromAffaire(id) {
      this.$apollo
        .query({
          query: QUERY_affaire,
          variables: { id: id },
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          let reprise = data.data.affaire;
          reprise.numeroAffaire = undefined;
          reprise.meta = [];
          reprise.meta.push({ fromAffaire: reprise.id });
          reprise.id = undefined;
          reprise.affaire = id;
          this.input = this.inputConverter(reprise);
        });
    },

    setInputFinancementCapitalAfinancer(val) {
      if (this.input.financement) {
        this.$set(this.input.financement, "capitalAfinancer", val);
      }
    },

    setInputFinancementRepartition(val) {
      let repartition = [];
      val.forEach((elem) => {
        repartition.push({
          client: elem.client.id,
          partCapital: elem.pourcent,
          montantTVA: elem.tva,
        });
      });

      if (this.input.financement) {
        this.$set(this.input.financement, "repartition", repartition);
      }
    },
  },
};
</script>