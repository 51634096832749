<template>
  <div>
    <CounterLayout :compteur="factureFournisseursCount">
      <template v-slot:icon>mdi-file-table</template>
    </CounterLayout>
  </div>
</template>

<script>
import { QUERY_factureFournisseursCount } from "./graphql/queries";

import CounterLayout from "@/components/COMPONENTS-skeleton/Layouts/CounterLayout.vue";

export default {
  name: "FactureFournisseursCounter",

  components: {
    CounterLayout,
  },
  props: {
    customSearch: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  apollo: {
    factureFournisseursCount: {
      query: QUERY_factureFournisseursCount,
      variables() {
        return {
          pagination: {
            filter: {
              customSearch: this.customSearch,
            },
          },
        };
      },
      fetchPolicy: "no-cache",
    },
  },
};
</script>
