<template>
  <SelectorLayout
    :addAction="customAddAction"
    @change="$emit('change', $event)"
    :disabled="
      disabled !== undefined
        ? disabled
        : !item && !(fournisseurPreSelect || clientPreSelect)
    "
    @fetch="fetch"
    :label="label"
    :prepend-icon="prependIcon"
    ref="selector"
    v-model="item"
  >
    <template v-slot:preSelect v-if="preSelector">
      <v-row v-if="!item && !factureByFournisseur">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="primary"
              v-bind="attrs"
              v-on="on"
              class="mt-4"
              @click="
                preSelect == 'fournisseur'
                  ? (preSelect = 'client')
                  : (preSelect = 'fournisseur')
              "
            >
              <v-icon> mdi-cached </v-icon>
            </v-btn>
          </template>
          <span>{{
            preSelect == "fournisseur"
              ? "Chercher par client"
              : "Chercher par fournisseur"
          }}</span>
        </v-tooltip>
        <v-col>
          <FournisseurSelector
            v-model="fournisseurPreSelect"
            v-if="preSelect == 'fournisseur'"
          />
          <ClientSelector v-model="clientPreSelect" v-else />
        </v-col>
      </v-row>
    </template>

    <template v-slot:selection="data">
      <FactureClientObject
        :factureClientProp="data.item"
        size="xs"
        v-bind="data.attrs"
        :input-value="data.selected"
        @click="data.select"
        action="none"
      />
    </template>

    <template v-slot:item="data">
      <FactureClientObject :factureClientProp="data.item" action="none" />
    </template>
  </SelectorLayout>
</template>

<script>
import { QUERY_factureClients } from "./graphql/queries";

import ClientSelector from "./ClientSelector.vue";
import FactureClientObject from "./FactureClientObject.vue";
import FournisseurSelector from "./FournisseurSelector.vue";
import SelectorLayout from "@/components/COMPONENTS-skeleton/Layouts/SelectorLayout.vue";

export default {
  name: "FactureSelector",

  components: {
    ClientSelector,
    FactureClientObject,
    FournisseurSelector,
    SelectorLayout,
  },

  props: {
    addAction: { type: String, default: "/devis-facture/facture-clients/add" },
    client: null,
    disabled: { type: Boolean, default: undefined },
    errorMessage: { type: String },
    factureByFournisseur: { type: Object, default: null },
    label: { type: String, default: "Facture" },
    multiple: { type: Boolean, default: false },
    prependIcon: { type: String, default: "mdi-file-table" },
    preSelector: { type: Boolean, default: true },
    valide: { type: Boolean, default: undefined },
    value: null,
  },

  data: () => ({
    clientPreSelect: null,
    fournisseurPreselect: null,
    item: null,
    preSelect: "fournisseur",
  }),

  computed: {
    customAddAction() {
      let toReturn = this.addAction;
      if (this.fournisseurPreSelect) {
        toReturn.includes("?") ? (toReturn += "&") : (toReturn += "?");
        toReturn += "fournisseur=" + this.fournisseurPreSelect;
      }
      if (this.clientPreSelect) {
        toReturn.includes("?") ? (toReturn += "&") : (toReturn += "?");
        toReturn += "client=" + this.clientPreSelect;
      }

      return toReturn;
    },
  },

  methods: {
    fetch() {
      this.$refs.selector.loading = true;
      let pagination = this.$refs.selector.pagination;
      if (this.valide !== undefined) {
        pagination.filter.customSearch.push({
          valide: this.valide,
        });
      }
      if (this.client) {
        pagination.filter.customSearch.push({
          client: this.client,
        });
      }

      if (this.preSelect == "fournisseur" && this.fournisseurPreSelect) {
        pagination.filter.customSearch.push({
          fournisseur: this.fournisseurPreSelect
            ? this.fournisseurPreSelect.id
              ? this.fournisseurPreSelect.id
              : this.fournisseurPreSelect
            : undefined,
        });
      }
      if (this.preSelect == "client" && this.clientPreSelect) {
        pagination.filter.customSearch.push({
          client: this.clientPreSelect
            ? this.clientPreSelect.id
              ? this.clientPreSelect.id
              : this.clientPreSelect
            : undefined,
        });
      }
      this.$apollo
        .query({
          query: QUERY_factureClients,
          variables: {
            pagination: pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          this.$refs.selector.items = data.factureClients;
          this.$refs.selector.loading = false;
        });
    },
  },

  watch: {
    client: function (val) {
      if (val) {
        this.fetch();
      }
    },

    clientPreSelect(val) {
      if (val) {
        this.fetch();
      }
    },

    factureByFournisseur() {
      this.fournisseurPreSelect = this.factureByFournisseur;
    },

    fournisseurPreSelect(val) {
      if (val) {
        this.fetch();
      }
    },

    value: {
      deep: true,
      handler(val) {
        this.item = val && val.id ? val.id : val;
        this.fetch();
      },
    },

    item: {
      deep: true,
      handler(val) {
        if (val === null) {
          this.$emit("input", null);
        } else {
          this.$emit("input", val);
        }
      },
    },
  },
};
</script>
