<template>
  <v-container fluid class="pa-0">
    <slot name="multipleActions"></slot>
    <v-card>
      <v-card-title class="primary white--text py-2">
        <v-icon class="mr-2 white--text">mdi-view-list</v-icon>
        {{ title }}
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="pt-3">
        <v-card-subtitle v-if="subTitle" class="justify-center py-6 pl-8 text-subtitle-1">
          <template>{{ subTitle }}</template>
        </v-card-subtitle>
        <v-data-table :mobile-breakpoint="1280" :calculate-widths="true" :headers="headerComputed" hide-default-footer
          @input="changeItemsSelected" v-model="itemsSelected" :items="items" :items-per-page="displayLimit"
          :loading="loading" loading-text="Chargement des données..." :show-select="hasmultipleActionsSlot"
          @update:sort-by="sorting.column = $event" @update:sort-desc="sorting.desc = $event">
          <template v-slot:top>
            <v-row class="px-8 py-5">
              <v-text-field class="pt-2" v-model="search" prepend-icon="mdi-magnify" label="Recherche" single-line
                hide-details @input="checkSearchInput" />


              <slot name="customFilters" v-if="!noFilter"></slot>
              <v-select v-if="itemsCount > 10" class="pt-2" style="max-width: 100px" v-model="displayLimit"
                :items="displayLimitItems" label="Eléments par page" />
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-checkbox v-if="hasMyElementsFilter" prepend-icon="mdi-account" v-model="onlyCreatedByMe"
                      class="mt-2" />
                  </div>
                </template>
                <span>Mes éléments</span>
              </v-tooltip>
            </v-row>
          </template>
          <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
            <slot :name="name" v-bind="data"></slot>
          </template>
          <template v-slot:[`item.fichiers`]="{ item }">
            <FichiersCounter :customSearch="[
                { 'meta.typeParent': item.__typename },
                { 'meta.idParent': item.id },
              ]" />
          </template>
          <template v-slot:[`item.commentaires`]="{ item }">
            <CommentairesCounter :customSearch="[
                { 'meta.typeParent': item.__typename },
                { 'meta.idParent': item.id },
              ]" />
          </template>
          <template v-slot:footer>
            <v-pagination v-if="paginationLength > 1" v-model="currentPage" :length="paginationLength" circle
              :total-visible="7" />
            <v-row class="mt-4">
              <v-col></v-col>
              <v-col cols="4">
                <v-row>
                  <v-col>
                    <v-select :items="exportFormats" v-model="exportFormat" label="Format" />
                  </v-col>
                  <v-col>
                    <v-btn color="primary" @click="exportList" class="mt-2">
                      Exporter
                    </v-btn>
                  </v-col>
                </v-row>

              </v-col>

            </v-row>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import CommentairesCounter from "@/components/COMPONENTS-collaboration/CommentairesCounter";
import FichiersCounter from "@/components/COMPONENTS-fichiers-stockages/FichiersCounter.vue";

import { QUERY_userMe } from "@/components/COMPONENTS-authentification-habilitation/graphql/queries.js";

import exportFromJSON from 'export-from-json'

export default {
  name: "ListLayout",

  components: {
    CommentairesCounter,
    FichiersCounter,
  },

  props: {
    // Les colonnes ainsi que leur nom, id et options
    headers: {
      type: Array,
    },

    formatDataForExport: {
      type: Function,
      default(e) {
        return e;
      }
    },

    hasMyElementsFilter: {
      type: Boolean,
      default: true
    },

    noFilter: {
      type: Boolean,
      default() {
        return false;
      },
    },

    subTitle: {
      type: String,
      default() {
        return null;
      },
    },

    title: {
      type: String,
      default() {
        return this.$route.name;
      },
    },
    typename: {
      type: String,
      default: undefined,
    },
  },

  data: () => ({
    // Permet de faire des recherches en dehors de la barre de recherche
    customSearch: [],
    // Page d'affichage courante
    currentPage: 1,
    // Nombre d'éléments à afficher par page
    displayLimit: 10,
    displayLimitItems: [10, 20, 50, 100],
    // Format choisi pour exporter la liste
    exportFormat: 'csv',
    // Formats disponibles pour exporter la liste
    exportFormats: [{ text: "CSV", value: "csv" }, { text: "EXCEL", value: "xls" }, { text: "JSON", value: "json" }],
    // Les items affichés sur la page courante
    items: [],
    // Nombre d'items correspondant aux critères envoyés
    itemsCount: 0,
    // Tableau des éléments selectionnés
    itemsSelected: [],
    // Permet d'afficher une barre de chargement pendant que les données sont récupérées
    loading: true,
    // Permet de ne rechercher que les items qui ont été créés par l'utilisateur courant
    onlyCreatedByMe: false,
    // Recherche tapée par l'utilisateur dans le champ "Recherche"
    search: "",
    // Objet contenant le nom de la colonne à trier et si l'ordre de tri est ascendant ou descendant
    sorting: {
      column: undefined,
      desc: undefined,
    },
    userMe: {}
  }),

  // Lors de la création d'une liste, on écoute grâce à l'id du composant
  // généré si un formulaire renvoie de la data en réponse au clic "ajout"
  created() {
    if (this.typename) {
      this.$root.$on("fetch-" + this.typename, () => {
        this.$emit("fetch");
      });
    }
  },

  beforeDestroy() {
    this.$root.$off("fetch-" + this.typename);
  },

  mounted: async function () {
    await this.init();
  },

  computed: {
    hasmultipleActionsSlot() {
      return !!this.$slots.multipleActions;
    },

    headerComputed() {
      let array = this.headers;
      for (let i = 0; i < array.length; i++) {
        if (array[i].position) {
          let position = array[i].position - 1;
          array.splice(position, 0, array.splice(i, 1)[0]);
        }
      }
      return array;
    },

    pagination() {
      return {
        page: this.currentPage,
        limit: this.displayLimit,
        filter: {
          toFind: this.search,
          customSearch: this.customSearch,
        },
        sort: this.sorting,
      };
    },

    // Calcul le nombre de pages en fonction du nombre d'entreprises et du displayLimit
    paginationLength() {
      let length = 0;
      if (this.itemsCount && this.displayLimit) {
        length = parseInt(
          Math.trunc(this.itemsCount / this.displayLimit) +
          (this.itemsCount % this.displayLimit > 0 ? 1 : 0)
        );
      }

      return length;
    },

    path() {
      return this.$route.path;
    },

    query() {
      return this.$route.query;
    },
  },
  watch: {
    currentPage() {
      this.$emit("fetch");
    },

    displayLimit() {
      this.currentPage = 1;
      this.$emit("fetch");
    },

    items() {
      this.changeItemsSelected([]);
    },

    onlyCreatedByMe() {
      this.createdByUserVerif();
    },

    async path() {
      await this.init();
    },

    async query() {
      await this.init();
    },

    sorting: {
      deep: true,
      handler() {
        this.$emit("fetch");
      },
    },

    userMe(val) {
      if (val.preferences && val.preferences.mesElements != undefined) {
        this.onlyCreatedByMe = val.preferences.mesElements;
      }
    }
  },

  methods: {
    changeItemsSelected(items) {
      if (items.length == 0) {
        this.$emit("showMultipleActions", false);
      } else {
        this.$emit("showMultipleActions", true);
      }
      this.itemsSelected = items;
    },

    checkSearchInput(value) {
      if (value.length >= 1 || value.length == 0) {
        this.currentPage = 1;
        this.$emit("fetch");
      }
    },

    createdByUserVerif() {
      if (this.onlyCreatedByMe == true) {
        this.customSearch.push({ createdBy: this.userMe.id });
        this.$emit("fetch");
      } else {
        let index = this.customSearch.findIndex(elem => elem.createdBy != undefined)
        if (index > -1) this.customSearch.splice(index, 1);
        this.$emit("fetch");
      }
    },

    exportList() {
      const data = this.exportFormat == 'json' ? this.items : this.formatDataForExport(this.items);
      const fileName = `${this.title.replaceAll(' ', '_').toLowerCase()}_p${this.currentPage}_d${this.displayLimit}`;
      const exportType = this.exportFormat;

      exportFromJSON({ data, fileName, exportType })
    },

    async init() {
      // this.customSearch = [];
      let response = await this.$apollo
        .query({
          query: QUERY_userMe,
          fetchPolicy: 'no-cache'
        })

      this.userMe = response.data.userMe;

      let urlParts = this.path.split("/");
      let listOrDeleted = urlParts[urlParts.length - 1];
      this.itemsAreActive = !listOrDeleted.startsWith("deleted");

      // On cherche si le paramètre "isActif" est déjà passé, si oui on l'édite, sinon on l'ajoute (Utile lors des changements de routes)
      function estIsActif(search) {
        return Object.prototype.hasOwnProperty.call(search, "isActif");
      }
      let res = this.customSearch.find(estIsActif);
      if (res) {
        res.isActif = this.itemsAreActive;
      } else {
        this.customSearch.push({ isActif: this.itemsAreActive });
      }

      this.createdByUserVerif();

      this.$emit("fetch");
    },
  },
};
</script>
