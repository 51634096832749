<template>
  <CounterLayout :compteur="pointFacturationsCount">
    <template v-slot:icon>mdi-wallet</template>
  </CounterLayout>
</template>

<script>
import { QUERY_pointFacturationsCount } from "./graphql/queries";

import CounterLayout from "@/components/COMPONENTS-skeleton/Layouts/CounterLayout.vue";

export default {
  name: "PointFacturationsCounter",

  components: {
    CounterLayout,
  },

  props: {
    customSearch: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  apollo: {
    pointFacturationsCount: {
      query: QUERY_pointFacturationsCount,
      variables() {
        return {
          pagination: {
            filter: {
              customSearch: this.customSearch,
            },
          },
        };
      },
      fetchPolicy: "no-cache",
    },
  },

  created() {
    if (!this.$root._events.devisFactureUpdatePointFacturations) {
      this.$root.$on("devisFactureUpdatePointFacturations", this.updateQuery);
    }
  },

  methods: {
    updateQuery: function () {
      this.$apollo.queries.pointFacturationsCount.refetch();
    },
  },
};
</script>