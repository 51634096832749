<template>
  <div>
    <CounterLayout :compteur="equipementsCount">
      <template v-slot:icon>mdi-antenna</template>
    </CounterLayout>
  </div>
</template>

<script>
import CounterLayout from "@/components/COMPONENTS-skeleton/Layouts/CounterLayout.vue";
import { QUERY_equipementsCount } from "./graphql/queries";
export default {
  name: "EquipementCounter",

  components: {
    CounterLayout,
  },

  props: {
    customSearch: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data: () => ({}),
  apollo: {
    equipementsCount: {
      query: QUERY_equipementsCount,
      variables() {
        return {
          pagination: {
            filter: {
              customSearch: this.customSearch,
            },
          },
        };
      },
      fetchPolicy: "no-cache",
    },
  },
  created() {
    if (!this.$root._events.authentificationHabilitationUpdateEquipements) {
      this.$root.$on(
        "authentificationHabilitationUpdateEquipements",
        this.updateQuery
      );
    }
  },

  mounted: function () {},
  watch: {},
  methods: {
    updateQuery: function () {
      this.$apollo.queries.equipementsCount.refetch();
    },
  },
};
</script>