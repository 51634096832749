<template>
  <div>
    <v-dialog
      v-model="dialog"
    >
      <ListLayout
        ref="list"
        :headers="headers"
        @fetch="fetchEntreprises"
        title="Liste des entreprises correspondantes"
      >
        <template v-slot:customFilters>
          <v-text-field
            label="Code postal"
            v-model="filtersLocal.code_postal"
          />
        </template>
        <template v-slot:[`item.raisonSociale`]="{ item }">
          {{ item.raisonSociale }}
        </template>
        <template v-slot:[`item.siren`]="{ item }">
          {{ item.siren }}
        </template>
        <template v-slot:[`item.formeJuridique`]="{ item }">
          {{ item.formeJuridique }}
        </template>
        <template v-slot:[`item.choice`]="{ item }">
          <v-btn
            color="primary"
            @click="getEntreprise(item.siren)"
          >
            <v-icon>
              mdi-check
            </v-icon>
          </v-btn>
        </template>
      </ListLayout>
    </v-dialog>
  </div>
</template>

<script>
import { QUERY_entrepriseInformationFromSiren, QUERY_entreprisesInformationsFromFilters } from './graphql/queries';
import ListLayout from "@/components/COMPONENTS-skeleton/Layouts/ListLayout.vue";

export default {
  name: "EntreprisesListByFilters",

  components: {
    ListLayout
  },

  data: () => ({
    dialog: true,
    filtersLocal: {},
    headers: [
      {
        text: "Raison sociale",
        value: "raisonSociale",
        sortable: false,
      },
      {
        text: "Siren",
        value: "siren",
        sortable: false,
      },
      {
        text: "Forme juridique",
        value: "formeJuridique",
        sortable: false,
      },
      {
        text: "",
        value: "choice",
        sortable: false,
      }
    ],
  }),

  methods: {
    fetchEntreprises() {
      this.dialog = true;
      let filters = JSON.parse(JSON.stringify(this.filters));
      filters.page = this.$refs.list.pagination.page;
      filters.par_page = this.$refs.list.pagination.limit;
      for(let key in this.filtersLocal) {
        filters[key] = this.filtersLocal[key];
      }

      this.$refs.list.loading = true;
      this.$apollo
        .query({
          query: QUERY_entreprisesInformationsFromFilters,
          variables: { filters },
          fetchPolicy: "no-cache",
        })
        .then(async (__) => {
          const data = __.data;
          console.log("data", data)
          this.$refs.list.items = data.entreprisesInformationsFromFilters.entreprises;
          this.$refs.list.itemsCount = data.entreprisesInformationsFromFilters.count;
          this.$refs.list.loading = false;
        });
    },

    getEntreprise(siren) {
      if(this.returnOnlySiren) {
        this.$emit('getEntreprise', siren)
        this.dialog = false;
      }else {
        this.$apollo
          .query({
            query: QUERY_entrepriseInformationFromSiren,
            variables: { siren },
            fetchPolicy: 'no-cache'
          })
          .then(__ => {
            this.$emit('getEntreprise', __.data.entrepriseInformationFromSiren)
            this.dialog = false;
          })
      }
    }
  },

  props: {
    filters: {
      type: Object,
      default() {
        return {}
      }
    },

    returnOnlySiren: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    filters: {
      deep: true,
      handler() {
        this.fetchEntreprises();
      }
    },

    filtersLocal: {
      deep: true,
      handler() {
        this.fetchEntreprises()
      }
    }
  }
}
</script>