<template>
  <v-container class="fill-height fullBG" fluid>
    <v-row align="center" justify="center">
      <v-col lg="5">
        <LoginAndRegisterForm @changeBG="changeBG" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LoginAndRegisterForm from "./LoginAndRegisterForm.vue";

export default {
  name: "LoginPage",

  components: {
    LoginAndRegisterForm,
  },

  methods: {
    changeBG(bg) {
      this.homeBgUrl = bg;
      document.querySelectorAll('.fullBG')[0].style.backgroundImage = `linear-gradient(to bottom, rgba(245, 246, 252, 0.20), rgba(0, 0, 0, 0.60)),url(${bg})`;
    }
  }
};

</script>

<style scoped lang="scss">
.fullBG {
  /* Location of the image */
  background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0.20), rgba(0, 0, 0, 0.60)), url("~@/assets/HomeBG.jpg");

  /* Background image is centered vertically and horizontally at all times */
  background-position: center center;

  /* Background image doesn't tile */
  background-repeat: no-repeat;

  /* Background image is fixed in the viewport so that it doesn't move when 
       the content's height is greater than the image's height */
  background-attachment: fixed;

  /* This is what makes the background image rescale based
       on the container's size */
  background-size: cover;

  /* Set a background color that will be displayed
       while the background image is loading */
  background-color: "#464646";
}
</style>