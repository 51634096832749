<template>
  <div>
    <template v-if="asset">
      <template v-if="size == 'xl'">
        <ObjectLayout :action="action" size="xl" :item="asset">
          <template v-slot:icon>mdi-package-variant-closed</template>
          <template v-slot:titre> {{ asset.label }}</template>
          <template v-slot:body>
            <ObjectLayout v-if="asset.label" class="py-2">
              <template v-slot:icon>mdi-tag-text</template>
              <template v-slot:titre>Dénomination de l'asset</template>
              <template v-slot:content>{{ asset.label }}</template>
            </ObjectLayout>

            <ObjectLayout
              v-if="asset.modele && asset.modele.marque.nom"
              class="py-2"
            >
              <template v-slot:icon>mdi-material-design</template>
              <template v-slot:titre>Marque</template>
              <template v-slot:content>{{ asset.modele.marque.nom }}</template>
            </ObjectLayout>
            <ObjectLayout v-if="asset.modele && asset.modele.nom" class="py-2">
              <template v-slot:icon>mdi-barcode-scan</template>
              <template v-slot:titre>Modèle</template>
              <template v-slot:content>{{ asset.modele.nom }}</template>
            </ObjectLayout>
          </template>
          <template v-slot:body2>
            <ObjectLayout v-if="asset.numeroSerie" class="py-2">
              <template v-slot:icon>mdi-numeric</template>
              <template v-slot:titre>Numéro de série</template>
              <template v-slot:content>{{ asset.numeroSerie }}</template>
            </ObjectLayout>

            <ObjectLayout v-if="asset.numeroInventaire" class="py-2">
              <template v-slot:icon>mdi-card-bulleted</template>
              <template v-slot:titre>Numéro d'inventaire</template>
              <template v-slot:content>{{ asset.numeroInventaire }}</template>
            </ObjectLayout>

            <ObjectLayout v-if="asset.etatAsset" class="py-2">
              <template v-slot:icon>mdi-hexagon-slice-6</template>
              <template v-slot:titre>Etat de l'asset</template>
              <template v-slot:content>
                <EtatAssetObject :etatAsset="asset.etatAsset" size="xs" />
              </template>
            </ObjectLayout>

            <ObjectLayout v-if="asset.dureeGarantie" class="py-2">
              <template v-slot:icon>mdi-timeline-clock</template>
              <template v-slot:titre>Garantie</template>
              <template v-slot:content>{{ asset.dureeGarantie }} ans</template>
            </ObjectLayout>
          </template>
        </ObjectLayout>
      </template>
      <template v-if="size == 'md'">
        <ObjectLayout size="md">
          <template v-slot:icon>mdi-package-variant-closed</template>
          <template v-slot:content>{{ asset.label }}</template>
          <template v-slot:extracontent>
            <v-icon small class="mr-2">mdi-material-design</v-icon>
            {{ asset.numeroInventaire }}
          </template>
        </ObjectLayout>
      </template>
      <template v-if="size == 'xs'">
        <ObjectLayout
          size="xs"
          :action="action"
          :onClickPush="`/assets/assets/view/${asset.id}`"
          :styleXs="styleXs"
        >
          <template v-slot:icon>mdi-package-variant-closed</template>
          <template v-slot:content>
            {{ asset.numeroInventaire }} - {{ asset.label }}
          </template>
          <template v-slot:tooltip v-if="tooltip">
            <template v-if="asset.label">
              <b class="font-weight-bold">Label:</b>
              {{ asset.label }} <br />
            </template>
            <template v-if="asset.modele.marque.nom">
              <b class="font-weight-bold">Marque:</b>
              {{ asset.modele.marque.nom }} <br />
            </template>
            <template v-if="asset.modele.nom">
              <b class="font-weight-bold">Modèle:</b>
              {{ asset.modele.nom }} <br />
            </template>
            <template v-if="asset.etatAsset">
              <b class="font-weight-bold">Etat:</b>
              {{ asset.etatAsset }} <br />
            </template>
            <template v-if="asset.numeroInventaire">
              <b class="font-weight-bold">N° inventaire:</b>
              {{ asset.numeroInventaire }} <br />
            </template>
            <template v-if="asset.numeroSerie">
              <b class="font-weight-bold">N° série:</b>
              {{ asset.numeroSerie }} <br />
            </template>
            <template v-if="asset.dureeGarantie">
              <b class="font-weight-bold">Garantie:</b>
              {{ asset.dureeGarantie }} ans 
            </template>
          </template>
        </ObjectLayout>
      </template>
    </template>
  </div>
</template>

<script>
import { QUERY_asset } from "./graphql/queries";

import EtatAssetObject from "./EtatAssetObject.vue";
import ObjectLayout from "@/components/COMPONENTS-skeleton/Layouts/ObjectLayout.vue";

export default {
  name: "AssetObject",

  components: {
    EtatAssetObject,
    ObjectLayout,
  },

  props: {
    action: {
      type: String,
      default: "router",
    },

    assetID: {
      type: String,
    },

    assetProp: {
      type: Object,
      default() {
        return {};
      },
    },

    size: { type: String, default: "md" },

    styleXs: {
      type: String,
      default: undefined
    },

    tooltip: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    asset: undefined,
  }),

  watch: {
    assetProp: function () {
      this.init();
    },
    assetID: function () {
      this.init();
    },
  },

  mounted: function () {
    this.init();
  },

  methods: {
    init() {
      if (this.assetID) {
        let id = this.assetID;
        this.$apollo
          .query({
            query: QUERY_asset,
            variables: { id: id },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let asset = data.data.asset;
            this.asset = JSON.parse(JSON.stringify(asset));
          });
      } else {
        this.asset = JSON.parse(JSON.stringify(this.assetProp));
      }
    },
  },
};
</script>