<template>
  <div>EntreePlanningObject</div>
</template>

<script>
export default {
  name: "EntreePlanningObject",
};
</script>

