<template>
  <div>
    <div v-if="!value">
      <v-row>
        <v-col>
          <EntreprisesListByRaisonSociale
            v-if="raisonSociale"
            :raisonSociale="raisonSociale"
            @getEntreprise="setEntreprise"
          />
          <v-text-field
            v-model="input.raisonSociale"
            label="Raison sociale"
            required
            :disabled="disabled"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col align="center" justify="center">
          <v-btn color="primary" @click="getEntreprisesByRaisonSociale()" :disabled="disabled">
            Rechercher
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-else-if="!disabled">
      <v-row class="mt-2">
        <v-col cols="12">
          <EntrepriseObject :entrepriseProp="value" size="xl" />
        </v-col>
        <v-col cols="12" align="center" justify="center">
          <v-btn color="warning" @click="reset">
            Annuler
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import EntreprisesListByRaisonSociale from "./EntreprisesListByRaisonSociale.vue";
import EntrepriseObject from "./EntrepriseObject.vue";
import { MUTATION_entrepriseAndRepresentantsCreate } from "./graphql/mutations";

export default {
  name: "EntrepriseRaisonSocialeFindOrCreateSelector",

  components: {
    EntreprisesListByRaisonSociale,
    EntrepriseObject
  },

  data: () => ({
    input: {},
    raisonSociale: null
  }),

  methods: {
    init() {
      this.input = {};
      this.raisonSociale = null;
    },

    getEntreprisesByRaisonSociale() {
      this.raisonSociale = this.input.raisonSociale;
    },

    reset() {
      this.$emit('input', null);
      this.init();
    },

    setEntreprise(res) {
      this.$apollo
        .mutate({
          mutation: MUTATION_entrepriseAndRepresentantsCreate,
          variables: {
            input: res
          }
        })
        .then(__ => {
          const data = __.data.entrepriseAndRepresentantsCreate;
          this.$emit("input", data);
          if(this.getRepresentantsInfos) this.$emit("representantsInfos", data.representants);
        })
    }
  },

  mounted() {
    this.init();
  },

  props: {
    value: null,
    getRepresentantsInfos: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
}
</script>