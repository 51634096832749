<template>
  <div>
    <v-row>
      <v-col>
        Montant du devis Hors Taxes :
        <MoneyLayout :montant="montantDevisHT" />
      </v-col>
      <v-col>
        Montant du devis TTC :
        <MoneyLayout :montant="montantDevisTTC" />
      </v-col>
    </v-row>

    <v-data-table :items="value" :headers="headerComputed">
      <template v-slot:[`item.unite`]="{ item }">
        <template v-if="item.modele"> Equipement(s) </template>
        <template v-else>
          {{ item.unite }}
        </template>
      </template>

      <template v-slot:[`item.label`]="{ item }">
        <template v-if="item.modele">
          <ModeleObject
            :modeleID="
              item.modele && item.modele.id ? item.modele.id : item.modele
            "
            size="xs"
          />
        </template>
        <template v-else>
          {{ item.label }}
        </template>
      </template>

      <template v-slot:[`item.prixUnitaire`]="{ item }">
        {{ item.prixUnitaire | monetaire }}
      </template>

      <template v-slot:[`item.prixTotal`]="{ item }">
        {{ (item.prixUnitaire * item.quantite) | monetaire }}
      </template>

      <template v-slot:[`item.tauxTva`]="{ item }">
        {{ item.tauxTva }} %
      </template>

      <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
        <slot :name="name" v-bind="data" />
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          color="info"
          fab
          x-small
          dark
          @click="moveItem('up', value.indexOf(item))"
          :disabled="value.indexOf(item) > 0 ? false : true"
        >
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
        <v-btn
          color="info"
          fab
          x-small
          dark
          @click="moveItem('down', value.indexOf(item))"
          :disabled="value.indexOf(item) == value.length - 1 ? true : false"
        >
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
        <v-btn
          color="info"
          fab
          x-small
          dark
          class="ml-4"
          @click="editItem(value.indexOf(item))"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          color="error"
          fab
          x-small
          dark
          class="ml-4"
          @click="deleteItem(value.indexOf(item))"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ModeleObject from "@/components/COMPONENTS-assets/ModeleObject.vue";
import MoneyLayout from "@/components/COMPONENTS-skeleton/Layouts/MoneyLayout.vue";

export default {
  name: "DevisClientLignesList",

  components: {
    ModeleObject,
    MoneyLayout,
  },

  props: {
    value: [],
    actions: { type: Boolean, default: true },
  },

  data: () => ({
    headers: [
      { text: "Quantité", value: "quantite" },
      { text: "Unité", value: "unite" },
      { text: "Label", value: "label" },
      { text: "Prix Unitaire H.T.", value: "prixUnitaire" },
      { text: "Prix Total H.T.", value: "prixTotal" },
      { text: "T.V.A", value: "tauxTva" },
    ],
  }),

  mounted: function () {},

  computed: {
    headerComputed() {
      let array = [...this.headers];
      if (this.actions) {
        array.push({ text: "Actions", value: "actions" });
      }
      return array;
    },
    montantDevisHT() {
      let montant = 0;
      if (this.value) {
        this.value.forEach((ligne) => {
          montant = montant + ligne.quantite * ligne.prixUnitaire;
        });
      }
      return montant;
    },
    montantDevisTTC() {
      let montant = 0;
      if (this.value) {
        this.value.forEach((ligne) => {
          montant =
            montant +
            ligne.quantite *
              (ligne.prixUnitaire * ((100 + ligne.tauxTva) / 100));
        });
      }
      return montant;
    },
  },

  methods: {
    deleteItem: function (item) {
      let tableau = [...this.value];
      tableau.splice(item, 1);
      this.$emit("input", tableau);
    },

    editItem: function (item) {
      let tableau = [...this.value];
      let formValue = tableau[item];

      this.$emit("formValue", formValue);
      tableau.splice(item, 1);
      this.$emit("input", tableau);
    },

    handleInput() {
      this.$emit("input", this.value);
    },

    moveItem: function (sens, itemIndex) {
      let tableau = [...this.value];
      let to = sens == "up" ? itemIndex - 1 : itemIndex + 1;
      tableau.splice(to, 0, tableau.splice(itemIndex, 1)[0]);

      this.$emit("input", tableau);
    },
  },
};
</script>

