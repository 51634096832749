var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ListLayout',{ref:"list",attrs:{"headers":_vm.headers},on:{"fetch":_vm.init},scopedSlots:_vm._u([{key:"item.browserName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.browserName)+" ")]}},{key:"item.OSName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.OSName)+" ")]}},{key:"item.isMobile",fn:function(ref){
var item = ref.item;
return [(item.isMobile)?_c('v-icon',[_vm._v("mdi-cellphone")]):_c('v-icon',[_vm._v("mdi-laptop")])]}},{key:"item.lastConnexion",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("shortDateAndHourFR")(item.lastConnexion))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('EquipementActions',{attrs:{"object":item,"fetch":_vm.fetchEquipements,"size":"xs"}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }