<template>
  <div>
    <template v-if="contratLocation && contratLocation.financement">
      <template v-if="size == 'xl'">
        <ObjectLayout size="xl" :item="contratLocation">
          <template v-slot:icon>mdi-file-sign</template>
          <template v-slot:titre>
            Contrat : {{ contratLocation.numeroContrat }}
          </template>
          <template v-slot:body>
            <TitleLayout titre="Informations administratives" />
            <v-row>
              <v-col>
                <ObjectLayout v-if="contratLocation.numeroContrat">
                  <template v-slot:icon>mdi-counter</template>
                  <template v-slot:titre>Numéro de contrat</template>
                  <template v-slot:content>
                    {{ contratLocation.numeroContrat }}
                  </template>
                </ObjectLayout>
              </v-col>
              <v-col>
                <ObjectLayout v-if="contratLocation.affaire">
                  <template v-slot:icon>mdi-briefcase-clock</template>
                  <template v-slot:titre>Affaire associée</template>
                  <template v-slot:content>
                    <AffaireObject :affaireID="contratLocation.affaire.id" v-if="contratLocation.affaire.id"
                      size="xs" />
                  </template>
                </ObjectLayout>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ObjectLayout v-if="contratLocation.locataire">
                  <template v-slot:icon>mdi-domain</template>
                  <template v-slot:titre>Locataire</template>
                  <template v-slot:content>
                    <ClientObject size="xs" :clientID="contratLocation.locataire.id" />
                  </template>
                </ObjectLayout>
              </v-col>
              <v-col>
                <ObjectLayout v-if="contratLocation.signataireClient">
                  <template v-slot:icon>mdi-fountain-pen-tip</template>
                  <template v-slot:titre>Signataire</template>
                  <template v-slot:content>
                    <PersonneObject size="xs" :personneID="contratLocation.signataireClient.id" />
                  </template>
                </ObjectLayout>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ObjectLayout v-if="contratLocation.datePrevInstallation">
                  <template v-slot:icon>mdi-calendar</template>
                  <template v-slot:titre>Date prévisionnelle d'installation</template>
                  <template v-slot:content>
                    {{ contratLocation.datePrevInstallation | shortDateFR }}
                  </template>
                </ObjectLayout> </v-col>
              <v-col>
                <ObjectLayout v-if="contratLocation.dateCreation">
                  <template v-slot:icon>mdi-calendar</template>
                  <template v-slot:titre>Date de création</template>
                  <template v-slot:content>
                    {{ contratLocation.dateCreation | shortDateFR }}
                  </template>
                </ObjectLayout>
              </v-col>
            </v-row>
            <TitleLayout titre="Devis et factures à financer" />
            <v-row>
              <v-col>
                <ObjectLayout>
                  <template v-slot:icon>mdi-account</template>
                  <template v-slot:titre>Etat du contrat</template>
                  <template v-slot:content> En cours... </template>
                </ObjectLayout>
              </v-col>
              <v-col> </v-col>
            </v-row>
            <v-row>
              <v-col> </v-col>
              <v-col>
                <ObjectLayout>
                  <template v-slot:icon>mdi-domain</template>
                  <template v-slot:titre>Sites d'utilisation</template>
                  <template v-slot:content>
                    <EtablissementObject v-for="etablissement in contratLocation.etablissementsUtilisation"
                      :key="etablissement.id" :etablissementProp="etablissement" size="xs" class="mb-1" />
                  </template>
                </ObjectLayout>
              </v-col>
            </v-row>
            <TitleLayout titre="Informations financières" />
            <v-row>
              <v-col>
                <ObjectLayout v-if="contratLocation.montantFinancement">
                  <template v-slot:icon>mdi-cash-plus</template>
                  <template v-slot:titre>Capital à financer</template>
                  <template v-slot:content>
                    <MoneyLayout :montant="contratLocation.montantFinancement" />
                  </template>
                </ObjectLayout>
              </v-col>
              <v-col>
                <ObjectLayout v-if="contratLocation.montantTVA">
                  <template v-slot:icon>mdi-cash-plus</template>
                  <template v-slot:titre>TVA à financer</template>
                  <template v-slot:content>
                    <MoneyLayout :montant="contratLocation.montantTVA" />
                  </template>
                </ObjectLayout>
              </v-col>
            </v-row>

            <div class="pl-2 pt-4">
              <v-icon class="pr-2">mdi-scale</v-icon>Répartition du capital
            </div>
            <v-simple-table class="pb-4" dense>
              <thead>
                <tr>
                  <th>Client</th>
                  <th>Part capital</th>
                  <th>Part TVA</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(repartition, index) in contratLocation.financement
                .repartition" :key="'repartition' + index">
                  <td>
                    <ClientObject :clientID="repartition.client.id" size="xs" />
                  </td>
                  <td>
                    <span class="font-weight-bold">
                      {{ (repartition.partCapital / 100) | pourcent }}
                    </span>
                    :
                    {{
                        (contratLocation.montantFinancement *
                          (repartition.partCapital / 100))
                        | monetaire
                    }}
                  </td>
                  <td>{{ repartition.montantTVA | monetaire }}</td>
                </tr>
              </tbody>
            </v-simple-table>

            <v-row>
              <v-col>
                <ObjectLayout>
                  <template v-slot:icon>mdi-calendar-clock</template>
                  <template v-slot:titre>
                    Nombre de périodes principales*
                  </template>
                  <template v-slot:content>
                    {{ contratLocation.financement.nper }}
                  </template>
                </ObjectLayout>
              </v-col>
              <v-col>
                <ObjectLayout v-if="contratLocation.financement.nperComp > 0">
                  <template v-slot:icon>mdi-calendar-clock</template>
                  <template v-slot:titre>
                    Nombre de périodes complémentaires
                  </template>
                  <template v-slot:content>
                    {{ contratLocation.financement.nperComp }}
                  </template>
                </ObjectLayout>
              </v-col>
            </v-row>

            <v-row>
              <v-col xl="6" lg="6" md="6" sm="6" xs="12">
                <ObjectLayout>
                  <template v-slot:icon>mdi-timeline-help</template>
                  <template v-slot:titre>Mode de paiement</template>
                  <template v-slot:content>
                    {{
                        contratLocation.financement.isBeginning
                          ? "Paiement en début de période"
                          : "Paiement en fin de période"
                    }}
                  </template>
                </ObjectLayout>
              </v-col>
              <v-col xl="6" lg="6" md="6" sm="6" xs="12">
                <ObjectLayout>
                  <template v-slot:icon>mdi-timeline-help</template>
                  <template v-slot:titre>Périodicité</template>
                  <template v-slot:content>
                    {{
                        contratLocation.financement.cf == 12 || contratLocation.financement.cf == "mensuelOpti"
                          ? "Loyers mensuels"
                          : contratLocation.financement.cf == 4 ||
                            contratLocation.financement.cf == "trimestrielOpti"
                            ? "Loyers trimestriels"
                            : contratLocation.financement.cf == 2 ||
                              contratLocation.financement.cf == "semestrielOpti"
                              ? "Loyers semestriels"
                              : "Loyers annuels"
                    }}
                  </template>
                </ObjectLayout>
              </v-col>
            </v-row>

            <v-expansion-panels class="py-6">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <v-row>
                    <v-col>
                      <v-icon class="mr-2">mdi-table-large</v-icon> Tableau de
                      loyers
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <LoyersView :edit="false" :value="contratLocation.financement.loyers" />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <template v-if="contratLocation.facturation">
              <TitleLayout titre="Informations de facturation" />
              <v-row>
                <v-col>
                  <ObjectLayout v-if="contratLocation.facturation.type">
                    <template v-slot:titre>Type de facturation</template>
                    <template v-slot:content>
                      {{ contratLocation.facturation.type }}
                    </template>
                  </ObjectLayout>
                </v-col>

              </v-row>
            </template>
            <TitleLayout titre="Informations de cession" />
            <v-row>
              <v-col>
                <ObjectLayout v-if="contratLocation.bailleur">
                  <template v-slot:icon>mdi-piggy-bank</template>
                  <template v-slot:titre>Bailleur</template>
                  <template v-slot:content>
                    <BailleurObject size="xs" :bailleurID="contratLocation.bailleur.id" />
                  </template>
                </ObjectLayout>
              </v-col>
              <v-col>
                <ObjectLayout v-if="contratLocation.demandeAccord">
                  <template v-slot:icon>mdi-cash-check</template>
                  <template v-slot:titre>Demande d'accord</template>
                  <template v-slot:content>
                    <DemandeAccordObject size="xs" :demandeAccordID="contratLocation.demandeAccord.id" />
                  </template>
                </ObjectLayout>
              </v-col>
            </v-row>
          </template>
          <template v-slot:info>
            Créé par
            <UserObject :userID="contratLocation.createdBy.id" size="text" />
            le
            {{ contratLocation.dateCreation | shortDateAndHourFR }}
          </template>
        </ObjectLayout>
      </template>
      <template v-if="size == 'xs'">
        <ObjectLayout size="xs" :onClickPush="`/satelease/contratsLocations/view/${contratLocation.id}`" :action="action">
          <template v-slot:icon>mdi-file-sign</template>
          <template v-slot:content>
            {{ contratLocation.numeroContrat }}
          </template>
        </ObjectLayout>
      </template>
      <template v-if="size == 'md'">ContratLocationObject MD </template>
    </template>
  </div>
</template>

<script>
import { QUERY_contratLocation } from "./graphql/queries";

import AffaireObject from "./AffaireObject.vue";
import BailleurObject from "./BailleurObject.vue";
import ClientObject from "@/components/COMPONENTS-devis-facture/ClientObject.vue";
import DemandeAccordObject from "./DemandeAccordObject.vue";
import EtablissementObject from "@/components/COMPONENTS-annuaire/EtablissementObject.vue";
import LoyersView from "./LoyersView.vue";
import MoneyLayout from "@/components/COMPONENTS-skeleton/Layouts/MoneyLayout.vue";
import ObjectLayout from "@/components/COMPONENTS-skeleton/Layouts/ObjectLayout.vue";
import PersonneObject from "@/components/COMPONENTS-annuaire/PersonneObject.vue";
import TitleLayout from "@/components/COMPONENTS-skeleton/Layouts/TitleLayout.vue";
import UserObject from "@/components/COMPONENTS-authentification-habilitation/UserObject.vue";

export default {
  name: "ContratLocationObject",

  components: {
    AffaireObject,
    BailleurObject,
    ClientObject,
    DemandeAccordObject,
    EtablissementObject,
    LoyersView,
    MoneyLayout,
    ObjectLayout,
    PersonneObject,
    TitleLayout,
    UserObject,
  },

  props: {
    action: { type: String, default: "router" },
    contratLocationID: {
      type: String,
    },
    contratLocationProp: {
      type: Object,
      default() {
        return {};
      },
    },
    size: { type: String, default: "md" },
  },

  data: () => ({
    contratLocation: undefined,
  }),

  mounted: function () {
    this.init();
  },

  watch: {
    contratLocationID: function () {
      this.init();
    },
    contratLocationProp: function () {
      this.init();
    },
  },

  methods: {
    init() {
      if (this.contratLocationID) {
        let id = this.contratLocationID;
        this.$apollo
          .query({
            query: QUERY_contratLocation,
            variables: { id: id },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let contratLocation = data.data.contratLocation;
            this.contratLocation = JSON.parse(JSON.stringify(contratLocation));
          });
      } else {
        this.contratLocation = JSON.parse(
          JSON.stringify(this.contratLocationProp)
        );
      }
    },
  },
};
</script>