<template>
  <div>
    <ActionLayout :actions="actions" :size="size" :action="action" />
  </div>
</template>


<script>
import ActionLayout from "@/components/COMPONENTS-skeleton/Layouts/ActionLayout.vue";
export default {
  name:"EmailActions",

  components:{
    ActionLayout,
  },

   props: {
    object: { type: Object },
    size: { type: String, default: "md" },
    action: { type: String, default: "router" },
    fetch: {
      type: Function,
      default() {
        console.log("defaultFetch");
      },
    },
  },

  computed: {
    actions() {
      let toReturn = [
        {
          text: "Visualiser",
          icon: "mdi-eye",
          path: "/envoi-notifications/emails/view/" + this.object.id,
          acl: "/envoi-notifications/emails/view",
          color: "",
          conditions: { multiple: false },
        },
      ];
      
      return toReturn;
    },
  },
}
</script>

<style>

</style>