<template>
  <FactureFournisseurForm
    ref="form"
    :client="client"
    :componentParent="componentParent"
    :fournisseur="fournisseur"
  >
    <template v-slot:[`item.meta.financement`]="{ item }">
      <template v-if="item.meta">
        <v-icon v-if="item.meta.financement" color="success">
          mdi-check-circle
        </v-icon>
        <v-icon v-if="!item.meta.financement" color="error">
          mdi-close-circle
        </v-icon>
      </template>
    </template>

    <template v-slot:[`item.meta.apparaitDansContrat`]="{ item }">
      <template v-if="item.meta">
        <v-icon v-if="item.meta.apparaitDansContrat" color="success">
          mdi-check-circle
        </v-icon>
        <v-icon v-if="!item.meta.apparaitDansContrat" color="error">
          mdi-close-circle
        </v-icon>
      </template>
    </template>

    <template v-slot:customLigneForm="{ ligneToAdd }">
      <v-row class="align-center justify-center" v-if="ligneToAdd.meta">
        <v-col cols="12" xl="5" lg="5" md="5" sm="5">
          <v-checkbox
            label="A financer"
            v-model="ligneToAdd.meta.financement"
          />
        </v-col>
        <v-col cols="12" xl="5" lg="5" md="5" sm="5">
          <v-checkbox
            label="A faire apparaitre dans le contrat"
            v-model="ligneToAdd.meta.apparaitDansContrat"
          />
        </v-col>
      </v-row>
    </template>
  </FactureFournisseurForm>
</template>

<script>
import FactureFournisseurForm from "@/components/COMPONENTS-devis-facture/FactureFournisseurForm.vue";

export default {
  name: "CustomFactureFournisseurForm",

  components: { FactureFournisseurForm },

  props: {
    client: undefined,
    componentParent: undefined,
    fournisseur: undefined,
  },

  mounted: function () {
    this.$refs.form.$refs.lignesForm.ligneToAdd.meta = {
      financement: true,
      apparaitDansContrat: true,
    };
    this.$refs.form.$refs.lignesList.headers.push(
      { text: "Financé", value: "meta.financement" },
      { text: "Apparait dans contrat", value: "meta.apparaitDansContrat" }
    );
  },
};
</script>