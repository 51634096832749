<template>
  <ListLayout
    ref="list"
    :headers="headers"
    title="Liste des requêtes d'organisation"
    @fetch="init"
  >
  <template v-slot:[`item.object`]="{ item }">
    <RequetesApiOrganisationObject 
      :requetesApiOrganisationProp="item"
      size="xs"
    />
  </template>
  <template v-slot:[`item.user`]="{ item }">
    <UserObject 
      :userProp="item.user"
      size="xs"
    />
  </template>
  <template 
    v-if="acl('/admin-provider/requetes-api/all')"
    v-slot:[`item.organisation`]="{ item }"
  >
    <OrganisationObject
     :organisationProp="item.organisation"
      size="xs"
    />
  </template>
  <template v-slot:[`item.requeteName`]="{ item }">
      {{item.requeteName}}
    </template>
  <template v-slot:[`item.apiName`]="{ item }">
      {{item.apiName}}
  </template>
  <template v-slot:[`item.requeteDate`]="{ item }">
      {{item.requeteDate | shortDateAndHourFR}}
  </template>
  </ListLayout>
</template>

<script>
import ListLayout from "@/components/COMPONENTS-skeleton/Layouts/ListLayout.vue";
import UserObject from "@/components/COMPONENTS-authentification-habilitation/UserObject.vue";
import OrganisationObject from "@/components/COMPONENTS-authentification-habilitation/OrganisationObject.vue";
import RequetesApiOrganisationObject from "./RequetesApiOrganisationObject.vue";
import { QUERY_requetesApiOrganisation } from "./graphql/queries";

export default {
  name: "RequetesApiOrganisationList",

  components: {
    ListLayout,
    OrganisationObject,
    RequetesApiOrganisationObject,
    UserObject,
  },

  computed: {
    headers() {
      let res = [
        { text: "", value: "object", sortable: false },
        { text: "Utilisateur", value: "user", sortable: false },
        {text: "Nom de la requête", value : "requeteName"},
        {text: "Nom de l'API", value : "apiName"},
        {text: "Date de la requête", value: "requeteDate"}
      ]

      if(this.acl('/admin-provider/requetes-api/all')) {
        res.splice(2, 0, { text: "Organisation", value: "organisation", sortable: false });
      }

      return res;
    }
  },

  methods: {
    fetchRequetesApiOrganisation() {
      this.$refs.list.loading = true;
      this.$apollo
        .query({
          query: QUERY_requetesApiOrganisation,
          variables: {
            pagination: this.$refs.list.pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          this.$refs.list.items = data.requetesApiOrganisation;
          this.$refs.list.itemsCount = data.requetesApiOrganisationCount;
          this.$refs.list.loading = false;
        })      
    },

    init() {
      this.fetchRequetesApiOrganisation();
    }
  }
}
</script>