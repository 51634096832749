var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ListLayout',{ref:"list",attrs:{"headers":_vm.headers,"title":"Liste des users"},on:{"fetch":_vm.init,"showMultipleActions":function($event){_vm.showMultipleActions = $event}},scopedSlots:_vm._u([{key:"multipleActions",fn:function(){return [_c('UserActions',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMultipleActions),expression:"showMultipleActions"}],attrs:{"multiple":true,"object":_vm.$refs.list ? _vm.$refs.list.itemsSelected : [],"size":"xl"},on:{"fetch":_vm.init,"loading":function($event){_vm.$refs.list.loading = true}}})]},proxy:true},{key:"item.object",fn:function(ref){
var item = ref.item;
return [_c('UserObject',{attrs:{"userProp":item,"size":"xs"}})]}},{key:"item.habilitationCount",fn:function(ref){
var item = ref.item;
return [_c('HabilitationCounter',{attrs:{"customSearch":[{ utilisateur: item.id }]}})]}},{key:"item.verify",fn:function(ref){
var item = ref.item;
return [(item.verify)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check-circle ")]):_vm._e(),(!item.verify)?_c('div',[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-close-circle ")])],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('UserActions',{attrs:{"object":item,"size":"xs"},on:{"fetch":_vm.init,"loading":function($event){_vm.$refs.list.loading = true}}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }