<template>
  <FormLayout
    icon="mdi-file"
    @ajouter="ajouter"
    @reinitialiser="init"
    :hasResetButton="hasResetButton"
    typeItem="Fichier"
    :item="input"
    :disabled="photo && !fichier ? true : false"
  >
    <template v-if="photo"> <FichierFormCamera v-model="fichier" /> </template>
    <v-row>
      <v-file-input v-model="fichier" label="Test" />
    </v-row>
    Fichier :{{ fichier }}
  </FormLayout>
</template>

<script>
import { mapState } from "vuex";

import {
  MUTATION_fichierCreate,
  MUTATION_uploadURLCreate,
} from "./graphql/mutations";

import FichierFormCamera from "./FichierFormCamera.vue";
import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";

export default {
  name: "PointFacturationForm",

  components: {
    FichierFormCamera,
    FormLayout,
  },

  props: {
    componentParent: undefined,
    disposition: { type: String, default: "default" },
    hasResetButton: { type: Boolean, default: true },
    ocr: { type: Boolean, default: false },
    photo: { type: Boolean, default: false },
  },

  data: () => ({
    fichier: null,
    input: {},
  }),

  computed: {
    ...mapState(["formsErrors"]),
  },

  mounted: function () {
    this.init();
  },

  methods: {
    ajouter() {
      let elementsName = this.fichier.name.split(".");
      let extension = elementsName.pop();
      let fileName = elementsName.join();
      // On créé un URL d'envoi
      this.$apollo
        .mutate({
          mutation: MUTATION_uploadURLCreate,
          variables: {
            fileName: this.fichier.name,
          },
        })
        .then((data) => {
          //On upload le fichier sur l'url du Bucket
          let uploadUrl = data.data.uploadURLCreate;
          fetch(uploadUrl.url, {
            method: "PUT",
            body: this.fichier,
          }).then(() => {
            this.$apollo
              .mutate({
                mutation: MUTATION_fichierCreate,
                variables: {
                  input: {
                    displayName: fileName,
                    extension: extension,
                    fileName: uploadUrl.fileName,
                    getOcr: this.ocr,
                    size: 10,
                    type: this.fichier.type,
                    note: "",
                    personalData: false,
                    meta: [this.meta],
                  },
                },
              })
              .then((__) => {
                this.$store.commit("addAlert", {
                  type: "info",
                  text: "Votre Fichier a été ajouté",
                });

                if (this.componentParent) {
                  this.$root.$emit(
                    this.componentParent[0],
                    __.data.fichierCreate
                  );
                }
                this.$root.$emit("skeleton--close");
                this.init();
              });
          });
        });
    },

    init() {},

    inputConverter(arg) {
      const obj = JSON.parse(JSON.stringify(arg));

      let input = {};

      obj.id != undefined ? (input.id = obj.id) : null;

      return input;
    },
  },
};
</script>