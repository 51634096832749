<template>
  <div>
    <template v-if="hasBadgeSlot">
      <v-badge color="primary" :content="compteur ? compteur : '0'">
        <slot name="badge"></slot>
      </v-badge>
    </template>
    <template v-if="!hasBadgeSlot && size == 'md'">
      <v-chip :color="!color ? (compteur > 0 ? 'success' : '') : color">
        <v-avatar left>
          <v-icon small><slot name="icon">mdi-counter</slot></v-icon>
        </v-avatar>
        <strong>{{ compteur }}</strong>
      </v-chip>
    </template>
    <template v-if="size == 'xl'">
      <v-card class="mx-auto" max-width="400">
        <v-card-title class="justify-center text-center">
          <v-avatar
            color="primary"
            size="140"
            style="position: absolute; top: -25px; left: 50px"
          >
            <div v-if="hasIconSlot">
              <v-icon x-large color="white" style="font-size: 70px"
                ><slot name="icon"></slot
              ></v-icon>
            </div>
          </v-avatar>
          <v-list-item two-line class="py-3">
            <v-list-item-content>
              <v-list-item-subtitle v-if="subTitle" class="text-right font-weight-light text-h6"
                > <template>{{ subTitle }}</template></v-list-item-subtitle
              >
              <v-list-item-title class="text-right text-h4 font-weight-normal">
                {{ compteur }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card-title>
        <v-divider class="mx-4"></v-divider>
        <v-card-actions>
          <v-row class="pl-5 my-1">
            <v-icon small color="grey" style="font-size: 20px"
              >mdi-update</v-icon
            >
            <v-card-subtittle class="grey--text">
              Juste mis à jour
            </v-card-subtittle>
          </v-row>
        </v-card-actions>
      </v-card>
    </template>
  </div>
</template>

<script>
export default {
  name: "CounterLayout",
  props: {
    size: { type: String, default: "md" },
    color: { type: String, default: undefined },
    onClickPush: { type: String, default: undefined },
    subTitle: { type: String, default: null },
    //router = router.push normal
    //popup = router push dans popup
    //none = pas d'action
    action: { type: String, default: "router" },
    compteur: { type: Number, default: 0 },
  },
  computed: {
    hasBadgeSlot() {
      return !!this.$slots.badge;
    },
    hasIconSlot() {
      return !!this.$slots.icon;
    },
    hasTitreSlot() {
      return !!this.$slots.titre;
    },
  },
  methods: {
    //Lors du clic sur un objet compatible (xs)
    clickAction() {
      // Par défaut on utilise router.push, on actualise la vue chargée dans router-view
      if (this.action == "router") {
        this.$router.push({ path: this.onClickPush });
      } else if (this.action == "popup") {
        // si on choisit d'utiliser une popup, on ajoute une option à la fin de l'URL
        // router/index.js se charge de la suite
        this.$router.push({ path: this.onClickPush + "?popup=true" });
      }
    },
  },
};
</script>

<style>
</style>