var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ListLayout',{ref:"list",attrs:{"headers":_vm.headers},on:{"fetch":_vm.init,"showMultipleActions":function($event){_vm.showMultipleActions = $event}},scopedSlots:_vm._u([{key:"multipleActions",fn:function(){return [_c('ClientActions',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMultipleActions),expression:"showMultipleActions"}],attrs:{"multiple":true,"object":_vm.$refs.list ? _vm.$refs.list.itemsSelected : [],"size":"xl"},on:{"fetch":_vm.init,"loading":function($event){_vm.$refs.list.loading = true}}})]},proxy:true},{key:"customFilters",fn:function(){return [_c('EntrepriseSelector',{attrs:{"addAction":null},model:{value:(_vm.customFilters.entreprise),callback:function ($$v) {_vm.$set(_vm.customFilters, "entreprise", $$v)},expression:"customFilters.entreprise"}}),_c('AdressePostaleVilleSelector',{model:{value:(_vm.etablissementsCustomFilters.libelleCommune),callback:function ($$v) {_vm.$set(_vm.etablissementsCustomFilters, "libelleCommune", $$v)},expression:"etablissementsCustomFilters.libelleCommune"}}),_c('EnseigneSelector',{model:{value:(_vm.etablissementsCustomFilters.enseigne),callback:function ($$v) {_vm.$set(_vm.etablissementsCustomFilters, "enseigne", $$v)},expression:"etablissementsCustomFilters.enseigne"}})]},proxy:true},{key:"item.etablissement",fn:function(ref){
var item = ref.item;
return [_c('ClientObject',{attrs:{"clientProp":item,"size":"xs"}})]}},{key:"item.devisClientCount",fn:function(ref){
var item = ref.item;
return [_c('DevisClientCounter',{attrs:{"customSearch":[{ client: item.id }]}})]}},{key:"item.devisFournisseurCount",fn:function(ref){
var item = ref.item;
return [_c('DevisFournisseurCounter',{attrs:{"customSearch":[{ client: item.id }]}})]}},{key:"item.factureClientsCount",fn:function(ref){
var item = ref.item;
return [_c('FactureClientsCounter',{attrs:{"customSearch":[{ client: item.id }]}})]}},{key:"item.factureFournisseursCount",fn:function(ref){
var item = ref.item;
return [_c('FactureFournisseursCounter',{attrs:{"customSearch":[{ client: item.id }]}})]}},{key:"item.servicesCount",fn:function(ref){
var item = ref.item;
return [_c('ServicesCounter',{attrs:{"customSearch":[{ client: item.id }]}})]}},{key:"item.pointFacturations",fn:function(ref){
var item = ref.item;
return [_c('PointFacturationsCounter',{attrs:{"customSearch":[{ client: item.id }]}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('ClientActions',{attrs:{"object":item,"size":"xs"},on:{"fetch":_vm.init,"loading":function($event){_vm.$refs.list.loading = true}}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }