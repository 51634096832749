<template>
  <v-card elevation="2" class="justify-center">
    <TitleLayout
      titre="Géolocalisation"
      :sousTitre="
        !location
          ? 'Pour accéder à votre géolocalisation, cliquez sur le boutton'
          : 'Pour réinitialiser les cordonnées, cliquez sur le bouton ci-dessous'
      "
    />
    <v-row class="text-center">
      <v-col cols="12">
        <template v-if="location">
          <v-col cols="10" class="mx-auto">
            <GmapMap
              :center="{
                lat: parseFloat(location.coords.latitude),
                lng: parseFloat(location.coords.longitude),
              }"
              :zoom="10"
              map-type-id="terrain"
              style="width: 100%; height: 320px"
              :options="{
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: true,
                disableDefaultUi: false,
              }"
            >
              <GmapMarker
                :position="{
                  lat: parseFloat(location.coords.latitude),
                  lng: parseFloat(location.coords.longitude),
                }"
                :draggable="true"
                @drag="updateCoordinates"
                :clickable="true"
                @click="
                  center = {
                    lat: parseFloat(latitude),
                    lng: parseFloat(longitude),
                  }
                "
              />
            </GmapMap>
          </v-col>
        </template>
        <template v-else>
          <div class="py-3"></div>
        </template>
      </v-col>
      <v-col cols="12" class="py-4">
        <v-btn
          :class="{ primary: !location, warning: location }"
          @click="!location ? locateMe() : reloadLocation()"
        >
          {{ !location ? "Récupérer la position" : " Réinitialiser" }}</v-btn
        >
      </v-col>
      <v-col cols="12" v-if="errorStr">
        Désolé, mais l'erreur suivante s'est produit: {{ errorStr }}
      </v-col>
      <v-col cols="12" v-if="gettingLocation">
        <i> Obtenir votre position...</i>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import TitleLayout from "@/components/COMPONENTS-skeleton/Layouts/TitleLayout.vue";

export default {
  name: "LocalisationNavigateur",

  components: {
    TitleLayout,
  },
  data: () => ({
    location: null,
    gettingLocation: false,
    errorStr: null,
    coordinates: null,
  }),

  methods: {
    updateCoordinates(location) {
      this.coordinates = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
    },
    async getLocation() {
      return new Promise((resolve, reject) => {
        if (!("geolocation" in navigator)) {
          reject(new Error("Géolocalisation impossible"));
        }

        navigator.geolocation.getCurrentPosition(
          (pos) => {
            resolve(pos);
          },
          (err) => {
            reject(err);
          }
        );
      });
    },
    async locateMe() {
      this.gettingLocation = true;
      try {
        this.gettingLocation = false;
        this.location = await this.getLocation();
      } catch (e) {
        this.gettingLocation = false;
        this.errorStr = e.message;
      }
    },

    async reloadLocation() {
      this.location = null;
    },
  },
};
</script>

