<template>
  <v-container>
   <template v-if='urlSource != ""'>
        <video controls>
          <source :src="urlSource" type="video/mp4">
        </video>
      </template>
  </v-container>
</template>

<script>
export default {
  name: "FichierVideoPlayer",

  props: {
    urlSource: { type: String, default: "" }
  }
}
</script>