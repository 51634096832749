export default [
  //Accords
  {
    path: '/accord-validation/accords',
    name: 'Gestion des accords',
    component: () => import('./AccordsList.vue'),
    meta: {
      acl: "/accord-validation/accords"
    }
  },
  {
    path: '/accord-validation/accords/list',
    name: 'Liste des accords',
    component: () => import('./AccordsList.vue'),
    meta: {
      acl: "/accord-validation/accords/list"
    }
  },
  {
    path: '/accord-validation/accords/add',
    name: 'Ajouter un accord',
    component: () => import('./AccordForm.vue'),
    meta: {
      acl: "/accord-validation/accords/add"
    }
  },
  {
    path: '/accord-validation/accords/edit/:id',
    name: 'Editer un accord',
    component: () => import('./AccordForm.vue'),
    meta: {
      acl: "/accord-validation/accords/edit"
    }
  },
  {
    path: '/accord-validation/accords/view/:id',
    name: 'Visualiser un accord',
    component: () => import('./AccordView.vue'),
    meta: {
      acl: "/accord-validation/accords/view"
    }
  },
  {
    path: '/accord-validation/accords/deleted',
    name: 'Liste des accords supprimés',
    component: () => import('./AccordsList.vue'),
    meta: {
      acl: "/accord-validation/accords/deleted"
    }
  },

  // Param
  {
    path: '/accord-validation/parametres',
    name: 'Paramètes accord validation',
    component: () => import('./AccordValidationOrganisationParametresForm.vue'),
    meta: {
      acl: "/accord-validation/parametres"
    }
  }
]