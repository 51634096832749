<template>
  <ListLayout
    ref="list"
    :headers="headers"
    @fetch="init"
    @showMultipleActions="showMultipleActions = $event"
    title="Liste des habilitations"
  >
    <template v-slot:multipleActions>
      <HabilitationActions
        :multiple="true"
        :object="$refs.list ? $refs.list.itemsSelected : []"
        size="xl"
        v-show="showMultipleActions"
        @fetch="init"
        @loading="$refs.list.loading = true"
      />
    </template>
    <template v-slot:[`item.object`]="{ item }">
      <HabilitationObject :habilitationProp="item" size="xs" />
    </template>
    <template v-slot:[`item.organisation`]="{ item }">
      <OrganisationObject :organisationProp="item.organisation" size="xs" />
    </template>
    <template v-slot:[`item.utilisateur`]="{ item }">
      <UserObject :userProp="item.utilisateur" size="xs" />
    </template>
    <template v-slot:[`item.dateConnexion`]="{ item }">
      {{ item.dateConnexion | shortDateAndHourFR }}
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <HabilitationActions
        :object="item"
        size="xs"
        @fetch="init"
        @loading="$refs.list.loading = true"
      />
    </template>
  </ListLayout>
</template>

<script>
import HabilitationActions from "./HabilitationActions.vue";
import HabilitationObject from "./HabilitationObject.vue";
import ListLayout from "@/components/COMPONENTS-skeleton/Layouts/ListLayout.vue";
import OrganisationObject from "./OrganisationObject.vue";
import UserObject from "./UserObject.vue";
import { QUERY_habilitations } from "./graphql/queries";

export default {
  name: "HabilitationsList",

  components: {
    HabilitationActions,
    HabilitationObject,
    ListLayout,
    OrganisationObject,
    UserObject,
  },

  props: {
    organisationID: null
  },

  data: () => ({
    search: null,
    showMultipleActions: false,
    headers: [
      { text: "", value: "object", sortable: false },
      { text: "Rôle", value: "role", sortable: true },
      { text: "Organisation", value: "organisation", sortable: false },
      { text: "Utilisateur", value: "utilisateur", sortable: false },
      { text: "Dernière connexion", value: "dateConnexion", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),

  methods: {
    fetchHabilitations() {
      this.$refs.list.loading = true;
      let pagination = JSON.parse(JSON.stringify(this.$refs.list.pagination));

      if (this.organisationID) {
        pagination.filter.customSearch.push({ organisation: this.organisationID });
      }
      
      this.$apollo
        .query({
          query: QUERY_habilitations,
          variables: {
            pagination: pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          this.$refs.list.items = data.habilitations;
          this.$refs.list.itemsCount = data.habilitationsCount;
          this.$refs.list.loading = false;
        });
    },
    init() {
      this.fetchHabilitations();
    },
  },

  watch: {
    organisationID() {
      this.init();
    }
  }
};
</script>