<template>
  <div>
    <v-row>
      <v-col v-show="isCameraOpen && isLoading" align="center" justify="center">
        <v-progress-circular
          :size="200"
          :width="20"
          color="primary"
          indeterminate
        />
        <ul class="loader-circle"></ul>
      </v-col>
      <v-col
        v-if="isCameraOpen"
        v-show="!isLoading"
        align="center"
        justify="center"
      >
        <video
          v-show="!isPhotoTaken"
          ref="camera"
          :width="900"
          :height="675"
          autoplay
          style="max-width: 900px; width: 100%; height: 100%"
        />
        <canvas
          v-show="isPhotoTaken"
          id="photoTaken"
          ref="canvas"
          :width="900"
          :height="675"
          style="max-width: 900px; max-height: 675px; width: 100%; height: 100%"
        />
      </v-col>

      <v-col
        cols="12"
        v-if="isCameraOpen && !isLoading"
        class="py-10 text-center"
      >
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="takePhoto"
            >
              <v-icon dark>
                {{ isPhotoTaken ? "mdi-reload" : "mdi-camera" }}
              </v-icon>
            </v-btn>
          </template>
          <span>Prendre une photo</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "FichierFormCamera",

  components: {},

  props: {
    value: null,
  },

  data: () => ({
    isCameraOpen: false,
    isPhotoTaken: false,
    isShotPhoto: false,
    isLoading: false,
    link: "#",
  }),
  mounted() {
    this.toggleCamera();
  },
  beforeDestroy() {
    this.stopCameraStream();
  },
  methods: {
    toggleCamera() {
      if (this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.stopCameraStream();
      } else {
        this.isCameraOpen = true;
        this.createCameraElement();
      }
    },

    createCameraElement() {
      this.isLoading = true;

      const constraints = (window.constraints = {
        audio: false,
        video: true,
        facingMode: "environment",
      });

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
           
          this.isLoading = false;
          this.$refs.camera.srcObject = stream;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$store.commit("addAlert", {
            type: "warning",
            text:
              "Peut-être que le navigateur n'a pas pris en charge votre caméra ou l'accès à celle-ci a été bloqué ." +
              error,
          });
          this.isCameraOpen = false;
        });
    },

    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();

      tracks.forEach((track) => {
        track.stop();
      });
    },

    takePhoto() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }

      this.isPhotoTaken = !this.isPhotoTaken;

      const context = this.$refs.canvas.getContext("2d");
      context.drawImage(this.$refs.camera, 0, 0, 900, 675);
    },
  },

  watch: {
    isPhotoTaken: function (val) {
      if (val) {
        // const canvas = document
        //   .getElementById("photoTaken")
        //   .toDataURL("image/jpeg")
        //   .replace("image/jpeg", "image/octet-stream");
        const canvas = document.getElementById("photoTaken");
        canvas.toBlob((blob) => {
          let file = new File([blob], "photoAPN.jpg", { type: "image/jpeg" });
          this.$emit("input", file);
        }, "image/jpeg");
      } else {
        this.$emit("input", null);
      }
    },
    value: function (val) {
      this.item = val && val.id ? val.id : val;
    },
  },
};
</script>
