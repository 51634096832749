export default [
  // Routes externes modifiées
  {
    path: '/assets/assets',
    name: 'Gestion des assets',
    component: () => import('@/components/COMPONENTS-projet-satelease/CustomAssetsList.vue'),
    meta: {
      acl: "/assets/assets",
      replace: true
    }
  },
  {
    path: '/assets/assets/list',
    name: 'Liste des assets',
    component: () => import('@/components/COMPONENTS-projet-satelease/CustomAssetsList.vue'),
    meta: {
      acl: "/assets/assets/list",
      replace: true
    }
  },
  {
    path: '/devis-facture/devis-fournisseurs',
    name: 'Gestion des devis fournisseurs',
    component: () => import('./CustomDevisFournisseursList.vue'),
    meta: {
      acl: "/devis-facture/devis-fournisseurs",
      replace: true
    }
  },
  {
    path: '/devis-facture/devis-fournisseurs/list',
    name: 'Liste des devis fournisseurs',
    component: () => import('./CustomDevisFournisseursList.vue'),
    meta: {
      acl: "/devis-facture/devis-fournisseurs/list",
      replace: true
    }
  },
  {
    path: '/devis-facture/devis-fournisseurs/add',
    name: 'Ajouter un devis fournisseur',
    component: () => import('./CustomDevisFournisseurForm.vue'),
    meta: {
      acl: "/devis-facture/devis-fournisseurs/add",
      replace: true
    }
  },
  {
    path: '/devis-facture/devis-fournisseurs/edit/:id',
    name: 'Editer un devis fournisseur',
    component: () => import('./CustomDevisFournisseurForm.vue'),
    meta: {
      acl: "/devis-facture/devis-fournisseurs/edit",
      replace: true
    }
  },
  {
    path: '/devis-facture/facture-fournisseurs',
    name: 'Gestion des factures fournisseurs',
    component: () => import('./CustomFactureFournisseursList.vue'),
    meta: {
      acl: "/devis-facture/facture-fournisseurs",
      replace: true
    }
  },
  {
    path: '/devis-facture/facture-fournisseurs/list',
    name: 'Liste des factures fournisseurs',
    component: () => import('./CustomFactureFournisseursList.vue'),
    meta: {
      acl: "/devis-facture/facture-fournisseurs/list",
      replace: true
    }
  },
  {
    path: '/devis-facture/facture-fournisseurs/add',
    name: 'Ajouter une facture fournisseur',
    component: () => import('./CustomFactureFournisseurForm.vue'),
    meta: {
      acl: "/devis-facture/facture-fournisseurs/add",
      replace: true
    }
  },
  {
    path: '/devis-facture/facture-fournisseurs/edit/:id',
    name: 'Editer une facture fournisseur',
    component: () => import('./CustomFactureFournisseurForm.vue'),
    meta: {
      acl: "/devis-facture/facture-fournisseurs/edit",
      replace: true
    }
  },
  //Affaires
  {
    path: '/satelease/affaires',
    name: 'Gestion des affaires',
    component: () => import('./AffairesList.vue'),
    meta: {
      acl: "/satelease/affaires"
    }
  },
  {
    path: '/satelease/affaires/list',
    name: 'Liste des affaires',
    component: () => import('./AffairesList.vue'),
    meta: {
      acl: "/satelease/affaires/list"
    }
  },
  {
    path: '/satelease/affaires/add',
    name: 'Ajouter une affaire',
    component: () => import('./AffaireForm.vue'),
    meta: {
      acl: "/satelease/affaires/add"
    }
  },
  {
    path: '/satelease/affaires/edit/:id',
    name: 'Editer une affaire',
    component: () => import('./AffaireForm.vue'),
    meta: {
      acl: "/satelease/affaires/edit"
    }
  },
  {
    path: '/satelease/affaires/view/:id',
    name: 'Visualiser une affaire',
    component: () => import('./AffaireView.vue'),
    meta: {
      acl: "/satelease/affaires/view"
    }
  },
  {
    path: '/satelease/affaires/deleted',
    name: 'Liste des affaires supprimées',
    component: () => import('./AffairesList.vue'),
    meta: {
      acl: "/satelease/affaires/deleted"
    }
  },
  //Bailleurs
  {
    path: '/satelease/refinancement/bailleurs',
    name: 'Gestion des bailleurs',
    component: () => import('./BailleursList.vue'),
    meta: {
      acl: "/satelease/refinancement/bailleurs"
    }
  },
  {
    path: '/satelease/refinancement/bailleurs/list',
    name: 'Liste des bailleurs',
    component: () => import('./BailleursList.vue'),
    meta: {
      acl: "/satelease/refinancement/bailleurs/list"
    }
  },
  {
    path: '/satelease/refinancement/bailleurs/add',
    name: 'Ajouter un bailleur',
    component: () => import('./BailleurForm.vue'),
    meta: {
      acl: "/satelease/refinancement/bailleurs/add"
    }
  },
  {
    path: '/satelease/refinancement/bailleurs/edit/:id',
    name: 'Editer un bailleur',
    component: () => import('./BailleurForm.vue'),
    meta: {
      acl: "/satelease/refinancement/bailleurs/edit"
    }
  },
  {
    path: '/satelease/refinancement/bailleurs/view/:id',
    name: 'Visualiser un bailleur',
    component: () => import('./BailleurView.vue'),
    meta: {
      acl: "/satelease/refinancement/bailleurs/view"
    }
  },
  {
    path: '/satelease/refinancement/bailleurs/deleted',
    name: 'Liste des bailleurs supprimés',
    component: () => import('./BailleursList.vue'),
    meta: {
      acl: "/satelease/refinancement/bailleurs/deleted"
    }
  },


  // Demande d'accords
  {
    path: '/satelease/refinancement/demandeAccords',
    name: 'Gestion des demandes d\'accord',
    component: () => import('./DemandeAccordsList.vue'),
    meta: {
      acl: "/satelease/refinancement/demandeAccords"
    }
  },
  {
    path: '/satelease/refinancement/demandeAccords/list',
    name: 'Liste des demandes d\'accord',
    component: () => import('./DemandeAccordsList.vue'),
    meta: {
      acl: "/satelease/refinancement/demandeAccords/list"
    }
  },
  {
    path: '/satelease/refinancement/demandeAccords/add',
    name: 'Ajouter une demande d\'accord',
    component: () => import('./DemandeAccordForm.vue'),
    meta: {
      acl: "/satelease/refinancement/demandeAccords/add"
    }
  },
  {
    path: '/satelease/refinancement/demandeAccords/edit/:id',
    name: 'Editer une demande d\'accord',
    component: () => import('./DemandeAccordForm.vue'),
    meta: {
      acl: "/satelease/refinancement/demandeAccords/edit"
    }
  },
  {
    path: '/satelease/refinancement/demandeAccords/view/:id',
    name: 'Visualiser une demande d\'accord',
    component: () => import('./DemandeAccordView.vue'),
    meta: {
      acl: "/satelease/refinancement/demandeAccord/view"
    }
  },
  {
    path: '/satelease/refinancement/demandeAccords/deleted',
    name: 'Liste des demandes d\'accord supprimés',
    component: () => import('./DemandeAccordsList.vue'),
    meta: {
      acl: "/satelease/refinancement/demandeAccords/deleted"
    }
  },
  // Cessions
  {
    path: '/satelease/refinancement/cessions',
    name: 'Gestion des cessions',
    component: () => import('./CessionsList.vue'),
    meta: {
      acl: "/satelease/refinancement/cessions"
    }
  },
  {
    path: '/satelease/refinancement/cessions/list',
    name: 'Liste des cessions',
    component: () => import('./CessionsList.vue'),
    meta: {
      acl: "/satelease/refinancement/cessions/list"
    }
  },
  {
    path: '/satelease/refinancement/cessions/add',
    name: 'Ajouter une cession',
    component: () => import('./CessionForm.vue'),
    meta: {
      acl: "/satelease/refinancement/cessions/add"
    }
  },
  {
    path: '/satelease/refinancement/cessions/edit/:id',
    name: 'Editer une cession',
    component: () => import('./CessionForm.vue'),
    meta: {
      acl: "/satelease/refinancement/cessions/edit"
    }
  },
  {
    path: '/satelease/refinancement/cessions/view/:id',
    name: 'Visualiser une cession',
    component: () => import('./CessionView.vue'),
    meta: {
      acl: "/satelease/refinancement/cessions/view"
    }
  },
  {
    path: '/satelease/refinancement/cessions/deleted',
    name: 'Liste des cessions supprimées',
    component: () => import('./CessionsList.vue'),
    meta: {
      acl: "/satelease/refinancement/cessions/deleted"
    }
  },
  // Enveloppes Théoriques
  {
    path: '/satelease/refinancement/enveloppeTheoriques',
    name: 'Gestion des enveloppes théoriques',
    component: () => import('./EnveloppeTheoriquesList.vue'),
    meta: {
      acl: "/satelease/refinancement/enveloppeTheoriques"
    }
  },
  {
    path: '/satelease/refinancement/enveloppeTheoriques/list',
    name: 'Liste des enveloppes théoriques',
    component: () => import('./EnveloppeTheoriquesList.vue'),
    meta: {
      acl: "/satelease/refinancement/enveloppeTheoriques/list"
    }
  },
  {
    path: '/satelease/refinancement/enveloppeTheoriques/add',
    name: 'Ajouter une enveloppe théorique',
    component: () => import('./EnveloppeTheoriqueForm.vue'),
    meta: {
      acl: "/satelease/refinancement/enveloppeTheoriques/add"
    }
  },
  {
    path: '/satelease/refinancement/enveloppeTheoriques/edit/:id',
    name: 'Editer une enveloppe théorique',
    component: () => import('./EnveloppeTheoriqueForm.vue'),
    meta: {
      acl: "/satelease/refinancement/enveloppeTheoriques/edit"
    }
  },
  {
    path: '/satelease/refinancement/enveloppeTheoriques/view/:id',
    name: 'Visualiser une enveloppe théorique',
    component: () => import('./EnveloppeTheoriqueView.vue'),
    meta: {
      acl: "/satelease/refinancement/enveloppeTheoriques/view"
    }
  },
  {
    path: '/satelease/refinancement/enveloppeTheoriques/deleted',
    name: 'Liste des enveloppes théoriques supprimées',
    component: () => import('./EnveloppeTheoriquesList.vue'),
    meta: {
      acl: "/satelease/refinancement/enveloppeTheoriques/deleted"
    }
  },
  // Partenaires
  {
    path: '/satelease/partenariat/partenaires',
    name: 'Gestion des partenaires',
    component: () => import('./PartenairesList.vue'),
    meta: {
      acl: "/satelease/partenariat/partenaires"
    }
  },
  {
    path: '/satelease/partenariat/partenaires/list',
    name: 'Liste des partenaires',
    component: () => import('./PartenairesList.vue'),
    meta: {
      acl: "/satelease/partenariat/partenaires/list"
    }
  },
  {
    path: '/satelease/partenariat/partenaires/add',
    name: 'Ajouter un partenaire',
    component: () => import('./PartenaireForm.vue'),
    meta: {
      acl: "/satelease/partenariat/partenaires/add"
    }
  },
  {
    path: '/satelease/partenariat/partenaires/edit/:id',
    name: 'Editer un partenaire',
    component: () => import('./PartenaireForm.vue'),
    meta: {
      acl: "/satelease/partenariat/partenaires/edit"
    }
  },
  {
    path: '/satelease/partenariat/partenaires/view/:id',
    name: 'Visualiser un partenaire',
    component: () => import('./PartenaireView.vue'),
    meta: {
      acl: "/satelease/partenariat/partenaires/view"
    }
  },
  {
    path: '/satelease/partenariat/partenaires/deleted',
    name: 'Liste des partenaires supprimés',
    component: () => import('./PartenairesList.vue'),
    meta: {
      acl: "/satelease/partenariat/partenaires/deleted"
    }
  },
  // Demandes Partenaires
  {
    path: '/satelease/partenariat/demandepartenaires',
    name: 'Gestion des demandes partenaire',
    component: () => import('./DemandePartenairesList.vue'),
    meta: {
      acl: "/satelease/partenariat/demandepartenaires"
    }
  },
  {
    path: '/satelease/partenariat/demandepartenaires/list',
    name: 'Liste des demandes partenaire',
    component: () => import('./DemandePartenairesList.vue'),
    meta: {
      acl: "/satelease/partenariat/demandepartenaires/list"
    }
  },
  {
    path: '/satelease/partenariat/demandepartenaires/add',
    name: 'Ajouter une demande partenaire',
    component: () => import('./DemandePartenaireForm.vue'),
    meta: {
      acl: "/satelease/partenariat/demandepartenaires/add"
    }
  },
  {
    path: '/satelease/partenariat/demandepartenaires/edit/:id',
    name: 'Editer une demande partenaire',
    component: () => import('./DemandePartenaireForm.vue'),
    meta: {
      acl: "/satelease/partenariat/demandepartenaires/edit"
    }
  },
  {
    path: '/satelease/partenariat/demandepartenaires/view/:id',
    name: 'Visualiser une demande partenaire',
    component: () => import('./DemandePartenaireView.vue'),
    meta: {
      acl: "/satelease/partenariat/demandepartenaires/view"
    }
  },
  {
    path: '/satelease/partenariat/demandepartenaires/deleted',
    name: 'Liste des demandes partenaires supprimées',
    component: () => import('./DemandePartenairesList.vue'),
    meta: {
      acl: "/satelease/partenariat/demandepartenaires/deleted"
    }
  },
  //Contrats
  {
    path: '/satelease/contratsLocations',
    name: 'Gestion des contrats de location',
    component: () => import('./ContratLocationsList.vue'),
    meta: {
      acl: "/satelease/contratsLocations"
    }
  },
  {
    path: '/satelease/contratsLocations/list',
    name: 'Liste des contrats de location',
    component: () => import('./ContratLocationsList.vue'),
    meta: {
      acl: "/satelease/contratsLocations/list"
    }
  },
  {
    path: '/satelease/contratsLocations/view/:id',
    name: 'Visualiser un contrat de location',
    component: () => import('./ContratLocationView.vue'),
    meta: {
      acl: "/satelease/contratsLocations/view"
    }
  },
  {
    path: '/satelease/contratsLocations/process/:id?',
    name: 'Processus de contractualisation',
    component: () => import('./ContratLocationProcessus.vue'),
    meta: {
      acl: "/satelease/contratsLocations/process"
    }
  },
  {
    path: '/satelease/contratsLocations/add',
    name: 'Ajouter un contrat de location',
    component: () => import('./ContratLocationForm.vue'),
    meta: {
      acl: "/satelease/contratsLocations/add"
    }
  },
  {
    path: '/satelease/contratsLocations/edit/:id?',
    name: 'Editer un contrat de location',
    component: () => import('./ContratLocationForm.vue'),
    meta: {
      acl: "/satelease/contratsLocations/edit"
    }
  },
  {
    path: '/satelease/contratsLocations/deleted',
    name: 'Liste des contrats de location supprimés',
    component: () => import('./ContratLocationsList.vue'),
    meta: {
      acl: "/satelease/contratsLocations/deleted"
    }
  },
  {
    path: '/satelease/contratsLocations/validate/:id',
    name: 'Valider un contrat',
    component: () => import('./ContratLocationValidateForm.vue'),
    meta: {
      acl: "/satelease/contratsLocations/validate"
    }
  },
  {
    path: '/satelease/parametres',
    name: 'Paramètres',
    component: () => import('./ProjetSateLeaseOrganisationParametresForm.vue'),
    meta: {
      acl: "/satelease/parametres"
    }
  }
]