import gql from 'graphql-tag';
import {
  ACCORD_ETAPE_FIELDS,
  ACCORD_FIELDS,
} from "./fragments.js";

export const MUTATION_accordCreate = gql`
  mutation($input:AccordInput!){
    accordCreate(input:$input){
      ...AccordFields
    }
  }${ACCORD_FIELDS}
`;

export const MUTATION_accordDelete = gql`
  mutation($id:ID!){
    accordDelete(id:$id){
      ...AccordFields
    }
  }${ACCORD_FIELDS}
`;

export const MUTATION_accordUpdate = gql`
  mutation($input:AccordInput!){
    accordUpdate(input:$input){
      ...AccordFields
    }
  }${ACCORD_FIELDS}
`;

export const MUTATION_accordEtapeValid = gql`
  mutation($input:AccordEtapeValidInput!){
    accordEtapeValid(input:$input){
      ...AccordEtapeFields
    }
  }${ACCORD_ETAPE_FIELDS}
`;

