<template>
  <div>
    <template v-if="marque">
      <template v-if="size == 'xl'">
        <ObjectLayout :action="action" size="xl" :item="marque">
          <template v-slot:icon>mdi-material-design</template>
          <template v-slot:titre>{{ marque.nom }}</template>
          <template v-slot:body>
            <ObjectLayout v-if="marque.nom">
              <template v-slot:titre>Dénomination de la marque</template>
              <template v-slot:content>{{ marque.nom }}</template>
            </ObjectLayout>
          </template>
          <template v-slot:body2>
            <div
              class="d-flex justify-center align-center"
              style="width: 100%; height: 220px"
            >
              <FichierLogo
                size="128"
                v-model="marque.logo"
                :text="marque.nom"
                :justDisplay="true"
              />
            </div>
          </template>
        </ObjectLayout>
      </template>
      <template v-if="size == 'md'">
        <ObjectLayout :action="action" size="md">
          <v-row>
            <v-list-item-avatar tile>
              <FichierLogo
                size="32"
                v-model="marque.logo"
                :text="marque.nom"
                :justDisplay="true"
                class="mx-2"
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-html="marque.nom"></v-list-item-title>
            </v-list-item-content>
          </v-row>
        </ObjectLayout>
      </template>
      <template v-if="size == 'xs'">
        <ObjectLayout
          :action="action"
          size="xs"
          :onClickPush="`/assets/marques/view/${marque.id}`"
        >
          <template v-slot:image>
            <FichierLogo
              size="14"
              v-model="marque.logo"
              :text="marque.nom"
              :justDisplay="true"
            />
          </template>
          <template v-slot:content>{{ marque.nom }}</template>
          <template v-slot:tooltip v-if="tooltip">
            <template v-if="marque.nom">
              <b class="font-weight-bold">Nom:</b>
              {{ marque.nom }}
            </template>
          </template>
        </ObjectLayout>
      </template>
    </template>
  </div>
</template>

<script>
import { QUERY_marque } from "./graphql/queries";
import FichierLogo from "@/components/COMPONENTS-fichiers-stockages/FichierLogo.vue";
import ObjectLayout from "@/components/COMPONENTS-skeleton/Layouts/ObjectLayout.vue";
export default {
  name: "MarqueObject",
  components: {
    FichierLogo,
    ObjectLayout,
  },
  props: {
    size: { type: String, default: "md" },
    marqueProp: {
      type: Object,
      default() {
        return {};
      },
    },
    marqueID: {
      type: String,
    },
    action: {
      type: String,
      default: "router",
    },

    tooltip: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    marqueProp: function () {
      this.init();
    },
    marqueID: function () {
      this.init();
    },
  },
  data: () => ({
    marque: undefined,
  }),
  mounted: function () {
    this.init();
  },
  methods: {
    init() {
      if (this.marqueID) {
        let id = this.marqueID;
        this.$apollo
          .query({
            query: QUERY_marque,
            variables: { id: id },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let marque = data.data.marque;
            this.marque = JSON.parse(JSON.stringify(marque));
          });
      } else {
        this.marque = JSON.parse(JSON.stringify(this.marqueProp));
      }
    },
  },
};
</script>