<template>
  <div>
    <template v-if="organisation">
      <template v-if="size == 'xl'">
        <ObjectLayout :action="action" size="xl">
          <template v-slot:icon>mdi-city-variant</template>
          <template v-slot:titre> {{ organisation.label }}</template>
          <template v-slot:body>
            <ObjectLayout v-if="organisation.label">
              <template v-slot:titre>Nom de l'organisation</template>
              <template v-slot:content>{{ organisation.label }}</template>
            </ObjectLayout>
            <ObjectLayout
              v-if="organisation.habilitations && organisation.habilitations[0]"
            >
              <template v-slot:titre>Habilitations</template>
              <template v-slot:content>
                {{ organisation.habilitations }}
              </template>
            </ObjectLayout>
          </template>
        </ObjectLayout>
      </template>
      <template v-if="size == 'md'">
        <ObjectLayout :action="action" size="md">
          <template v-slot:icon>mdi-city-variant</template>
          <template v-slot:content>{{ organisation.label }}</template>
          <template v-slot:extracontent>
            {{ organisation.habilitations }}
          </template>
        </ObjectLayout>
      </template>
      <template v-if="size == 'xs'">
        <ObjectLayout
          :action="action"
          size="xs"
          :onClickPush="`/authentification-habilitation/organisations/view/${organisation.id}`"
        >
          <template v-slot:icon>mdi-city-variant</template>
          <template v-slot:content>{{ organisation.label }}</template>
        </ObjectLayout>
      </template>
    </template>
  </div>
</template>

<script>
import { QUERY_organisation } from "./graphql/queries";
import ObjectLayout from "@/components/COMPONENTS-skeleton/Layouts/ObjectLayout.vue";
export default {
  name: "OrganisationObject",

  components: {
    ObjectLayout,
  },

  props: {
    size: {
      type: String,
      default: "md",
    },
    organisationProp: {
      type: Object,
      default() {
        return {};
      },
    },
    organisationID: {
      type: String,
    },
    action: {
      type: String,
      default: "router",
    },
  },

  watch: {
    organisationProp: function () {
      this.init();
    },
    organisationID: function () {
      this.init();
    },
  },

  data: () => ({
    organisation: undefined,
  }),

  mounted: function () {
    this.init();
  },

  methods: {
    init() {
      if (this.organisationID) {
        let id = this.organisationID;
        this.$apollo
          .query({
            query: QUERY_organisation,
            variables: {
              id: id,
            },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let organisation = data.data.organisation;
            this.organisation = JSON.parse(JSON.stringify(organisation));
          });
      } else {
        this.organisation = JSON.parse(JSON.stringify(this.organisationProp));
      }
    },
  },
};
</script>