<template>
  <div>
    <template v-if="lienService">
      <template v-if="size == 'xl'">
        <ObjectLayout :action="action" size="xl">
          <template v-slot:icon>mdi-link-box</template>
          <template v-slot:titre> {{ lienService.numeroLienService }}</template>
          <template v-slot:body>
            <v-row>
              <v-col>
                <ObjectLayout>
                  <template v-slot:icon>mdi-link</template>
                  <template v-slot:titre>Lien</template>
                  <template v-slot:content>
                    <a :href="lienService.lien" target="_blank">
                      {{ lienService.lien }}
                    </a>
                  </template>
                </ObjectLayout>
                <ObjectLayout v-if="lienService.asset">
                  <template v-slot:titre>Asset lié</template>
                  <template v-slot:content>
                    <AssetObject :assetProp="lienService.asset" size="xs" />
                  </template>
                </ObjectLayout>
              </v-col>
              <v-col align="center" justify="center" class="pt-5">
                <img
                  :src="lienService.qrCode"
                  style="height: 250px; width: 250px"
                />
              </v-col>
            </v-row>
            <v-row> </v-row>
          </template>
        </ObjectLayout>
      </template>
      <template v-if="size == 'md'">
        <ObjectLayout size="md">
          <template v-slot:icon>mdi-link-box</template>
          <template v-slot:content>
            {{ lienService.numeroLienService }}
          </template>
        </ObjectLayout>
      </template>
      <template v-if="size == 'xs'">
        <ObjectLayout
          size="xs"
          :action="action"
          :onClickPush="`/assets/lienservices/view/${lienService.id}`"
          :color="lienService.asset ? 'success' : ''"
        >
          <template v-slot:icon>mdi-link-box</template>
          <template v-slot:content>
            {{ lienService.numeroLienService }}
          </template>
          <template v-slot:tooltip v-if="tooltip">
            <template v-if="lienService.numeroLienService">
              <b class="font-weight-bold">N° lien:</b>
              {{ lienService.numeroLienService }} <br />
            </template>

            <template v-if="lienService.asset">
              <b class="font-weight-bold">Asset:</b>
              {{ lienService.asset.label }} <br />

              <b class="font-weight-bold">N° inventaire:</b>
              {{ lienService.asset.numeroInventaire }} <br />

              <b class="font-weight-bold">Etat asset:</b>
              {{ lienService.asset.etatAsset }} <br />
            </template>
          </template>
        </ObjectLayout>
      </template>
    </template>
  </div>
</template>

<script>
import { QUERY_lienService } from "./graphql/queries";
import AssetObject from "./AssetObject.vue";
import ObjectLayout from "@/components/COMPONENTS-skeleton/Layouts/ObjectLayout.vue";
export default {
  name: "LienServiceObject",

  components: {
    AssetObject,
    ObjectLayout,
  },

  props: {
    size: { type: String, default: "md" },
    lienServiceProp: {
      type: Object,
      default() {
        return {};
      },
    },
    lienServiceID: {
      type: String,
    },
    action: {
      type: String,
      default: "router",
    },

    tooltip: {
      type: Boolean,
      default: true,
    },
  },

  watch: {
    lienServiceProp: function () {
      this.init();
    },
    lienServiceID: function () {
      this.init();
    },
  },

  data: () => ({
    lienService: undefined,
  }),

  mounted: function () {
    this.init();
  },

  methods: {
    init() {
      if (this.lienServiceID) {
        let id = this.lienServiceID;
        this.$apollo
          .query({
            query: QUERY_lienService,
            variables: { id: id },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let lienService = data.data.lienService;
            this.lienService = JSON.parse(JSON.stringify(lienService));
          });
      } else {
        this.lienService = JSON.parse(JSON.stringify(this.lienServiceProp));
      }
    },
  },
};
</script>