<template>
  <ViewLayout :tabs="tabs">
    <template v-slot:templates>
      <h5>
        Ajoutez ici vos templates au format word (.docx). Ces derniers sont
        ensuite disponible dans l'applicatifs dans les fabriques à fichiers.
      </h5>
      <TemplatesList :items="templatesAvailable" typeParent="AnnuaireOrganisationParametres"
        :idParent="annuaireOrganisationParametres.id" />
    </template>

    <template v-slot:parametres>
      <FormLayout icon="mdi-cog" @modifier="modifier" @reinitialiser="init" titre="Paramètres de l'annuaire"
        :item="input">
        <v-row>
          <v-col cols="6">
            <v-select
              label="Quelle API souhaitez-vous utiliser pour récupérer les informations depuis un numéro de SIREN ?"
              v-model="input.entrepriseAPI" :items="items" />
          </v-col>
        </v-row>
      </FormLayout>
    </template>
  </ViewLayout>
</template>

<script>
import { mapState } from "vuex";
import { QUERY_annuaireOrganisationParametres } from "./graphql/queries.js";
import { MUTATION_annuaireOrganisationParametresUpdate } from "./graphql/mutations.js";
import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";
import TemplatesList from "@/components/COMPONENTS-fichiers-stockages/TemplatesList";
import ViewLayout from "@/components/COMPONENTS-skeleton/Layouts/ViewLayout.vue";
import _ from "lodash";

export default {
  name: "AnnuaireOrganisationParametresForm",
  components: {
    FormLayout,
    TemplatesList,
    ViewLayout,
  },
  data: () => ({
    items: [
      { text: "INSEE - gratuit", value: "INSEE" },
      { text: "PAPPERS - payant", value: "PAPPERS" },
    ],
    input: {},
    annuaireOrganisationParametres: {},
    tabs: [
      { icon: "mdi-cog", displayName: "Paramètres", name: "parametres" },
      // { icon: "mdi-cog", displayName: "Templates", name: "templates" },
    ],
    templatesAvailable: [],
  }),

  computed: {
    ...mapState(["formsErrors"]),
  },

  mounted: function () {
    this.init();
  },

  methods: {
    init() {
      this.$apollo
        .query({
          query: QUERY_annuaireOrganisationParametres,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          let annuaireOrganisationParametres =
            data.data.annuaireOrganisationParametres;
          this.input = JSON.parse(
            JSON.stringify(annuaireOrganisationParametres)
          );
        });
    },

    inputConverter(arg) {
      const obj = { ...arg };

      if (_.isEmpty(obj)) {
        return "";
      }

      let input = {
        entrepriseAPI: obj.entrepriseAPI,
      };

      return input;
    },

    modifier() {
      let toSend = this.inputConverter(this.input);
      this.$apollo.mutate({
        mutation: MUTATION_annuaireOrganisationParametresUpdate,
        variables: {
          input: toSend,
        },
        update: (store, { data: { annuaireOrganisationParametresUpdate } }) => {
          this.$emit(
            "annuaireOrganisationParametresUpdateReturn",
            annuaireOrganisationParametresUpdate
          );
          this.$store.commit("addAlert", {
            type: "info",
            text: "Le paramètrage a été modifié",
          });
          this.$root.$emit("annuaire--update-OrganisationParametres");
          this.$root.$emit("skeleton--close");
        },
      });
    },
  },
};
</script>