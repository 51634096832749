<template>
  <FormLayout
    icon="mdi-package-variant-closed"
    @ajouter="ajouter"
    @fromFile="fromFile"
    @modifier="modifier"
    @reinitialiser="init"
    :fromOCR="true"
    typeItem="un équipement"
    :item="input"
    :disabled="input.modele ? false : true"
  >
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="input.label"
          :error-messages="formsErrors.assetLabel"
          label="Libellé"
          prepend-icon="mdi-tag-text"
        />
      </v-col>
      <v-col>
        <v-row>
          <v-col>
            <v-text-field
              :error-messages="formsErrors.numInvUsed"
              v-model="input.numeroInventaire"
              label="Numéro d'inventaire"
              hint="Le numéro d'inventaire est automatiquement généré, à saisir uniquement en cas de reprise de données"
              persistent-hint
              prepend-icon="mdi-counter"
              :disabled="!numeroInventaireInput"
            />
          </v-col>
          <v-col cols="2">
            <v-switch v-model="numeroInventaireInput"></v-switch>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <EtatAssetSelector v-model="input.etatAsset" />
      </v-col>
      <v-col>
        <v-text-field
          v-model="input.numeroSerie"
          :error-messages="formsErrors.numSerieUsed"
          label="Numéro de série"
          prepend-icon="mdi-card-bulleted"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <MarqueSelector v-model="input.marque" ref="marqueSelector" />
      </v-col>
      <v-col>
        <ModeleSelector
          :disabled="input.marque ? false : true"
          :marque="input.marque"
          v-model="input.modele"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="input.referenceFabricant"
          :error-messages="formsErrors.assetReferenceFabricant"
          label="Référence Fabricant"
          prepend-icon="mdi-card-bulleted"
        />
      </v-col>
      <v-col>
        <v-text-field
          v-model="input.dureeGarantie"
          :error-messages="formsErrors.assetDureeGarantie"
          label="Durée de la garantie (en année)"
          prepend-icon="mdi-timeline-clock"
          type="number"
        />
      </v-col>
    </v-row>
  </FormLayout>
</template>

<script>
import { mapState } from "vuex";

import {
  MUTATION_assetCreate,
  MUTATION_assetUpdate,
} from "./graphql/mutations";
import { QUERY_asset } from "./graphql/queries";

import EtatAssetSelector from "./EtatAssetSelector.vue";
import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";
import MarqueSelector from "./MarqueSelector.vue";
import ModeleSelector from "./ModeleSelector.vue";

export default {
  name: "AssetForm",

  components: {
    EtatAssetSelector,
    FormLayout,
    MarqueSelector,
    ModeleSelector,
  },

  props: {
    componentParent: undefined,
    idAssetEditPopup: undefined
  },

  data: () => ({
    asset: {
      etatAsset: "NEUF",
    },
    input: {},
    numeroInventaireInput: false,
  }),

  computed: {
    ...mapState(["formsErrors"]),
  },

  mounted: function () {
    this.init();
  },

  methods: {
    ajouter() {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_assetCreate,
          variables: {
            input: toSend,
          },
        })
        .then((__) => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "L'asset a été ajouté",
          });

          if (this.componentParent) {
            this.$root.$emit(this.componentParent, __.data.assetCreate);
          }

          this.$root.$emit("skeleton--close");
          this.init();
        });
    },

    fromFile(file) {
       
       
      // TODO : coder ça en mode plugin, activable depuis interface administration
      let eptaCondition = 0;
      if (file.ocrData) {
         
        for (let [key, value] of Object.entries(file.ocrData)) {
          if (value.description == "EPTA") {
             
            // TODO Mettre marque associée à EPTA EN BDD
            eptaCondition++;
            this.$refs.marqueSelector.$refs.selector.toFind = "EPTA";
            this.$refs.marqueSelector.$refs.selector.$refs.autocomplete.focus();
            this.$refs.marqueSelector.$refs.selector.$refs.autocomplete.activateMenu();
             
            // this.$set(this.input, "marque", "628517044e093dfe1ed77f50");
          }
          if (eptaCondition > 0) {
            if (value.description == "SN") {
              this.$set(
                this.input,
                "numeroSerie",
                file.ocrData[eval(key) + 1].description
              );
            }
            if (value.description == "REF") {
              this.$set(
                this.input,
                "referenceFabricant",
                file.ocrData[eval(key) + 1].description
              );
            }
          }
        }
      }
    },

    init() {
      console.log(this.idAssetEditPopup)
      if (
        (this.$route.path.startsWith("/assets/assets/edit/") &&
        this.$route.params.id) || this.idAssetEditPopup
      ) {
        let id = this.idAssetEditPopup ? this.idAssetEditPopup : this.$route.params.id;
        this.$apollo
          .query({
            query: QUERY_asset,
            variables: {
              id: id,
            },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let asset = data.data.asset;
            this.input = JSON.parse(JSON.stringify(asset));
          });
      } else {
        this.input = JSON.parse(JSON.stringify(this.asset));
      }
    },

    inputConverter(arg) {
      const obj = JSON.parse(JSON.stringify(arg));

      let input = {
        dureeGarantie: parseInt(obj.dureeGarantie),
        etatAsset: obj.etatAsset,
        label: obj.label,
        marque: obj.marque && obj.marque.id ? obj.marque.id : obj.marque,
        modele: obj.modele && obj.modele.id ? obj.modele.id : obj.modele,
        numeroInventaire: obj.numeroInventaire,
        numeroSerie: obj.numeroSerie,
        referenceFabricant: obj.referenceFabricant,
      };

      obj.id != undefined ? (input.id = obj.id) : null;

      return input;
    },

    modifier() {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_assetUpdate,
          variables: {
            input: toSend,
          },
        })
        .then(() => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "L'asset a été modifié",
          });
          this.$root.$emit("skeleton--close");
        });
    },
  },
};
</script>
