<template>
    <FormLayout
    icon="mdi-store"
    @ajouter="ajouter"
    @modifier="modifier"
    @reinitialiser="init"
    typeItem="une entrée planning"
    :item="input"
  >
    <v-row>
      <v-col>
        <v-select
          v-model="input.planningAssocie"
          :items="items"
          prepend-icon="mdi-calendar-blank-multiple"
          item-text="label"
          item-value="id"
          :rules="mustBePutRule"
        />
        <DateInput
          v-model="input.dateDebut"
          label="Date de début"
          :min="min"
          :max="max"
          :allowed_dates="allowed_dates"
          @input="testRules(0)"
          :rules="[...mustBePutRule, ...coherenceDateBeforeRule]"
          required
        />
        <DateInput
          v-model="input.dateFin"
          label="Date de fin"
          :min="min"
          :max="max"
          :allowed_dates="allowed_dates"
          @input="testRules(1)"
          :rules="[...mustBePutRule, ...coherenceDateAfterRule]"
          required
        />
        <v-text-field
          v-model="input.label"
          :counter="30"
          :rules="mustBePutRule"
          label="Label"
        />
        <v-text-field
          v-model="input.description"
          label="Description"
        />

        <v-sheet>
          <v-toolbar flat color="white">
            <v-btn :color="colorEntree" dark @click.stop="dialog = true">
              Changer la couleur
            </v-btn>
          </v-toolbar>
        </v-sheet>
        <v-dialog v-model="dialog" max-width="500">
          <v-card>
            <v-container>
              <v-color-picker v-model="colorEntree"></v-color-picker>
            </v-container>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </FormLayout>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";

import {
  MUTATION_entreePlanningCreate,
  MUTATION_entreePlanningUpdate,
} from "./graphql/mutations";
import { QUERY_entreesPlanning } from "./graphql/queries";

import DateInput from "@/components/COMPONENTS-skeleton/Inputs/DateInput";
import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";

export default {
  name: "EntreePlanningForm",

  components: {
    FormLayout,
    DateInput,
  },

  props: {
    componentParent: undefined,
    plannings: {
      type: Array,
      default() {
        return [];
      },
    },
    initialEntreePlanning: {
      type: Object,
      default() {
        return {
          id: "",
          planningAssocie: "",
          dateDebut: "",
          dateFin: "",
          label: "",
          description: "",
          color: "",
        };
      },
    },
    min: {
      type: String,
      default: "2000-01-01",
    },
    max: {
      type: String,
      default: "2099-12-31",
    },
    allowed_dates: {
      type: Function,
      default: () => true,
    },
  },

  data: () => ({
    input: {},
    entreePlanning: {},
    valid: true,
    items: [],
    mustBePutRule: [(v) => !!v || "Ce champ ne peut pas être vide"],
    coherenceDateBeforeRule: [true],
    coherenceDateAfterRule: [true],
    type: "hex",
    hex: "#1976D2",
    hexa: "##1976D2FF",
    dialog: false,
    justCreated: null,
    colorEntree: null
  }),

  computed: {
    ...mapState(["formsErrors"]),
  },

  watch: {
    // En cas de passage de la vue edit à add par exemple
    $route() {
      this.init();
    },

    plannings: function (val) {
      this.items = val;
      this.entreePlanning.planningAssocie = this.plannings[0].id;
    },
  },

  mounted: function () {
    this.init();
  },

  methods: {
    ajouter() {
      console.log(this.input)
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_entreePlanningCreate,
          variables: { input: toSend },
        })
        .then((__) => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "L'entrée de planning a été ajoutée",
          });

          if (this.componentParent) {
            this.$root.$emit(this.componentParent, __.data.entreePlanningCreate);
          }

          this.$root.$emit("skeleton--close");
          this.init();
        });
    },

    init() {
      this.items = this.plannings;
      if (this.$route.params.id) {
        let id = this.$route.params.id;
        this.$apollo
          .query({
            query: QUERY_entreesPlanning,
            variables: { id: id },
          })
          .then((data) => {
            this.entreePlanning = data.data.entreePlanning;
            this.input = JSON.parse(JSON.stringify(this.entreePlanning));
          });
      } else {
        this.input = JSON.parse(JSON.stringify(this.entreePlanning));
      }
    },

    inputConverter(arg) {
      const obj = JSON.parse(JSON.stringify(arg));
      if (_.isEmpty(obj)) {
        return "";
      }

      let input = {
        id: obj.id,
        dateDebut: obj.dateDebut,
        dateFin: obj.dateFin,
        description: obj.description,
        label: obj.label,
        color: obj.color,
      };
      return input;
    },

    modifier() {
      this.$store.commit("resetFormsErrors");
      let toSend = this.inputConverter(this.input);
      this.$apollo.mutate({
        mutation: MUTATION_entreePlanningUpdate,
        variables: { input: toSend },
        refetchQueries: [{ query: QUERY_entreesPlanning }],
        update: (store, { data: { entreePlanningUpdate } }) => {
          this.$emit("entreePlanningReturn", entreePlanningUpdate);
          this.$store.commit("addAlert", {
            type: "info",
            text: "L'entrée planning a été modifiée",
          });
          this.$root.$emit("planning--update-entreesPlanning");
          this.$root.$emit("skeleton--close");
        },
      });
    },
    testRules: function (index) {
      const test = this.entreePlanning.dateDebut <= this.entreePlanning.dateFin;

      if (test) {
        this.coherenceDateBeforeRule = [true];
        this.coherenceDateAfterRule = [true];
        return;
      }

      if (index > 0)
        this.coherenceDateAfterRule = [
          "La date de fin ne peut pas être avant la date de début",
        ];
      else
        this.coherenceDateBeforeRule = [
          "La date de début ne peut pas être après la date de fin",
        ];
    },
  },
};
</script>
