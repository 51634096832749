<template>
  <CounterLayout :compteur="clientsCount">
    <template v-slot:icon>mdi-briefcase-account</template>
  </CounterLayout>
</template>

<script>
import { QUERY_clientsCount } from "./graphql/queries";

import CounterLayout from "@/components/COMPONENTS-skeleton/Layouts/CounterLayout.vue";

export default {
  name: "ClientsCounter",

  components: {
    CounterLayout,
  },
  props: {
    customSearch: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  apollo: {
    clientsCount: {
      query: QUERY_clientsCount,
      variables() {
        return {
          pagination: {
            filter: {
              customSearch: this.customSearch,
            },
          },
        };
      },
      fetchPolicy: "no-cache",
    },
  },

  // created() {
  //   if (!this.$root._events.sateleaseUpdateClients) {
  //     this.$root.$on("sateleaseUpdateClients", this.updateQuery);
  //   }
  // },

  // methods: {
  //   updateQuery: function () {
  //     this.$apollo.queries.clientsCount.refetch();
  //   },
  // },
};
</script>

