<template>
  <FormLayout
    icon="mdi-application-cog"
    @modifier="modifier"
    @reinitialiser="init"
    typeItem="les paramètres de l'application"
    :item="input"
  >
    <v-switch
      v-model="input.isSignUpBlocked"
      label="Désactiver les inscriptions"
    />
  </FormLayout>
</template>

<script>
import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";

import { QUERY_authentificationHabilitationApplicationParametres } from "./graphql/queries.js";
import { MUTATION_authentificationHabilitationApplicationParametresUpdate } from "./graphql/mutations.js";

import _ from "lodash";

export default {
  name: "AuthentificationHabilitationApplicationParametresForm",

  components: {
    FormLayout
  },

  data: () => ({
    authentificationHabilitationApplicationParametres: {
      id: ""
    },

    input: {
      isSignUpBlocked: false
    }
  }),

  methods: {
    init() {
      this.$apollo
        .query({
          query: QUERY_authentificationHabilitationApplicationParametres,
          fetchPolicy: "no-cache",
        })
        .then(__ => {
          this.authentificationHabilitationApplicationParametres = JSON.parse(
            JSON.stringify(__.data.authentificationHabilitationApplicationParametres)
          );
        })
    },

    inputConverter(arg) {
      const obj = { ...arg };

      if (_.isEmpty(obj)) {
        return "";
      }

      let input = {
        isSignUpBlocked: obj.isSignUpBlocked
      };

      return input;
    }, 

    modifier() {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation:
            MUTATION_authentificationHabilitationApplicationParametresUpdate,
          variables: {
            input: toSend,
          },
        })
        .then(() => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "Le paramètrage de l'application a été modifié",
          });
          this.$root.$emit("skeleton--close");
        });
    }
  },

  mounted() {
    this.init();
  },

  watch: {
    authentificationHabilitationApplicationParametres: function (val) {
      this.input = JSON.parse(JSON.stringify(val));
    },
  }
}
</script>
