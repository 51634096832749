<template>
  <div>
    <TitleLayout titre="Liste des utilisateurs attachés" />
    <v-data-table
      dense
      :headers="headers"
      :items="users"
      :hide-default-footer="true"
    >
      <template v-slot:[`item.user`]="{ item }">
        <UserObject :userProp="item" size="xs" />
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small @click="removeUser(item)" color="red">
          mdi-pin-off
        </v-icon>
      </template>
    </v-data-table>
    <v-row class="mt-5">
      <v-col> <UserSelector v-model="userSelected" /> </v-col>
      <v-col cols="2">
        <v-btn
          color="primary"
          @click="addUser"
          :disabled="userSelected ? false : true"
        >
          <v-icon class="mr-2">mdi-pin</v-icon>
          Attacher
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { QUERY_users } from "./graphql/queries";

import TitleLayout from "@/components/COMPONENTS-skeleton/Layouts/TitleLayout.vue";
import UserObject from "./UserObject.vue";
import UserSelector from "./UserSelector.vue";

export default {
  name: "UserAttach",

  components: {
    TitleLayout,
    UserObject,
    UserSelector,
  },

  props: {
    value: null,
  },

  data: () => ({
    headers: [
      { text: "Utilisateur", value: "user", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    userItems: [],
    users: [],
    userSelected: undefined,
  }),

  methods: {
    addUser() {
      if (this.userSelected) {
        // On check si l'user n'est pas déjà selectionné
        let found = this.userItems.find(
          (element) => element == this.userSelected
        );
        if (!found) {
          this.userItems.push(this.userSelected);
        } else {
          this.$store.commit("addAlert", {
            type: "warning",
            text: "L'utilisateur est déjà attaché",
          });
        }
        this.userSelected = undefined;
      }
    },
    fetchUsers() {
       
      this.$apollo
        .query({
          query: QUERY_users,
          variables: {
            pagination: {
              filter: {
                customSearch: [{ _id: this.userItems }],
              },
            },
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const users = __.data.users;
          this.users = users;
        });
    },
    removeUser(item) {
      let index = this.userItems.indexOf(item.id);
      if (index > -1) {
        this.userItems.splice(index, 1);
      }
    },
  },

  watch: {
    userItems: function (val) {
      if (val === null || val === []) {
        this.$emit("input", []);
      } else {
        this.$emit("input", val);
        this.fetchUsers();
      }
    },

    value: function (val) {
      let toReturn = [];
      let changeUserItems = false;

      if (Array.isArray(val)) {
        val.forEach((user) => {
          if (user.id) {
            toReturn.push(user.id);
            changeUserItems = true;
          }
        });

        this.userItems = toReturn;
      }

      if (changeUserItems) {
        this.userItems = toReturn;
      } else {
        this.userItems = val;
      }
    },
  },
};
</script>