<template>
  <ViewLayout :tabs="tabs" :item="personne" v-if="personne">
    <template v-slot:personne>
      <PersonneActions :object="personne" :withView="false" size="xl" />
      <v-row class="align-center mx-auto">
        <v-col cols="12">
          <PersonneObject :personneProp="personne" size="xl" />
        </v-col>
      </v-row>
    </template>
  </ViewLayout>
</template>

<script>
import { QUERY_personne } from "./graphql/queries";
import PersonneActions from "./PersonneActions";
import PersonneObject from "./PersonneObject.vue";
import ViewLayout from "@/components/COMPONENTS-skeleton/Layouts/ViewLayout.vue";

export default {
  name: "PersonneView",

  components: {
    PersonneActions,
    PersonneObject,
    ViewLayout,
  },

  data: () => ({
    personne: null,
    tabs: [
      {
        icon: "mdi-card-account-mail-outline",
        displayName: "Personne",
        name: "personne",
      },
      { name: "commentaires" },
      { name: "fichiers" },
    ],
  }),

  apollo: {
    personne: {
      query: QUERY_personne,
      variables() {
        let idQuery = this.showPopup
          ? this.id
          : this.$route.params.id
          ? this.$route.params.id
          : this.id;
        return {
          id: idQuery,
        };
      },
      fetchPolicy: "no-cache",
    },
  },
  props: {
    showPopup: {
      type: Boolean,
    },
    id: {
      type: String,
    },
  },
};
</script>