import gql from 'graphql-tag';

export const ENTREE_PLANNING_FIELDS = gql`
  fragment EntreePlanningFields on EntreePlanning {
    id
    dateDebut
    dateFin
    label
    description
    color
  }  
`

export const PLANNING_FIELDS = gql`
  fragment PlanningFields on Planning {
    id
    label
  }  
`