var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(!_vm.items[0])?_c('p',{staticClass:"text-center"},[_vm._v("Il n'y a pas de fichier associé")]):_vm._e(),_c('v-treeview',{attrs:{"dense":"","items":_vm.items,"item-key":"name","open-on-click":""},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [(item.children)?[_c('v-badge',{staticClass:"mr-4",attrs:{"color":"primary","content":item.children.length}},[_c('v-icon',[_vm._v(" "+_vm._s(open ? "mdi-folder-open" : "mdi-folder")+" ")])],1)]:_c('v-icon',{on:{"click":function($event){return _vm.getFileId(item.id)}}},[_vm._v(" "+_vm._s(_vm.extensions[item.extension] ? _vm.extensions[item.extension] : "mdi-file-question-outline")+" ")])]}},{key:"label",fn:function(ref){
var item = ref.item;
return [(item.recent && !item.newFile)?[_c('v-badge',{attrs:{"color":"info","content":"Récent","inline":""}},[_vm._v(" "+_vm._s(item.name)+_vm._s(item.extension ? "." + item.extension : "")+" ")])]:(item.newFile)?[_c('v-badge',{attrs:{"color":"success","content":"Nouveau","inline":""}},[_vm._v(" "+_vm._s(item.name)+_vm._s(item.extension ? "." + item.extension : "")+" ")])]:[_vm._v(" "+_vm._s(item.name)+_vm._s(item.extension ? "." + item.extension : "")+" ")]]}},{key:"append",fn:function(ref){
var item = ref.item;
return [(!item.children)?[(item.extension == 'pdf')?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.viewPDF(item)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1):_vm._e(),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.favoriteItem(item)}}},[_c('v-icon',{attrs:{"color":item.favorite ? 'primary' : null}},[_vm._v("mdi-star")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.fileDownload(item)}}},[_c('v-icon',[_vm._v("mdi-download")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]:_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }