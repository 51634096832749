<template>
  <div>
    <template v-if="email">
      <template v-if="size == 'xl'">
        <ObjectLayout 
          :action="action" 
          size="xl"
        >
          <template v-slot:icon>mdi-email-fast</template>
          <template v-slot:titre> {{ email.subject }}</template>
          <template v-slot:body>
            <ObjectLayout>
              <template v-slot:titre>Expéditeur de l'email</template>
              <template v-slot:content>{{ email.sender.email }}</template>
            </ObjectLayout>
            <ObjectLayout>
              <template v-slot:titre>
                Email{{ email.to.length > 1 ? 's' : '' }} d{{ email.to.length > 1 ? 'es' : 'u' }} destinataire{{ email.to.length > 1 ? 's' : '' }}
              </template>
              <template v-slot:content>{{ showDestinataires() }}</template>
            </ObjectLayout>
            <ObjectLayout>
              <template v-slot:titre>
                Date d'envoi
              </template>
              <template v-slot:content>{{ email.dateEnvoi | shortDateAndHourFR }}</template>
            </ObjectLayout>
             <ObjectLayout>
              <template v-slot:titre>
                Provider
              </template>
              <template v-slot:content>{{ email.providerName }}</template>
            </ObjectLayout>
          </template>

        </ObjectLayout>
      </template>
      <template v-if="size == 'xs'">
        <ObjectLayout :action="action" size="xs" :onClickPush="onClickPush">
          <template v-slot:icon>mdi-email-fast</template>
          <template v-slot:content>{{ email.subject }}</template>
          <template v-slot:extracontent>
            <v-icon small class="mr-2">mdi-at</v-icon>
            {{ email.sender.email }}
          </template>

        </ObjectLayout>
      </template>
      <template v-if="size == 'md'">
        <ObjectLayout :action="action" size="md">
          <template v-slot:icon>mdi-email-fast</template>
          <template v-slot:content>{{ email.subject }}</template>
        </ObjectLayout>
      </template>
    </template>
  </div>
</template>

<script>
import { QUERY_email } from "./graphql/queries";
import ObjectLayout from "@/components/COMPONENTS-skeleton/Layouts/ObjectLayout.vue";
export default {
  name:"EmailObject",

  components: {
    ObjectLayout,
  },

  props: {
    size: { type: String, default: "md" },

    emailProp: {
      type: Object,
      default() {
        return {};
      },
    },
    
    emailID: {
      type: String,
    },

    action: {
      type: String,
      default: "router",
    },
  },

   watch: {
    emailProp: function () {
      this.init();
    },
    emailID: function () {
      this.init();
    },
  },

  data: () => ({
    email: undefined,
    onClickPush: "/envoi-notifications/emails/list"
  }),

  mounted: function () {
    this.init();
  },
  methods: {
    init() {
      if (this.emailID) {
        let id = this.emailID;
        this.$apollo
          .query({
            query: QUERY_email,
            variables: { id: id },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let email = data.data.email;
            this.email = JSON.parse(JSON.stringify(email));
            this.onClickPush = `/envoi-notifications/emails/view/${this.email.id}`;
          });
      } else {
        this.email = JSON.parse(JSON.stringify(this.emailProp));
        this.onClickPush = `/envoi-notifications/emails/view/${this.email.id}`;
      }
    },

    showDestinataires() {
      let emails = this.email.to.map(elem => elem.email);
      let tos = emails.join(', ');
      let display = (this.email.to.length > 1) ? tos.substring(0, 30) + ' ...' : tos;

      return display;
    }
  },
}
</script>

<style>

</style>