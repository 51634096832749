<template>
  <div>
    <template v-if="affaire">
      <template v-if="size == 'xl'">
        <ObjectLayout size="xl" :item="affaire">
          <template v-slot:icon>mdi-briefcase-clock</template>
          <template v-slot:titre>
            Affaire : {{ affaire.numeroAffaire }}
          </template>
          <template v-slot:body>
            <TitleLayout titre="Informations administratives" />
            <v-row class="justify-center">
              <v-col xl="3" lg="6" md="6" sm="6" xs="12">
                <ObjectLayout v-if="affaire.numeroAffaire">
                  <template v-slot:icon>mdi-counter</template>
                  <template v-slot:titre>Numéro d'affaire</template>
                  <template v-slot:content>
                    {{ affaire.numeroAffaire }}
                  </template>
                </ObjectLayout>
              </v-col>
              <v-col xl="3" lg="6" md="6" sm="6" xs="12">
                <ObjectLayout v-if="affaire.dateCreation">
                  <template v-slot:icon>mdi-calendar</template>
                  <template v-slot:titre>Date de création</template>
                  <template v-slot:content>
                    {{ affaire.dateCreation | shortDateFR }}
                  </template>
                </ObjectLayout>
              </v-col>
              <v-col xl="6" lg="6" md="6" sm="6" xs="12">
                <ObjectLayout v-if="affaire.datePrevInstallation">
                  <template v-slot:icon>mdi-calendar</template>
                  <template v-slot:titre
                    >Date prévisionnelle d'installation</template
                  >
                  <template v-slot:content>
                    {{ affaire.datePrevInstallation | shortDateFR }}
                  </template>
                </ObjectLayout>
              </v-col>
              <v-col xl="3" lg="6" md="6" sm="6" xs="12">
                <ObjectLayout v-if="affaire.signataireClient">
                  <template v-slot:icon>mdi-fountain-pen-tip</template>
                  <template v-slot:titre>Signataire</template>
                  <template v-slot:content>
                    <PersonneObject
                      :personneProp="affaire.signataireClient"
                      size="xs"
                      class="mb-1"
                    />
                  </template>
                </ObjectLayout>
              </v-col>

              <v-col cols="12" xl="3" v-if="affaire.locataire">
                <ObjectLayout>
                  <template v-slot:icon>mdi-domain</template>
                  <template v-slot:titre>Locataire</template>
                  <template v-slot:content>
                    <ClientObject
                      :clientID="affaire.locataire.id"
                      size="xs"
                      action="popup"
                    />
                  </template>
                </ObjectLayout>
              </v-col>
            </v-row>

            <template v-if="affaire.financement">
              <TitleLayout titre="Informations du financement" />

              <v-row>
                <v-col>
                  <ObjectLayout v-if="affaire.montantFinancement">
                    <template v-slot:icon>mdi-cash-plus</template>
                    <template v-slot:titre>Capital à financer</template>
                    <template v-slot:content>
                      <MoneyLayout :montant="affaire.montantFinancement" />
                    </template>
                  </ObjectLayout>
                </v-col>
                <v-col>
                  <ObjectLayout v-if="affaire.montantTVA">
                    <template v-slot:icon>mdi-cash-plus</template>
                    <template v-slot:titre>TVA à financer</template>
                    <template v-slot:content>
                      <MoneyLayout :montant="affaire.montantTVA" />
                    </template>
                  </ObjectLayout>
                </v-col>
              </v-row>
              <div class="pl-2 pt-4">
                <v-icon class="pr-2">mdi-scale</v-icon>Répartition du capital
              </div>
              <v-simple-table class="pb-4" dense>
                <thead>
                  <tr>
                    <th>Client</th>
                    <th>Part capital</th>
                    <th>Part TVA</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(repartition, index) in affaire.financement
                      .repartition"
                    :key="'repartition' + index"
                  >
                    <td>
                      <ClientObject
                        :clientID="repartition.client.id"
                        size="xs"
                      />
                    </td>
                    <td>
                      <span class="font-weight-bold">
                        {{ (repartition.partCapital / 100) | pourcent }}
                      </span>
                      :
                      {{
                        (affaire.montantFinancement *
                          (repartition.partCapital / 100))
                          | monetaire
                      }}
                    </td>
                    <td>{{ repartition.montantTVA | monetaire }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
              <v-row>
                <v-col>
                  <ObjectLayout>
                    <template v-slot:icon>mdi-calendar-clock</template>
                    <template v-slot:titre>
                      Nombre de périodes principales*
                    </template>
                    <template v-slot:content>
                      {{ affaire.financement.nper }}
                    </template>
                  </ObjectLayout>
                </v-col>
                <v-col>
                  <ObjectLayout v-if="affaire.financement.nperComp > 0">
                    <template v-slot:icon>mdi-calendar-clock</template>
                    <template v-slot:titre>
                      Nombre de périodes complémentaires
                    </template>
                    <template v-slot:content>
                      {{ affaire.financement.nperComp }}
                    </template>
                  </ObjectLayout>
                </v-col>
              </v-row>
              <v-row>
                <v-col xl="6" lg="6" md="6" sm="6" xs="12">
                  <ObjectLayout>
                    <template v-slot:icon>mdi-timeline-help</template>
                    <template v-slot:titre>Mode de paiement</template>
                    <template v-slot:content>
                      {{
                        affaire.financement.isBeginning
                          ? "Paiement en début de période"
                          : "Paiement en fin de période"
                      }}
                    </template>
                  </ObjectLayout>
                </v-col>
                <v-col xl="6" lg="6" md="6" sm="6" xs="12">
                  <ObjectLayout>
                    <template v-slot:icon>mdi-timeline-help</template>
                    <template v-slot:titre>Périodicité</template>
                    <template v-slot:content>
                      {{
                        affaire.financement.cf == 12 || affaire.financement.cf == "mensuelOpti"
                          ? "Loyers mensuels"
                          : affaire.financement.cf == 4 || affaire.financement.cf == "trimestrielOpti"
                          ? "Loyers trimestriels"
                          : affaire.financement.cf == 2 || affaire.financement.cf == "semestrielOpti"
                          ? "Loyers semestriels"
                          : "Loyers annuels"
                      }}
                    </template>
                  </ObjectLayout>
                </v-col>
              </v-row>
              <v-expansion-panels class="py-6">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <v-row>
                      <v-col>
                        <v-icon class="mr-2">mdi-table-large</v-icon> Tableau de
                        loyers
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <LoyersView
                      :edit="false"
                      :value="affaire.financement.loyers"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-row class="justify-center">
                <v-col
                  cols="12"
                  xl="4"
                  lg="4"
                  md="4"
                  sm="4"
                  xs="12"
                  v-if="affaire.fraisDossier"
                >
                  <ObjectLayout v-if="affaire.fraisDossier">
                    <template v-slot:icon></template>
                    <template v-slot:titre>Frais de dossier</template>
                    <template v-slot:content>
                      <MoneyLayout :montant="affaire.fraisDossier" />
                    </template>
                  </ObjectLayout>
                </v-col>
                <v-col cols="12" xl="4" lg="4" md="4" sm="4" xs="12">
                  <ObjectLayout>
                    <template v-slot:icon></template>
                    <template v-slot:titre> Marge loueur</template>
                    <template v-slot:content>
                      <MoneyLayout :montant="affaire.financement.margeLoueur" />
                    </template>
                  </ObjectLayout>
                </v-col>
                <v-col cols="12" xl="4" lg="4" md="4" sm="4" xs="12">
                  <ObjectLayout v-if="affaire.financement.rate">
                    <template v-slot:icon>mdi-percent</template>
                    <template v-slot:titre>Taux du financement</template>
                    <template v-slot:content>
                      <v-chip color="info" class="font-weight-bold">
                        {{ affaire.financement.rate }} %
                      </v-chip>
                    </template>
                  </ObjectLayout>
                </v-col>
              </v-row>
            </template>
          </template>
        </ObjectLayout>
      </template>

      <template v-if="size == 'md'">
        <v-row> {{ affaire.numeroAffaire }}</v-row>
      </template>
      <template v-if="size == 'xs'">
        <ObjectLayout
          size="xs"
          :onClickPush="`/satelease/affaires/view/${affaire.id}`"
          :action="action"
        >
          <template v-slot:icon>mdi-briefcase-clock</template>
          <template v-slot:content>
            {{ affaire.numeroAffaire }}
          </template>
          <template v-slot:extracontent>
            {{ affaire.locataire && affaire.locataire.entreprise && affaire.locataire.entreprise.raisonSociale 
              ? affaire.locataire.entreprise.raisonSociale + " - " : "" }}
            {{ affaire.montantFinancement | monetaire }}
          </template>
        </ObjectLayout>
      </template>

      <v-chip
        v-if="size == 'xs-demandeAccords'"
        class="pr-0 pl-2 font-weight-bold"
        color="info"
      >
        {{ demandeAccords.waiting }}
        <v-avatar right class="pr-2">
          <v-icon small>mdi-clock-outline</v-icon>
        </v-avatar>
        <v-chip class="pr-0 pl-2" color="warning">
          {{ demandeAccords.waitingForElmt }}
          <v-avatar right class="pr-2">
            <v-icon small>mdi-clock-outline</v-icon>
          </v-avatar>
          <v-chip class="pr-0 pl-2" color="success">
            {{ demandeAccords.valid }}
            <v-avatar right class="pr-2">
              <v-icon small>mdi-check-circle</v-icon>
            </v-avatar>
            <v-chip class="pr-0 pl-2" color="error">
              {{ demandeAccords.refused }}
              <v-avatar right class="pr-2">
                <v-icon small>mdi-close-circle</v-icon>
              </v-avatar>
            </v-chip>
          </v-chip>
        </v-chip>
      </v-chip>
    </template>
  </div>
</template>

<script>
import { QUERY_affaire } from "./graphql/queries";

import ClientObject from "@/components/COMPONENTS-devis-facture/ClientObject.vue";
import LoyersView from "./LoyersView.vue";
import MoneyLayout from "@/components/COMPONENTS-skeleton/Layouts/MoneyLayout.vue";
import ObjectLayout from "@/components/COMPONENTS-skeleton/Layouts/ObjectLayout.vue";
import PersonneObject from "@/components/COMPONENTS-annuaire/PersonneObject.vue";
import TitleLayout from "@/components/COMPONENTS-skeleton/Layouts/TitleLayout.vue";

export default {
  name: "AffaireObject",

  components: {
    ClientObject,
    LoyersView,
    MoneyLayout,
    ObjectLayout,
    PersonneObject,
    TitleLayout,
  },

  props: {
    action: { type: String, default: "router" },
    affaireID: {
      type: String,
    },
    affaireProp: {
      type: Object,
      default() {
        return {};
      },
    },
    size: { type: String, default: "md" },
  },

  data: () => ({
    affaire: undefined,
    openDialogShowAffaire: false,
  }),

  watch: {
    affaireID: function () {
      this.init();
    },
    affaireProp: function () {
      this.init();
    },
  },

  computed: {
    // Permet de calculer le nombre d'accord par état (en attente, valid...)
    demandeAccords: function () {
      let demandeAccords = {
        waiting: 0,
        waitingForElmt: 0,
        valid: 0,
        refused: 0,
      };
      for (let da of this.affaire.demandeAccords) {
        demandeAccords[da.etat]++;
      }
      return demandeAccords;
    },

    loyersView: function () {
      let loyersView = [];

      if (this.affaire.financement.loyers[0]) {
        for (let [i, loyer] of this.affaire.financement.loyers.entries()) {
          if (i == 0) {
            loyersView.push({ number: 1, pmt: loyer.pmt });
          } else {
            if (loyersView.at(-1).pmt == loyer.pmt) {
              loyersView.at(-1).number++;
            } else {
              loyersView.push({ number: 1, pmt: loyer.pmt });
            }
          }
        }
      }
      return loyersView;
    },
  },

  mounted: function () {
    this.init();
  },

  methods: {
    init() {
      if (this.affaireID) {
        let id = this.affaireID;
        this.$apollo
          .query({
            query: QUERY_affaire,
            variables: { id: id },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let affaire = data.data.affaire;
            this.affaire = JSON.parse(JSON.stringify(affaire));
            // this.updatePanelEtb();
          });
      } else {
        this.affaire = JSON.parse(JSON.stringify(this.affaireProp));
      }
    },
  },
};
</script>