<template>
  <div>
    <template v-if="size == 'xs'">
      <CounterLayout :compteur="personnesCount" size="xs">
        <template v-slot:icon>mdi-card-account-mail-outline</template>
      </CounterLayout>
    </template>
    <template v-if="size == 'xl'">
      <CounterLayout :compteur="personnesCount" subTitle="Personnes" size="xl">
        <template v-slot:icon>mdi-card-account-mail-outline</template>
      </CounterLayout>
    </template>
  </div>
</template>

<script>
import CounterLayout from "@/components/COMPONENTS-skeleton/Layouts/CounterLayout.vue";
import { QUERY_personnesCount } from "./graphql/queries";
export default {
  name: "PersonnesCounter",
  components: {
    CounterLayout,
  },
  props: {
    customSearch: {
      type: Array,
      default() {
        return [];
      },
    },

    size: {
      type: String,
      default: "md",
    },
  },
  data: () => ({}),
  apollo: {
    personnesCount: {
      query: QUERY_personnesCount,
      variables() {
        return {
          pagination: {
            filter: {
              customSearch: this.customSearch,
            },
          },
        };
      },
      fetchPolicy: "no-cache",
    },
  },

  created() {
    if (!this.$root._events.annuaireUpdatePersonnes) {
      this.$root.$on("annuaireUpdatePersonnes", this.updateQuery);
    }
  },

  mounted: function () {},
  watch: {},
  methods: {
    updateQuery: function () {
      this.$apollo.queries.personnesCount.refetch();
    },
  },
};
</script>