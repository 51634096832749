<template>
  <ViewLayout :tabs="tabs" :item="factureClient" v-if="factureClient.id">
    <template v-slot:factureClient>
      <FactureClientActions
        @fetch="$refs.reglementClientsList.init()"
        :object="factureClient"
        :withView="false"
        size="xl"
      />

      <v-row class="mx-auto">
        <v-col>
          <FactureClientObject size="xl" :factureClientProp="factureClient" />
        </v-col>
        <v-col>
          <ReglementClientsList
            ref="reglementClientsList"
            :customSearch="[{ factureClient: factureClient.id }]"
            :noFilter="true"
          />
        </v-col>
      </v-row>
    </template>
  </ViewLayout>
</template>

<script>
import { QUERY_factureClient } from "./graphql/queries";

import FactureClientObject from "./FactureClientObject.vue";
import FactureClientActions from "./FactureClientActions.vue";
import ReglementClientsList from "./ReglementClientsList.vue";
import ViewLayout from "@/components/COMPONENTS-skeleton/Layouts/ViewLayout.vue";

export default {
  name: "FactureClientView",

  components: {
    FactureClientObject,
    FactureClientActions,
    ReglementClientsList,
    ViewLayout,
  },

  data: () => ({
    factureClient: {},
    tabs: [
      {
        icon: "mdi-file-table",
        displayName: "Facture client",
        name: "factureClient",
      },
      // {
      //   icon: "mdi-package-variant-closed",
      //   displayName: "Equipements",
      //   name: "assets",
      // },
      { name: "commentaires" },
      { name: "fichiers" },
    ],
  }),

  apollo: {
    factureClient: {
      query: QUERY_factureClient,
      variables() {
        let idQuery = this.showPopup
          ? this.id
          : this.$route.params.id
          ? this.$route.params.id
          : this.id;
        return {
          id: idQuery,
        };
      },
      fetchPolicy: "no-cache",
    },
  },

  props: {
    id: {
      type: String,
    },

    showPopup: {
      type: Boolean,
    },
  },
};
</script>