<template>
  <v-form @submit.prevent="ajouter">
    <v-row>
      <v-col>
        <v-alert text color="info">
          <div v-pre>
            Il est possible d'utiliser les variables dans l'objet du mail ainsi
            que dans le contenu HTML qui sera généré à l'aide de ce template.
            <br />Format :
            {{ objet.attribut }}
          </div>
        </v-alert>
        <v-text-field v-model="input.name" label="Nom du template" />
        <v-text-field v-model="input.emailObject" label="Objet du mail" />

        <v-textarea v-model="input.htmlContent" label="Contenu HTML du mail" />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        <v-btn :disabled="false" color="primary" type="submit">
          <v-icon class="mr-2">mdi-content-save</v-icon>Enregistrer le template
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapState } from "vuex";

import {
  MUTATION_emailTemplateCreate,
  MUTATION_emailTemplateUpdate,
} from "./graphql/mutations";
import { QUERY_emailTemplate } from "./graphql/queries";

export default {
  name: "EmailTemplateForm",

  components: {},

  props: {
    componentParent: undefined,
    meta: { type: Object },
  },

  data: () => ({
    emailTemplate: {
      id: undefined,
    },
    input: {},
  }),

  computed: {
    ...mapState(["formsErrors"]),
  },

  mounted: function () {
    this.init();
  },

  methods: {
    ajouter() {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_emailTemplateCreate,
          variables: {
            input: toSend,
          },
        })
        .then((__) => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "Votre EmailTemplate a été ajouté",
          });

          if (this.componentParent) {
            this.$root.$emit(this.componentParent, __.data.emailTemplateCreate);
          }
          this.$root.$emit("envoiNotificationUpdateEmailTemplate");

          this.$root.$emit("skeleton--close");
          this.init();
        });
    },

    init() {
      if (
        this.$route.path.startsWith(
          "/envoi-notifications/emailTemplates/edit/"
        ) &&
        this.$route.params.id
      ) {
        let id = this.$route.params.id;
        this.$apollo
          .query({
            query: QUERY_emailTemplate,
            variables: {
              id: id,
            },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let emailTemplate = data.data.emailTemplate;
            this.input = JSON.parse(JSON.stringify(emailTemplate));
          });
      } else {
        this.input = JSON.parse(JSON.stringify(this.emailTemplate));
      }
    },

    inputConverter(arg) {
      const obj = JSON.parse(JSON.stringify(arg));
       
      let input = {
        emailObject: obj.emailObject,
        meta: this.meta,
        name: obj.name,
        htmlContent: obj.htmlContent,
      };
      return input;
    },

    modifier() {
      let toSend = this.inputConverter(this.input);

      this.$apollo
        .mutate({
          mutation: MUTATION_emailTemplateUpdate,
          variables: {
            input: toSend,
          },
        })
        .then(() => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "EmailTemplate a été modifié",
          });
          this.$root.$emit("skeleton--close");
        });
    },
  },
};
</script>