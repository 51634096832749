<template>
  <div>  
    <v-card class="mx-auto" max-width="100%" min-height="300">
      <v-card-title class="primary white--text rounded py-2">
        <v-icon class="mr-2 white--text">mdi-pencil</v-icon>Changer la couleur de l'organisation
      </v-card-title><br />
      <v-row
        v-for="j in 6"
        :key="j"
      >
        <v-spacer></v-spacer>
        <v-col
          v-for="k in 3"
          :key="k"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn  
                :color="items[3 * (j-1) + (k-1)].couleur"
                large
                type="filled"
                @click="changeColor(items[3 * (j-1) + (k-1)].couleur)"
                v-bind="attrs"
                v-on="on"
              />
            </template>
            {{items[3 * (j-1) + (k-1)].nom}} 
          </v-tooltip>
          <v-spacer />
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-card>
  </div>
</template>

<script>


export default {
    name:  "OrganisationColorSelector",

    props: {
      value: {
        type: String,
        default: ""
      },

      componentParent: undefined
    },

    mounted() {
      this.init();
    },

    data: () => ({
      colorCourante: null,
      items: [
        {couleur: '#B71C1C', nom: "Rouge"},{couleur: '#E91E63', nom:"Rose"},{couleur:'#B39DDB', nom:"Violet clair"},
        {couleur: '#9C27B0', nom: "Violet"},{couleur: '#673AB7', nom: "Violet foncé"},{couleur:'#9E9E9E', nom: "Gris"},
        {couleur: '#2196F3', nom: "Bleu"},{couleur: '#1565C0', nom:"Bleu foncé"},{couleur:'#00BCD4', nom: "Cyan"},
        {couleur:'#009688', nom: "Sarcelle"}, {couleur: '#1EB980', nom:"Vert foncé(défaut)"},{couleur:'#4CAF50', nom:"Vert"},
        {couleur:'#8BC34A', nom: "Vert clair"},{couleur:'#FFC107', nom:"Ambre"},{couleur:'#FF9800', nom:"Orange"},
        {couleur:'#FF5722', nom: "Orange foncé"},{couleur:'#795548', nom:'Brun'},{couleur:'#607D8B', nom:'Gris-bleu'}
      ]
    }),
    
    methods: {
      changeColor(color, close = true) {
        this.colorCourante = color;
        if (this.componentParent) {
          this.$root.$emit(this.componentParent, this.colorCourante);
          if(close) this.$root.$emit("skeleton--close");
        }
      },

      init() {
        let color = "";
        if(this.value && this.value != "") {
          color = this.value;
        }else {
          color = this.$vuetify.theme.themes.light.primary
        }
        this.changeColor(color, false);
      }
    },

    watch: {
      value() {
        this.init();
      },

      colorCourante(val) {
        this.$emit("input", val);
      }
    }
  }

</script>