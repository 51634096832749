<template>
  <div>
    <SelectorLayout
      ref="selector"
      v-model="item"
      @fetch="fetch"
      :prepend-icon="prependIcon"
      :label="label"
      :addAction="addAction"
    >
      <template v-slot:selection="data">
        <LienServiceObject
          :lienServiceProp="data.item"
          v-bind="data.attrs"
          :input-value="data.selected"
          @click="data.select"
          size="xs"
          action="none"
        />
      </template>

      <template v-slot:item="data">
        <LienServiceObject
          :lienServiceProp="data.item"
          size="xs"
          action="none"
        />
      </template>
    </SelectorLayout>
  </div>
</template>

<script>
import { QUERY_lienServices } from "./graphql/queries";
import LienServiceObject from "./LienServiceObject.vue";
import SelectorLayout from "@/components/COMPONENTS-skeleton/Layouts/SelectorLayout.vue";

export default {
  name: "LienServiceSelector",

  components: {
    LienServiceObject,
    SelectorLayout,
  },

  data: () => ({
    item: null,
  }),

  props: {
    value: null,
    errorMessage: { type: String },
    label: { type: String, default: "LienServices" },
    prependIcon: { type: String, default: "mdi-link-box" },
    multiple: { type: Boolean, default: false },
    addAction: { type: String, default: "/assets/lienServices/add" },
  },

  created() {
    this.root.on("assets--lienService-create", (item) => {
      this.item = item;
    });
  },

  methods: {
    // Fetch les lienServices en fonction du filtre
    fetch() {
      this.$refs.selector.loading = true;
      this.apollo
        .query({
          query: QUERY_lienServices,
          variables: {
            pagination: this.$refs.selector.pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          this.$refs.selector.items = data.lienServices;
          this.$refs.selector.loading = false;
        });
    },
  },

  watch: {
    value: {
      deep: true,
      handler(val) {
        this.item = val && val.id ? val.id : val;
        this.fetch();
      },
    },

    item: {
      deep: true,
      handler(val) {
        if (val === null) {
          this.$emit("input", null);
        } else {
          this.$emit("input", val);
        }
      },
    },
  },
};
</script>