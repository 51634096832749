<template>
  <ListLayout
    ref="list"
    :headers="headers"
    @fetch="init"
    @showMultipleActions="showMultipleActions = $event"
    title="Liste des users"
  >
    <template v-slot:multipleActions>
      <UserActions
        :multiple="true"
        :object="$refs.list ? $refs.list.itemsSelected : []"
        size="xl"
        v-show="showMultipleActions"
        @fetch="init"
        @loading="$refs.list.loading = true"
      />
    </template>

    <template v-slot:[`item.object`]="{ item }">
      <UserObject :userProp="item" size="xs" />
    </template>

    <template v-slot:[`item.habilitationCount`]="{ item }">
      <HabilitationCounter :customSearch="[{ utilisateur: item.id }]" />
    </template>

    <template v-slot:[`item.verify`]="{ item }">
      <v-icon v-if="item.verify" color="success"> mdi-check-circle </v-icon>
      <div v-if="!item.verify">
        <v-icon color="error"> mdi-close-circle </v-icon>
       <!-- <v-btn color="primary" @click="resendEmail(item.id)"
          >Renvoyer l'email</v-btn
        > -->
      </div>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <UserActions
        :object="item"
        size="xs"
        @fetch="init"
        @loading="$refs.list.loading = true"
      />
    </template>
  </ListLayout>
</template>

<script>
import HabilitationCounter from "./HabilitationCounter.vue";
import UserActions from "./UserActions.vue";
import UserObject from "./UserObject.vue";
import ListLayout from "@/components/COMPONENTS-skeleton/Layouts/ListLayout.vue";

import { QUERY_users } from "./graphql/queries";

export default {
  name: "UsersList",

  components: {
    HabilitationCounter,
    ListLayout,
    UserActions,
    UserObject,
  },

  data: () => ({
    search: null,
    showMultipleActions: false,
    headers: [
      { text: "", value: "object", sortable: false },
      { text: "Vérifié", value: "verify", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),

  methods: {
    fetchUsers() {
      this.$refs.list.loading = true;
      this.$apollo
        .query({
          query: QUERY_users,
          variables: {
            pagination: this.$refs.list.pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          this.$refs.list.items = data.users;
          this.$refs.list.itemsCount = data.usersCount;
          this.$refs.list.loading = false;
        });
    },

    init() {
      if (this.acl("/authentification-habilitation/all/users")) {
        let entrie = {
          text: "Habilitations",
          value: "habilitationCount",
          sortable: false,
        };
        if (!JSON.stringify(this.headers).includes(JSON.stringify(entrie))) {
          this.headers.splice(1, 0, entrie);
        }
      }
      this.fetchUsers();
    },

    
  },
};
</script>