<template>
  <ViewLayout :tabs="tabs" :item="enveloppeTheorique">
    <template v-slot:enveloppeTheorique>
      <EnveloppeTheoriqueActions :object="enveloppeTheorique" size="xl" />

      <v-row class="align-center mx-auto">
        <v-col cols="12">
          <EnveloppeTheoriqueObject
            size="xl"
            :enveloppeTheoriqueProp="enveloppeTheorique"
          />
        </v-col>
      </v-row>
    </template>
  </ViewLayout>
</template>
<script>
import { QUERY_enveloppeTheorique } from "./graphql/queries";

import EnveloppeTheoriqueActions from "./EnveloppeTheoriqueActions.vue";
import EnveloppeTheoriqueObject from "./EnveloppeTheoriqueObject.vue";
import ViewLayout from "@/components/COMPONENTS-skeleton/Layouts/ViewLayout.vue";

export default {
  name: "EnveloppeTheoriqueView",

  components: {
    EnveloppeTheoriqueActions,
    EnveloppeTheoriqueObject,
    ViewLayout,
  },

  data: () => ({
    enveloppeTheorique: {},
    tabs: [
      {
        icon: "mdi-email-variant",
        displayName: "enveloppe théorique",
        name: "enveloppeTheorique",
      },
      { name: "commentaires" },
      { name: "fichiers" },
    ],
  }),

  apollo: {
    enveloppeTheorique: {
      query: QUERY_enveloppeTheorique,
      variables() {
        let idQuery = this.showPopup
          ? this.id
          : this.$route.params.id
          ? this.$route.params.id
          : this.id;
        return {
          id: idQuery,
        };
      },
    },
  },
};
</script>