<template>
  <div>
    <v-combobox
      v-bind:value="value"
      v-on:input="$emit('input', $event)"
      :items="fichierCategories"
      label="Catégorie"
      :error-messages="errorMessages"
      chips
      clearable
      item-text="value"
      prepend-icon="mdi-format-list-bulleted-type"
      dense
    >
    </v-combobox>
  </div>
</template>

<script>
import { QUERY_fichierCategories } from "./graphql/queries";
export default {
  name: "FichierCategorieSelector",
  components: {},
  props: ["typeParent", "value", "errorMessages"],
  data: () => ({
    isLoading: false,
    search: null,
    tab: null,
    entreprises: [],
  }),
  created() {
    this.$root.$on("fichiers-stockages--update-files", this.updateQuery);
  },

  watch: {
    //Evite de retourner un champ null lors de la suppression d'un chip
    value: function (val) {
      if (val == null) {
        this.$emit("input", "");
      } else {
        this.$emit("input", val);
      }
    },
  },
  apollo: {
    fichierCategories: {
      query: QUERY_fichierCategories,
      variables() {
        return {
          typeParent: this.typeParent,
        };
      },
      fetchPolicy: "no-cache",
    },
  },
  mounted: function () {},
  methods: {
    updateQuery: function () {
      this.$apollo.queries.fichierCategories.refetch();
    },
  },
};
</script>