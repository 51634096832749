<template>
  <div>
    <template v-if="accordEtape">
      <template v-if="size == 'timeline-item'">
        <v-timeline-item :color="color">
          <template v-slot:icon>
            <v-icon dark>
              {{ icon }}
            </v-icon>
          </template>

          <v-card class="elevation-2">
            <v-card-title :class="`text-h5 white--text ` + color">
              <v-icon class="mr-2">mdi-sign-direction</v-icon>
              Etape
              {{ etapeNumber }} - {{ etatString }}
              <v-spacer />
              <v-icon>{{ icon }}</v-icon>
            </v-card-title>
            <v-card-text>
              <v-row dense>
                <v-col>
                  <ObjectLayout>
                    <template v-slot:icon>mdi-account-question</template>
                    <template v-slot:titre>Action à mener</template>
                    <template v-slot:content>
                      {{ typeString }}
                    </template>
                  </ObjectLayout>
                </v-col>
              </v-row>

              <template v-if="accordEtape.destinataire">
                <v-row dense>
                  <v-col>
                    <ObjectLayout>
                      <template v-slot:icon>mdi-account</template>
                      <template v-slot:titre>Nom du destinataire</template>
                      <template v-slot:content>
                        {{ accordEtape.destinataire.nom }}
                      </template>
                    </ObjectLayout>
                  </v-col>
                  <v-col>
                    <PersonneObject
                      :personneID="accordEtape.destinataire.personneAssociee.id"
                      v-if="accordEtape.destinataire.personneAssociee"
                      size="xs"
                      class="mt-3"
                    />
                  </v-col>
                </v-row>

                <ObjectLayout>
                  <template v-slot:icon>mdi-account</template>
                  <template v-slot:titre>
                    Adresse email du destinataire
                  </template>
                  <template v-slot:content>
                    {{ accordEtape.destinataire.email }}
                  </template>
                </ObjectLayout>
              </template>
            </v-card-text>

            <v-card-actions v-if="accordEtape.etat == 'EN_COURS'">
              <DateInput v-model="dateAction" />
              <v-spacer />
              <v-btn
                fab
                x-small
                dark
                color="success"
                class="ml-2"
                @click="valid(true)"
              >
                <v-icon>mdi-check-bold</v-icon>
              </v-btn>
              <v-btn
                fab
                x-small
                dark
                color="error"
                class="ml-2"
                @click="valid(false)"
              >
                <v-icon>mdi-cancel</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-timeline-item>
      </template>

      <template v-if="size == 'xl'">
        <ObjectLayout :action="action" size="xl" :item="accordEtape">
          <template v-slot:icon>mdi-sign-direction</template>
          <template v-slot:titre> {{ accordEtape.id }}</template>
          <template v-slot:body>
            <ObjectLayout v-if="accordEtape.id" class="py-2">
              <template v-slot:icon>mdi-tag-text</template>
              <template v-slot:titre>Dénomination de l'accordEtape</template>
              <template v-slot:content>{{ accordEtape.id }}</template>
            </ObjectLayout>
          </template>
        </ObjectLayout>
      </template>
      <template v-if="size == 'md'">
        <ObjectLayout size="md">
          <template v-slot:icon>mdi-sign-direction</template>
          <template v-slot:content>{{ accordEtape.id }}</template>
          <template v-slot:extracontent>
            <v-icon small class="mr-2">mdi-material-design</v-icon>
            {{ accordEtape.id }}
          </template>
        </ObjectLayout>
      </template>
      <template v-if="size == 'xs'">
        <ObjectLayout
          size="xs"
          :action="action"
          :onClickPush="`/accord-validation/accordEtapes/view/${accordEtape.id}`"
        >
          <template v-slot:icon>mdi-sign-direction</template>
          <template v-slot:content>
            {{ accordEtape.id }} - {{ accordEtape.id }}
          </template>
        </ObjectLayout>
      </template>
    </template>
  </div>
</template>

<script>
import dayjs from "dayjs";

import { MUTATION_accordEtapeValid } from "./graphql/mutations";
import { QUERY_accordEtape } from "./graphql/queries";

import DateInput from "@/components/COMPONENTS-skeleton/Inputs/DateInput.vue";
import ObjectLayout from "@/components/COMPONENTS-skeleton/Layouts/ObjectLayout.vue";
import PersonneObject from "@/components/COMPONENTS-annuaire/PersonneObject.vue";

export default {
  name: "AccordEtapeObject",

  components: {
    DateInput,
    ObjectLayout,
    PersonneObject,
  },

  props: {
    action: {
      type: String,
      default: "router",
    },

    accordEtapeProp: {
      type: Object,
      default() {
        return {};
      },
    },

    accordEtapeID: {
      type: String,
    },

    etapeNumber: {
      type: Number,
    },

    etapePrecedente: {
      type: Object,
      default: null,
    },

    size: { type: String, default: "md" },
  },

  data: () => ({
    accordEtape: undefined,
    dateAction: null,
  }),

  watch: {
    accordEtapeID: function () {
      this.init();
    },

    accordEtapeProp: function () {
      this.init();
    },
  },

  mounted: function () {
    this.init();
  },

  computed: {
    color() {
      switch (this.accordEtape.etat) {
        default:
          return "grey";
        case "EN_ATTENTE":
          return "grey";
        case "EN_COURS":
          return "info";
        case "TERMINE_OK":
          return "success";
        case "ABANDONNE":
          return "warning";
        case "TERMINE_NOK":
          return "error";
      }
    },
    etatString() {
      switch (this.accordEtape.etat) {
        default:
          return "En attente";
        case "EN_ATTENTE":
          return "En attente";
        case "EN_COURS":
          return "En cours";
        case "TERMINE_OK":
          return "Validée";
        case "ABANDONNE":
          return "Annulée";
        case "TERMINE_NOK":
          return "Refusée";
      }
    },
    icon() {
      switch (this.accordEtape.etat) {
        default:
          return "mdi-clock-outline";
        case "EN_ATTENTE":
          return "mdi-clock-outline";
        case "EN_COURS":
          return "mdi-chevron-right-circle-outline";
        case "TERMINE_OK":
          return "mdi-check-circle-outline";
        case "ABANDONNE":
          return "mdi-close-circle-outline";
        case "TERMINE_NOK":
          return "mdi-alert-circle-outline";
      }
    },
    typeString() {
      switch (this.accordEtape.type) {
        default:
          return "";
        case "DOCUSIGN_APPROBATION":
          return "Demande d'approbation";
        case "DOCUSIGN_SPECIFIER_DESTINATAIRE":
          return "Spécifie le destinataire de l'étape suivante";
        case "DOCUSIGN_RECOIT_COPIE":
          return "Recoit une copie";
        case "DOCUSIGN_SIGNATURE_SIMPLE":
          return "Signature électronique simple";
        case "DOCUSIGN_SIGNATURE_VERIFICATION_IDENTITE_AVANCEE":
          return "Signature électronique avec vérification de l'identité";
      }
    },
  },

  methods: {
    valid(etat) {
       
       
      let input = {
        id: this.accordEtape.id,
        date: this.dateAction,
        etat: etat,
      };

      this.$apollo
        .mutate({
          mutation: MUTATION_accordEtapeValid,
          variables: {
            input: input,
          },
        })
        .then(() => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "L'étape a changé d'état",
          });
          this.$emit("fetch");
          this.$root.$emit("skeleton--close");
        });
    },
    init() {
      if (this.accordEtapeID) {
        let id = this.accordEtapeID;
        this.$apollo
          .query({
            query: QUERY_accordEtape,
            variables: { id: id },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let accordEtape = data.data.accordEtape;
            this.accordEtape = JSON.parse(JSON.stringify(accordEtape));
          });
      } else {
        this.accordEtape = JSON.parse(JSON.stringify(this.accordEtapeProp));
      }
      this.dateAction = JSON.parse(JSON.stringify(dayjs()));
    },
  },
};
</script>