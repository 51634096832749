<template>
  <FormLayout
    icon="mdi-plus-circle-multiple"
    @ajouter="ajouter"
    typeItem="des liens de service"
    :hasResetButton="false"
  >
    <v-row class="align-center">
      <v-col>
        <v-text-field
          prepend-inner-icon="mdi-numeric"
          label="Quantité de liens"
          type="number"
          v-model="input.quantite"
        >
        </v-text-field>
      </v-col>

      <v-col>
        <FournisseurSelector
          label="Reservé à un fournisseur"
          v-model="input.fournisseur"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="input.lien"
          label="Lien personnalisé"
        />
      </v-col>
    </v-row>
  </FormLayout>
</template>
<script>
import { mapState } from "vuex";

import { MUTATION_lienServicesCreate } from "./graphql/mutations";

import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";
import FournisseurSelector from "@/components/COMPONENTS-devis-facture/FournisseurSelector.vue";

export default {
  name: "LienServiceMultiForm",

  components: {
    FormLayout,
    FournisseurSelector,
  },

  data: function () {
    return {
      input: {},
    };
  },

  computed: {
    ...mapState(["formsErrors"]),
  },
  mounted: function () {
    this.init();
  },
  methods: {
    init() {
      this.input = {};
    },

    ajouter() {
       

      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_lienServicesCreate,
          variables: {
            input: toSend,
          },
        })
        .then((__) => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "Les liens de service ont été ajoutés",
          });
          this.$root.$emit("skeleton--close-dialog");
          this.$root.$emit(
            "asset--lienServices-create",
            __.data.lienServicesCreate
          );
          this.$root.$emit("skeleton--close-popup");
        });
    },

    inputConverter(arg) {
      const obj = JSON.parse(JSON.stringify(arg));

      let input = {
        quantite: parseInt(obj.quantite),
        fournisseur: obj.fournisseur,
        lien: obj.lien
      };

      obj.id != undefined ? (input.id = obj.id) : null;

      return input;
    },
  },
};
</script>