<template>
  <div>
    OAuthGoogleHandler
    {{$route.query}}
  </div>
</template>

<script>
import { QUERY_userLoginOAuthGoogle } from "./graphql/queries.js";
import { onLogin } from "@/components/COMPONENTS-skeleton/vue-apollo";

export default {
  name: "OAuthGoogleHandler",

  mounted: function() {
    // Envoi en back du code donné par Google pour récupérer l'adresse mail de l'utilisateur
    this.$apollo
      .mutate({
        mutation: QUERY_userLoginOAuthGoogle,
        variables: {
          code: this.$route.query.code
        }
      })
      .then(async (data) => {
        // On se connecter avec les tokens reçus du back
        if(data.data.userLoginOAuthGoogle) {
          await onLogin(
            this.$apollo.provider.defaultClient,
            data.data.userLoginOAuthGoogle.token,
            data.data.userLoginOAuthGoogle.refreshToken
          );
        }
        // Puis on redirige l'utilisateur à la racine
        this.$router.push({ path: "/" })
      })
  }
}
</script>