var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ListLayout',{ref:"list",attrs:{"headers":_vm.headers},on:{"fetch":_vm.init},scopedSlots:_vm._u([{key:"item.object",fn:function(ref){
var item = ref.item;
return [_c('OrganisationObject',{attrs:{"organisationProp":item,"size":"xs"}})]}},{key:"item.label",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.label)+" ")]}},{key:"item.habilitationCount",fn:function(ref){
var item = ref.item;
return [_c('HabilitationCounter',{attrs:{"customSearch":[{ organisation: item.id }]}})]}},{key:"item.updater",fn:function(ref){
var item = ref.item;
return [_c('OrganisationUpdater',{attrs:{"organisationProp":item},on:{"fetch":_vm.init}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('OrganisationActions',{attrs:{"object":item,"fetch":_vm.fetchOrganisations,"size":"xs"}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }