<template>
  <ListLayout
    ref="list"
    :headers="headers"
    title="Liste des liens de services"
    :noFilter="noFilter"
    @fetch="init"
    @showMultipleActions="showMultipleActions = $event"
  >
    <template v-slot:multipleActions>
      <LienServiceActions
        :multiple="true"
        :withView="false"
        :object="$refs.list ? $refs.list.itemsSelected : []"
        size="xl"
        v-show="showMultipleActions"
        @fetch="init"
        @loading="$refs.list.loading = true"
      />
    </template>

    <template v-slot:customFilters>
      <FournisseurSelector
        v-model="customFilters.fournisseur"
        :addAction="null"
      />
    </template>

    <template v-slot:[`item.object`]="{ item }">
      <LienServiceObject :lienServiceProp="item" size="xs" />
    </template>

    <template v-slot:[`item.asset`]="{ item }">
      <AssetObject :assetID="item.asset.id" size="xs" v-if="item.asset" />
    </template>

    <template v-slot:[`item.fournisseur`]="{ item }">
      <FournisseurObject
        :fournisseurID="item.fournisseur.id"
        size="xs"
        v-if="item.fournisseur"
      />
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <LienServiceActions
        :object="item"
        size="xs"
        @fetch="init"
        @loading="$refs.list.loading = true"
      />
    </template>
  </ListLayout>
</template>

<script>
import AssetObject from "./AssetObject.vue";
import FournisseurObject from "@/components/COMPONENTS-devis-facture/FournisseurObject.vue";
import FournisseurSelector from "@/components/COMPONENTS-devis-facture/FournisseurSelector.vue";
import ListLayout from "@/components/COMPONENTS-skeleton/Layouts/ListLayout.vue";
import LienServiceActions from "./LienServiceActions.vue";
import LienServiceObject from "./LienServiceObject.vue";
import { QUERY_lienServices } from "./graphql/queries";

export default {
  name: "LienServicesList",
  components: {
    AssetObject,
    FournisseurSelector,
    FournisseurObject,
    LienServiceActions,
    LienServiceObject,
    ListLayout,
  },
  props: {
    noFilter: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data: () => ({
    customFilters: {
      asset: null,
      fournisseur: null,
    },

    headers: [
      { text: "Lien de services", value: "object", sortable: false },
      { text: "Numéro de lien", value: "numeroLienService" },
      { text: "Asset", value: "asset" },
      { text: "Fournisseur", value: "fournisseur" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    search: null,
    showMultipleActions: false,
  }),
  methods: {
    fetchItems() {
      this.$refs.list.items = [];
      this.$refs.list.loading = true;
      let pagination = JSON.parse(JSON.stringify(this.$refs.list.pagination));

      if (this.customFilters.asset) {
        pagination.filter.customSearch.push({
          asset: this.customFilters.asset,
        });
      }

      if (this.customFilters.fournisseur) {
        pagination.filter.customSearch.push({
          fournisseur: this.customFilters.fournisseur,
        });
      }

      this.$apollo
        .query({
          query: QUERY_lienServices,
          variables: {
            pagination: pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          this.$refs.list.items = data.lienServices;
          this.$refs.list.itemsCount = data.lienServicesCount;
          this.$refs.list.loading = false;
        });
    },
    init() {
      this.fetchItems();
    },
  },

  watch: {
    customFilters: {
      deep: true,
      handler() {
        this.$refs.list.currentPage = 1;
        this.fetchItems();
      },
    },
  },
};
</script>