<template>
  <div>
    <ActionLayout
      :actions="actions"
      :size="size"
      :action="action"
      @fetch="$emit('fetch')"
      :mainIcon="mainIcon"
    />
  </div>
</template>

<script>
import ActionLayout from "@/components/COMPONENTS-skeleton/Layouts/ActionLayout.vue";

import {
  MUTATION_entrepriseDelete,
  MUTATION_entrepriseUpdate,
  MUTATION_etablissementsFromEntrepriseUpdate
} from "./graphql/mutations.js";

export default {
  name: "EntrepriseActions",
  components: {
    ActionLayout,
  },

  props: {
    withView: { type: Boolean, default: true },
    mainIcon: { type: String, default: "mdi-store" },
    object: { type: [Array, Object] },
    size: { type: String, default: "md" },
    action: { type: String, default: "router" },
    multiple: { type: Boolean, default: false },
  },

  computed: {
    actions() {
      return [
        {
          text: "Editer",
          icon: "mdi-pencil",
          path: "/annuaire/entreprises/edit/" + this.object.id,
          acl: "/annuaire/entreprises/edit",
          color: "",
          conditions: { multiple: false, isActif: true },
        },
        {
          text: "Supprimer",
          icon: "mdi-delete",
          method: "editIsActif",
          acl: "/annuaire/entreprises/deleted",
          color: "error",
          conditions: { multiple: true, isActif: true },
        },
        {
          text: "Restauration",
          icon: "mdi-restore",
          method: "editIsActif",
          acl: "/annuaire/entreprises/edit",
          color: "",
          conditions: { multiple: true, isActif: false },
        },
        {
          text: "Supprimer définitivement",
          icon: "mdi-delete",
          method: "delete",
          acl: "/annuaire/entreprises/deleted",
          color: "",
          conditions: { multiple: true, isActif: false },
        },
        {
          text: "Visualiser",
          icon: "mdi-eye",
          path: "/annuaire/entreprises/view/" + this.object.id,
          acl: "/annuaire/entreprises/view",
          color: "",
          conditions: { size: "md xs" },
        },
        {
          text: "Mettre à jour les établissements",
          icon: "mdi-refresh-auto",
          method: "updateEtablissements",
          acl: "/annuaire/entreprises/etablissements/update",
          color: "",
          conditions: { multiple: false, isActif: true },
        },
      ];
    },
  },

  /**
   * Permet d'utiliser la popup de validation
   */
  created() {
    this.$root.$on("deleteEntreprise", this.deleteEntreprise);
    this.$root.$on("editEntreprise", this.editEntreprise);
  },

  methods: {
    /**
     * Supprimer une entreprise grâce à son id
     */
    deleteEntreprise(id) {
      this.$apollo
        .mutate({
          mutation: MUTATION_entrepriseDelete,
          variables: { id: id },
        })
        .then(() => {
          this.$emit("fetch");
        });
    },

    /**
     * Fonction à avoir dans tous les composants Actions (infos à changer selon le composant)
     * Permet de lancer la popup de validation
     *
     * obj : l'objet a supprimé
     */
    delete(obj) {
      // infos qui s'afficheront dans le popup
      let toValid = {
        titre: "Suppression définitive d'une entreprise",
        texte: `Êtes-vous sûr de vouloir supprimer définitivement l'entreprise ${obj.raisonSociale} ?`,
        type: "warning",
        toReturn: {
          function: "deleteEntreprise", // cette fonction change en fonction du composant (ex: PersonneActions.vue ça serait deletePersonne,
          //qu'il faut créer à la place de deleteEntreprise)
          arg: obj.id,
        },
      };

      // Envoi l'info pour ouvrir la popup
      this.$root.$emit("popupValidation", toValid);
    },

    /**
     * Fonction qui édite une entreprise
     *
     * obj : l'objet à éditer
     */
    async editEntreprise(obj) {
      // Si obj n'est pas un tableau alors on le transforme en tableau
      if (!Array.isArray(obj)) {
        obj = [obj];
      }
      // Selon le schéma, il est obligé d'avoir le siren et la raison sociale dans EntepriseInput
      // Bien vérifier dans le schéma si jamais il y a une erreur du style "$input etc.."
      this.$emit("loading");
      for (let item of obj) {
        let entreprise = {
          id: item.id,
          isActif: !item.isActif,
          siren: item.siren,
          raisonSociale: item.raisonSociale,
        };
        await this.$apollo.mutate({
          mutation: MUTATION_entrepriseUpdate,
          variables: {
            input: entreprise,
          },
        });
      }
      this.$emit("fetch");
    },

    /**
     * Fonction à avoir dans tous les composants (infos à changer)
     */
    editIsActif(obj) {
      // Si obj n'est pas un tableau alors on le transforme en tableau
      if (!Array.isArray(obj)) {
        obj = [obj];
      }
      let toValid = {
        titre: obj[0].isActif
          ? "Suppression d'une entreprise"
          : "Restauration d'une entreprise",
        texte: obj[0].isActif
          ? `Êtes-vous sûr de vouloir supprimer ${obj.length} entreprise(s) ? ` +
            'Elle seront toujours présentes dans l\'onglet "Corbeille"'
          : `Êtes-vous sûr de vouloir restaurer ${obj.length} entreprise(s) ?`,
        type: "warning",
        toReturn: {
          function: "editEntreprise", // à changer selon le composant, comme deleteEntreprise
          arg: obj,
        },
      };

      this.$root.$emit("popupValidation", toValid);
    },

    updateEtablissements(obj) {
      this.$apollo
        .mutate({
          mutation: MUTATION_etablissementsFromEntrepriseUpdate,
          variables: {
            idEntreprise: obj.id
          },
          fetchPolicy: 'no-cache'
        })
        .then(__ => {
          let nb = __.data.etablissementsFromEntrepriseUpdate;

          this.$store.commit("addAlert", {
            type: `${nb == -1 ? 'warning' : 'info'}`,
            text: `${nb == -1 ? 'L\'entreprise choisi n\'existe pas' : nb + ' etablissements ont été rajoutés'}`,
          });
          this.$emit("fetch");
          this.$root.$emit("skeleton--close");
        })
    }
  },
};
</script>