<template>
  <v-app>
    <Alert />
    <PopupValidation />
    <div class="devTool" v-show="displayDev">
      v1.1.0031 env: <br />
      {{ $store.state.env }}
    </div>
    <template v-if="$route.meta.emptyLayout">
      <router-view />
    </template>
    <template v-else>
      <v-navigation-drawer v-model="drawer" app :width="325">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title primary--text" v-if="applicationName">
              {{ applicationName }}
            </v-list-item-title>
            <v-list-item-subtitle v-if="env['VUE_APP_VERSION_NUMBER']">
              {{ env["VUE_APP_VERSION_NUMBER"] }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider />
        <MainMenu ref="MainMenu" @setSubMenuItems="setSubMenuItems" />
      </v-navigation-drawer>

      <v-app-bar app clipped-right>
        <v-app-bar-nav-icon @click="drawer = !drawer">
          <template v-if="drawer">
            <v-icon>mdi-chevron-left</v-icon>
          </template>
        </v-app-bar-nav-icon>

        <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
        <v-spacer />
        <!-- TODO : faire un composant dédié, revoir la taille (Barre de recherche) -->
        <v-text-field append-icon="mdi-magnify" label="Recherche" single-line hide-details class="mr-6" disabled />
        <UserMeAppBar @changeOrganisation="getOrganisationParams" />
      </v-app-bar>
      <Popup :number="i - 1" v-for="i in popups.length" :key="'EnfantDe' + popups[i - 1].params.componentParent" />
      <v-main>
        <HabilitationUsurpationBanner />
        <v-sheet class="pa-md-4">
          <router-view />
        </v-sheet>
      </v-main>
      <MainSubMenu ref="SubMenu" />
    </template>
  </v-app>
</template>

<script>
import Alert from "@/components/COMPONENTS-skeleton/Alert";
import HabilitationUsurpationBanner from "@/components/COMPONENTS-authentification-habilitation/HabilitationUsurpationBanner";
import MainMenu from "@/components/COMPONENTS-skeleton/MainMenu";
import MainSubMenu from "@/components/COMPONENTS-skeleton/MainSubMenu";
import Popup from "@/components/COMPONENTS-skeleton/Popup.vue";
import PopupValidation from "@/components/COMPONENTS-skeleton/PopupValidation";
import UserMeAppBar from "@/components/COMPONENTS-authentification-habilitation/UserMeAppBar";

import { mapState } from "vuex";

import { QUERY_authentificationHabilitationOrganisationParametres, QUERY_systemContext } from "@/components/COMPONENTS-authentification-habilitation/graphql/queries";

export default {
  components: {
    Alert,
    HabilitationUsurpationBanner,
    MainMenu,
    MainSubMenu,
    Popup,
    PopupValidation,
    UserMeAppBar,
  },

  data: () => ({
    displayDev: false,
    drawer: false,
    keyDevCount: 0,
    organisationParams: {}
  }),

  created() {
    this.$root.$on("skeleton--openMenu", this.openMenu);
    this.$root.$on("skeleton--close", this.closePopup);
  },

  computed: {
    ...mapState(["popups", "env"]),

    applicationName() {
      return this.organisationParams.applicationName ? this.organisationParams.applicationName : this.env["VUE_APP_PROJECT_NAME"]
    },
  },

  methods: {
    closePopup() {
      this.$store.commit("resetPopup");
    },
    init() {
      // On récupère le contexte d'éxecution du Frontend afin de convertir les variables d'environnements
      // accessibles uniquement par le backend et de les exploiter en Frontend
      // Par exemple: récupèrer informations Oauth, clé API publiques, Nom du projet, version ...
      this.$apollo
        .query({
          query: QUERY_systemContext,
        })
        .then((data) => {
          this.$store.commit("setEnv", data.data.systemContext);
          this.$gmapOptions.load = {
            key: this.env["VUE_APP_GOOGLE_API_KEY"],
          };
        });
      window.addEventListener("keypress", (e) => {
        if (e.shiftKey && e.code == "Backquote") {
          this.keyDevCount++;
          if (this.keyDevCount > 3) {

            this.displayDev = !this.displayDev;
          }
        }
      });

      this.$store.commit('setColorDefaultLight', this.$vuetify.theme.themes.light.primary);
      this.$store.commit('setColorDefaultDark', this.$vuetify.theme.themes.dark.primary);

      this.getOrganisationParams();
    },
    getOrganisationParams() {
      this.$apollo
        .query({
          query: QUERY_authentificationHabilitationOrganisationParametres,
          fetchPolicy: 'no-cache'
        })
        .then(__ => {
          this.organisationParams = __.data.authentificationHabilitationOrganisationParametres;
        })
    },
    openMenu() {
      this.drawer = true;
    },
    setSubMenuItems(item) {
      this.$refs.SubMenu.item = item;
    },
  },

  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.devTool {
  font-family: monospace;
  position: absolute;
  z-index: 100;
  background-color: black;
  color: green;
  width: 100%;
}
</style>