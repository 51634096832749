<template>
  <ListLayout
    ref="list"
    :headers="headers"
    @fetch="init"
    @showMultipleActions="showMultipleActions = $event"
    title="Liste des personnes"
    :noFilter="noFilter"
  >
    <template v-slot:multipleActions>
      <PersonneActions
        :multiple="true"
        :object="$refs.list ? $refs.list.itemsSelected : []"
        size="xl"
        v-show="showMultipleActions"
        @fetch="init"
        @loading="$refs.list.loading = true"
      />
    </template>

    <template v-slot:customFilters>
      <EntrepriseSelector
        :addAction="null"
        v-model="customFilters.entreprise"
      />
      <EtablissementSelector
        :addAction="null"
        :disabled="customFilters.entreprise ? false : true"
        :entreprise="customFilters.entreprise"
        v-model="customFilters.etablissement"
      />
    </template>

    <template v-slot:[`item.avatar`]="{ item }">
      <PersonneObject :personneProp="item" size="xs" />
    </template>
    <template v-slot:[`item.associatedUser`]="{ item }">
      <UserObject
        :user="item.associatedUser"
        v-if="item.associatedUser"
        size="xs-justUser"
      />
    </template>

    <template v-slot:[`item.fonctions`]="{ item }">
      <FonctionObject
        :key="'fonction' + index"
        :fonction="fonction"
        size="xs"
        v-for="(fonction, index) in item.fonctions"
      />
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <PersonneActions
        :object="item"
        size="xs"
        @fetch="init"
        @loading="$refs.list.loading = true"
      />
    </template>
  </ListLayout>
</template>

<script>
import { QUERY_personnes } from "./graphql/queries";

import EntrepriseSelector from "./EntrepriseSelector.vue";
import EtablissementSelector from "./EtablissementSelector.vue";
import FonctionObject from "./FonctionObject.vue";
import ListLayout from "@/components/COMPONENTS-skeleton/Layouts/ListLayout.vue";
import PersonneActions from "./PersonneActions.vue";
import PersonneObject from "./PersonneObject";
import UserObject from "@/components/COMPONENTS-authentification-habilitation/UserObject.vue";

export default {
  name: "PersonnesList",

  components: {
    EntrepriseSelector,
    EtablissementSelector,
    FonctionObject,
    ListLayout,
    PersonneActions,
    PersonneObject,
    UserObject,
  },

  props: {
    etablissementID: null,
    customSearch: {
      type: Array,
      default() {
        return undefined;
      },
    },
    noFilter: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },

  data: () => ({
    customFilters: {
      entreprise: null,
      etablissement: null,
    },

    openDialogEditPersonne: false,
    contactToEdit: null,
    showMultipleActions: false,
    // Recherche tapée par l'utilisateur dans le champ "Recherche"
    search: null,

    // Les colonnes ainsi que leur nom, id et options
    headers: [
      { text: "", value: "avatar", sortable: false },
      { text: "Nom", value: "nom" },
      { text: "Prénom", value: "prenom" },
      { text: "Fonctions", value: "fonctions" },
      { text: "Fichiers", value: "fichiers" },
      { text: "Commentaires", value: "commentaires" },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),

  methods: {
    fetchPersonnes() {
       
      this.$refs.list.items = [];
      this.$refs.list.loading = true;
      let pagination = JSON.parse(JSON.stringify(this.$refs.list.pagination));

      if (this.etablissementID) {
        pagination.filter.customSearch.push({
          "fonctions.etablissement": this.etablissementID,
        });
      } else if (this.customFilters.etablissement) {
        pagination.filter.customSearch.push({
          "fonctions.etablissement": this.customFilters.etablissement,
        });
      } else if (this.customFilters.entreprise) {
        pagination.filter.customSearch.push({
          "fonctions.entreprise": this.customFilters.entreprise,
        });
      }
       
      this.$apollo
        .query({
          query: QUERY_personnes,
          variables: {
            pagination: pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          this.$refs.list.items = data.personnes;
          this.$refs.list.itemsCount = data.personnesCount;
          this.$refs.list.loading = false;
        });
    },
    init() {
      this.fetchPersonnes();
    },
  },

  watch: {
    customFilters: {
      deep: true,
      handler() {
         
        this.$refs.list.currentPage = 1;
        this.fetchPersonnes();
      },
    },
    entrepriseID() {
      this.fetchPersonnes();
    },
  },
};
</script>

