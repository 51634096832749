<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :disabled="disabled"
        v-model="dateFormatted"
        :error-messages="errorMessages"
        :label="label"
        :hint="hint + ' format DD/MM/YYYY'"
        :persistent-hint="persistentHint"
        :prepend-icon="prependIcon"
        v-bind="attrs"
        v-on="on"
        clearable
        maxlength="10"
        @click:clear="clearEvent()"
        :error="textFieldError"
      />
    </template>
    <v-date-picker
      v-model="datePiker"
      @input="menu = false"
      locale="fr"
      :min="min"
      :max="max"
      :typeable="true"
      :allowed-dates="allowed_dates"
      @change="pickerChange"
    />
  </v-menu>
</template>

<script>
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";

export default {
  name: "DateInput",

  props: {
    allowed_dates: { type: Function, default: () => true },
    disabled: { type: Boolean, default: false },
    errorMessages: { type: Array },
    hint: { type: String, default: "" },
    label: {
      type: String,
      default: "Date",
    },
    min: { type: String, default: "1900-01-01" },
    max: { type: String, default: "2099-12-31" },
    persistentHint: { type: Boolean, default: false },
    prependIcon: { type: String, default: "mdi-calendar" },
    value: { type: String, default: "" },
  },
  data: () => ({
    textFieldError: false,
    date: null,
    menu: false,
    dateFormatted: "",
    datePiker: "",
  }),
  watch: {
    dateFormatted: function (val) {
      if (val) {
        if (!((val.length == 3 || val.length == 6) && val.slice(-1) == "/")) {
          // Empêche la saisie de caractères autre que des nombres
          if (isNaN(val.slice(-1))) {
            this.$nextTick(() => {
              this.dateFormatted = val.slice(0, -1);
            });
          }
          // Ajout automatique des "/"
          if (val.length == 2 || val.length == 5) {
            this.dateFormatted = val + "/";
          }
        }
        if (val.length == 10) {
          // Vérifie si la date est valide et l'envoie au picker
          dayjs.extend(customParseFormat);
          let date = dayjs(val.toString(), "DD/MM/YYYY", "fr", true);
          if (date.isValid()) {
            this.textFieldError = false;
            this.datePiker = date.format("YYYY-MM-DD");
            this.handleInput();
          } else {
            // passe le field en erreur si invalid
            this.textFieldError = true;
          }
        }
      }
    },
    // Permet de récupérer la valeur de la props
    value: function () {
      this.init();
    },
  },
  mounted: function () {
    this.init();
  },
  methods: {
    init() {
      if (this.value) {
        this.dateFormatted = dayjs(this.value).format("DD/MM/YYYY");
        this.datePiker = dayjs(this.value).format("YYYY-MM-DD");
      } else {
        this.dateFormatted = "";
      }
    },
    //Envoie les données au composant parent (v-model)
    handleInput() {
      dayjs.extend(customParseFormat);
      let date = dayjs(this.dateFormatted, "DD/MM/YYYY", "fr", true);
      this.$emit("input", date.format("YYYY-MM-DD"));
    },
    pickerChange(date) {
      this.dateFormatted = dayjs(date).format("DD/MM/YYYY");
    },
    clearEvent: function () {
      this.$emit("input", null);
    },
  },
};
</script>