var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ListLayout',{ref:"list",attrs:{"headers":_vm.headers,"title":_vm.title,"noFilter":_vm.noFilter},on:{"fetch":_vm.init,"showMultipleActions":function($event){_vm.showMultipleActions = $event}},scopedSlots:_vm._u([{key:"multipleActions",fn:function(){return [_c('FactureClientActions',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMultipleActions),expression:"showMultipleActions"}],attrs:{"multiple":true,"withView":false,"object":_vm.$refs.list ? _vm.$refs.list.itemsSelected : [],"size":"xl"},on:{"fetch":_vm.init,"loading":function($event){_vm.$refs.list.loading = true}}})]},proxy:true},{key:"customFilters",fn:function(){return [_c('ClientSelector',{attrs:{"addAction":null},model:{value:(_vm.customFilters.client),callback:function ($$v) {_vm.$set(_vm.customFilters, "client", $$v)},expression:"customFilters.client"}})]},proxy:true},_vm._l((_vm.$scopedSlots),function(index,name){return {key:name,fn:function(data){return [_vm._t(name,null,null,data)]}}}),{key:"item.object",fn:function(ref){
var item = ref.item;
return [_c('FactureClientObject',{attrs:{"factureClientProp":item,"size":"xs"}})]}},{key:"item.dateFacture",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("shortDateFR")(item.dateFacture))+" ")]}},{key:"item.dateEcheance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("shortDateFR")(item.dateEcheance))+" ")]}},{key:"item.client",fn:function(ref){
var item = ref.item;
return [(item.client.id)?_c('ClientObject',{attrs:{"clientID":item.client.id,"size":"xs","action":"popup"}}):_vm._e()]}},{key:"item.fournisseur",fn:function(ref){
var item = ref.item;
return [(item.fournisseur)?_c('FournisseurObject',{attrs:{"fournisseurID":item.fournisseur.id,"size":"xs","action":"popup"}}):_vm._e()]}},{key:"item.regle",fn:function(ref){
var item = ref.item;
return [(item.valide)?[(item.regle)?[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"success","text-color":"white"}},[_vm._v(" Facture réglée ")])]:_vm._e(),(!item.regle && _vm.getRetardReglement(item) > 0)?[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"warning","text-color":"white"}},[_vm._v(" Facture non réglée ")])]:_vm._e(),(!item.regle && _vm.getRetardReglement(item) < 0)?[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"error","text-color":"white"}},[_vm._v(" Règlement en retard "),_c('b',{staticClass:"pl-2"},[_vm._v(_vm._s(-_vm.getRetardReglement(item))+" j")])])]:_vm._e()]:_vm._e()]}},{key:"item.montantHT",fn:function(ref){
var item = ref.item;
return [_c('MoneyLayout',{attrs:{"montant":item.montantHT}})]}},{key:"item.montantTVA",fn:function(ref){
var item = ref.item;
return [_c('MoneyLayout',{attrs:{"montant":item.montantTVA}})]}},{key:"item.devisAssocie",fn:function(ref){
var item = ref.item;
return [(item.devisAssocie && item.devisAssocie.id)?_c('DevisClientObject',{attrs:{"size":"xs","devisClientID":item.devisAssocie.id}}):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('FactureClientActions',{attrs:{"object":item,"size":"xs"},on:{"fetch":_vm.init,"loading":function($event){_vm.$refs.list.loading = true}}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }