<template>
  <div>
    <template v-if="pointFacturation">
      <template v-if="size == 'xl'">
        <ObjectLayout :action="action" size="xl" :item="pointFacturation">
          <template v-slot:icon>mdi-wallet</template>
          <template v-slot:titre> {{ pointFacturation.label }}</template>
          <template v-slot:body>
            <ObjectLayout v-if="pointFacturation.label" class="py-2">
              <template v-slot:icon>mdi-tag-text</template>
              <template v-slot:titre>
                Dénomination du point de facturation
              </template>
              <template v-slot:content>{{ pointFacturation.label }}</template>
            </ObjectLayout>
            <ObjectLayout v-if="pointFacturation.adresseFacturation && pointFacturation.adresseFacturation.codePostal" class="py-2">
              <template v-slot:titre>
                Adresse de facturation
              </template>
              <template v-slot:content>
                <AdressePostaleObject 
                  size="xs"
                  :adresse="pointFacturation.adresseFacturation"
                />
              </template>
            </ObjectLayout>
          </template>
        </ObjectLayout>
      </template>
      <template v-if="size == 'md'">
        <ObjectLayout size="md">
          <template v-slot:icon>mdi-wallet</template>
          <template v-slot:content>{{ pointFacturation.label }}</template>
          <template v-slot:extracontent>
            <v-icon small class="mr-2">mdi-material-design</v-icon>
            {{ pointFacturation.label }}
          </template>
        </ObjectLayout>
      </template>
      <template v-if="size == 'xs'">
        <ObjectLayout
          size="xs"
          :action="action"
          :onClickPush="`/devis-facture/point-facturations/view/${pointFacturation.id}`"
        >
          <template v-slot:icon>mdi-wallet</template>
          <template v-slot:content>
            {{ pointFacturation.label }}
          </template>
          <template v-slot:extracontent>
            {{ pointFacturation.client.codeClient }}
          </template>
        </ObjectLayout>
      </template>
    </template>
  </div>
</template>

<script>
import { QUERY_pointFacturation } from "./graphql/queries";

import AdressePostaleObject from "@/components/COMPONENTS-annuaire/AdressePostaleObject.vue";
import ObjectLayout from "@/components/COMPONENTS-skeleton/Layouts/ObjectLayout.vue";

export default {
  name: "PointFacturationObject",

  components: {
    AdressePostaleObject,
    ObjectLayout,
  },

  props: {
    action: {
      type: String,
      default: "router",
    },

    pointFacturationProp: {
      type: Object,
      default() {
        return {};
      },
    },

    pointFacturationID: {
      type: String,
    },

    size: { type: String, default: "md" },
  },

  data: () => ({
    pointFacturation: undefined,
  }),

  watch: {
    pointFacturationID: function () {
      this.init();
    },
    pointFacturationProp: function () {
      this.init();
    },
  },

  mounted: function () {
    this.init();
  },

  methods: {
    init() {
      if (this.pointFacturationID) {
        let id = this.pointFacturationID;
        this.$apollo
          .query({
            query: QUERY_pointFacturation,
            variables: { id: id },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let pointFacturation = data.data.pointFacturation;
            this.pointFacturation = JSON.parse(
              JSON.stringify(pointFacturation)
            );
          });
      } else {
        this.pointFacturation = JSON.parse(
          JSON.stringify(this.pointFacturationProp)
        );
      }
    },
  },
};
</script>