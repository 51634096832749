var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"align":"center","justify":"center"}},[_c('div',{staticClass:"text-body1 text-xl-h6 text-lg-h6 text-md-h6 text-sm-h6"},[_vm._v(" Capital à financer ")]),_c('div',{staticClass:"\n          text-h5 text-xl-h4 text-lg-h4 text-md-h4 text-sm-h4\n          primary--text\n        "},[_vm._v(" "+_vm._s(_vm._f("monetaire")(_vm.capitalTotal))+" H.T. ")])]),_c('v-col',{attrs:{"align":"center","justify":"center"}},[_c('div',{staticClass:"text-body1 text-xl-h6 text-lg-h6 text-md-h6 text-sm-h6"},[_vm._v(" T.V.A. à financer ")]),_c('div',{staticClass:"\n          text-h5 text-xl-h4 text-lg-h4 text-md-h4 text-sm-h4\n          primary--text\n        "},[_vm._v(" "+_vm._s(_vm._f("monetaire")(_vm.tvaTotale))+" ")])])],1),_c('v-row',[_c('v-col',{attrs:{"align":"center","justify":"center"}},[_c('div',{staticClass:"text-h6 blue-grey--text py-2"},[_vm._v(" Répartition du capital à financer ")]),_c('v-data-table',{attrs:{"dense":"","headers":_vm.headersCapitaux,"items":_vm.capitaux,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.client",fn:function(ref){
var item = ref.item;
return [_c('ClientObject',{attrs:{"clientID":item.client.id,"size":"xs"}})]}},{key:"item.capital",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("monetaire")(item.capital))+" ")]}},{key:"item.tva",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("monetaire")(item.tva))+" ")]}},{key:"item.pourcent",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("pourcent")((item.capital / _vm.capitalTotal)))+" ")])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }