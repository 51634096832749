<template>
  <div>
    <template v-if="famille">
      {{ familleID }}
      <template v-if="size == 'xl'">
        <ObjectLayout :action="action" size="xl" :item="famille">
          <template v-slot:icon>mdi-package-variant-closed</template>
          <template v-slot:titre> {{ famille.label }}</template>
          <template v-slot:body>
            <ObjectLayout v-if="famille.label" class="py-2">
              <template v-slot:icon>mdi-tag-text</template>
              <template v-slot:titre>Dénomination de la famille</template>
              <template v-slot:content>{{ famille.label }}</template>
            </ObjectLayout>

            <ObjectLayout
              v-if="famille.parent"
              class="py-2"
            >
              <template v-slot:icon>mdi-material-design</template>
              <template v-slot:titre>Parent</template>
              <template v-slot:content>
                 {{ famille.parent.id }}
                <FamilleObject
                  size="xs" 
                  :familleId="famille.parent.id" 
                />
              </template>
            </ObjectLayout>
          </template>
        </ObjectLayout>
      </template>
      <template v-if="size == 'md'">
        <ObjectLayout size="md">
          <template v-slot:icon>mdi-package-variant-closed</template>
          <template v-slot:content>{{ famille.label }}</template>
          <template v-slot:extracontent>
            <v-icon small class="mr-2">mdi-material-design</v-icon>
            {{ famille.label }}
          </template>
        </ObjectLayout>
      </template>
      <template v-if="size == 'xs'">
        <ObjectLayout
          size="xs"
          :action="action"
          :onClickPush="`/assets/familles/view/${famille.id}`"
        >
          <template v-slot:icon>mdi-family-tree</template>
          <template v-slot:content>
            {{ famille.label }}
          </template>
          <template v-slot:tooltip v-if="tooltip">
            <template v-if="famille.label">
              <b class="font-weight-bold">Label:</b>
              {{ famille.label }} <br />
            </template>
          </template>
        </ObjectLayout>
      </template>
    </template>
  </div>
</template>

<script>
import { QUERY_famille } from "./graphql/queries";

import ObjectLayout from "@/components/COMPONENTS-skeleton/Layouts/ObjectLayout.vue";

export default {
  name: "FamilleObject",

  components: {
    ObjectLayout,
  },

  props: {
    action: {
      type: String,
      default: "router",
    },

    familleID: {
      type: String,
    },

    familleProp: {
      type: Object,
      default() {
        return {};
      },
    },

    size: { type: String, default: "md" },

    styleXs: {
      type: String,
      default: undefined
    },

    tooltip: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    famille: undefined,
  }),

  watch: {
    familleProp: function () {
      this.init();
    },
    familleID: function () {
      this.init();
    },
  },

  mounted: function () {
    this.init();
  },

  methods: {
    init() {
      console.log("fmailleID", this.familleID)
      if (this.familleID) {
        let id = this.familleID;
        this.$apollo
          .query({
            query: QUERY_famille,
            variables: { id: id },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let famille = data.data.famille;
            this.famille = JSON.parse(JSON.stringify(famille));
          });
      } else {
        this.famille = JSON.parse(JSON.stringify(this.familleProp));
      }
    },
  },
};
</script>