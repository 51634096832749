<template>
  <v-container>
    <template v-if="objFichier.type != ''">
      <FichierVideoPlayer
        v-if="objFichier.type == 'video/mp4' && urlSource"
        :urlSource="urlSource"
      />
      <FichierPDFPlayer
        v-if="objFichier.type == 'application/pdf' && urlSource"
        :urlSource="urlSource"
      />
    </template>
  </v-container>
</template>

<script>
import { MUTATION_downloadURLCreate } from "./graphql/mutations";
import FichierPDFPlayer from "./FichierPDFPlayer.vue";
import FichierVideoPlayer from "./FichierVideoPlayer.vue";

export default {
  name: "FichierPlayer",

  components: {
    FichierPDFPlayer,
    FichierVideoPlayer
  },
  props: {
    objFichier: { type: Object, default: undefined },
  },
  data: () => ({
    urlSource: undefined,
  }),
  mounted() {
    if (this.objFichier.id) this.getFichierUrl(this.objFichier.id);
  },
  computed: {},
  methods: {
    getFichierUrl(id) {
       
      this.$apollo
        .mutate({
          mutation: MUTATION_downloadURLCreate,
          variables: {
            id: id,
          },
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          this.urlSource = data.data.downloadURLCreate.url;
        });
    },
  },
  watch: {
    objFichier(val) {
      if (val.id) {
        this.getFichierUrl(val.id);
      }
    },
  },
};
</script>