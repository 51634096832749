var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ListLayout',{ref:"list",attrs:{"headers":_vm.headers,"title":"Liste des liens de services","noFilter":_vm.noFilter},on:{"fetch":_vm.init,"showMultipleActions":function($event){_vm.showMultipleActions = $event}},scopedSlots:_vm._u([{key:"multipleActions",fn:function(){return [_c('LienServiceActions',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMultipleActions),expression:"showMultipleActions"}],attrs:{"multiple":true,"withView":false,"object":_vm.$refs.list ? _vm.$refs.list.itemsSelected : [],"size":"xl"},on:{"fetch":_vm.init,"loading":function($event){_vm.$refs.list.loading = true}}})]},proxy:true},{key:"customFilters",fn:function(){return [_c('FournisseurSelector',{attrs:{"addAction":null},model:{value:(_vm.customFilters.fournisseur),callback:function ($$v) {_vm.$set(_vm.customFilters, "fournisseur", $$v)},expression:"customFilters.fournisseur"}})]},proxy:true},{key:"item.object",fn:function(ref){
var item = ref.item;
return [_c('LienServiceObject',{attrs:{"lienServiceProp":item,"size":"xs"}})]}},{key:"item.asset",fn:function(ref){
var item = ref.item;
return [(item.asset)?_c('AssetObject',{attrs:{"assetID":item.asset.id,"size":"xs"}}):_vm._e()]}},{key:"item.fournisseur",fn:function(ref){
var item = ref.item;
return [(item.fournisseur)?_c('FournisseurObject',{attrs:{"fournisseurID":item.fournisseur.id,"size":"xs"}}):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('LienServiceActions',{attrs:{"object":item,"size":"xs"},on:{"fetch":_vm.init,"loading":function($event){_vm.$refs.list.loading = true}}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }