<template>
  <ViewLayout :tabs="tabs" :item="client">
    <template v-slot:client>
      <ClientActions :object="client" size="xl" />

      <v-row class="align-center mx-auto">
        <v-col cols="12">
          <ClientObject :clientProp="client" size="xl" />
        </v-col>

        <v-col cols="12">
          <PointFacturationsList
            v-if="client.id"
            :customSearch="[{ client: client.id }]"
          />
        </v-col>
      </v-row>
      <slot></slot>
    </template>

    <template v-slot:devis>
      <v-row class="align-center mx-auto">
        <v-col cols="12">
          <DevisClientsList
            v-if="client.id"
            :customSearch="[{ client: client.id }]"
            :noFilter="true"
            :title="'Liste des devis de ' + client.codeClient"
          />
        </v-col>
      </v-row>
    </template>

    <template v-slot:factures>
      <v-row class="align-center mx-auto">
        <v-col cols="12">
          <FactureClientsList
            v-if="client.id"
            :customSearch="[{ client: client.id }]"
            :noFilter="true"
            :title="'Liste des factures de ' + client.codeClient"
          />
        </v-col>
      </v-row>
    </template>
  </ViewLayout>
</template>

<script>
import { QUERY_client } from "./graphql/queries";

import ClientActions from "./ClientActions.vue";
import ClientObject from "./ClientObject.vue";
import DevisClientsList from "./DevisClientsList.vue";
import FactureClientsList from "./FactureClientsList.vue";
import PointFacturationsList from "./PointFacturationsList.vue";
import ViewLayout from "@/components/COMPONENTS-skeleton/Layouts/ViewLayout.vue";

export default {
  name: "ClientView",

  components: {
    ClientObject,
    ClientActions,
    DevisClientsList,
    FactureClientsList,
    PointFacturationsList,
    ViewLayout,
  },

  data: () => ({
    client: {},
    tabs: [
      { icon: "mdi-briefcase-account", displayName: "Client", name: "client" },
      { icon: "mdi-file-cad", displayName: "Devis", name: "devis" },
      { icon: "mdi-file-table", displayName: "Factures", name: "factures" },
      { name: "commentaires" },
      { name: "fichiers" },
    ],
  }),

  apollo: {
    client: {
      query: QUERY_client,
      variables() {
        let idQuery = this.showPopup
          ? this.id
          : this.$route.params.id
          ? this.$route.params.id
          : this.id;
        return {
          id: idQuery,
        };
      },
    },
  },
};
</script>