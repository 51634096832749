<template>
  <FormLayout
    icon="mdi-handshake"
    @ajouter="ajouter"
    buttonText="Etape suivante"
    buttonIcon="mdi-arrow-right-bold-circle"
    @modifier="modifier"
    @reinitialiser="init"
    :hasResetButton="hasResetButton"
    typeItem="un accord"
    :item="input"
  >
    <v-row>
      <v-col>
        <TitleLayout titre="Informations administratives" />
        <v-row>
          <v-col>
            <v-select
              :items="typeList"
              v-model="input.type"
              label="Type d'accord"
              prepend-icon="mdi-draw-pen"
              class="field-required"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="input.objet"
              label="Objet"
              prepend-icon="mdi-chevron-right"
              class="field-required"
            />
          </v-col>
          <v-col>
            <DateInput
              v-model="input.dateExpiration"
              label="Date d'expiration"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-textarea
              v-model="input.message"
              label="Message"
              prepend-icon="mdi-message-text"
            />
          </v-col>
          <v-col></v-col>
        </v-row>
      </v-col>
      <v-col>
        <TitleLayout titre="Etapes de l'accord" />
        <AccordEtapeAttach v-model="input.etapes" :type-accord="input.type" />
      </v-col>
    </v-row>
  </FormLayout>
</template>

<script>
import { mapState } from "vuex";

import {
  MUTATION_accordCreate,
  MUTATION_accordUpdate,
} from "./graphql/mutations";
import { QUERY_accord } from "./graphql/queries";

import AccordEtapeAttach from "./AccordEtapeAttach.vue";
import DateInput from "@/components/COMPONENTS-skeleton/Inputs/DateInput";

import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";
import TitleLayout from "@/components/COMPONENTS-skeleton/Layouts/TitleLayout.vue";

export default {
  name: "AccordForm",

  components: {
    AccordEtapeAttach,
    DateInput,
    FormLayout,
    TitleLayout,
  },

  props: {
    hasResetButton: { type: Boolean, default: true },
    disposition: { type: String, default: "default" },
    componentParent: undefined,
  },

  data: () => ({
    accord: {
      id: undefined,
      etapes: [
        {
          destinataire: {
            nom: "",
            email: "",
            personneAssociee: null,
          },
          nextAnd: true,
        },
      ],
      type: "ACCORD_DOCUSIGN",
    },
    input: {},
    typeList: [
      {
        text: "Docusign",
        value: "ACCORD_DOCUSIGN",
      },
    ],
  }),

  computed: {
    ...mapState(["formsErrors"]),
  },

  mounted: function () {
    this.init();
  },

  methods: {
    ajouter() {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_accordCreate,
          variables: {
            input: toSend,
          },
        })
        .then((__) => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "Votre Accord a été ajouté",
          });

          if (this.componentParent) {
            this.$root.$emit(this.componentParent, __.data.accordCreate);
          }

          this.$root.$emit("skeleton--close");
          this.init();
        });
    },

    init() {
      if (
        this.$route.path.startsWith("/accord-validation/accords/edit/") &&
        this.$route.params.id
      ) {
        let id = this.$route.params.id;
        this.$apollo
          .query({
            query: QUERY_accord,
            variables: {
              id: id,
            },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let accord = data.data.accord;
            if (accord.etapes) {
              accord.etapes = accord.etapes.sort(function (a, b) {
                return a.position - b.position;
              });
            }
            this.input = JSON.parse(JSON.stringify(accord));
          });
      } else {
        // si on est dans le cas d'une reprise d'un accord existant pour créer celui ci
        if (this.$route.query.fromAccord) {
          let id = this.$route.query.fromAccord;
          this.$apollo
            .query({
              query: QUERY_accord,
              variables: {
                id: id,
              },
              fetchPolicy: "no-cache",
            })
            .then((data) => {
               
              let ancienAccord = data.data.accord;
              ancienAccord.id = null;

               

              this.input = JSON.parse(JSON.stringify(ancienAccord));
            });
        } else {
          this.input = JSON.parse(JSON.stringify(this.accord));
        }
      }
    },

    inputConverter(arg) {
      const obj = JSON.parse(JSON.stringify(arg));

      let input = {};
      obj.id != undefined ? (input.id = obj.id) : null;
      let etapes = [];
      for (let [index, objEtape] of Object.entries(obj.etapes)) {
        let etape = {
          type: objEtape.type,
          destinataire: {
            personneAssociee:
              objEtape.destinataire && objEtape.destinataire.personneAssociee
                ? objEtape.destinataire.personneAssociee
                : null,
            nom:
              objEtape.destinataire && objEtape.destinataire.nom
                ? objEtape.destinataire.nom
                : null,
            email:
              objEtape.destinataire && objEtape.destinataire.email
                ? objEtape.destinataire.email
                : null,
          },
          message: objEtape.message,
          position: parseInt(index),
          nextAnd: objEtape.nextAnd,
        };
        etapes.push(etape);
      }

      input.dateExpiration = obj.dateExpiration;
      input.etapes = etapes;
      input.objet = obj.objet;
      input.message = obj.message;
      input.type = obj.type;

      return input;
    },

    modifier() {
      let toSend = this.inputConverter(this.input);

      this.$apollo
        .mutate({
          mutation: MUTATION_accordUpdate,
          variables: {
            input: toSend,
          },
        })
        .then(() => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "Accord a été modifié",
          });
          this.$root.$emit("skeleton--close");
        });
    },
  },
};
</script>