<template>
  <CounterLayout :compteur="emailTemplatesCount">
    <template v-slot:icon>mdi-email-newsletter</template>
  </CounterLayout>
</template>

<script>
import { QUERY_emailTemplatesCount } from "./graphql/queries";

import CounterLayout from "@/components/COMPONENTS-skeleton/Layouts/CounterLayout.vue";

export default {
  name: "EmailTemplatesCounter",

  components: {
    CounterLayout,
  },

  props: {
    customSearch: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  apollo: {
    emailTemplatesCount: {
      query: QUERY_emailTemplatesCount,
      variables() {
        return {
          pagination: {
            filter: {
              customSearch: this.customSearch,
            },
          },
        };
      },
      fetchPolicy: "no-cache",
    },
  },

  created() {
    this.$root.$on("envoiNotificationUpdateEmailTemplate", this.updateQuery);
  },

  methods: {
    updateQuery: function () {
      this.$apollo.queries.emailTemplatesCount.refetch();
    },
  },
};
</script>