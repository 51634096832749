<template>
  <FormLayout
    icon="mdi-file-sign"
    @ajouter="ajouter"
    @modifier="modifier"
    @reinitialiser="init"
    typeItem="un contrat de location"
    :item="input"
    :disabled="false"
  >
    <TitleLayout titre="Informations administratives" />
    <v-row>
      <v-col cols="12" xl="6" lg="6" md="6" sm="6">
        <v-row>
          <v-col>
            <v-text-field
              v-model="input.numeroContrat"
              label="Numéro de contrat"
              hint="Le numéro de contrat est automatiquement généré, à saisir uniquement en cas de reprise de données"
              persistent-hint
              prepend-icon="mdi-counter"
              :disabled="!numeroContratInput"
              @focus="currentlyActiveField = 'numeroContrat'"
              @blur="currentlyActiveField = ''"
            />
          </v-col>
          <v-col cols="2">
            <v-switch v-model="numeroContratInput" />
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-row>
          <v-col>
            <AffaireSelector
              v-model="input.affaire"
              :addAction="null"
              :disabled="input.id ? true : false"
            />
          </v-col>
          <v-col cols="2">
            <v-btn
              icon
              color="primary"
              :disabled="input.affaire ? false : true"
              @click="repriseDataFromAffaire(input.affaire)"
            >
              <v-icon>mdi-auto-fix</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xl="6" lg="6" md="6" sm="6">
        <ClientSelector
          v-model="input.locataire"
          label="Locataire (siège)"
          :isSiege="true"
        />
      </v-col>
      <v-col cols="12" xl="6" lg="6" md="6" sm="6">
        <PersonneSelector
          v-model="input.signataireClient"
          label="Signataire"
          prepend-icon="mdi-fountain-pen-tip"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <DateInput
          v-model="input.datePrevInstallation"
          label="Date prévisionnelle d'installation"
        />
      </v-col>
    </v-row>
    <TitleLayout titre="Devis et factures founisseurs à financer" />
    <FinancementCapitalAttach
      @capitalAfinancer="setInputFinancementCapitalAfinancer"
      @capitaux="setInputFinancementRepartition"
      :devisFournisseurs="
        $refs.devisFournisseurAttach
          ? $refs.devisFournisseurAttach.devisFournisseurs
          : []
      "
      :factureFournisseurs="
        $refs.factureFournisseurAttach
          ? $refs.factureFournisseurAttach.factureFournisseurs
          : []
      "
    />

    <v-row>
      <v-col cols="12">
        <DevisFournisseurAttach
          ref="devisFournisseurAttach"
          v-model="input.devisFournisseurs"
        />
      </v-col>
      <v-col cols="12">
        <FactureFournisseurAttach
          ref="factureFournisseurAttach"
          v-model="input.facturesFournisseurs"
        />
      </v-col>
    </v-row>
    <TitleLayout titre="Informations financières" />
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="input.fraisDossier"
          type="number"
          label="Frais de dossier"
          persistent-hint
          suffix="€"
          prepend-icon="mdi-cash"
          append-icon="mdi-calculator"
          @click:append="calcFraisDossier"
        />

        <FinancementForm
          v-model="input.financement"
          :datePrevInstallation="input.datePrevInstallation"
          ref="financementForm"
        />
      </v-col>
    </v-row>
    <TitleLayout titre="Gestion de la facturation" />
    <ContratLocationFacturationForm
      v-model="input.facturation"
      :contratLocation="input"
      ref="contratLocationFacturationForm"
    />
    <TitleLayout titre="Gestion de la cession" />
    <v-row>
      <v-col>
        <v-row>
          <v-col>
            <BailleurSelector
              v-model="input.bailleur"
              hint="Le bailleur est automatiquement repris de la demande d'accord, à utiliser dans le cas où il n'y a pas de demande d'accord."
              :disabled="!bailleurInput"
            />
          </v-col>
          <v-col cols="2">
            <v-switch
              v-model="bailleurInput"
              @change="input.demandeAccord = null"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <DemandeAccordSelector
          v-model="input.demandeAccord"
          :disabled="bailleurInput"
          @change="setBailleur($event)"
          :affaire="input.affaire ? input.affaire : null"
      /></v-col>
    </v-row>
  </FormLayout>
</template>

<script>
import { mapState } from "vuex";

import {
  MUTATION_contratLocationCreate,
  MUTATION_contratLocationUpdate,
} from "./graphql/mutations";
import {
  QUERY_affaire,
  QUERY_contratLocation,
  QUERY_demandeAccord,
  QUERY_projetSateLeaseOrganisationParametres,
} from "./graphql/queries";

import AffaireSelector from "./AffaireSelector.vue";
import BailleurSelector from "./BailleurSelector.vue";
import ClientSelector from "@/components/COMPONENTS-devis-facture/ClientSelector.vue";
import DateInput from "@/components/COMPONENTS-skeleton/Inputs/DateInput.vue";
import ContratLocationFacturationForm from "./ContratLocationFacturationForm.vue";
import DemandeAccordSelector from "./DemandeAccordSelector.vue";
import DevisFournisseurAttach from "@/components/COMPONENTS-devis-facture/DevisFournisseurAttach.vue";
import FactureFournisseurAttach from "@/components/COMPONENTS-devis-facture/FactureFournisseurAttach.vue";
import FinancementCapitalAttach from "./FinancementCapitalAttach.vue";
import FinancementForm from "./FinancementForm.vue";
import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";
import PersonneSelector from "@/components/COMPONENTS-annuaire/PersonneSelector.vue";
import TitleLayout from "@/components/COMPONENTS-skeleton/Layouts/TitleLayout.vue";

export default {
  name: "ContratLocationForm",

  components: {
    AffaireSelector,
    BailleurSelector,
    ClientSelector,
    ContratLocationFacturationForm,
    DateInput,
    DemandeAccordSelector,
    DevisFournisseurAttach,
    FactureFournisseurAttach,
    FinancementCapitalAttach,
    FinancementForm,
    FormLayout,
    PersonneSelector,
    TitleLayout,
  },

  props: {
    componentParent: undefined,
    fromAffaire: { type: String, default: undefined },
  },

  data: () => ({
    bailleurInput: false,
    contratLocation: {
      dateCreation: new Date().toISOString().substr(0, 10),
      devisFournisseurs: [],
      facturation: {
        type: "FACTURATION_CLASSIQUE",
        repartition: [],
      },
      facturesFournisseurs: [],
      financement: {
        // Present value (should be entered as negative)
        capitalAfinancer: undefined,
        // Compounding Frequency : 12 pour par mois
        cf: undefined,
        //Is payment made at the beginning of périod ?
        isBeginning: undefined,
        loyers: [],
        // Nombre de périodes principales
        nper: undefined,
        // Nombre de périodes complémentaires
        nperComp: undefined,
        // Payment Frequency : 12 pour par mois
        pf: undefined,
        // Taux d'interet
        rate: undefined,
        repartition: [],
        //Future Value (valeur résiduelle)
        valeurFinFinancement: undefined,
      },

      fraisDossier: undefined,
      id: undefined,
      locataire: null,
      numeroAffaire: "",
      signatairesClient: [],
    },
    currentlyActiveField: "",
    input: {},
    menuDatePickerDateCreation: false,
    numeroContratInput: false,
    projetSateLeaseOrganisationParametres: {},
  }),

  watch: {
    fromAffaire: function () {
      this.init();
    },
    //Si on desactive l'input, on supprime la donnée stockée
    numeroContratInput: function (val) {
      if (!val) {
        this.input.numeroContrat = this.contratLocation.numeroContrat;
      }
    },
  },

  computed: {
    ...mapState(["formsErrors"]),

    devisFournisseurs() {
      return this.input.devisFournisseurs;
    },

    size() {
      const size = { xs: "x-small", sm: "small", lg: "large", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
  },

  mounted: async function () {
    await this.$apollo
      .query({
        query: QUERY_projetSateLeaseOrganisationParametres,
      })
      .then((data) => {
        this.projetSateLeaseOrganisationParametres =
          data.data.projetSateLeaseOrganisationParametres;
      });

    this.init();
  },

  methods: {
    ajouter() {
      let toSend = this.inputConverter(this.input);

      this.$apollo
        .mutate({
          mutation: MUTATION_contratLocationCreate,
          variables: { input: toSend },
        })
        .then((__) => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "Le contrat a été ajouté",
          });

          if (this.componentParent) {
            this.$root.$emit(this.componentParent, __.data.contratLocationCreate);
            this.$root.$emit("skeleton--close");
          }else {
            this.$router.push({ path: `/satelease/contratsLocations/edit/${__.data.contratLocationCreate.id}` })
          }

          this.init();
        });
    },

    calcFraisDossier() {
      const fraisDossierPourcentage =
        this.projetSateLeaseOrganisationParametres.fraisDossierPourcentage;
      const fraisDossierMinimum =
        this.projetSateLeaseOrganisationParametres.fraisDossierMinimum;
      let montantFraisDossier =
        this.input.financement.capitalAfinancer > 0
          ? (this.input.financement.capitalAfinancer *
              fraisDossierPourcentage) /
              100 <
            fraisDossierMinimum
            ? fraisDossierMinimum
            : (this.input.financement.capitalAfinancer *
                fraisDossierPourcentage) /
              100
          : fraisDossierMinimum;
      this.$set(this.input, "fraisDossier", parseFloat(montantFraisDossier).toFixed(2));
    },

    init() {
      if (
        this.$route.path.startsWith("/satelease/contratsLocations/edit/") &&
        this.$route.params.id
      ) {
        let id = this.$route.params.id;
        this.$apollo
          .query({
            query: QUERY_contratLocation,
            variables: { id: id },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let contratLocation = data.data.contratLocation;
            this.input = this.inputConverter(contratLocation);
            // this.updatePanelEtb();
          });
      } else if (this.$route.query.fromAffaire || this.fromAffaire) {
        let fromAffaire = this.$route.query.fromAffaire
          ? this.$route.query.fromAffaire
          : this.fromAffaire;

        this.repriseDataFromAffaire(fromAffaire);
      } else {
        this.input = JSON.parse(JSON.stringify(this.contratLocation));
        //On applique le paramètrage par défaut
        this.input.financement.rate =
          this.projetSateLeaseOrganisationParametres.affaireFinancementDefaultRate;
        this.input.financement.nper =
          this.projetSateLeaseOrganisationParametres.affaireFinancementDefaultNpPrincipales;
        this.input.financement.nperComp =
          this.projetSateLeaseOrganisationParametres.affaireFinancementDefaultNpComplementaires;
        this.input.financement.valeurFinFinancement =
          this.projetSateLeaseOrganisationParametres.affaireFinancementDefaultFV;
        this.input.financement.isBeginning =
          this.projetSateLeaseOrganisationParametres.affaireFinancementDefaultIsBeginning;
        this.input.financement.cf =
          this.projetSateLeaseOrganisationParametres.affaireFinancementDefaultCf;
        this.input.financement.pf =
          this.projetSateLeaseOrganisationParametres.affaireFinancementDefaultCf;
      }
    },

    inputConverter(arg) {
      const obj = JSON.parse(JSON.stringify(arg));

      const commissionPersonnes = [];
      if (obj.commissionPersonnes) {
        for (let commissionPersonne of obj.commissionPersonnes) {
          if (commissionPersonne.id) {
            commissionPersonnes.push(commissionPersonne.id);
          } else {
            commissionPersonnes.push(commissionPersonne);
          }
        }
      }
      const commissionEntreprises = [];
      if (obj.commissionEntreprises) {
        for (let commissionEntreprise of obj.commissionEntreprises) {
          if (commissionEntreprise.id) {
            commissionEntreprises.push(commissionEntreprise.id);
          } else {
            commissionEntreprises.push(commissionEntreprise);
          }
        }
      }

      const devisFournisseurs = [];
      if (obj.devisFournisseurs) {
        for (let objDevisFournisseur of obj.devisFournisseurs) {
          if (objDevisFournisseur.id) {
            devisFournisseurs.push(objDevisFournisseur.id);
          } else {
            devisFournisseurs.push(objDevisFournisseur);
          }
        }
      }

      const facturesFournisseurs = [];
      if (obj.facturesFournisseurs) {
        for (let facturesFournisseur of obj.facturesFournisseurs) {
          if (facturesFournisseur.id) {
            facturesFournisseurs.push(facturesFournisseur.id);
          } else {
            facturesFournisseurs.push(facturesFournisseur);
          }
        }
      }

      let input = {
        affaire: obj.affaire && obj.affaire.id ? obj.affaire.id : obj.affaire,
        numeroContrat: obj.numeroContrat,
        financement: this.$refs.financementForm.inputConverter(obj.financement),
        facturation: this.$refs.contratLocationFacturationForm.inputConverter(
          obj.facturation
        ),
        locataire:
          obj.locataire && obj.locataire.id ? obj.locataire.id : obj.locataire,
        commissionPersonnes: commissionPersonnes,
        commissionEntreprises: commissionEntreprises,
        signataireClient:
          obj.signataireClient && obj.signataireClient.id
            ? obj.signataireClient.id
            : obj.signataireClient,
        devisFournisseurs: devisFournisseurs,
        facturesFournisseurs: facturesFournisseurs,
        fraisDossier: parseFloat(obj.fraisDossier),
        bailleur:
          obj.bailleur && obj.bailleur.id ? obj.bailleur.id : obj.bailleur,
        demandeAccord:
          obj.demandeAccord && obj.demandeAccord.id
            ? obj.demandeAccord.id
            : obj.demandeAccord,
        meta: obj.meta,
        datePrevInstallation: obj.datePrevInstallation
      };

      obj.id != undefined ? (input.id = obj.id) : null;

      return input;
    },

    modifier() {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_contratLocationUpdate,
          variables: { input: toSend },
        })
        .then(() => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "Le contrat a été modifié",
          });
          this.$root.$emit("skeleton--close");
        });
    },

    repriseDataFromAffaire(id) {
      this.$apollo
        .query({
          query: QUERY_affaire,
          variables: { id: id },
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          let reprise = data.data.affaire;
          reprise.meta = [];
          reprise.meta.push({ fromAffaire: reprise.id });
          reprise.id = undefined;
          reprise.affaire = id;
          this.input = this.inputConverter(reprise);
        });
    },

    async setBailleur(event) {
      let idBailleur = null;
      if (event) {
        await this.$apollo
          .query({
            query: QUERY_demandeAccord,
            variables: { id: event },
            fetchPolicy: "no-cache",
          })
          .then((__) => {
            idBailleur = __.data.demandeAccord.bailleur.id;
          });
      }
      this.$set(this.input, "bailleur", idBailleur);
    },

    setInputFinancementCapitalAfinancer(val) {
      if (this.input.financement) {
        this.$set(this.input.financement, "capitalAfinancer", val);
      }
    },

    setInputFinancementRepartition(val) {
      let repartition = [];
      val.forEach((elem) => {
        repartition.push({
          client: elem.client.id,
          partCapital: elem.pourcent,
          montantTVA: elem.tva,
        });
      });

      if (this.input.financement) {
        this.$set(this.input.financement, "repartition", repartition);
      }
    },
  },
};
</script>