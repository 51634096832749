<template>
  <ActionLayout
    :actions="actions"
    :size="size"
    :action="action"
    :mainIcon="mainIcon"
  />
</template>

<script>
import ActionLayout from "@/components/COMPONENTS-skeleton/Layouts/ActionLayout.vue";

import {
  MUTATION_devisFournisseurDelete,
  MUTATION_devisFournisseurDuplicate,
  MUTATION_devisFournisseurUpdate,
  MUTATION_devisFournisseurFactureFournisseurTransform
} from "./graphql/mutations.js";

export default {
  name: "DevisFournisseurActions",
  components: {
    ActionLayout,
  },

  props: {
    withView: { type: Boolean, default: true },
    mainIcon: { type: String, default: "mdi-home" },
    object: { type: [Array, Object] },
    size: { type: String, default: "md" },
    action: { type: String, default: "router" },
    multiple: { type: Boolean, default: false },
  },

  computed: {
    actions() {
      return [
        {
          text: "Editer",
          icon: "mdi-pencil",
          path: "/devis-facture/devis-fournisseurs/edit/" + this.object.id,
          acl: "/devis-facture/devis-fournisseurs/edit",
          color: "",
          conditions: { multiple: false, isActif: true },
        },
        {
          text: "Transformer en facture",
          icon: "mdi-file-table",
          method: "transformToFacture",
          acl: "/devis-facture/facture-fournisseurs/add",
          color: "blue",
          conditions: { multiple: false, isActif: true, objet: { factureAssociee: null } },
        },
        {
          text: "Dupliquer",
          icon: "mdi-content-duplicate",
          method: "duplicate",
          acl: "/devis-facture/devis-fournisseurs/add",
          color: "green",
          conditions: { multiple: false, isActif: true },
        },
        {
          text: "Supprimer",
          icon: "mdi-delete",
          method: "editIsActif",
          acl: "/devis-facture/devis-fournisseurs/deleted",
          color: "error",
          conditions: { multiple: true, isActif: true },
        },
        {
          text: "Restauration",
          icon: "mdi-restore",
          method: "editIsActif",
          acl: "/devis-facture/devis-fournisseurs/edit",
          color: "",
          conditions: { multiple: true, isActif: false },
        },
        {
          text: "Supprimer définitivement",
          icon: "mdi-delete",
          method: "delete",
          acl: "/devis-facture/devis-fournisseurs/deleted",
          color: "",
          conditions: { multiple: true, isActif: false },
        },
        {
          text: "Visualiser",
          icon: "mdi-eye",
          path: "/devis-facture/devis-fournisseurs/view/" + this.object.id,
          acl: "/devis-facture/devis-fournisseurs/view",
          color: "",
          conditions: { size: "md xs" },
        },
      ];
    },
  },

  /**
   * Permet d'utiliser la popup de validation
   */
  created() {
    this.$root.$on("deleteDevisFournisseur", this.deleteDevisFournisseur);
    this.$root.$on("editDevisFournisseur", this.editDevisFournisseur);
  },

  methods: {
    /**
     * Supprimer une devisFournisseur grâce à son id
     */
    deleteDevisFournisseur(id) {
      this.$apollo
        .mutate({
          mutation: MUTATION_devisFournisseurDelete,
          variables: { id: id },
        })
        .then(() => {
          this.$emit("fetch");
        });
    },

    delete(obj) {
      // infos qui s'afficheront dans le popup
      let toValid = {
        titre: "Suppression définitive d'un devis fournisseur",
        texte: `Êtes-vous sûr de vouloir supprimer définitivement le devis fournisseur ${obj.raisonSociale} ?`,
        type: "warning",
        toReturn: {
          function: "deleteDevisFournisseur", // cette fonction change en fonction du composant (ex: PersonneActions.vue ça serait deletePersonne,
          //qu'il faut créer à la place de deleteDevisFournisseur)
          arg: obj.id,
        },
      };

      // Envoi l'info pour ouvrir la popup
      this.$root.$emit("popupValidation", toValid);
    },

    duplicate(obj) {
      this.$apollo
        .mutate({
          mutation: MUTATION_devisFournisseurDuplicate,
          variables: {
            id: obj.id
          }
        })
        .then(__ => {
          const data = __.data.devisFournisseurDuplicate;
          this.$router.push({ path: `/devis-facture/devis-fournisseurs/edit/${data.id}` })
        })
    },

    async editDevisFournisseur(obj) {
      if (!Array.isArray(obj)) {
        obj = [obj];
      }
      this.$emit("loading");
      for (let item of obj) {
        let devisFournisseur = {
          id: item.id,
          isActif: !item.isActif
        };
        await this.$apollo.mutate({
          mutation: MUTATION_devisFournisseurUpdate,
          variables: {
            input: devisFournisseur,
          },
        });
      }
      this.$emit("fetch");
    },

    editIsActif(obj) {
      // Si obj n'est pas un tableau alors on le transforme en tableau
      if (!Array.isArray(obj)) {
        obj = [obj];
      }
      let toValid = {
        titre: obj[0].isActif
          ? "Suppression d'un devis fournisseur"
          : "Restauration d'un devis fournisseur",
        texte: obj[0].isActif
          ? `Êtes-vous sûr de vouloir supprimer ${obj.length} devis fournisseur(s) ? ` +
            'Elle seront toujours présentes dans l\'onglet "Corbeille"'
          : `Êtes-vous sûr de vouloir restaurer ${obj.length} devis fournisseur(s) ?`,
        type: "warning",
        toReturn: {
          function: "editDevisFournisseur", // à changer selon le composant, comme deleteDevisFournisseur
          arg: obj,
        },
      };

      this.$root.$emit("popupValidation", toValid);
    },

    async transformToFacture(obj) {
      this.$apollo
        .mutate({
          mutation: MUTATION_devisFournisseurFactureFournisseurTransform,
          variables: {
            idDevis: obj.id
          }
        })
        .then(__ => {
          const data = __.data.devisFournisseurFactureFournisseurTransform;
          this.$router.push({ path: `/devis-facture/facture-fournisseurs/edit/${data.factureAssociee.id}` })
        })
    }
  },
};
</script>