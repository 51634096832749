<template>
  <div>
    <h1>Liste des plannings</h1>

    <v-data-table
      :headers="headers"
      :items="plannings"
      :items-per-page="5"
      class="elevation-1"
    >
      <template v-slot:[`item.id`]="{ item }">
        <router-link :to="`/plannings/view/${item.id}`">{{
          item.id
        }}</router-link>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small v-on:click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { MUTATION_planningDelete } from "./graphql/mutations";
import { QUERY_plannings } from "./graphql/queries";
export default {
  name: "PlanningsList",
  props: ["plannings"],
  data: () => ({
    dialog: false,
    headers: [
      { text: "identifiant", value: "id", sortable: false },
      { text: "Label", value: "label" },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),
  created() {
    this.$root.$on("deletePlanning", this.deletePlanning);
  },

  methods: {
    deleteItem(item) {
      let toValid = {
        titre: "Suppression d'un planning",
        texte: `Êtes-vous sûr de vouloir supprimer le planning ${item.label}?`,
        type: "warning",
        toReturn: {
          function: "deletePlanning",
          arg: item.id,
        },
      };
      this.$root.$emit("popupValidation", toValid);
       
    },
    deletePlanning(id) {
      this.$apollo.mutate({
        mutation: MUTATION_planningDelete,
        variables: { id: id },
        refetchQueries: [{ query: QUERY_plannings }],
      });
    },
  },
};
</script>

