<template>
  <FormLayout
    @ajouter="ajouter"
    :disabled="
      input.client && input.dateReglement && input.montantReglement
        ? false
        : true
    "
    :hasResetButton="hasResetButton"
    icon="mdi-cash-register"
    :item="input"
    @modifier="modifier"
    @reinitialiser="init"
    typeItem="Règlement client"
  >
    <v-row>
      <v-col>
        <ClientSelector
          v-model="input.client"
          :disabled="clientProp ? true : false"
        />
      </v-col>
      <v-col>
        <FactureClientSelector
          v-model="input.factureClient"
          :client="input.client"
          :disabled="factureClientProp ? true : input.client ? false : true"
          :preSelector="false"
          :valide="true"
          :nonRegle="true"
          @change="getFactureClient($event)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
          v-model="input.modeReglement"
          :items="MODE_REGLEMENT"
          label="Mode de règlement"
          prepend-icon="mdi-form-select"
        />
      </v-col>
      <v-col>
        <v-text-field
          v-model="input.identifiantReglement"
          label="Identifiant du règlement"
          hint="Numéro de chèque, numéro de virement, ..."
          persistent-hint
          prepend-icon="mdi-tag"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <DateInput
          label="Date de règlement"
          v-model="input.dateReglement"
          :min="factureClient ? factureClient.dateFacture : null"
        />
      </v-col>
      <v-col>
        <v-text-field
          v-model="input.montantReglement"
          type="number"
          label="Montant du règlement"
          persistent-hint
          suffix="€"
          prepend-icon="mdi-cash"
        />
      </v-col>
    </v-row>
  </FormLayout>
</template>

<script>
import { mapState } from "vuex";
import dayjs from "dayjs";

import {
  MUTATION_reglementClientCreate,
  MUTATION_reglementClientUpdate,
} from "./graphql/mutations";
import { QUERY_factureClient, QUERY_reglementClient } from "./graphql/queries";

import ClientSelector from "./ClientSelector.vue";
import DateInput from "@/components/COMPONENTS-skeleton/Inputs/DateInput.vue";
import FactureClientSelector from "./FactureClientSelector.vue";
import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";

export default {
  name: "PointFacturationForm",

  components: {
    ClientSelector,
    DateInput,
    FactureClientSelector,
    FormLayout,
  },

  props: {
    clientProp: undefined,
    factureClientProp: undefined,
    hasResetButton: { type: Boolean, default: true },
    disposition: { type: String, default: "default" },
    componentParent: undefined,
  },

  data: () => ({
    factureClient: null,
    input: {},
    MODE_REGLEMENT: [
      { value: "LETTRE_DE_CHANGE", text: "Lettre de change" },
      { value: "PRELEVEMENT_BANCAIRE", text: "Prélévement bancaire" },
      { value: "VIREMENT", text: "Virement" },
      { value: "COMPENSATION", text: "Compensation" },
    ],
    reglementClient: {
      id: undefined,
      modeReglement: "VIREMENT",
    },
  }),

  computed: {
    ...mapState(["formsErrors"]),
  },

  mounted: function () {
    this.init();
  },

  methods: {
    ajouter() {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_reglementClientCreate,
          variables: {
            input: toSend,
          },
        })
        .then((__) => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "Votre reglementClient a été ajouté",
          });

          if (this.componentParent) {
             
            this.$root.$emit(
              this.componentParent,
              __.data.reglementClientCreate
            );
          }

          this.$root.$emit("skeleton--close");

          this.init();
        });
    },

    getFactureClient(arg) {
      if (arg) {
        this.$apollo
          .query({
            query: QUERY_factureClient,
            variables: {
              id: arg.id ? arg.id : arg,
            },
            fetchPolicy: "no-cache",
          })
          .then((__) => {
            let factureClient = __.data.factureClient;
            this.factureClient = factureClient;
            this.input.montantReglement =
              factureClient.montantHT +
              factureClient.montantTVA -
              factureClient.montantRegle;
          });
      }
    },

    init() {
      if (
        this.$route.path.startsWith("/devis-facture/reglement-clients/edit/") &&
        this.$route.params.id
      ) {
         
        let id = this.$route.params.id;
        this.$apollo
          .query({
            query: QUERY_reglementClient,
            variables: {
              id: id,
            },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
             
            let reglementClient = data.data.reglementClient;
            this.input = JSON.parse(JSON.stringify(reglementClient));
          });
      } else {
        this.reglementClient.dateReglement = dayjs();
        if (this.clientProp) {
          this.reglementClient.client = this.clientProp;
        }
        if (this.factureClientProp) {
          this.reglementClient.factureClient = this.factureClientProp;
        }
        this.input = JSON.parse(JSON.stringify(this.reglementClient));
        if (this.factureClientProp) {
          this.getFactureClient(this.factureClientProp);
        }
      }
    },

    inputConverter(arg) {
      const obj = JSON.parse(JSON.stringify(arg));

      let input = {
        client: obj.client && obj.client.id ? obj.client.id : obj.client,
        factureClient:
          obj.factureClient && obj.factureClient.id
            ? obj.factureClient.id
            : obj.factureClient,
        modeReglement: obj.modeReglement,
        identifiantReglement: obj.identifiantReglement,
        dateReglement: obj.dateReglement,
        montantReglement: parseFloat(obj.montantReglement),
      };

      obj.id != undefined ? (input.id = obj.id) : null;

      return input;
    },

    modifier() {
      let toSend = this.inputConverter(this.input);

      this.$apollo
        .mutate({
          mutation: MUTATION_reglementClientUpdate,
          variables: {
            input: toSend,
          },
        })
        .then(() => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "reglementClient a été modifié",
          });
          this.$root.$emit("skeleton--close");
        });
    },
  },
};
</script>