<template>
  <div>
    <template v-if="partenaire">
      <!--  SIZE XL -->
      <template v-if="size == 'xl'">
        <ObjectLayout size="xl">
          <template v-slot:icon>mdi-handshake-outline</template>
          <template v-slot:titre>Partenaire</template>
          <template v-slot:body>
            <EntrepriseObject
              :entrepriseProp="partenaire.entrepriseAssociee"
              format="chip"
            />
          </template>
        </ObjectLayout>
      </template>
      <!--  SIZE MD -->
      <template v-if="size == 'md'">
        <v-row>
          <v-list-item-avatar tile>
            <EntrepriseLogo
              v-if="partenaire.entrepriseAssociee"
              size="28"
              :entreprise="partenaire.entrepriseAssociee"
              :justDisplay="true"
              class="mr-2"
            />
          </v-list-item-avatar>
        </v-row>
      </template>
      <!--  SIZE XS -->
      <template v-if="size == 'xs'">
        <ObjectLayout
          :action="action"
          size="xs"
          :onClickPush="`/satelease/partenariat/partenaires/view/${partenaire.id}`"
        >
          <template v-slot:image>
            <!-- TODO: Mettre entreprise Object ici -->
          </template>
        </ObjectLayout>
      </template>
    </template>
  </div>
</template>

<script>
import { QUERY_partenaire } from "./graphql/queries";
import ObjectLayout from "@/components/COMPONENTS-skeleton/Layouts/ObjectLayout.vue";
export default {
  name: "PartenaireObject",

  components: {
    ObjectLayout,
  },
  data: () => ({
    partenaire: undefined,
  }),

  props: {
    size: { type: String, default: "md" },

    partenaireProp: {
      type: Object,
      default() {
        return {};
      },
    },

    partenaireID: {
      type: String,
    },
    action: { type: String, default: "router" },
  },

  watch: {
    partenaireProp: function () {
      this.init();
    },
    partenaireID: function () {
      this.init();
    },
  },

  mounted: function () {
    this.init();
  },

  methods: {
    init() {
      if (this.partenaireID) {
        let id = this.partenaireID;
        this.$apollo
          .query({
            query: QUERY_partenaire,
            variables: { id: id },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let partenaire = data.data.partenaire;
            this.partenaire = JSON.parse(JSON.stringify(partenaire));
          });
      } else {
        this.partenaire = JSON.parse(JSON.stringify(this.partenaireProp));
      }
    },
  },
};
</script>

