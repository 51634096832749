<template>
  <div>
    <v-combobox
      v-bind:value="value"
      v-on:input="$emit('input', $event)"
      :items="personneFonctions"
      label="Fonction"
      :error-messages="errorMessages"
      chips
      clearable
      item-text="value"
      prepend-icon="mdi-chess-pawn"
      dense
    >
    </v-combobox>
  </div>
</template>

<script>
import { QUERY_personneFonctions } from "./graphql/queries";
export default {
  name: "PersonneFonctionSelector",
  components: {},
  props: ["value", "errorMessages"],
  data: () => ({
    isLoading: false,
  }),

  watch: {
    //Evite de retourner un champ null lors de la suppression d'un chip
    value: function (val) {
      if (val == null) {
        this.$emit("input", "");
      } else {
        this.$emit("input", val);
      }
    },
  },
  apollo: {
    personneFonctions: {
      query: QUERY_personneFonctions,
      fetchPolicy: "no-cache",
    },
  },
  mounted: function () {},
  methods: {},
};
</script>