<template>
  <FormLayout
    icon="mdi-domain"
    @ajouter="ajouter"
    @modifier="modifier"
    @reinitialiser="init"
    typeItem="une organisation"
    :disposition="disposition"
    :hasResetButton="hasResetButton"
  >
    <v-row justify="center">
      <v-col cols="6">
        <v-text-field
          v-model="input.label"
          :error-messages="formsErrors.organisationLabel"
          label="Nom de l'organisation"
          prepend-icon="mdi-tag-text"
        />
      </v-col>
    </v-row>
  </FormLayout>
</template>
<script>
import { mapState } from "vuex";
import { QUERY_organisation, QUERY_organisations } from "./graphql/queries";
import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";
import {
  MUTATION_organisationCreate,
  MUTATION_organisationUpdate,
} from "./graphql/mutations";
export default {
  name: "OrganisationForm",
  components: {
    FormLayout,
  },
  data: function () {
    return {
      input: {},
    };
  },
  props: {
    organisation: {
      type: Object,
      default() {
        return {
          id: undefined,
        };
      },
    },
    hasResetButton: { type: Boolean, default: true },
    disposition: { type: String, default: "default" },
  },
  computed: {
    ...mapState(["formsErrors"]),
  },
  mounted: function () {
    this.init();
  },
  methods: {
    init() {
      if (this.$route.params.id) {
        let id = this.$route.params.id;
        this.$apollo
          .query({
            query: QUERY_organisation,
            variables: {
              id: id,
            },
          })
          .then((data) => {
            let organisation = data.data.organisation;
            this.input = JSON.parse(JSON.stringify(organisation));
          });
      } else {
        this.input = JSON.parse(JSON.stringify(this.organisation));
      }
    },

    ajouter() {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_organisationCreate,
          variables: {
            input: toSend,
          },
        })
        .then((__) => {
          this.init();
          this.$store.commit("addAlert", {
            type: "info",
            text: "Votre organisation a été ajoutée",
          });
          this.$root.$emit("skeleton--close");
          this.$root.$emit(
            "authentification-habilitation--organisation-create",
            __.data.organisationCreate
          );
          this.$emit(
            "authentification-habilitation--organisation-create",
            __.data.organisationCreate
          );
          this.$root.$emit("skeleton--close");
        });
    },

    inputConverter(arg) {
      const obj = JSON.parse(JSON.stringify(arg));

      let input = {
        label: obj.label,
      };

      obj.id != undefined ? (input.id = obj.id) : null;

      return input;
    },

    modifier() {
      let toSend = this.inputConverter(this.input);
      this.$apollo.mutate({
        mutation: MUTATION_organisationUpdate,
        variables: {
          input: toSend,
        },
        refetchQueries: [
          {
            query: QUERY_organisations,
          },
        ],
        update: (store, { data: { organisationUpdate } }) => {
          this.$emit("organisationReturn", organisationUpdate);
          this.$store.commit("addAlert", {
            type: "info",
            text: "Votre organisation a été modifiée",
          });
          this.$root.$emit(
            "authentification-habilitation--update-organisations"
          );
          this.$root.$emit("skeleton--close");
        },
      });
    },
  },
};
</script>