<template>
  <ViewLayout v-if="email" :tabs="tabs" :item="email">
    <template v-slot:email>
      <v-row class="align-center mx-auto">
        <v-col cols="12">
          <EmailObject :emailProp="email" size="xl" />
        </v-col>
      </v-row>
    </template>
    <template v-slot:events>
      <template v-if="email.events">
        <ObjectLayout  
          size="xl"
        >
          <template v-slot:icon>mdi-email-alarm-check</template>
          <template v-slot:titre>Evènements</template>
          <template v-slot:body v-if="email.events.length > 0">
            <div
              v-for="(event, index) in email.events"
              :key="index"
            >
              <template
                v-if="Array.isArray(event)"
              >
                <v-treeview dense :items="event" open-on-click>
                  <template v-slot:prepend="{ item, open }">
                    <template v-if="item.children">
                      <v-badge color="primary" :content="event[0].children.length" class="mr-4">
                        <p class="mb-0 grey--text text--darken-2">{{ item.name + (open ? ' :' : '') }}</p>
                      </v-badge>
                    </template>
                  </template>
                  <template slot="label" slot-scope="{ item }">
                    <p class="mb-0 font-weight-bold grey--text text--darken-2">
                      {{ item.time | shortDateAndHourFR }}
                    </p>
                  </template>
                </v-treeview>
              </template>
              <template v-else>
                <ObjectLayout>
                  <template v-slot:titre>{{ getEventName(event) }} :</template>
                  <template v-slot:content>{{ event.time | shortDateAndHourFR }}</template>
                </ObjectLayout>
              </template>
            </div>
          </template>
          <template v-slot:body v-else>Aucun évènement</template>
        </ObjectLayout>
      </template>
    </template>
  </ViewLayout>
</template>

<script>
import { QUERY_email } from "./graphql/queries";

import ViewLayout from "@/components/COMPONENTS-skeleton/Layouts/ViewLayout.vue";
import ObjectLayout from "@/components/COMPONENTS-skeleton/Layouts/ObjectLayout.vue";
import EmailObject from "./EmailObject.vue";

export default {
  name:"EmailView",

  components:{
    EmailObject,
    ViewLayout,
    ObjectLayout
  },

  computed: {
    computedId() {
      return this.showPopup ?
        this.id :
        this.$route.params.id ?
        this.$route.params.id :
        this.id;
    }
  },

  data: () => ({
    email: undefined, 
    tabs: [
      { icon: "mdi-email", displayName: "Email", name: "email" },
      { icon: "mdi-alarm-check", displayName: "évènements", name: "events" },
    ],
  }),

  methods: {
    combineDuplicatesEvent() {
      let uniqueNames = [];
      let eventsUnique = this.email.events.filter(elem => {
        const isDuplicate = uniqueNames.includes(elem.name);

        if(!isDuplicate) {
          uniqueNames.push(elem.name);
          return true;
        }

        return false;
      })
      let events = [];
      
      for(let event of eventsUnique) {
        let sameEvents = this.email.events.filter(elem => elem.name == event.name);
        if(sameEvents.length == 1) {
          events.push(sameEvents[0]);
        }else {
          events.push([{ name: this.getEventName(event), children: sameEvents }]);
        }
      }

      this.email.events = events;
    },

    getEventName(event) {
      return (event.name.charAt(0).toUpperCase() + event.name.slice(1)).replace('_', ' ');
    },

    init() {
      this.$apollo
        .query({
          query: QUERY_email,
          variables: {
            id: this.computedId
          },
          fetchPolicy: "no-cache",
        })
        .then(__ => {
          const data = __.data;
          this.email = data.email;
          if(!this.email) {
            this.$router.push({ path: '/envoi-notifications/emails/list' });
          }else {
            this.combineDuplicatesEvent();
          }
        })
    }
  },

  mounted() {
    this.init();
  },

  props: {
    id: {
      type: String,
    },
    showPopup: {
      type: Boolean,
    },
  }
}
</script>

<style>

</style>