<template>
  <div>
    <template v-if="adresse">
      <template v-if="size == 'xl'">
        <v-card flat>
          <GmapMap
            :center="{
              lat: parseFloat(adresse.latitude),
              lng: parseFloat(adresse.longitude),
            }"
            :zoom="8"
            map-type-id="terrain"
            style="width: 100%; height: 320px"
            :options="{
              zoomControl: true,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: true,
              rotateControl: false,
              fullscreenControl: true,
              disableDefaultUi: false,
            }"
          >
            <GmapMarker
              :position="{
                lat: parseFloat(adresse.latitude),
                lng: parseFloat(adresse.longitude),
              }"
              :clickable="true"
              @click="
                center = {
                  lat: parseFloat(adresse.latitude),
                  lng: parseFloat(adresse.longitude),
                }
              "
            />
          </GmapMap>
        </v-card>
      </template>
      <template v-if="size == 'xs'">
        <ObjectLayout size="xs">
          <template v-slot:icon>mdi-map-marker-radius</template>
          <template v-slot:content>
            {{ adresse.numeroVoie + " " + adresse.libelleVoie }}
          </template>
          <template v-slot:subcontent>
            {{ adresse.codePostal }}
            {{ adresse.libelleCommune }}
          </template>
        </ObjectLayout>
      </template>
      <template v-if="size == 'md'">
        <ObjectLayout>
          <template v-slot:icon>mdi-map-marker-radius</template>
          <template v-slot:titre>Numéro et voie</template>
          <template v-slot:content>
            {{ adresse.numeroVoie + " " + adresse.libelleVoie }}
          </template>
        </ObjectLayout>
        <ObjectLayout>
          <template v-slot:icon>mdi-city</template>
          <template v-slot:titre>Code postal et commune</template>
          <template v-slot:content>
            {{ adresse.codePostal }}
            {{ adresse.libelleCommune }}
            {{ adresse.cedex }}
          </template>
          <template v-slot:subcontent> </template>
        </ObjectLayout>
      </template>
    </template>
    <!-- 
      <v-card-text class="pt-0 text--primary">
        <a :href="`geo:${adresse.longitude},${adresse.latitude}`">
          {{ adresse.numeroVoie + " " + adresse.libelleVoie + " " }}<br />
          {{ adresse.codePostal }}
          {{ adresse.libelleCommune }}<br />
          {{ adresse.cedex }}
        </a>
      </v-card-text>
 -->
  </div>
</template>

<script>
import ObjectLayout from "@/components/COMPONENTS-skeleton/Layouts/ObjectLayout.vue";
export default {
  name: "AdressePostaleObject",
  components: {
    ObjectLayout,
  },
  props: {
    size: {
      type: String,
      default: "md",
    },
    adresse: {
      type: Object,
    },
  },
};
</script>