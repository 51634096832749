<template>
  <div>
    <ActionLayout
      :actions="actions"
      :size="size"
      :action="action"
      @fetch="$emit('fetch')"
      :mainIcon="mainIcon"
    />
    <v-dialog v-model="dialog">
      <v-card>
        <v-card-title>Associer à une enseigne</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px">
          <v-row>
            <v-col>
              A quelle enseigne souhaitez-vous associer ces entreprises ?
              <EnseigneSelector v-model="enseigne" />
            </v-col>
            <v-col>
              <v-btn @click="addToEnseigne">Attacher</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ActionLayout from "@/components/COMPONENTS-skeleton/Layouts/ActionLayout.vue";
import EnseigneSelector from "./EnseigneSelector.vue";
import {
  MUTATION_etablissementEnseigneAssign,
  MUTATION_etablissementDelete,
  MUTATION_etablissementUpdate,
} from "./graphql/mutations.js";
export default {
  name: "EtablissementActions",
  components: {
    ActionLayout,
    EnseigneSelector,
  },

  props: {
    mainIcon: { type: String, default: "mdi-home" },
    object: { type: [Array, Object] },
    size: { type: String, default: "md" },
    action: { type: String, default: "router" },
    multiple: { type: Boolean, default: false },
  },
  data: () => ({
    enseigne: undefined,
    dialog: false,
  }),
  computed: {
    actions() {
      return [
        {
          text: "Ajouter à une enseigne",
          icon: "mdi-store",
          method: "addToEnseigne",
          acl: "/annuaire/etablissements/addToEnseigne",
          color: "",
          conditions: { multiple: true, single: false, isActif: true },
        },
        {
          text: "Editer",
          icon: "mdi-pencil",
          path: "/annuaire/etablissements/edit/" + this.object.id,
          acl: "/annuaire/etablissements/edit",
          color: "",
          conditions: { multiple: false, isActif: true },
        },
        {
          text: "Supprimer",
          icon: "mdi-delete",
          method: "editIsActif",
          acl: "/annuaire/etablissements/deleted",
          color: "error",
          conditions: { multiple: true, isActif: true },
        },
        {
          text: "Restauration",
          icon: "mdi-restore",
          method: "editIsActif",
          acl: "/annuaire/etablissements/edit",
          color: "",
          conditions: { multiple: true, isActif: false },
        },
        {
          text: "Supprimer définitivement",
          icon: "mdi-delete",
          method: "delete",
          acl: "/annuaire/etablissements/deleted",
          color: "",
          conditions: { multiple: false, isActif: false },
        },
        {
          text: "Visualiser",
          icon: "mdi-eye",
          path: "/annuaire/etablissements/view/" + this.object.id,
          acl: "/annuaire/etablissements/view",
          color: "",
          conditions: { size: "md xs" },
        },
      ];
    },
  },
  created() {
    this.$root.$on("deleteEtablissement", this.deleteEtablissement);
    this.$root.$on("editEtablissement", this.editEtablissement);
  },

  methods: {
    //Pour multiple ETB
    addToEnseigne() {
      if (!this.enseigne) {
        this.dialog = true;
      } else {
        let etbs = this.object.map((e) => e.id);
        this.$apollo
          .mutate({
            mutation: MUTATION_etablissementEnseigneAssign,
            variables: { etablissements: etbs, enseigne: this.enseigne },
          })
          .then(() => {
            this.$emit("fetch");
          });
      }
    },
    /**
     * Supprimer une etablissement grâce à son id
     */
    deleteEtablissement(id) {
      this.$apollo
        .mutate({
          mutation: MUTATION_etablissementDelete,
          variables: { id: id },
        })
        .then(() => {
          this.$emit("fetch");
        });
    },

    /**
     * Fonction à avoir dans tous les composants Actions (infos à changer selon le composant)
     * Permet de lancer la popup de validation
     *
     * obj : l'objet a supprimé
     */
    delete(obj) {
      // infos qui s'afficheront dans le popup
      let toValid = {
        titre: "Suppression définitive d'une etablissement",
        texte: `Êtes-vous sûr de vouloir supprimer définitivement l'etablissement ${obj.libelle} ?`,
        type: "warning",
        toReturn: {
          function: "deleteEtablissement", // cette fonction change en fonction du composant (ex: PersonneActions.vue ça serait deletePersonne,
          //qu'il faut créer à la place de deleteEtablissement)
          arg: obj.id,
        },
      };

      // Envoi l'info pour ouvrir la popup
      this.$root.$emit("popupValidation", toValid);
    },

    /**
     * Fonction qui édite une etablissement
     *
     * obj : l'objet à éditer
     */
    async editEtablissement(obj) {
      // Si obj n'est pas un tableau alors on le transforme en tableau
      if (!Array.isArray(obj)) {
        obj = [obj];
      }
      // Selon le schéma, il est obligé d'avoir le siren et la raison sociale dans EntepriseInput
      // Bien vérifier dans le schéma si jamais il y a une erreur du style "$input etc.."
      this.$emit("loading");
      for (let item of obj) {
        let etablissement = {
          id: item.id,
          isActif: !item.isActif,
          libelle: item.libelle,
          siret: item.siret,
          adresse: {
            numeroVoie: item.adresse.numeroVoie,
            libelleVoie: item.adresse.libelleVoie,
            codePostal: item.adresse.codePostal,
            libelleCommune: item.adresse.libelleCommune,
            cedex: item.adresse.cedex,
            longitude: item.adresse.longitude,
            latitude: item.adresse.latitude,
          },
          isSiege: item.isSiege,
        };
        await this.$apollo.mutate({
          mutation: MUTATION_etablissementUpdate,
          variables: {
            input: etablissement,
          },
        });
      }
      this.$emit("fetch");
    },

    /**
     * Fonction à avoir dans tous les composants (infos à changer)
     */
    editIsActif(obj) {
      // Si obj n'est pas un tableau alors on le transforme en tableau
      if (!Array.isArray(obj)) {
        obj = [obj];
      }
      let toValid = {
        titre: obj[0].isActif
          ? "Suppression d'etablissement(s)"
          : "Restauration d'etablissement(s)",
        texte: obj[0].isActif
          ? `Êtes-vous sûr de vouloir supprimer ${obj.length} etablissement(s) ? ` +
            'Ils seront toujours présents dans l\'onglet "Corbeille"'
          : `Êtes-vous sûr de vouloir restaurer ${obj.length} etablissement(s) ?`,
        type: "warning",
        toReturn: {
          function: "editEtablissement", // à changer selon le composant, comme deleteEtablissement
          arg: obj,
        },
      };

      this.$root.$emit("popupValidation", toValid);
    },
  },
};
</script>