<template>
  <v-card class="mx-auto fill-height">
    <CommentairesList
      :customSearch="[
        { 'meta.typeParent': typeParent },
        {
          'meta.idParent': idParent,
        },
      ]"
      @replyTo="replyTo = $event"
    />
    <CommentaireForm
      :typeParent="typeParent"
      :idParent="idParent"
      @replyTo="replyTo = $event"
      :replyTo="replyTo"
    />
  </v-card>
</template>

<script>
import CommentaireForm from "./CommentaireForm.vue";
import CommentairesList from "./CommentairesList.vue";
export default {
  name: "CommentairesObject",
  components: {
    CommentaireForm,
    CommentairesList,
  },
  props: {
    typeParent: { type: String, default: "" },
    idParent: { type: String, default: "" },
  },
  data: () => ({
    replyTo: null,
  }),
  methods: {},
};
</script>

