var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.accordEtapeItems),function(item,index){return [_c('div',{key:index},[_c('v-card',{staticClass:"mx-auto mb-6",attrs:{"max-width":"80%"}},[_c('v-card-title',{staticClass:"primary white--text py-2"},[_vm._v(" Etape "+_vm._s(index + 1)+" "),_c('v-row',{staticClass:"text-end"},[_c('v-col',{staticClass:"mt-n10 mr-n6"},[(
                  _vm.accordEtapeItems.length > 0 &&
                  index + 1 < _vm.accordEtapeItems.length
                )?_c('v-btn',{staticClass:"ml-2",attrs:{"fab":"","x-small":"","dark":"","color":"info"},on:{"click":function($event){return _vm.moveItem('down', index)}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1):_vm._e(),(index > 0 && _vm.accordEtapeItems.length > 1)?_c('v-btn',{staticClass:"ml-2",attrs:{"fab":"","x-small":"","dark":"","color":"info"},on:{"click":function($event){return _vm.moveItem('up', index)}}},[_c('v-icon',[_vm._v("mdi-chevron-up")])],1):_vm._e(),(index > 0 && _vm.accordEtapeItems.length > 1)?_c('v-btn',{staticClass:"ml-2",attrs:{"fab":"","x-small":"","dark":"","color":"error"},on:{"click":function($event){return _vm.deleteAccordEtape(index)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()],1)],1)],1),_c('v-card-text',{staticClass:"pt-4"},[_c('AccordEtapeForm',{attrs:{"type-accord":_vm.typeAccord,"destinataire-a-specifie":_vm.accordEtapeItems[index - 1] &&
              _vm.accordEtapeItems[index - 1].type ==
                'DOCUSIGN_SPECIFIER_DESTINATAIRE'
                ? true
                : false},model:{value:(_vm.accordEtapeItems[index]),callback:function ($$v) {_vm.$set(_vm.accordEtapeItems, index, $$v)},expression:"accordEtapeItems[index]"}})],1)],1),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('v-row',{staticClass:"fill-height"},[_c('v-col'),_c('v-col',{staticClass:"clickable",on:{"click":function($event){return _vm.addAccordEtape(index)}}},[(hover)?_c('v-icon',{staticClass:"d-flex justify-center"},[_vm._v(" mdi-plus-circle ")]):_c('v-icon',{staticClass:"d-flex justify-center"},[_vm._v(" mdi-dots-vertical ")])],1),_c('v-col')],1)]}}],null,true)}),(index + 1 < _vm.accordEtapeItems.length)?_c('v-row',{staticClass:"fill-height mb-2"},[_c('v-col'),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.nextAndItems,"dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"d-flex justify-center",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(item.text)+" ")])]}}],null,true),model:{value:(item.nextAnd),callback:function ($$v) {_vm.$set(item, "nextAnd", $$v)},expression:"item.nextAnd"}})],1),_c('v-col')],1):_c('v-row',{staticClass:"fill-height"},[_c('v-col'),_c('v-col',[_c('p',{staticClass:"d-flex justify-center overline"},[_vm._v("FIN")])]),_c('v-col')],1)],1)]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }