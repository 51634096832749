<template>
  <v-container>
     <v-card
        class="mt-2"
      >
        <v-list two-line v-if="userMe">
          <v-list-item 
            three-line
            v-for="(content, i) in listContent"
            :key="i"
            @click="content.onClick"
          >
            <v-col>
              <v-row>
                <v-list-item-content>
                  <v-list-item-title class="text-h5 mb-1">
                    <TitleLayout :titre="content.title" />
                  </v-list-item-title>
                </v-list-item-content>
              </v-row>
              <v-row>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">{{ content.text }}</v-list-item-title>

                  <v-list-item-subtitle >
                    Dernière modification : 
                    <span v-if="userMe.meta && content.subtext">{{ content.subtext | shortDateAndHourFR }}</span> 
                    <span v-else>jamais</span>
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-icon>
                    mdi-chevron-right
                  </v-icon>
                </v-list-item-action>
              </v-row>
              <v-row>
                <v-divider
                  v-if="i < listContent.length - 1"
                  :key="i"
                ></v-divider>
              </v-row>
            </v-col>
          </v-list-item>
        </v-list>
      </v-card>
  </v-container>
</template>

<script>
import TitleLayout from "@/components/COMPONENTS-skeleton/Layouts/TitleLayout.vue";
import { QUERY_userMe } from "./graphql/queries.js";

export default {
  name: "UserMeEdit",

  components: {
    TitleLayout
  },

  data: () => ({
    userMe: {},
  }),

  computed: {
    listContent() {
      return [
        {
          title: "Changer votre adresse mail",
          text: this.userMe.email ? this.userMe.email : '',
          subtext: (this.userMe.meta && this.userMe.meta.lastChangeEmail) ? this.userMe.meta.lastChangeEmail : '',
          onClick: () => { this.goToUserMeEdit('email'); }
        },
        {
          title: "Changer votre mot de passe",
          text: "●●●●●",
          subtext: (this.userMe.meta && this.userMe.meta.lastChangePassword) ? this.userMe.meta.lastChangePassword : '',
          onClick: () => { this.goToUserMeEdit('password'); }
        },
        {
          title: "Gérer le double facteur d'authentification",
          text: `2FA est ${this.userMe.is2FAEnabled ? 'activé' : 'désactivé'}`,
          subtext: (this.userMe.meta && this.userMe.meta.lastChange2FA) ? this.userMe.meta.lastChange2FA : '',
          onClick: () => { this.goToUserMeEdit('2fa'); }
        }
      ]
    }
  },

  methods: {
    goToUserMeEdit(editing) {
      this.$router.push({ path: `/authentification-habilitation/user-me/edit/${editing}` });
    },

    init() {
      this.$apollo
        .query({
          query: QUERY_userMe,
          fetchPolicy: 'no-cache'
        })
        .then(__ => {
          this.userMe = __.data.userMe;
        })
    }
  },

  mounted() {
    this.init();
  },
}
</script>