<template>
  <div>ServiceObject</div>
</template>

<script>
export default {
  name: "ServiceObject",
};
</script>

