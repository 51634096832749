<template>
  <div>
    <ListLayout
      ref="list"
      :headers="headers"
      @fetch="init"
      @showMultipleActions="showMultipleActions = $event"
    >
      <template v-slot:multipleActions>
        <FournisseurActions
          :multiple="true"
          :object="$refs.list ? $refs.list.itemsSelected : []"
          size="xl"
          v-show="showMultipleActions"
          @fetch="init"
          @loading="$refs.list.loading = true"
        />
      </template>
      <template v-slot:[`item.fournisseur`]="{ item }">
        <FournisseurObject :fournisseurProp="item" size="xs" />
      </template>
      <template v-slot:[`item.devisFournisseurCount`]="{ item }">
        <DevisFournisseurCounter :customSearch="[{ fournisseur: item.id }]" />
      </template>
      <template v-slot:[`item.factureFournisseursCount`]="{ item }">
        <FactureFournisseursCounter
          :customSearch="[{ fournisseur: item.id }]"
        />
      </template>
      <template v-slot:[`item.servicesCount`]="{ item }">
        <ServicesCounter :customSearch="[{ fournisseur: item.id }]" />
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <FournisseurActions
          :isActif="$refs.list.itemsAreActive"
          :object="item"
          size="xs"
          @fetch="init"
          @loading="$refs.list.loading = true"
        />
      </template>
    </ListLayout>
  </div>
</template>

<script>
import { QUERY_fournisseurs } from "./graphql/queries";

import DevisFournisseurCounter from "./DevisFournisseurCounter.vue";
import FactureFournisseursCounter from "./FactureFournisseursCounter.vue";
import FournisseurActions from "./FournisseurActions.vue";
import FournisseurObject from "./FournisseurObject.vue";
import ListLayout from "@/components/COMPONENTS-skeleton/Layouts/ListLayout.vue";
import ServicesCounter from "./ServicesCounter.vue";

export default {
  name: "FournisseursList",

  components: {
    DevisFournisseurCounter,
    FactureFournisseursCounter,
    FournisseurActions,
    FournisseurObject,
    ListLayout,
    ServicesCounter,
  },

  data: () => ({
    headers: [
      { text: "Fournisseur", value: "fournisseur", sortable: false },
      { text: "Devis", value: "devisFournisseurCount", sortable: false },
      { text: "Factures", value: "factureFournisseursCount", sortable: false },
      { text: "Fichiers", value: "fichiers" },
      { text: "Commentaires", value: "commentaires" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    showMultipleActions: false,
  }),

  methods: {
    fetchFournisseurs() {
      this.$refs.list.loading = true;
      this.$apollo
        .query({
          query: QUERY_fournisseurs,
          variables: {
            pagination: this.$refs.list.pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;

          this.$refs.list.items = data.fournisseurs;
          this.$refs.list.itemsCount = data.fournisseursCount;
          this.$refs.list.loading = false;
        });
    },

    init() {
      this.fetchFournisseurs();
    },
  },
};
</script>