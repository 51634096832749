<template>
  <v-card elevation="2" class="justify-center">
    <TitleLayout
      titre="Signature du document"
      sousTitre="Utiliser le pad ci-dessous pour signer le document"
    />
    <v-col>
      <VueSignaturePad id="signature" ref="signaturePad"/>
    </v-col>
    
    <div class="text-center py-4">
      <v-btn class="mx-1"  color='primary' @click="sauvegarder">valider</v-btn>
      <v-btn class="mx-1" color='warning' @click="annuler">effacer</v-btn>
    </div>
  </v-card>
</template>
<script>
import TitleLayout from "@/components/COMPONENTS-skeleton/Layouts/TitleLayout.vue";
export default {
  name: 'SignaturePad',

  components:{
    TitleLayout,
  },
  methods: {
    annuler() {
      this.$refs.signaturePad.undoSignature();
    },
    sauvegarder() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      console.log(isEmpty);
      console.log(data);
    },

  }
};
</script>
<style scoped>
#signature {
  border: double 2px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, rgba(73, 72, 72, 0.356), rgba(73, 72, 72, 0.356));
  background-origin: border-box;
  background-clip: content-box, border-box;
}
</style>