<template>
  <div>
    EptaFactureReaderPlugin<br />
    <v-btn @click="read">On lit la facture !!!!</v-btn>
    <p>{{ text }}</p>
  </div>
</template>

<script>
import _ from "lodash";
import { QUERY_fichier_jsonTextContent } from "../graphql/queries";
export default {
  name: "EptaFactureReaderPlugin",
  data: () => ({
    jsonTextContent: undefined,
    text: "Que va t'on faire ?",
  }),
  props: {
    id: {
      type: String,
    },
  },
  methods: {
    JSONparse(arg) {
      let doc = JSON.parse(arg);
      let texte = "";
      doc.Pages.forEach((page) => {
        page.Texts.forEach((text) => {
          text.R.forEach((str) => {
            // str.T = decodeURI(str.T);
            texte += decodeURI(str.T);
          });
        });
      });
      return texte;
      // return JSON.parse(decodeURI(arg));
    },
    read() {
       
      this.$apollo
        .query({
          query: QUERY_fichier_jsonTextContent,
          variables: { id: this.id },
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          this.findInfos(this.JSONparse(data.data.fichier.jsonTextContent));
        });
    },
    findInfos(json) {
       
      let result = _.map(json, "Pages");
      console.log(result);
    },
  },
};
</script>

