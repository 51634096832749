<template>
  <div>
    <template v-if="moyenPaiement">
      <template v-if="size == 'xl'">
        <ObjectLayout :action="action" size="xl" :item="moyenPaiement">
          <template v-slot:icon>mdi-cash-fast</template>
          <template v-slot:titre>
            {{ type }} - {{ moyenPaiement.label }}
          </template>
          <template v-slot:body>
            <TitleLayout titre="Informations administratives" />
            <v-row>
              <v-col>
                <ObjectLayout v-if="moyenPaiement.label" class="py-2">
                  <template v-slot:icon>mdi-tag-text</template>
                  <template v-slot:titre>
                    Dénomination du moyen de paiement
                  </template>
                  <template v-slot:content>{{ moyenPaiement.label }}</template>
                </ObjectLayout>
              </v-col>
              <v-col>
                <ObjectLayout v-if="moyenPaiement.type" class="py-2">
                  <template v-slot:icon>mdi-tag-text</template>
                  <template v-slot:titre> Type de moyen de paiement </template>
                  <template v-slot:content>{{ type }}</template>
                </ObjectLayout>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-if="
                  moyenPaiement.pointFacturation &&
                  moyenPaiement.pointFacturation.client
                "
              >
                <ObjectLayout class="py-2">
                  <template v-slot:icon>mdi mdi-briefcase-account</template>
                  <template v-slot:titre> Client </template>
                  <template v-slot:content>
                    <ClientObject
                      :clientID="moyenPaiement.pointFacturation.client.id"
                      size="xs"
                    />
                  </template>
                </ObjectLayout>
              </v-col>
              <v-col v-if="moyenPaiement.pointFacturation">
                <ObjectLayout class="py-2">
                  <template v-slot:icon>mdi-wallet</template>
                  <template v-slot:titre> Point de facturation </template>
                  <template v-slot:content>
                    <PointFacturationObject
                      :pointFacturationID="moyenPaiement.pointFacturation.id"
                      size="xs"
                    />
                  </template>
                </ObjectLayout>
              </v-col>
            </v-row>
            <template
              v-if="
                moyenPaiement.type == 'PRELEVEMENT_BANCAIRE' &&
                moyenPaiement.compteBancaire
              "
            >
              <TitleLayout titre="Informations bancaires" />
              <v-row>
                <v-col>
                  <ObjectLayout class="py-2">
                    <template v-slot:icon>mdi-account</template>
                    <template v-slot:titre> Titulaire du compte </template>
                    <template v-slot:content>
                      {{ moyenPaiement.compteBancaire.titulaire }}
                    </template>
                  </ObjectLayout>
                </v-col>
                <v-col>
                  <ObjectLayout class="py-2">
                    <template v-slot:icon>mdi-office-building-marker</template>
                    <template v-slot:titre> Domiciliation du compte </template>
                    <template v-slot:content>
                      {{ moyenPaiement.compteBancaire.domiciliation }}
                    </template>
                  </ObjectLayout>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <ObjectLayout class="py-2">
                    <template v-slot:icon>mdi-contain</template>
                    <template v-slot:titre> Numéro IBAN </template>
                    <template v-slot:content>
                      {{
                        moyenPaiement.compteBancaire.iban
                          | VMask("AA## XXXX #### #### #### #### ###")
                      }}
                    </template>
                  </ObjectLayout>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <ObjectLayout class="py-2">
                    <template v-slot:icon>mdi-contain</template>
                    <template v-slot:titre> Numéro BIC </template>
                    <template v-slot:content>
                      {{ moyenPaiement.compteBancaire.bic }}
                    </template>
                  </ObjectLayout>
                </v-col>
                <v-col>
                  <ObjectLayout class="py-2">
                    <template v-slot:icon>mdi-contain</template>
                    <template v-slot:titre> RIB </template>
                    <template v-slot:content>
                      {{
                        moyenPaiement.compteBancaire.rib
                          | VMask("##### ##### ########### ##")
                      }}
                    </template>
                  </ObjectLayout>
                </v-col>
              </v-row>
            </template>
          </template>
        </ObjectLayout>
      </template>
      <template v-if="size == 'md'">
        <ObjectLayout size="md">
          <template v-slot:icon>mdi-cash-fast</template>
          <template v-slot:content>{{ moyenPaiement.label }}</template>
          <template v-slot:extracontent>
            <v-icon small class="mr-2">mdi-material-design</v-icon>
            {{ moyenPaiement.label }}
          </template>
        </ObjectLayout>
      </template>
      <template v-if="size == 'xs'">
        <ObjectLayout
          size="xs"
          :action="action"
          :onClickPush="`/devis-facture/moyen-paiements/view/${moyenPaiement.id}`"
        >
          <template v-slot:icon>mdi-cash-fast</template>
          <template v-slot:content>
            {{ type }} - {{ moyenPaiement.label }}</template
          >
        </ObjectLayout>
      </template>
    </template>
  </div>
</template>

<script>
import { QUERY_moyenPaiement } from "./graphql/queries";

import ClientObject from "./ClientObject.vue";
import ObjectLayout from "@/components/COMPONENTS-skeleton/Layouts/ObjectLayout.vue";
import PointFacturationObject from "./PointFacturationObject.vue";
import TitleLayout from "@/components/COMPONENTS-skeleton/Layouts/TitleLayout.vue";

export default {
  name: "MoyenPaiementObject",

  components: {
    ClientObject,
    ObjectLayout,
    PointFacturationObject,
    TitleLayout,
  },

  props: {
    action: {
      type: String,
      default: "router",
    },

    moyenPaiementProp: {
      type: Object,
      default() {
        return {};
      },
    },

    moyenPaiementID: {
      type: String,
    },

    size: { type: String, default: "md" },
  },

  data: () => ({
    moyenPaiement: undefined,
  }),

  computed: {
    type() {
      if (this.moyenPaiement.type == "PRELEVEMENT_BANCAIRE") {
        return "Prélévement bancaire";
      }
      return "";
    },
  },

  watch: {
    moyenPaiementID: function () {
      this.init();
    },

    moyenPaiementProp: function () {
      this.init();
    },
  },

  mounted: function () {
    this.init();
  },

  methods: {
    init() {
      if (this.moyenPaiementID) {
        let id = this.moyenPaiementID;
        this.$apollo
          .query({
            query: QUERY_moyenPaiement,
            variables: { id: id },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let moyenPaiement = data.data.moyenPaiement;
            this.moyenPaiement = JSON.parse(JSON.stringify(moyenPaiement));
          });
      } else {
        this.moyenPaiement = JSON.parse(JSON.stringify(this.moyenPaiementProp));
      }
    },
  },
};
</script>