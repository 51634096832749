<template>
  <FormLayout
    icon="mdi-cash-check"
    @ajouter="ajouter"
    @modifier="modifier"
    @reinitialiser="init"
    typeItem="une demande d'accord"
    :item="input"
    :disabled="
      input.affaire && input.bailleur && input.numeroDemande ? false : true
    "
  >
    <v-row>
      <v-col>
        <AffaireSelector
          v-model="input.affaire"
          :disabled="affaire ? true : false"
        />
        <AffaireObject
          v-if="input.affaire"
          :affaireID="input.affaire.id ? input.affaire.id : input.affaire"
          size="xl"
        />
      </v-col>

      <v-col>
        <BailleurSelector v-model="input.bailleur" />
        <v-text-field
          v-model="input.numeroDemande"
          label="Numéro de la demande"
          prepend-icon="mdi-counter"
          required
        />
        <DateInput v-model="input.dateDemande" label="Date de la demande" />
        <v-text-field
          v-model="input.montantDemande"
          label="Montant de la demande"
          suffix="€"
          prepend-icon="mdi-cash"
          append-outer-icon="mdi-auto-fix"
          @click:append-outer="getMontantDemande()"
        />
        <v-select
          label="Etat de la demande"
          v-model="input.etat"
          prepend-icon="mdi-progress-question"
          :items="[
            { value: 'waiting', text: 'En cours' },
            {
              value: 'waitingForElmt',
              text: 'En attente d\'éléments complémentaires',
            },
            { value: 'valid', text: 'Validé' },
            { value: 'refused', text: 'Refusé' },
          ]"
        />
        <template v-if="input.etat != 'waiting' && input.etat != 'refused'">
          <v-text-field
            v-model="input.numeroAccord"
            label="Numéro d'accord"
            prepend-icon="mdi-counter"
          />
          <DateInput
            v-model="input.dateValide"
            label="Date de validité de l'accord"
          />
          <v-text-field
            v-model="input.loyers"
            suffix="€"
            label="Loyers de l'accord"
            prepend-icon="mdi-cash"
          />
          <v-text-field
            v-model="input.taux"
            suffix="%"
            label="Taux de l'accord"
            prepend-icon="mdi-percent"
            append-outer-icon="mdi-auto-fix"
            @click:append-outer="getTaux()"
          />
          <v-row v-if="input.taux > 0 && input.affaire">
            <v-col>
              <v-btn color="primary" @click="simulationExec">
                Simulation des gains
              </v-btn>
            </v-col>
            <v-col>
              Capital à céder :
              <MoneyLayout :montant="simulation.capitalCession" />
            </v-col>
            <v-col>
              Spread :
              <MoneyLayout :montant="simulation.spread" />
            </v-col>
          </v-row>
          <ItemsTableForm
            v-model="input.documentsAFournir"
            title="Liste des documents nécessaire à la cession"
            icon="mdi-file-question"
          />
        </template>
      </v-col>
    </v-row>
  </FormLayout>
</template>

<script>
import dayjs from "dayjs";
import { financementJS } from "@aidalinfo/calculs-financiers";
import { mapState } from "vuex";

import {
  MUTATION_demandeAccordCreate,
  MUTATION_demandeAccordUpdate,
} from "./graphql/mutations";
import { QUERY_affaire } from "./graphql/queries";
import {
  QUERY_demandeAccord,
  QUERY_projetSateLeaseOrganisationParametres,
} from "./graphql/queries";

import AffaireObject from "./AffaireObject.vue";
import AffaireSelector from "./AffaireSelector.vue";
import BailleurSelector from "./BailleurSelector.vue";
import DateInput from "@/components/COMPONENTS-skeleton/Inputs/DateInput";
import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";
import ItemsTableForm from "@/components/COMPONENTS-skeleton/ItemsTableForm.vue";
import MoneyLayout from "@/components/COMPONENTS-skeleton/Layouts/MoneyLayout.vue";

export default {
  name: "DemandeAccordForm",

  components: {
    AffaireObject,
    AffaireSelector,
    BailleurSelector,
    DateInput,
    FormLayout,
    ItemsTableForm,
    MoneyLayout,
  },

  props: {
    affaire: null,
    componentParent: undefined,
  },

  apollo: {
    projetSateLeaseOrganisationParametres: {
      query: QUERY_projetSateLeaseOrganisationParametres,
    },
  },

  data: () => ({
    input: {},
    etatStates: [],
    simulation: {
      capitalCession: 0,
      spread: 0,
    },
    demandeAccord: {
      etat: "waiting",
      dateDemande: new Date().toISOString().substr(0, 10),
      spread: 0,
    },
  }),

  computed: {
    ...mapState(["formsErrors"]),
  },

  mounted: function () {
    this.init();
  },

  watch: {
    input: {
      deep: true,
      handler(val) {
        // Permet de mettre une date par défaut à la date de validité, égale à la date du jour où l'état est changé + 6 mois - 1 jour
        if (val.etat != "waiting" && val.etat != "refused") {
          if (!val.dateValide) {
            let dateValide = dayjs()
              .add(6, "months")
              .subtract(1, "day")
              .format("YYYY-MM-DD");
            this.$set(this.input, "dateValide", dateValide);
          }
        }
      },
    },
  },

  methods: {
    // AJoute une demande en base
    ajouter() {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_demandeAccordCreate,
          variables: {
            input: toSend,
          },
        })
        .then((__) => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "La demande d'accord a été créée",
          });

          if (this.componentParent) {
            this.$root.$emit(this.componentParent, __.data.demandeAccordCreate);
          }

          this.$root.$emit("skeleton--close");
          this.init();
        });
    },

    // Permet de générer un montant demander égal au montant à financer de l'affaire  + 25%
    getMontantDemande() {
      let montantDemande = 0;
      let tauxAjout =
        (100 +
          this.projetSateLeaseOrganisationParametres
            .demandeAccordPourcentageAjoutCapital) /
        100;
      if (this.input.affaire) {
        this.$apollo
          .query({
            query: QUERY_affaire,
            variables: {
              id: this.input.affaire.id
                ? this.input.affaire.id
                : this.input.affaire,
            },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let affaire = data.data.affaire;
            montantDemande = affaire.montantFinancement * tauxAjout;
            this.$set(this.input, "montantDemande", montantDemande);
          });
      }
    },

    // Permet de récupérer le taux à partir d'un montant demandé et d'un loyer pour la demande
    getTaux() {
      if (
        this.input.affaire &&
        this.input.montantDemande &&
        this.input.loyers
      ) {
        this.$apollo
          .query({
            query: QUERY_affaire,
            variables: {
              id: this.input.affaire.id
                ? this.input.affaire.id
                : this.input.affaire,
            },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let affaire = data.data.affaire;
            let financementObj = financementJS(affaire.financement);
            financementObj.calcRate(
              this.input.loyers,
              this.input.montantDemande
            );
            this.$set(this.input, "taux", financementObj.rate);
          });
      }
    },

    // Initialisation du formulaire
    init() {
      if (
        this.$route.path.startsWith(
          "/satelease/refinancement/demandeAccords/edit/"
        ) &&
        this.$route.params.id
      ) {
        let id = this.$route.params.id;
        this.$apollo
          .query({
            query: QUERY_demandeAccord,
            variables: {
              id: id,
            },
          })
          .then((data) => {
            let demandeAccord = data.data.demandeAccord;
            this.input = JSON.parse(JSON.stringify(demandeAccord));
            // this.updatePanelEtb();
          });
      } else {
        if (this.affaire) {
          this.demandeAccord.affaire = this.affaire;
        }
        this.input = JSON.parse(JSON.stringify(this.demandeAccord));
      }
    },

    // Converti les informations du formulaire en input valide pour le Backend
    inputConverter(arg) {
      const obj = JSON.parse(JSON.stringify(arg));

      let input = {
        affaire: obj.affaire && obj.affaire.id ? obj.affaire.id : obj.affaire,
        bailleur:
          obj.bailleur && obj.bailleur.id ? obj.bailleur.id : obj.bailleur,
        etat: obj.etat,
        dateDemande: obj.dateDemande,
        numeroDemande: obj.numeroDemande,
        numeroAccord: obj.numeroAccord ? obj.numeroAccord : obj.numeroDemande,
        dateValide: obj.dateValide,
        montantDemande: parseFloat(obj.montantDemande),
        loyers: parseFloat(obj.loyers),
        taux: parseFloat(obj.taux),
        documentsAFournir: obj.documentsAFournir,
      };

      obj.id != undefined ? (input.id = obj.id) : null;

      return input;
    },

    // Modifie une demande en base
    modifier() {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_demandeAccordUpdate,
          variables: {
            input: toSend,
          },
        })
        .then(() => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "La demande d'accord a été modifiée",
          });
          this.$root.$emit("skeleton--close");
        });
    },

    // Lance une simulation des gains
    simulationExec() {
      this.$apollo
        .query({
          query: QUERY_affaire,
          variables: {
            id: this.input.affaire.id
              ? this.input.affaire.id
              : this.input.affaire,
          },
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          let affaire = data.data.affaire;
          let financementObj = financementJS(affaire.financement);
          let cessionObj = financementJS(affaire.financement);
          cessionObj.actualisationChaineLoyer(this.input.taux);
          this.$set(
            this.simulation,
            "capitalCession",
            cessionObj.capitalAfinancer
          );
          this.$set(
            this.simulation,
            "spread",
            cessionObj.capitalAfinancer - financementObj.capitalAfinancer
          );
        });
    },
  },
};
</script>
