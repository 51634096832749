export default [

  /*-------------- Assets -------------------*/

  {
    path: '/assets/assets',
    name: 'Gestion des assets',
    component: () => import('./AssetsList.vue'),
    meta: {
      acl: "/assets/assets"
    }
  },

  {
    path: '/assets/assets/add',
    name: 'Ajouter un asset',
    component: () => import('./AssetForm.vue'),
    meta: {
      acl: "/assets/assets/add"
    }
  },

  {
    path: '/assets/assets/edit/:id',
    name: 'Editer un asset',
    component: () => import('./AssetForm.vue'),
    meta: {
      acl: "/assets/assets/edit"
    }
  },

  {
    path: '/assets/assets/list',
    name: 'Liste des assets',
    component: () => import('./AssetsList.vue'),
    meta: {
      acl: "/assets/assets/list"
    }
  },

  {
    path: '/assets/assets/view/:id',
    name: 'Visualisation d\'un asset',
    component: () => import('./AssetView.vue'),
    meta: {
      acl: "/assets/assets/view"
    }
  },

  {
    path: '/assets/assets/deleted',
    name: 'Liste des assets supprimés',
    component: () => import('./AssetsList.vue'),
    meta: {
      acl: "/assets/assets/deleted"
    }
  },

  {
    path: '/assets/assets/duplicate',
    name: 'Dupliquer des assets',
    component: () => import('./AssetsDuplicate.vue'),
    meta: {
      acl: "/assets/assets/duplicate"
    }
  },

  /*-------------- Liens de services -------------------*/

  {
    path: '/assets/lienservices',
    name: 'Gestion des liens de services',
    component: () => import('./LienServicesList.vue'),
    meta: {
      acl: "/assets/lienservices"
    }
  },
  {
    path: '/assets/lienservices/actionsView/:id',
    name: 'Actions sur le lien de service',
    component: () => import('./LienServiceActionsView.vue'),
    meta: {
      acl: "/assets/lienservices/actionsView"
    }
  },

  {
    path: '/assets/lienservices/add',
    name: 'Ajouter des liens de services',
    component: () => import('./LienServiceForm.vue'),
    meta: {
      acl: "/assets/lienservices/add"
    }
  },

  {
    path: '/assets/lienservices/edit/:id',
    name: 'Editer un lien de services',
    component: () => import('./LienServiceForm.vue'),
    meta: {
      acl: "/assets/lienservices/edit"
    }
  },

  {
    path: '/assets/lienservices/list',
    name: 'Liste des liens de services',
    component: () => import('./LienServicesList.vue'),
    meta: {
      acl: "/assets/lienservices/list",
    }
  },

  {
    path: '/assets/lienservices/addMulti',
    name: 'Formulaire multiple de liens de services',
    component: () => import('./LienServiceMultiForm.vue'),
    meta: {
      acl: "/assets/lienservices/addMulti"
    }

  },

  {
    path: '/assets/lienservices/view/:id',
    name: 'Visualisation d\'un lien de services',
    component: () => import('./LienServiceView.vue'),
    meta: {
      acl: "/assets/lienservices/view"
    }
  },

  {
    path: '/assets/lienservices/deleted',
    name: 'Liste des liens de services supprimées',
    component: () => import('./LienServicesList.vue'),
    meta: {
      acl: "/assets/lienservices/deleted"
    }
  },
  {
    path: '/assets/lienservices/print/:id',
    name: 'Impression du/des lien(s) de services',
    component: () => import('./LienServicePrinter.vue'),
    meta: {
      acl: "/assets/lienservices/print"
    }
  },
  {
    path: '/assets/lienservices/redirect',
    name: 'Lien de services - redirection',
    component: () => import('./LienServiceRedirecter.vue'),
    meta: {
      acl: "/assets/lienservices/redirect"
    }
  },

  // Marques
  {
    path: '/assets/marques',
    name: 'Gestion des marques',
    component: () => import('./MarquesList.vue'),
    meta: {
      acl: "/assets/marques"
    }
  },


  {
    path: '/assets/marques/add',
    name: 'Ajouter une marque',
    component: () => import('./MarqueForm.vue'),
    meta: {
      acl: "/assets/marques/add"
    }
  },

  {
    path: '/assets/marques/edit/:id',
    name: 'Editer une marque',
    component: () => import('./MarqueForm.vue'),
    meta: {
      acl: "/assets/marques/edit"
    }
  },

  {
    path: '/assets/marques/list',
    name: 'Liste des marques',
    component: () => import('./MarquesList.vue'),
    meta: {
      acl: "/assets/marques/list"
    }
  },

  {
    path: '/assets/marques/view/:id',
    name: 'Visualisation d\'une marque',
    component: () => import('./MarqueView.vue'),
    meta: {
      acl: "/assets/marques/view"
    }
  },

  {
    path: '/assets/marques/deleted',
    name: 'Liste des marques supprimées',
    component: () => import('./MarquesList.vue'),
    meta: {
      acl: "/assets/marques/deleted"
    }
  },

  /**** Modeles  */

  {
    path: '/assets/modeles',
    name: 'Gestion des modèles',
    component: () => import('./ModelesList.vue'),
    meta: {
      acl: "/assets/modeles"
    }
  },


  {
    path: '/assets/modeles/add',
    name: 'Ajouter un modèle',
    component: () => import('./ModeleForm.vue'),
    meta: {
      acl: "/assets/modeles/add"
    }
  },

  {
    path: '/assets/modeles/edit/:id',
    name: 'Editer un modèle',
    component: () => import('./ModeleForm.vue'),
    meta: {
      acl: "/assets/modeles/edit"
    }
  },

  {
    path: '/assets/modeles/list',
    name: 'Liste des modèles',
    component: () => import('./ModelesList.vue'),
    meta: {
      acl: "/assets/modeles/list"
    }
  },

  {
    path: '/assets/modeles/view/:id',
    name: 'Visualisation d\'un modèle',
    component: () => import('./ModeleView.vue'),
    meta: {
      acl: "/assets/modeles/view"
    }
  },

  {
    path: '/assets/modeles/deleted',
    name: 'Liste des modèles supprimées',
    component: () => import('./ModelesList.vue'),
    meta: {
      acl: "/assets/modeles/deleted"
    }
  },

  // Paramètres
  {
    path: '/assets/parametres',
    name: 'Paramètres Assets',
    component: () => import('./AssetOrganisationParametresForm.vue'),
    meta: {
      acl: "/assets/parametres"
    }
  },

  /*-------------- Familles -------------------*/
  {
    path: '/assets/familles',
    name: 'Gestion des familles',
    component: () => import('./FamillesList.vue'),
    meta: {
      acl: "/assets/familles"
    }
  },

  {
    path: '/assets/familles/add',
    name: 'Ajouter une famille',
    component: () => import('./FamilleForm.vue'),
    meta: {
      acl: "/assets/familles/add"
    }
  },

  {
    path: '/assets/familles/edit/:id',
    name: 'Editer une famille',
    component: () => import('./FamilleForm.vue'),
    meta: {
      acl: "/assets/familles/edit"
    }
  },

  {
    path: '/assets/familles/list',
    name: 'Liste des familles',
    component: () => import('./FamillesList.vue'),
    meta: {
      acl: "/assets/familles/list"
    }
  },

  {
    path: '/assets/familles/view/:id',
    name: 'Visualisation d\'une famille',
    component: () => import('./FamilleView.vue'),
    meta: {
      acl: "/assets/familles/view"
    }
  },

  {
    path: '/assets/familles/deleted',
    name: 'Liste des familles supprimées',
    component: () => import('./FamillesList.vue'),
    meta: {
      acl: "/assets/familles/deleted"
    }
  },
]