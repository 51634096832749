<template>
  <div>
    <v-autocomplete
      v-bind:value="value"
      :items="etatsAsset"
      v-on:input="$emit('input', $event)"
      :error-messages="errorMessage"
      chips
      :prepend-icon="prependIcon"
      clearable
      :label="label"
      dense
      item-text="value"
    >
      <template v-slot:selection="data">
        <EtatAssetObject
          :etatAsset="data.item.value"
          size="xs"
          v-bind="data.attrs"
          :input-value="data.selected"
          @click="data.select"
        />
      </template>
      <template v-slot:item="data">
        <EtatAssetObject
          :etatAsset="data.item.value"
          size="xs"
          :tooltip="false"
        />
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import EtatAssetObject from "./EtatAssetObject.vue";

export default {
  name: "EtatAssetSelector",

  components: {
    EtatAssetObject,
  },

  props: {
    value: null,
    errorMessage: { type: String },
    label: { type: String, default: "Etat de l'asset" },
    prependIcon: { type: String, default: "mdi-hexagon-slice-6" },
  },

  data: () => ({
    etatsAsset: [
      {
        value: "NEUF",
        icon: "mdi-hexagon-slice-6",
        color: "#42A5F5",
        text: "Produit neuf",
        description: "Produit neuf, jamais utilisé",
      },
      {
        value: "PARFAIT",
        icon: "mdi-hexagon-slice-5",
        color: "#66BB6A",
        text: "Parfait état",
        description:
          "Produit d'occasion en parfait état, aucune rayure ou impact visible",
      },
      {
        value: "TRES_BON",
        icon: "mdi-hexagon-slice-4",
        color: "#9CCC65",
        text: "Très bon état",
        description:
          "Produit d'occasion présentant des micro-rayures à peine visibles (invisibles à 20 cm), sans fissure et/ou impact",
      },
      {
        value: "CORRECT",
        icon: "mdi-hexagon-slice-3",
        color: "#FFA726",
        text: "Etat correct",
        description:
          "Produit d'occasion présentant des traces d’usure visibles, des rayures profondes et/ou petits impacts",
      },
      {
        value: "CASSE",
        icon: "mdi-hexagon-outline",
        color: "#EF5350",
        text: "Cassé / Hors d'usage",
        description: "Produit d'occasion pour pièces",
      },
    ],
  }),

  created() {},

  watch: {
    //Evite de retourner un champ null lors de la suppression d'un chip
    value: function (val) {
      if (val === null) {
        this.$emit("input", "");
      }
    },
  },

  methods: {
    //Permet de filtrer les entreprises selon deux critères: - Le Numéro Siren, la raison sociale
    filterEtat(item, queryText) {
      return item.value.toLowerCase().indexOf(queryText.toLowerCase()) > -1;
    },
  },
};
</script>