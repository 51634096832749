import gql from 'graphql-tag';

export const AFFAIRE_FIELDS = gql`
  fragment AffaireFields on Affaire{
    dateCreation
    datePrevInstallation
    fraisDossier
    id
    isActif
    montantFinancement
    montantTVA
    numeroAffaire
  }
`;

export const BAILLEUR_FIELDS = gql`
  fragment BailleurFields on Bailleur{
    codeBailleur
    dateCreation
    documentsAFournir
    id    
    isActif
    informationCreancier{
      raisonSociale
      adresse
      cpVille
      pays
      ics
    }
  }
`;

export const CONTRAT_LOCATION_FIELDS = gql`
fragment ContratLocationFields on ContratLocation{
  dateCreation
  datePrevInstallation
  deverrouille
  etat
  fraisDossier
  id
  isActif
  montantFinancement
  montantTVA
  numeroContrat
  sousContrat
  valide
}
`;

export const DEMANDE_ACCORD_FIELDS = gql`
  fragment DemandeAccordFields on DemandeAccord{
    id
    etat
    dateDemande
    numeroAccord
    numeroDemande
    dateValide
    montantDemande
    loyers
    taux
    documentsAFournir
    isActif
    dateCreation
  }
`;

export const DEMANDE_PARTENAIRE_FIELDS = gql`
  fragment DemandePartenaireFields on DemandePartenaire{
    id
    numeroDemande
    montantCapital
    duree
    periodicite
    dateDemande
    etat
    isActif
  }
`;

export const ENVELOPPE_THEORIQUE_FIELDS = gql`
  fragment EnveloppeTheoriqueFields on EnveloppeTheorique{
    id   
    isActif
    dateCreation
    montant
    tauxBailleur
  }
`;


export const LOYER_FIELDS = gql`
fragment LoyerFields on Loyer{
  complementaire
  period
  verrou
  dateDebut
  dateFin
  interetPart
  principalPart
  balance
  pmt
}
`;

export const FINANCEMENT_FIELDS = gql`
fragment FinancementFields on Financement{
  rate
  nper
  nperComp
  capitalAfinancer
  valeurFinFinancement
  cf
  nbMois
  nbMoisComp
  isBeginning
  margeLoueur
  repartition{
    client{
      id
    }
    partCapital
    montantTVA
  }
  loyers{
    ...LoyerFields
  }
}${LOYER_FIELDS}
`;

export const PARTENAIRE_FIELDS = gql`
  fragment PartenaireFields on Partenaire{
    id
    isActif
  }
`;





