<template>
  <v-container>
    <v-img
      :src="logoUrl"
      width="150"
    />
    <LoginForm
      v-if="!isLogged"
      @logging="updateIsLogged"
    />
    <v-img
      :src="backgroundUrl"
      width="500"
    />
  </v-container>
</template>

<script>
import { QUERY_userLoginOrganisation } from "./graphql/queries.js";
import { MUTATION_downloadUrlLogoAndBgOrganisationCreate } from "@/components/COMPONENTS-fichiers-stockages/graphql/mutations.js";
import LoginForm from "./LoginForm.vue";
import { isLogged, onLogin } from "@/components/COMPONENTS-skeleton/vue-apollo";

export default {
  name: "OrganisationLogin",

  components: {
    LoginForm
  },

  data: () => ({
    isLogged: null,
    logoUrl: "",
    backgroundUrl: ""
  }),

  mounted: async function() {
    await this.updateIsLogged();
    if(this.$route.params.nameOrId) {
      let nameOrId = this.$route.params.nameOrId;
      let customSearch = [
        { isActif: true },
        { 'meta.typeParent': 'AuthentificationHabilitationOrganisationParametres' }
      ];
      (nameOrId.length <= 20) ? customSearch.push({ 'meta.nameParent': nameOrId }) : customSearch.push({ 'meta.idParent': nameOrId });

      this.$apollo
        .mutate({
          mutation: MUTATION_downloadUrlLogoAndBgOrganisationCreate,
          variables: {
            pagination: {
              skip: 0,
              limit: 2,
              filter: {
                customSearch: customSearch,
                toFind: ""
              }
            }
          }
        })
        .then(__ => {
          const data = __.data;
          this.logoUrl = data.downloadUrlLogoAndBgOrganisationCreate.logoUrl;
          this.backgroundUrl = data.downloadUrlLogoAndBgOrganisationCreate.backgroundUrl;
        })
        .catch(() => {
          this.$router.push({ path: '/' });
        })

        if(this.isLogged) {
          this.connect();
        }
    }
  },
  
  methods: {
    connect() {
      this.$apollo
        .mutate({
          mutation: QUERY_userLoginOrganisation,
          variables: {
            organisationNameOrId: this.$route.params.nameOrId
          }
        })
        .then(async (data) => {
          const { token, refreshToken } = data.data.userLoginOrganisation;

          if(token) {
            await onLogin(this.$apollo.provider.defaultClient, token, refreshToken);
            this.$router.push({ path: "/accueil" }).catch(() => {});
          }else {
            await this.logout();
            this.$router.push({ path: `/login/${this.$route.params.nameOrId}` }).catch(() => {});
          }
        });
    },

    updateIsLogged: async function () {
      this.isLogged = await isLogged();
      this.connect();
    }
  }
}
</script>