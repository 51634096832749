<template>
  <div>
    <ActionLayout
      :actions="actions"
      :size="size"
      :action="action"
      @fetch="$emit('fetch')"
      :mainIcon="mainIcon"
    />
  </div>
</template>

<script>
import ActionLayout from "@/components/COMPONENTS-skeleton/Layouts/ActionLayout.vue";
import { MUTATION_organisationUpdate } from "./graphql/mutations.js";
export default {
  name: "OrganisationActions",

  components: {
    ActionLayout,
  },
  props: {
    withView: { type: Boolean, default: true },
    mainIcon: { type: String, default: "mdi-city-variant" },
    object: { type: [Array, Object] },
    size: { type: String, default: "md" },
    action: { type: String, default: "router" },
    fetch: {
      type: Function,
      default() {
        console.warn("defaultFetch");
      },
    },
  },

  computed: {
    actions() {
      let toReturn = [
        {
          text: "Editer",
          icon: "mdi-pencil",
          path:
            "/authentification-habilitation/organisations/edit/" +
            this.object.id,
          acl: "/authentification-habilitation/organisations/edit",
          color: "",
          conditions: { multiple: false, isActif: true },
        },
        {
          text: "Supprimer",
          icon: "mdi-delete",
          method: "editIsActif",
          acl: "/authentification-habilitation/organisations/deleted",
          color: "error",
          conditions: { multiple: true, isActif: true },
        },

        {
          text: "Restauration",
          icon: "mdi-restore",
          method: "editIsActif",
          acl: "/authentification-habilitation/organisations/deleted",
          color: "",
          conditions: { multiple: true, isActif: false },
        },
        {
          text: "Supprimer définitivement",
          icon: "mdi-delete",
          method: "delete",
          acl: "/authentification-habilitation/organisations/definitive-delete",
          color: "",
          conditions: { multiple: true, isActif: false },
        },
        {
          text: "Visualiser",
          icon: "mdi-eye",
          path:
            "/authentification-habilitation/organisations/view/" +
            this.object.id,
          acl: "/authentification-habilitation/organisations/view",
          color: "",
          conditions: { size: "md xs" },
        },
      ];

      return toReturn;
    },
  },

  created() {
    this.$root.$on("editOrganisation", this.editOrganisation);
  },

  methods: {
    /**
     * Fonction qui édite une organisation
     *
     * obj : l'objet à éditer
     */
    editOrganisation(obj) {
      // Bien vérifier dans le schéma si jamais il y a une erreur du style "$input etc.."
      let organisation = {
        id: obj.id,
        label: obj.label,
        isActif: !obj.isActif,
      };
      this.$apollo
        .mutate({
          mutation: MUTATION_organisationUpdate,
          variables: {
            input: organisation,
          },
        })
        .then(() => {
          this.fetch();
        });
    },

    /**
     * Fonction à avoir dans tous les composants (infos à changer)
     */
    editIsActif(obj) {
      let toValid = {
        titre: obj.isActif
          ? "Suppression d'une organisation"
          : "Restauration d'une organisation",
        texte: obj.isActif
          ? `Êtes-vous sûr de vouloir supprimer l'organisation ${obj.label} ? ` +
            'Il sera toujours présente dans l\'onglet "Corbeille"'
          : `Êtes-vous sûr de vouloir restaurer l'organisation ${obj.label} ?`,
        type: "warning",
        toReturn: {
          function: "editOrganisation", // à changer selon le composant, comme deleteOrganisation
          arg: obj,
        },
      };

      this.$root.$emit("popupValidation", toValid);
    },
  },
};
</script>