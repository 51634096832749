<template>
  <div>
    <CounterLayout :compteur="demandePartenairesCount">
      <template v-slot:icon>mdi-cash-check</template>
    </CounterLayout>
  </div>
</template>

<script>
import CounterLayout from "@/components/COMPONENTS-skeleton/Layouts/CounterLayout.vue";
import { QUERY_demandePartenairesCount } from "./graphql/queries";
export default {
  name: "DemandePartenairesCounter",

  components: {
    CounterLayout,
  },
  props: {
    customSearch: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data: () => ({}),
  apollo: {
    demandePartenairesCount: {
      query: QUERY_demandePartenairesCount,
      variables() {
        return {
          pagination: {
            filter: {
              customSearch: this.customSearch,
            },
          },
        };
      },
      fetchPolicy: "no-cache",
    },
  },
  created() {
    if (!this.$root._events.sateleaseUpdateDemandePartenaires) {
      this.$root.$on("sateleaseUpdateDemandePartenaires", this.updateQuery);
    }
  },
  mounted: function () {},
  watch: {},
  methods: {
    updateQuery: function () {
      this.$apollo.queries.demandePartenairesCount.refetch();
    },
  },
};
</script>

