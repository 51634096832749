<template>
  <v-list-item :to="item.path">
    <v-list-item-icon>
      <v-icon>{{ item.icon }}</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>{{ item.title }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
export default {
  name: "MainMenuItem",
  props: {
    item: Object,
    subGroup: { type: Boolean, default: false },
  },
};
</script>
