<template>
  <div>
    <template v-if="fournisseur && fournisseur.entreprise">
      <template v-if="size == 'xl'">
        <ObjectLayout :action="action" size="xl">
          <template v-slot:icon>mdi-truck</template>
          <template v-slot:titre>
            {{ fournisseur.entreprise.raisonSociale }}
          </template>
          <template v-slot:body>
            <ObjectLayout>
              <template v-slot:titre>Raison Sociale</template>
              <template v-slot:content>
                {{ fournisseur.entreprise.raisonSociale }}
              </template>
            </ObjectLayout>
            <ObjectLayout>
              <template v-slot:titre> Siren de l'entreprise </template>
              <template v-slot:content>
                {{ fournisseur.entreprise.siren }}
              </template>
            </ObjectLayout>
            <ObjectLayout>
              <template v-slot:titre>Nom commercial</template>
              <template v-slot:content>
                {{ fournisseur.entreprise.nomCommercial }}
              </template>
            </ObjectLayout>
            <ObjectLayout>
              <template v-slot:titre>Nombre d'établissement(s)</template>
              <template v-slot:content>
                {{ fournisseur.entreprise.nbEtablissements }}
              </template>
            </ObjectLayout>
          </template>
        </ObjectLayout>
      </template>

      <template v-if="size == 'md'">
        <ObjectLayout size="md"> </ObjectLayout>
      </template>

      <template v-if="size == 'xs'">
        <ObjectLayout
          :action="action"
          size="xs"
          :onClickPush="`/devis-facture/fournisseurs/view/${fournisseur.id}`"
          :color="fournisseur.isOrganisation ? 'secondary' : 'null'"
        >
          <template v-slot:icon>mdi-truck</template>
          <template v-slot:content>
            {{ fournisseur.codeFournisseur }}
          </template>
          <template v-slot:extracontent>
            <v-icon small>
              <slot name="icon">mdi-chevron-right </slot>
            </v-icon>
            {{ fournisseur.entreprise.raisonSociale }}
          </template>
        </ObjectLayout>
      </template>
    </template>
  </div>
</template>

<script>
import { QUERY_fournisseur } from "./graphql/queries";
import ObjectLayout from "@/components/COMPONENTS-skeleton/Layouts/ObjectLayout.vue";

export default {
  name: "FournisseurObject",
  components: {
    ObjectLayout,
  },
  props: {
    action: { type: String, default: "router" },

    fournisseurID: {
      type: String,
    },

    fournisseurProp: {
      type: Object,
      default() {
        return {};
      },
    },

    size: { type: String, default: "md" },
  },

  watch: {
    fournisseurProp: function () {
      this.init();
    },
    fournisseurID: function () {
      this.init();
    },
  },

  data: () => ({
    fournisseur: undefined,
  }),

  mounted: function () {
    this.init();
  },

  methods: {
    init() {
      if (this.fournisseurID) {
        let id = this.fournisseurID;
        this.$apollo
          .query({
            query: QUERY_fournisseur,
            variables: { id: id },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let fournisseur = data.data.fournisseur;
            this.fournisseur = JSON.parse(JSON.stringify(fournisseur));
          });
      } else {
        this.fournisseur = JSON.parse(JSON.stringify(this.fournisseurProp));
      }
    },
  },
};
</script>
