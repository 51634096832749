<template>
  <ViewLayout
    :tabs="tabs"
    :item="requetesApiOrganisation"
  >
    <template v-slot:requetesApiOrganisation>
      <RequetesApiOrganisationObject 
        :requetesApiOrganisationProp="requetesApiOrganisation"
        size="xl"
      />
    </template>
  </ViewLayout>
</template>

<script>
import RequetesApiOrganisationObject from "./RequetesApiOrganisationObject.vue";
import ViewLayout from "@/components/COMPONENTS-skeleton/Layouts/ViewLayout.vue";

import { QUERY_requeteApiOrganisation } from "./graphql/queries.js";

export default {
  name: "RequetesApiOrganisationView",

  components: {
    RequetesApiOrganisationObject,
    ViewLayout
  },

  data: () => ({
    requetesApiOrganisation: {},
    tabs: [
      {
        icon: "mdi-api",
        displayName: "Requête d'API",
        name: "requetesApiOrganisation",
      }
    ]
  }),

  methods: {
    init() {
      this.$apollo
        .query({
          query: QUERY_requeteApiOrganisation,
          variables: {
            id: this.$route.params.id
          },
          fetchPolicy: 'no-cache'
        })
        .then(__ => {
          const data = __.data.requeteApiOrganisation;
          this.requetesApiOrganisation = data;
        })
    }
  },

  mounted() {
    this.init();
  }
}
</script>