<template>
  <ListLayout ref="list" :headers="headers" @fetch="init">
    <template v-slot:[`item.numeroDemande`]="{ item }">
      {{ item.numeroDemande }}
    </template>
    <template v-slot:[`item.montantCapital`]="{ item }">
      {{ item.montantCapital | monetaire }}
    </template>

    <template v-slot:[`item.dateDemande`]="{ item }">
      {{ item.dateDemande | shortDateFR }}
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <DemandePartenaireActions
        :object="item"
        :fetch="fetchDemandePartenaires"
        size="xs"
      />
    </template>
  </ListLayout>
</template>

<script>
import ListLayout from "@/components/COMPONENTS-skeleton/Layouts/ListLayout.vue";
import DemandePartenaireActions from "./DemandeAccordActions.vue";
import { QUERY_demandePartenaires } from "./graphql/queries";

export default {
  name: "DemandePartenairesList",

  components: {
    ListLayout,
    DemandePartenaireActions,
  },

  data: () => ({
    // Recherche tapée par l'utilisateur dans le champ "Recherche"
    search: "",

    // Les colonnes ainsi que leur nom, id et options
    headers: [
      { text: "Numéro de la demande", value: "numeroDemande", sortable: false },
      { text: "Montant du capital", value: "montantCapital" },
      { text: "Durée", value: "duree" },
      { text: "Périodicité", value: "periodicite" },
      { text: "Date de la demande", value: "dateDemande", sortable: false },
      { text: "Etat", value: "etat", sortable: false },
      { text: "Fichiers", value: "fichiers" },
      { text: "Commentaires", value: "commentaires" },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),

  methods: {
    fetchDemandePartenaires() {
      this.$refs.list.loading = true;
      this.$apollo
        .query({
          query: QUERY_demandePartenaires,
          variables: {
            pagination: this.$refs.list.pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
           
          this.$refs.list.items = data.demandePartenaires;
          this.$refs.list.itemsCount = data.demandePartenairesCount;
          this.$refs.list.loading = false;
        });
    },
    init() {
      this.fetchDemandePartenaires();
    },
  },
};
</script>

 