<template>
  <div>ServiceView</div>
</template>

<script>
export default {
  name: "ServiceView",
};
</script>

