<template>
  <div>CompteComptableSelector</div>
</template>

<script>
export default {
  name: "CompteComptableSelector",
};
</script>

