<template>
  <ListLayout
    ref="list"
    :headers="headers"
    @fetch="init"
    @showMultipleActions="showMultipleActions = $event"
  >
    <template v-slot:multipleActions>
      <ClientActions
        :multiple="true"
        :object="$refs.list ? $refs.list.itemsSelected : []"
        size="xl"
        v-show="showMultipleActions"
        @fetch="init"
        @loading="$refs.list.loading = true"
      />
    </template>
    <template v-slot:customFilters>
      <EntrepriseSelector
        v-model="customFilters.entreprise"
        :addAction="null"
      />
      <AdressePostaleVilleSelector
        v-model="etablissementsCustomFilters.libelleCommune"
      />
      <EnseigneSelector v-model="etablissementsCustomFilters.enseigne" />
    </template>
    <template v-slot:[`item.etablissement`]="{ item }">
      <ClientObject :clientProp="item" size="xs" />
    </template>
    <template v-slot:[`item.devisClientCount`]="{ item }">
      <DevisClientCounter :customSearch="[{ client: item.id }]" />
    </template>
    <template v-slot:[`item.devisFournisseurCount`]="{ item }">
      <DevisFournisseurCounter :customSearch="[{ client: item.id }]" />
    </template>
    <template v-slot:[`item.factureClientsCount`]="{ item }">
      <FactureClientsCounter :customSearch="[{ client: item.id }]" />
    </template>
    <template v-slot:[`item.factureFournisseursCount`]="{ item }">
      <FactureFournisseursCounter :customSearch="[{ client: item.id }]" />
    </template>
    <template v-slot:[`item.servicesCount`]="{ item }">
      <ServicesCounter :customSearch="[{ client: item.id }]" />
    </template>
    <template v-slot:[`item.pointFacturations`]="{ item }">
      <PointFacturationsCounter :customSearch="[{ client: item.id }]" />
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <ClientActions
        :object="item"
        size="xs"
        @fetch="init"
        @loading="$refs.list.loading = true"
      />
    </template>
  </ListLayout>
</template>

<script>
import { QUERY_clients } from "./graphql/queries";
import { QUERY_etablissementsID } from "@/components/COMPONENTS-annuaire/graphql/queries";

import AdressePostaleVilleSelector from "@/components/COMPONENTS-annuaire/AdressePostaleVilleSelector.vue";
import ClientActions from "./ClientActions.vue";
import ClientObject from "./ClientObject.vue";
import DevisClientCounter from "./DevisClientCounter.vue";
import DevisFournisseurCounter from "./DevisFournisseurCounter.vue";
import EntrepriseSelector from "@/components/COMPONENTS-annuaire/EntrepriseSelector.vue";
import EnseigneSelector from "@/components/COMPONENTS-annuaire/EnseigneSelector.vue";
import FactureClientsCounter from "./FactureClientsCounter.vue";
import FactureFournisseursCounter from "./FactureFournisseursCounter.vue";
import ListLayout from "@/components/COMPONENTS-skeleton/Layouts/ListLayout.vue";
import PointFacturationsCounter from "./PointFacturationsCounter.vue";
import ServicesCounter from "./ServicesCounter.vue";

export default {
  name: "ClientsList",

  components: {
    AdressePostaleVilleSelector,
    ClientActions,
    ClientObject,
    DevisClientCounter,
    DevisFournisseurCounter,
    EntrepriseSelector,
    EnseigneSelector,
    FactureClientsCounter,
    FactureFournisseursCounter,
    ListLayout,
    PointFacturationsCounter,
    ServicesCounter,
  },

  data: () => ({
    customFilters: {
      entreprise: null,
      etablissementsID: [],
    },
    etablissementsCustomFilters: {
      libelleCommune: null,
      enseigne: null,
    },
    headers: [
      { text: "Client", value: "etablissement", sortable: false },
      { text: "Code client", value: "codeClient" },
      { text: "Devis", value: "devisClientCount", sortable: false },
      {
        text: "Devis fournisseurs",
        value: "devisFournisseurCount",
        sortable: false,
      },
      { text: "Factures", value: "factureClientsCount", sortable: false },
      {
        text: "Factures fournisseurs",
        value: "factureFournisseursCount",
        sortable: false,
      },
      {
        text: "Points de facturation",
        value: "pointFacturations",
        sortable: false,
      },
      { text: "Fichiers", value: "fichiers" },
      { text: "Commentaires", value: "commentaires" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    showMultipleActions: false,
  }),

  methods: {
    fetchClients() {
      this.$refs.list.loading = true;
      let pagination = JSON.parse(JSON.stringify(this.$refs.list.pagination));

      if (this.customFilters.entreprise) {
        pagination.filter.customSearch.push({
          entreprise: this.customFilters.entreprise,
        });
      }
      if (this.customFilters.etablissementsID[0]) {
        pagination.filter.customSearch.push({
          etablissement: this.customFilters.etablissementsID,
        });
      }

      this.$apollo
        .query({
          query: QUERY_clients,
          variables: {
            pagination: pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          this.$refs.list.items = data.clients;
          this.$refs.list.itemsCount = data.clientsCount;
          this.$refs.list.loading = false;
        });
    },
    fetchEtablissementsID() {
      this.$apollo
        .query({
          query: QUERY_etablissementsID,
          variables: {
            pagination: {
              filter: {
                customSearch: [
                  {
                    enseigne: this.etablissementsCustomFilters.enseigne,
                  },
                  {
                    "adresse.libelleCommune":
                      this.etablissementsCustomFilters.libelleCommune,
                  },
                ],
              },
            },
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          let etablissementsID = [];
          data.etablissements.forEach((elem) => {
            etablissementsID.push(elem.id);
          });
          this.customFilters.etablissementsID = etablissementsID;
        });
    },
    init() {
      this.fetchClients();
    },
  },

  watch: {
    customFilters: {
      deep: true,
      handler() {
        this.$refs.list.currentPage = 1;
        this.fetchClients();
      },
    },
    etablissementsCustomFilters: {
      deep: true,
      handler() {
        this.fetchEtablissementsID();
      },
    },
  },
};
</script>