import gql from 'graphql-tag';
import { AUTHENTIFICATION_HABILITATION_APPLICATION_PARAMETRES_FIELDS, HABILITATION_FIELDS, ORGANISATION_FIELDS, USER_FIELDS, AUTHENTIFICATION_HABILITATION_ORGANISATION_PARAMETRES_FIELDS } from './fragments';

export const MUTATION_authentificationHabilitationApplicationParametresUpdate = gql`
  mutation($input: AuthentificationHabilitationApplicationParametresInput) {
    authentificationHabilitationApplicationParametresUpdate(input: $input) {
      ...AuthentificationHabilitationApplicationParametresFields
    }
  }${AUTHENTIFICATION_HABILITATION_APPLICATION_PARAMETRES_FIELDS}
`;

export const MUTATION_authentificationHabilitationOrganisationParametresUpdate = gql`
  mutation($input: AuthentificationHabilitationOrganisationParametresInput) {
    authentificationHabilitationOrganisationParametresUpdate(input: $input) {
      ...AuthentificationHabilitationOrganisationParametresFields
    }
  }${AUTHENTIFICATION_HABILITATION_ORGANISATION_PARAMETRES_FIELDS}
`;

export const MUTATION_equipementDelete = gql`
mutation($id: ID!){
  equipementDelete(id:$id) {
    id
    browserName
    OSName
  }
}
`;

export const MUTATION_habilitationCreate = gql`
  mutation($input:HabilitationInput!) {
    habilitationCreate(input:$input) {
      ...HabilitationFields
    }
  }${HABILITATION_FIELDS}
`;

export const MUTATION_habilitationDelete = gql`
  mutation($id: ID!){
    habilitationDelete(id:$id)
  }
`;

export const MUTATION_habilitationUpdate = gql`
  mutation($input:HabilitationInput!) {
    habilitationUpdate(input:$input) {
      ...HabilitationFields
    }
  }${HABILITATION_FIELDS}
`;

export const MUTATION_organisationCreate = gql`
  mutation($input: OrganisationInput!) {
    organisationCreate(input:$input) {
      ...OrganisationFields
    }
  }${ORGANISATION_FIELDS}
`;

export const MUTATION_organisationUpdate = gql`
  mutation($input:OrganisationInput!) {
    organisationUpdate(input:$input) {
      ...OrganisationFields
    }
  }${ORGANISATION_FIELDS}
`;

export const MUTATION_userCreate = gql`
  mutation($input: UserInput!) {
    userCreate(input:$input) {
      ...UserFields
    }
  }${USER_FIELDS}
`;

export const MUTATION_userDelete = gql`
  mutation($id: ID!){
    userDelete(id:$id)
  }
`;

export const MUTATION_userMe2FAActivate = gql`
  mutation($otp: String!) {
    userMe2FAActivate(otp: $otp)
  }
`;

export const MUTATION_userMe2FAUpdate = gql`
  mutation($password: String!, $is2FAEnabled: Boolean!) {
    userMe2FAUpdate(password: $password, is2FAEnabled: $is2FAEnabled)
  }
`;

export const MUTATION_userMeEmailUpdate = gql`
  mutation($email: String!) {
    userMeEmailUpdate(email: $email)
  }
`;

export const MUTATION_userMePasswordUpdate = gql`
  mutation($password: String!, $oldPassword: String!) {
    userMePasswordUpdate(password: $password, oldPassword: $oldPassword)
  }
`;

export const MUTATION_userMeUpdate = gql`
  mutation($input: UserInput!) {
    userMeUpdate(input: $input) {
      ...UserFields
    }
  }${USER_FIELDS}
`;

export const MUTATION_userOrganisationInscription = gql`
  mutation($input: UserInput!) {
    userOrganisationInscription(input:$input) {
      token
      refreshToken
    } 
  }
`;

export const MUTATION_userPreferencesUpdate = gql`
  mutation($input: PreferencesInput!) {
    userPreferencesUpdate(input: $input) {
      ...UserFields
    }
  }${USER_FIELDS}
`;

export const MUTATION_userResetPasswordById = gql`
  mutation($id: ID!, $password: String!, $passwordConfirmation: String!) {
    userResetPasswordById(id: $id, password: $password, passwordConfirmation: $passwordConfirmation)
  }
`;

export const MUTATION_userResetPassword = gql`
  mutation($code: String!, $password: String!, $passwordConfirmation: String!) {
    userResetPassword(code: $code, password: $password, passwordConfirmation: $passwordConfirmation)
  }
`;

export const MUTATION_userSendResetPassword = gql`
  mutation($email: String!) {
    userSendResetPassword(email: $email)
  }
`;

export const MUTATION_userUpdate = gql`
  mutation($input: UserInput) {
    userUpdate(input:$input) {
      ...UserFields
    }
  }${USER_FIELDS}
`;

export const MUTATION_userValidate = gql`
  mutation($code: String!) {
    userValidate(code: $code) {
      token
    } 
  }
`;

export const MUTATION_userValidateProvider = gql`
  mutation($userId: ID!) {
    userValidateProvider(userId: $userId) {
      ...UserFields
    }
  }${USER_FIELDS}
`;