export default [
  {
    path: '/authentification-habilitation/parametres/application',
    name: "Paramètres d'application Authentification Habilitation",
    component: () => import('./AuthentificationHabilitationApplicationParametresForm.vue'),
    meta: {
      acl: "/authentification-habilitation/parametres/application"
    }
  },
  //Paramètre
  {
    path: '/authentification-habilitation/parametres',
    name: 'Paramètres Authentification et Habilitation',
    component: () => import('./AuthentificationHabilitationOrganisationParametresForm.vue'),
    meta: {
      acl: "/authentification-habilitation/parametres"
    }
  },
  {
    path: '/authentification-habilitation/parametres/color-selector',
    name: "Paramètres de changement de couleur",
    component: () => import('./OrganisationColorSelector.vue'),
    meta: {
      acl: "/authentification-habilitation/parametres/color-selector"
    }
  },
  // USERS
  {
    path: '/authentification-habilitation/users',
    name: 'Gestion de tous les utilisateurs',
    component: () => import('./UsersList.vue'),
    meta: {
      acl: "/authentification-habilitation/users"
    }
  },
  {
    path: '/authentification-habilitation/users/list',
    name: 'Liste de tous les utilisateurs',
    component: () => import('./UsersList.vue'),
    meta: {
      acl: "/authentification-habilitation/users"
    }
  },
  {
    path: '/authentification-habilitation/users/add',
    name: 'Ajouter un utilisateur',
    component: () => import('./UserForm.vue'),
    meta: {
      acl: "/authentification-habilitation/users/add"
    }
  },
  {
    path: '/authentification-habilitation/users/edit/:id',
    name: 'Editer un utilisateur',
    component: () => import('./UserForm.vue'),
    meta: {
      acl: "/authentification-habilitation/users/edit"
    }
  },

  {
    path: '/authentification-habilitation/users/view/:id',
    name: 'Visualiser un utilisateur',
    component: () => import('./UserView.vue'),
    meta: {
      acl: "/authentification-habilitation/users/view"
    }
  },

  {
    path: '/authentification-habilitation/users/deleted',
    name: 'Liste de tous les utilisateurs supprimés',
    component: () => import('./UsersList.vue'),
    meta: {
      acl: "/authentification-habilitation/users/deleted"
    }
  },
  // Habilitations
  {
    path: '/authentification-habilitation/habilitations',
    name: 'Gestion de tous les habilitations',
    component: () => import('./HabilitationsList.vue'),
    meta: {
      acl: "/authentification-habilitation/habilitations"
    }
  },
  {
    path: '/authentification-habilitation/habilitations/list',
    name: 'Liste de toutes les habilitations',
    component: () => import('./HabilitationsList.vue'),
    meta: {
      acl: "/authentification-habilitation/habilitations"
    }
  },
  {
    path: '/authentification-habilitation/habilitations/add',
    name: 'Ajouter une habilitation (tous)',
    component: () => import('./HabilitationForm.vue'),
    meta: {
      acl: "/authentification-habilitation/habilitations/add"
    }
  },
  {
    path: '/authentification-habilitation/habilitations/edit/:id',
    name: 'Editer une habilitation (tous)',
    component: () => import('./HabilitationForm.vue'),
    meta: {
      acl: "/authentification-habilitation/habilitations/edit"
    }
  },

  {
    path: '/authentification-habilitation/habilitations/view/:id',
    name: 'Visualiser une habilitation (tous)',
    component: () => import('./HabilitationView.vue'),
    meta: {
      acl: "/authentification-habilitation/habilitations/view"
    }
  },

  {
    path: '/authentification-habilitation/habilitations/deleted',
    name: 'Liste de toutes les habilitations supprimées',
    component: () => import('./HabilitationsList.vue'),
    meta: {
      acl: "/authentification-habilitation/habilitations/deleted"
    }
  },

  {
    path: '/authentification-habilitation/magic-link/:code',
    name: 'Lien de connexion',
    component: () => import('./UserMagicLink.vue'),
    meta: {
      emptyLayout: true,
      public: true
    }
  },
  
  {
    path: '/authentification-habilitation/habilitations/miroir/create/:idHabilitation',
    name: 'Utilisation du mode miroir',
    component: () => import('./UsurpationForm.vue'),
    meta: {
      acl: '/authentification-habilitation/habilitations/usurpation'
    }
  },


  {
    path: '/authentification-habilitation/verifier/:code',
    name: 'Vérifier si l\'url est valide',
    component: () => import('./UserUrlCodeVerif.vue'),
    meta: {
      emptyLayout: true,
      public: true
    }
  },
  {
    path: '/authentification-habilitation/verifier/:code/:organisation',
    name: 'Vérifier si l\'url est valide2',
    component: () => import('./UserUrlCodeVerif.vue'),
    meta: {
      emptyLayout: true,
      public: true
    }
  },

  {
    path: '/authentification-habilitation/reinitialisation/mot-de-passe/:code',
    name: 'Réinitialiser votre mot de passe',
    component: () => import('./UserPasswordReset.vue'),
    meta: {
      emptyLayout: true,
      public: true
    }
  },

  {
    path: '/authentification-habilitation/provider/reinitialisation/mot-de-passe/:user_id',
    name: "Réinitialiser le mot de passe d'un utilisateur",
    component: () => import('./UserPasswordResetProvider.vue'),
    meta: {
      acl: "/authentification-habilitation/provider/reinitialisation/mot-de-passe"
    }
  },

  {
    path: '/authentification-habilitation/user-me/view',
    name: 'Paramètres de mon compte utilisateur',
    component: () => import('./UserMeView.vue'),
    meta: {
      acl: "/authentification-habilitation/user-me/view"
    }
  },
  {
    path: '/authentification-habilitation/user-me/edit',
    name: 'Modification de mon compte utilisateur',
    component: () => import('./UserMeEdit.vue'),
    meta: {
      acl: "/authentification-habilitation/user-me/edit"
    }
  },
  {
    path: '/authentification-habilitation/user-me/edit/password',
    name: 'Modification de mon mot de passe',
    component: () => import('./UserMeEditPassword.vue'),
    meta: {
      acl: "/authentification-habilitation/user-me/edit/password"
    }
  },
  {
    path: '/authentification-habilitation/user-me/edit/email',
    name: 'Modification de mon email',
    component: () => import('./UserMeEditEmail.vue'),
    meta: {
      acl: "/authentification-habilitation/user-me/edit/email"
    }
  },
  {
    path: '/authentification-habilitation/user-me/edit/2fa',
    name: 'Gérer la double authentification',
    component: () => import('./UserMeEdit2FA.vue'),
    meta: {
      acl: "/authentification-habilitation/user-me/edit/2fa"
    }
  },
  

  {
    path: '/authentification-habilitation/equipements',
    alias: '/administration/equipements',
    name: 'Gestion de mes équipements',
    component: () => import('./EquipementsList.vue'),
    meta: {
      acl: "/authentification-habilitation/equipements"
    }
  },

  {
    path: '/authentification-habilitation/equipements/list',
    alias: '/administration/equipements/list',
    name: 'Liste de mes équipements',
    component: () => import('./EquipementsList.vue'),
    meta: {
      acl: "/authentification-habilitation/equipements/list"
    }
  },
  {
    path: '/authentification-habilitation/equipements/view/:id',
    alias: '/administration/equipements/view',
    name: 'Visualiser mon équipement',
    component: () => import('./EquipementView.vue'),
    meta: {
      acl: "/authentification-habilitation/equipements/view"
    }
  },

  {
    path: '/authentification-habilitation/equipements/deleted',
    alias: '/administration/equipements/deleted',
    name: 'Suppression de mon équipement',
    component: () => import('./EquipementsList.vue'),
    meta: {
      acl: "/authentification-habilitation/equipements/deleted"
    }
  },

  {
    path: '/authentification-habilitation/oauth/callback/google',
    name: 'Connexion avec Google',
    component: () => import('./OAuthGoogleHandler.vue'),
    meta: {
      emptyLayout: true,
      public: true
    }
  },
  {
    path: '/authentification-habilitation/oauth/callback/microsoft-office',
    name: 'Connexion avec Office365',
    component: () => import('./OAuthOfficeHandler.vue'),
    meta: {
      emptyLayout: true,
      public: true
    }
  },

  {
    path: '/authentification-habilitation/organisations',
    alias: '/administration/organisations',
    name: 'Gestion des organisations',
    component: () => import('./OrganisationsList.vue'),
    meta: {
      acl: "/authentification-habilitation/organisations"
    }
  },
  {
    path: '/authentification-habilitation/organisations/add',
    alias: '/administration/organisations/add',
    name: 'Ajouter une organisation',
    component: () => import('./OrganisationForm.vue'),
    meta: {
      acl: "/authentification-habilitation/organisations/add"
    }
  },
  {
    path: '/authentification-habilitation/organisations/list',
    alias: '/administration/organisations/list',
    name: 'Liste des organisations',
    component: () => import('./OrganisationsList.vue'),
    meta: {
      acl: "/authentification-habilitation/organisations/list"
    }
  },
  {
    path: '/authentification-habilitation/organisations/deleted',
    alias: '/administration/organisations/deleted',
    name: 'Liste des organisations supprimées',
    component: () => import('./OrganisationsList.vue'),
    meta: {
      acl: "/authentification-habilitation/organisations/deleted"
    }
  },
  {
    path: '/authentification-habilitation/organisations/view/:id',
    alias: '/administration/organisations/view',
    name: 'Visualiser l\'organisation',
    component: () => import('./OrganisationView.vue'),
    meta: {
      acl: "/authentification-habilitation/organisations/view"
    }
  },
  {
    path: '/authentification-habilitation/organisations/edit/:id',
    alias: '/administration/organisations/edit/:id',
    name: 'Editer une organisation',
    component: () => import('./OrganisationForm.vue'),
    meta: {
      acl: "/authentification-habilitation/organisations/edit"
    }
  },

  // {
  //   path: '/authentification-habilitation/organisations/login/:nameOrId',
  //   alias: '/login/:nameOrId',
  //   name: 'Se connecter sur une organisation',
  //   component: () => import('./OrganisationLogin.vue'),
  //   meta: {
  //     public: true,
  //     emptyLayout: true
  //   }
  // }
]