<template>
  <div>
    <v-row>
      <v-col>
        <v-card class="mx-auto" color="primary" dark max-width="400">
          <v-card-title>
            <v-icon large left> mdi-package-variant-closed </v-icon>
            <span class="text-h6 font-weight-light">Equipement</span>
          </v-card-title>

          <v-card-text class="text-h5 font-weight-bold">
            "Il semblerait que ce lien n'ait pas encore d'équipement associé"
          </v-card-text>

          <v-card-actions>
            <v-btn text>
              <v-icon left> mdi-plus-circle </v-icon>
              Associer un équipement
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="mx-auto" color="primary" dark max-width="400">
          <v-card-title>
            <v-icon large left> mdi-package-variant-closed </v-icon>
            <span class="text-h6 font-weight-light">Equipement</span>
          </v-card-title>

          <v-card-text class="text-h5 font-weight-bold">
            "Il semblerait que ce lien n'ait pas encore d'équipement associé"
          </v-card-text>

          <v-card-actions>
            <v-btn text>
              <v-icon left> mdi-plus-circle </v-icon>
              Associer un équipement
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "LienServiceActionsView",
};
</script>
