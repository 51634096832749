<template>
  <ViewLayout :tabs="tabs" :item="reglementClient">
    <template v-slot:reglementClient>
      <ReglementClientActions :object="reglementClient" size="xl" />

      <v-row class="align-center mx-auto">
        <v-col cols="12">
          <ReglementClientObject
            size="xl"
            :reglementClientProp="reglementClient"
          />
        </v-col>
      </v-row>
    </template>
  </ViewLayout>
</template>
<script>
import { QUERY_reglementClient } from "./graphql/queries";

import ReglementClientActions from "./ReglementClientActions.vue";
import ReglementClientObject from "./ReglementClientObject.vue";
import ViewLayout from "@/components/COMPONENTS-skeleton/Layouts/ViewLayout.vue";

export default {
  name: "ReglementClientView",
  components: {
    ReglementClientActions,
    ReglementClientObject,
    ViewLayout,
  },

  data: () => ({
    reglementClient: {},
    tabs: [
      {
        icon: "mdi-cash-register",
        displayName: "Règlement client",
        name: "reglementClient",
      },
      { name: "commentaires" },
      { name: "fichiers" },
    ],
  }),

  apollo: {
    reglementClient: {
      query: QUERY_reglementClient,
      variables() {
        let idQuery = this.showPopup
          ? this.id
          : this.$route.params.id
          ? this.$route.params.id
          : this.id;
        return {
          id: idQuery,
        };
      },
    },
  },
};
</script>