<template>
  <div>
    <ActionLayout
      :actions="actions"
      :size="size"
      :action="action"
      :mainIcon="mainIcon"
    />
  </div>
</template>

<script>
import ActionLayout from "@/components/COMPONENTS-skeleton/Layouts/ActionLayout.vue";
import {
  MUTATION_demandePartenaireDelete,
  MUTATION_demandePartenaireUpdate,
} from "./graphql/mutations.js";
export default {
  name: "DemandePartenaireActions",
  components: {
    ActionLayout,
  },

  props: {
    withView: {
      type: Boolean,
      default: true,
    },
    mainIcon: {
      type: String,
      default: "mdi-folder-move",
    },
    object: {
      type: [Array, Object],
    },
    size: {
      type: String,
      default: "md",
    },
    action: {
      type: String,
      default: "router",
    },
    fetch: {
      type: Function,
      default() {
        console.log("defaultFetch");
      },
    },
  },
  computed: {
    actions() {
      let toReturn = [];
      if (this.object.IsActif) {
        toReturn.push(
          {
            text: "Editer",
            icon: "mdi-pencil",
            path: "/satelease/demandePartenaires/edit/" + this.object.id,
            acl: "/satelease/demandePartenaires/edit",
            color: "",
          },
          {
            text: "Supprimer",
            icon: "mdi-delete",
            method: "editIsActif",
            acl: "/satelease/demandePartenaires/deleted",
            color: "error",
          }
        );
      } else {
        toReturn.push(
          {
            text: "Restauration",
            icon: "mdi-restore",
            method: "editIsActif",
            acl: "/satelease/demandePartenaires/edit",
            color: "",
          },
          {
            text: "Supprimer définitivement",
            icon: "mdi-delete",
            method: "delete",
            acl: "/satelease/demandePartenaires/deleted",
            color: "error",
          }
        );
      }
      if (this.withView) {
        toReturn.unshift({
          text: "Visualiser",
          icon: "mdi-eye",
          path: "/satelease/demandePartenaires/view/" + this.object.id,
          acl: "/satelease/demandePartenaires/view",
          color: "",
        });
      }
      return toReturn;
    },
  },

  created() {
    if (!this.$root._events.deleteDemandePartenaire) {
      this.$root.$on("deleteDemandePartenaire", this.deleteDemandePartenaire);
    }
    if (!this.$root._events.editDemandePartenaire) {
      this.$root.$on("editDemandePartenaire", this.editDemandePartenaire);
    }
  },

  methods: {
    /**
     * Supprimer un demandePartenaire grâce à son id
     */
    deleteDemandePartenaire(id) {
      this.$apollo
        .mutate({
          mutation: MUTATION_demandePartenaireDelete,
          variables: {
            id: id,
          },
        })
        .then(() => {
          this.fetch();
        });
    },

    /**
     * Fonction à avoir dans tous les composants Actions (infos à changer selon le composant)
     * Permet de lancer la popup de validation
     *
     * obj : l'objet a supprimé
     */
    delete(obj) {
      // infos qui s'afficheront dans le popup
      let toValid = {
        titre: "Suppression définitive d'une demande de partenaire",
        texte: `Êtes-vous sûr de vouloir supprimer définitivement la demande de partenaire ${obj.numeroContrat} ?`,
        type: "warning",
        toReturn: {
          function: "deleteDemandePartenaire", // cette fonction change en fonction du composant (ex: PersonneActions.vue ça serait deletePersonne,
          //qu'il faut créer à la place de deletedemandePartenaires)
          arg: obj.id,
        },
      };

      // Envoi l'info pour ouvrir la popup
      this.$root.$emit("popupValidation", toValid);
    },

    /**
     * Fonction qui édite un demandePartenaire
     *
     * obj : l'objet à éditer
     */
    editDemandePartenaire(obj) {
      // Selon le schéma, il est obligé d'avoir le siren et la raison sociale dans EntepriseInput
      // Bien vérifier dans le schéma si jamais il y a une erreur du style "$input etc.."
      let demandePartenaire = {
        id: obj.id,
        numeroContrat: obj.numeroContrat,
        affaireAssociee: obj.affaireAssociee,
        isActif: !obj.isActif,
      };
      this.$apollo
        .mutate({
          mutation: MUTATION_demandePartenaireUpdate,
          variables: {
            input: demandePartenaire,
          },
        })
        .then(() => {
          this.fetch();
        });
    },

    /**
     * Fonction à avoir dans tous les composants (infos à changer)
     */
    editIsActif(obj) {
      let toValid = {
        titre: obj.isActif
          ? "Suppression d'une demande de partenaire"
          : "Restauration d'une contrat de location",
        texte: obj.isActif
          ? `Êtes-vous sûr de vouloir supprimer la demande de partenaire ${obj.numeroContrat} ? ` +
            'Il sera toujours présente dans l\'onglet "Corbeille"'
          : `Êtes-vous sûr de vouloir restaurer la demande de partenaire ${obj.numeroContrat} ?`,
        type: "warning",
        toReturn: {
          function: "editDemandePartenaire", // à changer selon le composant, comme deletedemandePartenaires
          arg: obj,
        },
      };

      this.$root.$emit("popupValidation", toValid);
    },
  },
};
</script>


