<template>
  <!-- Etend et complète DevisFournisseurList se trouvant dans devis-facture -->
  <div>
    <DevisFournisseursList ref="list">
      <!-- <template v-slot:[`item.aFinancer`]="{ item }"> {{ item }} </template> -->
    </DevisFournisseursList>
  </div>
</template>

<script>
import DevisFournisseursList from "@/components/COMPONENTS-devis-facture/DevisFournisseursList.vue";

export default {
  name: "CustomDevisFournisseursList",
  components: {
    DevisFournisseursList,
  },
};
</script>