<template>
  <SelectorLayout
    :addAction="addAction"
    :disabled="disabled"
    @fetch="fetch"
    :label="label"
    :prepend-icon="prependIcon"
    ref="selector"
    v-model="item"
  >
    <template v-slot:selection="data">
      <PointFacturationObject
        :pointFacturationProp="data.item"
        v-bind="data.attrs"
        :input-value="data.selected"
        @click="data.select"
        size="xs"
        action="none"
      />
    </template>

    <template v-slot:item="data">
      <PointFacturationObject
        :pointFacturationProp="data.item"
        size="xs"
        action="none"
      />
      <ClientObject
        :clientID="data.item.client.id"
        size="xs"
      />
    </template>
  </SelectorLayout>
</template>

<script>
import { QUERY_pointFacturations } from "./graphql/queries";

import ClientObject from "./ClientObject.vue";
import PointFacturationObject from "./PointFacturationObject.vue";
import SelectorLayout from "@/components/COMPONENTS-skeleton/Layouts/SelectorLayout.vue";

export default {
  name: "PointFacturationSelector",

  components: {
    ClientObject,
    PointFacturationObject,
    SelectorLayout,
  },

  props: {
    addAction: {
      type: String,
      default: "/devis-facture/point-facturations/add",
    },
    client: null,
    disabled: { type: Boolean, default: false },
    errorMessage: { type: String },
    label: { type: String, default: "Point de facturation" },
    multiple: { type: Boolean, default: false },
    prependIcon: { type: String, default: "mdi-wallet" },
    value: null,
    componentParent: undefined
  },

  mounted() {
    this.item = this.value && this.value.id ? this.value.id : this.value;
    if (this.pointFacturation) {
      this.fetch();
    }
  },


  data: () => ({
    item: null,
  }),

  methods: {
    fetch() {
      this.$refs.selector.loading = true;
      let pagination = JSON.parse(
        JSON.stringify(this.$refs.selector.pagination)
      );
      if (this.client) {
        pagination.filter.customSearch.push({
          client: this.client,
        });
      }
      this.$apollo
        .query({
          query: QUERY_pointFacturations,
          variables: {
            pagination: pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          this.$refs.selector.items = data.pointFacturations;
          this.$refs.selector.loading = false;
        });
    },
  },
  watch: {
    client: function (val) {
      if (val) {
        this.fetch();
      }
    },
    value: {
      deep: true,
      handler(val) {
        this.item = val && val.id ? val.id : val;
        this.fetch();
      },
    },

    item: {
      deep: true,
      handler(val) {
        if (val === null) {
          this.$emit("input", null);
        } else {
          this.$emit("input", val);
          if (this.componentParent) {
            this.$root.$emit(this.componentParent, val);
          }
        }
      },
    },
  },
};
</script>