import gql from 'graphql-tag';
import {
  EMAIL_FIELDS,
  EMAIL_TEMPLATE_FIELDS,
  ENVOI_NOTIFICATIONS_ORGANISATION_PARAMETRES_FIELDS
} from './fragments';

export const MUTATION_ajouterEmailEnBDD = gql`
mutation ($input: EmailInput!){
  ajouterEmailEnBDD(input: $input){
    ...EmailFields
  }
}${EMAIL_FIELDS}
`;

export const MUTATION_emailTemplateCreate = gql`
mutation ($input: EmailTemplateInput!){
  emailTemplateCreate(input:$input){
    ...EmailTemplateFields
  }
}${EMAIL_TEMPLATE_FIELDS}
`;

export const MUTATION_emailTemplateDelete = gql`
mutation($id:ID!){
  emailTemplateDelete(id:$id){
    id
  }
}
`;

export const MUTATION_emailTemplateMetaFavorite = gql`
mutation($input: EmailTemplateMetaFavoriteInput){
  emailTemplateMetaFavorite (input: $input){
    ...EmailTemplateFields
  }
}${EMAIL_TEMPLATE_FIELDS}
`;

export const MUTATION_emailTemplateUpdate = gql`
mutation($input:EmailTemplateInput!){
  emailTemplateUpdate(input:$input){
    ...EmailTemplateFields
  }
}${EMAIL_TEMPLATE_FIELDS}
`;

export const MUTATION_envoiNotificationsOrganisationParametresUpdate = gql`
  mutation($input: EnvoiNotificationsOrganisationParametresInput) {
    envoiNotificationsOrganisationParametresUpdate(input: $input) {
      ...EnvoiNotificationsOrganisationParametresFields
    }
  }${ENVOI_NOTIFICATIONS_ORGANISATION_PARAMETRES_FIELDS}
`;