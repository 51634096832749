import gql from 'graphql-tag';
import { PERSONNE_FIELDS, ENTREPRISE_FIELDS, ETABLISSEMENT_FIELDS, ENSEIGNE_FIELDS, ADRESSE_POSTALE_FIELDS } from './fragments.js';

export const MUTATION_annuaireOrganisationParametresUpdate = gql`
  mutation($input: AnnuaireOrganisationParametresInput) {
    annuaireOrganisationParametresUpdate(input: $input) {
      entrepriseAPI
    }
  }
`;

export const MUTATION_personneCreate = gql`
mutation($input: PersonneInput!) {
  personneCreate(input:$input){
    ...PersonneFields
    fonctions{
      fonction
      pouvoirSignature
      etablissement{
        ...EtablissementFields
      }
      entreprise{
        ...EntrepriseFields
      } 
    }
  }
}${PERSONNE_FIELDS}${ETABLISSEMENT_FIELDS}${ENTREPRISE_FIELDS}
`;

export const MUTATION_personneUpdate = gql`
mutation($input: PersonneInput!) {
  personneUpdate(input:$input){
    ...PersonneFields
    fonctions{
      fonction
      pouvoirSignature
      etablissement{
        ...EtablissementFields
      }
      entreprise{
        ...EntrepriseFields
      } 
    }
  }
}${PERSONNE_FIELDS}${ETABLISSEMENT_FIELDS}${ENTREPRISE_FIELDS}
`;

export const MUTATION_personneDelete = gql`
mutation($id:ID!){
  personneDelete(id:$id) {
    ...PersonneFields
  }
}${PERSONNE_FIELDS}
`;

export const MUTATION_entrepriseCreate = gql`
mutation($input: EntrepriseInput!) {
  entrepriseCreate(input:$input){
    ...EntrepriseFields
  }
}${ENTREPRISE_FIELDS}
`;

export const MUTATION_etablissementsFromEntrepriseUpdate = gql`
  mutation($idEntreprise: ID!) {
    etablissementsFromEntrepriseUpdate(idEntreprise: $idEntreprise)
  }
`;

export const MUTATION_entrepriseAndRepresentantsCreate = gql`
  mutation($input: JSONObject) {
    entrepriseAndRepresentantsCreate(input: $input) {
      ...EntrepriseFields
      representants {
        ...PersonneFields
        fonctions {
          entreprise {
            id
          }
          etablissement {
            ...EtablissementFields 
          }
          fonction
          pouvoirSignature
        }
      }
    }
  }${ENTREPRISE_FIELDS}${PERSONNE_FIELDS}${ETABLISSEMENT_FIELDS}
`;

export const MUTATION_entrepriseUpdate = gql`
mutation($input: EntrepriseInput!) {
  entrepriseUpdate(input:$input){
    ...EntrepriseFields
  }
}${ENTREPRISE_FIELDS}
`;
export const MUTATION_entrepriseDelete = gql`
mutation($id:ID!){
  entrepriseDelete(id:$id)
}
`;

export const MUTATION_enseigneCreate = gql`
  mutation ($input: EnseigneInput) {
    enseigneCreate(input: $input) {
      ...EnseigneFields
    }
  }${ENSEIGNE_FIELDS}
`;

export const MUTATION_enseigneUpdate = gql`
  mutation ($input: EnseigneInput) {
    enseigneUpdate(input: $input) {
      ...EnseigneFields
    }
  }${ENSEIGNE_FIELDS}
`;

export const MUTATION_enseigneDelete = gql`
  mutation($id: ID!) {
    enseigneDelete(id: $id) {
      ...EnseigneFields
    }
  }${ENSEIGNE_FIELDS}
`;

export const MUTATION_etablissementCreate = gql`
mutation($input: EtablissementInput!){
  etablissementCreate(input:$input){
    ...EtablissementFields
  }
}${ETABLISSEMENT_FIELDS}
`;

export const MUTATION_etablissementEnseigneAssign = gql`
mutation($etablissements:[ID], $enseigne:ID){
  etablissementEnseigneAssign(etablissements:$etablissements, enseigne:$enseigne)
}
`;

export const MUTATION_etablissementDelete = gql`
mutation($id:ID!){
  etablissementDelete(id:$id){
    ...EtablissementFields
  }
}${ETABLISSEMENT_FIELDS}
`

export const MUTATION_etablissementUpdate = gql`
mutation($input: EtablissementInput!){
  etablissementUpdate(input:$input){
    ...EtablissementFields
  }
}${ETABLISSEMENT_FIELDS}
`;

export const MUTATION_etablissementGeocodeAddress = gql`
  mutation($id: ID!) {
    etablissementGeocodeAddress(id: $id) {
      ...EtablissementFields
      adresse{
        ...AdressePostaleFields
      }
    }
  }${ETABLISSEMENT_FIELDS}${ADRESSE_POSTALE_FIELDS}
`;

export const MUTATION_personneUserAssociation = gql`
  mutation($personneID:ID!, $userID: ID!) {
  personneUserAssociation(personneID: $personneID, userID: $userID)
  }
`;


