<template>
  <div>
    <v-avatar
      @mouseover="hover = true"
      @mouseleave="hover = false"
      :color="color()"
      :size="size"
      :style="{ fontSize: fontSize + 'px' }"
      :rounded="tile"
      class="white--text font-weight-black profile"
      v-on="!justDisplay ? { click: () => (openCropper = !openCropper) } : {}"
      onMouseOver="this.style.cursor='pointer'"
    >
      <template v-if="hover && !justDisplay">
        <v-icon dark> mdi-camera </v-icon></template
      >
      <template v-else>
        <template v-if="value && value.id">
          <img :src="fichierUrl" alt="logo" />
        </template>
        <template v-else-if="initiales()">
          {{ initiales() }}
        </template>
        <template v-else>
          <v-icon dark large> mdi-image </v-icon>
        </template>
      </template>
    </v-avatar>

    <FichierImageCropper
      :trigger="openCropper"
      @update-fichier="updateFichier"
      :meta="meta"
    />
  </div>
</template>

<script>
import FichierImageCropper from "./FichierImageCropper.vue";
import { MUTATION_downloadURLCreate } from "./graphql/mutations";
import colors from "vuetify/lib/util/colors";
export default {
  name: "FichierLogo",
  components: {
    FichierImageCropper,
  },
  props: {
    value: null,
    tile: { type: Boolean, default: true },
    text: null,
    //Si à true, permet l'affichage sans hover et sans renvoi vers formulaire
    justDisplay: { type: Boolean, default: false },
    // La taille en pixel, 42 px par défaut
    size: { Number, default: 42 },
    meta: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data: () => ({
    openCropper: false,
    hover: false,
    fichierUrl: undefined,
  }),
  computed: {
    fontSize() {
      let fontSize = this.size / 2;
      if (fontSize > 14) {
        return fontSize;
      } else {
        return 14;
      }
    },
  },
  watch: {
    value: function (val) {
      if (val) {
        if (val.id) {
          this.getFile(val.id);
        } else {
          if (typeof val == "string") {
            this.getFile(val);
          }
        }
      }
    },
  },
  mounted() {
    if (this.value) {
      if (this.value.id) {
        this.getFile(this.value.id);
      } else {
        if (typeof this.value == "string") {
          this.getFile(this.value);
        }
      }
    }
  },
  methods: {
    initiales() {
      let initiales = "";
      if (this.text && this.text != " ") {
        let arrayMots = this.text.split(" ");

        for (let mot of arrayMots) {
          initiales += mot[0];
        }
      } else {
        return null;
      }

      return initiales.substring(0, 3).toUpperCase();
    },
    //Génère une couleur basée sur les initiales à l'aide d'un calcul
    color() {
      if (this.text) {
        let addition = this.text.charAt(0).charCodeAt() + this.text.length;
        let modulo = addition % 19;
        return Object.values(colors)[modulo].darken1;
      } else {
        return "grey";
      }
    },
    getFile(idFichier) {
      this.$apollo
        .mutate({
          mutation: MUTATION_downloadURLCreate,
          variables: {
            id: idFichier,
          },
        })
        .then((data) => {
          this.fichierUrl = data.data.downloadURLCreate.url;
        });
    },
    updateFichier(idFichier) {
      this.openCropper = false;
      this.$emit("input", { id: idFichier });
      this.getFile(idFichier);
    },
  },
};
</script>

