<template>
  <div>
    <ActionLayout
      :actions="actions"
      :size="size"
      :action="action"
      @fetch="$emit('fetch')"
      :mainIcon="mainIcon"
    />
    <FichierMaker
      :fromFavorite="true"
      ref="fichierMaker"
      typeParent="LienServices"
    />
  </div>
</template>

<script>
import ActionLayout from "@/components/COMPONENTS-skeleton/Layouts/ActionLayout.vue";
import FichierMaker from "@/components/COMPONENTS-fichiers-stockages/FichierMaker.vue";

import {
  MUTATION_lienServiceDelete,
  MUTATION_lienServiceUpdate,
} from "./graphql/mutations.js";

export default {
  name: "LienServiceActions",

  components: {
    ActionLayout,
    FichierMaker,
  },

  props: {
    withView: {
      type: Boolean,
      default: true,
    },
    mainIcon: {
      type: String,
      default: "mdi-link-box",
    },
    object: {
      type: [Array, Object],
    },
    size: {
      type: String,
      default: "md",
    },
    action: {
      type: String,
      default: "router",
    },
    multiple: { type: Boolean, default: false },
  },

  computed: {
    actions() {
      return [
        {
          text: "Editer",
          icon: "mdi-pencil",
          path: "/assets/lienservices/edit/" + this.object.id,
          acl: "/assets/lienservices/edit",
          color: "",
          conditions: { multiple: false, isActif: true },
        },
        {
          text: "Imprimer",
          icon: "mdi-printer",
          method: "printQRCode",
          acl: "/assets/lienservices/print",
          color: "primary",
          conditions: { multiple: true, isActif: true },
        },
        {
          text: "Supprimer",
          icon: "mdi-delete",
          method: "editIsActif",
          acl: "/assets/lienservices/deleted",
          color: "error",
          conditions: { multiple: true, isActif: true },
        },

        {
          text: "Restauration",
          icon: "mdi-restore",
          method: "editIsActif",
          acl: "/assets/lienservices/edit",
          color: "",
          conditions: { multiple: true, isActif: false },
        },
        {
          text: "Supprimer définitivement",
          icon: "mdi-delete",
          method: "delete",
          acl: "/assets/lienservices/definitive-delete",
          color: "error",
          conditions: { multiple: false, isActif: false },
        },
        {
          text: "Visualiser",
          icon: "mdi-eye",
          path: "/assets/lienservices/view/" + this.object.id,
          acl: "/assets/lienservices/view",
          color: "",
          conditions: { size: "md xs" },
        },
      ];
    },
  },

  beforeDestroy() {
    this.$root.$off("deleteLienService", this.deleteLienService);
    this.$root.$off("editLienService", this.editLienService);
  },

  /**
   * Permet d'utiliser la popup de validation
   */
  created() {
    this.$root.$on("deleteLienService", this.deleteLienService);
    this.$root.$on("editLienService", this.editLienService);
  },

  methods: {
    /**
     * Supprimer un asset grâce à son id
     */
    deleteLienService(id) {
      this.$apollo
        .mutate({
          mutation: MUTATION_lienServiceDelete,
          variables: {
            id: id,
          },
        })
        .then(() => {
          this.$emit("fetch");
        });
    },

    /**
     * Fonction à avoir dans tous les composants Actions (infos à changer selon le composant)
     * Permet de lancer la popup de validation
     *
     * obj : l'objet a supprimé
     */
    delete(obj) {
      // infos qui s'afficheront dans le popup
      let toValid = {
        titre: "Suppression définitive d'un lien de service",
        texte: `Êtes-vous sûr de vouloir supprimer définitivement le lien de service ${obj.label} ?`,
        type: "warning",
        toReturn: {
          function: "deleteLienService", // cette fonction change en fonction du composant (ex: PersonneActions.vue ça serait deletePersonne,
          //qu'il faut créer à la place de deleteAsset)
          arg: obj.id,
        },
      };

      // Envoi l'info pour ouvrir la popup
      this.$root.$emit("popupValidation", toValid);
    },

    /**
     * Fonction qui édite une asset
     *
     * obj : l'objet à éditer
     */
    async editLienService(obj) {
      // Si obj n'est pas un tableau alors on le transforme en tableau
      if (!Array.isArray(obj)) {
        obj = [obj];
      }
      // Selon le schéma, il est obligé d'avoir le siren et la raison sociale dans EntepriseInput
      // Bien vérifier dans le schéma si jamais il y a une erreur du style "$input etc.."
      this.$emit("loading");
      for (let item of obj) {
        let lienService = {
          id: item.id,
          isActif: !item.isActif,
          asset: item.asset && item.asset.id ? item.asset.id : null
        };
        
        await this.$apollo.mutate({
          mutation: MUTATION_lienServiceUpdate,
          variables: {
            input: lienService,
          },
        });
      }
      this.$emit("fetch");
    },

    /**
     * Fonction à avoir dans tous les composants (infos à changer)
     */
    editIsActif(obj) {
      // Si obj n'est pas un tableau alors on le transforme en tableau
      if (!Array.isArray(obj)) {
        obj = [obj];
      }
      let toValid = {
        titre: obj[0].isActif
          ? "Suppression de lien(s) de services"
          : "Restauration de lien(s) de services",
        texte: obj[0].isActif
          ? `Êtes-vous sûr de vouloir supprimer ${obj.length} lien(s) de services ? ` +
            'Ils seront toujours présents dans l\'onglet "Corbeille"'
          : `Êtes-vous sûr de vouloir restaurer ${obj.length} lien(s) de services ?`,
        type: "warning",
        toReturn: {
          function: "editLienService", // à changer selon le composant, comme deleteAsset
          arg: obj,
        },
      };

      this.$root.$emit("popupValidation", toValid);
    },

    async printQRCode(obj) {
      this.$refs.fichierMaker.forcedData = { lienServices: obj };
      await this.$refs.fichierMaker.generate(true);
    },
  },
};
</script>
