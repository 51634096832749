import gql from 'graphql-tag';
import { ENTREE_PLANNING_FIELDS, PLANNING_FIELDS } from './fragments'

export const MUTATION_entreePlanningCreate = gql`
    mutation($input: EntreePlanningInput) {
        entreePlanningCreate(input: $input) {
            ...EntreePlanningFields
        }
    }${ENTREE_PLANNING_FIELDS}
`;

export const MUTATION_entreePlanningUpdate = gql`
    mutation($input: EntreePlanningInput) {
        entreePlanningUpdate(input: $input) {
            ...EntreePlanningFields
        }
    }${ENTREE_PLANNING_FIELDS}
`;

export const MUTATION_entreePlanningDelete = gql`
    mutation($id: ID!) {
        entreePlanningDelete(id: $id) {
            id
        }
    }
`;

export const MUTATION_planningCreate = gql`
    mutation($input: PlanningInput) {
        planningCreate(input: $input){
            ...PlanningFields        
        }
    }${PLANNING_FIELDS}
`;

export const MUTATION_planningUpdate = gql`
    mutation($input: PlanningInput) {
        planningUpdate(input: $input){
            ...PlanningFields
            entrees{
              ...EntreePlanningFields
            }            
        }
    }${PLANNING_FIELDS}${ENTREE_PLANNING_FIELDS}
`;

export const MUTATION_planningDelete = gql`
    mutation($id: ID!) {
        planningDelete(id: $id){
            id            
        }
    }
`;

export const MUTATION_planningAddEntree = gql`
  mutation($id:ID!, $entree:ID!){
    planningAddEntree(id:$id, entree:$entree){
        ...PlanningFields
      entrees{
        ...EntreePlanningFields
      }   
    }
  }${PLANNING_FIELDS}${ENTREE_PLANNING_FIELDS}
`;