<template>
  <v-form>
    <v-row>
      <v-col>
        <v-select
          v-model="input.isBeginning"
          :items="[
            { text: 'Paiement en début de période', value: true },
            { text: 'Paiement en fin de période', value: false },
          ]"
          label="Mode de paiement"
          prepend-icon="mdi-timeline-help"
        />
      </v-col>
      <v-col>
        <v-select
          v-model="input.cf"
          :items="cfItems"
          label="Périodicité"
          prepend-icon="mdi-timeline-help"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" xl="4" lg="3" md="3" sm="6">
        <v-text-field
          v-model.number="input.rate"
          type="number"
          suffix="%"
          label="Taux du financement"
          prepend-icon="mdi-percent"
        />
      </v-col>
      <v-col cols="6" xl="4" lg="3" md="3" sm="6">
        <v-text-field
          v-model.number="input.nper"
          type="number"
          label="Nombre de périodes principales"
          prepend-icon="mdi-calendar-clock"
        />
      </v-col>
      <v-col cols="6" xl="4" lg="3" md="3" sm="6">
        <v-text-field
          v-model.number="input.nperComp"
          type="number"
          label="Nombre de périodes complémentaires"
          prepend-icon="mdi-calendar-clock"
        />
      </v-col>
      <v-col cols="6" xl="4" lg="3" md="3" sm="6">
        <v-text-field
          v-model.number="input.capitalAfinancer"
          label="Capital à financer"
          type="number"
          prepend-icon="mdi-cash-plus"
          suffix="€"
          disabled
        />
      </v-col>
      <v-col cols="6" xl="4" lg="3" md="3" sm="6">
        <v-text-field
          v-model.number="input.valeurFinFinancement"
          type="number"
          label="Valeur résiduelle"
          suffix="€"
          prepend-icon="mdi-cash-minus"
        />
      </v-col>
      <v-col cols="6" xl="4" lg="3" md="3" sm="6">
        <v-text-field
          v-model.number="input.capitalADeduire"
          type="number"
          label="Capital à déduire"
          suffix="€"
          prepend-icon="mdi-cash-minus"
        />
      </v-col>
    </v-row>
    <template v-if="loyers">
      <v-row justify="center">
        <v-col>
          <v-row class="my-2">
            <v-col align="center" justify="center">
              <v-btn
                v-bind="size"
                @click="calcLoyers()"
                :color="input.loyers && input.loyers[0] ? '' : 'primary'"
                class="mx-2 py-sm-2 my-2"
              >
                <v-icon v-bind="size" class="mr-2">mdi-calculator</v-icon>
                {{
                  input.loyers && input.loyers[0]
                    ? "Recalculer les loyers initiaux"
                    : "Calcul des Loyers initiaux"
                }}
              </v-btn>

              <v-btn
                v-bind="size"
                @click="saisonForm = !saisonForm"
                color="secondary"
                class="mx-2"
              >
                <v-icon v-bind="size" class="mr-2">mdi-calendar-clock</v-icon>
                Saisonnalité
                <v-icon v-bind="size" class="ml-2">
                  {{ saisonForm ? "mdi-chevron-down" : "mdi-chevron-up" }}
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="saisonForm">
        <v-col>
          <v-row align="center" justify="center">
            <v-alert
              text
              dense
              color="warning"
              icon="mdi-chat-alert"
              border="left"
            >
              Utiliser l'outil de saisonnalité efface les valeurs contenues dans
              le tableau de loyers. Vous pouvez ajuster le tableau après avoir
              utilisé l'outil.
            </v-alert>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                v-model="saisonInput.duree"
                :items="saisonFormDureeItems"
                label="Durée des saisons (en période)"
                prepend-icon="mdi-calendar-clock"
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="saisonInput.montant"
                label="Montant des loyers en saison"
                type="number"
                suffix="€"
                prepend-icon="mdi-cash"
              />
            </v-col>
            <v-col>
              <v-select
                v-model="saisonInput.periodeEntreSaison"
                :items="saisonFormPeriodeEntreSaisonItems"
                label="Nombre de périodes entres les saisons"
              />
            </v-col>
            <v-col>
              <v-select
                v-model="saisonInput.firstPeriodAt"
                :items="saisonFormfirstPeriodAtItems"
                label="Faire démarrer la première saison à la période "
              />
            </v-col>
            <v-col cols="1">
              <v-btn
                elevation="2"
                fab
                small
                color="primary"
                class="mt-4"
                @click="saisonsCalc"
              >
                <v-icon>mdi-calculator</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <LoyersView v-model="input.loyers" @recalcLoyers="recalcLoyers" />
      <v-row v-if="sommes.capital" class="mt-10">
        <v-col>
          Capital Remboursé
          <MoneyLayout class="ml-1" :montant="sommes.capital" />
        </v-col>
        <v-col>
          Marge Loueur
          <MoneyLayout class="ml-1" :montant="input.margeLoueur" />
        </v-col>
        <v-col>
          Intérets
          <MoneyLayout class="ml-1" :montant="sommes.interet" />
        </v-col>
        <v-col>
          Coût total <MoneyLayout class="ml-1" :montant="sommes.loyers" />
        </v-col>
      </v-row>
      <v-row v-if="input.datePrevInstallation">
        <v-col>
          Date de mise en loyer :
          {{ input.datePremierLoyer | shortDateFR }}</v-col
        >
        <v-col>
          Loyers Intercalaires
          <MoneyLayout
            class="ml-1"
            :montant="financementObj.montantLoyersIntercallaires"
          />
        </v-col>
      </v-row>
    </template>
  </v-form>
</template>

<script>
import _ from "lodash";
import { financementJS } from "@aidalinfo/calculs-financiers";

import LoyersView from "./LoyersView.vue";
import MoneyLayout from "@/components/COMPONENTS-skeleton/Layouts/MoneyLayout.vue";

export default {
  name: "FinancementForm",

  components: {
    LoyersView,
    MoneyLayout,
  },

  props: {
    datePrevInstallation: {
      type: String,
      default: undefined,
    },
    loyers: { type: Boolean, default: true },
    value: {
      type: Object,
      default() {
        return {
          isBeginning: true,
          rate: 0,
          cf: "trimestrielOpti",
          nper: 0,
          nperComp: 0,
          capitalAfinancer: 0,
          margeLoueur: 0,
          valeurFinFinancement: 0,
          loyers: [],
          dateDebutContrat: undefined,
        };
      },
    },
  },
  data: () => ({
    saisonForm: false,
    saisonInput: {
      duree: 1,
      montant: 0,
      periodeEntreSaison: 1,
      firstPeriodAt: 1,
    },
    input: {},
  }),
  computed: {
    size() {
      const size = { xs: "x-small", sm: "small", lg: "large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
    cfItems: function () {
      let items = [
        { text: "Loyers mensuels", value: "12" },
        { text: "Loyers trimestiels", value: "4" },
        { text: "Loyers semestriels", value: "2" },
        { text: "Loyers annuels", value: "1" },
      ];
      if (this.input.isBeginning) {
        items.unshift(
          { text: "Loyers mensuel optimisés", value: "mensuelOpti" },
          { text: "Loyers trimestriels optimisés", value: "trimestrielOpti" },
          { text: "Loyers semetriels optimisés", value: "semestrielOpti" },
          { text: "Loyers annuels optimisés", value: "annuelOpti" }
        );
      }

      return items;
    },
    sommes() {
      let capital = 0;
      let interet = 0;
      let loyers = 0;
      if (this.input.loyers && this.input.loyers[0]) {
        for (let loyer of this.input.loyers) {
          capital = parseFloat(capital) + parseFloat(loyer.principalPart);
          interet = parseFloat(interet) + parseFloat(loyer.interetPart);
          loyers = parseFloat(loyers) + parseFloat(loyer.pmt);
        }
      }

      return { capital: capital, interet: interet, loyers: loyers };
    },
    saisonFormDureeItems() {
      let items = [];
      for (let i = 1; i < this.input.nper; i++) {
        items.push(i);
      }
      return items;
    },
    saisonFormPeriodeEntreSaisonItems() {
      let items = [];
      for (let i = 1; i < this.input.nper; i++) {
        items.push(i);
      }
      return items;
    },
    saisonFormfirstPeriodAtItems() {
      let items = [];
      for (let i = 1; i < this.input.nper; i++) {
        items.push(i);
      }
      return items;
    },
  },

  mounted: function () {
    this.input = JSON.parse(JSON.stringify(this.value));
  },

  watch: {
    value: {
      deep: true,
      handler() {
        this.input = this.value;
      },
    },
    input: {
      deep: true,
      handler() {
        this.handleInput();
      },
    },
  },
  methods: {
    handleInput() {
      this.$emit("input", this.input);
    },
    calcLoyers() {
      let arg = { ...this.input };
      arg.datePrevInstallation = this.datePrevInstallation;
      let financementObj = financementJS(arg);
      financementObj.calcInitialLoyers();
      this.$set(this.input, "loyers", financementObj.loyers);
      this.$set(this.input, "margeLoueur", financementObj.margeLoueur);
    },
    recalcLoyers() {
      let arg = { ...this.input };
      let financementObj = financementJS(arg);

      financementObj.recalcLoyers();
      this.input.loyers = financementObj.loyers;
    },
    inputConverter(arg) {
      const obj = JSON.parse(JSON.stringify(arg));

      if (_.isEmpty(obj)) {
        return "";
      }
      let loyers = [];
      for (let objLoyer of obj.loyers) {
        let inputLoyer = {
          complementaire: objLoyer.complementaire,
          period: objLoyer.period,
          dateDebut: objLoyer.dateDebut,
          dateFin: objLoyer.dateFin,
          verrou: objLoyer.verrou,
          interetPart: objLoyer.interetPart,
          principalPart: objLoyer.principalPart,
          balance: objLoyer.balance,
          pmt: parseFloat(objLoyer.pmt),
        };
        loyers.push(inputLoyer);
      }
      let repartition = [];
      for (let objRepartition of obj.repartition) {
        let inputRepartition = {
          client:
            objRepartition.client && objRepartition.client.id
              ? objRepartition.client.id
              : objRepartition.client,
          montantTVA: objRepartition.montantTVA,
          partCapital: objRepartition.partCapital,
        };
        repartition.push(inputRepartition);
      }

      let capitalADeduire = isNaN(obj.capitalADeduire) ? 0 : obj.capitalADeduire;
      let input = {
        isBeginning: obj.isBeginning,
        cf: obj.cf,
        pf: obj.pf,
        rate: obj.rate,
        nper: obj.nper,
        nperComp: obj.nperComp,
        capitalAfinancer: obj.capitalAfinancer - capitalADeduire,
        capitalADeduire: capitalADeduire,
        capitalTotal: obj.capitalAfinancer,
        margeLoueur: obj.margeLoueur,
        valeurFinFinancement: obj.valeurFinFinancement,
        loyers: loyers,
        repartition: repartition,
      };

      return input;
    },
    saisonsCalc() {
      let arg = { ...this.input };
      arg.datePrevInstallation = this.datePrevInstallation;
      let financementObj = financementJS(arg);
      financementObj.calcInitialLoyers();

      let nbSaison = 0;
      let begin = this.saisonInput.firstPeriodAt - 1;
      let saisonDuree = this.saisonInput.duree;
      let btwSaisonDuree = this.saisonInput.periodeEntreSaison;
      financementObj.loyers.forEach((loyer) => {
        if (loyer.period > begin && loyer.period < arg.nper) {
          let saisonBegin =
            begin + nbSaison * saisonDuree + nbSaison * btwSaisonDuree + 1;
          let saisonEnd =
            begin +
            nbSaison * saisonDuree +
            nbSaison * btwSaisonDuree +
            saisonDuree;

          if (loyer.period == saisonEnd) {
            nbSaison++;
          }

          if (loyer.period >= saisonBegin && loyer.period <= saisonEnd) {
            loyer.verrou = true;
            loyer.pmt = this.saisonInput.montant;
          }
        }
      });

      financementObj.recalcLoyers();
      this.$set(this.input, "loyers", financementObj.loyers);
      this.$set(this.input, "margeLoueur", financementObj.margeLoueur);
    },
  },
};
</script>