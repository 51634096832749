<template>
  <v-container class="fluid">
    <OrganisationActions :object="organisation" :withView="false" size="xl" />
    <v-row class="align-center mx-auto">
      <v-col>
        <OrganisationObject size="xl" :organisationProp="organisation" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <HabilitationsList 
          :organisationID="organisation.id"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <AuthentificationHabilitationOrganisationParametresForm 
          :organisationID="organisation.id"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { QUERY_organisation } from "./graphql/queries";

import AuthentificationHabilitationOrganisationParametresForm from "./AuthentificationHabilitationOrganisationParametresForm.vue";
import OrganisationObject from "./OrganisationObject.vue";
import OrganisationActions from "./OrganisationActions.vue";
import HabilitationsList from "./HabilitationsList.vue";

export default {
  name: "OrganisationView",

  components: {
    OrganisationObject,
    OrganisationActions,
    HabilitationsList,
    AuthentificationHabilitationOrganisationParametresForm
  },

  data: () => ({
    organisation: {},
  }),

  apollo: {
    organisation: {
      query: QUERY_organisation,
      variables() {
        let idQuery = this.showPopup
          ? this.id
          : this.$route.params.id
          ? this.$route.params.id
          : this.id;
        return {
          id: idQuery,
        };
      },
    },
  },
  props: {
    showPopup: {
      type: Boolean,
    },
    id: {
      type: String,
    },
  },
};
</script>


