<template>
  <div>
    <v-row>
      <v-col>
        <v-row>
          <v-col v-if="personalDataCheckBox">
            <v-checkbox
              v-model="personalData"
              label="Les documents contiennent des données personnelles"
            />
          </v-col>
          <v-col>
            <v-checkbox
              v-model="securise"
              label="Les documents contiennent des données sensibles"
              v-if="securiseCheckBox"
            />
          </v-col>
        </v-row>

        <VueDropzone
          ref="vueDropzone"
          id="dropzone"
          :options="dropzoneOptions"
          :useCustomSlot="true"
          @vdropzone-file-added="fileAdded"
          @vdropzone-success="successUpload"
          @vdropzone-sending="sending"
        >
          <div class="dropzone-custom-content">
            <h3 class="dropzone-custom-title">
              <v-icon x-large color="primary" class="mr-2">
                mdi-cloud-upload
              </v-icon>
              Glissez et déposez vos fichiers ici pour les intégrer à votre
              application préférée !
            </h3>
            <div class="subtitle">
              ...ou cliquez pour sélectionner des fichiers depuis votre
              ordinateur.
            </div>
          </div>
        </VueDropzone>
      </v-col>
      <v-col> </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  MUTATION_uploadURLCreate,
  MUTATION_fichierCreate,
} from "./graphql/mutations";
import VueDropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  name: "FichierForm",
  components: {
    VueDropzone,
  },
  props: {
    personalDataCheckBox: {
      type: Boolean,
      default: true,
    },
    authorizedExtensions: {
      type: Array,
      default() {
        return [];
      },
    },
    securiseCheckBox: {
      type: Boolean,
      default: true,
    },
    // Liste séparée par des virgules
    // type:images == ensemble des fichiers images supportés par les navigateurs modernes
    // type:videos == ensemble des fichiers vidéos
    // type:audios
    acceptedFiles: {
      type: String,
      default: null,
    },
    maxFiles: {
      type: Number,
      default: null,
    },
    publicFiles: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    securise: true,
    personalData: false,
    lastUploadedFileName: null,
  }),
  computed: {
    dropzoneOptions() {
      let options = {
        arallelUploads: 1,
        method: "put",
        autoProcessQueue: false,
        url: "toDefine",
        thumbnailWidth: 100,
        uploadMultiple: false,
      };
      options.maxFiles = this.maxFiles;
      if (this.acceptedFiles && this.acceptedFiles.startsWith("type:")) {
        let type = this.acceptedFiles.substring(5, this.acceptedFiles.length);
        if (type == "images") {
          // https://developer.mozilla.org/fr/docs/Web/Media/Formats/Image_types
          options.acceptedFiles =
            ".apng, .avif, .gif, .jpg, .jpeg, .jfif, .pjpeg, .pjp, .png, .svg, .webp";
        } else if (type == "videos") {
          // https://www.reneelab.fr/extension-video.html
          options.acceptedFiles =
            ".mp4, .m4v, .mov, .qt, .avi, .flv, .wmv, .asf, .mpeg, .mpg, .vob, .mkv, .rm, .rmvb, .vob, .ts, .dat";
        } else if (type == "audios") {
          options.acceptedFiles = ".m4a, .flac, .mp3, .mp4, .wav, .wma, .aac";
        } else {
          options.acceptedFiles = null;
        }
      } else {
        options.acceptedFiles = this.acceptedFiles;
      }

      return options;
    },
  },
  methods: {
    fileAdded(file) {
      this.$apollo
        .mutate({
          mutation: MUTATION_uploadURLCreate,
          variables: {
            fileName: file.name,
          },
        })
        .then((data) => {
          let uploadUrl = data.data.uploadURLCreate;
          this.lastUploadedFileName = uploadUrl.fileName;

          this.$refs.vueDropzone.setOption("url", uploadUrl.url);
          this.$refs.vueDropzone.dropzone.processFile(file);
        });
    },
    sending(file, xhr) {
      let _send = xhr.send;
      xhr.send = function () {
        _send.call(xhr, file);
      };
    },
    successUpload(file) {
      this.$apollo
        .mutate({
          mutation: MUTATION_fichierCreate,
          variables: {
            input: {
              displayName: file.name,
              fileName: this.lastUploadedFileName,
              size: file.size,
              type: file.type,
              personalData: this.personalData,
              securise: this.securise,
              meta: this.publicFiles ? [{ isPublic: true }] : [],
            },
          },
        })
        .then((data) => {
          this.$emit(
            "fichiers-stockages--fichier-create",
            data.data.fichierCreate
          );
        });
    },
  },
};
</script>

<style scoped>
.dropzone-custom-content {
  text-align: center;
}

.dropzone-custom-title {
  margin-top: 0;
  color: var(--v-primary-base);
}

.subtitle {
  color: var(--v-secondary-base);
}

.vue-dropzone {
  border: 2px solid var(--v-primary-lighten1);
  font-family: "Roboto", sans-serif;
}
.vue-dropzone:hover {
  background-color: var(--v-celluleDark-base);
}
.dropzone {
  background-color: var(--v-celluleDark-darken1);
}
.vue-dropzone > .dz-preview .dz-details {
  background-color: var(--v-primary-base);
}
</style>
