<template>
  <div>
    <PersonneSelector />
  </div>
</template>

<script>
import PersonneSelector from "@/components/COMPONENTS-annuaire/PersonneSelector.vue";

export default {
  name: "DevTest",

  components: {
    PersonneSelector
  }
}
</script>