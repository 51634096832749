<template>
  <FormLayout icon="mdi-file-cad" @ajouter="ajouter" @modifier="modifier" @reinitialiser="init" typeItem="un devis"
    :item="input" :disabled="input.client && input.lignes[0] ? false : true">
    <v-row>
      <v-col>
        <ClientSelector v-model="input.client" label="Client" :disabled="client ? true : false" />
      </v-col>
      <v-col></v-col>
    </v-row>

    <v-row>
      <v-col>
        <DevisClientLignesList v-model="input.lignes" ref="list" @formValue="ligneTmp = $event">
          <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
            <slot :name="name" v-bind="data"></slot>
          </template>
        </DevisClientLignesList>

        <DevisClientLigneForm v-model="input.lignes" ref="lignesForm" :ligneToAddFromParent="ligneTmp">
          <template v-slot:customLigneForm="{ ligneToAdd }">
            <slot name="customLigneForm" :ligneToAdd="ligneToAdd"> </slot>
          </template>
        </DevisClientLigneForm>
      </v-col>
    </v-row>
  </FormLayout>
</template>

<script>
import { mapState } from "vuex";

import {
  MUTATION_devisClientCreate,
  MUTATION_devisClientUpdate,
} from "./graphql/mutations";
import { QUERY_devisClient } from "./graphql/queries";

import ClientSelector from "./ClientSelector.vue";
import DevisClientLigneForm from "./DevisClientLigneForm.vue";
import DevisClientLignesList from "./DevisClientLignesList.vue";
import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";

export default {
  name: "DevisClientForm",

  components: {
    ClientSelector,
    DevisClientLigneForm,
    DevisClientLignesList,
    FormLayout,
  },

  props: {
    client: undefined,
    componentParent: undefined,
  },

  data: () => ({
    devisClient: {
      lignes: [],
    },
    input: {},
    ligneTmp: {}
  }),

  watch: {
    // En cas de passage de la vue edit à add par exemple
    $route() {
      this.init();
    },
  },

  computed: {
    ...mapState(["formsErrors"]),
  },

  mounted: function () {
    this.init();
  },

  methods: {
    ajouter() {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_devisClientCreate,
          variables: {
            input: toSend,
          },
        })
        .then((__) => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "Le devis a été ajouté",
          });

          if (this.componentParent) {
            this.$root.$emit(this.componentParent, __.data.devisClientCreate);
            this.$root.$emit("skeleton--close");
          }else {
            this.$router.push({ path: `/devis-facture/devis-clients/edit/${__.data.devisClientCreate.id}`})
          }

          this.init();
        });
    },

    async init() {
      if (
        this.$route.path.startsWith("/devis-facture/devis-clients/edit/") &&
        this.$route.params.id
      ) {
        let id = this.$route.params.id;
        this.$apollo
          .query({
            query: QUERY_devisClient,
            variables: {
              id: id,
            },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let devisClient = data.data.devisClient;
            this.input = JSON.parse(JSON.stringify(devisClient));
          });
      } else {
        if (this.client) {
          this.devisClient.client = this.client;
        }
        // Si on est dans le cas d'une duplication de devis
        if (this.$route.query.duplicate) {
          let id = this.$route.query.duplicate;
          this.$apollo
            .query({
              query: QUERY_devisClient,
              variables: {
                id: id,
              },
              fetchPolicy: "no-cache",
            })
            .then((data) => {
              let devisClient = data.data.devisClient;
              devisClient.id = null;

              this.input = JSON.parse(JSON.stringify(devisClient));
            });
        } else {
          // Si on est dans le cas d'une création de devis
          this.input = JSON.parse(JSON.stringify(this.devisClient));
        }
      }
    },

    inputConverter(arg) {
      const obj = {
        ...arg,
      };
      if (Object.keys(obj).length == 0) {
        return "";
      }
      let lignes = [];
      for (let objLigne of obj.lignes) {
        let inputLigne = {
          quantite: objLigne.quantite,
          unite: objLigne.unite,
          label: objLigne.label,
          modele:
            objLigne.modele && objLigne.modele.id
              ? objLigne.modele.id
              : objLigne.modele,
          prixUnitaire: objLigne.prixUnitaire,
          tauxTva: objLigne.tauxTva,
          meta: objLigne.meta,
          marque: objLigne.marque
        };
        lignes.push(inputLigne);
      }

      let input = {
        id: obj.id,
        client: obj.client && obj.client.id ? obj.client.id : obj.client,
        lignes: lignes,
        meta: obj.meta,
      };
      return input;
    },

    modifier() {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_devisClientUpdate,
          variables: {
            input: toSend,
          },
        })
        .then(() => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "Le devis a été modifié",
          });
          this.$root.$emit("skeleton--close");
        });
    },
  },
};
</script>