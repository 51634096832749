<template>
  <v-chip
    :color="montant > 0 ? 'success' : montant < 0 ? 'error' : ''"
    class="font-weight-bold"
  >
    <v-icon class="mr-2" small>mdi-cash-multiple</v-icon>
    {{ montant | monetaire }}
  </v-chip>
</template>

<script>
export default {
  name: "MoneyLayout",
  props: ["montant"],
};
</script>

<style>
</style>