<template>
  <div>
    <ActionLayout
      :actions="actions"
      :size="size"
      :action="action"
      @fetch="$emit('fetch')"
      :mainIcon="mainIcon"
    />
  </div>
</template>

<script>
import ActionLayout from "@/components/COMPONENTS-skeleton/Layouts/ActionLayout.vue";
import {
  MUTATION_marqueDelete,
  MUTATION_marqueUpdate,
} from "./graphql/mutations.js";
export default {
  name: "MarqueActions",
  components: {
    ActionLayout,
  },

  props: {
    withView: {
      type: Boolean,
      default: true,
    },
    mainIcon: {
      type: String,
      default: "mdi-material-design",
    },
    object: {
      type: [Array, Object],
    },
    size: {
      type: String,
      default: "md",
    },
    action: {
      type: String,
      default: "router",
    },
    multiple: { type: Boolean, default: false },
  },

  computed: {
    actions() {
      return [
        {
          text: "Editer",
          icon: "mdi-pencil",
          path: "/assets/marques/edit/" + this.object.id,
          acl: "/assets/marques/edit",
          color: "",
          conditions: { multiple: false, isActif: true },
        },
        {
          text: "Supprimer",
          icon: "mdi-delete",
          method: "editIsActif",
          acl: "/assets/marques/deleted",
          color: "error",
          conditions: { multiple: true, isActif: true },
        },
        {
          text: "Restauration",
          icon: "mdi-restore",
          method: "editIsActif",
          acl: "/assets/marques/edit",
          color: "",
          conditions: { multiple: true, isActif: false },
        },
        {
          text: "Supprimer définitivement",
          icon: "mdi-delete",
          method: "delete",
          acl: "/assets/marques/definitive-delete",
          color: "error",
          conditions: { multiple: false, isActif: false },
        },
        {
          text: "Visualiser",
          icon: "mdi-eye",
          path: "/assets/marques/view/" + this.object.id,
          acl: "/assets/marques/view",
          color: "",
          conditions: {},
        },
      ];
    },
  },

  created() {
    if (!this.$root._events.deleteMarque) {
      this.$root.$on("deleteMarque", this.deleteMarque);
    }
    if (!this.$root._events.editMarque) {
      this.$root.$on("editMarque", this.editMarque);
    }
  },

  methods: {
    /**
     * Supprimer une marque grâce à son id
     */
    deleteMarque(id) {
      this.$apollo
        .mutate({
          mutation: MUTATION_marqueDelete,
          variables: {
            id: id,
          },
        })
        .then(() => {
          this.$emit("fetch");
        });
    },

    /**
     * Fonction à avoir dans tous les composants Actions (infos à changer selon le composant)
     * Permet de lancer la popup de validation
     *
     * obj : l'objet a supprimé
     */
    delete(obj) {
      // infos qui s'afficheront dans le popup
      let toValid = {
        titre: "Suppression définitive d'une marque",
        texte: `Êtes-vous sûr de vouloir supprimer définitivement la marque ${obj.nom} ?`,
        type: "warning",
        toReturn: {
          function: "deleteMarque", // cette fonction change en fonction du composant (ex: PersonneActions.vue ça serait deletePersonne,
          //qu'il faut créer à la place de deleteMarque)
          arg: obj.id,
        },
      };

      // Envoi l'info pour ouvrir la popup
      this.$root.$emit("popupValidation", toValid);
    },

    /**
     * Fonction qui édite une marque
     *
     * obj : l'objet à éditer
     */
    async editMarque(obj) {
      // Si obj n'est pas un tableau alors on le transforme en tableau
      if (!Array.isArray(obj)) {
        obj = [obj];
      }
      // Selon le schéma, il est obligé d'avoir le siren et la raison sociale dans EntepriseInput
      // Bien vérifier dans le schéma si jamais il y a une erreur du style "$input etc.."
      this.$emit("loading");
      for (let item of obj) {
        let marque = {
          id: item.id,
          nom: item.nom,
          logo: {
            id: item.logo && item.logo.id ? item.logo.id : "",
          },
          isActif: !item.isActif,
        };
        await this.$apollo.mutate({
          mutation: MUTATION_marqueUpdate,
          variables: {
            input: marque,
          },
        });
      }
      
      this.$emit("fetch");
    },

    /**
     * Fonction à avoir dans tous les composants (infos à changer)
     */
    editIsActif(obj) {
      if (!Array.isArray(obj)) {
        obj = [obj];
      }
      let toValid = {
        titre: obj[0].isActif
          ? "Suppression de marque(s)"
          : "Restauration de marque(s)",
        texte: obj[0].isActif
          ? `Êtes-vous sûr de vouloir supprimer ${obj.length} marque(s) ? ` +
            'Elle sera toujours présente dans l\'onglet "Corbeille"'
          : `Êtes-vous sûr de vouloir restaurer ${obj.length} marque(s) ?`,
        type: "warning",
        toReturn: {
          function: "editMarque", // à changer selon le composant, comme deleteMarque
          arg: obj,
        },
      };

      this.$root.$emit("popupValidation", toValid);
    },
  },
};
</script>
