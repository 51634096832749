import gql from 'graphql-tag';
import { USER_FIELDS } from '@/components/COMPONENTS-authentification-habilitation/graphql/fragments'
import { PERSONNE_FIELDS } from '@/components/COMPONENTS-annuaire/graphql/fragments';

export const COMMENTAIRE_FIELDS = gql`
  fragment CommentaireFields on Commentaire {
    id
    dateCreation
    dateUpdate
    type
    texte
    meta{
      typeParent
      idParent
    }
    user{
      ...UserFields
      personneAssociee{
        ...PersonneFields
      }
    }
  }${USER_FIELDS}${PERSONNE_FIELDS}
`;
