<template>
  <div>PlanningObject</div>
</template>

<script>
export default {
  name: "PlanningObject",
};
</script>

