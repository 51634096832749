<template>
  <div>
    <ActionLayout
      :actions="actions"
      :size="size"
      :action="action"
      @fetch="$emit('fetch')"
    />
    <FichierMaker
      :fromFavorite="true"
      ref="fichierMaker"
      typeParent="FactureClient"
    />
  </div>
</template>

<script>
import {
  MUTATION_factureClientDelete,
  MUTATION_factureClientUpdate,
} from "./graphql/mutations.js";

import { QUERY_fichiers } from "@/components/COMPONENTS-fichiers-stockages/graphql/queries.js";
import { QUERY_factureClient } from './graphql/queries.js';

import ActionLayout from "@/components/COMPONENTS-skeleton/Layouts/ActionLayout.vue";
import FichierMaker from "@/components/COMPONENTS-fichiers-stockages/FichierMaker.vue";

export default {
  name: "FactureClientActions",

  components: {
    ActionLayout,
    FichierMaker
  },

  props: {
    action: {
      type: String,
      default: "router",
    },
    multiple: { type: Boolean, default: false },
    object: {
      type: [Array, Object],
    },
    size: {
      type: String,
      default: "md",
    },
  },

  computed: {
    actions() {
      return [
        {
          text: "Editer",
          icon: "mdi-pencil",
          path: "/devis-facture/facture-clients/edit/" + this.object.id,
          acl: "/devis-facture/facture-clients/edit",
          color: "",
          conditions: {
            multiple: false,
            isActif: true,
            objet: { valide: false },
          },
        },
        {
          text: "Ajouter un règlement",
          icon: "mdi-cash-register",
          path: !Array.isArray(this.object)
            ? "/devis-facture/reglement-clients/add/?factureClientProp=" +
              this.object.id +
              "&clientProp=" +
              this.object.client.id
            : "",
          acl: "/devis-facture/reglement-clients/add",
          popup: true,
          color: "success",
          conditions: {
            multiple: false,
            isActif: true,
            objet: { valide: true, regle: false },
          },
        },
        {
          text: "Imprimer",
          icon: "mdi-printer",
          method: "printFacture",
          acl: "/devis-facture/facture-clients/print",
          color: "primary",
          conditions: {
            multiple: false,
            isActif: true,
            objet: { valide: true, regle: false },
          },
        },
        {
          text: "Dupliquer",
          icon: "mdi-content-duplicate",
          method: "duplicate",
          acl: "/devis-facture/facture-fournisseurs/add",
          color: "green",
          conditions: { multiple: false, isActif: true },
        },
        {
          text: "Valider",
          icon: "mdi-check-bold",
          path: "/devis-facture/facture-clients/validate/" + this.object.id,
          acl: "/devis-facture/facture-clients/validate",
          color: "info",
          conditions: {
            multiple: false,
            isActif: true,
            objet: { valide: false },
          },
        },
        {
          text: "Supprimer",
          icon: "mdi-delete",
          method: "editIsActif",
          acl: "/devis-facture/facture-clients/deleted",
          color: "error",
          conditions: {
            multiple: true,
            isActif: true,
            objet: { valide: false },
          },
        },
        {
          text: "Restauration",
          icon: "mdi-restore",
          method: "editIsActif",
          acl: "/devis-facture/facture-clients/edit",
          color: "",
          conditions: {
            multiple: true,
            isActif: false,
            objet: { valide: false },
          },
        },
        {
          text: "Supprimer définitivement",
          icon: "mdi-delete",
          method: "delete",
          acl: "/devis-facture/facture-clients/deleted",
          color: "error",
          conditions: {
            multiple: false,
            isActif: false,
            objet: { valide: false },
          },
        },
        {
          text: "Visualiser",
          icon: "mdi-eye",
          path: "/devis-facture/facture-clients/view/" + this.object.id,
          acl: "/devis-facture/facture-clients/view",
          color: "",
          conditions: {},
        },
      ];
    },
  },

  created() {
    this.$root.$on("component-" + this._uid, () => {
       
    });
    this.$root.$on("deleteFactureClient", this.deleteFactureClient);
    this.$root.$on("editFactureClient", this.editFactureClient);
  },

  methods: {
    /**
     * Supprimer une facture grâce à son id
     */
    deleteFactureClient(id) {
      this.$apollo
        .mutate({
          mutation: MUTATION_factureClientDelete,
          variables: {
            id: id,
          },
        })
        .then(() => {
          this.$emit("fetch");
        });
    },

    /**
     * Fonction à avoir dans tous les composants Actions (infos à changer selon le composant)
     * Permet de lancer la popup de validation
     *
     * obj : l'objet a supprimé
     */
    deleteClient(obj) {
      // infos qui s'afficheront dans le popup
      let toValid = {
        titre: "Suppression définitive d'une facture",
        texte: `Êtes-vous sûr de vouloir supprimer définitivement la facture ${obj.nom} ?`,
        type: "warning",
        toReturn: {
          function: "deleteFactureClient", // cette fonction change en fonction du composant (ex: PersonneActions.vue ça serait deletePersonne,
          //qu'il faut créer à la place de deleteFacture)
          arg: obj.id,
        },
      };

      // Envoi l'info pour ouvrir la popup
      this.$root.$emit("popupValidation", toValid);
    },

    /**
     * Fonction qui édite une facture
     *
     * obj : l'objet à éditer
     */
    async editFactureClient(obj) {
      // Si obj n'est pas un tableau alors on le transforme en tableau
      if (!Array.isArray(obj)) {
        obj = [obj];
      }
      // Selon le schéma, il est obligé d'avoir le siren et la raison sociale dans EntepriseInput
      // Bien vérifier dans le schéma si jamais il y a une erreur du style "$input etc.."
      this.$emit("loading");
      for (let item of obj) {
        let facture = {
          id: item.id,
          nom: item.nom,
          client: item.client.id,
          isActif: !item.isActif,
        };
        await this.$apollo.mutate({
          mutation: MUTATION_factureClientUpdate,
          variables: {
            input: facture,
          },
        });
      }
      this.$emit("fetch");
    },

    /**
     * Fonction à avoir dans tous les composants (infos à changer)
     */
    editIsActif(obj) {
      // Si obj n'est pas un tableau alors on le transforme en tableau
      if (!Array.isArray(obj)) {
        obj = [obj];
      }
      let toValid = {
        titre: obj[0].isActif
          ? "Suppression de facture(s)"
          : "Restauration de facture(s)",
        texte: obj.isActif
          ? `Êtes-vous sûr de vouloir supprimer ${obj.length} facture(s) ? ` +
            'Elles seront toujours présentes dans l\'onglet "Corbeille"'
          : `Êtes-vous sûr de vouloir restaurer ${obj.length} facture(s) ?`,
        type: "warning",
        toReturn: {
          function: "editFactureClient", // à changer selon le composant, comme deleteFacture
          arg: obj,
        },
      };

      this.$root.$emit("popupValidation", toValid);
    },

    async printFacture(obj) {
      // On récupère toutes les infos de la facture
      let dataFacture = await this.$apollo
        .query({
          query: QUERY_factureClient,
          variables: {
            id: obj.id
          },
          fetchPolicy: 'no-cache'
        });

      let facture = dataFacture.data.factureClient;
      
      // On cherche le template favori pour les factures clients
      let dataTemplate = await this.$apollo
        .query({
          query: QUERY_fichiers,
          variables: {
            pagination: {
              filter: {
                customSearch: [
                  { 'meta.template': true, 'meta.favorite': true, 'meta.typeParent': 'FactureClient', 
                  'meta.categorie': `Facture ${facture.valide ? 'validée' : 'non validée'}` }
                ]
              }
            }
          },
          fetchPolicy: 'no-cache'
        });

      let template = dataTemplate.data.fichiers[0];

      // Si la facture est valide et que le template existe, alors on créé le fichier
      if(facture.valide && template) {
        this.$refs.fichierMaker.forcedData = facture;
        this.$refs.fichierMaker.selectedTemplate = template;
        this.$refs.fichierMaker.withAlerte = false;
        this.$refs.fichierMaker.generate(true);
      }
    }
  },
};
</script>
