<template>
  <div>
    <template v-if="equipement">
      <template v-if="size == 'xl'">
        <ObjectLayout :action="action" size="xl">
          <template v-slot:icon>mdi-antenna</template>
          <template v-slot:titre> {{ equipement.browserName }}</template>
          <template v-slot:body>
            <ObjectLayout v-if="equipement.browserName">
              <template v-slot:titre>BrowserName</template>
              <template v-slot:content>{{ equipement.browserName }}</template>
            </ObjectLayout>
            <ObjectLayout v-if="equipement.OSName">
              <template v-slot:titre>OSName</template>
              <template v-slot:content>{{ equipement.OSName }}</template>
            </ObjectLayout>
            <ObjectLayout v-if="equipement.lastConnexion">
              <template v-slot:titre>Dernière connexion</template>
              <template v-slot:content>{{
                equipement.lastConnexion | shortDateAndHourFR
              }}</template>
            </ObjectLayout>
          </template>
        </ObjectLayout>
      </template>
      <template v-if="size == 'xs'">
        <ObjectLayout size="xs">
          <template v-slot:icon>mdi-antenna</template>
          <template v-slot:content>{{ equipement.browserName }}</template>
          <template v-slot:extracontent>
            {{ equipement.OSName }}
          </template>
        </ObjectLayout>
      </template>
      <template v-if="size == 'md'">
        <ObjectLayout size="md">
          <template v-slot:icon>mdi-antenna</template>
          <template v-slot:content>{{ equipement.browserName }}</template>
        </ObjectLayout>
      </template>
    </template>
  </div>
</template>

<script>
import { QUERY_equipement } from "./graphql/queries";
import ObjectLayout from "@/components/COMPONENTS-skeleton/Layouts/ObjectLayout.vue";
export default {
  name: "EquipementObject",

  components: {
    ObjectLayout,
  },

  props: {
    size: { type: String, default: "md" },
    equipementProp: {
      type: Object,
      default() {
        return {};
      },
    },
    equipementID: {
      type: String,
    },
    action: {
      type: String,
      default: "router",
    },
  },

  watch: {
    equipementProp: function () {
      this.init();
    },
    equipementID: function () {
      this.init();
    },
  },

  data: () => ({
    equipement: undefined,
  }),

  mounted: function () {
    this.init();
  },

  methods: {
    init() {
      if (this.equipementID) {
        let id = this.equipementID;
        this.$apollo
          .query({
            query: QUERY_equipement,
            variables: { id: id },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let equipement = data.data.equipement;
            this.equipement = JSON.parse(JSON.stringify(equipement));
          });
      } else {
        this.equipement = JSON.parse(JSON.stringify(this.equipementProp));
      }
    },
  },
};
</script>