<template>
  <FormLayout
    icon="mdi-truck"
    @ajouter="ajouter"
    @modifier="modifier"
    @reinitialiser="init"
    typeItem="un fournisseur"
    :item="input"
    :disabled="input.entreprise ? false : true"
  >
    <v-row>
      <v-col>
        <EntrepriseSelector
          v-model="input.entreprise"
          label="Société"
          :error-messages="formsErrors.fournisseurEntreprise"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <TitleLayout
          titre="Contacts"
          sousTitre="Gestion des contacts et rôles chez le fournisseur"
        />
        <v-card
          v-for="(contact, index) in input.contacts"
          :key="'contact' + index"
        >
          <v-card-title class="primary white--text py-2">
            <v-icon class="pr-2">mdi-contacts</v-icon> Contact {{ index + 1 }}
            <v-row class="text-end">
              <v-col class="mt-n10 mr-n6">
                <v-btn
                  @click="moveContact('down', index)"
                  fab
                  x-small
                  dark
                  color="info"
                  class="ml-2"
                  v-if="
                    input.contacts.length > 0 &&
                    index + 1 < input.contacts.length
                  "
                >
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>

                <v-btn
                  @click="moveContact('up', index)"
                  fab
                  x-small
                  dark
                  color="info"
                  class="ml-2"
                  v-if="index > 0 && input.contacts.length > 1"
                >
                  <v-icon>mdi-chevron-up</v-icon>
                </v-btn>

                <v-btn
                  @click="deleteContact(index)"
                  fab
                  x-small
                  dark
                  color="error"
                  class="ml-2"
                  v-if="index > 0 && input.contacts.length > 1"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-select
            label="Rôles du contact"
            v-model="contact.roles"
            multiple
            :items="clientRole"
            chips
          />
          <PersonneSelector v-model="contact.personne" />
        </v-card>
        <v-row class="mt-2">
          <v-col align="center" justify="center">
            <v-btn icon @click="addContact">
              <v-icon class="d-flex justify-center"> mdi-plus-circle </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col> <UserAttach v-model="input.users" /> </v-col>
    </v-row>
  </FormLayout>
</template>

<script>
import { mapState } from "vuex";
import { QUERY_fournisseur } from "./graphql/queries";
import {
  MUTATION_fournisseurCreate,
  MUTATION_fournisseurUpdate,
} from "./graphql/mutations";

import EntrepriseSelector from "@/components/COMPONENTS-annuaire/EntrepriseSelector";
import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";
import PersonneSelector from "@/components/COMPONENTS-annuaire/PersonneSelector.vue";
import TitleLayout from "@/components/COMPONENTS-skeleton/Layouts/TitleLayout.vue";
import UserAttach from "@/components/COMPONENTS-authentification-habilitation/UserAttach.vue";

export default {
  name: "FournisseurForm",

  components: {
    EntrepriseSelector,
    FormLayout,
    PersonneSelector,
    TitleLayout,
    UserAttach,
  },

  props: {
    componentParent: undefined,
  },

  data: () => ({
    clientRole: [
      { text: "Administration des ventes", value: "ADMINISTRATION_DES_VENTES" },
      { text: "Commercial", value: "COMMERCIAL" },
      { text: "Comptabilité", value: "COMPTABILITE" },
      { text: "Direction", value: "DIRECTION" },
      { text: "Service après-vente", value: "SERVICE_APRES_VENTE" },
      { text: "Technique", value: "TECHNIQUE" },
    ],
    fournisseur: {
      contacts: [
        {
          roles: [],
          personne: undefined,
        },
      ],
    },
    input: {},
  }),

  computed: {
    ...mapState(["formsErrors"]),
  },

  mounted: function () {
    this.init();
  },

  methods: {
    addContact() {
      this.input.contacts.push({
        roles: [],
        personne: undefined,
      });
    },
    ajouter() {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_fournisseurCreate,
          variables: {
            input: toSend,
          },
        })
        .then((__) => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "Le fournisseur a été ajouté",
          });

          if (this.componentParent) {
            this.$root.$emit(this.componentParent, __.data.fournisseurCreate);
          }

          this.$root.$emit("skeleton--close");
          this.init();
        });
    },

    deleteContact() {},

    init() {
      if (this.$route.params.id) {
        let id = this.$route.params.id;
        this.$apollo
          .query({
            query: QUERY_fournisseur,
            variables: {
              id: id,
            },
          })
          .then((data) => {
            let fournisseur = data.data.fournisseur;
            this.input = JSON.parse(JSON.stringify(fournisseur));
            // this.updatePanelEtb();
          });
      } else {
        this.input = JSON.parse(JSON.stringify(this.fournisseur));
      }
    },

    inputConverter(arg) {
      const obj = JSON.parse(JSON.stringify(arg));

      let users = [];
      if (obj.users) {
        for (let user of obj.users) {
          users.push(user.id ? user.id : user);
        }
      }

      let input = {
        id: obj.id,
        entreprise:
          obj.entreprise && obj.entreprise.id
            ? obj.entreprise.id
            : obj.entreprise,
        users: users,
      };
      return input;
    },

    modifier() {
      this.$store.commit("resetFormsErrors");
      let toSend = this.inputConverter(this.input);
      this.$apollo.mutate({
        mutation: MUTATION_fournisseurUpdate,
        variables: {
          input: toSend,
        },
        update: (store, { data: { fournisseurUpdate } }) => {
          this.$emit("fournisseurReturn", fournisseurUpdate);
          this.$store.commit("addAlert", {
            type: "info",
            text: "Le fournisseur a été modifié",
          });
        },
      });
    },
    moveContact() {},
  },
};
</script>