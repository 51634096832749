<template>
  <div>AccordValidationOrganisationParametresForm</div>
</template>

<script>
export default {
  name: "AccordValidationOrganisationParametresForm",

  components: {},

  data: () => ({}),

  methods: {}
}
</script>