<template>
  <div>
    <div class="text-h6 blue-grey--text py-2">Liste des assets attachés</div>
    <v-data-table
      dense
      :headers="headers"
      :items="assets"
      :hide-default-footer="true"
    >
      <template v-slot:[`item.asset`]="{ item }">
        <AssetObject :assetProp="item" size="xs" />
      </template>

      <template v-slot:[`item.marque`]="{ item }">
        <MarqueObject :marqueProp="item.modele.marque" size="xs" />
      </template>

      <template v-slot:[`item.modele`]="{ item }">
        <ModeleObject :modeleProp="item.modele" size="xs" />
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small @click="editAsset(item)" color="blue">
          mdi-pencil
        </v-icon>
        <v-icon small @click="removeAsset(item)" color="red">
          mdi-pin-off
        </v-icon>
      </template>
    </v-data-table>
    <v-row class="mt-5">
      <v-col> <AssetSelector v-model="assetSelected" /> </v-col>
      <v-col cols="2">
        <v-btn
          color="primary"
          @click="addAsset"
          :disabled="assetSelected ? false : true"
        >
          <v-icon class="mr-2">mdi-pin</v-icon>
          Attacher
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { QUERY_assets } from "./graphql/queries";

import AssetObject from "./AssetObject.vue";
import AssetSelector from "./AssetSelector.vue";
import MarqueObject from "./MarqueObject.vue";
import ModeleObject from "./ModeleObject.vue";

export default {
  name: "AssetAttach",

  components: {
    AssetObject,
    AssetSelector,
    MarqueObject,
    ModeleObject,
  },

  props: {
    value: null,
  },

  data: () => ({
    assetItems: [],
    assets: [],
    assetSelected: undefined,
    headers: [
      { text: "Asset", value: "asset", sortable: false },
      { text: "Marque", value: "marque", sortable: false },
      { text: "Modèle", value: "modele", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),

  methods: {
    addAsset() {
      if (this.assetSelected) {
        // On check si l'asset n'est pas déjà selectionné
        let found = this.assetItems.find(
          (element) => element == this.assetSelected
        );
        if (!found) {
          this.assetItems.push(this.assetSelected);
        } else {
          this.$store.commit("addAlert", {
            type: "warning",
            text: "L'asset est déjà attaché",
          });
        }
        this.assetSelected = undefined;
      }
    },

    editAsset(item) {
      this.$router.push({ path: `/assets/assets/edit/${item.id}?popup=true&componentParent=component-${this._uid}&idAssetEditPopup=${item.id}` })
    },

    fetchAssets() {
      let assetsIds = this.assetItems.map(e => e && e.id ? e.id : e)
      
      this.$apollo
        .query({
          query: QUERY_assets,
          variables: {
            pagination: {
              filter: {
                customSearch: [{ _id: assetsIds }],
              },
            },
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          if (data.assets.length > 1) {
            if (
              data.assets[data.assets.length - 1].modele.id !=
              data.assets[data.assets.length - 2].modele.id
            ) {
              this.$store.commit("addAlert", {
                type: "warning",
                text: "Il n'est pas possible d'ajouter des assets de modèles différents sur la même ligne de facture.",
              });
              this.assetItems.pop();
            } else {
              this.assets = data.assets;
            }
          } else {
            this.assets = data.assets;
          }
        });
    },

    removeAsset(item) {
      let index = this.assetItems.findIndex(e => e.id == item.id);
      if (index > -1) {
        this.assetItems.splice(index, 1);
      }else {
        index = this.assetItems.indexOf(item.id);
        if(index > -1) {
          this.assetItems.splice(index, 1);
        }
      }
    },
  },
  watch: {
    assetItems: function (val) {
      if (val === null || val == []) {
        this.$emit("input", []);
      } else {
        this.$emit("input", val);
        this.fetchAssets();
      }
    },

    value: function (val) {
      this.assetItems = val;
    },
  },
};
</script>