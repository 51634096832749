<template>
  <div>
    <SelectorLayout
      ref="selector"
      v-model="item"
      @fetch="fetch"
      :prepend-icon="prependIcon"
      :label="label"
      :addAction="addAction"
    >
      <template v-slot:selection="data">
        <OrganisationObject
          :organisationProp="data.item"
          v-bind="data.attrs"
          :input-value="data.selected"
          @click="data.select"
          size="xs"
          action="none"
        />
      </template>
      <template v-slot:item="data">
        <OrganisationObject
          :organisationProp="data.item"
          size="xs"
          action="none"
        />
      </template>
    </SelectorLayout>
  </div>
</template>

<script>
import { QUERY_organisations } from "./graphql/queries";
import OrganisationObject from "./OrganisationObject.vue";
import SelectorLayout from "@/components/COMPONENTS-skeleton/Layouts/SelectorLayout.vue";
export default {
  name: "OrganisationSelector",

  components: {
    OrganisationObject,
    SelectorLayout,
  },

  data: () => ({
    item: null,
  }),

  props: {
    value: null,
    errorMessage: { type: String },
    label: { type: String, default: "Organisation" },
    prependIcon: { type: String, default: "mdi-package-variant-closed" },
    multiple: { type: Boolean, default: false },
    addAction: {
      type: String,
      default: "/authentification-habilitation/organisations/add",
    },
  },

  methods: {
    // Fetch les entreprises en fonction du filtre
    fetch() {
      this.loading = true;
      this.$apollo
        .query({
          query: QUERY_organisations,
          variables: {
            pagination: this.$refs.selector.pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          this.$refs.selector.items = data.organisations;
          this.$refs.selector.loading = false;
        });
    },
  },

  watch: {
    value: {
      deep: true,
      handler(val) {
        this.item = val && val.id ? val.id : val;
        this.fetch();
      },
    },

    item: {
      deep: true,
      handler(val) {
        if (val === null) {
          this.$emit("input", null);
        } else {
          this.$emit("input", val);
        }
      },
    },
  },
};
</script>