<template>
  <div>
    <SelectorLayout
      :dense="dense"
      ref="selector"
      v-model="item"
      @fetch="fetch"
      :prepend-icon="prependIcon"
      :label="label"
      :addAction="addAction"
      @change="$emit('change', $event)"
    >
      <template v-slot:selection="data">
        <PersonneObject
          :personneProp="data.item"
          size="xs"
          v-bind="data.attrs"
          :input-value="data.selected"
          @click="data.select"
          action="none"
        />
      </template>
      <template v-slot:item="data">
        <PersonneObject :personneProp="data.item" size="xs" action="none" />
      </template>
    </SelectorLayout>
  </div>
</template>

<script>
import { QUERY_personnes } from "./graphql/queries";
import SelectorLayout from "@/components/COMPONENTS-skeleton/Layouts/SelectorLayout.vue";
import PersonneObject from "./PersonneObject";

export default {
  name: "PersonneSelector",

  components: {
    PersonneObject,
    SelectorLayout,
  },

  props: {
    addAction: { type: String, default: "/annuaire/personnes/add" },
    dense: { type: Boolean, default: false },
    errorMessages: { type: String },
    label: { type: String, default: "Personne" },
    multiple: { type: Boolean, default: false },
    prependIcon: { type: String, default: "mdi-card-account-mail-outline" },
    value: null,
  },

  data: () => ({
    item: null,
  }),

  created() {
    this.$root.$on("annuaire--personne-create", (item) => {
      this.item = item;
    });
  },

  methods: {
    // Fetch les entreprises en fonction du filtre

    fetch() {
      this.loading = true;
      this.$apollo
        .query({
          query: QUERY_personnes,
          variables: {
            pagination: this.$refs.selector.pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          this.$refs.selector.items = data.personnes;
          this.$refs.selector.loading = false;
        });
    },
  },

   watch: {
    value: {
      deep: true,
      handler(val) {
        this.item = val && val.id ? val.id : val;
        this.fetch();
      },
    },

    item: {
      deep: true,
      handler(val) {
        if (val === null) {
          this.$emit("input", null);
        } else {
          this.$emit("input", val);
        }
      },
    },
  },
};
</script>