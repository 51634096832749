<template>
  <SelectorLayout
    :addAction="addAction"
    :disabled="disabled"
    @fetch="fetch"
    :label="label"
    :prepend-icon="prependIcon"
    ref="selector"
    v-model="item"
  >
    <template v-slot:selection="data">
      <ContratLocationObject
        :contratLocationProp="data.item"
        size="xs"
        v-bind="data.attrs"
        :input-value="data.selected"
        @click="data.select"
        action="none"
      />
    </template>
    <template v-slot:item="data">
      <ContratLocationObject :contratLocationProp="data.item" size="xs" action="none" />
    </template>
  </SelectorLayout>
</template>

<script>
import { QUERY_contratLocations } from "./graphql/queries";

import ContratLocationObject from "./ContratLocationObject.vue";
import SelectorLayout from "@/components/COMPONENTS-skeleton/Layouts/SelectorLayout.vue";

export default {
  name: "ContratLocationSelector",

  components: {
    ContratLocationObject,
    SelectorLayout
  },

  props: {
    addAction: { type: String, default: "/satelease/contratsLocations/add" },
    disabled: { type: Boolean, default: false },
    errorMessages: { type: Array },
    label: { type: String, default: "Contrat de location" },
    multiple: { type: Boolean, default: false },
    prependIcon: { type: String, default: "mdi-vector-polyline" },
    value: null,
  },

  data: () => ({
    item: null,
  }),

  methods: {
    // Fetch les affaires en fonction du filtre
    fetch() {
      this.$refs.selector.loading = true;
      this.$apollo
        .query({
          query: QUERY_contratLocations,
          variables: {
            pagination: this.$refs.selector.pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          this.$refs.selector.items = data.contratLocations;
          this.$refs.selector.loading = false;
        });
    },
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        this.item = val && val.id ? val.id : val;
        this.fetch();
      },
    },

    item: {
      deep: true,
      handler(val) {
        if (val === null) {
          this.$emit("input", null);
        } else {
          this.$emit("input", val);
        }
      },
    },
  }
}
</script>