<template>
  <SelectorLayout
    :addAction="customAddAction"
    @fetch="fetch"
    :label="label"
    :prepend-icon="prependIcon"
    ref="selector"
    v-model="item"
  >
    <template v-slot:preSelect>
      <v-row v-if="!item && !devisByFournisseur">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="primary"
              v-bind="attrs"
              v-on="on"
              class="mt-4"
              @click="
                preSelect == 'fournisseur'
                  ? (preSelect = 'client')
                  : (preSelect = 'fournisseur')
              "
            >
              <v-icon> mdi-cached </v-icon>
            </v-btn>
          </template>
          <span>
            {{
              preSelect == "fournisseur"
                ? "Chercher par client"
                : "Chercher par fournisseur"
            }}
          </span>
        </v-tooltip>

        <v-col>
          <FournisseurSelector
            v-model="fournisseur"
            v-if="preSelect == 'fournisseur'"
          />
          <ClientSelector v-model="client" v-else />
        </v-col>
      </v-row>
    </template>

    <template v-slot:selection="data">
      <DevisClientObject
        :devisProp="data.item"
        size="xs"
        v-bind="data.attrs"
        :input-value="data.selected"
        @click="data.select"
        action="none"
      />
    </template>

    <template v-slot:item="data">
      <DevisObject :devisProp="data.item" action="none" />
    </template>
  </SelectorLayout>
</template>

<script>
import { QUERY_devisClients } from "./graphql/queries";

import ClientSelector from "./ClientSelector.vue";
import DevisClientObject from "./DevisClientObject.vue";
import FournisseurSelector from "./FournisseurSelector.vue";
import SelectorLayout from "@/components/COMPONENTS-skeleton/Layouts/SelectorLayout.vue";

export default {
  name: "DevisClientSelector",

  components: {
    ClientSelector,
    DevisClientObject,
    FournisseurSelector,
    SelectorLayout,
  },

  data: () => ({
    client: null,
    fournisseur: null,
    item: null,
    preSelect: "fournisseur",
  }),

  props: {
    addAction: { type: String, default: "/devis-facture/devis/add" },
    devisByFournisseur: { type: Object, default: null },
    errorMessage: { type: String },
    label: { type: String, default: "Devis" },
    multiple: { type: Boolean, default: false },
    prependIcon: { type: String, default: "mdi-file-cad" },
    value: null,
  },

  computed: {
    customAddAction() {
      let toReturn = this.addAction;
      if (this.fournisseur) {
        toReturn.includes("?") ? (toReturn += "&") : (toReturn += "?");
        toReturn += "fournisseur=" + this.fournisseur;
      }
      if (this.client) {
        toReturn.includes("?") ? (toReturn += "&") : (toReturn += "?");
        toReturn += "client=" + this.client;
      }

      return toReturn;
    },
  },

  methods: {
    // Fetch les fournisseurs en fonction du filtre
    fetch() {
      this.$refs.selector.loading = true;
      let pagination = this.$refs.selector.pagination;
      if (this.preSelect == "fournisseur" && this.fournisseur) {
        pagination.filter.customSearch.push({
          fournisseur: this.fournisseur
            ? this.fournisseur.id
              ? this.fournisseur.id
              : this.fournisseur
            : undefined,
        });
      }
      if (this.preSelect == "client" && this.client) {
        pagination.filter.customSearch.push({
          client: this.client
            ? this.client.id
              ? this.client.id
              : this.client
            : undefined,
        });
      }
      this.$apollo
        .query({
          query: QUERY_devisClients,
          variables: {
            pagination: pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          this.$refs.selector.items = data.deviss;
          this.$refs.selector.loading = false;
        });
    },
  },

  watch: {
    client(val) {
      if (val) {
        this.fetch();
      }
    },

    devisByFournisseur() {
      this.fournisseur = this.devisByFournisseur;
    },

    fournisseur(val) {
      if (val) {
        this.fetch();
      }
    },

    preSelect: function () {
      this.fournisseur = undefined;
      this.client = undefined;
    },

    value: {
      deep: true,
      handler(val) {
        this.item = val && val.id ? val.id : val;
        this.fetch();
      },
    },

    item: {
      deep: true,
      handler(val) {
        if (val === null) {
          this.$emit("input", null);
        } else {
          this.$emit("input", val);
        }
      },
    },
  },

};
</script>