
import gql from 'graphql-tag';

export const ASSET_FIELDS = gql`
fragment AssetFields on Asset{
  dureeGarantie
  id
  isActif
  dateCreation
  numeroInventaire
  numeroSerie
  label
  etatAsset
}`;

export const ASSET_ORGANISATION_PARAMETRES_FIELDS = gql`
  fragment AssetOrganisationParametresFields on AssetOrganisationParametres {
    id
    caracteristics
  }
`;

export const LIENSERVICE_FIELDS = gql`
fragment LienServiceFields on LienService{
  id
  isActif
  dateCreation
  numeroLienService
  lien
  qrCode
}`;

export const LIENSERVICEHISTORIQUEENTREE_FIELDS = gql`
fragment LienServiceHistoriqueEntreeFields on LienServiceHistoriqueEntree{
  date
}`;

export const FAMILLE_FIELDS = gql`
  fragment FamilleFields on Famille {
    id
    label
    dateCreation
  }
`;

export const MARQUE_FIELDS = gql`
fragment MarqueFields on Marque{
  id
  isActif
  dateCreation
  nom
}`;

export const MODELE_FIELDS = gql`
fragment ModeleFields on Modele{
  dateCreation
  description
  id
  isActif
  nom
  referenceFabricant
  propriete
}`;

export const AUTRE_FIELDS = gql`
fragment AutreFields on Autre{
  icone
  cle
  valeur
}`;
