<template>
  <v-col cols="12">
    <div style="height: 65px" class="d-flex align-center" no-gutters>
      <v-divider vertical inset class="primary divider" />

      <v-col class="align-center">
        <v-card-title
          class="text-uppercase font-weight-regular text-h6 text-truncate"
        >
          {{ titre }}
          <v-icon class="ml-4" v-if="icon">
            {{ icon }}
          </v-icon>
        </v-card-title>

        <v-card-subtitle
          class="text-normal text-subtitle-1 font-weight-thin text-subtitle-1"
          v-if="sousTitre"
        >
          {{ sousTitre }}
        </v-card-subtitle>
      </v-col>
    </div>
  </v-col>
</template>

<script>
export default {
  name: "TitleLayout",

  props: {
    titre: {
      type: String,
      default() {
        return null;
      },
    },
    sousTitre: {
      type: String,
      default() {
        return null;
      },
    },
    icon: {},
    separation: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
};
</script>

<style >
.divider {
  border-width: 0.1em;
}
</style>