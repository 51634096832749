<template>
  <div>
    <ActionLayout
      :actions="actions"
      :size="size"
      :action="action"
      @fetch="$emit('fetch')"
      :mainIcon="mainIcon"
    />
  </div>
</template>

<script>
import ActionLayout from "@/components/COMPONENTS-skeleton/Layouts/ActionLayout.vue";

export default {
  name: "UserMeActions",
  components: {
    ActionLayout,
  },

  props: {
    mainIcon: { type: String, default: "mdi-cog-outline" },
    object: { type: [Array, Object] },
    size: { type: String, default: "md" },
    action: { type: String, default: "router" },
  },

  computed: {
    actions() {
      return [
        {
          text: "Modifier les informations du compte",
          icon: "mdi-pencil",
          path: "/authentification-habilitation/user-me/edit",
          acl: "/authentification-habilitation/user-me/edit",
          color: "",
          conditions: { multiple: false },
        },
      ];
    },
  },
};
</script>

