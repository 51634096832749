<template>
  <FormLayout
    icon="mdi-store"
    @ajouter="ajouter"
    @modifier="modifier"
    @save="save"
    @reinitialiser="init"
    typeItem="une enseigne"
    :item="input"
    :disabled="input.nom ? false : true"
  >
    <v-row class="ma-4" justify="center" no-gutters>
      <FichierLogo size="128" v-model="input.logo" :text="input.nom" />
    </v-row>
    <v-row class="justify-center align-center">
      <v-col cols="12" xl="4" lg="4" md="4" sm="12">
        <v-text-field
          v-model="input.nom"
          :error-messages="formsErrors.AnnuaireEnseigneNom"
          name="nom"
          label="Nom de l'enseigne"
        />
        <v-textarea
          v-model="input.description"
          :error-messages="formsErrors.AnnuaireEnseigneDescription"
          name="description"
          label="Description de l'enseigne"
        />
      </v-col>
    </v-row>
  </FormLayout>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";

import {
  MUTATION_enseigneCreate,
  MUTATION_enseigneUpdate,
} from "./graphql/mutations";
import { QUERY_enseigne, QUERY_enseignes } from "./graphql/queries";

import FichierLogo from "@/components/COMPONENTS-fichiers-stockages/FichierLogo.vue";
import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";

export default {
  name: "EnseigneForm",

  components: {
    FichierLogo,
    FormLayout,
  },

  props: {
    componentParent: undefined,
  },

  data: () => ({
    input: {},
    enseigne: {
      logo: {
        id: "",
      },
    },
  }),

  computed: {
    ...mapState(["formsErrors"]),
  },

  watch: {
    // En cas de passage de la vue edit à add par exemple
    $route() {
      this.init();
    },
  },

  mounted: function () {
    this.init();
  },

  methods: {
    async ajouter() {
      await this.save()
      this.$root.$emit("skeleton--close")
      this.init()
    },

    init() {
      if (this.$route.params.id) {
        let id = this.$route.params.id;
        this.$apollo
          .query({
            query: QUERY_enseigne,
            variables: { id: id },
          })
          .then((data) => {
            this.enseigne = data.data.enseigne;
            this.input = JSON.parse(JSON.stringify(this.enseigne));
          });
      } else {
        this.input = JSON.parse(JSON.stringify(this.enseigne));
      }
    },

    inputConverter(arg) {
      const obj = JSON.parse(JSON.stringify(arg));
      if (_.isEmpty(obj)) {
        return "";
      }

      let input = {
        id: obj.id,
        nom: obj.nom,
        description: obj.description,
        logo: obj.logo && obj.logo.id ? obj.logo.id : null,
      };
      return input;
    },

    async modifier() {
      await this.save()
      this.$root.$emit("skeleton--close")
      this.init()
    },

    async save() {
      this.$store.commit("resetFormsErrors");
      let toSend = this.inputConverter(this.input);

      if(toSend.id) {
        this.$apollo.mutate({
          mutation: MUTATION_enseigneUpdate,
          variables: { input: toSend },
          refetchQueries: [{ query: QUERY_enseignes }],
          update: (store, { data: { enseigneUpdate } }) => {
            this.$emit("enseigneReturn", enseigneUpdate);
            this.$store.commit("addAlert", {
              type: "info",
              text: "L'enseigne a été modifiée",
            });
            this.$root.$emit("annuaire--update-enseignes");
            this.$root.$emit("skeleton--close");
          },
        });
      }else {
        this.$apollo
          .mutate({
            mutation: MUTATION_enseigneCreate,
            variables: { input: toSend },
          })
          .then((__) => {
            this.$store.commit("addAlert", {
              type: "info",
              text: "L'enseigne a été ajoutée",
            });

            if (this.componentParent) {
              this.$root.$emit(this.componentParent, __.data.enseigneCreate);
            }

            this.$root.$emit("skeleton--close");
            this.init();
          });
      }
    },

    updateLogo(idlogo) {
      this.input.logo = {};
      this.input.logo.id = idlogo;
    },
  },
};
</script>
