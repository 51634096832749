import gql from 'graphql-tag';

import { COMMENTAIRE_FIELDS } from './fragments';

export const QUERY_commentaires = gql`
query($pagination: PaginationObject){
  commentaires(pagination: $pagination){
    ...CommentaireFields
    commentaireParent{
      ...CommentaireFields
    }
  }
  commentairesCount(pagination: $pagination)
}${COMMENTAIRE_FIELDS}
`;

export const QUERY_commentairesCount = gql`
query($pagination: PaginationObject){
  commentairesCount(pagination:$pagination)
}
`;