<template>
  <FormLayout icon="mdi-file-cad" @ajouter="ajouter" @modifier="modifier" @reinitialiser="init"
    typeItem="un devis fournisseur" :item="input" :disabled="input.client && input.lignes[0] && input.numeroDevis ? false : true
      ">
    <v-row>
      <v-col>
        <ClientSelector v-model="input.client" label="Client (Site d'installation)" :disabled="client ? true : false" />
      </v-col>
      <v-col>
        <FournisseurSelector v-model="input.fournisseur" :disabled="fournisseur ? true : false" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field v-model="input.numeroDevis" :error-messages="formsErrors.DevisFournisseurNumeroDevis"
          label="Numéro du devis" prepend-icon="mdi-form-textbox" />
      </v-col>
      <v-col></v-col>
    </v-row>
    <v-row>
      <v-col>
        <DevisFournisseurLignesList v-model="input.lignes" ref="list" @formValue="ligneTmp = $event">
          <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
            <slot :name="name" v-bind="data"></slot>
          </template>
        </DevisFournisseurLignesList>

        <DevisFournisseurLigneForm v-model="input.lignes" ref="lignesForm" :ligneToAddFromParent="ligneTmp">
          <template v-slot:customLigneForm="{ ligneToAdd }">
            <slot name="customLigneForm" :ligneToAdd="ligneToAdd"> </slot>
          </template>
        </DevisFournisseurLigneForm>
      </v-col>
    </v-row>
  </FormLayout>
</template>

<script>
import { mapState } from "vuex";

import {
  MUTATION_devisFournisseurCreate,
  MUTATION_devisFournisseurUpdate,
} from "./graphql/mutations";
import { QUERY_devisFournisseur } from "./graphql/queries";

import ClientSelector from "./ClientSelector.vue";
import DevisFournisseurLigneForm from "./DevisFournisseurLigneForm.vue";
import DevisFournisseurLignesList from "./DevisFournisseurLignesList.vue";
import FournisseurSelector from "./FournisseurSelector.vue";
import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";

export default {
  name: "DevisFournisseurForm",

  components: {
    ClientSelector,
    DevisFournisseurLigneForm,
    DevisFournisseurLignesList,
    FournisseurSelector,
    FormLayout,
  },

  props: {
    client: undefined,
    componentParent: undefined,
    fournisseur: undefined,
  },

  data: () => ({
    devisFournisseur: {
      lignes: [],
    },
    ligneTmp: {},
    input: {},
  }),

  watch: {
    // En cas de passage de la vue edit à add par exemple
    $route() {
      this.init();
    },
  },

  computed: {
    ...mapState(["formsErrors"]),
  },

  mounted: function () {
    this.init();
  },

  methods: {
    ajouter() {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_devisFournisseurCreate,
          variables: {
            input: toSend,
          },
        })
        .then((__) => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "Le devis a été ajouté",
          });

          if (this.componentParent) {
            this.$root.$emit(
              this.componentParent,
              __.data.devisFournisseurCreate
            );
            this.$root.$emit("skeleton--close");
          } else {
            this.$router.push({ path: `/devis-facture/devis-fournisseurs/edit/${__.data.devisFournisseurCreate.id}` })
          }


          this.init();

        });
    },

    async init() {
      if (
        this.$route.path.startsWith(
          "/devis-facture/devis-fournisseurs/edit/"
        ) &&
        this.$route.params.id
      ) {
        let id = this.$route.params.id;
        this.$apollo
          .query({
            query: QUERY_devisFournisseur,
            variables: {
              id: id,
            },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let devisFournisseur = data.data.devisFournisseur;
            this.input = JSON.parse(JSON.stringify(devisFournisseur));
          });
      } else {
        if (this.client) {
          this.devisFournisseur.client = this.client;
        }
        if (this.fournisseur) {
          this.devisFournisseur.fournisseur = this.fournisseur;
        }
        // Si on est dans le cas d'une création de devis
        this.input = JSON.parse(JSON.stringify(this.devisFournisseur));
      }
    },

    inputConverter(arg) {
      const obj = JSON.parse(JSON.stringify(arg));

      if (Object.keys(obj).length == 0) {
        return "";
      }
      let lignes = [];
      for (let objLigne of obj.lignes) {
        let inputLigne = {
          quantite: objLigne.quantite,
          unite: objLigne.unite,
          label: objLigne.label,
          modele:
            objLigne.modele && objLigne.modele.id
              ? objLigne.modele.id
              : objLigne.modele,
          prixUnitaire: objLigne.prixUnitaire,
          tauxTva: objLigne.tauxTva,
          meta: objLigne.meta,
          marque: objLigne.marque && objLigne.marque.id
              ? objLigne.marque.id
              : objLigne.marque,
        };
        lignes.push(inputLigne);
      }

      let input = {
        id: obj.id,
        client: obj.client && obj.client.id ? obj.client.id : obj.client,
        fournisseur:
          obj.fournisseur && obj.fournisseur.id
            ? obj.fournisseur.id
            : obj.fournisseur,
        lignes: lignes,
        meta: obj.meta,
        numeroDevis: obj.numeroDevis,
      };
      return input;
    },

    modifier() {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_devisFournisseurUpdate,
          variables: {
            input: toSend,
          },
        })
        .then(() => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "Le devis a été modifié",
          });
          this.$root.$emit("skeleton--close");
        });
    },
  },
};
</script>