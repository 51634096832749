<template>
  <div>
    <v-otp-input
      v-model="input.otp"
      @finish="onOTPFinish"
      :error-messages="formsErrors.userOTPVerification"
    />
    <p align="center">
      <a @click="sendOTPByEmail" href="#">Envoyer le code par email</a>
    </p>
  </div>
</template>

<script>
import { QUERY_user2FAGetOTPByEmail, QUERY_userLogin2FA } from "./graphql/queries.js";
import { mapState } from "vuex";
import { onLogin } from "@/components/COMPONENTS-skeleton/vue-apollo.js";

export default {
  name: "UserOTPVerification",

  data: () => ({
    input: {
      otp: ""
    }
  }), 
  
  computed: {
    ...mapState(["formsErrors"]),
  },

  methods: {
    onOTPFinish() {
      this.$apollo
        .mutate({
          mutation: QUERY_userLogin2FA,
          variables: {
            otp: this.input.otp
          }
        })
        .then(async __ => {
          const data = __.data;
          await onLogin(
            this.$apollo.provider.defaultClient,
            data.userLogin2FA.token,
            data.userLogin2FA.refreshToken
          );
          this.$emit('verif');
        })
    },

    sendOTPByEmail() {
      this.$apollo
        .query({
          query: QUERY_user2FAGetOTPByEmail,
          fetchPolicy: 'no-cache'
        })
        .then(() => {
           this.$store.commit("addAlert", {
            type: "info",
            text: "L'email a été envoyé. Le code a une durée de 2 minutes avant expiration.",
          });
          this.$root.$emit("skeleton--close");
        })
    }
  }
}
</script>