<template>
  <div>
    <v-data-table
      :items="loyers"
      :headers="headers"
      disable-pagination
      hide-default-footer
      dense
    >
      <template v-slot:[`item.period`]="{ item }">
        {{ item.period }}
      </template>
      <template v-slot:[`item.date`]="{ item }">
        <template v-if="item.dateDebut && item.dateFin">
          Du {{ item.dateDebut | shortDateFR }} au
          {{ item.dateFin | shortDateFR }}
        </template>
      </template>
      <template v-slot:[`item.verrou`]="{ item }">
        <v-tooltip bottom v-if="item.complementaire">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">mdi-calendar-plus</v-icon>
          </template>
          <span>Période complémentaire</span>
        </v-tooltip>

        <v-btn
          v-if="item.period != nbPer && !item.complementaire"
          icon
          @click="edit ? (item.verrou = !item.verrou) : false"
          :color="item.verrou ? 'error' : 'success'"
        >
          <v-icon>{{ item.verrou ? "mdi-lock" : "mdi-lock-open" }}</v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.pmt`]="{ item }">
        <template v-if="item.verrou">
          <v-text-field
            v-model="item.pmt"
            v-if="edit"
            append-icon="mdi-calculator"
            @keyup="resetLoyersNonVerrouiller()"
            @click:append="recalcLoyers()"
          />
          <span class="font-weight-bold" v-else>
            {{ item.pmt | monetaire }}
          </span>
        </template>
        <template v-else>{{ item.pmt | monetaire }}</template>
      </template>
      <template v-slot:[`item.interetPart`]="{ item }">
        {{ item.interetPart | monetaire }}
      </template>
      <template v-slot:[`item.principalPart`]="{ item }">
        {{ item.principalPart | monetaire }}
      </template>
      <template v-slot:[`item.balance`]="{ item }">
        {{ item.balance | monetaire }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "LoyersView",
  components: {},
  data: () => ({
    loyers: [],
    headers: [
      { text: "Période", value: "period", sortable: false },
      { text: "Date", value: "date", sortable: false },
      { text: "Verrou", value: "verrou", sortable: false },
      { text: "Paiement", value: "pmt", sortable: false },
      { text: "Part interet", value: "interetPart", sortable: false },
      { text: "Part principale", value: "principalPart", sortable: false },
      { text: "Balance", value: "balance", sortable: false },
    ],
    sommeCapitalRembourse: 0,
    sommeInteret: 0,
    sommeLoyers: 0,
  }),
  props: {
    edit: { type: Boolean, default: true },
    value: {
      typeof: Array,
      default() {
        return {};
      },
    },
  },
  computed: {
    nbPer() {
      let nbPer = 0;
      for (let loyer of this.loyers) {
        if (!loyer.complementaire) {
          nbPer++;
        }
      }
      return nbPer;
    },
  },
  mounted: function () {
    this.loyers = this.value;
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.loyers = this.value;
      },
    },
    loyers: {
      deep: true,
      handler() {
        this.handleInput();
      },
    },
  },
  methods: {
    handleInput() {
      this.$emit("input", this.loyers);
    },
    recalcLoyers() {
      this.$emit("recalcLoyers");
    },
    resetLoyersNonVerrouiller() {
      for (let loyer of this.loyers) {
        loyer.principalPart = 0;
        loyer.interetPart = 0;
        loyer.balance = 0;
        if (!loyer.verrou) {
          loyer.pmt = 0;
        }
      }
    },
  },
};
</script>

