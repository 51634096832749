<template>
  <div>
    <template v-if="planComptable">
      <v-expansion-panels>
        <v-expansion-panel
          v-for="(classe, index) in planComptable.classes"
          :key="'classe' + index"
        >
          <v-expansion-panel-header>
            Classe {{ classe.numero }} - {{ classe.label }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
              :headers="headers"
              :items="classe.comptes"
              hide-default-footer
              disable-pagination
            ></v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
  </div>
</template>

<script>
import { QUERY_planComptable } from "./graphql/queries";
export default {
  name: "PlanComptableView",
  components: {},
  data: () => ({
    headers: [
      { text: "Numero", value: "numero", sortable: false },
      { text: "Label", value: "label", sortable: false },
    ],
  }),
  apollo: {
    planComptable: {
      query: QUERY_planComptable,
      fetchPolicy: "no-cache",
    },
  },
};
</script>

