<template>
  <div>
    <SelectorLayout
      ref="selector"
      v-model="item"
      @fetch="fetch"
      :prepend-icon="prependIcon"
      :label="label"
      :addAction="addAction"
    >
      <template v-slot:selection="data">
        <EnseigneObject
          :enseigneProp="data.item"
          v-bind="data.attrs"
          :input-value="data.selected"
          @click="data.select"
          size="xs"
          action="none"
        />
      </template>

      <template v-slot:item="data">
        <EnseigneObject :enseigneProp="data.item" size="xs" action="none" />
      </template>
    </SelectorLayout>
  </div>
</template>

<script>
import { QUERY_enseignes } from "./graphql/queries";
import EnseigneObject from "./EnseigneObject";
import SelectorLayout from "@/components/COMPONENTS-skeleton/Layouts/SelectorLayout.vue";

export default {
  name: "EnseigneSelector",

  components: {
    EnseigneObject,
    SelectorLayout,
  },

  props: {
    value: null,
    errorMessage: { type: String },
    label: { type: String, default: "Enseigne" },
    prependIcon: { type: String, default: "mdi-store" },
    multiple: { type: Boolean, default: false },
    addAction: { type: String, default: "/annuaire/enseignes/add" },
  },

  created() {
    this.$root.$on("annuaire--enseigne-create", (item) => {
      this.item = item;
    });
  },

  data: () => ({
    item: null,
  }),

  methods: {
    // Fetch les entreprises en fonction du filtre

    fetch() {
      this.loading = true;
      this.$apollo
        .query({
          query: QUERY_enseignes,
          variables: {
            pagination: this.$refs.selector.pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          this.$refs.selector.items = data.enseignes;
          this.$refs.selector.loading = false;
        });
    },
  },

  watch: {
    value: {
      deep: true,
      handler(val) {
        this.item = val && val.id ? val.id : val;
        this.fetch();
      },
    },

    item: {
      deep: true,
      handler(val) {
        if (val === null) {
          this.enseignes = [];
          this.$emit("input", null);
        } else {
          this.$emit("input", val);
        }
      },
    },
  },
};
</script>