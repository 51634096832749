<template>
  <v-form @submit.prevent="envoiFichier">
    <v-row>
      <v-col>
        <v-file-input
          :rules="[verifExtensions]"
          v-model="fichier"
          :label="label"
        />
        <v-text-field
          v-model="displayName"
          label="Nom du fichier"
          prepend-icon="mdi-alphabetical"
          :suffix="'.' + extension"
          required
        />
        <v-combobox
          v-show="useCategory"
          v-model="categorie"
          :items="fichierCategories"
          label="Catégorie"
          chips
          clearable
          prepend-icon="mdi-table"
          dense
          class="pt-1"
          ref="userCBox"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        <v-btn
          :disabled="
            this.fichier == null ||
            (this.authorizedExtensions.length > 0 &&
              this.authorizedExtensions.indexOf(this.extension) == -1)
          "
          color="primary"
          type="submit"
        >
          <v-icon class="mr-2">mdi-file-upload</v-icon>Envoyer
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import {
  MUTATION_uploadURLCreate,
  MUTATION_fichierCreate,
} from "./graphql/mutations";
import { QUERY_fichierCategories } from "./graphql/queries";

export default {
  name: "TypeFichierForm",
  components: {},
  data: () => ({
    fichier: null,
    displayName: "",
    note: "",
    extension: "",
    categorie: "",
  }),
  apollo: {
    fichierCategories: {
      query: QUERY_fichierCategories,
      variables() {
        return {
          typeParent: this.meta.typeParent,
        };
      },
      fetchPolicy: "no-cache",
    },
  },

  props: {
    securise: { type: Boolean, default: true },
    meta: { type: Object },
    useCategory: { type: Boolean, default: true },
    authorizedExtensions: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  computed: {
    label: function () {
      let label = "Fichier.";
      if (this.authorizedExtensions.length > 0) {
        this.authorizedExtensions.forEach((elem) => {
          label += elem + "/";
        });
        label = label.substring(0, label.length - 1);
      } else {
        label += "*";
      }

      return label;
    },
  },

  watch: {
    fichier: function (val) {
       
      if (val) {
        let elementsName = val.name.split(".");
        let extension = elementsName.pop();
        let fileName = elementsName.join();
        this.displayName = fileName;
        this.extension = extension;
      } else {
        this.fichier = null;
        this.displayName = "";
        this.note = "";
        this.extension = "";
        this.categorie = "";
      }
    },
  },
  methods: {
    envoiFichier() {
      // permet de récupérer la catégorie même sans focusOut du combobox
      this.$refs.userCBox.updateSelf();

      let meta = this.meta;

      this.categorie ? (meta.categorie = this.categorie) : this.meta.categorie;

      if (
        this.authorizedExtensions.length == 0 ||
        this.authorizedExtensions.indexOf(this.extension) != -1
      ) {
        this.$apollo
          .mutate({
            mutation: MUTATION_uploadURLCreate,
            variables: {
              fileName: this.fichier.name,
            },
          })
          .then((data) => {
            let uploadUrl = data.data.uploadURLCreate;
            fetch(uploadUrl.url, {
              method: "PUT",
              body: this.fichier,
            })
              .then(() => {
                this.$apollo.mutate({
                  mutation: MUTATION_fichierCreate,
                  variables: {
                    input: {
                      displayName: this.displayName,
                      extension: this.extension,
                      fileName: uploadUrl.fileName,
                      size: this.fichier.size,
                      type: this.fichier.type,
                      note: "note",
                      personalData: true,
                      meta: [this.meta],
                      securise: this.securise
                    },
                  },
                });
              })
              .then(() => {
                this.init();
                this.$root.$emit("fichiersStockagesUpdateFichiers");
                this.$store.commit("addAlert", {
                  type: "info",
                  text: "Le document est ajouté à la liste des fichiers",
                });
              })
              .catch((e) => {
                console.error(e);
              });
          });
      }
    },

    isFormDisabled() {
      if (this.extension && this.authorizedExtensions.length > 0) {
        return this.authorizedExtensions.indexOf(this.extension) == -1;
      } else {
        return false;
      }
    },

    init() {
      this.fichier = null;
      this.displayName = "";
      this.note = "";
      this.extension = "";
      this.categorie = "";
    },

    verifExtensions(value) {
      if (
        value &&
        this.authorizedExtensions.length > 0 &&
        this.authorizedExtensions.indexOf(value.name.split(".").pop()) == -1
      ) {
        return "L'extension du fichier n'est pas acceptée ici";
      } else {
        return true;
      }
    },
  },
};
</script>

