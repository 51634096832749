<template>
  <ViewLayout :tabs="tabs" :item="bonDeCommandeFournisseur">
    <template v-slot:bonDeCommandeFournisseur>
      <BonDeCommandeFournisseurActions
        :object="bonDeCommandeFournisseur"
        size="xl"
      />

      <v-row class="align-center mx-auto">
        <v-col cols="12">
          <BonDeCommandeFournisseurObject
            size="xl"
            :bonDeCommandeFournisseurProp="bonDeCommandeFournisseur"
          />
        </v-col>
      </v-row>
    </template>
  </ViewLayout>
</template>
<script>
import { QUERY_bonDeCommandeFournisseur } from "./graphql/queries";

import BonDeCommandeFournisseurActions from "./BonDeCommandeFournisseurActions.vue";
import BonDeCommandeFournisseurObject from "./BonDeCommandeFournisseurObject.vue";
import ViewLayout from "@/components/COMPONENTS-skeleton/Layouts/ViewLayout.vue";

export default {
  name: "BonDeCommandeFournisseurView",
  components: {
    BonDeCommandeFournisseurActions,
    BonDeCommandeFournisseurObject,
    ViewLayout,
  },

  data: () => ({
    bonDeCommandeFournisseur: {},
    tabs: [
      {
        icon: "mdi-file-send",
        displayName: "Bon de Commande Fournisseur",
        name: "bonDeCommandeFournisseur",
      },
      { name: "commentaires" },
      { name: "fichiers" },
    ],
  }),

  apollo: {
    bonDeCommandeFournisseur: {
      query: QUERY_bonDeCommandeFournisseur,
      variables() {
        let idQuery = this.showPopup
          ? this.id
          : this.$route.params.id
          ? this.$route.params.id
          : this.id;
        return {
          id: idQuery,
        };
      },
    },
  },
};
</script>