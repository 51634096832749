<template>
  <div>
    <div class="text-h6 blue-grey--text py-2">
      Liste des factures clients attachées
    </div>
    <v-data-table
      dense
      :headers="headers"
      :items="factureClients"
      :items-per-page="500"
      :hide-default-footer="true"
    >
      <template v-slot:[`item.factureClient`]="{ item }">
        <FactureClientObject :factureClientProp="item" size="xs" />
      </template>
      <template v-slot:[`item.client`]="{ item }">
        <ClientObject :clientID="item.client.id" size="xs" />
      </template>
      <template v-slot:[`item.montantAFinancer`]="{ item }">
        {{ item.montantAFinancer | monetaire }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small @click="removeFacture(item)" color="red">
          mdi-pin-off
        </v-icon>
      </template>
    </v-data-table>
    <v-row class="mt-5" justify="center">
      <v-col cols="8">
        <FactureClientSelector v-model="factureClientSelected" />
      </v-col>
      <v-col cols="12" xl="2" lg="2" md="2" sm="12" class="px-0">
        <v-btn
          v-bind="size"
          block
          class="py-4"
          color="primary"
          @click="addFactureClient"
          :disabled="factureClientSelected ? false : true"
        >
          <v-icon class="mr-2">mdi-pin</v-icon>
          Attacher
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { QUERY_factureClients } from "./graphql/queries";

import ClientObject from "./ClientObject.vue";
import FactureClientObject from "./FactureClientObject.vue";
import FactureClientSelector from "./FactureClientSelector.vue";

export default {
  name: "FactureClientAttach",

  components: {
    ClientObject,
    FactureClientObject,
    FactureClientSelector,
  },

  props: {
    value: [],
  },

  data: () => ({
    factureClients: [],
    factureClientItems: [],
    factureClientSelected: undefined,
    headers: [
      { text: "Facture", value: "factureClient", sortable: false },
      { text: "Client", value: "client", sortable: false },
      {
        text: "Montant à financer H.T.",
        value: "montantAFinancer",
        sortable: false,
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),

  computed: {
    size() {
      const size = { xs: "x-small", sm: "small", lg: "large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
  },

  methods: {
    addFactureClient() {
      if (this.factureClientSelected) {
        let found = this.factureClientItems.find(
          (element) => element == this.factureClientSelected
        );
        if (!found) {
          this.factureClientItems.push(this.factureClientSelected);
        } else {
          this.$store.commit("addAlert", {
            type: "warning",
            text: "La facture est déjà attachée",
          });
        }
        this.factureClientSelected = undefined;
      }
    },
    fetchFactureClients() {
      this.$apollo
        .query({
          query: QUERY_factureClients,
          variables: {
            pagination: {
              limit: 500,
              filter: {
                customSearch: [{ _id: this.factureClientItems }],
              },
            },
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          this.factureClients = data.factureClients;
        });
    },
    removeFactureClient(item) {
      let index = this.factureClientItems.indexOf(item.id);
      if (index > -1) {
        this.factureClientItems.splice(index, 1);
      }
    },
  },
  watch: {
    value: function (val) {
      this.factureClientItems = val;
    },
    factureClientItems: function (val) {
      if (val === null || val === []) {
        this.$emit("input", []);
      } else {
        this.$emit("input", val);
        this.fetchFactureClients();
      }
    },
  },
};
</script>