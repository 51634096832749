<template>
  <div>
    <template v-if="bailleur">
      <template v-if="size == 'xl'">
        <ObjectLayout size="xl" :item="bailleur">
          <template v-slot:icon>mdi-piggy-bank</template>
          <template v-slot:titre>
            Bailleur - {{ bailleur.codeBailleur }}
          </template>
          <template v-slot:body>
            <TitleLayout titre="Informations administratives" />
            <v-row>
              <v-col>
                <ObjectLayout v-if="bailleur.codeBailleur">
                  <template v-slot:icon>mdi-tag-text</template>
                  <template v-slot:titre> Code unique du bailleur </template>
                  <template v-slot:content>
                    {{ bailleur.codeBailleur }}
                  </template>
                </ObjectLayout>
              </v-col>
              <v-col>
                <ObjectLayout v-if="bailleur.entrepriseAssociee">
                  <template v-slot:icon>mdi-tag-text</template>
                  <template v-slot:titre> Entreprise associée </template>
                  <template v-slot:content>
                    <EntrepriseObject
                      :entrepriseProp="bailleur.entrepriseAssociee"
                      size="xs"
                    />
                  </template>
                </ObjectLayout>
              </v-col>
            </v-row>
            <template v-if="bailleur.informationCreancier">
              <TitleLayout titre="Informations bancaires" />
              <v-row>
                <v-col>
                  <ObjectLayout>
                    <template v-slot:titre> Raison sociale </template>
                    <template v-slot:content>
                      {{ bailleur.informationCreancier.raisonSociale }}
                    </template>
                  </ObjectLayout>
                </v-col>
                <v-col>
                  <ObjectLayout>
                    <template v-slot:icon>mdi-contain</template>
                    <template v-slot:titre>
                      Identifiant Créancier SEPA (ICS)
                    </template>
                    <template v-slot:content>
                      {{
                        bailleur.informationCreancier.ics
                          | VMask("XXXX XXXX XXX XX")
                      }}
                    </template>
                  </ObjectLayout>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <ObjectLayout>
                    <template v-slot:icon>mdi-office-building-marker</template>
                    <template v-slot:titre> Adresse </template>
                    <template v-slot:content>
                      {{ bailleur.informationCreancier.adresse }}
                    </template>
                  </ObjectLayout>
                </v-col>
                <v-col>
                  <ObjectLayout>
                    <template v-slot:icon>mdi-office-building-marker</template>
                    <template v-slot:titre> Code Postal - Ville </template>
                    <template v-slot:content>
                      {{ bailleur.informationCreancier.cpVille }}
                    </template>
                  </ObjectLayout>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <ObjectLayout>
                    <template v-slot:icon>mdi-flag</template>
                    <template v-slot:titre> Pays</template>
                    <template v-slot:content>
                      {{ bailleur.informationCreancier.pays }}
                    </template>
                  </ObjectLayout>
                </v-col>
              </v-row>
            </template>
          </template>
        </ObjectLayout>
      </template>

      <template v-if="size == 'md'">
        <ObjectLayout size="md">
          <template v-slot:icon>mdi-piggy-bank</template>
          <template v-slot:titre>demande d'accord</template>
          <template v-slot:content>
            {{ bailleur.demandesAccords }}
          </template>
        </ObjectLayout>
      </template>
      <template v-if="size == 'xs'">
        <ObjectLayout
          size="xs"
          :onClickPush="`/satelease/refinancement/bailleurs/view/${bailleur.id}`"
          :action="action"
        >
          <template v-slot:icon>mdi-piggy-bank</template>
          <template v-slot:content>
            {{ bailleur.codeBailleur }} -
            {{ bailleur.entrepriseAssociee.raisonSociale }}
          </template>
        </ObjectLayout>
      </template>
    </template>
  </div>
</template>

<script>
import { QUERY_bailleur } from "./graphql/queries";

import EntrepriseObject from "@/components/COMPONENTS-annuaire/EntrepriseObject.vue";
import ObjectLayout from "@/components/COMPONENTS-skeleton/Layouts/ObjectLayout.vue";
import TitleLayout from "@/components/COMPONENTS-skeleton/Layouts/TitleLayout.vue";

export default {
  name: "BailleurObject",

  components: {
    ObjectLayout,
    EntrepriseObject,
    TitleLayout,
  },

  data: () => ({
    bailleur: undefined,
  }),

  props: {
    size: { type: String, default: "md" },
    bailleurProp: {
      type: Object,
      default() {
        return {};
      },
    },
    bailleurID: {
      type: String,
    },
    action: { type: String, default: "router" },
  },

  watch: {
    bailleurProp: function () {
      this.init();
    },

    bailleurID: function () {
      this.init();
    },
  },

  mounted: function () {
    this.init();
  },

  methods: {
    init() {
      if (this.bailleurID) {
        let id = this.bailleurID;
        this.$apollo
          .query({
            query: QUERY_bailleur,
            variables: { id: id },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let bailleur = data.data.bailleur;
            this.bailleur = JSON.parse(JSON.stringify(bailleur));
            // this.updatePanelEtb();
          });
      } else {
        this.bailleur = JSON.parse(JSON.stringify(this.bailleurProp));
      }
    },
    generateProposition() {},
  },
};
</script>

