<template>
  <v-container>
    <v-card elevation="2" max-width="600" class="mx-auto">
      <v-card-title class="grey--text align-end pa-10">

        <!-- {{ applicationName }} -->
        <v-img max-height="200px" contain :src="logoOrgUrl" v-if="logoOrgUrl" />
        <v-img max-height="200px" max-widht="450px" contain src="~@/assets/logo.png" v-else />
      </v-card-title>
      <template v-if="isLogged == 'logout'">
        <v-tabs fixed-tabs v-model="tab">
          <v-tabs-slider color="primary" />
          <v-tab key="connexion"> Connexion </v-tab>
          <v-tab key="inscription"> Inscription </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-card-text>
            <v-tab-item key="connexion">
              <LoginForm @logging="updateIsLogged" />
            </v-tab-item>
            <v-tab-item key="inscription">
              <p align="center">
                En vous inscrivant à notre plateforme,,<br />
                vous acceptez
                <a href="#">nos conditions générales d'utilisation</a>.
              </p>
              <!--TODO Ajouter les CGU-->
              <UserForm @registering="updateIsLogged" disposition="inside" :hasResetButton="false" :showRoles="false" />
            </v-tab-item>
            <v-row>
              <v-col align="center">
                <p class="font-weight-light">
                  Made with ❤️ by
                  <a href="https://www.aidalinfo.fr" alt="Site internet du concepteur"
                    class="font-weight-medium grey--text text--darken-2" target="_blank">
                    Aidalinfo
                  </a>
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-tabs-items>
      </template>

      <template v-else-if="isLogged == 'login'">
        <v-card-text>
          <OrganisationChoice @logging="updateIsLogged" :organisation="organisation" />
        </v-card-text>
      </template>

      <template v-else-if="isLogged == '2fa'">
        <v-card-text>
          <UserOTPVerification @verif="updateIsLogged" />
        </v-card-text>
      </template>
    </v-card>
  </v-container>
</template>

<script>
// @ is an alias to /src
import { MUTATION_downloadUrlLogoAndBgOrganisationCreate } from "@/components/COMPONENTS-fichiers-stockages/graphql/mutations.js";
import { QUERY_organisationByUrl, QUERY_authentificationHabilitationOrganisationParametresByNameOrId } from './graphql/queries';
import LoginForm from "./LoginForm";
import OrganisationChoice from "./OrganisationChoice";
import UserForm from "./UserForm";
import UserOTPVerification from "./UserOTPVerification.vue";

import { getCurrentTokenState } from "@/components/COMPONENTS-skeleton/vue-apollo";

export default {
  name: "LoginAndRegisterForm",

  components: {
    LoginForm,
    OrganisationChoice,
    UserForm,
    UserOTPVerification,
  },

  computed: {
    applicationName() {
      return this.organisationParams.applicationName ? this.organisationParams.applicationName : this.$store.state.env["VUE_APP_PROJECT_NAME"]
    },

    backgroundUrl() {
      return this.backgroundOrgUrl
        ? this.backgroundOrgUrl
        : "~@/assets/HomeBG.jpg";
    },

    logoUrl() {
      console.log('titi')
      return this.logoOrgUrl ? this.logoOrgUrl : null;
    },

    organisation() {
      if (this.$route.query.org) {
        return this.$route.query.org;
      }

      return null;
    },
  },

  data: () => ({
    isLogged: null,
    tab: null,
    backgroundOrgUrl: null,
    logoOrgUrl: null,
    organisationParams: {}
  }),

  methods: {
    async getLogoAndBackground() {
      let nameOrId = this.$route.query.org;
      let customSearch = [
        { isActif: true },
        {
          "meta.typeParent":
            "AuthentificationHabilitationOrganisationParametres",
        },
      ];
      nameOrId.length <= 20
        ? customSearch.push({ "meta.nameParent": nameOrId })
        : customSearch.push({ "meta.idParent": nameOrId });

      let res = await this.$apollo
        .mutate({
          mutation: MUTATION_downloadUrlLogoAndBgOrganisationCreate,
          variables: {
            pagination: {
              skip: 0,
              limit: 2,
              filter: {
                customSearch: customSearch,
                toFind: "",
              },
            },
          },
        });

      this.logoOrgUrl = res.data.downloadUrlLogoAndBgOrganisationCreate.logoUrl;
      this.backgroundOrgUrl =
        res.data.downloadUrlLogoAndBgOrganisationCreate.backgroundUrl;
    },

    async getOrganisationParams() {
      let res = await this.$apollo
        .query({
          query: QUERY_authentificationHabilitationOrganisationParametresByNameOrId,
          variables: {
            organisationNameOrId: this.$route.query.org
          }
        });

      this.organisationParams = res.data.authentificationHabilitationOrganisationParametresByNameOrId;
    },

    async init() {
      await this.getOrganisationParams();
      await this.getLogoAndBackground();

      if (this.organisationParams.color && this.organisationParams.color != '') {
        this.$vuetify.theme.themes.light.primary = this.organisationParams.color;
        this.$vuetify.theme.themes.dark.primary = this.organisationParams.color;
      }
    },

    updateIsLogged: async function () {
      this.isLogged = await getCurrentTokenState();
    },
  },

  mounted: async function () {
    await this.updateIsLogged();
    if (this.$route.query.org) {
      await this.init();
    } else {
      this.$apollo
        .query({
          query: QUERY_organisationByUrl,
          variables: {
            url: window.location.host,
            fetchPolicy: "no-cache",
          },
        })
        .then(async (__) => {
          const data = __.data.organisationByUrl;
          if (data) {
            this.$router.push({ path: `/?org=${data}` });
            await this.init();
          }
        });
    }
  },

  watch: {
    backgroundOrgUrl(val) {
      this.$emit("changeBG", val);
    },
  },
};
</script>
