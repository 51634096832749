<template>
  <v-container class="fluid">
    <AccordActions :object="accord" :withView="false" size="xl" />
    <v-row class="align-center mx-auto">
      <v-col>
        <AccordObject size="xl" :accordID="accord.id" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { QUERY_accord } from "./graphql/queries";
import AccordObject from "./AccordObject.vue";
import AccordActions from "./AccordActions.vue";
export default {
  name: "AccordView",
  components: {
    AccordObject,
    AccordActions,
  },

  data: () => ({
    accord: {},
  }),

  apollo: {
    accord: {
      query: QUERY_accord,
      variables() {
        let idQuery = this.showPopup
          ? this.id
          : this.$route.params.id
          ? this.$route.params.id
          : this.id;
        return {
          id: idQuery,
        };
      },
    },
  },
  props: {
    showPopup: {
      type: Boolean,
    },
    id: {
      type: String,
    },
  },
};
</script>
