<template>
  <div>
    <ActionLayout :actions="actions" :size="size" :action="action" />
  </div>
</template>

<script>
import ActionLayout from "@/components/COMPONENTS-skeleton/Layouts/ActionLayout.vue";
import {
  MUTATION_demandeAccordDelete,
  MUTATION_demandeAccordUpdate,
} from "./graphql/mutations.js";
export default {
  name: "DemandeAccordActions",
  components: {
    ActionLayout,
  },
  props: {
    mainIcon: {
      type: String,
      defautl: "mdi-cash-check",
    },
    object: {
      type: [Array, Object],
    },
    size: {
      type: String,
      default: "md",
    },
    action: {
      type: String,
      default: "router",
    },
    multiple: { type: Boolean, default: false },
  },
  data: () => ({}),
  computed: {
    actions() {
      return [
        {
          text: "Editer",
          icon: "mdi-pencil",
          path:
            "/satelease/refinancement/demandeAccords/edit/" + this.object.id,
          acl: "/satelease/refinancement/demandeAccords/edit",
          color: "",
          conditions: { multiple: false, isActif: true },
        },
        {
          text: "Supprimer",
          icon: "mdi-delete",
          method: "editIsActif",
          acl: "/satelease/refinancement/demandeAccords/deleted",
          color: "error",
          conditions: { multiple: true, isActif: true },
        },
        {
          text: "Restauration",
          icon: "mdi-restore",
          method: "editIsActif",
          acl: "/satelease/refinancement/demandeAccords/edit",
          color: "",
          conditions: { multiple: true, isActif: false },
        },
        {
          text: "Supprimer définitivement",
          icon: "mdi-delete",
          method: "delete",
          acl: "/satelease/refinancement/demandeAccords/definitive-delete",
          color: "error",
          conditions: { multiple: false, isActif: false },
        },
        {
          text: "Visualiser",
          icon: "mdi-eye",
          path:
            "/satelease/refinancement/demandeAccords/view/" + this.object.id,
          acl: "/satelease/refinancement/demandeAccords/view",
          color: "",
          conditions: {},
        },
      ];
    },
  },

  created() {
    this.$root.$on("deleteDemandeAccord", this.deleteDemandeAccord);
    this.$root.$on("editDemandeAccord", this.editDemandeAccord);
  },

  methods: {
    /**
     * Supprimer un demandeAccord grâce à son id
     */
    deleteDemandeAccord(id) {
      this.$apollo
        .mutate({
          mutation: MUTATION_demandeAccordDelete,
          variables: {
            id: id,
          },
        })
        .then(() => {
          this.$emit("fetch");
        });
    },

    /**
     * Fonction à avoir dans tous les composants Actions (infos à changer selon le composant)
     * Permet de lancer la popup de validation
     *
     * obj : l'objet a supprimé
     */
    delete(obj) {
      // infos qui s'afficheront dans le popup
      let toValid = {
        titre: "Suppression définitive de la demande d'accord",
        texte: `Êtes-vous sûr de vouloir supprimer définitivement la demande d'accord ${obj.numeroDemande} ?`,
        type: "warning",
        toReturn: {
          function: "deleteDemandeAccord", // cette fonction change en fonction du composant (ex: PersonneActions.vue ça serait deletePersonne,
          //qu'il faut créer à la place de deleteDemandeAccord)
          arg: obj.id,
        },
      };

      // Envoi l'info pour ouvrir la popup
      this.$root.$emit("popupValidation", toValid);
    },

    /**
     * Fonction qui édite un demandeAccord
     *
     * obj : l'objet à éditer
     */
    async editDemandeAccord(obj) {
      // Si obj n'est pas un tableau alors on le transforme en tableau
      if (!Array.isArray(obj)) {
        obj = [obj];
      }
      // Selon le schéma, il est obligé d'avoir le siren et la raison sociale dans EntepriseInput
      // Bien vérifier dans le schéma si jamais il y a une erreur du style "$input etc.."
      this.$emit("loading");
      for (let item of obj) {
        let demandeAccord = {
          id: item.id,
          numeroDemande: item.numeroDemande,

          isActif: !item.isActif,
        };
        await this.$apollo.mutate({
          mutation: MUTATION_demandeAccordUpdate,
          variables: {
            input: demandeAccord,
          },
        });
      }
      this.$emit("fetch");
    },

    /**
     * Fonction à avoir dans tous les composants (infos à changer)
     */
    editIsActif(obj) {
      // Si obj n'est pas un tableau alors on le transforme en tableau
      if (!Array.isArray(obj)) {
        obj = [obj];
      }
      let toValid = {
        titre: obj[0].isActif
          ? "Suppression de demande(s) d'accord"
          : "Restauration de demande(s) d'accord",
        texte: obj[0].isActif
          ? `Êtes-vous sûr de vouloir supprimer ${obj.length} demande(s) d'accord ? ` +
            'Elle seront toujours présentes dans l\'onglet "Corbeille"'
          : `Êtes-vous sûr de vouloir restaurer ${obj.length} demande(s) d'accord ?`,
        type: "warning",
        toReturn: {
          function: "editDemandeAccord", // à changer selon le composant, comme deleteDemandeAccord
          arg: obj,
        },
      };

      this.$root.$emit("popupValidation", toValid);
    },
  },
};
</script>


