<template>
  <FormLayout
    icon="mdi-material-design"
    @ajouter="ajouter"
    @modifier="modifier"
    @reinitialiser="init"
    typeItem="une marque"
    :item="input"
    :disabled="input.nom ? false : true"
  >
    <v-row class="ma-4" justify="center" no-gutters>
      <FichierLogo size="128" v-model="input.logo" :text="input.nom" />
    </v-row>
    <v-row class="justify-center align-center">
      <v-col class="justify-center" cols="12" xl="4" lg="4" md="4" sm="4">
        <v-text-field
          v-model="input.nom"
          :error-messages="formsErrors.AssetsMarqueNom"
          name="label"
          label="Intitulé de la marque"
        />
      </v-col>
    </v-row>
  </FormLayout>
</template>

<script>
import { mapState } from "vuex";

import {
  MUTATION_marqueCreate,
  MUTATION_marqueUpdate,
} from "./graphql/mutations";
import { QUERY_marque } from "./graphql/queries";

import FichierLogo from "@/components/COMPONENTS-fichiers-stockages/FichierLogo.vue";
import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";

export default {
  name: "MarqueForm",

  components: {
    FichierLogo,
    FormLayout,
  },

  props: {
    componentParent: undefined,
  },

  data: () => ({
    input: {},
    marque: {},
  }),

  computed: {
    ...mapState(["formsErrors"]),
  },

  mounted: function () {
    this.init();
  },

  methods: {
    ajouter() {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_marqueCreate,
          variables: {
            input: toSend,
          },
        })
        .then((__) => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "La marque a été créée",
          });

          if (this.componentParent) {
            this.$root.$emit(this.componentParent, __.data.marqueCreate);
          }

          this.$root.$emit("skeleton--close");
          this.init();
        });
    },

    init() {
      if (
        this.$route.path.startsWith("/assets/marques/edit/") &&
        this.$route.params.id
      ) {
        let id = this.$route.params.id;
        this.$apollo
          .query({
            query: QUERY_marque,
            variables: {
              id: id,
            },
          })
          .then((data) => {
            let marque = data.data.marque;
            this.input = JSON.parse(JSON.stringify(marque));
          });
      } else {
        this.input = JSON.parse(JSON.stringify(this.marque));
      }
    },

    inputConverter(arg) {
      const obj = JSON.parse(JSON.stringify(arg));

      let input = {
        nom: obj.nom,
        logo: {
          id: obj.logo && obj.logo.id ? obj.logo.id : "",
        },
      };

      obj.id != undefined ? (input.id = obj.id) : null;

      return input;
    },

    modifier() {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_marqueUpdate,
          variables: {
            input: toSend,
          },
        })
        .then(() => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "La marque a été modifiée",
          });
          this.$root.$emit("skeleton--close");
        });
    },
  },
};
</script>
