import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@/components/COMPONENTS-skeleton/css/main.css'
import fr from 'vuetify/es5/locale/fr';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { fr },
    current: 'fr'
  },
  theme: {
    options: {
      customProperties: true
    },
    dark: false,
    themes: {
      light: {
        primary: "#1EB980",
        secondary: "#FF9800",
        thirdary: "#FFFFFF",
        celluleDark: "#FFFFFF"

      },
      dark: {
        primary: "#1EB980",
        secondary: "#FF9800",
        thirdary: "#000000",
        celluleDark: "#212121"
      },
    },
  },
});