export default [
  {
    path: '/admin-provider/requetes-api',
    name: 'Gestion des requêtes API',
    component: () => import('./RequetesApiOrganisationList.vue'),
    meta: {
      acl: "/admin-provider/requetes-api"
    }
  },

  {
    path:'/admin-provider/requetes-api/list',
    name: 'Liste des requêtes API',
    component: () => import('./RequetesApiOrganisationList.vue'),
    meta: {
      acl: "/admin-provider/requetes-api/list"
    }
  },

  {
    path: '/admin-provider/requetes-api/view/:id',
    name: 'Visualiser une requête d\'API',
    component: () => import('./RequetesApiOrganisationView.vue'),
    meta: {
      acl: "/admin-provider/requetes-api/view/"
    }
  }
]