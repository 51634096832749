<template>
  <div>
    <div v-for="etat of etatsAsset" :key="etat.value">
      <template v-if="etatAsset == etat.value">
        <template v-if="size == 'xs'">
          <ObjectLayout size="xs" :color="etat.color">
            <template v-slot:content>
              {{ etat.text }}
            </template>
            <template v-slot:icon>
              {{ etat.icon }}
            </template>
            <template v-slot:tooltip v-if="tooltip">
              {{ etat.description }}
            </template>
          </ObjectLayout>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import ObjectLayout from "@/components/COMPONENTS-skeleton/Layouts/ObjectLayout.vue";
export default {
  name: "etatAssetObject",
  components: {
    ObjectLayout,
  },
  props: {
    size: { type: String, default: "md" },
    etatAsset: { type: String, default: "NEUF" },
    tooltip: { type: Boolean, default: true },
  },
  computed: {},
  data: () => ({
    etatsAsset: [
      {
        value: "NEUF",
        icon: "mdi-hexagon-slice-6",
        color: "#42A5F5",
        text: "Produit neuf",
        description: "Produit neuf, jamais utilisé",
      },
      {
        value: "PARFAIT",
        icon: "mdi-hexagon-slice-5",
        color: "#66BB6A",
        text: "Parfait état",
        description:
          "Produit d'occasion en parfait état, aucune rayure ou impact visible",
      },
      {
        value: "TRES_BON",
        icon: "mdi-hexagon-slice-4",
        color: "#9CCC65",
        text: "Très bon état",
        description:
          "Produit d'occasion présentant des micro-rayures à peine visibles (invisibles à 20 cm), sans fissure et/ou impact",
      },
      {
        value: "CORRECT",
        icon: "mdi-hexagon-slice-3",
        color: "#FFA726",
        text: "Etat correct",
        description:
          "Produit d'occasion présentant des traces d’usure visibles, des rayures profondes et/ou petits impacts",
      },
      {
        value: "CASSE",
        icon: "mdi-hexagon-outline",
        color: "#EF5350",
        text: "Cassé / Hors d'usage",
        description: "Produit d'occasion pour pièces",
      },
    ],
  }),
};
</script>