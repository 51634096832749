<template>
  <ViewLayout :tabs="tabs">
    <template v-slot:parametres>
      <FormLayout
        icon="mdi-cog"
        @modifier="modifier"
        @reinitialiser="init"
        titre="Paramètres envoi notifications"
        :item="input"
      >
        <v-row>
          <v-col cols="6">
            <v-select 
              v-model="input.providerName"
              :items="providers"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="input.providerKeyAPI"
              label="Clé d'API"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="input.emailSend"
              label="Email d'envoi"
              :error-messages="formsErrors.emailCorrect"
            />
          </v-col>
        </v-row>
      </FormLayout>
    </template>
  </ViewLayout>
</template>

<script>
import { mapState } from "vuex";
import { QUERY_envoiNotificationsOrganisationParametres } from "./graphql/queries.js";
import { MUTATION_envoiNotificationsOrganisationParametresUpdate } from "./graphql/mutations.js";
import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";
import ViewLayout from "@/components/COMPONENTS-skeleton/Layouts/ViewLayout.vue";
import _ from "lodash";

export default {
  name: "EnvoiNotificationsOrganisationParametresForm",
  components: {
    FormLayout,
    ViewLayout,
  },

  computed: {
    ...mapState(["formsErrors"]),
  },

  data: () => ({
    input: {},

    providers: [
      { value: "SENDINBLUE", text: "Sendinblue" },
      { value: "TEST", text: "Test" }
    ],

    tabs: [{ icon: "mdi-cog", displayName: "Paramètres", name: "parametres" }],
  }),

  mounted: function () {
    this.init();
  },

  methods: {
    init() {
      this.$apollo
        .query({
          query: QUERY_envoiNotificationsOrganisationParametres,
        })
        .then((data) => {
          let envoiNotificationsOrganisationParametres =
            data.data.envoiNotificationsOrganisationParametres;
          this.input = JSON.parse(
            JSON.stringify(envoiNotificationsOrganisationParametres)
          );
        });
    },

    inputConverter(arg) {
      const obj = { ...arg };

      if (_.isEmpty(obj)) {
        return "";
      }

      let input = {
        providerKeyAPI: obj.providerKeyAPI,
        providerName: obj.providerName,
        emailSend: obj.emailSend
      };

      return input;
    },

    modifier() {
      let toSend = this.inputConverter(this.input);
      this.$apollo.mutate({
        mutation: MUTATION_envoiNotificationsOrganisationParametresUpdate,
        variables: {
          input: toSend,
        },
        update: (
          store,
          { data: { envoiNotificationsOrganisationParametresUpdate } }
        ) => {
          this.$emit(
            "envoiNotificationsOrganisationParametresUpdateReturn",
            envoiNotificationsOrganisationParametresUpdate
          );
          this.$store.commit("addAlert", {
            type: "info",
            text: "Le paramètrage a été modifié",
          });
          this.$root.$emit(
            "envoi-notifications--update-OrganisationParametres"
          );
          this.$root.$emit("skeleton--close");
        },
      });
    },
  },
};
</script>