<template>
  <div>
    <ListLayout
      ref="list"
      :headers="headers"
      @fetch="init"
      @showMultipleActions="showMultipleActions = $event"
    >
      <template v-slot:multipleActions>
        <FichierActions
          :multiple="true"
          :withView="false"
          :object="$refs.list ? $refs.list.itemsSelected : []"
          size="xl"
          @fetch="init"
          v-show="showMultipleActions"
        />
      </template>
      <template v-slot:customFilters>
        <FichierTypeSelector v-model="customFilters.type" />
      </template>
      <template v-slot:[`item.object`]="{ item }">
        <FichierObject :fichierProp="item" size="xs" />
      </template>
      <template v-slot:[`item.createdBy`]="{ item }">
        <UserObject :userID="item.createdBy" size="xs" />
      </template>
      <template v-slot:[`item.personalData`]="{ item }">
        <v-icon v-if="item.personalData" color="success">
          mdi-check-circle
        </v-icon>
        <v-icon v-if="!item.personalData" color="error">
          mdi-close-circle
        </v-icon>
      </template>

      <template v-slot:[`item.securise`]="{ item }">
        <v-icon v-if="item.securise" color="success"> mdi-check-circle </v-icon>
        <v-icon v-if="!item.securise" color="error"> mdi-close-circle </v-icon>
      </template>
      <template v-slot:[`item.dateCreation`]="{ item }">
        {{ item.dateCreation | shortDateFR }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <FichierActions
          :isActif="$refs.list.itemsAreActive"
          :object="item"
          @fetch="init"
          size="xs"
        />
      </template>
    </ListLayout>
  </div>
</template>

<script>
import FichierActions from "./FichierActions.vue";
import FichierObject from "./FichierObject.vue";
import FichierTypeSelector from "./FichierTypeSelector.vue";
import ListLayout from "@/components/COMPONENTS-skeleton/Layouts/ListLayout.vue";
import UserObject from "@/components/COMPONENTS-authentification-habilitation/UserObject.vue";
import { QUERY_fichiers } from "./graphql/queries";
export default {
  name: "ClientsList",
  components: {
    FichierActions,
    FichierObject,
    FichierTypeSelector,
    ListLayout,
    UserObject,
  },
  data: () => ({
    customFilters: {
      type: [],
    },
    showMultipleActions: false,
    headers: [
      { text: "Fichier", value: "object", sortable: false },
      { text: "Créateur", value: "createdBy", sortable: false },
      { text: "Date Création", value: "dateCreation", sortable: true },
      { text: "Taille", value: "size", sortable: true },
      { text: "Données personnelles", value: "personalData", sortable: true },
      { text: "Données sensibles", value: "securise", sortable: true },
      { text: "Commentaires", value: "commentaires" },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),
  methods: {
    fetchFichiers() {
      this.$refs.list.items = [];
      this.$refs.list.loading = true;
      let pagination = JSON.parse(JSON.stringify(this.$refs.list.pagination));
      if (this.customFilters.type[0]) {
        pagination.filter.customSearch.push({
          type: this.customFilters.type,
        });
      }

      this.$apollo
        .query({
          query: QUERY_fichiers,
          variables: {
            pagination: pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          this.$refs.list.items = data.fichiers;
          this.$refs.list.itemsCount = data.fichiersCount;
          this.$refs.list.loading = false;
        });
    },
    init() {
      this.fetchFichiers();
    },
  },
  watch: {
    customFilters: {
      deep: true,
      handler() {
         
        this.$refs.list.currentPage = 1;
        this.init();
      },
    },
    etablissementsCustomFilters: {
      deep: true,
      handler() {
        this.init();
      },
    },
  },
};
</script>