<template>
  <v-row>
    <v-col>
      <v-sheet>
        <v-toolbar flat color="white"
          ><v-btn color="primary" dark @click.stop="dialog = true">
            Ajouter un événement
          </v-btn>
          <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
            Aujourd'hui
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>

          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Jour</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Semaine</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Mois</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>4 jours</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-dialog v-model="dialog" max-width="500">
        <v-card>
          <v-container>
            <EntreePlanningForm :plannings="plannings" />
          </v-container>
        </v-card>
      </v-dialog>
      <v-sheet height="600">
        <v-calendar
          locale="fr"
          ref="calendar"
          v-model="focus"
          color="primary"
          :events="events"
          :type="type"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="getEvents"
        >
        </v-calendar>

        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card color="grey lighten-4" min-width="350px" flat>
            <v-toolbar :color="selectedEvent.color">
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <span v-html="selectedEvent.details"></span>
            </v-card-text>
            <v-card-actions>
              <v-btn text color="secondary" @click="selectedOpen = false">
                Fermer
              </v-btn>
              <v-btn text @click="show = !show">Modifier</v-btn>
              <v-btn text @click="deleteEvent(selectedEvent)"> Effacer </v-btn>
            </v-card-actions>
            <v-expand-transition max-width="500">
              <div v-show="show">
                <v-container>
                  <EntreePlanningForm :plannings="plannings" />
                </v-container>
              </div>
            </v-expand-transition>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import EntreePlanningForm from "./EntreePlanningForm";
import {
  QUERY_entreesPlannings,
  QUERY_entreesPlanning,
} from "./graphql/queries";

import { MUTATION_entreePlanningDelete } from "./graphql/mutations";
import _ from "lodash";
export default {
  name: "PlanningsView",
  components: { EntreePlanningForm },
  data: () => ({
    dialog: false,
    start: null,
    end: null,
    events: [],
    entreesPlannings: [],
    show: false,
    focus: new Date().toISOString().substr(0, 10),
    type: "month",
    typeToLabel: {
      month: "Mois",
      week: "Semaine",
      day: "Jour",
      "4Jour": "4 Jours",
    },
    selectedEvent: {},
    selectedOpen: false,
    selectedElement: null,
  }),

  props: ["plannings"],

  apollo: {
    entreesPlannings: {
      query: QUERY_entreesPlannings,
      variables() {
        let idPlannings = _.map(this.plannings, "id");
        return {
          input: {
            idPlannings: idPlannings,
          },
        };
      },
    },
  },

  watch: {
    entreesPlannings: function (val) {
      let evenements = [];
      val.map((entree) => {
        evenements.push({
          id: entree.id,
          name: entree.label,
          start: entree.dateDebut,
          details: entree.description,
          end: entree.dateFin,
          color: entree.color,
        });
      });
      this.events = evenements;
    },
  },
  methods: {
    showEvent({ nativeEvent, event }) {
      // fonction qui sélectionne l'événement avec une valeur false de base qui le laisse fermé
      const open = () => {
        this.selectedEvent = event; // selectEvent est égale à un évènement  du calendar
        this.selectedElement = nativeEvent.target; // selectElement = target de l'évènement  cliquable
        setTimeout(() => (this.selectedOpen = true), 10);
      };
      if (this.selectedOpen) {
        this.selectedOpen = false; // si n'y a pas d'event à sélectionner il reste à false
        setTimeout(open, 10);
      } else {
        open(); // sinon il sélectionne un event et affichage la V-card
      }
      nativeEvent.stopPropagation();
    },

    prev() {
      this.$refs.calendar.prev(); // fonction qui permet de faire défiler les mois dans l'ordre inverse de la chronologie
    },
    next() {
      this.$refs.calendar.next(); // fonction qui permet de faire défiler les mois dans l'ordre de la chronologie
    },
    getEvents({ start, end }) {
      this.start = start.date;
      this.end = end.date;
    },

    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },

    deleteEvent: function (event) {
      // fonction récupère l'id de l'entrée et la supprime
      this.$apollo
        .mutate({
          mutation: MUTATION_entreePlanningDelete,
          variables: {
            id: event.id,
          },
          //TODO: refetchQueries marche pas
          refetchQueries: [{ query: QUERY_entreesPlanning }], //udpate des entrées plannings
          update: (store, { data: { entreePlanningDelete } }) => {
            this.justCreated = entreePlanningDelete;
            this.$store.commit("addAlert", {
              type: "success",
              text: "L'événement  a bien été supprimée'",
            });
            this.$root.$emit("close-dialog");
          },
        })
        .then((data) => {
          console.log(data);
        });
    },

    setToday() {
      this.focus = "";
    },
  },
};
</script>

