<template>
  <div>
    <template v-if="demandeAccord">
      <template v-if="size == 'xl'">
        <ObjectLayout :action="action" size="xl" :item="demandeAccord">
          <template v-slot:icon>mdi-cash-check</template>
          <template v-slot:titre>
            {{ demandeAccord.numeroDemande }}
          </template>
          <template v-slot:body>
            <TitleLayout titre="Informations administratives" />
            <v-row>
              <v-col>
                <ObjectLayout v-if="demandeAccord.createdBy">
                  <template v-slot:icon>mdi-account</template>
                  <template v-slot:titre> Demande établie par </template>
                  <template v-slot:content>
                    <UserObject
                      :userID="demandeAccord.createdBy.id"
                      size="xs"
                    />
                  </template>
                </ObjectLayout>
              </v-col>
              <v-col>
                <ObjectLayout>
                  <template v-slot:icon>mdi-calendar</template>
                  <template v-slot:titre> Demande établie le </template>
                  <template v-slot:content>
                    {{ demandeAccord.dateDemande | shortDateFR }}
                  </template>
                </ObjectLayout>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ObjectLayout v-if="demandeAccord.affaire">
                  <template v-slot:icon>mdi-briefcase-clock</template>
                  <template v-slot:titre> Affaire associée </template>
                  <template v-slot:content>
                    <AffaireObject
                      :affaireProp="demandeAccord.affaire"
                      size="xs"
                    />
                  </template>
                </ObjectLayout>
              </v-col>
              <v-col>
                <ObjectLayout v-if="demandeAccord.bailleur">
                  <template v-slot:icon>mdi-bank</template>
                  <template v-slot:titre> Bailleur </template>
                  <template v-slot:content>
                    <BailleurObject
                      :bailleurProp="demandeAccord.bailleur"
                      size="xs"
                    />
                  </template>
                </ObjectLayout>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ObjectLayout>
                  <template v-slot:icon>mdi-counter</template>
                  <template v-slot:titre> Numéro de la demande</template>
                  <template v-slot:content>
                    {{ demandeAccord.numeroDemande }}
                  </template>
                </ObjectLayout>
              </v-col>
            </v-row>

            <TitleLayout titre="éléments de la demande" />
            <v-row>
              <v-col>
                <ObjectLayout :color="chip.color">
                  <template v-slot:icon>mdi-flag-checkered</template>
                  <template v-slot:titre> Etat de la demande</template>
                  <template v-slot:content>
                    {{ chip.text }}
                  </template>
                </ObjectLayout>
              </v-col>
              <v-col>
                <ObjectLayout>
                  <template v-slot:icon>mdi-cash</template>
                  <template v-slot:titre> Montant demandé </template>
                  <template v-slot:content>
                    {{ demandeAccord.montantDemande | monetaire }}
                  </template>
                </ObjectLayout>
              </v-col>
            </v-row>
          </template>
        </ObjectLayout>
      </template>

      <template v-if="size == 'xs'">
        <ObjectLayout
          size="xs"
          :action="action"
          :onClickPush="`/satelease/refinancement/demandeAccords/view/${demandeAccord.id}`"
          :color="chip.color"
        >
          <template v-slot:icon>mdi-cash-check</template>
          <template v-slot:content>
            {{ demandeAccord.numeroDemande }} - {{ chip.text }}
          </template>
          <template v-slot:extracontent>{{
            demandeAccord.bailleur.entrepriseAssociee.raisonSociale
          }}</template>
        </ObjectLayout>
      </template>

      <template v-if="size == 'xs-etat'">
        <v-chip :color="chip.color" class="font-weight-bold">
          <v-avatar left>
            <v-icon small>{{ chip.icon }}</v-icon>
          </v-avatar>
          {{ chip.text }}
        </v-chip>
      </template>
    </template>
  </div>
</template>

<script>
import { QUERY_demandeAccord } from "./graphql/queries";

import AffaireObject from "./AffaireObject.vue";
import BailleurObject from "./BailleurObject.vue";
import ObjectLayout from "@/components/COMPONENTS-skeleton/Layouts/ObjectLayout.vue";
import TitleLayout from "@/components/COMPONENTS-skeleton/Layouts/TitleLayout.vue";
import UserObject from "@/components/COMPONENTS-authentification-habilitation/UserObject.vue";

export default {
  name: "DemandeAccordObject",

  components: {
    AffaireObject,
    BailleurObject,
    ObjectLayout,
    TitleLayout,
    UserObject,
  },

  props: {
    size: { type: String, default: "md" },
    demandeAccordProp: {
      type: Object,
      default() {
        return {};
      },
    },
    demandeAccordID: {
      type: String,
    },
    action: {
      type: String,
      default: "router",
    },
  },

  data: () => ({
    demandeAccord: undefined,
  }),

  watch: {
    demandeAccordProp: function () {
      this.init();
    },
    demandeAccordID: function () {
      this.init();
    },
  },

  mounted: function () {
    this.init();
  },

  computed: {
    chip() {
      let values = {};
      if (this.demandeAccord.etat == "waiting") {
        values = {
          color: "info",
          icon: "mdi-clock-outline",
          text: "En attente",
        };
      }
      if (this.demandeAccord.etat == "waitingForElmt") {
        values = {
          color: "warning",
          icon: "mdi-clock-outline",
          text: "En attente d'éléments",
        };
      }
      if (this.demandeAccord.etat == "valid") {
        values = {
          color: "success",
          icon: "mdi-check-circle",
          text: "Validée",
        };
      }
      if (this.demandeAccord.etat == "refused") {
        values = {
          color: "error",
          icon: "mdi-close-circle",
          text: "Refusée",
        };
      }

      return values;
    },
  },
  methods: {
    init() {
      if (this.demandeAccordID) {
        let id = this.demandeAccordID;
        this.$apollo
          .query({
            query: QUERY_demandeAccord,
            variables: { id: id },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let demandeAccord = data.data.demandeAccord;
            this.demandeAccord = JSON.parse(JSON.stringify(demandeAccord));
          });
      } else {
        this.demandeAccord = JSON.parse(JSON.stringify(this.demandeAccordProp));
      }
    },
  },
};
</script>