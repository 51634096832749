import dayjs from "dayjs";

let dateUpdateOrganisation = dayjs('2022-05-25')
console.log('//// UPDATER \\\\')
// On récupère la liste des fichiers de mises à jour des répertoires enfants de @/components
const files = require.context('/src/components/', true, /\.update\.js$/).keys()
console.log(files)


const updater = function (vue) {
  files.forEach(file => {
    let strDate = file.slice(file.length - 20, file.length - 10)
    console.log(dayjs(strDate))
    if (dayjs(strDate).isAfter(dateUpdateOrganisation)) {
      console.log('on applique la mise à jour ' + strDate)
      let module = require(`/src/components/${file.substring(2)}`)
      module.default(vue)
    }
  });

}


export default updater