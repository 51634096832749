<template>
  <ViewLayout :tabs="tabs" :item="factureFournisseur">
    <template v-slot:factureFournisseur>
      <FactureFournisseurActions :object="factureFournisseur" :withView="false" size="xl" />
      <v-row class="align-center mx-auto">
        <v-col>
          <FactureFournisseurObject size="xl" :factureFournisseurProp="factureFournisseur" />
        </v-col>
      </v-row>
    </template>
    <template v-slot:assets>TEST</template>
  </ViewLayout>
</template>

<script>
import { QUERY_factureFournisseur } from "./graphql/queries";

import FactureFournisseurObject from "./FactureFournisseurObject.vue";
import FactureFournisseurActions from "./FactureFournisseurActions.vue";
import ViewLayout from "@/components/COMPONENTS-skeleton/Layouts/ViewLayout.vue";

export default {
  name: "FactureFournisseurView",

  components: {
    FactureFournisseurObject,
    FactureFournisseurActions,
    ViewLayout,
  },

  data: () => ({
    factureFournisseur: {},
    tabs: [
      {
        icon: "mdi-file-table",
        displayName: "Facture",
        name: "factureFournisseur",
      },
      // {
      //   icon: "mdi-package-variant-closed",
      //   displayName: "Equipements",
      //   name: "assets",
      // },
      { name: "commentaires" },
      { name: "fichiers" },
    ],
  }),

  apollo: {
    factureFournisseur: {
      query: QUERY_factureFournisseur,
      variables() {
        let idQuery = this.showPopup
          ? this.id
          : this.$route.params.id
            ? this.$route.params.id
            : this.id;
        return {
          id: idQuery,
        };
      },
      fetchPolicy: "no-cache",
    },
  },

  props: {
    id: {
      type: String,
    },

    showPopup: {
      type: Boolean,
    },
  },
};
</script>