<template>
  <ListLayout ref="list" :headers="headers" @fetch="init">
    <template v-slot:[`item.object`]="{ item }">
      <TemplateObject :templateProp="item" size="xs" />
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <TemplateActions :object="item" :fetch="fetchTemplates" size="xs" />
    </template>
  </ListLayout>
</template>

<script>
import ListLayout from "@/components/COMPONENTS-skeleton/Layouts/ListLayout.vue";
import TemplateActions from "./TemplateActions.vue";
import TemplateObject from "./TemplateObject.vue";

import { QUERY_templates } from "./graphql/queries";

export default {
  name: "TemplatesList",

  components: {
    ListLayout,
    TemplateActions,
    TemplateObject,
  },

  data: () => ({
    headers: [
      { text: "ID", value: "id", sortable: true },
      { text: "", value: "object", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),

  methods: {
    fetchTemplates() {
      this.$refs.list.loading = true;
      this.$refs.list.pagination.filter.customSearch.push({
        templateStatus: true,
      });
      this.$apollo
        .query({
          query: QUERY_templates,
          variables: {
            pagination: this.$refs.list.pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          this.$refs.list.items = data.templates;
          this.$refs.list.loading = false;
           
        });
    },
    init() {
      this.fetchTemplates();
    },
  },
};
</script>