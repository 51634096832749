import gql from 'graphql-tag';
import {
  BON_DE_COMMANDE_FOURNISSEUR_FIELDS,
  CLIENT_FIELDS,
  DEVIS_CLIENT_FIELDS,
  DEVIS_FOURNISSEUR_FIELDS,
  FACTURE_CLIENT_FIELDS,
  FACTURE_FOURNISSEUR_FIELDS,
  FOURNISSEUR_FIELDS,
  MOYENPAIEMENT_FIELDS,
  POINTFACTURATION_FIELDS,
  REGLEMENT_CLIENT_FIELDS,
  REGLEMENT_FOURNISSEUR_FIELDS
} from './fragments';

export const MUTATION_bonDeCommandeFournisseurCreate = gql`
mutation($input:BonDeCommandeFournisseurInput!){
  bonDeCommandeFournisseurCreate(input:$input){
    ...BonDeCommandeFournisseurFields
  }
}${BON_DE_COMMANDE_FOURNISSEUR_FIELDS}
`;

export const MUTATION_bonDeCommandeFournisseurDelete = gql`
mutation($id:ID!){
  bonDecommandeFournisseurDelete(id:$id)
}
`;

export const MUTATION_bonDeCommandeFournisseurUpdate = gql`
mutation($input:BonDeCommandeFournisseurInput!){
  bonDeCommandeFournisseurUpdate(input:$input){
    ...BonDeCommandeFournisseurFields
  }
}${BON_DE_COMMANDE_FOURNISSEUR_FIELDS}
`;

export const MUTATION_clientCreate = gql`
mutation($input:ClientInput!){
  clientCreate(input:$input){
    ...ClientFields
  }
}${CLIENT_FIELDS}
`;

export const MUTATION_clientDelete = gql`
mutation($id:ID!){
  clientDelete(id:$id)
}
`;

export const MUTATION_clientUpdate = gql`
mutation($input:ClientInput!){
  clientUpdate(input:$input){
    ...ClientFields
  }
}${CLIENT_FIELDS}
`;



export const MUTATION_devisClientCreate = gql`
mutation($input:DevisClientInput!){
  devisClientCreate(input:$input){
    ...DevisClientFields
  }
}${DEVIS_CLIENT_FIELDS}
`;

export const MUTATION_devisClientDelete = gql`
mutation($id:ID!){
  devisClientDelete(id:$id)
}
`;

export const MUTATION_devisClientDuplicate = gql`
  mutation($id: ID!) {
    devisClientDuplicate(id: $id) {
      ...DevisClientFields
    }
  }${DEVIS_CLIENT_FIELDS}
`;

export const MUTATION_devisClientUpdate = gql`
mutation($input:DevisClientInput!){
  devisClientUpdate(input:$input){
    ...DevisClientFields
  }
}${DEVIS_CLIENT_FIELDS}
`;

export const MUTATION_devisClientFactureClientAttach = gql`
  mutation($idDevis: ID!, $idFacture: ID!) {
    devisClientFactureClientAttach(idDevis: $idDevis, idFacture: $idFacture) {
      ...DevisClientFields
    }
  }${DEVIS_CLIENT_FIELDS}
`;

export const MUTATION_devisClientFactureClientTransform = gql`
  mutation($idDevis: ID!) {
    devisClientFactureClientTransform(idDevis: $idDevis) {
      ...DevisClientFields
    }
  }${DEVIS_CLIENT_FIELDS}
`;

export const MUTATION_devisFournisseurCreate = gql`
mutation($input:DevisFournisseurInput!){
  devisFournisseurCreate(input:$input){
    ...DevisFournisseurFields
  }
}${DEVIS_FOURNISSEUR_FIELDS}
`;

export const MUTATION_devisFournisseurDelete = gql`
mutation($id:ID!){
  devisFournisseurDelete(id:$id)
}
`;

export const MUTATION_devisFournisseurDuplicate = gql`
  mutation($id: ID!) {
    devisFournisseurDuplicate(id: $id) {
      ...DevisFournisseurFields
    }
  }${DEVIS_FOURNISSEUR_FIELDS}
`;

export const MUTATION_devisFournisseurUpdate = gql`
mutation($input:DevisFournisseurInput!){
  devisFournisseurUpdate(input:$input){
    ...DevisFournisseurFields
  }
}${DEVIS_FOURNISSEUR_FIELDS}
`;

export const MUTATION_devisFournisseurFactureFournisseurAttach = gql`
  mutation($idDevis: ID!, $idFacture: ID!) {
    devisFournisseurFactureFournisseurAttach(idDevis: $idDevis, idFacture: $idFacture) {
      ...DevisFournisseurFields
    }
  }${DEVIS_FOURNISSEUR_FIELDS}
`;

export const MUTATION_devisFournisseurFactureFournisseurTransform = gql`
  mutation($idDevis: ID!) {
    devisFournisseurFactureFournisseurTransform(idDevis: $idDevis) {
      ...DevisFournisseurFields
    }
  }${DEVIS_FOURNISSEUR_FIELDS}
`;

export const MUTATION_devisFactureOrganisationParametresUpdate = gql`
  mutation($input:DevisFactureOrganisationParametresInput){
    devisFactureOrganisationParametresUpdate(input:$input){
      id
    
    }
  }
`;

export const MUTATION_factureClientCreate = gql`
mutation($input:FactureClientInput!){
  factureClientCreate(input:$input){
    ...FactureClientFields
  }
}${FACTURE_CLIENT_FIELDS}
`;


export const MUTATION_factureClientDelete = gql`
mutation($id:ID!){
  factureClientDelete(id:$id)
}
`;

export const MUTATION_factureClientUpdate = gql`
mutation($input:FactureClientInput!){
  factureClientUpdate(input:$input){
    ...FactureClientFields
  }
}${FACTURE_CLIENT_FIELDS}
`;

export const MUTATION_factureClientValidate = gql`
mutation($input:FactureClientValidateInput!){
  factureClientValidate(input:$input){
    ...FactureClientFields
  }
}${FACTURE_CLIENT_FIELDS}
`;

export const MUTATION_factureFournisseurCreate = gql`
mutation($input:FactureFournisseurInput!){
  factureFournisseurCreate(input:$input){
    ...FactureFournisseurFields
  }
}${FACTURE_FOURNISSEUR_FIELDS}
`;


export const MUTATION_factureFournisseurDelete = gql`
mutation($id:ID!){
  factureFournisseurDelete(id:$id)
}
`;

export const MUTATION_factureFournisseurUpdate = gql`
mutation($input:FactureFournisseurInput!){
  factureFournisseurUpdate(input:$input){
    ...FactureFournisseurFields
  }
}${FACTURE_FOURNISSEUR_FIELDS}
`;


export const MUTATION_fournisseurCreate = gql`
mutation($input: FournisseurInput!){
  fournisseurCreate(input:$input){
    ...FournisseurFields
  }
}${FOURNISSEUR_FIELDS}
`;

export const MUTATION_fournisseurUpdate = gql`
mutation($input: FournisseurInput!){
  fournisseurUpdate(input:$input){
    ...FournisseurFields
  }
}${FOURNISSEUR_FIELDS}
`;

export const MUTATION_fournisseurDelete = gql`
mutation($id:ID!){
  fournisseurDelete(id:$id) {
    ...FournisseurFields
  }
}${FOURNISSEUR_FIELDS}
`;

export const MUTATION_moyenPaiementCreate = gql`
mutation($input: MoyenPaiementInput!){
  moyenPaiementCreate(input:$input){
    ...MoyenPaiementFields
  }
}${MOYENPAIEMENT_FIELDS}
`;

export const MUTATION_moyenPaiementUpdate = gql`
mutation($input: MoyenPaiementInput!){
  moyenPaiementUpdate(input:$input){
    ...MoyenPaiementFields
  }
}${MOYENPAIEMENT_FIELDS}
`;

export const MUTATION_moyenPaiementDelete = gql`
mutation($id:ID!){
  moyenPaiementDelete(id:$id) {
    ...MoyenPaiementFields
  }
}${MOYENPAIEMENT_FIELDS}
`;

export const MUTATION_pointFacturationCreate = gql`
mutation($input: PointFacturationInput!){
  pointFacturationCreate(input:$input){
    ...PointFacturationFields
  }
}${POINTFACTURATION_FIELDS}
`;

export const MUTATION_pointFacturationUpdate = gql`
mutation($input: PointFacturationInput!){
  pointFacturationUpdate(input:$input){
    ...PointFacturationFields
  }
}${POINTFACTURATION_FIELDS}
`;

export const MUTATION_pointFacturationDelete = gql`
mutation($id:ID!){
  pointFacturationDelete(id:$id) {
    ...PointFacturationFields
  }
}${POINTFACTURATION_FIELDS}
`;


export const MUTATION_reglementClientCreate = gql`
mutation($input: ReglementClientInput!){
  reglementClientCreate(input:$input){
    ...ReglementClientFields
  }
}${REGLEMENT_CLIENT_FIELDS}
`;

export const MUTATION_reglementClientUpdate = gql`
mutation($input: ReglementClientInput!){
  reglementClientUpdate(input:$input){
    ...ReglementClientFields
  }
}${REGLEMENT_CLIENT_FIELDS}
`;

export const MUTATION_reglementClientDelete = gql`
mutation($id:ID!){
  reglementClientDelete(id:$id) {
    ...ReglementClientFields
  }
}${REGLEMENT_CLIENT_FIELDS}
`;

export const MUTATION_reglementFournisseurCreate = gql`
mutation($input: ReglementFournisseurInput!){
  reglementFournisseurCreate(input:$input){
    ...ReglementFournisseurFields
  }
}${REGLEMENT_FOURNISSEUR_FIELDS}
`;

export const MUTATION_reglementFournisseurUpdate = gql`
mutation($input: ReglementFournisseurInput!){
  reglementFournisseurUpdate(input:$input){
    ...ReglementFournisseurFields
  }
}${REGLEMENT_FOURNISSEUR_FIELDS}
`;

export const MUTATION_reglementFournisseurDelete = gql`
mutation($id:ID!){
  reglementFournisseurDelete(id:$id) {
    ...ReglementFournisseurFields
  }
}${REGLEMENT_FOURNISSEUR_FIELDS}
`;
