<template>
  <ViewLayout :tabs="tabs" :item="famille">
    <template v-slot:famille>
      <FamilleActions :object="famille" size="xl" />
      <v-row class="align-center mx-auto">
        <v-col cols="12">
          <FamilleObject size="xl" :familleProp="famille" />
        </v-col>
        <v-col>
        </v-col>
      </v-row>
    </template>
  </ViewLayout>
</template>

<script>
import { QUERY_famille } from "./graphql/queries";

import FamilleActions from "./FamilleActions.vue";
import FamilleObject from "./FamilleObject.vue";
import ViewLayout from "@/components/COMPONENTS-skeleton/Layouts/ViewLayout.vue";

export default {
  name: "FamilleView",

  components: {
    FamilleActions,
    FamilleObject,
    ViewLayout,
  },

  data: () => ({
    famille: {},
    tabs: [
      {
        icon: "mdi-family-tree",
        displayName: "Famille",
        name: "famille",
      },
      { name: "commentaires" },
      { name: "fichiers" },
    ],
  }),

  apollo: {
    famille: {
      query: QUERY_famille,
      variables() {
        let idQuery = this.showPopup
          ? this.id
          : this.$route.params.id
          ? this.$route.params.id
          : this.id;
        return {
          id: idQuery,
        };
      },
    },
  },
};
</script>