<template>
  <div>
    <ActionLayout
      :actions="actions"
      :size="size"
      :action="action"
      @fetch="$emit('fetch')"
      :mainIcon="mainIcon"
    />
  </div>
</template>

<script>
import ActionLayout from "@/components/COMPONENTS-skeleton/Layouts/ActionLayout.vue";
import { MUTATION_equipementDelete } from "./graphql/mutations.js";
export default {
  name: "EquipementActions",
  components: {
    ActionLayout,
  },

  props: {
    withView: { type: Boolean, default: true },
    mainIcon: { type: String, default: "mdi-antenna" },
    object: { type: [Array, Object] },
    size: { type: String, default: "md" },
    action: { type: String, default: "router" },
    fetch: {
      type: Function,
      default() {
        console.warn("defaultFetch");
      },
    },
  },

  computed: {
    actions() {
      let toReturn = [
        {
          text: "Supprimer définitivement",
          icon: "mdi-delete",
          method: "delete",
          acl: "/authentification-habilitation/equipements/defitive-delete",
          color: "error",
        },
      ];

      if (this.withView) {
        toReturn.unshift({
          text: "Visualiser",
          icon: "mdi-eye",
          path:
            "/authentification-habilitation/equipements/view/" + this.object.id,
          acl: "/authentification-habilitation/equipements/view/",
          color: "",
        });
      }

      return toReturn;
    },
  },
  created() {
    this.$root.$on("deleteEquipement", this.deleteEquipement);
  },

  methods: {
    /**
     * Supprimer une etablissement grâce à son id
     */
    deleteEquipement(id) {
      this.$apollo
        .mutate({
          mutation: MUTATION_equipementDelete,
          variables: { id: id },
        })
        .then(() => {
          this.fetch();
        });
    },

    delete(obj) {
      let toValid = {
        titre: "Suppression définitive de votre équipement",
        texte: `Êtes-vous sûr de vouloir supprimer définitivement votre équipement ${obj.browserName} - ${obj.OSName} ?`,
        type: "warning",
        toReturn: {
          function: "deleteEquipement",
          arg: obj.id,
        },
      };

      // Envoi l'info pour ouvrir la popup
      this.$root.$emit("popupValidation", toValid);
    },
  },
};
</script>