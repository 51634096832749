<template>
  <div>
    <ActionLayout
      :actions="actions"
      :size="size"
      :action="action"
      @fetch="$emit('fetch')"
      :mainIcon="mainIcon"
    />
  </div>
</template>

<script>
import { onUsurpationLogin } from "@/components/COMPONENTS-skeleton/vue-apollo";
import ActionLayout from "@/components/COMPONENTS-skeleton/Layouts/ActionLayout.vue";
import {
  MUTATION_userDelete,
  MUTATION_userUpdate,
  MUTATION_userValidateProvider
} from "./graphql/mutations.js";
import {
  QUERY_userLoginUsurpationByUser,
  QUERY_resendConfirmationMail,
} from "./graphql/queries.js";
export default {
  name: "UserActions",
  components: {
    ActionLayout,
  },

  props: {
    withView: { type: Boolean, default: true },
    mainIcon: { type: String, default: "mdi-account-multiple" },
    object: { type: [Array, Object] },
    size: { type: String, default: "md" },
    action: { type: String, default: "router" },
    multiple: { type: Boolean, default: false },
  },

  computed: {
    actions() {
      let toReturn = [
        {
          text: "Editer",
          icon: "mdi-pencil",
          path: "/authentification-habilitation/users/edit/" + this.object.id,
          acl: "/authentification-habilitation/users/edit",
          color: "",
          conditions: { multiple: false, isActif: true },
        },
        {
          text: "Supprimer",
          icon: "mdi-delete",
          method: "editIsActif",
          acl: "/authentification-habilitation/users/deleted",
          color: "error",
          conditions: { multiple: true, isActif: true },
        },

        {
          text: "Restauration",
          icon: "mdi-restore",
          method: "editIsActif",
          acl: "/authentification-habilitation/users/edit",
          color: "",
          conditions: { multiple: true, isActif: false },
        },
        {
          text: "Supprimer définitivement",
          icon: "mdi-delete",
          method: "delete",
          acl: "/authentification-habilitation/users/definitive-delete",
          color: "",
          conditions: { multiple: true, isActif: false },
        },
        {
          text: "Visualiser",
          icon: "mdi-eye",
          path: "/authentification-habilitation/users/view/" + this.object.id,
          acl: "/authentification-habilitation/users/view",
          color: "",
          conditions: { size: "md xs" },
        },
        {
          text: "Miroir",
          icon: "mdi-mirror-rectangle",
          method: "usurpation",
          acl: "/authentification-habilitation/users/usurpation",
          color: "warning",
          conditions: { multiple: false, isActif: true },
        },
        {
          text: "Réinitialiser le mot de passe",
          icon: "mdi-lock-reset",
          path: "/authentification-habilitation/provider/reinitialisation/mot-de-passe/" + this.object.id,
          acl: "/authentification-habilitation/provider/reinitialisation/mot-de-passe",
          color: "warning",
          conditions: { multiple: false, isActif: true }
        }
      ];
      if (!this.object.verify) {
        toReturn.push({
          text: "Email de vérification",
          icon: "mdi-email-fast",
          method: "resendEmail",
          acl: "/authentification-habilitation/users/renvoyerMail",
          color: "primary",
          conditions: {
            multiple: false,
          },
        });
        toReturn.push({
          text: "Valider le compte",
          icon: "mdi-check",
          method: "verifyUser",
          acl: "/authentification-habilitation/users/verifyUser",
          color: "primary",
          conditions: {
            multiple: false
          }
        })
      }

      return toReturn;
    },
  },

  /**
   * Permet d'utiliser la popup de validation
   */
  created() {
    this.$root.$on("deleteUser", this.deleteUser);
    this.$root.$on("editUser", this.editUser);
  },

  methods: {
    /**
     * Supprimer un user grâce à son id
     */
    deleteUser(id) {
      this.$apollo
        .mutate({
          mutation: MUTATION_userDelete,
          variables: { id: id },
        })
        .then(() => {
          this.fetch();
        });
    },

    /**
     * Fonction à avoir dans tous les composants Actions (infos à changer selon le composant)
     * Permet de lancer la popup de validation
     *
     * obj : l'objet a supprimé
     */
    delete(obj) {
      // infos qui s'afficheront dans le popup
      let toValid = {
        titre: "Suppression définitive d'un utilisateur",
        texte: `Êtes-vous sûr de vouloir supprimer définitivement l'utilisateur ${obj.label} ?`,
        type: "warning",
        toReturn: {
          function: "deleteUser", // cette fonction change en fonction du composant (ex: PersonneActions.vue ça serait deletePersonne,
          //qu'il faut créer à la place de deleteUser)
          arg: obj.id,
        },
      };

      // Envoi l'info pour ouvrir la popup
      this.$root.$emit("popupValidation", toValid);
    },

    /**
     * Fonction qui édite une user
     *
     * obj : l'objet à éditer
     */
    async editUser(obj) {
      // Si obj n'est pas un tableau alors on le transforme en tableau
      if (!Array.isArray(obj)) {
        obj = [obj];
      }

      this.$emit("loading");
      for (let item of obj) {
        let user = {
          id: item.id,
          isActif: !item.isActif,
        };
        await this.$apollo.mutate({
          mutation: MUTATION_userUpdate,
          variables: {
            input: user,
          },
        });
      }
      this.$emit("fetch");
    },

    /**
     * Fonction à avoir dans tous les composants (infos à changer)
     */
    editIsActif(obj) {
      // Si obj n'est pas un tableau alors on le transforme en tableau
      if (!Array.isArray(obj)) {
        obj = [obj];
      }
      let toValid = {
        titre: obj[0].isActif
          ? "Suppression d'utilisateur(s)"
          : "Restauration d'utilisateur(s)",
        texte: obj[0].isActif
          ? `Êtes-vous sûr de vouloir supprimer ${obj.length} utilisateur(s) ? ` +
            'Il seront toujours présents dans l\'onglet "Corbeille"'
          : `Êtes-vous sûr de vouloir restaurer ${obj.length} utilisateurs(s) ?`,
        type: "warning",
        toReturn: {
          function: "editUser", // à changer selon le composant, comme deleteEntreprise
          arg: obj,
        },
      };

      this.$root.$emit("popupValidation", toValid);
    },

    resendEmail(obj) {
      this.$apollo
        .query({
          query: QUERY_resendConfirmationMail,
          variables: {
            user: obj.id,
          },
          fetchPolicy: "no-cache",
        })
        .then(() => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "Votre email de vérification a bien été envoyé",
          });
          this.$root.$emit("skeleton--close");
        });
    },

    verifyUser(obj) {
      this.$apollo
        .mutate({
          mutation: MUTATION_userValidateProvider,
          variables: {
            userId: obj.id
          },
          fetchPolicy: "no-cache"
        })
        .then(() => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "L'utilisateur a été validé",
          });
          this.$root.$emit("skeleton--close");
        });
    },

    usurpation(obj) {
      this.$apollo
        .mutate({
          mutation: QUERY_userLoginUsurpationByUser,
          variables: {
            id: obj.id,
          },
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          let token = data.data.userLoginUsurpationByUser.token;
          onUsurpationLogin(this.$apollo.provider.defaultClient, token);
          this.$router.push({ path: "/" }).catch(() => {});
        });
    },
  },
};
</script>

