import gql from 'graphql-tag';
import { FICHIER_FIELDS } from './fragments';

export const QUERY_fichier = gql`
query($id:ID!){
  fichier(id: $id){
    ...FichierFields
    createdBy
  }
}${FICHIER_FIELDS}
`;

export const QUERY_fichier_jsonTextContent = gql`
query($id:ID!){
  fichier(id: $id){
    id
    jsonTextContent
  }
}
`;

export const QUERY_fichierTypes = gql`
query($pagination: PaginationObject){
  fichierTypes(pagination: $pagination)
}
`;

export const QUERY_fichiers = gql`
query($pagination: PaginationObject){
  fichiers(pagination: $pagination){
    ...FichierFields
    createdBy
  }
  fichiersCount(pagination: $pagination)
}${FICHIER_FIELDS}
`;

export const QUERY_fichiersCount = gql`
query($pagination: PaginationObject){
  fichiersCount(pagination:$pagination)
}
`;

export const QUERY_fichierCategories = gql`
  query($typeParent:String!){
    fichierCategories(typeParent:$typeParent)
  }
`;