<template>
<div>
  <CounterLayout :compteur="lienServicesCount">
    <template v-slot:icon>mdi-link-box</template>
  </CounterLayout>
</div>
</template>

<script>
import CounterLayout from "@/components/COMPONENTS-skeleton/Layouts/CounterLayout.vue";
import {
  QUERY_lienServicesCount
} from "./graphql/queries";
export default {
  name: 'LienServicesCounter',
  components: {
    CounterLayout,
  },
  props: {
    customSearch: {
      type: Array,
      default () {
        return [];
      },
    },
  },
  data: () => ({}),
  apollo: {
    lienServicesCount: {
      query: QUERY_lienServicesCount,
      variables() {
        return {
          pagination: {
            filter: {
              customSearch: this.customSearch,
            },
          },
        };
      },
      fetchPolicy: "no-cache",
    },
  },

  created() {
    if (!this.$root._events.assetsUpdateLienServices) {
      this.$root.$on("assetsUpdateLienServices", this.updateQuery);
    }else{
      this.$root.$off("assetsUpdateLienServices", this.updateQuery);
    }
      
    
  },
  mounted: function () {},
  watch: {},
  methods: {
    updateQuery: function () {
      this.$apollo.queries.lienServicesCount.refetch();
    },
  },
};
</script>