<template>
  <div>
    <v-row>
      <v-col>
        <v-btn
          color="primary"
          style="margin-bottom: 20px"
          @click="importDefaultTemplates"
        >
          Importer tous les templates par défaut
        </v-btn>
      </v-col>
      <v-col>
        <v-btn
          color="error"
          style="margin-bottom: 20px"
          @click="deleteDefaultTemplates"
        >
          Supprimer tous les templates par défaut
        </v-btn>
      </v-col>
    </v-row>
    <v-expansion-panels class="mb-6">
      <v-expansion-panel v-for="item in items" :key="item.type">
        <v-expansion-panel-header expand-icon="mdi-menu-down">
          <v-row no-gutters>
            <v-col cols="11">
              <p>
                Liste des templates pour les objets de type
                <b>{{ item.type }}</b>
              </p>
            </v-col>
            <FichiersCounter
              :customSearch="[
                { 'meta.typeParent': item.type },
                { 'meta.template': true },
              ]"
            />
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-expansion-panels class="mb-6">
            <v-expansion-panel
              v-for="categorie in item.categories"
              :key="categorie.value"
            >
              <v-expansion-panel-header expand-icon="mdi-menu-down">
                <v-row no-gutters>
                  <v-col cols="11">
                    <p>
                      Liste des templates pour les documents de type
                      <b>{{ item.type }} - {{ categorie.value }}</b>
                    </p>
                  </v-col>
                  <FichiersCounter
                    :customSearch="[
                      { 'meta.typeParent': item.type },
                      { 'meta.categorie': categorie.value },
                      { 'meta.template': true },
                    ]"
                  />
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col>
                    <FichiersObject
                      :meta="{
                        typeParent: item.type,
                        categorie: categorie.value,
                        template: true,
                        toSplit: categorie.toSplit,
                      }"
                      :useCategory="false"
                      :authorizedExtensions="['docx', 'doc']"
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import FichiersCounter from "./FichiersCounter.vue";
import FichiersObject from "./FichiersObject.vue";

import { MUTATION_fichiersTemplatesDefaultCreate, MUTATION_fichiersTemplatesDefaultDelete } from "./graphql/mutations.js";

export default {
  name: "TemplatesList",
  components: {
    FichiersCounter,
    FichiersObject,
  },
  props: {
    items: [],
  },
  data: () => ({}),

  methods: {
    deleteDefaultTemplates() {
      this.$apollo
        .mutate({
          mutation: MUTATION_fichiersTemplatesDefaultDelete
        })
        .then(__ => {
          this.$root.$emit("fichiersStockagesUpdateFichiers");
          this.$store.commit("addAlert", {
            type: "info",
            text: `${__.data.fichiersTemplatesDefaultDelete} templates par défaut ont été supprimés`,
          });
          this.$root.$emit("skeleton--close");
        })
    },

    importDefaultTemplates() {
      let types = this.items.map(elem => elem.type);
      this.$apollo
        .mutate({
          mutation: MUTATION_fichiersTemplatesDefaultCreate,
          variables: {
            types
          }
        })
        .then(() => {
          this.$root.$emit("fichiersStockagesUpdateFichiers");
          this.$store.commit("addAlert", {
            type: "info",
            text: "Les templates par défaut ont été rajoutés",
          });
          this.$root.$emit("skeleton--close");
        })
    }
  }
};
</script>

