<template>
  <ViewLayout :tabs="tabs">
    <template v-slot:templates>
      <h5>
        Ajoutez ici vos templates au format word (.docx). Ces derniers sont
        ensuite disponible dans l'applicatifs dans les fabriques à fichiers.
      </h5>
      <TemplatesList
        :items="templatesAvailable"
        typeParent="ProjetSateLeaseOrganisation"
        :idParent="projetSateLeaseOrganisationParametres.id"
      />
    </template>

    <template v-slot:parametres>
      <FormLayout
        icon="mdi-cog"
        @modifier="modifier"
        @reinitialiser="init"
        titre="Paramètres assets"
        :item="input"
      >
       <v-row>
          <v-col cols="12" >
            <FormLayout
              icon="mdi-cog"
              @ajouter="addCaracteristic"
              @modifier="editCaracteristic"
              @reinitialiser="resetCaracteristic"
              titre="Ajouter des caractéristiques personnalisées"
              :hasResetButton="false"
            >
              <v-text-field
                prepend-icon="mdi-form-textbox"
                v-model="caracteristicCourante.fieldName"
                label="Nom de la caractéristique"
                :rules="[() => input.caracteristics.find(elem => elem.fieldName == caracteristicCourante.fieldName) == undefined
                || 'Ce nom de caractéristique est déjà utilisé']"
              />
              <v-text-field
                prepend-icon="mdi-emoticon-outline"
                v-model="caracteristicCourante.icon"
                label="Icône de la caractéristique"
              />
              <v-text-field
                prepend-icon="mdi-weight-kilogram"
                v-model="caracteristicCourante.unite"
                label="Unité (kg, L, m, ...) de la caractéristique"
              />
              <v-select
                prepend-icon="mdi-form-select"
                v-model="caracteristicCourante.formType"
                :items="formsType"
                label="Choix du formulaire"
              />
              <ItemsTableForm
                v-if="caracteristicCourante.formType == 'selector'"
                v-model="caracteristicCourante.items"
                title="Eléments à faire figurer dans le sélecteur"
                icon="mdi-format-list-bulleted"
              />
              <v-row>
                <v-col cols="7">
                  <v-checkbox 
                    label="Caractéristique par défaut (s'affiche automatiquement dans le formulaire d'ajout de modèles)"
                    v-model="caracteristicCourante.default"
                  />
                </v-col>
                <v-col>
                  <v-checkbox 
                    label="Caractéristique obligatoire"
                    v-model="caracteristicCourante.obligatory"
                  />
                </v-col>
              </v-row>
            </FormLayout>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Nom
                    </th>
                    <th class="text-left">
                      Type de formulaire
                    </th>
                    <th class="text-left">
                      Icône
                    </th>
                    <th class="text-left">
                      Unité
                    </th>
                    <th class="text-left">
                      Par défaut
                    </th>
                    <th class="text-left">
                      Obligatoire
                    </th>
                    <th class="text-left">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="caracteristic in input.caracteristics"
                    :key="caracteristic.fieldName"
                  >
                    <td>{{ caracteristic.fieldName }}</td>
                    <td>{{ formsType.filter(elem => elem.value == caracteristic.formType)[0].text }}</td>
                    <td><v-icon>{{ caracteristic.icon }}</v-icon></td>
                    <td>{{ caracteristic.unite }}</td>
                    <td>
                      <v-icon :color="caracteristic.default ? 'success' : 'error'">
                        {{ caracteristic.default ? "mdi-check" : "mdi-close" }}
                      </v-icon>
                    </td>
                    <td>
                      <v-icon :color="caracteristic.obligatory ? 'success' : 'error'">
                        {{ caracteristic.obligatory ? "mdi-check" : "mdi-close" }}
                      </v-icon>
                    </td>
                    <td>
                      <v-icon
                        @click="editCaracteristic(caracteristic)"
                      >
                        mdi-pencil
                      </v-icon>
                      <v-icon
                        @click="deleteCaracteristic(caracteristic.fieldName)"
                        color="error"
                      >
                        mdi-delete
                      </v-icon>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </FormLayout>
    </template>
  </ViewLayout>
</template>

<script>
import { QUERY_assetOrganisationParametres } from "./graphql/queries.js";
import { MUTATION_assetOrganisationParametresUpdate } from "./graphql/mutations.js";

import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";
import TemplatesList from "@/components/COMPONENTS-fichiers-stockages/TemplatesList";
// import TitleLayout from "@/components/COMPONENTS-skeleton/Layouts/TitleLayout.vue";
import ViewLayout from "@/components/COMPONENTS-skeleton/Layouts/ViewLayout.vue";
import ItemsTableForm from "@/components/COMPONENTS-skeleton/ItemsTableForm.vue";

import _ from "lodash";

export default {
  name: "AssetOrganisationParametres",

  components: {
    FormLayout,
    TemplatesList,
    // TitleLayout,
    ViewLayout,
    ItemsTableForm
  },

  computed: {
    caracteristicsItems() {
      let caracteristics = this.input.caracteristics;
      return caracteristics && caracteristics.length > 0 ? this.input.caracteristics.map(elem => elem.fieldName) : []
    }
  },

  data: () => ({
    caracteristicCourante: {},

    input: {
      caracteristics: []
    },

    formsType: [
      { text: "Zone de texte", value: "textfield" },
      { text: "Nombre", value: "textfield-number" },
      { text: "Date", value: "date" },
      { text: "On/Off", value: "switch" },
      { text: "Case à cocher", value: "checkbox" },
      { text: "Sélecteur", value: "selector" },
    ],

    projetSateLeaseOrganisationParametres: {
      id: "",
      contratLocationSignataireLoueur: {},
    },

    tabs: [
      { icon: "mdi-cog", displayName: "Paramètres", name: "parametres" },
      { icon: "mdi-cog", displayName: "Templates", name: "templates" },
    ],

    templatesAvailable: [
      {
        type: "LienService",
        categories: [{ value: "Etiquette propriété", acl: "USER" }],
      },
      {
        type: "LienServices",
        categories: [
          { value: "Etiquettes de propriété multiple", acl: "USER" },
        ],
      },
    ],
  }),

  mounted: function () {
    this.init();
  },

  methods: {
    addCaracteristic() {
      let doublon = this.input.caracteristics.find(elem => elem.fieldName == this.caracteristicCourante.fieldName);
      if(!doublon) {
        if(this.caracteristicCourante.items && this.caracteristicCourante.items.length > 0 
        && this.caracteristicCourante.formType != "selector") {
          delete this.caracteristicCourante.items;
        }
        this.input.caracteristics.push(this.caracteristicCourante);
        this.resetCaracteristic();
      }
    },

    deleteCaracteristic(caracteristic) {
      let index = this.input.caracteristics.map(elem => elem.fieldName).indexOf(caracteristic);
      if(index != -1) {
        if(this.input.caracteristics.length == 1) {
          this.input.caracteristics.shift();
        }else {
          this.input.caracteristics.splice(index, 1)
        }
      }
    },

    editCaracteristic(caracteristic) {
      if(Object.entries(this.caracteristicCourante).length == 0) {
        this.deleteCaracteristic(caracteristic.fieldName);
        this.caracteristicCourante = JSON.parse(JSON.stringify(caracteristic));
      }
    },

    init() {
      this.$apollo
        .query({
          query: QUERY_assetOrganisationParametres,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          let assetOrganisationParametres =
            data.data.assetOrganisationParametres;
          this.input = JSON.parse(
            JSON.stringify(assetOrganisationParametres)
          );
        });
    },

    inputConverter(arg) {
      const obj = { ...arg };

      if (_.isEmpty(obj)) {
        return "";
      }

      let input = {
        caracteristics: obj.caracteristics
      };

      return input;
    },

    modifier() {
      let toSend = this.inputConverter(this.input);
      this.$apollo
        .mutate({
          mutation: MUTATION_assetOrganisationParametresUpdate,
          variables: {
            input: toSend,
          },
          fetchPolicy: 'no-cache'
        })
        .then(() => {
          this.$store.commit("addAlert", {
            type: "info",
            text: "Le paramètrage de l'organisation a été modifié",
          });
          this.$root.$emit("skeleton--close");
        })
    },

    resetCaracteristic() {
      this.caracteristicCourante = {};
    }
  },
};
</script>