<template>
  <div>
    <v-col cols="10" class="justify-center mx-auto text-center">
      <v-row>
        <img
          v-for="(lien, i) in lienServiceQRCode"
          :key="i"
          :src="lien"
        />
      </v-row>

      <v-btn class="my-9" @click="printLienService">Imprimer</v-btn>
    </v-col>
  </div>
</template>

<script>
import printJS from "print-js";
import { QUERY_lienServices } from "./graphql/queries";

export default {
  name: "LienServicePrinter",

  components: {},

  props: {
    lienServiceID: {
      type: String,
    },
  },

  data: () => ({
    lienServiceQRCode: [],
  }),

  mounted: function () {
    this.init();
  },
  watch: {
    lienServiceID: function () {
      this.init();
    },
  },

  methods: {
    init() {
      let id = undefined;

      if (this.lienServiceID) {
        id = this.lienServiceID;
      } else {
        /* on vérifie sinon si il y a l'id de l'url*/
        if (this.$route.params.id) {
          let params = this.$route.params.id;
          if (params.includes("+")) {
            /* si il y a plusieur id on sépare par un + qu'on stock dans la variable id*/
            id = params.split("+");
          } else {
            /* si il y a qu'un id on le stock dans la variable id*/
            id = params;
          }
        }
      }

      if (id) {
        let customSearch = [];
        if (Array.isArray(id)) {
          /* si l'objet passé en argument est un objet Array d'id */
          customSearch.push({ $or: [] });
          id.forEach((elem) => {
            customSearch[0].$or.push({ id: elem });
          });
        } else {
          /* sinon on stock l'id dans customSearch*/
          customSearch.push({ id: id });
        }

        this.$apollo
          .query({
            query: QUERY_lienServices,
            variables: {
              pagination: {
                filter: {
                  customSearch: customSearch,
                },
                skip: 0,
                limit: Array.isArray(id) ? id.length : 1,
              },
            },
            fetchPolicy: "no-cache",
          })
          .then((__) => {
            const data = __.data;
            if (Array.isArray(data.lienServices)) {
              data.lienServices.forEach((elem) => {
                this.lienServiceQRCode.push(elem.qrCode);
              });
            } else {
              this.lienServiceQRCode.push(data.lienServices[0].qrCode);
            }
          });
      }
    },

    printLienService() {
      let canvas = [];
      if (this.lienServiceQRCode) {
        for(let QRCode of this.lienServiceQRCode) {
          canvas.push(QRCode);
        }
      }

      printJS({
        printable: canvas,
        type: "image",
        imageStyle: "width:50%;margin-bottom:20px;",
      });
    },
  },
};
</script>