var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ListLayout',{ref:"list",attrs:{"headers":_vm.headers,"title":"Liste des personnes","noFilter":_vm.noFilter},on:{"fetch":_vm.init,"showMultipleActions":function($event){_vm.showMultipleActions = $event}},scopedSlots:_vm._u([{key:"multipleActions",fn:function(){return [_c('PersonneActions',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMultipleActions),expression:"showMultipleActions"}],attrs:{"multiple":true,"object":_vm.$refs.list ? _vm.$refs.list.itemsSelected : [],"size":"xl"},on:{"fetch":_vm.init,"loading":function($event){_vm.$refs.list.loading = true}}})]},proxy:true},{key:"customFilters",fn:function(){return [_c('EntrepriseSelector',{attrs:{"addAction":null},model:{value:(_vm.customFilters.entreprise),callback:function ($$v) {_vm.$set(_vm.customFilters, "entreprise", $$v)},expression:"customFilters.entreprise"}}),_c('EtablissementSelector',{attrs:{"addAction":null,"disabled":_vm.customFilters.entreprise ? false : true,"entreprise":_vm.customFilters.entreprise},model:{value:(_vm.customFilters.etablissement),callback:function ($$v) {_vm.$set(_vm.customFilters, "etablissement", $$v)},expression:"customFilters.etablissement"}})]},proxy:true},{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('PersonneObject',{attrs:{"personneProp":item,"size":"xs"}})]}},{key:"item.associatedUser",fn:function(ref){
var item = ref.item;
return [(item.associatedUser)?_c('UserObject',{attrs:{"user":item.associatedUser,"size":"xs-justUser"}}):_vm._e()]}},{key:"item.fonctions",fn:function(ref){
var item = ref.item;
return _vm._l((item.fonctions),function(fonction,index){return _c('FonctionObject',{key:'fonction' + index,attrs:{"fonction":fonction,"size":"xs"}})})}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('PersonneActions',{attrs:{"object":item,"size":"xs"},on:{"fetch":_vm.init,"loading":function($event){_vm.$refs.list.loading = true}}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }