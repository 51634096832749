<template>
  <ListLayout
    ref="list"
    :headers="headers"
    title="Liste des accords"
    :noFilter="noFilter"
    @fetch="init"
    @showMultipleActions="showMultipleActions = $event"
  >
    <template v-slot:multipleActions>
      <AccordActions
        :multiple="true"
        :withView="false"
        :object="$refs.list ? $refs.list.itemsSelected : []"
        size="xl"
        v-show="showMultipleActions"
        @fetch="init"
        @loading="$refs.list.loading = true"
      />
    </template>
    <template v-slot:[`item.object`]="{ item }">
      <AccordObject :accordProp="item" size="xs" />
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <AccordActions
        :object="item"
        size="xs"
        @fetch="init"
        @loading="$refs.list.loading = true"
      />
    </template>
  </ListLayout>
</template>

<script>
import ListLayout from "@/components/COMPONENTS-skeleton/Layouts/ListLayout.vue";
import AccordActions from "./AccordActions.vue";
import { QUERY_accords } from "./graphql/queries";
import AccordObject from "./AccordObject.vue";

export default {
  name: "AccordsList",
  components: {
    ListLayout,
    AccordActions,
    AccordObject,
  },
  props: {
    noFilter: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data: () => ({
    search: null,
    headers: [
      { text: "Accord", value: "object", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    showMultipleActions: false,
  }),
  methods: {
    fetchAccords() {
      this.$refs.list.loading = true;
      this.$apollo
        .query({
          query: QUERY_accords,
          variables: {
            pagination: this.$refs.list.pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          this.$refs.list.items = data.accords;
           
          this.$refs.list.itemsCount = data.accordsCount;
          this.$refs.list.loading = false;
        });
    },
    init() {
      this.fetchAccords();
    },
  },
};
</script>