<template>
  <div>
    <template v-if="enseigne">
      <!-- SIZE XL -->
      <template v-if="size == 'xl'">
        <ObjectLayout size="xl" :item="enseigne">
          <template v-slot:icon>mdi-domain</template>
          <template v-slot:titre> {{ enseigne.nom }}</template>
          <template v-slot:body>
            <ObjectLayout v-if="enseigne.nom">
              <template v-slot:titre> Nom de l'enseigne</template>
              <template v-slot:content>
                {{ enseigne.nom }}
              </template>
            </ObjectLayout>
            <ObjectLayout v-if="enseigne.description">
              <template v-slot:titre>Description de l'enseigne</template>
              <template v-slot:content>
                {{ enseigne.description }}
              </template>
            </ObjectLayout>
          </template>
          <template v-slot:body2>
            <div
              class="d-flex justify-center align-center"
              style="width: 100%; height: 220px"
            >
              <FichierLogo
                size="128"
                v-model="enseigne.logo"
                :text="enseigne.nom"
                :justDisplay="true"
              />
            </div>
          </template>
        </ObjectLayout>
      </template>
      <!--  SIZE MD -->
      <template v-if="size == 'md'">
        <v-row>
          <v-list-item-avatar tile>
            <FichierLogo
              size="32"
              v-model="enseigne.logo"
              :text="enseigne.nom"
              :justDisplay="true"
              class="mx-2"
            />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-html="enseigne.nom"></v-list-item-title>
            <v-list-item-subtitle
              v-html="enseigne.siren"
            ></v-list-item-subtitle>
          </v-list-item-content>
        </v-row>
      </template>
      <!--  SIZE XS -->
      <template v-if="size == 'xs' || size == 'xs-details'">
        <ObjectLayout
          :action="action"
          size="xs"
          :onClickPush="`/annuaire/enseignes/view/${enseigne.id}`"
        >
          <template v-slot:image>
            <FichierLogo
              size="14"
              v-model="enseigne.logo"
              :text="enseigne.nom"
              :justDisplay="true"
            />
          </template>
          <template v-slot:content>{{ enseigne.nom }}</template>
          <template v-slot:tooltip v-if="tooltip">
            <template v-if="enseigne.nom">
              <b class="font-weight-bold">Nom:</b>
              {{ enseigne.nom }} <br />
            </template>
            <template v-if="enseigne.description">
              <b class="font-weight-bold">Description:</b>
              {{ enseigne.description }}
            </template>
          </template>
        </ObjectLayout>
      </template>
    </template>
  </div>
</template>

<script>
import { QUERY_enseigne } from "./graphql/queries";
import FichierLogo from "@/components/COMPONENTS-fichiers-stockages/FichierLogo.vue";
import ObjectLayout from "@/components/COMPONENTS-skeleton/Layouts/ObjectLayout.vue";

export default {
  name: "enseigneObject",
  components: {
    ObjectLayout,
    FichierLogo,
  },
  props: {
    size: { type: String, default: "md" },
    enseigneProp: {
      type: Object,
      default() {
        return {};
      },
    },
    enseigneID: {
      type: String,
    },
    action: {
      type: String,
      default: "router",
    },
    tooltip: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    enseigneProp: function () {
      this.init();
    },
    enseigneID: function () {
      this.init();
    },
  },
  data: () => ({
    enseigne: undefined,
  }),
  mounted: function () {
    this.init();
  },
  methods: {
    init() {
      if (this.enseigneID) {
        let id = this.enseigneID;
        this.$apollo
          .query({
            query: QUERY_enseigne,
            variables: { id: id },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            let enseigne = data.data.enseigne;
            this.enseigne = JSON.parse(JSON.stringify(enseigne));
          });
      } else {
        this.enseigne = JSON.parse(JSON.stringify(this.enseigneProp));
      }
    },
  },
};
</script>