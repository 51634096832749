<template>
  <div class="fichierImageCropper">
    <v-dialog
      v-model="dialog"
      v-if="dataUrl"
      width="auto"
      max-width="400px"
      persistent
    >
      <v-card>
        <v-card-title>Redimensionner une image</v-card-title>
        <div class="cropper-image-container">
          <img :src="dataUrl" @load.stop="createCropper" alt ref="img" />
        </div>
        <v-card-actions>
          <v-btn text color="primary" @click="uploadImage"> Envoyer </v-btn>

          <v-btn text color="warning" @click="cancel"> Annuler </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <input :accept="mimes" class="cropper-img-input" ref="input" type="file" />
  </div>
</template>

<script>
//https://fengyuanchen.github.io/cropperjs/
import "cropperjs/dist/cropper.css";
import Cropper from "cropperjs";
import {
  MUTATION_uploadURLCreate,
  MUTATION_fichierCreate,
} from "./graphql/mutations";
export default {
  name: "FichierImageCropper",
  components: {},
  props: {
    //Trigger is the object who sent event to open input (a btn object)
    trigger: {
      //https://developer.mozilla.org/fr/docs/Web/API/Element
      type: Boolean,
      required: true,
    },
    // mimes are the file type, here we accept this default list, it's a props, it's possible to custom this list at the declaration of component
    //https://developer.mozilla.org/fr/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
    //https://fr.vuejs.org/v2/guide/components-props.html
    mimes: {
      type: String,
      default: "image/png, image/gif, image/jpeg, image/bmp, image/x-icon",
    },
    //options for the cropper, it's props, edition possible in parent
    //https://github.com/fengyuanchen/cropperjs/blob/master/README.md#options
    cropperOptions: {
      type: Object,
      default() {
        return {
          aspectRatio: 1,
          autoCropArea: 1,
          viewMode: 1,
          movable: false,
          zoomable: false,
        };
      },
    },

    meta: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data: () => ({
    dialog: false,
    cropper: undefined,
    // will contain the computer selected image data encoded in base64
    dataUrl: undefined,
    filename: undefined,
    mimeType: undefined,
  }),
  watch: {
    trigger: function (val) {
      if (val == true) {
        this.pickImage();
      }
    },
  },
  mounted() {
    // Listen for input file changes
    //https://blog.logrocket.com/vue-refs-accessing-dom-elements/
    let fileInput = this.$refs.input;
    fileInput.addEventListener("change", () => {
      // check if file is selected
      if (fileInput.files != null && fileInput.files[0] != null) {
        // check file type is images (define in props)
        let correctType = this.mimes
          .split(", ")
          .find((m) => m === fileInput.files[0].type);
        if (!correctType) {
          this.$emit("error", "FIle type not correct", "user");
          return;
        }
        //https://developer.mozilla.org/fr/docs/Web/API/FileReader
        let reader = new FileReader();
        //map dataUrl to reader
        reader.onload = (e) => {
          this.dataUrl = e.target.result;
        };

        // Put the data encoded base64 into reader
        reader.readAsDataURL(fileInput.files[0]);

        this.filename = fileInput.files[0].name || "unknown";
        this.mimeType = this.mimeType || fileInput.files[0].type;
        //return to parent component an emit who can be listened
        this.$emit("changed", fileInput.files[0], reader);
        //Ouvre la fenêtre de dialogue contenant le cropper
        this.dialog = true;
      }
    });
  },
  methods: {
    destroy() {
      this.cropper.destroy();
      this.$refs.input.value = "";
      this.dataUrl = undefined;
    },
    cancel() {
      this.$emit("cancel");
      this.destroy();
      this.dialog = false;
    },
    //Emulate click on input, open file explorer to select image
    pickImage() {
      this.$refs.input.click();
    },
    //Creation of cropper after the img is loaded
    createCropper() {
      this.cropper = new Cropper(this.$refs.img, this.cropperOptions);
    },
    submit() {
      this.$emit("submit");
      this.uploadImage();
      this.destroy();
    },
    uploadImage() {
      //On transforme le canva en blob
      this.cropper.getCroppedCanvas().toBlob((blob) => {
        //On transforme le blob en fichier
        let file = new File([blob], "fichierImageCropper.png", {
          type: "image/png",
          lastModified: new Date(),
        });
        //On récupére un lien d'upload
        this.$apollo
          .mutate({
            mutation: MUTATION_uploadURLCreate,
            variables: {
              fileName: file.name,
            },
          })
          .then((data) => {
            let uploadUrl = data.data.uploadURLCreate;
            fetch(uploadUrl.url, {
              method: "PUT",
              body: file,
            }).then(() => {
              this.$apollo
                .mutate({
                  mutation: MUTATION_fichierCreate,
                  variables: {
                    input: {
                      displayName: "avatar/logo",
                      fileName: uploadUrl.fileName,
                      size: file.size,
                      type: file.type,
                      securise: false,
                      note: "note",
                      personalData: false,
                      meta: this.meta
                    },
                  },
                })
                .then((data) => {
                  this.$emit("update-fichier", data.data.fichierCreate.id);
                  this.destroy();
                });
            });
          });
      });
    },
  },
};
</script>

<style>
.cropper-bg {
  background-image: none;
}
.cropper-img-input {
  display: none;
}
.cropper-point {
  background-color: #1eb980;
}
.cropper-view-box {
  outline: 1px solid #1eb980;
  outline-color: #1eb980b4;
}
.cropper-image-container {
  margin: auto;
  position: relative;
  max-width: 400px;
  height: 300px;
}
img {
  max-width: 100%;
  height: 100%;
}
</style>